<div class="content p-d-flex p-jc-between p-ai-center p-shadow-2">
    <img 
        class="logo pointer"
        (click)="goHome()"
        src="assets/images/logo_header.svg"/>
    <p-menubar 
        *ngIf="isAuthenticated"
        class="menu" 
        [model]="menuItems">
    </p-menubar>
    <app-profile-menu
        *ngIf="isAuthenticated" 
        class="profile">
    </app-profile-menu>
</div>
