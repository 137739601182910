import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {Codes, KeysFromCodes } from '@giuntipsy/utils/lib';
import { LoggedGuard } from '../guards/logged.guard';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor(private translate: TranslateService, private messageSrv: MessageService) { }

  //Tree structure to get the names of the errors
  static PROJECTS_BY_THOUSANDS=new Map<number, string>([
    [1,"Author"],
    [2,"Backoffice"],
    [3, "Reporting"],
    [5, "Assessment"],
    [6, "PsyPortal"],
    [7, "Login"],
    [8, "Communications"],
    [9, "Plans"]
  ])

  static DEFAULT_ERROR_KEY_IF_FAILS="HTTP.Default.DEFAULT_ERROR"

  //Find the HttpCode in KeysFromCodes. Throw error if code not found
  private getMessageLabelFromHttpCode(httpCode:number){

    let httpCodeLabel;
    try{
      //First, filter doing mod to know the project where the error comes from.

      let httpCodeByProject=ErrorsService.PROJECTS_BY_THOUSANDS.get(Math.floor(httpCode / 1000));

      //Then, get key label from the new enum KeysFromCodes in utils
      httpCodeLabel= httpCodeByProject+"."+KeysFromCodes[httpCodeByProject][httpCode]
      // console.log(httpCodeLabel)
      // console.log(httpCodeLabel)
    }
    catch(err){
      throw err;
    }
    if(httpCodeLabel==undefined)
      throw new Error("Undefined http code in KeysFromCodes")

    return httpCodeLabel

  }

  private translateError(errorKey:string){
    //Try to translate errorKey if exists
    let translated= this.translate.instant(errorKey)
    let failed=false
    if(translated==errorKey)
      failed=true

    return {
      failed:failed,
      translated:translated
    }
  }

  private logError(errorMessage:string, error?:any){
    if(error)
      console.error(errorMessage, error)
    else
      console.error(errorMessage);
  }

  private translateDefaultKey(defaultKey){
    let translatedMessage;
    let translatedKey=defaultKey;
    let translatedWithDefaultKeyFromParams=this.translateError(defaultKey)

    if(!translatedWithDefaultKeyFromParams.failed)
      translatedMessage=translatedWithDefaultKeyFromParams.translated;
    else{//Everything failed, use static default error
      translatedMessage=this.translateError(ErrorsService.DEFAULT_ERROR_KEY_IF_FAILS).translated
      translatedKey=ErrorsService.DEFAULT_ERROR_KEY_IF_FAILS
    }
    return {
      translatedMessage:translatedMessage,
      translatedKey:translatedKey
    }
  }

  /**
   * @param httpCode http code target
   *
   * @param options (optional):
   *
   * @param defaultMsgKey (optional) error key to show if the code is not found in json (Like PROJECTS.ERROR_REPORT)
   * @param useGenericIfCustomEnvFail  (optional) If httpCode not found in customEnv, use generic env (HTTP)
   * @param logErrorOnConsole (optional) Log message also in console
   * @param fullErrorToLog (optional) If logErrorOnConsole, log error object too
   * @param customEnvironment (optional) For example if, for the same code you need to define
   * PROJECT.ERROR_LEGACY_WITHOUT_ENOUGH_CREDITS for custom message instead of using generic HTTP.ERROR_LEGACY_WITHOUT_ENOUGH_CREDITS
   *
   * The logic is this:
   *
   * -Find the errCodeByProject key from the httpCode defined in KeysFromCodes
   *
   * 1.If customEnv:
   *    -Try to find and translate the message from key given the errCodeByProject key
   *    -If not found and useGenericIfCustomEnvFail, (*2)
   *    -If useGenericIfCustomEnvFail disabled, (*3)
   *
   * 2.If not customEnv or not found inside and useGenericIfCustomEnvFail:
   *    -Try to find and translate the message from errCodeByProject in the HTTP generic environment
   *    -If not found, (*3)
   *
   * 3.If something failed, try to find and translate the key from params (defaultMsgKey)
   *    -If this fails, use the static key DEFAULT_ERROR_KEY_IF_FAILS
   *
   * -Log error if option selected
   * -Return errKey and message translated
   */
  getShowableMessageFromHttpCode(httpCode:number, options?:ErrorActionsOptions){

    /*if(httpCode==undefined){
      return{
        translatedMessage:this.translate.instant(ErrorsService.DEFAULT_ERROR_KEY_IF_FAILS),
        translatedKey:ErrorsService.DEFAULT_ERROR_KEY_IF_FAILS
      }
    }*/

    let defaultMsgKey='PROJECTS.ERROR_REPORT';
    let useGenericEnvIfCustomFail=false;
    let logErrorOnConsole=false;
    let fullErrorToLog=null;
    let customEnvironment="HTTP";

    //Set options
    if(options){
      customEnvironment=options.customEnvironment ?options.customEnvironment : customEnvironment
      defaultMsgKey=options.defaultMsgKey ? options.defaultMsgKey : defaultMsgKey;
      useGenericEnvIfCustomFail=options.useGenericEnvIfCustomFail ? options.useGenericEnvIfCustomFail : useGenericEnvIfCustomFail;
      logErrorOnConsole=options.logErrorOnConsole ? options.logErrorOnConsole : logErrorOnConsole;
      fullErrorToLog=options.fullErrorToLog ? options.fullErrorToLog : fullErrorToLog;
    }

    let errCodeByProject;
    let keyToTranslateAndShow;
    let usingDefaultMsg=false;

    //Find the httpCode in KeysFromCodes to know the error name
    try{
      errCodeByProject=this.getMessageLabelFromHttpCode(httpCode)
      keyToTranslateAndShow=customEnvironment+"."+errCodeByProject;
    }
    catch(err){
      console.error(err);
      usingDefaultMsg=true;
      keyToTranslateAndShow=defaultMsgKey;
    }

    let translatedMessage;
    let translatedKey;
    //Try to find message in the selected environment (HTTP or custom)
    let translatedWithParamsKey=this.translateError(keyToTranslateAndShow)

    if(!translatedWithParamsKey.failed){
      translatedMessage=translatedWithParamsKey.translated;
      translatedKey=keyToTranslateAndShow
    }
    else if(usingDefaultMsg){ //ErrCode not found, can't do anything more
      //Try to translate from the defaultMsgKey given in params
      let translation=this.translateDefaultKey(keyToTranslateAndShow)
      translatedMessage=translation.translatedMessage
      translatedKey=translation.translatedKey //Maybe selected DEFAULT_ERROR_KEY_IF_FAILS
    }
    else{ //key not found in selected environment but httpCode is valid
      //If environment was HTTP, use defaultMsg, else, if useGenericEnvIfCustomFail, use HTTP now
      if(!useGenericEnvIfCustomFail){
        keyToTranslateAndShow=defaultMsgKey;

        let translation=this.translateDefaultKey(keyToTranslateAndShow)
        translatedMessage=translation.translatedMessage
        translatedKey=translation.translatedKey //Maybe selected DEFAULT_ERROR_KEY_IF_FAILS
      }
      else{ //Find in generic env
        keyToTranslateAndShow="HTTP."+errCodeByProject
        let translatedWithHttpEnv=this.translateError(keyToTranslateAndShow)
        if(!translatedWithHttpEnv.failed){
          translatedMessage=translatedWithHttpEnv.translated;
          translatedKey=keyToTranslateAndShow
        }
        else{
          keyToTranslateAndShow=defaultMsgKey;

          let translation=this.translateDefaultKey(keyToTranslateAndShow)
          translatedMessage=translation.translatedMessage
          translatedKey=translation.translatedKey //Maybe selected DEFAULT_ERROR_KEY_IF_FAILS
        }

      }
    }
    //Example 9009: HTTP.Plans.LEGACY_WITHOUT_ENOUGH_CREDITS
    //Or with custom environment (PROJECT.ERRORS): PROJECT.ERRORS.Plans.LEGACY_WITHOUT_ENOUGH_CREDITS

    if(logErrorOnConsole){
      if(fullErrorToLog)
        this.logError(translatedMessage, fullErrorToLog)
      else
        this.logError(translatedMessage)
    }

    return {
      translatedMessage:translatedMessage,
      translatedKey:translatedKey
    }
  }
}
/**
 * @param defaultMsgKey (optional) error key to show if the code is not found in json (Like PROJECTS.ERROR_REPORT)
 * @param useGenericIfCustomEnvFail  (optional) If httpCode not found in customEnv, use generic env (HTTP)
 * @param logErrorOnConsole (optional) Log message also in console
 * @param fullErrorToLog (optional) If logErrorOnConsole, log error object too
 * @param customEnvironment (optional) For example if, for the same code you need to define
 * PROJECT.ERROR_LEGACY_WITHOUT_ENOUGH_CREDITS for custom message instead of using generic HTTP.ERROR_LEGACY_WITHOUT_ENOUGH_CREDITS.
 */
 export interface ErrorActionsOptions{
  defaultMsgKey?:string
  useGenericEnvIfCustomFail?:boolean
  logErrorOnConsole?:boolean
  fullErrorToLog?:object
  customEnvironment?:string
}

