import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-template-user-simple',
  templateUrl: './template-user-simple.component.html',
  styleUrls: ['./template-user-simple.component.scss']
})
export class TemplateUserSimpleComponent implements OnInit, OnDestroy {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Input() confirmation;
  @Input() triggerName;
  @Input() importingCSVTesttakers; //ImportUser specific logic
  @Input() from;

  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemTriggered = new EventEmitter<any>();

  itemOptions: any;

  subs = new SubSink();

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initMenuOptions();
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.initMenuOptions();
    });
  }

  initMenuOptions(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.itemOptions = [{
        label: 'Options',
        items: [
          {
            label: this.translate.instant('GROUP.DELETE'),
            icon: 'pi pi-trash',
            command: () => {
              this.deleteItem();
            }
          }
        ]}
      ];
    });
  }

 /**
  * If text to show is too long, show ... after 32 Characters
  */
  AddThreeDotsIfTextLongerThan32(textToShow): any{
    if(textToShow == null){
      return ""
    }
    if(textToShow.length > 32){
      return textToShow.substring(0, 32) + "...";
    }else{
      return textToShow
    }
  }

  deleteItem(): void {
    this.itemDeleted.emit(this.item);
  }

  selectItem(): void {
    this.itemSelected.emit(this.item);
  }

  triggerItem(): void{
    this.itemTriggered.emit(this.item);
  }

  selectItemOpt(): void {
    //If importing testtaker from CSV context active, do not mark the avatars
    if(this.importingCSVTesttakers || this.item.original){
      return;
    }

    this.item.selected = !this.item.selected;
    this.selectItem();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
