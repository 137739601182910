<!--TOAST ELEMENTS-->
<p-toast position="top-center" key="toast-error-dialog" preventOpenDuplicates="true" class="toast-dialog">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-error pointer p-mx-3" (click)="closeToastError()">{{message.summary}}</button>
        </div>
    </ng-template>
</p-toast>

<p-toast position="top-center" key="toast-confirm-dialog" preventOpenDuplicates="true" class="toast-dialog">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-{{message.severity}} pointer p-ml-3 p-mr-1" (click)="closeConfirm()">{{'GENERAL.CANCEL' | translate}}</button>
            <button type="button" class="btn-toast-{{message.severity}} pointer p-ml-1 p-mr-3" (click)="applyConfirm()">{{'GENERAL.APPLY' | translate}}</button>
        </div>
    </ng-template>
</p-toast>

<!-- TITLE -->
<h3 class="p-text-center">{{'REPORTS.MENU-GET-ANOTHER-REPORT-TITLE'|translate}}</h3>
<br>

<!-- Build the report selector popup -->
<div class="" *ngFor="let test of testAndReportList;">

    <div class="test-title-report-popup">
        {{test.parentTest.test}} - {{test.title}} [{{test.locale|uppercase}}]
    </div>
        <br>

        <div *ngIf="checkIfTestIsRequested(test)">
            <custom-label [labelType]="'reporting'"></custom-label>
        </div>
        <div *ngIf="!checkIfTestIsRequested(test)">

            <div *ngFor="let report of test.reports;">

                <div *ngIf="(report.questionnaires.length > 0 && checkReportStatus(report) != reportStatus.requested) && !checkDiscontinuedReport(report) && (!partialReport || partialReport && report.parentReport_id === null)" class="p-field-radiobutton" [ngClass]="{ 'appendix': report.parentReport_id !== null, 'disabled': (report.parentReport_id !== null && !report.selectable) }">
                    <div class="checkbox-container">
                        <p-checkbox
                            name="{{report.id}}"
                            [binary]="true"
                            inputId="{{report.id}}"
                            *ngIf="!(report.parentReport_id !== null && !report.selectable)"
                            [disabled]="purchasingSpinner"
                            (onChange) = "saveReportSelection()"
                            [(ngModel)]="report.reportSelected" >
                        </p-checkbox>
                    </div>

                    <!--Name Report Label-->
                    <label class="p-d-flex p-ai-center " for="{{report.id}}">
                        <div class="p-checkbox-text-label">{{report.name}}
                            <span *ngIf="checkReportStatus(report) == reportStatus.partial">
                                ({{'REPORTS.REPORT-PARTIAL-PURCHASED-LABEL' | translate}}
                                {{getReportsCompleteNumber(report)}})
                            </span>
                            <span *ngIf="checkReportStatus(report) == reportStatus.complete">
                                ({{'REPORTS.REPORT-PURCHASED-LABEL' | translate}})
                            </span>
                        </div>
                    </label>

                    <!--Credits label-->
                    <label *ngIf="checkReportStatus(report) != reportStatus.complete" class="p-d-flex p-ai-center" for="{{report.id}}">
                        <span class="credit" *ngIf="showCredits">{{report.credits}} {{'REPORTS.CREDITS' | translate}}</span>
                    </label>

                    <!--Report Profile (Only works for MBTI)-->
                    <div *ngIf="report.extraInfo?.reportField && report.questionnaires.length == 1 && !report.questionnaires[0].reportingResult.selectedField" class="select-field-container p-ml-2">
                        <p-dropdown
                            #selectorReport
                            [options]="report.options"
                            (onShow)="loadReportDropdown(report)"
                            optionLabel="label"
                            optionValue="value"
                            class="custom-dropdown"
                            [disabled]="!report.questionnaires[0].reportingResult.served"
                            [(ngModel)]="report.questionnaires[0].selectedField"
                            [showClear]="true"
                            placeholder="{{'REPORTS.MBTI-SELECT' | translate}}">
                        </p-dropdown>
                    </div>

                    <!--Report Profile Selected (Only works for MBTI)-->
                    <div *ngIf="showSelectedSubReport(report)" class="selected-field p-ml-2">
                        <span #purchasedReport>{{report.questionnaires[0].reportingResult.selectedField}}*</span>
                    </div>

                    <span class="moreInfo" *ngIf="report.parentReport_id !== null">*</span>
                </div>
            </div>
        </div>
        <!-- Add Span between Test reports -->
        <br>
</div>


<!-- FOOTER -> PRICE & PURCHASE BUTTON -->
<div class="">
    <!-- Status of operation Labels, Purchasing In Progress.. / Error Generating Report -->
    <div *ngIf="purchasingSpinner" class="p-d-flex p-ai-center">
        <div *ngIf="purchasingSpinner" class="p-d-flex p-ai-center">
            <p-progressSpinner  [style]="{width: '50px', height: '50px'}" strokeWidth="4" class="custom-spinner spinner-margin" animationDuration="2s"></p-progressSpinner>
            {{'REPORTS.GENERATING-REPORTS' | translate}}
        </div>
        <div *ngIf="errorGeneratingReport" class="p-d-flex p-ai-center">
            <i class="pi pi-times iconFailed"></i>
            {{errorGeneratingMsg}}
            <br>
        </div>
    </div>

    <!-- Price cost text, and psyuser balance evaluation labels-->
    <div class="" *ngIf= "purchasingSpinner == false">
        <div class="" *ngIf="reportsToPurchase.length > 0 && !allSelectedReportsAlreadyPurchased">
            {{'REPORTS.REPORT-SELECTION-TOTAL-COST' | translate}}
            <strong *ngIf="showCredits">{{totalSelectionCredits}} {{'REPORTS.CREDITS' | translate}} </strong>
            <span *ngIf="showUses && showCredits">{{'REPORTS.OR' | translate}}</span>
            <strong *ngIf="showUses">{{totalSelectionPlanUses}} {{'REPORTS.PLAN-USES' | translate}} </strong>
            <div class="" *ngIf="!psyuserCanPurchaseSelection">
                <p class="negative-balance-text"> {{'REPORTS.NEGATIVE-BALANCE-TEXT' | translate}}</p>
            </div>
        </div>
        <br>
    </div>

    <!--MBTI Information messages-->
    <div class="p-text p-my-3" *ngIf="mbtiMsg" [innerHTML]="mbtiMsg">
    </div>

    <!-- Purchase Button -->
    <div class="p-text p-my-3" *ngIf="checkAllDisposable()">
        <div class="p-d-flex p-ai-center active-inactive-switch">
            <div class="selector p-text-center">
                <input type="checkbox"
                id="showAllDisposable"
                class="custom-checkbox"
                [(ngModel)]="onlyNewReports" >
            </div>
            <label for="showAllDisposable">{{'REPORTS.BTN-NEW-ONES'|translate}}</label>
        </div>
    </div>
    <span *ngIf="showAppendicesMessage()">* {{ 'REPORTS.INFO-APPENDICES' | translate }}</span>
    <div class="p-text-right p-my-3">

        <button
            pButton
            (click)="purchaseSelection()"
            label="{{'REPORTS.PURCHASE-BUTTON-LABEL' | translate}}"
            class="btn-primary"
            [disabled]="!psyuserCanPurchaseSelection || purchasingSpinner">
        </button>
    </div>

   
    <!-- END FOOTER -->
</div>
<br>




