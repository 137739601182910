<div class="content">
    <div class="p-d-flex p-jc-between p-ai-center">
        <span class="title">
            <span *ngIf="!isProfile">{{'DASHBOARD.WELCOME' | translate}}</span>
            <span class="username">{{userName}} <span *ngIf="isProfile">{{surname}} <span *ngIf="qualification" class="qualification">{{qualification}}</span></span></span>
        </span>
        <button
            pButton
            *ngIf="!isProfile"
            class="btn-mini2"
            [routerLink]="['/profile']"
            label="{{'DASHBOARD.PROFILE' | translate}}">
        </button>
    </div>

    <div class="p-grid credits-container" [ngClass]="{'credits-container-ex': showCredits && showUses}">
        <div class="credits p-d-flex p-ai-center p-jc-between" *ngIf="showCredits" [ngClass]="{'credits-reduced': showCredits && showUses}">
            <div class="p-d-flex p-ai-center">
                <div class="title">{{'DASHBOARD.CREDITS' | translate}}</div>
                <span class="credit-number pointer" [routerLink]="['/profile']" [queryParams]="{ index: '2'}" pTooltip="{{'DASHBOARD.DETAILS' | translate}}"
                    tooltipPosition="top">{{currentUserCredits}}</span>
            </div>
            <button pButton class="btn-primary" label="{{'DASHBOARD.BUY' | translate}}" (click)="buyCredits()"></button>
        </div>
        <div class="credits p-d-flex p-ai-center p-jc-between" *ngIf="showUses" [ngClass]="{'credits-reduced': showCredits && showUses}">
            <div class="p-d-flex p-ai-center">
                <div class="title">{{'DASHBOARD.USES' | translate}}</div>
                <span class="credit-number pointer"  [routerLink]="['/profile']" [queryParams]="{ index: '2'}"  pTooltip="{{'DASHBOARD.DETAILS' | translate}}"
                    tooltipPosition="top">{{currentUserUses}}</span>
            </div>
            <button pButton class="btn-primary" label="{{'DASHBOARD.BUY-USES' | translate}}" (click)="buyCredits()"></button>
        </div>
    </div>

    <div class="graphs p-d-flex">
        <div class="project-number p-d-flex p-flex-column p-jc-center" [ngClass]="{'project-number-reduced': showCredits && showUses}">
            <span class="number">{{listProjects.length}}</span>
            <span>{{'DASHBOARD.PROJECTS' | translate}}</span>
        </div>
        <div class="p-d-flex p-ai-center p-jc-between graph-container">
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataCreated" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countCreated}}</span>
                    <span class="label">{{'DASHBOARD.CREATED' | translate | uppercase}}</span>
                </div>
            </div>
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataAssigned" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countAssigned}}</span>
                    <span class="label">{{'DASHBOARD.ASSIGNED' | translate | uppercase }}</span>
                </div>
            </div>
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataInProgress" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countInProgress}}</span>
                    <span class="label">{{'DASHBOARD.IN-PROGRESS' | translate | uppercase }}</span>
                </div>
            </div>
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataPerformed" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countPerformed}}</span>
                    <span class="label">{{'DASHBOARD.COMPLETED' | translate | uppercase }}</span>
                </div>
            </div>
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataScored" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countScored}}</span>
                    <span class="label">{{'DASHBOARD.SCORED' | translate}}</span>
                </div>
            </div>
            <!-- <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataScoredNonValid" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countScoredNonValid}}</span>
                    <span class="label">{{'DASHBOARD.NON-VALID' | translate}}</span>
                </div>
            </div> -->
            <div class="graph p-col-12 p-lg-3 p-md-6">
                <p-chart type="doughnut" [data]="dataFinished" [options]="doughnutOptions" height="70px"></p-chart>
                <div class="partial-count p-d-flex p-flex-column p-ai-center">
                    <span class="number">{{countFinished}}</span>
                    <span class="label">{{'DASHBOARD.FINISHED' | translate | uppercase }}</span>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="showDialog">
    <div class="dialog-content">
        <h3>{{'CONTACT.MSG'|translate}}</h3>
        <div class="text">
            <p [innerHtml]="'CONTACT.DATA' | translate"></p>
        </div>
    </div>
</p-dialog>