<p-toast position="top-center" key="toast-sucess" preventOpenDuplicates="true"></p-toast>

<p-toast position="top-center" key="toast-warning" preventOpenDuplicates="true"></p-toast>

<p-toast position="dialog" key="toast-info" preventOpenDuplicates="true">
    <ng-template   let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-error pointer p-mx-3" (click)="closeInfo() ">{{'GENERAL.CLOSE' | translate}}</button>
             <div [innerHTML]="message.summary" class="p-ai-center p-jc-center" ></div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="top-center" key="toast-error" preventOpenDuplicates="true">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-error pointer p-mx-3" (click)="close()">{{message.summary}}</button>
        </div>
    </ng-template>
</p-toast>

<p-toast position="top-center" key="toast-confirm" preventOpenDuplicates="true">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <!--Enable cancel for summary == empty-->
            <button *ngIf="!message.summary" type="button" class="btn-toast-{{message.severity}} pointer p-ml-3 p-mr-1" (click)="closeConfirm()">{{'GENERAL.CANCEL' | translate}}</button>
            <button type="button" class="btn-toast-{{message.severity}} pointer p-ml-1 p-mr-3" (click)="applyConfirm()">{{'GENERAL.APPLY' | translate}}</button>
        </div>
    </ng-template>
</p-toast>