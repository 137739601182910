<!--EXPORT BUTTON-->
<div class="p-d-flex p-jc-end">
    <button pButton class="btn-mini3 p-mx-3" label="{{'ACCOUNTS.EXCEL'|translate}}" (click)="downloadHistory()">
    </button>
</div>

<!--FILTER-->
<app-filter 
    #filter 
    (applyFilter)="applyFilter($event)"
    (resetFilter)="resetFilter($event)"
    [displayAllItemsSelector]="false"
    [items]="originalHistoryGroup"
    [searchKeys]="[]"
    [filterByNumberOfSubitems]="false"
    [filterByActiveInactiveHistory]="filterByActiveInactiveHistory"
    [filterByDateRange]="true"
    [sortTypes]="['desc-creation-date','asc-creation-date']">
</app-filter>

<div class="history-container p-col-12 p-lg-6 p-mx-0" *ngIf="history?.length > 0">

    <!--Filter Count-->
    <div *ngIf="filter?.isFiltered" class="result-count">{{filterCount + ' ' + ('FILTER.RESULT' | translate)}}</div>

    <!--HISTORY-->
    <div *ngFor="let hist of listHistoryChunk" 
        class="history-content p-grid p-jc-center p-ai-center p-md-flex-column p-shadow-2"
        [ngClass]="{'inactive': !hist.isAlive && filterByActiveInactiveHistory}">
        <!--Date-->
        <div class="p-col-12 p-lg-2 p-md-12 p-d-flex p-jc-between p-ai-center created">            
            {{hist.createdAt | date: 'dd/MM/yyyy - HH:mm:ss'}}
        </div>
        <!--Test and Report names-->
        <div class="p-col-12 p-lg-4 p-md-12 p-d-flex p-ai-center p-jc-start">
            <div *ngIf="hist.test" class="p-d-flex p-flex-column">
                <span class="detail-label">
                    {{hist.test.parentTest.test}} - {{hist.test?.title}} [{{hist.test?.locale | uppercase}}]
                </span>
                <span class="detail-type">
                    {{hist.report?.name}}
                </span>
            </div>                    
        </div>
        <!--Transaction type and Comment-->
        <div class="p-col-12 p-lg-3 p-md-12 p-d-flex p-ai-center p-jc-between">
            <div *ngIf="!hist.testDetails" class="p-d-flex p-flex-column">                
                <span class="detail-label">
                    <i>#{{'ACCOUNTS.' + hist.transactionType | translate | lowercase}}</i>
                </span>
                <span class="detail-type detail-comment">
                    {{hist.comment}}
                </span>
            </div>
            <div class="history-icon p-mr-2">
                <div *ngIf="['INCREASE_CREDIT', 'DECREASE_CREDIT', 'REFUND_CREDIT'].includes(hist.transactionType)">
                    <i class="pi pi-credit-card"></i>    
                </div>
                <div *ngIf="['REFUND_USE','DECREASE_USE'].includes(hist.transactionType)">                    
                    <i *ngIf="hist.customerHasPlan?.plan.deduction.scoring" class="pi pi-cog"></i>
                    <i *ngIf="hist.customerHasPlan?.plan.deduction.reporting" class="pi pi-file"></i>
                    <i *ngIf="hist.customerHasPlan?.plan.deduction.administration" class="pi pi-envelope"></i>
                </div>
            </div>
        </div>
        <!--Weight-->
        <div class="p-col-12 p-lg-3 p-md-12 p-d-flex p-ai-center p-jc-between">            
            <div class="weight" [ngSwitch]="hist.status">
                
                <div *ngSwitchCase="'zero'">
                    <span class="p-mr-3">
                        {{hist.weight}} {{getTranslationType(hist) | lowercase}}
                    </span>
                </div>
                    
                <div *ngSwitchCase="'positive'">
                    <span class="p-mr-3 add">
                        {{hist.weight}} {{getTranslationType(hist) | lowercase}}
                    </span>
                </div>
                
                <div *ngSwitchCase="'negative'">
                    <span class="p-mr-3 sub">
                        {{hist.weight}} {{getTranslationType(hist) | lowercase}}
                    </span>
                </div>
            </div>
            <!--Subcription reference-->
            <div class="p-d-flex p-flex-column">
                <span class="detail-type">
                    {{hist.customerHasPlan?.plan.name}}
                </span>
                <span class="suscription-id" *ngIf="hist.customerHasPlan">
                    {{('ACCOUNTS.SUBSCRIPTION' | translate) + ' ' + (hist.customerHasPlan.id | padNumber : 8)}}
                </span>
            </div>
        </div>
    </div>

    <!--PAGINATION-->
    <p-paginator 
        #paginator
        [rows]="rowsToDisplayDefault"
        [totalRecords]="history.length" 
        [rowsPerPageOptions]="[5,10,20,50]"
        [showCurrentPageReport]="true" 
        currentPageReportTemplate = "{{('ACCOUNTS.ROW-COUNT-1' | translate) + topRow + ('ACCOUNTS.ROW-COUNT-2' | translate)}}"
        (onPageChange)="paginate($event)">
    </p-paginator>
</div>

<!--NO HISTORY-->
<div class="history-container p-col-12 p-lg-6 p-mx-0" *ngIf="history?.length <= 0">
    <app-empty-template  
        [text]="('ACCOUNTS.NO-HISTORY' | translate)">
    </app-empty-template>
</div>
