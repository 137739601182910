<div *ngIf="(loaded|async)">
  <quill-editor 
  [(ngModel)]="content" 
  (onContentChanged)="changeHtml($event)" 
  (onEditorCreated)="created($event)"
  [styles]="{'height': '150px'}" 
  #qEditor>
    <div quill-editor-toolbar>
      <!-- <span class="ql-formats">
        <select class="ql-font" [title]="'Bold'"></select>
      </span> 
      <span class="ql-formats">
        <select class="ql-size" [title]="'Size'"></select>
      </span> -->
      <span class="ql-formats">
        <select class="ql-color" [title]="'Color'">
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold" [title]="'Bold'"></button>
      </span>
      <!-- <span class="ql-formats">
        <select class="ql-align" [title]="'Aligment'">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span> -->
      <span class="ql-formats">
        <button class="ql-italic" [title]="'Italic'"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-underline" [title]="'Underline'"></button>
      </span>
    </div>
  </quill-editor>
</div>
<div *ngIf="!(loaded|async)" class="loader p-d-flex p-ai-center p-jc-center">
  <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>
