<div class="p-grid detail-content" *ngIf="loaded">
  <div class="detail-container p-col-12 p-lg-6 p-mx-auto p-shadow-2">

    <!--PsyUser Details Contenr-->
    <div class="detail-data p-mr-lg-2">
        <h1>{{psyuser.name}} {{psyuser.surname}}</h1>
        <hr>
        <div class="detail-subtitle">{{'DETAIL-PEOPLE.INFO' | translate}}</div>
        <div class="detail-label">{{'DETAIL-PEOPLE.ADDED-IN' | translate}}: <span class="detail-data">{{psyuser.createdAt | date: 'dd/MM/yyyy'}}</span></div>
        <div class="detail-label">{{'ACCOUNTS.QUALIFICATION' | translate}}: <span class="detail-data">{{psyuser.qualification}}</span></div>
        <div class="detail-label">{{'DETAIL-PEOPLE.EMAIL' | translate}}: <span class="detail-data">{{psyuser.email}}</span></div>
        <div class="detail-label">{{'ACCOUNTS.PHONE' | translate}}: <span class="detail-data">{{psyuser.phone}}</span></div>
        <span class="detail-label" [ngSwitch]="psyuser.isAdmin">
            {{'DETAIL-PEOPLE.PROFILE' | translate }}            
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER">
                {{'DETAIL-PEOPLE.PROFILE-USER' | translate }}
                <div class="detail-help p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-USER-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER_ADMIN">
                {{'DETAIL-PEOPLE.PROFILE-ADMIN' | translate }}
                <div class="detail-help p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER_FULL">
                {{'DETAIL-PEOPLE.PROFILE-ADMIN-FULL' | translate }}
                <div class="detail-help p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-FULL-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.ASSISTANT">
                {{'DETAIL-PEOPLE.PROFILE-ASSISTANT' | translate }}
                <div class="detail-help p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ASSISTANT-HELP' | translate"></div>
            </span>
        </span>

        <div class="btn-group p-d-flex p-jc-between">
            <button 
                *ngIf="[psyUserRoles.PSYUSER_FULL, psyUserRoles.ASSISTANT].includes(currentPsyUser.isAdmin)"
                type="button" 
                class="btn-primary p-my-2"
                pButton 
                [routerLink]="['/admin','edit']"
                [queryParams]="{type: 'EDIT'}"
                label="{{'DETAIL-PEOPLE.BTN-MODIFICATION' | translate }}">
            </button>   
            <button 
                *ngIf="psyuser_id != psyuser.id"
                type="button" 
                class="btn-secondary p-my-2" 
                pButton
                (click)="confirmDeletePsyuser()"
                label="{{'DETAIL-PEOPLE.BTN-DELETE' | translate }}">
            </button>
        </div>
    </div>
  </div>
    <div class="groups-container p-col-12 p-lg-6 p-mx-auto">

        <!--PsyUser Subscriptions-->
        <div class="no-plans">
            <app-empty-template *ngIf="psyuserSubscriptionsToShow?.length <= 0" 
                [text]="('ACCOUNTS.NO-PLANS' | translate)">
            </app-empty-template>
        </div>

        <div class="plan_container" *ngIf="psyuserSubscriptionsToShow?.length > 0">

            <app-plan-card *ngFor="let _plan of psyuserSubscriptionsToShow" 
                [ngClass]="{'inactive': !_plan.isAlive}"
                [plan]="_plan" [actions]="true" 
                (showPlanEvent)="showPlan(_plan)"
                (showEditCreditEvent)="showCreditPopUp(_plan)"
                (deleteSubscriptionEvent)="deassignPsyuser(_plan.psyuserSub_id)" >
            </app-plan-card>           
        </div>

        <!--Show Pop-up to add customer subscriptions-->
        <button
            *ngIf="customerSubscriptions.length > 0"
            type="button" 
            class="btn-primary p-mx-auto p-my-2 agg_plan"
            pButton 
            (click)="showAvailablePlans=true"
            label="{{'ACCOUNTS.ASSIGN-NEW-PLAN' | translate | uppercase}}">
        </button>
    </div>

    <!--PsyUser History-->
    <h3 class="history-title">{{('ACCOUNTS.HISTORY' | translate)}}</h3>
    <app-history 
        #history 
        [history]="psyuserSubscriptionsHistory" 
        [psyUserSubscriptions]="psyuserSubscriptions"
        [psyUserName]="psyuser.name + ' ' + psyuser.surname" 
        *ngIf="showHistory" 
        class="history"
        [filterByActiveInactiveHistory]="true">
    </app-history>
</div>

<!--POP-UP: Customer subscriptions -->
<p-dialog [(visible)]="showAvailablePlans" [modal]="true" header="{{'ACCOUNTS.PLANS'|translate}}" [style]="{width:'70vw'}">    
    <app-plan-card *ngFor="let plan of customerSubscriptions" 
        [plan]="plan" [showAssign]="true" 
        (showPlanEvent)="showPlan(plan)"
        (addSubscriptionEvent)="assignPsyuser(plan.sub_id)">
    </app-plan-card>
</p-dialog>

<!-- POP-UP: Manage Legacy Credits Management -->
<p-dialog [(visible)]="showLegacyPopUp" [modal]="true" [style]="{height: '650px'}" class="credits-dialog">
    <h3 class="p-text-center">{{('ACCOUNTS.MANAGE-LEGACY' | translate)}} </h3>

    <h4 class="p-d-flex p-jc-center p-ai-center">        
        <span class="company-name">{{customerName}} {{('ACCOUNTS.CREDITS' | translate )}}: </span> 
        <span class="company-credits p-ml-2">{{customerCredits}}</span>
    </h4>

    <div class="credits p-d-flex p-flex-column p-ai-center p-jc-between">
        <div class="title">{{'DASHBOARD.CREDITS' | translate}} {{psyuser?.name | uppercase}}</div>
        <span class="credit-number">{{psyUserCredits}}</span>
        <div class="p-d-flex p-ai-center p-mt-2 btn-credits">
            <button pButton (click)="changeValue(false)" label="-"></button>
            <input class="input-credits" type="number" [(ngModel)]="inputValue" (input)="inputChanged()">
            <button pButton (click)="changeValue(true)" label="+"></button>        
        </div>
    </div>
    
    <div class="p-d-flex p-jc-center p-my-5">
        <button pButton (click)="saveCredits()" [disabled]="buttonDisabled" class="btn-primary" label="{{('ACCOUNTS.SAVE' | translate | uppercase)}}"></button>
    </div>
</p-dialog>

<!-- POP-UP: Plan Info-->
<app-popup-detail [(showPopUp)]="showPlanInfo" [planToShow]="planToShow"></app-popup-detail>