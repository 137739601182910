import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { inAnimation } from 'src/app/shared/animations/in-out-animation';

@Component({
  selector: 'app-input-score',
  templateUrl: './input-score.component.html',
  styleUrls: ['./input-score.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  animations: [inAnimation]
})
export class InputScoreComponent implements OnInit {

  @Input() score: any;
  @Input() myFormGroup: any;
  @Input() maskEditable;
  disabled: boolean = false;


  constructor( ) { }

  ngOnInit(): void {
    if(this.score.type === 'itemRange'){
      //Set selectedRange if exists
      let rangeName = this.score.id + '_selectedRange';
      let aux = this.myFormGroup.entityObject[rangeName];
      this.score.selectedRange = aux;
      if(this.score.selectedRange && !this.score.fixedLimit){
        this.getMaxValueForItemRange(this.score.selectedRange);
      }
      this.getRangesLabels();
    }
  }

  getMaxValueForItemRange(range) {
    if (!this.score.fixedLimit) {
      let maxValue = range.finishValue - range.initValue + 1;
      this.score.maxVal = Number(maxValue);
    }
    this.score.selectedRange = range;
  }

  getRangesLabels(){
    this.score.ranges = this.score.ranges.filter(_range => _range !== null);
    for (let _range of this.score.ranges){
        _range['label'] = `${_range.initValue} - ${_range.finishValue}`;
    }
  }


}
