import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './core/authentication/authentication.service';
import { ProjectsApi } from './modules/projects/api/projects.api';
import { ScoringService } from './modules/projects/services/scoring.service';

import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { GAnalyticsService } from './shared/services/ganalytics.service';
import { LoggedGuard } from './shared/guards/logged.guard';

//Google analytics
// Use 127.x.x.x to test. localhost won't work
declare const gtag: Function;
declare const Cookiebot: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})

export class AppComponent {

  constructor(private authSrv: AuthenticationService, private scoringSrv: ScoringService, private projectsApi: ProjectsApi, private router: Router,  private gAnalytics:GAnalyticsService) 
  {
    this.observeCookiebot()
  }

  isAuthenticated(): boolean {
    return this.authSrv.isAuthenticated();
  }

  //Create the scripts required for google analytics
  createGAScriptInHtml():void{
    // register google tag manager
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTag}`;
    document.head.appendChild(gTagManagerScript);
      
    // register google analytics
    const gaScript = document.createElement('script');
    // Same as analytics script but with send_page_view: false to avoid duplicity
    gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsTag}', {'send_page_view': false});
    `;
    document.head.appendChild(gaScript);
  }

  /**
   * Modify the cookiebot script and create google analytics script when the user accept the cookies
   *  Cookiebot is loaded with the ngx-cookie-service library.
   * The loaded element event is tracked here.
   */
  observeCookiebot() {

    let target = document.querySelector('body');
    let mThis=this;

    //Triggers DOM mutation events. 
    let observer = new MutationObserver(function(mutations) {
      mutations.forEach((mutation)=> {
        //Trigger added node with the cookiebot dialog id
        if(mutation.addedNodes.length>0&&mutation.addedNodes[0]['id']==='CybotCookiebotDialog'){
          mThis.injectLinkInCookiebot();
          observer.disconnect();
        }
      });
    });

    let config = { attributes: true, childList: true, characterData: true };

    observer.observe(target, config);

    window.addEventListener('CookiebotOnAccept', function (e) {
      if (Cookiebot.consent.statistics) 
          {
            mThis.createGAScriptInHtml();
            mThis.gAnalytics.startTracking();
          } 
      }, false);
    
  }

  //Inject the cookies page link in cookiebot
  injectLinkInCookiebot(){

    let supportedParentAndChildClass={
      'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper':'CybotCookiebotDialogBodyButton',
      'CybotCookiebotDialogBodyButtonsWrapper':'CybotCookiebotDialogBodyButton',
    };

    const link=document.createElement('button');
    link.onclick=function () {
      location.href = `${location.origin}/cookies`;
    };

    link.innerHTML='Our Cookies';

    // inject link in cookiebot

    let supported=false;
    let container;
    // Check if the case is supported
    for(let parentId in supportedParentAndChildClass){
      container=document.getElementById(parentId);
      if(container){
        link.className=supportedParentAndChildClass[parentId];
        supported=true;
        break;
      }
    }
    
    if(supported){
      container.appendChild(link);
    }
  }
}

