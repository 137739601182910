import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-data-component',
  templateUrl: './project-data-component.component.html',
  styleUrls: ['./project-data-component.component.scss']
})

export class ProjectDataComponentComponent implements OnInit {
  constructor(
    private toastSrv: ToastService,
    private translate: TranslateService,
    private projectsApi: ProjectsApi
  ) { }

  @Input() projectId: number;
  @Input() groupalProject: boolean;
  @Output() sendEmailEvent = new EventEmitter<void>();
  @Output() deleteProjectEvent = new EventEmitter<void>();
  @Output() addUsers = new EventEmitter<void>();

  protected subs = new SubSink();

  public project: any = {
    code: '',
    createdAt: new Date(),
    supply: '',
    sequence: '',
    isMigrated: '',
    status: '',
    entryType_id: ''
  };

  protected loading = true;
  protected ProjectStatusString = ProjectStatusString;
  protected displayChangeName: boolean = false;

  protected projectCode: string = '';

  ngOnInit(): void {
    this.loading = true;
    this.getProject();
  }

  private getProject() {
    this.subs.sink = this.projectsApi.getProjectInfo(this.projectId).subscribe({
      next: (value) => {
        this.project = { ...value.data };
        this.projectCode = this.project.code;
      },
      error: (error) => {
        this.toastSrv.closeToastError();
        this.toastSrv.showToastError(error.message)
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
  protected changeName(): void{
    this.subs.sink = this.projectsApi.updateProjectCode(this.projectId, this.projectCode).subscribe({
      next: () => this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-NAME-OK')),
      error: ({ error }) => this.toastSrv.showToastError(error.message),
      complete: () => { this.displayChangeName = false; this.project.code = this.projectCode; }
    });
  }

  protected displayChangeNameEvent = (display: boolean) => this.displayChangeName = display;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
