import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { AuthorizedGuard } from 'src/app/shared/guards/authorized.guard';
import { AccountsComponent } from './components/accounts/accounts.component';
import { EditPsyuserComponent } from './components/edit-psysuer/edit-psyuser.component';
import { ManagePlanComponent } from './components/manage-plan/manage-plan.component';

const routes: Routes = [
  {
    path: '',
    component: AccountsComponent
  },
  {
    path: 'edit',
    component: EditPsyuserComponent,
    canActivate: [AuthorizedGuard],
    data: { 
      role: [PsyUserRoles.PSYUSER_FULL, PsyUserRoles.ASSISTANT]
    } 
  },
  {
    path: 'manage',
    component: ManagePlanComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
