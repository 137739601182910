import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-chart-component',
  templateUrl: './chart-component.component.html',
  styleUrls: ['./chart-component.component.scss']
})
export class ChartComponentComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private toastSrv: ToastService,
    private projectsApi: ProjectsApi
  ) { }

  @Input() projectId: number;

  private subs = new SubSink();

  protected ProjectStatusString = ProjectStatusString;
  protected project = {
    status: '',
    finalizedTests: 0,
    count: { created: 0, assigned: 0, inProgress: 0, performed: 0, finished: 0, finishedNonValidScoring: 0, scored: 0 }
  };
  protected chartData = { labels: [], datasets: [] };

  loaded = false;

  plugins = []

  protected chartOptions = {
    cutout: 68,
    borderRadius: 20,
    plugins: {
      legend: {
        position: 'right',
        align: 'middle',
        labels: {
          usePointStyle: true,
          fontSize: 14,
          fontFamily: 'MuseoSans',
          fontColor: '#004379'
        }
      }
    }
  };

  private initChartData() {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.chartData = {
        labels: [
          `${this.project.count.created} ${this.translate.instant('PROJECTS.GR-CREATED')}`,
          `${this.project.count.assigned} ${this.translate.instant('PROJECTS.GR-ASSIGNED')}`,
          `${this.project.count.inProgress} ${this.translate.instant('PROJECTS.GR-IN-PROGRESS')}`,
          `${this.project.count.performed} ${this.translate.instant('PROJECTS.GR-PERFORMED')}`,
          `${this.project.count.scored} ${this.translate.instant('PROJECTS.GR-SCORED')}`,
          `${this.project.count.finished} ${this.translate.instant('PROJECTS.GR-FINISHED')}`,
          `${this.project.count.finishedNonValidScoring} ${this.translate.instant('PROJECTS.GR-FINISHED-NON-VALID-SCORING')}`
        ],
        datasets: [{
          data: [this.project.count.created, this.project.count.assigned, this.project.count.inProgress, this.project.count.performed, this.project.count.scored, this.project.count.finished, this.project.count.finishedNonValidScoring],
          backgroundColor: ['#e8f0fa', '#fcdfa5', '#fca902', '#86d0f5', '#006EB6', '#004379', '#ff276a'],
          hoverBackgroundColor: ['#e8f0fa', '#fcdfa5', '#fca902', '#86d0f5', '#006EB6', '#004379', '#ff276a'],
          hoverBorderColor: ['#e8f0fa', '#fcdfa5', '#fca902', '#86d0f5', '#006EB6', '#004379', '#ff276a'],
          hoverBorderWidth: 5
        }],
      };

      // TODO: Valid for reporting and dashboard donuts
      // Set plugin to show label and number in the middle of doughnut
      this.plugins = [{
        id: 'text',
        beforeDatasetsDraw: (chart) => {
          const {ctx} = chart;

          ctx.save();
          // Get data coordenates
          const xCoor = chart.getDatasetMeta(0).data[0].x;
          const yCoor = chart.getDatasetMeta(0).data[0].y;

          // Set global text style
          ctx.fillStyle = '#006eb6';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          // Set number value, style and position
          ctx.font = '300 40px MuseoSans';
          ctx.fillText(this.project.finalizedTests, xCoor, yCoor-10);

          // Set label value, style and position
          ctx.font = '300 14px MuseoSans';
          ctx.fillText(this.translate.instant('PROJECTS.GR-FINISH'), xCoor, yCoor+20);
        }
      }];

      this.loaded = true;
    });
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsApi.getQuestionnaireStatusSummary(this.projectId).subscribe({
      next: (value) => {
        this.project = { ...value.data };
        this.initChartData();
      },
      error: (error) => {
        this.toastSrv.closeToastError();
        this.toastSrv.showToastError(error.message)
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
