<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
    <!-- Spinner -->
    <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>


<!--FILTER-->
<div class="filter" [hidden]="!loaded">
    <app-filter 
        #filter             
        (applyFilter)="applyFilter($event)"
        (resetFilter)="resetFilter($event)"
        [items]="customerPsyUsers"
        [searchKeys]="['name','email']"    
        [filterByNumberOfSubitems]="false"
        [sortTypes]="['az-name','za-name']">
    </app-filter>
</div>


<div class="container_users" *ngIf="loaded">    
    <div class="info_customer">        
        <div class="p-d-flex p-ai-center">
            <!--Customer Name-->
            <span class="cust_name p-mr-4">{{customerName}}</span>

            <!--Suscriptions-->
            <div>
                <span *ngIf="availablePlans2.length <= 0">
                    [{{'ACCOUNTS.NO-PLANS' | translate}}]
                </span>   
                
                <button         
                    pButton
                    *ngIf="availablePlans2.length > 0"
                    class="btn-mini2" 
                    (click)="showCustomerSubscriptions=true"
                    label="{{'ACCOUNTS.SHOW-SUBSCRIPTIONS' | translate}}">
                </button>            
            </div>
        </div>
        
        <!--Credits/Uses Resume-->
        <div class="p-d-flex p-ai-center" *ngIf="customerConsumesInfo">
            <p-fieldset legend="{{'PROFILE.CREDITS' | translate}}" class="p-mx-2" *ngIf="customerConsumesInfo.credits.totalPurchased > 0 
                || customerConsumesInfo.credits.totalConsumption > 0 || customerConsumesInfo.credits.totalBalance > 0 
                || customerConsumesInfo.credits.totalAvailableToAssign > 0">
                <table>
                    <tr>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.PURCHASED' | translate}} </span>{{customerConsumesInfo.credits.totalPurchased}}
                            </div>
                        </td>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.CONSUMED' | translate}} </span>{{customerConsumesInfo.credits.totalConsumption}}
                            </div>
                        </td>                        
                    </tr>
                    <tr>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.BALANCE' | translate}} </span> {{customerConsumesInfo.credits.totalBalance}}
                            </div>
                        </td>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.ASSIGNABLE' | translate}} </span>{{customerConsumesInfo.credits.totalAvailableToAssign}}    
                            </div>
                        </td>
                    </tr>
                </table>
            </p-fieldset>

            <p-fieldset legend="{{'PROFILE.USES' | translate}}" class="p-mx-2" *ngIf="customerConsumesInfo.uses.totalPurchased > 0 
                || customerConsumesInfo.uses.totalConsumption > 0 || customerConsumesInfo.uses.totalBalance">
                <table>
                    <tr>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.PURCHASED' | translate}} </span>{{customerConsumesInfo.uses.totalPurchased}}
                            </div>
                        </td>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.CONSUMED' | translate}} </span>{{customerConsumesInfo.uses.totalConsumption}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="p-d-flex p-jc-between">
                                <span>{{'ACCOUNTS.BALANCE' | translate}} </span>{{customerConsumesInfo.uses.totalBalance}}
                            </div>
                        </td>
                    </tr>
                </table>
            </p-fieldset>
        </div>        
    </div>
    
    <h2>{{'ACCOUNTS.NAME' | translate}}</h2>

    <!--Filter Count-->
    <div *ngIf="filter?.isFiltered" class="result-count">{{filter?.filteredCount + ' ' + ('FILTER.RESULT' | translate)}}</div>
    
    <app-empty-template *ngIf="customerPsyUsers.length == 0" 
        [text]="('EMPTY.GENERAL' | translate)">
    </app-empty-template>    

    <div *ngIf="customerPsyUsers.length > 0" >
        <!--USERS LIST-->
        <div class="user-card" 
            *ngFor="let item of listUserChunk; let i=index">
            <div class="user-card-content p-grid p-jc-center p-md-flex-column pointer">
                <div class="p-col-12 p-lg-4 p-md-12 p-d-flex p-ai-center">
                    <!--Name-->
                    <div *ngIf="item.name && item.surname; then thenTmp else elseTmp"></div>
                    <ng-template #thenTmp class="">
                        <p-avatar 
                            label="{{item.name.charAt(0)}}" 
                            class="p-mx-3 "
                            [ngClass]="{'circle-selected': item.selected}"
                            styleClass="p-mr-2.5" 
                            size="xlarge" 
                            shape="circle">
                        </p-avatar>
                    </ng-template>
                    <ng-template #elseTmp class="">
                        <p-avatar 
                            icon="pi pi-user"
                            class="p-mx-3 "
                            [ngClass]="{'circle-selected': item.selected}"
                            styleClass="p-mr-2.5" 
                            size="xlarge" 
                            shape="circle">
                        </p-avatar>
                    </ng-template>
                    <div class="p-d-flex p-flex-column">
                        <span class="user-card-name p-d-flex p-ai-center" (click)="managePsyUser(item)">
                            {{item.name}} {{item.surname}}
                        </span>

                        <div class="profile-name-role p-d-flex p-ai-center p-jc-center" *ngIf="isAdmin !== psyUserRoles.PSYUSER" [ngSwitch]="item.isAdmin">
                            <span *ngSwitchCase="psyUserRoles.PSYUSER">{{'DETAIL-PEOPLE.PROFILE-USER' | translate }}</span>
                            <span *ngSwitchCase="psyUserRoles.PSYUSER_ADMIN">{{'DETAIL-PEOPLE.PROFILE-ADMIN' | translate }}</span>
                            <span *ngSwitchCase="psyUserRoles.PSYUSER_FULL">{{'DETAIL-PEOPLE.PROFILE-ADMIN-FULL' | translate }}</span>
                            <span *ngSwitchCase="psyUserRoles.ASSISTANT">{{'DETAIL-PEOPLE.PROFILE-ASSISTANT' | translate }}</span>
                        </div>
                    </div>
                </div>
                <!--Credits-->
                <div class="credits">
                    <div class="p-d-flex p-jc-between p-ai-center" [ngClass]="{'credit': item.numCredits !== undefined && item.numCredits !== null}">
                        <span *ngIf="item.numCredits !== undefined && item.numCredits !== null">
                            {{item.numCredits}} {{'REPORTS.CREDITS' | translate | uppercase}}
                        </span>
                    </div>
                </div>
                <!--Email-->
                <div class="p-col-12 p-lg-3 p-md-12 p-d-flex p-jc-between p-ai-center">
                    <div class="p-d-flex p-flex-column ">
                        <span class="user-card-email">{{item.email}}</span>
                        <span class="user-card-created ">
                            {{'PEOPLE.ADDED'| translate}} {{item.createdAt | date: 'dd/MM/yyyy'}}
                        </span>
                    </div>
                </div>

                <!--Plans-->
                <div class="p-col-12 p-lg-1 p-md-12 p-d-flex p-jc-between p-ai-center">                    
                    <app-users-groups-circles [listItems]="item.plans" [type]="'user'"></app-users-groups-circles>
                </div>

                <div class="p-col-12 p-lg-2 p-md-12 p-d-flex p-ai-center p-jc-end user-card-actions clickable">
                    <button 
                        type="button" 
                        class="btn-mini" 
                        pButton
                        (click)="managePsyUser(item)"
                        label="{{'ACCOUNTS.MANAGE' | translate}}">
                    </button>
                    <i class="pi pi-ellipsis-v btn-secondary-menu pointer p-ml-5"
                    *ngIf="[psyUserRoles.PSYUSER_FULL, psyUserRoles.ASSISTANT].includes(psyuser.isAdmin)"
                    (click)="menu_people.toggle($event)"></i>
                    <p-menu #menu_people class="people-menu" [popup]="true" [model]="generateMenu(item)"></p-menu>
                </div>
            </div>
        </div>

        <!--PAGINATION-->
        <p-paginator 
            #paginator
            [rows]="rowsToDisplayDefault"
            [totalRecords]="customerPsyUsers.length" 
            [rowsPerPageOptions]="[5,10,20,50]"
            [showCurrentPageReport]="true" 
            currentPageReportTemplate = "{{('ACCOUNTS.ROW-COUNT-1' | translate) + topRow + ('ACCOUNTS.ROW-COUNT-2' | translate)}}"
            (onPageChange)="paginate($event)">
        </p-paginator>        
    </div>
    <!--FLOAT BUTTON-->
    <button
        *ngIf="[psyUserRoles.PSYUSER_FULL, psyUserRoles.ASSISTANT].includes(psyuser.isAdmin)"
        type="button" 
        class="btn-float p-shadow-3" 
        pButton
        icon="pi pi-plus" 
        iconPos="right"
        label="{{'ACCOUNTS.CREATE-PSY' | translate}}"
        (click)="goToCreateNew()">
    </button>
</div>

<!-- Pop Up plan details -->
<p-dialog [(visible)]="showCustomerSubscriptions" [modal]="true" header="{{'ACCOUNTS.SUBSCRIPTIONS'|translate}}" [style]="{width:'70vw'}">    
    <app-plan-card *ngFor="let plan of availablePlans2" [plan]="plan" (showPlanEvent)="showPlan(plan)"></app-plan-card>
</p-dialog>

<!-- Pop Up info plan (POP-UP)-->
<app-popup-detail [(showPopUp)]="showPlanInfo" [planToShow]="planToShow"></app-popup-detail>