import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { SubSink } from 'subsink';
import { ScoringService } from 'src/app/modules/projects/services/scoring.service';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';


@Component({
  selector: 'app-scoring-psyquestions-template',
  templateUrl: './scoring-psyquestions-template.component.html',
  styleUrls: ['./scoring-psyquestions-template.component.scss']
})

export class ScoringPsyuserFormularyTemplateComponent implements OnInit, OnDestroy{

  @Input() psyUserQuestionsForTest: any = [];
  @Input() relatedTestQuestionnaires: any = [];
  @Output() scoringSuccessfull = new EventEmitter<any>();
  @Output() scoringFailed = new EventEmitter<any>();

  psyUserForm: UntypedFormGroup;
  generateFormularyForPsyuser = false;
  generateNonGroupedQuestionsFormulary = false;
  generateGroupedQuestionsFormulary = false;
  questionnairesToScore= [];

  //Spinner if Scoring
  scoringSpinner = false;

  //Disable button flag
  public scoreButtonDisabled = true

  //Message loading
  messageLoading ="   ......."

  subs = new SubSink();
  generateScoringReportingQuestionsFormulary: boolean;

  constructor(
    private scoringSrv: ScoringService,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private formBuilder: RxFormBuilder,
    private localToastSrv: MessageService,
    private errorsSrv:ErrorsService,
    private projectsSrv: ProjectsService,
    private projectsApi: ProjectsApi
  ) { }

  ngOnInit(): void {
    this.checkIfFormularyisNeededForScoring()

    if(this.generateFormularyForPsyuser){
      this.generateItemsForm();
      this.generateMessages();
      this.subs.sink = this.psyUserForm.valueChanges.subscribe(() => {
        if(this.psyUserForm != null){
          this.checkScoreButtonDisabled()
        }
      });
    }
  }

  /**
   * The popup needs some extra logic on destroy (Not working)
   */
  ngOnDestroy(): void {
    // console.log("Report popup closed")
  }

  /**
   * Check if the button to Score has to be disabled, during a request and
   * while the formulary is not completely fulfilled
   * @returns true/false, disabling/enabling the button
   */
  checkScoreButtonDisabled(): void{

    //Check validness of psyuser form
    if(!this.psyUserForm?.invalid){
      this.scoreButtonDisabled = false;
    }else{
      this.scoreButtonDisabled = true;
    }

    //This overrides everything and block button while making requests
    if(this.scoringSpinner){
      this.scoreButtonDisabled = true;
    }
  }

  /**
   * Evaluate the test received answers for the psychologist
   * If there are questions, show that part, if not avoid construction
   * of the formulary without items.
   * @returns void
   */
  private checkIfFormularyisNeededForScoring(): void {
    this.generateFormularyForPsyuser = false;
    this.generateGroupedQuestionsFormulary = false;
    this.generateNonGroupedQuestionsFormulary = false;
    this.generateScoringReportingQuestionsFormulary = false;
    for (let psyQuestionsForTest of this.psyUserQuestionsForTest){

      if(psyQuestionsForTest.testSharedNonGroupedQuestions?.length != 0){
        this.generateNonGroupedQuestionsFormulary = true;
      }

      if(psyQuestionsForTest.testSharedGroupedQuestions?.length != 0){
        this.generateGroupedQuestionsFormulary = true
      }

      if(psyQuestionsForTest.scoringReportingQuestions?.length != 0){
        this.generateScoringReportingQuestionsFormulary = true
      }

      if(this.generateGroupedQuestionsFormulary || this.generateNonGroupedQuestionsFormulary || this.generateScoringReportingQuestionsFormulary){
        this.generateFormularyForPsyuser = true
      }
    }

  }

  /**
   * Generate formulary items for psyuser from the questions list received
   * from the server
   */
  private generateItemsForm(): void {
    let groupForm = {};
    this.generateFormularyForPsyuser = false;

    // console.log("Building formulary items from: ",this.psyUserQuestionsForTest)

    // Traverse the psyuser questions for test, non grouped questions pack

    for (let psyQuestionsForTest of this.psyUserQuestionsForTest){
      if (psyQuestionsForTest.testSharedNonGroupedQuestions.length > 0){
        psyQuestionsForTest.testSharedNonGroupedQuestions.forEach(_question => {
          _question.name = _question.name + "#profileData#" + psyQuestionsForTest.test_id;
          this.buildForm(_question, groupForm)
        });
      };
    };

    for (let psyQuestionsForTest of this.psyUserQuestionsForTest){
      if (psyQuestionsForTest.scoringReportingQuestions.length > 0){
        psyQuestionsForTest.scoringReportingQuestions.forEach(_question => {
          _question.name = _question.name + "#scoringReporting#" + psyQuestionsForTest.test_id;
          this.buildForm(_question, groupForm);
        });
      };
    };


    // Traverse the psyuser questions for test, this time the grouped questions pack
    for (let psyQuestionsForTest of this.psyUserQuestionsForTest){
      if (psyQuestionsForTest.testSharedGroupedQuestions.length > 0){
        psyQuestionsForTest.testSharedGroupedQuestions.forEach(group => {
        this.generateFormularyForPsyuser = true;

        group.items.forEach(_question => {
          _question.name = _question.name + "#profileData#" + psyQuestionsForTest.test_id;
          this.buildForm(_question, groupForm);
        });
      })};
    };
    this.psyUserForm = this.formBuilder.group(groupForm);

  }

  /**
   * Build formulary items from each type of sharedQuestion
   */
  private buildForm(_question, groupForm){
    this.generateFormularyForPsyuser = true;
    const validators = [];

    if (_question.type === 'number'){
      validators.push(RxwebValidators.numeric());
    }
    if (_question.type === 'select'){
      _question.options.forEach(z => {
        z.label = z.label + '';
      });
    }

    //All required
    validators.push(RxwebValidators.required());
    _question.mandatory = true;

    groupForm[_question.name] = ['', validators];
  }

  /**
   * Generate messages for validation fields.
   */
  generateMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            numeric: this.translate.instant('VALIDATIONS.NUMERIC'),
            minNumber: this.translate.instant('VALIDATIONS.MIN-NUMBER'),
            maxNumber: this.translate.instant('VALIDATIONS.MAX-NUMBER')
          }
        });
    });
  }

  /**
   * Save the questionnarie updated by the psyuser and perform the scoring.
   */
  async saveQuestionnairesAndScore() {
    //Activate Spinner and disable button
    this.scoringSpinner = true
    this.checkScoreButtonDisabled()

    // Process the questions for the psyuser before the scoring.
    this.setNumberInputsAsNumbers();
    let formValue = this.psyUserForm.value;

    let scoringParams = [];
    let scoringReportingData = [];

    for (const key in formValue) {
      if(key.includes('#scoringReporting#')){

        // Split between hashtags
        const splittedValue = key.split('#scoringReporting#');

        // Set auxKey and testId
        let auxKey = splittedValue[0];
        let testId = splittedValue[1];

        scoringReportingData.push({
          key: auxKey,
          value: formValue[key],
          testId: testId
        });
      }
      if(key.includes('#profileData#')){

        // Split between hashtags
        const splittedValue = key.split('#profileData#');

        // Set auxKey and testId
        let auxKey = splittedValue[0];
        let testId = splittedValue[1];

        scoringParams.push({
          key: auxKey,
          value: formValue[key],
          testId: testId
        });

      }
    }

    this.messageLoading = this.translate.instant('SCORING.SAVING');

    for (let questionnaire of this.relatedTestQuestionnaires){
      if ((questionnaire.statusCode == QuestionnaireCodeStatus.COMPILED || questionnaire.statusCode == QuestionnaireCodeStatus.READY_TO_SCORE)){
        this.questionnairesToScore.push(questionnaire)
      }
    }

    //Save scoringparams and scoringReporting data into questionnaires and set it status from 22-FILLING to 23-READY TO SCORE.
    try {

      // Remove duplicates
      let questionnairesToSave = [...new Set(this.questionnairesToScore.map(_quest => _quest.questionnaireCode))];

      const resultSaveSharedQuestions: any = await this.projectsApi.saveQuestionnaireSharedQuestions(questionnairesToSave, scoringParams, scoringReportingData);

      if(!resultSaveSharedQuestions.statusOk)
        throw new Error(resultSaveSharedQuestions?.message)

    } catch (ex) {
      console.error("Error saving the psyuser questions: " + ex.message);
      this.scoringSpinner = false
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-SCORING-ERROR-SAVING-QUESTIONNAIRES'));
      this.closeScoringPopupOnFail();
      return
    }

    // Check consistency of questionnaires
    this.messageLoading = this.translate.instant('SCORING.CHECKING');

    try {
      const consistencyResult = await this.projectsSrv.checkQuestionnairesConsistency(this.questionnairesToScore, this);
      if(!consistencyResult)
        throw new Error();
    } catch {
      this.scoringSpinner = false;
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-SCORING-CONSISTENCY-FAILED'));
      this.closeScoringPopupOnFail();
      return;
    }

    // Request scoring
    try {
      this.messageLoading = this.translate.instant('SCORING.SCORING');

      const scoringResult = await this.projectsApi.requestScoringServiceV2(this.questionnairesToScore.map(_quest => _quest.questionnaireCode));

      this.messageLoading = this.translate.instant('SCORING.OK');

      //Deactivate Spinner and close popup showing a success scoring message after
      this.scoringSpinner = false
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-SCORING-OK'));
      this.checkScoreButtonDisabled()
      this.closeScoringPopupOnSuccess();

    } catch (ex) {
      this.scoringSpinner = false

        let errToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          ex.message,
          {
            logErrorOnConsole:true,
            defaultMsgKey:"PROJECTS.API-SCORING-NO",
            customEnvironment:"SCORING.HTTP-CODES"
          }
        )
        this.toastSrv.showToastError(errToShow.translatedMessage);

        this.closeScoringPopupOnFail()
        return;
    }
  }

  /**
   * Convert all inputs with number values to a number in the object, for not send as string
   */
  setNumberInputsAsNumbers(): void{
    try{
      for (let psyQuestionsForTest of this.psyUserQuestionsForTest){
        psyQuestionsForTest.testSharedNonGroupedQuestions.filter(x => x.type === 'number').forEach(x => {
          const myval = this.psyUserForm.value[x.name];
          if (!isNaN(+myval) && myval.length > 0){
            this.psyUserForm.value[x.name] = Number.parseInt(myval);
          }
        });
      }

      for (let psyQuestionsForTest of this.psyUserQuestionsForTest){
        psyQuestionsForTest.testSharedGroupedQuestions.forEach(questionsGroup => {
          questionsGroup.items.filter(x => x.type === 'number').forEach(x => {
            const myval = this.psyUserForm.value[x.name];
            if (!isNaN(+myval) && myval.length > 0){
              this.psyUserForm.value[x.name] = Number.parseInt(myval);
            }
          });
        });
      }

    }catch (err){
      console.log("Error parsing value to number: ",err)
    }
  }

  /**
   * Show error toast in popup
   * @param text
   */
  showToastError(text: string): void{
    this.localToastSrv.add({severity: 'error', detail: text, key: 'toast-error-dialog', life: 5000});
  }


  /**
   * Closes the popup, and manage the clearing of variables
   */
  private closeScoringPopupOnSuccess() {
    this.scoringSuccessfull.emit(true);
  }

  /**
   * Closes the popup, and manage the clearing of variables
   */
   private closeScoringPopupOnFail() {
    this.scoringFailed.emit(true);
  }

}
