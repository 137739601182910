import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-template-test-simple',
  templateUrl: './template-test-simple.component.html',
  styleUrls: ['./template-test-simple.component.scss']
})
export class TemplateTestSimpleComponent implements OnInit, OnDestroy {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Input() confirmation;
  @Input() triggerName;

  @Output() itemTriggered = new EventEmitter<any>();

  itemOptions: any;

  subs = new SubSink();

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  triggerItem(): void{
    this.itemTriggered.emit(this.item);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
