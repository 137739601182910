import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PeopleService } from 'src/app/modules/people/services/people.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { ProfileApi } from '../../api/profile.api';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [ToastService],
  animations: [ inOutAnimation ]
})
export class EditProfileComponent implements OnInit, OnDestroy {

  @ViewChild('name') inputName: ElementRef;

  item: any;
  loaded = false;
  editForm: UntypedFormGroup;
  formData: any;

  subs = new SubSink();

  constructor(
    private authSrv: AuthenticationService,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private peopleSrv: PeopleService,
    private toastSrv: ToastService,
    private route: Router,
    private profileApi: ProfileApi
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.peopleSrv.sharedFormData.subscribe(formData => {
      this.formData = formData;
      this.initDropdowns();
      this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res => {
        if (res !== null) {
          this.item = res;
          this.setValidationMessages();
          this.generateForm();
          this.loaded = true;
          setTimeout( () => {
            this.inputName.nativeElement.click();
          }, 200 );
        }
      });
    });
  }

  get name(): any {
    return this.editForm.get('name');
  }

  get gender(): any {
    return this.editForm.get('gender');
  }

  get surname(): any {
    return this.editForm.get('surname');
  }

  get publicEmail(): any {
    return this.editForm.get('publicEmail');
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            email: this.translate.instant('VALIDATIONS.EMAIL')
          }
        });
    });
  }

  generateForm(): void {
    this.editForm = this.formBuilder.group({
      gender: ['', [RxwebValidators.required()]],
      name : ['', [RxwebValidators.required()]],
      surname: ['', [RxwebValidators.required()]],
      publicEmail: ['', [RxwebValidators.email()]]
    });

    this.setUserDetail(this.item);
  }

  setUserDetail(item): void {
    const controls: string[] = Object.keys(this.editForm.controls);

    Object.keys(item).forEach(e => {
      if (item[e] !== undefined && item[e] !== null && item[e] !== '' && controls.includes(e)) {
        if (e === 'gender') {
          this[e].patchValue(this.formData?.genders.filter(x => x.code === item[e])[0]);
        } else {
          this[e].patchValue(item[e]);
        }
        this[e].markAsTouched();
      }
    });
  }

  mapObjects(): void {
    Object.keys(this.editForm.value).forEach(e => {
      if (['gender'].includes(e)) {
        this.item[e] = this.editForm.value[e]?.code ? this.editForm.value[e]?.code : null;
      } else {
        this.item[e] = this.editForm.value[e] ? this.editForm.value[e] : null;
      }
    });
  }

  saveChanges(): void {
    this.mapObjects();
    this.authSrv.setCurrentPsyUser(this.item);

    this.subs.sink = this.profileApi.updatePsyUser(this.item).subscribe(
      (res) => {
        this.toastSrv.showToastSucess(this.translate.instant('EDIT-PEOPLE.API-EDIT-OK'));
        this.route.navigate(['profile']);
      },
      (error) => {
        this.toastSrv.showToastError(error.message.msg);
      }
    );
  }

  initDropdowns(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.formData?.genders.forEach(x => {
        x.name = this.translate.instant(`SELECT-GENDER.${x.code.toUpperCase()}`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
