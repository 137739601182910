import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber, Subscription, zip } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-template-group-simple',
  templateUrl: './template-group-simple.component.html',
  styleUrls: ['./template-group-simple.component.scss']
})
export class TemplateGroupSimpleComponent implements OnInit, OnDestroy {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Input() notEmails: false;
  @Input() disableSelection: boolean = false;

  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<any>();

  public itemOptions;

  subs = new SubSink();

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initMenuOptions();
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.initMenuOptions();
    });
  }

  initMenuOptions(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.itemOptions = [{
        label: 'Options',
        items: [
          {
            label: this.translate.instant('GROUP.DELETE'),
            icon: 'pi pi-trash',
            command: () => {
              this.deleteItem();
            }
          }
        ]}
      ];
    });
  }

  deleteItem(): void {
    this.itemDeleted.emit(this.item);
  }

  selectItem(): void {
    this.itemSelected.emit(this.item);
  }

  selectItemOpt(): void {
    if(!this.disableSelection){
      this.item.selected = !this.item.selected;
      this.selectItem();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
