import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeopleRoutingModule } from './people-routing.module';
import { PeopleComponent } from './components/people-group-container/people/people.component';
import { RootComponent } from './root/root.component';
import { EditPeopleComponent } from './components/edit-people/edit-people.component';
import { PeopleGroupContainerComponent } from './components/people-group-container/people-group-container.component';
import { GroupsComponent } from './components/people-group-container/groups/groups.component';
import { SharedElementsModule } from 'src/app/shared/shared-elements.module';
import { DetailPeopleComponent } from './components/detail-people/detail-people.component';
import { EditGroupComponent } from './components/edit-group/edit-group.component';

@NgModule({
  declarations: [
    PeopleComponent,
    RootComponent,
    DetailPeopleComponent,
    EditPeopleComponent,
    PeopleGroupContainerComponent,
    GroupsComponent,
    EditGroupComponent],
  imports: [
    CommonModule,
    PeopleRoutingModule,
    SharedElementsModule
  ],
  exports: [
    PeopleGroupContainerComponent
  ]
})
export class PeopleModule { }
