<!--FILTER COMPONENT-->
<div class="filter">
    <app-filter #filter
      [items]="[]"
      [searchKeys]="['name','description']"
      [filterByDateRange]="true"
      [onlyEmitSelectedMode]="true"
      (emitSelectedFilters)="applyDataTableFilter($event)"
      (resetFilter)="clearDataTableFilter()">
    </app-filter>
</div>

<!--USERS LIST-->
<div class="p-d-flex p-flex-column main-container">
    <p-table
        #dataTable
        class="rounded-table"
        responsiveLayout="scroll"

        [value]="data"
        [lazy]="true"
        (onLazyLoad)="getDataLazy($event)"
        [totalRecords]="dataCount"

        [loading]="!loaded"
        loadingIcon="pi pi-spin pi-spinner"

        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[5,10,20,50]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'TABLE.ROW-COUNT' | translate}}"

        dataKey="id"
        [(selection)]="selectedData"
        (selectionChange)="onSelect()"
        (onPage)="selectedData=[]; onSelect()">

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr class="primary-row">
          <th style="width: 1rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="name">
            <div>
              {{'GROUP.HEADER-NAME' | translate}}
              <p-sortIcon field="name"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="createdAt">
            <div>
              <i class="pi pi-envelope p-mr-3"></i>
              {{'GROUP.HEADER-CREATION' | translate}}
              <p-sortIcon field="createdAt"></p-sortIcon>
            </div>
          </th>
          <th>
            <i class="pi pi-users p-ml-2"></i>
            {{'GROUP.HEADER-USERS' | translate}}
          </th>
          <th colspan="2"></th>
        </tr>
      </ng-template>

      <!-- BODY -->
      <ng-template pTemplate="body" let-item>
        <tr class="primary-row pointer" [ngClass]="{'selected-row': isSelected(item)}" (click)="goToDetails(item, $event)">
          <td class="non-clickable">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
          <td>
            <div class="p-d-flex p-ai-center">
                <p-avatar
                    icon="pi pi-users"
                    [ngClass]="{'circle-selected': item.selected}"
                    class="p-mr-3"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
                <span class="user-card-name clickable">
                    {{item.name}}
                </span>
            </div>
          </td>
          <td>
            <div class="p-d-flex p-flex-column">
                <span class="user-card-created">
                    {{'PEOPLE.ADDED'| translate}} {{item.createdAt | date: 'dd/MM/yyyy'}}
                </span>
            </div>
          </td>
          <td>
            <div class="p-d-flex">
                <app-users-groups-circles [listItems]="item.testtakersNames" [type]="'user'" class="non-clickable"></app-users-groups-circles>
            </div>
          </td>
          <td class="p-text-right">
            <button
                type="button"
                class="btn-mini non-clickable"
                pButton
                (click)="assignTest(item)"
                label="{{'PEOPLE.ADD-TEST' | translate}}">
            </button>
          </td>
          <td class="p-text-right ellipsis-button">
            <p-menu #menu class="menu-options" [model]="menuItems" [popup]="true" [appendTo]="dataTable"></p-menu>
            <span class="p-d-inline-flex p-jc-center p-ai-center options-button" (click)="activeItem = item; menu.toggle($event)">
              <i class="pi pi-ellipsis-v"></i>
            </span>
          </td>
        </tr>
      </ng-template>

      <!--EMPTY MESSAGE-->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">
            <h3 class="p-text-center">{{'FILTER.NO-RESULTS' | translate}}</h3>
          </td>
        </tr>
      </ng-template>
    </p-table>
</div>

<!-- ADD BUTTON -->
<button
    type="button"
    class="btn-float p-shadow-3"
    pButton
    icon="pi pi-plus"
    iconPos="right"
    label="{{'GROUP.BTN-ADD' | translate | uppercase}}"
    (click)="goToCreateNew()">
</button>

<!--ACTION SIDEBAR-->
<p-sidebar
    [(visible)]="showBottomMenu"
    position="bottom"
    [baseZIndex]="10000"
    class="sidebar-action sidebar-action-table"
    [dismissible]="false"
    [modal]="false"
    (onHide)="selectedData = []">
   <div>
       <button
            type="button"
            class="btn-secondary p-mx-3"
            pButton
            label="{{'ACTION-SIDEBAR.DELETE' | translate}}"
            (click)="confirmDelete(selectedData)">
        </button>
   </div>
</p-sidebar>

<!--BLOCKER-->
<p-blockUI [blocked]="showBlockUi"></p-blockUI>