import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CatalogApi {
    private url = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getCatalogByPsuyuserId(psyuser_id): Observable<any> {
        return this.http.get(this.url + 'test/catalog/' + psyuser_id);
    }

    getUserCatalog(): Observable<any>{
        return this.http.get(this.url + 'testsV2');
    }

    getTestPlans(testId): Observable<any>{
        return this.http.get(this.url + 'tests/' + testId + '/plans');
    }

    getTestById(testId): Observable<any>{
        return this.http.get(this.url + 'tests/' + testId);
    }
}
