import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailPeopleComponent } from './components/detail-people/detail-people.component';
import { EditGroupComponent } from './components/edit-group/edit-group.component';
import { EditPeopleComponent } from './components/edit-people/edit-people.component';
import { PeopleGroupContainerComponent } from './components/people-group-container/people-group-container.component';

const routes: Routes = [
  {
    path: '',
    component: PeopleGroupContainerComponent
  },
  {
    path: 'detail',
    component: DetailPeopleComponent
  },
  {
    path: 'edit',
    component: EditPeopleComponent
  },
  {
    path: 'editgroup',
    component: EditGroupComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeopleRoutingModule { }
