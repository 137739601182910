import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { HelpService } from "../../help/services/help.service";

@Injectable({
  providedIn: "root",
})
export class ProjectsApi {
  private url = environment.apiUrl;
  private boUrl = environment.apiBackOfficeURL;
  private assesmentUrl = environment.apiAssesmentURL;

  constructor(
    private http: HttpClient,
    private helperSrv: HelpService
  ) {}

  getProjectTesttakerCount(project_id): Observable<any>{
    return this.http.get(this.url + "project/"+project_id+"/count");
  }

  getProjects(): Observable<any> {
    return this.http.get(this.url + "project");
  }

  getProjectStats(project_id): Observable<any>{
    return this.http.get(this.url + "v2/project/"+project_id+"/info");
  }

  getProjectsLazy(body: any): Observable<any> {
    return this.http.post(this.url + "v2/projectsLazy", body);
  }

  getFilteredProjectsAndCount(body): Observable<any> {
    return this.http.post(this.url + "v2/getFilteredProjectsAndCount", body);
  }

  getProject(project_id): Observable<any>{
    return this.http.get(this.url+"project/"+project_id);
  }

  getProjectInfo(project_id: number | string): Observable<any> {
    return this.http.get(`${this.url}v2/getProjectInfo/${project_id}`);
  }

  getQuestionnaireStatusSummary(project_id: number | string): Observable<any> {
    return this.http.get(`${this.url}v2/getQuestionnaireStatusSummary/${project_id}`);
  }

  getProjectsStatus(): Observable<any> {
    return this.http.get(this.url + "projects/status");
  }

  createProject(project): Observable<any> {
    return this.http.post(this.url + "project", project);
  }

  updateProject(project): Observable<any> {
    return this.http.post(this.url + "project/update", project);
  }

  deleteProject(projectId): Observable<any> {
    return this.http.request("delete", this.url + "project/" + projectId);
  }

  deleteProjects(projectIds): Observable<any> {
    return this.http.post(`${this.url}v3/projects/delete`, {projectIds: projectIds});
  }

  getProjectStatus(projectId): Observable<any> {
    return this.http.get(`${this.url}v3/projects/${projectId}/status`);
  }

  updateProjectCode(projectId: number | string, code: string): Observable<any> {
    return this.http.patch(`${this.url}v2/updateProjectCode/${projectId}`, { code });
  }

  deleteUserProject(projectId, userId): Observable<any> {
    return this.http.request("delete", this.url + "project/" + projectId + "/testtaker/" + userId);
  }

  deleteTestProject(projectId, testId): Observable<any> {
    return this.http.request("delete", this.url + "project/" + projectId + "/test/" + testId);
  }

  duplicateProject(projectId: number): Observable<any> {
    let body: any = {
      project_id: projectId,
    };
    return this.http.post(this.url + "project/duplicate", body);
  }

  getStatus(): Observable<any> {
    return this.http.get(this.boUrl + "status");
  }

  getProjectByQCode(questionnaireCode): Observable<any>{
    return this.http.get(this.url + "project/questionnaireCode/" + questionnaireCode);
  }

  /**
   * Request the pdf stored in the url and returns it
   * @param url
   * @returns
   */
  async getPdfFile(url: string) {
    const httpOptions = {
      responseType: "blob" as "json",
    };
    const res = await this.http.get(url, httpOptions).toPromise()
    .catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  }

  getDefaultMailText(psyUserId): Observable<any>{
    return this.http.get(this.url + "email/mailDefaultPreview/"+psyUserId);
  }

  sendMails(object:any): Observable<any>{
    return this.http.post(this.url + "email/send", object);
  }

  checkStatus(project_id:number): Observable<any>{
    return this.http.get(this.url + "email/status/" + project_id);
  }

  //#endregion

  //#region Entry mask

  getTestStructure(qCode): Observable<any> {
    return this.http.get(this.assesmentUrl + "entryMask/test_structure/" + qCode);
  }

  getQuestionaire(qCode): Observable<any> {
    return this.http.get(this.assesmentUrl + "entryMask/" + qCode);
  }

  saveQuestionnaire(object: object, qCode): Observable<any> {
    return this.http.post(this.assesmentUrl + "entryMask/" + qCode, object);
  }

  updateQuestionnaireStatusCode(body){
    return this.http.patch(this.url + 'project/updatequestionnaire/'+ body.questionnaireCode, body);
  }

  /**
  * Get the psyuser shared questions for project questionnaires
  */
  async requestScoringServiceV2(qCodes){
    let body = {questionnaireCodes: qCodes}
    return await this.http.post(this.url + "request/scoringService/v2", body).toPromise();
  }

  /**
  * Get the psyuser shared questions for project questionnaires
  */
  async getTestPsyuserSharedQuestions(testIds){
    let body = {testIds: testIds}
    return await this.http.post(this.url + "scoringPopup/getSharedQuestions/test", body).toPromise();
  }

  /**
  * Get the psyuser shared questions for project questionnaires
  */
  async saveQuestionnaireSharedQuestions(qCodes, scoringParams, scoringReportingData){
    let body = {};
    body["scoringParams"] = scoringParams;
    body["scoringReportingData"] = scoringReportingData;
    body["questionnaireCodes"] = qCodes;
    return await this.http.post(this.url + "scoringPopup/saveScoringParams/Questionnaire", body).toPromise();
  }

  async saveTestSharedQuestions(project_id, test_id, scoringParams, onlyQstUpToThisCompilationDate:Date = undefined){
    let res;
    let url = this.url + "v2/scoringPopup/saveScoringParams/project/" + project_id+"/test/"+test_id;
    if(onlyQstUpToThisCompilationDate)
      url+="/"+onlyQstUpToThisCompilationDate;

    let body = {
      scoringParams: scoringParams
    }
    try{
      res = await this.http.post(url, body).toPromise();
      return res;
    }
    catch(err) {
      return err.error;
    }
  }

  async saveProjectSharedQuestions(project_id, scoringParams, onlyQstUpToThisCompilationDate:Date = undefined){

    let res;
    let url = this.url + "v2/scoringPopup/saveScoringParams/project/" + project_id;
    if(onlyQstUpToThisCompilationDate)
      url+="/"+onlyQstUpToThisCompilationDate;

    let body = {
      scoringParams: scoringParams
    }
    try{
      res = await this.http.post(url, body).toPromise();
      return res;
    }
    catch(err) {
      return err.error;
    }
  }

  /**
   * Check questionnaire consistency in just one request
   * @param qCodes
   * @returns
   */
  async checkQuestionnaireConsistencyBulk(qCodes) {
    let body = {questionnaireCodes: qCodes}
    return this.http.post(this.url + "scoringPopup/checkConsistency/Questionnaire", body).toPromise();
  }

  //#endregion report

  getBlindDataByProjectId(projectId): Observable<any> {
    return this.http.get(this.assesmentUrl + "blind/" + projectId);
  }

  getScoringParamsByQCode(questionnaireCode): Observable<any> {
    return this.http.get(this.url + "scoringParams/" + questionnaireCode);
  }

  getProfilesDataByTests(tests): Observable<any> {
    let body = {tests}
    return this.http.post(this.url + "project/profiledatatests", body);
  }

  getProfilesDataByQuestionnaires(questionnaires): Observable<any> {
    let body = {questionnaires}
    return this.http.post(this.url + "project/profiledataquests", body);
  }

  getProjectReasonNonValid(project_id): Observable<any>{
    return this.http.get(this.url + 'v2/scoring/reasonNonValid/'+project_id);
  }

  getTestReasonNonValid(project_id, test_id): Observable<any>{
    return this.http.get(this.url + 'v2/scoring/reasonNonValid/'+project_id+'/test/'+test_id);
  }

  getQuestionnairesReasonNonValid(qCodes): Observable<any>{
    return this.http.post(this.url + 'v2/scoring/reasonNonValid/questionnaires', {questionnaireCodes: qCodes});
  }

  getDiscontinuedTests():Observable<any>{
    return this.http.get(this.url + 'testsdiscontinued');
  }

  getTestRules(test_id):Observable<any> {
    return this.http.get(this.url + 'test/testrules/' + test_id);
  }
  getRolesByTest(test_id): Observable<any> {
    return this.http.get(this.url + 'test/roles/' + test_id);
  }

  /**
  * Get the psyuser shared questions for project questionnaires
  */
  async getRequestProfileChangeForMBTI(selectedProfile, reportKey ,qCode) {
    return await this.http.get(`${this.url}request/report/mbti/${selectedProfile}/${reportKey}/${qCode}`).toPromise();
  }

  /**
   * Prepare data to Check if psyuser have enougth credits/uses
   */
  reserveCreditsOrUseToPurchase(reportsToPurchase): Observable<any>{
    const body = {
      reportsToPurchase: reportsToPurchase
    }
    return this.http.post(this.url + 'request/report/reserve', body);
  }

  async getGroupValidation(groupId) {
    return await this.http.get(this.url + 'projects/group/validate/' + groupId).toPromise();
  }

  getNumberOfTests(projectId: number): Observable<any> {
    return this.http.get(`${this.url}v2/project/numberOfTests/${projectId}`);
  }
  getNumberOfPeople(projectId: number): Observable<any> {
    return this.http.get(`${this.url}v2/project/numberOfPeople/${projectId}`);
  }

  getProjectsLazyV3(params, projectType): Observable<any> {

    // Add projectType
    params.filters['projectType'] = projectType;

    this.helperSrv.cleanLazyAndFormatLoadEventObj(params);

    return this.http.get(this.url + 'v3/projects', { params: params });
  }
}


/**
 * Possible arguments for lazy projects loading
 * @param first (optional) Position of first element
 * @param rows (optional) Number of rows to retrieve
 * @param includeGroupal (optional) Include groupal projects
 * @param includeIndividual (optional) Include individual projects
 * @param searchConditions (optional) dateRange, entryType_id, status_id and code restrictions
 * @param sortOrder (optional) order direction 1 DESC, 0 ASC
 * @param sortField (optional) Sort by sortField ( 'createdAt' | 'code' | 'testtakersCount' | 'testsCount')
 */
export interface LazyProjectsArgs{
  first?:number, //Position of first element
  rows?:number, //Number of rows to retrieve
  includeGroupal?:boolean, //Include groupal projects
  includeIndividual?:boolean, //Include individual projects
  searchConditions?:{
    dateRange?:{ //Filter dates between minDate and maxDate. If min or max value not selected, only min or max limit
      minDate?:Date,
      maxDate?:Date
    },
    entryType_id?:Array<number | string>, //AdministrationType id
    status_id?:Array<number | string>, //status of the project
    code?:string //project code like this
  },
  sortOrder?:number // order direction: 1 DESC, 0 ASC, default DESC
}