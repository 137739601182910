import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuillEditorComponent } from 'ngx-quill';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss']
})
export class CustomEmailComponent implements OnInit {

  @ViewChild('qEditor') qEditor: QuillEditorComponent; 

  constructor(private translate: TranslateService, private projectsApi: ProjectsApi, private authSrv:AuthenticationService, private projectsSrv:ProjectsService) { }

  public content: string;
  public defaultContent:string;
  subs = new SubSink();
  @Output() loaded =new EventEmitter<boolean>(false);
  @Output() htmlChanged = new EventEmitter<any>();
  ngOnInit(): void {

    this.loaded.emit(false);

    let psyUserId=this.authSrv.getCurrentPsyUser()?.id ? this.authSrv.getCurrentPsyUser().id : undefined;
    if(psyUserId){
      this.subs.sink = this.projectsApi.getDefaultMailText(psyUserId).subscribe(
        (res) => {

          //Esto es necesario debido a un error de quill-editor
          this.defaultContent=this.projectsSrv.convertHtmlToQuillFormat(res.data)
          this.content=this.defaultContent
          this.loaded.emit(true);
        },
      );
    }

    /*this.translate.get('key').subscribe(() => {  
      this.content = `<p>${this.translate.instant('CREATE-PROJECT.DEFAULT-EMAIL')}</p>`;    
    });

    this.translate.onLangChange.subscribe(() => {
      this.content = `<p>${this.translate.instant('CREATE-PROJECT.DEFAULT-EMAIL')}</p>`;
    });*/
  }

  created(event: any) {
    if (this.defaultContent != null) {
      event.root.innerHTML = this.defaultContent;
      //event.clipboard.dangerouslyPasteHTML(this.defaultContent)
    }
  }

  changeHtml(event: any): void {
    //The text has to be parsed to a normal html format equivalent to the quill one.
    let parsedHtml=this.projectsSrv.convertQuillFormatToHtml(event.html)
    this.htmlChanged.emit(parsedHtml);
  }


}
