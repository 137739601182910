import { Component, Input, OnInit } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';

export enum InfoBoxMode{
  SLIM='slim',
  ACCORDION='accordion'
}
@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {
  @Input() info: any;
  @Input() title: any;
  @Input() open: any = 'true';
  @Input() mode: InfoBoxMode = InfoBoxMode.SLIM;
  constructor(  private accordionModule: AccordionModule) { 
 
  }

  ngOnInit(): void {
    
  }

}
