<div class="p-d-flex p-jc-between p-pr-6">
  <h1>{{'HELP.HELP' | translate}}</h1>
  <h2 class="pointer">
    <a class="quickGuide-link-decoration" *ngIf="langSrv.isLanguage('it')" id="quick-guide" [download]="getQuickGuideFilename()" [href]="quickGuideURL">
      {{'HELP.QUICK-GUIDE' | translate}}
    </a>
  </h2>
</div>
<div class="p-grid">

  <div class="p-col-8">
    <div class="flex-container-helpText">
      <div>
        <h2 id="giunti">Giunti Testing</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id="whatIsGiunti" header="{{'HELP.WHAT-GIUNTI' | translate}}" [(selected)]="activeState[0]">
          <div [innerHtml]="'HELP-TEXT.WHAT-GIUNTI' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='howGiunti' header="{{'HELP.HOW-GIUNTI' | translate}}" [(selected)]="activeState[1]">
          <div [innerHtml]="'HELP-TEXT.HOW-GIUNTI' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='subscriptions' header="{{'HELP.SUBSCRIPTIONS' | translate}}" [(selected)]="activeState[26]">
          <div [innerHtml]="'HELP-TEXT.SUBSCRIPTIONS' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='credits' header="{{'HELP.CREDITS' | translate}}" [(selected)]="activeState[2]" *ngIf="showCredits">
          <div [innerHtml]="'HELP-TEXT.CREDITS' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='security' header="{{'HELP.SECURITY' | translate}}" [(selected)]="activeState[3]">
          <div [innerHtml]="'HELP-TEXT.SECURITY' | translate"></div>
        </p-accordionTab>
      </p-accordion>

      <div>
        <h2 id='access'>{{'HELP.ACCESS' | translate}}</h2>
      </div>

      <p-accordion [multiple]="true">
        <p-accordionTab id='login' header="{{'HELP.LOGIN' | translate}}" [(selected)]="activeState[4]"> 
          <div [innerHtml]="'HELP-TEXT.LOGIN' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='dashboard' header="{{'HELP.DASHBOARD' | translate}}" [(selected)]="activeState[5]">
          <div [innerHtml]="'HELP-TEXT.DASHBOARD' | translate"></div>
        </p-accordionTab>
      </p-accordion>

      <div>
        <h2 id='manage'>{{'HELP.MANAGE' | translate}}</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id='new_name' header="{{'HELP.NEW-NAME' | translate}}" [(selected)]="activeState[6]">
          <div [innerHtml]="'HELP-TEXT.NEW-NAME' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='create_group' header="{{'HELP.CREATE-GROUP' | translate}}" [(selected)]="activeState[7]">
          <div [innerHtml]="'HELP-TEXT.CREATE-GROUP' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='test_testtaker' header="{{'HELP.MANAGE-TEST-TESTTAKER' | translate}}" [(selected)]="activeState[8]"> 
          <div [innerHtml]="'HELP-TEXT.MANAGE-TEST-TESTTAKER' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='test_group' header="{{'HELP.MANAGE-TEST-GROUP' | translate}}" [(selected)]="activeState[9]">
          <div [innerHtml]="'HELP-TEXT.MANAGE-TEST-GROUP' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='delete_people' header="{{'HELP.DELETE-NAME' | translate}}" [(selected)]="activeState[10]">
          <div [innerHtml]="'HELP-TEXT.DELETE-NAME' | translate"></div>
        </p-accordionTab>
      </p-accordion>
      <div>
        <h2 id='manage_project'>{{'HELP.MANAGE-PROJECT' | translate}}</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id='what_project' header="{{'HELP.WHAT-PROJECT' | translate}}" [(selected)]="activeState[11]">
          <div [innerHtml]="'HELP-TEXT.WHAT-PROJECT' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='create_project' header="{{'HELP.CREATE-PROJECT' | translate}}" [(selected)]="activeState[12]">
          <div [innerHtml]="getTranslationMod('HELP-TEXT.CREATE-PROJECT')"></div>          
        </p-accordionTab>

        <p-accordionTab id='verify_project' header="{{'HELP.VERIFY-PROJECT' | translate}}" [(selected)]="activeState[13]">
          <div [innerHtml]="'HELP-TEXT.VERIFY-PROJECT' | translate"></div>
        </p-accordionTab>

        <p-accordionTab id='tab_project' header="{{'HELP.TAB-PROJECT' | translate}}" [(selected)]="activeState[14]">
          <div [innerHtml]="'HELP-TEXT.TAB-PROJECT' | translate"></div>
        </p-accordionTab>
      </p-accordion>

      <div>
        <h2 id='test_battery'>{{'HELP.TEST-BATTERY' | translate}}</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id='assign_test_testtaker' header="{{'HELP.ASSIGN-TEST-TESTTAKER' | translate}}" [(selected)]="activeState[15]">
          <div [innerHtml]="getTranslationMod('HELP-TEXT.ASSIGN-TEST-TESTTAKER')"></div>
        </p-accordionTab>

        <p-accordionTab id='assign_test_group' header="{{'HELP.ASSIGN-TEST-GROUP' | translate}}" [(selected)]="activeState[16]">
          <div [innerHtml]="getTranslationMod('HELP-TEXT.ASSIGN-TEST-GROUP')"></div>          
        </p-accordionTab>

        <p-accordionTab id='scoring_credits' header="{{'HELP.SCORING-CREDITS' | translate}}" [(selected)]="activeState[17]" *ngIf="showCredits">
          <div [innerHtml]="'HELP-TEXT.SCORING-CREDITS' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='available_tests' header="{{'HELP.AVAILABLE-TEST' | translate}}" [(selected)]="activeState[18]">
          <div [innerHtml]="'HELP-TEXT.AVAILABLE-TEST' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='suministration_types' header="{{'HELP.SUMINISTRATION-TYPES' | translate}}" [(selected)]="activeState[19]">
          <div [innerHtml]="'HELP-TEXT.SUMINISTRATION-TYPES' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='available_reports' header="{{'HELP.AVAILABLE-REPORTS' | translate}}" [(selected)]="activeState[20]">
          <div [innerHtml]="'HELP-TEXT.AVAILABLE-REPORTS' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='reassign_test' header="{{'HELP.REASSIGN-TEST' | translate}}" [(selected)]="activeState[21]">
          <div [innerHtml]="'HELP-TEXT.REASSIGN-TEST' | translate"></div>          
        </p-accordionTab>

        <p-accordionTab id='different_report' header="{{'HELP.DIFFERENT-REPORT' | translate}}" [(selected)]="activeState[22]">
          <div [innerHtml]="getTranslationMod('HELP-TEXT.DIFFERENT-REPORT')"></div>          
        </p-accordionTab>
      </p-accordion>

      <div>
        <h2 id='test_catalog'>{{'HELP.CATALOG' | translate}}</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id='find_test' header="{{'HELP.FIND-TEST' | translate}}" [(selected)]="activeState[23]">
          <div [innerHtml]="'HELP-TEXT.FIND-TEST' | translate"></div>          
        </p-accordionTab>
      </p-accordion>

      <div>
        <h2 id='personal_profile'>{{'HELP.PERSONAL-PROFILE' | translate}}</h2>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab id='modify_profile' header="{{'HELP.MODIFY-PROFILE' | translate}}" [(selected)]="activeState[24]">
          <div [innerHtml]="getTranslationMod('HELP-TEXT.MODIFY-PROFILE')"></div>          
        </p-accordionTab>

        <p-accordionTab id='modify_qualification' header="{{'HELP.MODIFY-QUALI' | translate}}" [(selected)]="activeState[25]">
          <div [innerHtml]="'HELP-TEXT.MODIFY-QUALI' | translate"></div>          
        </p-accordionTab>
      </p-accordion>
    </div><!-- end of helpText -->
  </div>

  <div class="p-col-4">
    <div class="flex-container-TableOfContents">
      <p class="index" (click)="anchorTo('giunti')">{{'HELP.GIUNTI' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('whatIsGiunti'); toggle(0)">{{'HELP.WHAT-GIUNTI' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('howGiunti'); toggle(1)">{{'HELP.HOW-GIUNTI' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('subscriptions'); toggle(26)">{{'HELP.SUBSCRIPTIONS' | translate}}</span></li>
        <li *ngIf="showCredits"><span class="subindex" (click)="anchorTo('credits'); toggle(2)">{{'HELP.CREDITS' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('security'); toggle(3)">{{'HELP.SECURITY' | translate}}</span></li>
      </ol>
      
      <p class="index" (click)="anchorTo('access')">{{'HELP.ACCESS' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('login'); toggle(4)">{{'HELP.LOGIN' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('dashboard'); toggle(5)">{{'HELP.DASHBOARD' | translate}}</span></li>
      </ol>      
      
      <p class="index" (click)="anchorTo('manage')">{{'HELP.MANAGE' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('new_name'); toggle(6)">{{'HELP.NEW-NAME' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('create_group'); toggle(7)">{{'HELP.CREATE-GROUP' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('test_testtaker'); toggle(8)">{{'HELP.MANAGE-TEST-TESTTAKER' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('test_group'); toggle(9)">{{'HELP.MANAGE-TEST-GROUP' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('delete_people'); toggle(10)">{{'HELP.DELETE-NAME' | translate}}</span></li>
      </ol>
      
      <p class="index" (click)="anchorTo('manage_project')">{{'HELP.MANAGE-PROJECT' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('what_project'); toggle(11)">{{'HELP.WHAT-PROJECT' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('create_project'); toggle(12)">{{'HELP.CREATE-PROJECT' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('verify_project'); toggle(13)">{{'HELP.VERIFY-PROJECT' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('tab_project'); toggle(14)">{{'HELP.TAB-PROJECT' | translate}}</span></li>
      </ol>
      
      <p class="index" (click)="anchorTo('test_battery')">{{'HELP.TEST-BATTERY' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('assign_test_testtaker'); toggle(15)">{{'HELP.ASSIGN-TEST-TESTTAKER' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('assign_test_group'); toggle(16)">{{'HELP.ASSIGN-TEST-GROUP' | translate}}</span></li>
        <li *ngIf="showCredits"><span class="subindex" (click)="anchorTo('scoring_credits'); toggle(17)">{{'HELP.SCORING-CREDITS' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('available_tests'); toggle(18)">{{'HELP.AVAILABLE-TEST' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('suministration_types'); toggle(19)">{{'HELP.SUMINISTRATION-TYPES' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('available_reports'); toggle(20)">{{'HELP.AVAILABLE-REPORTS' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('reassign_test'); toggle(21)">{{'HELP.REASSIGN-TEST' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('different_report'); toggle(22)">{{'HELP.DIFFERENT-REPORT' | translate}}</span>
        </li>
      </ol>
      
      <p class="index" (click)="anchorTo('test_catalog')">{{'HELP.CATALOG' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('find_test'); toggle(23)">{{'HELP.FIND-TEST' | translate}}</span></li>
      </ol>
      
      <p class="index" (click)="anchorTo('personal_profile')">{{'HELP.PERSONAL-PROFILE' | translate}}</p>
      
      <ol>
        <li><span class="subindex" (click)="anchorTo('modify_profile'); toggle(24)">{{'HELP.MODIFY-PROFILE' | translate}}</span></li>
        <li><span class="subindex" (click)="anchorTo('modify_qualification'); toggle(25)">{{'HELP.MODIFY-QUALI' | translate}}</span></li>
      </ol>
    </div>
  </div>
</div>