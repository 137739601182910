import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { CatalogApi } from '../api/catalog.api';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class CatalogService {
  psyUser: any;
  psyUserAssignationGroupsId: any;
  plansTests_ids: number[] = []

  private cachedTests: any;
  private dataTestsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private catalogApi: CatalogApi,
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Save available tests to avoid multiple calls
   * @returns
   */
  loadAvailableCatalog(): Observable<any> {
    if (this.cachedTests) {
      return this.dataTestsSubject.asObservable();
    } else {
      return this.catalogApi.getUserCatalog().pipe(
        tap((data) => {
          this.cachedTests = data;
          this.dataTestsSubject.next(data);
        })
      );
    }
  }

  getCatalog(): Promise<any> {
    const langUser = 'langUser';
    return this.catalogApi.getCatalogByPsuyuserId(this.authenticationService.getCurrentPsyUser().id).toPromise()
      .then(
        (res) => {
          let listTest = res.message;
          let currentLanguage = this.translate.currentLang;

          // Set data to display easy
          listTest = listTest.map((x) => ({
            ...x,
            ambit: this.getTestAmbit(x, currentLanguage),
            recipient: this.getRecipient(x, currentLanguage),
            country: this.getCountryTranslated(x),
          }));

          return listTest;
        },
        (err) => {
          return err;
        }
      );
  }

  setPsyUserAssignationGroups(psyUser): void {
    this.psyUserAssignationGroupsId = psyUser.assignationGroups.map(
      (x) => x.id
    );
  }

  /**
   * Parse the country id from test object and returns it translated
   * @param countryModel
   * @returns
   */
   getCountryTranslated(test: any){
    let testCountryID = undefined;
    if(test.country_id){
      testCountryID = this.translate.instant('SELECT-COUNTRY.'+test.country_id);
    }
    return testCountryID;
  }

  generateActiveInactiveCatalogDropdown(): string[] {
    let list:any[] = []

    list.push({value: 'mine', label: this.translate.instant('FILTER.MINE')});
    list.push({value: 'all', label: this.translate.instant('FILTER.ALL')});

    return list
  }

  /**
   * It obtains recipient for each test. If test haven't got a recipient, returns "no-recipient"
   * @param test
   * @param currentLanguage
   * @returns
   */
  getRecipient(test, currentLanguage) {
    let result = [];
  if (test.parentTest.recipients.length > 0){
    if (test.parentTest && test.parentTest.recipients && test.parentTest.recipients[0]?.translation && test.parentTest.recipients[0].translation[currentLanguage]?.name) {
        test.parentTest.recipients.forEach(x => {
          result.push(x.translation[currentLanguage].name)
        })
      } else {
        test.parentTest.recipients.forEach(x => {
          result.push(x.name)
        });
      }
    } else {
        result.push(this.translate.instant("FILTER.KEY-no-recipient"))
    }
    return result
  }

  /**
   * Obtain the test ambit
   * @param test
   */
  getTestAmbit(test, currentLanguage) {
    if(test.parentTest && test.parentTest.ambits.length == 0){
      return this.translate.instant('CATALOG.OTHERS')
    } else if ( test.parentTest && test.parentTest.ambits && test.parentTest.ambits[0].translation && test.parentTest.ambits[0].translation[currentLanguage]?.name ) {
      return test.parentTest.ambits[0].translation[currentLanguage].name;
    } else {
      return test.parentTest.ambits[0].name;
    }
  }


}
