import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ZendeskService } from '../../services/zendesk.service';
import { CoreApi } from 'src/app/core/api/core.api';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';

@Component({
  selector: 'app-zendesk-button',
  templateUrl: './zendesk-button.component.html',
  styleUrls: ['./zendesk-button.component.scss']
})
export class ZendeskButtonComponent implements OnInit {
  @Input() embedded = false;
  countryId: string
  showButton = false
  displayZendeskPopup = false
  message: string = '';
  discontinuedTests = [];
  selectedTest: any;

  constructor(
    private authService: AuthenticationService,
    private zendeskService: ZendeskService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private projectsApi: ProjectsApi
  ) { }

  ngOnInit(): void {
    this.projectsApi.getDiscontinuedTests().subscribe(dataDiscontinued =>{
      this.discontinuedTests = dataDiscontinued.data;
      this.discontinuedTests.forEach(test =>{
        test.combinedName = test.testKey ? test.title +"["+test.testKey+"]" : this.selectedTest.title;
      })
      this.discontinuedTests.push({
        combinedName: this.translate.instant('CATALOG.OTHERS') 
      });
    })
  }

  showDisplayZendesk(){
    this.message = '';
    this.displayZendeskPopup = true;
  }

  createTicketForZendesk(message){
    const psyuser = this.authService.getCurrentPsyUser();
    const psyuser_name = psyuser.name + " " + psyuser.surname;
    const psyuser_id = psyuser.id;
    const psyuser_email = psyuser.email;
    const testName = this.selectedTest.combinedName;
    const zendeskMessage = testName + " - " + message;

    this.zendeskService.createNewTicket(psyuser_name, psyuser_id, psyuser_email, zendeskMessage).subscribe(
      res => {
        this.toastSrv.showToastSucess(this.translate.instant('ZENDESK.TOAST-SUCCESS'));
      },
      error =>{
        this.toastSrv.showToastError(this.translate.instant('ZENDESK.TOAST-ERROR'));
      })
    this.displayZendeskPopup = false;
    this.message = '';

  }

}
