import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { PrimeNgModule } from '../shared/prime-ng/prime-ng.module';
import { SharedElementsModule } from '../shared/shared-elements.module';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ChangeComponent } from './change/change.component';
import { ResetPasswordComponent } from '../modules/profile/components/reset-password/reset-password.component';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { CookieComponent } from './cookie/cookie.component';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './cookie/cookiebot.config';
const components = [
  HeaderComponent,
  FooterComponent,
  LanguageSelectorComponent,
  ProfileMenuComponent,
  LoginComponent,
  ForgotComponent,
  ChangeComponent,
  ResetPasswordComponent,
  CookieComponent
];

@NgModule({
  declarations: [ components],
  imports: [
    CommonModule,
    PrimeNgModule,
    SharedElementsModule,
    RecaptchaFormsModule,
    NgxCookiebotModule.forRoot(CookiebotConfig)
  ],
  exports: [ components ]
})
export class CoreModule { }
