<div *ngIf="type==='group'">
    <p-avatar
        *ngFor="let item of listItems | slice:0:5 "
        icon="pi pi-users"
        class="circle"
        shape="circle"
        pTooltip="{{item.name}}"
        tooltipPosition="top">
    </p-avatar>
    <p-avatar *ngIf="listItems.length > 5"
        label="{{extra}}"
        class="circle circle-extra"
        shape="circle"
        pTooltip="{{listItems.length - 5}}"
        tooltipPosition="top">
    </p-avatar>
</div>

<div *ngIf="type==='user'">
    <p-avatar
        *ngFor="let item of listItems | slice:0:5 "
        label="{{(item.name === undefined || item.name === '' ) ? '#' : (item.name + '').charAt(0)}}"
        class="circle"
        shape="circle"
        [pTooltip]="escapeHtml ? item.tooltip + (item.tooltipPsySubs ? item.tooltipPsySubs:'') :
                                ((item.name === undefined || item.name === '') ? ('#'+item.id) : (item.surname ? (item.name + ' ' + item.surname) : item.name))"
        [escape]="false"
        tooltipPosition="top">
    </p-avatar>

    <p-avatar *ngIf="listItems.length > 5"
        label="{{extra}}"
        class="circle"
        shape="circle"
        pTooltip="{{listItems.length - 5}}"
        tooltipPosition="top">
    </p-avatar>
</div>


<div *ngIf="type==='individual'" class="p-d-flex p-ai-center p-jc-center">
    <p-avatar
        label="{{(listItems[0].name === undefined || listItems[0].name === '' || listItems[0].name === null ) ? '#' : (listItems[0].name + '').charAt(0)}}"
        pTooltip="{{(listItems[0].name === undefined || listItems[0].name === '' || listItems[0].name === null) ? ('#'+listItems[0].friendlyId) : (listItems[0].name + ' ' + listItems[0].surname)}}"
        tooltipPosition="top"
        class="circle"
        shape="circle">
    </p-avatar>
    <!-- <div class="name p-ml-2">{{listItems[0].name !== '' ? listItems[0].name : '#'+listItems[0].id}}</div> -->
</div>


<div *ngIf="type==='projectgroup'" class="p-d-flex p-ai-center">
    <div *ngFor="let item of listItems | slice:0:5 ">
        <p-avatar
            *ngIf="!item.isGroup"
            label="{{(item.name === undefined || item.name === null || item.name === '' || listItems[0].name === 0) ? '#' : (item.name + '').charAt(0)}}"
            class="circle"
            shape="circle"
            pTooltip="{{(item.name === undefined || item.name === null || item.name === '') ? ('#'+item.friendlyId) : (item.name + ' ' + item.surname)}}"
            tooltipPosition="top">
        </p-avatar>
        <p-avatar
            *ngIf="item.isGroup"
            icon="pi pi-users"
            class="circle"
            shape="circle"
            pTooltip="{{item.name}}"
            tooltipPosition="top">
        </p-avatar>
    </div>

    <p-avatar *ngIf="listItems.length > 5"
        label="{{extra}}"
        class="circle"
        shape="circle"
        pTooltip="{{listItems.length - 5}}"
        tooltipPosition="top">
    </p-avatar>
</div>

<div *ngIf="type==='status'">
    <p-avatar
        *ngFor="let item of questionnairesList | slice:0:5; let i = index "
        label="{{i+1}}"
        class="circle"
        shape="circle"
        [ngClass]="item.status_name"
        pTooltip="{{item.tooltip}}"
        [escape]="false"
        tooltipPosition="top">
    </p-avatar>
    <p-avatar *ngIf="listItems.length > 5"
        label="{{extra}}"
        class="circle circle-extra"
        shape="circle">
    </p-avatar>
</div>