<div class="help-content" *ngIf="display">
    <div class="content main-content">
        <h2>{{'HELP-SHORTCUT.TITLE' | translate}}</h2>
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-3">
                <span class="title">
                    {{'HELP.CREATE-PROJECT' | translate | uppercase}}
                </span>
                <p *ngIf="('HELP-TEXT.CREATE-PROJECT' | translate).length > 330" [innerHtml]="('HELP-TEXT.CREATE-PROJECT' | translate | slice:0:330)+'...'" ></p>
                <p *ngIf="('HELP-TEXT.CREATE-PROJECT' | translate).length <= 330" [innerHtml]="('HELP-TEXT.CREATE-PROJECT' | translate)"></p>
                <a class="btn-link pointer" (click)="goToHelpDetail('create_project', 12)">
                    {{'HELP-SHORTCUT.MORE' | translate}}
                </a>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <span class="title">
                    {{'HELP.VERIFY-PROJECT' | translate | uppercase}}
                </span>
                <p *ngIf="('HELP-TEXT.VERIFY-PROJECT' | translate).length > 300" [innerHtml]="('HELP-TEXT.VERIFY-PROJECT' | translate | slice:0:300)+'...'" ></p>
                <p *ngIf="('HELP-TEXT.VERIFY-PROJECT' | translate).length <= 300" [innerHtml]="('HELP-TEXT.VERIFY-PROJECT' | translate)"></p>
                <a class="btn-link pointer" (click)="goToHelpDetail('verify_project', 13)">
                    {{'HELP-SHORTCUT.MORE' | translate}}
                </a>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <span class="title">
                    {{'HELP.ASSIGN-TEST-TESTTAKER' | translate | uppercase}}
                </span>
                <p *ngIf="('HELP-TEXT.ASSIGN-TEST-TESTTAKER' | translate).length > 330" [innerHtml]="('HELP-TEXT.ASSIGN-TEST-TESTTAKER' | translate | slice:0:330)+'...'" ></p>
                <p *ngIf="('HELP-TEXT.ASSIGN-TEST-TESTTAKER' | translate).length <= 330" [innerHtml]="('HELP-TEXT.ASSIGN-TEST-TESTTAKER' | translate)"></p>
                <a class="btn-link pointer" (click)="goToHelpDetail('assign_test_testtaker', 15)">
                    {{'HELP-SHORTCUT.MORE' | translate}}
                </a>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <span class="title">
                    {{'HELP.AVAILABLE-REPORTS' | translate | uppercase}}
                </span>
                <p *ngIf="('HELP-TEXT.AVAILABLE-REPORTS' | translate).length > 330" [innerHtml]="('HELP-TEXT.AVAILABLE-REPORTS' | translate | slice:0:330)+'...'" ></p>
                <p *ngIf="('HELP-TEXT.AVAILABLE-REPORTS' | translate).length <= 330" [innerHtml]="('HELP-TEXT.AVAILABLE-REPORTS' | translate)"></p>
                <a class="btn-link pointer" (click)="goToHelpDetail('available_reports', 20)">
                    {{'HELP-SHORTCUT.MORE' | translate}}
                </a>
            </div>
        </div>
    </div>
    <!--FLOAT BUTTON-->
    <button 
        type="button"
        class="btn-float p-shadow-3" 
        pButton
        icon="pi pi-plus" 
        iconPos="right"
        (click)="goToCreateNew()"
        label="{{ 'PROJECTS.BTN-CREATE'|translate }}">
    </button>
</div>