<div class="input-item-container p-d-flex p-ai-center p-jc-between" #wrap>
  <label for="{{item.itemId}}" [innerHTML]="item.customLabel"></label>

  <span class="p-float-label">
    <div class="info-data p-mx-3 minmaxinfo" >
      <span>[Min. {{item.min}}][Max. {{item.max}}]</span>
    </div>
    <!-- Input -->
    <input
      (focus)="wrap.classList.add('minmax'); $event.target.select()" (blur)="wrap.classList.remove('minmax');"
      id="{{item.itemId}}"
      type="text"
      pInputText
      class="custom-input-text response_item p-ml-1 p-my-1"
      [class.multidigit]="item.max > 9"
      (input)="jumpNext(item.itemId, item.max)"
      autocomplete="off"
      [attr.disabled]="maskEditable? null : !maskEditable"
      [formControl]="ngControl.control"
    >
    <span
      *ngIf="ngControl.control.invalid && ngControl.control.dirty"
      [@inOutAnimation]
      class="error-tooltip">
      {{ngControl.control['errorMessage']}}
      <span *ngIf="ngControl.control.hasError('minNumber')">{{item.min}}</span>
      <span *ngIf="ngControl.control.hasError('maxNumber')">{{item.max}}</span>
    </span>
  </span>

  <!--Item Instruction Tooltip-->
  <div *ngIf="item.entryMaskInstructions" class="item-instructions p-text-center pointer" [ngClass]="p-col-6"
  pTooltip="{{item.entryMaskInstructions}}" tooltipPosition="top" [escape]="false">?</div>
</div>
