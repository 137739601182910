<div class="info">
  <div class="info-content p-mr-1">
    <div class="p-d-flex p-ai-center">
      <h1>{{project?.code}}</h1>
      <div class="bt-edit-name">
        <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="displayChangeNameEvent(true);"></button>
      </div>
    </div>
    <hr />
    <div class="detail-subtitle">{{'PROJECTS.INFORMATION'|translate}}</div>
    <div class="detail-label">{{'PROJECTS.CREATED'|translate}}<span class="detail-data">{{project.createdAt | date: 'dd/MM/yyyy'}}</span></div>
    <div *ngIf="!(project.supply===undefined || project.supply===null)" class="detail-label p-d-flex p-ai-center">
      {{'PROJECTS.SUPPLY'|translate}} <span class="detail-data type-label">{{ 'CREATE-PROJECT.DD-'+(project.supply | uppercase) | translate}}</span>
    </div>
    <div class="detail-label">{{'PROJECTS.COMPILER'|translate}}<span class="detail-data">{{project.sequence ? ('PROJECTS.SEQUENCE'|translate) : ('PROJECTS.NOT-SEQUENCE'|translate)}}</span></div>
    <div *ngIf="project.isMigrated" class="detail-label">{{'PROJECTS.MIGRATED'|translate}}</div>
    <div class="p-d-flex p-jc-between p-ai-center">
      <button type="button" class="btn-secondary button-right-margin" pButton (click)="deleteProjectEvent.emit()" label="{{'PROJECTS.BTN-DELETE'|translate}}"></button>
      <button
        *ngIf="project.supply !== undefined && project.supply !== 'on-site'"
        type="button"
        class="btn-primary button-right-margin"
        pButton
        (click)="addUsers.emit()"
        label="{{(project.status === ProjectStatusString.CREATED ? 'PROJECTS.BTN-RESUME' : 'PROJECTS.BTN-ADD-USERS') | translate}}"
      ></button>
      <button
        *ngIf="project.entryType_id == 3 && (![ProjectStatusString.READY_TO_SCORE, ProjectStatusString.SCORING_ONGOING, ProjectStatusString.SCORED].includes(project.status))"
        type="button"
        class="btn-primary"
        pButton
        (click)="sendEmailEvent.emit()"
        label="{{'PROJECTS.BTN-SEND-MAIL'|translate}}"
      ></button>
      <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}"> </p-progressSpinner>
    </div>
  </div>
</div>

<!--POP-UP CHANGE NAME-->
<p-dialog [(visible)]="displayChangeName" [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true" class="project-name-dialog">
  <div class="project-dialog-content">
      <span class="p-float-label">
          <input id="projectName" type="text" pInputText class="custom-input-text" [(ngModel)]="projectCode">
          <label for="projectName">{{'CREATE-PROJECT.NAME'|translate}}</label>
      </span>
  </div>
  <ng-template pTemplate="footer">
      <button
          type="button"
          class="btn-secondary p-mx-1"
          pButton
          (click)="displayChangeName = false; projectCode = project.code;"
          label="{{'CREATE-PROJECT.BTN-CANCEL'|translate}}">
      </button>
      <button
          type="button"
          class="btn-primary p-mx-1"
          pButton
          (click)="changeName();"
          label="{{'CREATE-PROJECT.BTN-OK'|translate}}">
      </button>
  </ng-template>
</p-dialog>
