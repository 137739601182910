import { Component, Input, OnInit } from '@angular/core';
import { ProjectStatusString } from '../../enums/project-status-string.enum';

@Component({
  selector: 'custom-label',
  templateUrl: './custom-label.component.html',
  styleUrls: ['./custom-label.component.scss']
})
export class CustomLabelComponent implements OnInit {

  @Input() labelType: ProjectStatusString.CREATED | ProjectStatusString.ASSIGNED | ProjectStatusString.FILLING | ProjectStatusString.READY_TO_SCORE | 
                      ProjectStatusString.SCORED | ProjectStatusString.SCORING_ERROR | ProjectStatusString.SCORING_ONGOING | ProjectStatusString.REPORT_ONGOING | ProjectStatusString.SCORED_NON_VALID;

  ProjectStatusString = ProjectStatusString;

  constructor() { }

  ngOnInit(): void {
  }

  
}
