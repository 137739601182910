import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsComponent } from './components/accounts/accounts.component';
import {AdminRoutingModule} from './admin-routing.module';
import { SharedElementsModule } from 'src/app/shared/shared-elements.module';
import { EditPsyuserComponent } from './components/edit-psysuer/edit-psyuser.component';
import { ManagePlanComponent } from './components/manage-plan/manage-plan.component';


@NgModule({
  declarations: [
    AccountsComponent,
    EditPsyuserComponent,
    ManagePlanComponent    
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedElementsModule
  ]
})
export class AdminModule { }
