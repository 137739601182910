import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotComponent } from './core/forgot/forgot.component';
import { ChangeComponent } from './core/change/change.component';
import { LoginComponent } from './core/login/login.component';
import { AuthorizedGuard } from './shared/guards/authorized.guard';
import { LoggedGuard } from './shared/guards/logged.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { CookieComponent } from './core/cookie/cookie.component';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/dashboard/dashboard.module')
      .then(mod => mod.DashboardModule),
      canActivate: [AuthorizedGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'change',
    component: ChangeComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module')
      .then(mod => mod.AdminModule),
    canActivate: [AuthorizedGuard],
    data: { 
      role: [PsyUserRoles.PSYUSER_ADMIN, PsyUserRoles.PSYUSER_FULL, PsyUserRoles.ASSISTANT]
    } 
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module')
      .then(mod => mod.DashboardModule),
      canActivate: [AuthorizedGuard],
      data: { 
        role: [PsyUserRoles.PSYUSER, PsyUserRoles.PSYUSER_ADMIN, PsyUserRoles.PSYUSER_FULL]
      }
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects/projects.module')
      .then(mod => mod.ProjectsModule),
      canActivate: [AuthorizedGuard],
      data: { 
        role: [PsyUserRoles.PSYUSER, PsyUserRoles.PSYUSER_ADMIN, PsyUserRoles.PSYUSER_FULL]
      } 
  },
  {
    path: 'people',
    loadChildren: () => import('./modules/people/people.module')
      .then(mod => mod.PeopleModule),
      canActivate: [AuthorizedGuard],
      data: { 
        role: [PsyUserRoles.PSYUSER, PsyUserRoles.PSYUSER_ADMIN, PsyUserRoles.PSYUSER_FULL]
      }
  },
  {
    path: 'catalog',
    loadChildren: () => import('./modules/catalog/catalog.module')
      .then(mod => mod.CatalogModule),
      canActivate: [AuthorizedGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./modules/help/help.module')
      .then(mod => mod.HelpModule),
      canActivate: [AuthorizedGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module')
      .then(mod => mod.ProfileModule),
      canActivate: [AuthorizedGuard]
  },
  {
    path: 'cookies',
    component: CookieComponent
  },
  {
    path: 'notfound',
    component: NotFoundComponent
  },
  {
    path: 'noauth',
    component: NotAuthorizedComponent
  },
  {
    path: '**',
    redirectTo: 'notfound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
