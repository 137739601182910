import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CoreApi } from '../api/core.api';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { CustomError, Status } from '@giuntipsy/utils/lib';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
  animations: [ inOutAnimation ],
  providers: [ ToastService ]
})
export class ChangeComponent implements OnInit, OnDestroy {
  @Input() reload = true;
  changeForm: UntypedFormGroup;
  displayPanel = false;
  showPassword = false;
  showRepeatPassword = false;
  myCodes = Status;
  loading: Boolean = false;
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})';
  subs = new SubSink();
  autofocus = 0;
  ext_pass: Boolean = false;

  private clientId = environment.clientId;

  constructor(
    public authSrv: AuthenticationService,
    private coreApi: CoreApi,
    public router: Router,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService) { }

  ngOnInit(): void {
    this.ext_pass = this.authSrv.getCurrentUser().ext_pass;
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.changeForm.reset();
    });
    this.setValidationMessages();
    this.generateForm();

    for (let index = 0; index < 3; index++) {
      setTimeout(() => { 
        this.autofocus = index;
      }, 1 );
    }
    
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            minLength: this.translate.instant('VALIDATIONS.MIN-LENGTH'),
            password: this.translate.instant('VALIDATIONS.APLHA'),
            passwordSpecials: this.translate.instant('VALIDATIONS.SPECIALS'),
            compare: this.translate.instant('VALIDATIONS.EQUALS'),
          }
        });
    });
  }

  generateForm(): void {
    this.changeForm = this.formBuilder.group({
      password: ['', [
        RxwebValidators.required(),
        RxwebValidators.minLength({value: this.ext_pass ? 12:8}),
        RxwebValidators.compose(
          {validators: [
            RxwebValidators.pattern(
              {expression: {
                onlyAlpha : new RegExp(this.strongRegex)
              }})
          ], messageKey: 'passwordSpecials'}
        ),
        RxwebValidators.password({validation: {digit: true} })
        ]
      ],
      repeatPassword: ['', [
          RxwebValidators.required(),
          RxwebValidators.compare({fieldName:'password'})
        ]
      ],
    });
  }

  get password(): any {
    return this.changeForm.get('password');
  }

  get repeatPassword(): any {
    return this.changeForm.get('repeatPassword');
  }

  login(): void {
    // Get the user email
    const currentUser = this.authSrv.getCurrentUser();

    this.loading = true;
    const user = {
      username: currentUser.email,
      password: this.password.value,
      reppassword: this.repeatPassword.value,
      client_id: this.clientId};

    this.subs.sink = this.coreApi.changePassword(user).subscribe(
        res => {
          this.toastSrv.showToastSucess(this.translate.instant('RESET.API-OK'));
          this.loading = false;
          if(this.reload){
            this.router.navigateByUrl('/'); // Redirect to home
            setTimeout(() => { 
              this.authSrv.logout();
            }, 1000 );
          }
        },
        error => {
          this.loading = false;
          const msg = 'fail'; // this.translate.instant(`RESPONSES.${error.error.toUpperCase()}`);
          this.toastSrv.showToastError(msg);
        });
  }

  showPasswordEvent($input): void {
    if (this.showPassword) {
      $input.input.nativeElement.type = 'text';
    } else {
      $input.input.nativeElement.type = 'password';
    }
  }

  showRepeatPasswordEvent($input): void {
    if (this.showRepeatPassword) {
      $input.input.nativeElement.type = 'text';
    } else {
      $input.input.nativeElement.type = 'password';
    }
  }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }
}
