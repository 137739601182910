<app-row-card
    [optionLeft]="optionsLeft"
    [optionRight]="optionsRight"
    [customNopeopleTl]="groupItems"
    [item]="item"
    [itemOptions]="itemOptions"
    [entryType]="entryType"
    (itemDeleted)="deleteItem()"
    (itemSelected)="selectItem()"
    (sendMail)="sendEmail($event)">
</app-row-card>

<ng-template #groupItems>
    <div class="p-grid p-ai-center container-data">
        <div *ngIf="item.name && item.surname; then thenTmp else elseTmp"></div>
            <ng-template #thenTmp>
                <p-avatar
                    label="{{getCapitalLetter(item.name)}}"
                    class="row-avatar user p-col-12 p-lg-1 p-md-1 p-sm-1"
                    [ngClass]="{'circle-selected': item.selected}"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
            <ng-template #elseTmp>
                <p-avatar
                    icon="pi pi-user"
                    class="row-avatar user p-col-12 p-lg-1 p-md-1 p-sm-1"
                    [ngClass]="{'circle-selected': item.selected}"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
        <div class="p-d-flex p-flex-column p-col-12 p-lg-5 p-md-5 p-sm-5">
            <div *ngIf="item.name && item.surname; then thenTemp else elseTemp"></div>
            <ng-template #thenTemp>
                <span class="row-name p-mx-3">
                    {{item.name}} {{item.surname}}
                </span>
            </ng-template>
            <ng-template #elseTemp>
                <span class="row-name p-mx-3">
                    {{item.testtakerFriendlyId}}
                </span>
            </ng-template>

            <span class="row-id p-mx-3">{{'PEOPLE.USER' | translate}} #{{item.testtakerFriendlyId}}</span>
        </div>
        <div class="p-grid p-col-12 p-lg-3 p-md-3 p-sm-3">

            <app-users-groups-circles
                [listItems]="item.tests"
                [type]="'status'">
            </app-users-groups-circles>

            <div *ngIf="emailTrackingActive && !disconnected && entryType == 3" class="p-d-flex p-ai-center p-mt-1">
                <!--EMAIL STATUS-->
                <div *ngIf="[ProjectStatusString.FILLING, ProjectStatusString.ASSIGNED].includes(item.status) && emailStatusRetrieved">
                    <div class="" *ngIf="(item.emailStatus == 0 || item.emailStatus == 1) && !item.firstChange">
                        <i *ngIf="item.status != ProjectStatusString.CREATED" class="pi pi-envelope mail-status-icon icon-failure" pTooltip="{{'PROJECTS.MAIL-STATUS-FAILED' | translate}}"></i>
                        <i *ngIf="item.status == ProjectStatusString.CREATED" class="pi pi-envelope mail-status-icon icon-failure" pTooltip="{{'PROJECTS.NOT-SENT-YET' | translate}}"></i>
                    </div>
                    <div class="" *ngIf="item.emailStatus == 2">
                        <i class="pi pi-clock mail-status-icon icon-inprogress" pTooltip="{{'PROJECTS.MAIL-STATUS-SOFTBOUNCE' | translate}}"></i>
                    </div>
                    <div class="" *ngIf="item.emailStatus == 3">
                        <i class="pi pi-send mail-status-icon icon-inprogress" pTooltip="{{'PROJECTS.MAIL-STATUS-SENT' | translate}}"></i>
                    </div>
                    <div class="" *ngIf="item.emailStatus == 4">
                        <i class="pi pi-inbox mail-status-icon icon-inprogress" pTooltip="{{'PROJECTS.MAIL-STATUS-RECP-INBOX' | translate}}"></i>
                    </div>
                    <div class="" *ngIf="item.emailStatus == 5 || item.emailStatus == 6">
                        <i class="pi pi-eye mail-status-icon icon-success" pTooltip="{{'PROJECTS.MAIL-STATUS-OPENED' | translate}}"></i>
                    </div>
                </div>
                <div *ngIf="([ProjectStatusString.FILLING, ProjectStatusString.ASSIGNED].includes(item.status) && !emailStatusRetrieved) ||
                            (item.emailStatus < 2 && item.firstChange && [ProjectStatusString.FILLING, ProjectStatusString.ASSIGNED].includes(item.status))">
                    <p-progressSpinner [style]="{width: '4wv', height: '4vh'}" strokeWidth="2" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
                </div>
            </div>
            <!--EMAIL STATUS FAILED-->
            <div *ngIf="emailTrackingActive && disconnected && entryType == 3">
                <i class="pi pi-envelope mail-status-icon icon-failure" pTooltip="{{'PROJECTS.DISCONNECTED' | translate}}"></i>
            </div>
            <!--REPORT CREATED-->
            <div *ngIf="downloadQuestioners.length > 0">
                <i  (click)="download()" *ngIf="!downloading"  class="pi pi-file-pdf mail-status-icon icon-success" pTooltip="{{'PROJECTS.PDF-STATUS' | translate}}"><small>{{downloadQuestioners.length}}</small></i>
                <p-progressSpinner *ngIf="downloading" [style]="{width: '40px', height: '40px'}" strokeWidth="6"  class="custom-spinner download-spinner" class="custom-spinner download-spinner"   animationDuration="2s"></p-progressSpinner>
            </div>
        </div>


        <!--ACTION BUTTONS-->
        <div class="p-col-12 p-lg-3 p-md-3 p-sm-3 p-text-right">
            <div *ngIf="!loading">
                <button
                    *ngIf="[subProjectType.inMaMu, subProjectType.inMaSi, subProjectType.grMaSi, subProjectType.grMaMu].includes(item.projectType) && [ProjectStatusString.ASSIGNED,'in-progress', 'non-valid'].includes(item.status)"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="insert()"
                    label="{{'PROJECTS.BTN-INSERT' | translate}}">
                </button>
                <button
                    *ngIf="showScoringBtn"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="requestScoring()"
                    label="{{'PROJECTS.BTN-SCORING' | translate}}">
                </button>

                <!-- (This buttons before it called downloadReport) -->
                <button
                    *ngIf="partialReportingCount.length > 0"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="showAnotherReportMenuProject()"
                    label="{{'PROJECTS.BTN-REPORT' | translate}}">
                </button>

                <button
                    *ngIf="item.status === ProjectStatusString.INVALID_SCORING"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="requestScoring()"
                    label="{{'PROJECTS.BTN-FORCE-SCORING' | translate}}">
                </button>
            </div>

            <!-- Spinner -->
            <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
        </div>
    </div>
</ng-template>

<!--POP-UP: Select Test-->
<p-dialog [(visible)]="displayPanel" [style]="{width: '70vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog">
    <div class="dialog-content p-text-center user-dialog">
        <h3>{{'PROJECTS.DIALOG-TITLE-TEST'|translate}}</h3>
        <app-template-test-simple
            *ngFor="let item of dialogItems"
            [item]="item"
            (itemTriggered)="insertByItem($event, displayScoring)"
            [optionsLeft]="'none'"
            [triggerName]="'SELECT'"
            [optionsRight]="'trigger'">
        </app-template-test-simple>
    </div>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>


<!-- POP-UP: Get another report popup-->
<p-dialog [(visible)]="displayAnotherReportPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report" (onHide)="reportingPopupRefPopup.clearPreviousSelected()">
    <div class="dialog-content">
        <app-report-selector-template
            #reportingPopupTemplate
            [projectData]="item"
            [testAndReportList]="projectTestReportList"
            [testtakerList]="userTesttakerList"
            (reportPurchased)="anotherReportMenuOnClose()"
            (requestedReports)="changeTestStatusByReportingRequest($event)"
            *ngIf="projectTestReportList.length > 0">
        </app-report-selector-template>
    </div>
</p-dialog>


<!-- POP-UP: Administration Data-->
<p-dialog [(visible)]="displayAdministrationData" [style]="{width: '90vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report">
    <div class="administration-container p-d-flex p-flex-column p-ai-center">

        <p-toast position="top-center" key="toast-success-copy" preventOpenDuplicates="true" class="toast-dialog">
        </p-toast>

        <h3>{{'PROJECTS.BTN-BLIND-ADMINISTRATION'|translate}}</h3>

        <div class="p-grid">
            <label *ngIf="item.projectSupply === projectEntryType.Blind"  class="p-col-3 p-d-flex p-ai-center p-jc-end">{{'EXCEL.ASSESSMENT-TITLE' | translate}}</label>
            <div *ngIf="item.projectSupply === projectEntryType.Blind"  class="p-col-9 p-d-flex p-ai-center">
                <button
                    pButton
                    (click)="showCopySuccess()"
                    type="button"
                    icon="pi pi-copy"
                    class="p-button-rounded p-button-text"
                    pTooltip="{{'PROJECTS.COPY'|translate}}"
                    tooltipPosition="top"
                    ngxClipboard
                    [cbContent]="urlAssessment + 'blind/login'">
                </button>
                <a class="btn-link pointer" href="{{urlAssessment + 'blind/login'}}" target=”_blank”>{{'PROJECTS.GO-TO-TEST' | translate}}</a>
            </div>

            <label *ngIf="item.projectSupply === projectEntryType.Blind" class="p-col-3 p-d-flex p-ai-center p-jc-end">{{'EXCEL.HEADER-SHORTCODE' | translate}}:</label>
            <span *ngIf="item.projectSupply === projectEntryType.Blind" class="p-col-9 item p-d-flex p-ai-center">
                <button
                    pButton
                    (click)="showCopySuccess()"
                    type="button"
                    icon="pi pi-copy"
                    class="p-button-rounded p-button-text"
                    pTooltip="{{'PROJECTS.COPY'|translate}}"
                    tooltipPosition="top"
                    ngxClipboard
                    [cbContent]="item.tests[0].questionnaireCode.slice(0,11)">
                </button>
                <span class="item-value">{{item.tests[0].questionnaireCode.slice(0,11)}}</span>
            </span>

            <label class="p-col-3 p-d-flex p-ai-center p-jc-end">{{'EXCEL.HEADER-FRIENDLYID' | translate}}:</label>
            <span class="p-col-9 item p-d-flex p-ai-center p-jc-start">
                <button
                    pButton
                    type="button"
                    (click)="showCopySuccess()"
                    icon="pi pi-copy"
                    class="p-button-rounded p-button-text"
                    pTooltip="{{'PROJECTS.COPY'|translate}}"
                    tooltipPosition="top"
                    ngxClipboard
                    [cbContent]="item.testtakerFriendlyId">
                </button>
                <span class="item-value">{{item.testtakerFriendlyId}}</span>
            </span>

            <hr class="p-col-12">

            <label class="p-col-3 p-d-flex p-ai-center p-jc-end">{{'EXCEL.DIRECT-URL'|translate}}:</label>
            <span class="p-col-9 item p-d-flex p-ai-center p-jc-start">
                <button
                    pButton
                    type="button"
                    (click)="showCopySuccess()"
                    icon="pi pi-copy"
                    class="p-button-rounded p-button-text"
                    pTooltip="{{'PROJECTS.COPY'|translate}}"
                    tooltipPosition="top"
                    ngxClipboard
                    [cbContent]="urlAssessment + item.tests[0].questionnaireCode">
                </button>
                <a class="btn-link pointer" href="{{urlAssessment + item.tests[0].questionnaireCode}}" target=”_blank”>
                    {{urlAssessment + item.tests[0].questionnaireCode}}
                </a>
            </span>
        </div>

        <button
            pButton
            type="button"
            class="btn-primary btn-icon p-mt-6"
            (click)="getBlindAministrationData()"
            label="{{'PROJECTS.DOWNLOAD-BLIND-DATA' | translate}}"
            icon="pi pi-file-excel"
            iconPos="right">
        </button>
    </div>
</p-dialog>

<!-- POP-UP: Scoring no valid popup-->
<p-dialog [(visible)]="displayNonValidScoringPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" >
    <div class="dialog-content">
            <app-non-valid-scoring-template
                #scoredNonValidPopupTemplate
                [itemTests] = "item.tests"
                [NonValidScoringCount]= "NonValidScoringCount">
            </app-non-valid-scoring-template>
    </div>
    <ng-template pTemplate="footer">
        <span class="p-d-flex p-jc-between">
            <button
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ALL' | translate}}"
                (click)="getAllReports()"
                class="btn-primary">
            </button>
            <button *ngIf="item.tests.length > 1"
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ONLY-VALID' | translate}}"
                (click)="getOnlyValidReports()"
                class="btn-primary">
            </button>
            <button
                pButton
                label="{{'REPORTS.BTN-CANCEL' | translate}}"
                (click)="closePopup()"
                class="btn-primary">
            </button>
        </span>
    </ng-template>
</p-dialog>