import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { Observable, of } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate {

  

  constructor(private router: Router, private authSrv: AuthenticationService){}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {       

    // Check if the user is authenticated
    if (this.authSrv.isAuthenticated()) { // logged in
      
      // Check if the route require rights
      const supportedPsyuserRoles = route.data.role;

      if(supportedPsyuserRoles) {
        return this.authSrv.currentPsyUser.pipe(        
          skipWhile(psyuser => !psyuser), // Wait until te psyuser is not null        
          take(1),
          map(psyuser => {
            if(supportedPsyuserRoles.includes(psyuser.isAdmin)){              
              return true;
            } else {              
              this.router.navigate(['/noauth']);
              return false;          
            }
          })
      );
      } else {
        // Standard authorization
        return of(true);
      }
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return of(false);
  }

}
