import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Paginator } from 'primeng/paginator';
import { Sidebar } from 'primeng/sidebar';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { PeopleApi } from '../../../api/people.api';
import { PeopleService } from '../../../services/people.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { TesttakerGroupSelectorComponent } from 'src/app/shared/components/modal-templates/testtaker-group-selector/testtaker-group-selector.component';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  providers: [ ToastService]
})
export class PeopleComponent implements OnInit, OnDestroy {

  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('testtakerGroupSelector') testTakerGroupSelector: TesttakerGroupSelectorComponent;

  public data = [];
  public dataCount: number = 0;
  public loaded = false;
  public selectedData = [];
  public showBottomMenu: boolean = false;
  public activeItem: any;
  public menuItems: MenuItem[];
  public showBlockUi = false;

  private confirmSubscription: Subscription;
  private subs = new SubSink();

  constructor(
    private peopleSrv: PeopleService,
    private peopleApi: PeopleApi,
    private router: Router,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private toastConfirmSrv: ToastConfirmService,
    private authSrv: AuthenticationService,
    private projectsSrv: ProjectsService) { }

  ngOnInit(): void {
    this.initMenuItems();
  }

  /**
   * Declare right menu for table items
   */
  initMenuItems(){
    this.menuItems = [
      {
          label: 'Options',
          items: [
              {
                label: this.translate.instant("PEOPLE.EDIT"),
                icon: 'pi pi-pencil',
                command: () => this.router.navigate(['people', 'edit'], {queryParams: {type: 'EDIT', from: 'list', id: this.activeItem.id}})
              },
              {
                label: this.translate.instant("PEOPLE.ADD-GROUP"),
                icon: 'pi pi-users',
                command: () =>  { this.testTakerGroupSelector.openModal(), this.selectedData = [this.activeItem] }
              },
              {
                label: this.translate.instant("PEOPLE.DELETE"),
                icon: 'pi pi-trash',
                command: () => this.confirmDelete([this.activeItem])
              }
          ]
      }
    ]
  }

  /**
   * Get users list based in filters criteria
   * @param event
   */
  getDataLazy(event: LazyLoadEvent) {
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(_psyUser =>{
      if (_psyUser) {
        this.peopleApi.getTestTakersLazy(event).subscribe({
          next: _res => {
            this.data = _res.data.rows;
            this.dataCount = _res.data.count;

            this.data = this.data.map(x => ({...x, completeName: `${x.name} ${x.surname}`, name: x.name + '' }));
          },
          error: _err => {
            this.toastSrv.showToastError(this.translate.instant('TABLE.ERROR-API'))
          },
          complete: () => {
            this.loaded = true;
          }
        });
      };
    });
  }

  /**
   * Normalize filter object
   * @param event
   */
  applyDataTableFilter(event) {

    // Apply date range
    if(event.rangeDates && event.rangeDates.length > 0){
      this.dataTable.filters['startDate'] = event.rangeDates[0];
      this.dataTable.filters['endDate'] = event.rangeDates[1];
    }

    // Apply gender dropdown
    if(event.dropdowns?.some(x => x.key.key == 'gender' && x.selected?.length > 0)){
      const selectedData = event.dropdowns.find(x => x.key.key == 'gender').selected;
      this.dataTable.filters['gender'] = selectedData;
    }

    // Apply global filter string
    this.dataTable.filterGlobal(event.searchText, 'contains');
  }

  /**
   * Show or hide the bottom bar if the number of selected users is greater than 1
   */
  onSelect() {
    this.showBottomMenu = this.selectedData.length > 1 ? true : false;
  }

  /**
   * Confirm delete user/s
   * @param users
   */
  confirmDelete(users): void {
    // Show confirm toast
    this.toastSrv.showToastConfirm(this.translate.instant('PEOPLE.CONFIRM-DELETE'));
    // Block the rest of the ui to avoid errors
    this.showBlockUi = true;

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true) {
        users = this.normalizeDeleteUsers(users);

        this.subs.sink = this.peopleApi.deleteUsers(users).subscribe(
          res => {
            this.toastSrv.showToastSucess(this.translate.instant('PEOPLE.API-DELETEUSER-OK'));
            this.applyDataTableFilter(this.dataTable.createLazyLoadMetadata());
          },
          error => {
            console.error("Error deleting users", users);
            this.toastSrv.showToastError(this.translate.instant('PEOPLE.ERROR-DELETING'));
          });

        this.clearDeleteSubscription();
      }

      if (res === false) {
        this.clearDeleteSubscription();
      }
    });
  }

  /**
   * Unsuscribe delete confirmation subscription and hide blockUi
   */
  clearDeleteSubscription(){
    this.confirmSubscription.unsubscribe();
    this.showBlockUi = false;
    this.showBottomMenu = false;
  }

  /**
   * Extract user ids to be deleted
   * @param users
   * @returns
   */
  normalizeDeleteUsers(users): string[]{
    if (Array.isArray(users)) {
      return users.map(x => x.id);
    } else {
      const aux = [];
      aux.push(users.id);
      return aux;
    }
  }

  /**
   * Clear datatable filters
   */
  clearDataTableFilter() {
    this.dataTable.filters = {};
    this.dataTable.reset();
  }

  /**
   * Go to create project with selected users
   * @param item
   */
  assignTest(item): void {
    let project = this.projectsSrv.generateNewProject();

    item ? project.testtakers.push(item) : (project.testtakers = this.selectedData);

    if(this.projectsSrv.setCurrentProject(project)) {
      item ? item.selected = true : null;
      item ? item.isGroup = false : null;
      this.router.navigate(['projects', 'create']);
    } else {
      this.toastSrv.showToastError(this.translate.instant('SHARING-PANEL.MAX-REACHED'));
    }
  }

  /**
   * Function to know if item is selected, valid to apply specific class
   * @param itemData
   * @returns
   */
  isSelected(itemData: any): boolean {
    return this.selectedData.some(x => x.id == itemData.id);
  }

  /**
   * Click in a row item to access in details
   * @param item
   * @param event
   */
  goToDetails(item, event?: Event) {
    let redirect = true;

    // Click in row
    if(event){
      const rowDetails = event?.target as HTMLElement;

      // Avoid go to details when click in checkbox or menu
      if(!Array.from(rowDetails?.classList).some(x => ['p-checkbox-box','options-button','pi-ellipsis-v','p-menuitem-link','non-clickable'].includes(x))) {
        event.stopPropagation();
      } else {
        redirect = false;
      }
    }

    if(redirect) {
      this.peopleSrv.setSharedUser(item);
      this.router.navigate(['people', 'detail'], {queryParams: {id: item.id}});
    }
  }

  goToCreateNew(): void {
    this.router.navigate(['people', 'edit'], {queryParams: {type: 'NEW'}});
  }

  /**
   * Push selected modal data to group
   * @param event
   */
  loadSelectedDataModal(selectedGroups){
    let flagShownMessage = false;
    let assignObj = []

    for(let _testtaker of this.selectedData) {
      assignObj.push( {
        userID: _testtaker.id,
        groupsIDList: selectedGroups.map(x => x.id)
      });
    }

    this.subs.sink = this.peopleApi.updateGroupUsersBulk(assignObj).subscribe({
      next: res => {
        this.toastSrv.showToastSucess(this.translate.instant('PEOPLE.API-ASSOCUSER-OK'));
        // Unselect selected testtakers
        this.selectedData = [];
        // Reload table data
        this.applyDataTableFilter(this.dataTable.createLazyLoadMetadata());
        this.showBottomMenu = false;
        this.testTakerGroupSelector.clear();
      },
      error: _err => {
        this.toastSrv.showToastError(this.translate.instant('PEOPLE.API-ASSOCUSER-NO'))
      }
    });


  }

  ngOnDestroy(): void {
    this.toastSrv.closeToastConfirm();
    this.subs.unsubscribe();
  }

}
