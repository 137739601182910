<app-row-card 
    [optionLeft]="optionsLeft" 
    [optionRight]="optionsRight" 
    [customNopeopleTl]="groupItems" 
    [item]="item" 
    [itemOptions]="itemOptions" 
    (itemDeleted)="deleteItem()">
</app-row-card>

<ng-template #groupItems>
    <div class="p-grid p-ai-center container-data" *ngIf="item.reports">
        <img class="image p-col-12 p-lg-1 p-md-1 p-sm-1" src="assets/images/row-test.png">
        <span class="row-name p-col-12 p-lg-6 p-md-6 p-sm-6 p-d-flex p-flex-column">
            <span>
                {{item.combinedName}}
            </span>
            <div *ngFor="let report of reportsSelected" >
                <span class="report-name">
                    {{report.name}}
                </span>
            </div>
            
        </span>
        <span class="credit p-col-12 p-lg-2 p-md-2 p-sm-2" *ngIf="showCredits">
            {{totalCredits}} {{'CREATE-PROJECT.CREDIT' | translate}}
        </span>
    </div>    
</ng-template>
