import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-template-test',
  templateUrl: './template-test.component.html',
  styleUrls: ['./template-test.component.scss']
})
export class TemplateTestComponent implements OnInit, OnDestroy {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Output() itemDeleted = new EventEmitter<any>();
  private translateSubscription: Subscription;
  private translateChangeSubscription: Subscription;
  private report: any;
  private reportsSelected: any;
  private psyUserCategory: string;
  public itemOptions;
  private totalCredits: any;

  showCredits: boolean;

  subs = new SubSink();

  constructor(
    private authSrv: AuthenticationService
  ) { }  

  ngOnInit(): void {
    this.setSelectedReport();

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {
      this.showCredits = dataUsr?.hasLegacyPlan;
    });
  }

  deleteItem(): void {
    this.itemDeleted.emit(this.item);
  }

  setSelectedReport(): void {
    this.reportsSelected = this.item.reports?.filter(x => this.item.selectedReport_id.includes(x.id));
    this.totalCredits=this.reportsSelected.reduce((sum, currentReport) => sum + currentReport.credits, 0);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
