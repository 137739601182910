import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { ProjectsService } from './projects.service';
import { ProjectsComponent } from '../components/projects-container/projects/projects.component';
import { ProjectsApi } from '../api/projects.api';
import { ReportingService } from './reporting.service';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { ProjectType } from 'src/app/shared/enums/project-type.enum';
import { ScoringService } from './scoring.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsActionsService {

  private projectsInstance: ProjectsComponent;

  constructor(
    private translate: TranslateService,
    private projectSrv: ProjectsService,
    private reportingSrv: ReportingService,
    private scoringSrv: ScoringService,
    private projectsApi: ProjectsApi,
    private errorsSrv: ErrorsService,
    private router: Router
  ) { }

  /**
   * Set projects component as instansce to trigger actions from service
   * @param instance
   */
  setComponentInstance(instance: ProjectsComponent) {
    this.projectsInstance = instance;
  }


  //#region ----- Set Available Menu Options by Project Type-----
  /**
   * Project type individual, and manual, single Test
   */
  getMenuOptionsInMaSi(project): any{
    const aux = [];

    if(!project?.isMigrated) {
      if (project.statusCode != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED) {
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        //this.addProjectPurchasedReportsShortcuts(aux); -- Removed
        //this.addAnotherReportShortcut(aux); -- Removed
        //aux.push(this.getOptReassign()); -- Removed
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Types:
   * Individual, Manual and Multiple Tests
   * Individual, Presential and Multiple Tests
   */
  getMenuOptionsInMaMuAndInPrMu(project): any{
    const aux = [];

    if(!project?.isMigrated) {
      if (project.status != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

   /**
   * Project type Individual, Presential, Single Test
   */
  getMenuOptionsInPrSi(project): any{
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

   /**
   * Project type Individual, Remote and Single Test
   */
  getMenuOptionsInReSi(project): any{
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getResendOpt(project));
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Types:
   * Individual, Remote and Multiple Test
   */
  getMenuOptionsInReMu(project): any{
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getResendOpt(project));
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project type Individual, Blind and Single Test
   */
  getMenuOptionsInBlSi(project): any{
    const aux = [];

    if (project.statusString === ProjectStatusString.ASSIGNED || project.statusString === ProjectStatusString.FILLING) {
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if ([ProjectStatusString.SCORED].includes(project.statusString)) {
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Types:
   * Individual, Blind and Multiple Test
   */
  getMenuOptionsInBlMu(project): any{
      const aux = [];

      if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
        aux.push(this.getOptionBlindAdministrationData(project));
      }

      if(!project?.isMigrated) {
        if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
          aux.push(this.addDownloadScoringTableMenu(project));
        };
        if(project.statusString == ProjectStatusString.SCORED){
          aux.push(this.getOptionDuplicate(project));
        };
      }

      // Always insert delete option
      aux.push(this.getDeleteOpt(project));

      return [{
        label: 'Options',
        items: aux
      }];
  }

   /**
   * Project Types:
   * Group, Remote and Multiple Test
   */
  getMenuOptionsGrReMu(project): any {
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getResendOpt(project));
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if(project.statusString == ProjectStatusString.SCORED){
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Type: Group, Remote and Single Test
   */
  getMenuOptionsGrReSi(project): any{
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getResendOpt(project));
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if(project.statusString == ProjectStatusString.SCORED){
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));


    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Type: Group Manual and Single/Multiple Test
   */
  getMenuOptions_GrMaSi_GrMaMu(project): any{
    const aux = [];

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if(project.statusString == ProjectStatusString.SCORED){
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Types:
   * Group, Remote and Multiple Test
   */
  getMenuOptionsGrBlMu(project): any{
    const aux = [];

    if (project.statusString === ProjectStatusString.ASSIGNED) {
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if (project.statusString === ProjectStatusString.FILLING) {
      aux.push(this.getResendOpt(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if(project.statusString == ProjectStatusString.SCORED){
        aux.push(this.getOptionDuplicate(project));
      };
    }

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  /**
   * Project Type: Group, Remote and Single Test
   */
  getMenuOptionsGrBlSi(project): any {
    const aux = [];

    if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)) {
      aux.push(this.getOptionBlindAdministrationData(project));
    }

    if(!project?.isMigrated) {
      if (project.statusString != ProjectStatusString.CREATED && project.statusString != ProjectStatusString.ASSIGNED){
        aux.push(this.addDownloadScoringTableMenu(project));
      };
      if(project.statusString == ProjectStatusString.SCORED){
        aux.push(this.getOptionDuplicate(project));
      };
    };

    // Always insert delete option
    aux.push(this.getDeleteOpt(project));

    return [{
      label: 'Options',
      items: aux
    }];
  }

  //#endregion ----- ------

  //#region ----- Menu Options (3 dots) -----

  /**
   * Call to delete action in projects component
   * @param project
   * @returns
   */
  getDeleteOpt(project): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-DELETE'),
      icon: 'pi pi-trash',
      command: () => {
        this.projectsInstance.confirmDelete(project.id);
      }
    };
  }

  /**
   * Adds shortcut menu item to download the scoring table in Excel.
   * Only display if at least one report has been purchased
   */
  addDownloadScoringTableMenu(project): any{
    //Show only if at least one report has been purchased
    return {
      label: this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING'),
      icon: 'pi pi-file-excel',
      command: () => {
        this.downloadScoringTable(project);
      }
    }
  }

  getOptionDuplicate(project): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-DUPLICATE'),
      icon: 'pi pi-refresh',
      command: () => {
        this.projectsApi.duplicateProject(project.id).subscribe(() =>{
          // Show success message
          this.projectsInstance.showSuccessMessage(this.translate.instant("PROJECTS.API-DUPLICATE-OK"));
          // Reload project list
          this.projectsInstance.reloadCurrentPage();
        }, error => {
          // Show error message
          this.projectsInstance.showErrorMessage(this.translate.instant("PROJECTS.API-DUPLICATE-NO"));
        });
      }
    };
  }

  /**
   * Adds shortcut menu item to download the blind administration Excel.
   * @returns
   */
  getOptionBlindAdministrationData(project): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-BLIND-ADMINISTRATION'),
      icon: 'pi pi-file-excel',
      command: () => {
        this.getBlindAdministrationExcel(project);
      }
    };
  }

  /**
   * Launch a popup to select the tessttakers to resend de emails
   * @param project
   * @returns
   */
  getResendOpt(project): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-RESEND'),
      icon: 'pi pi-send',
      command: () => {
        this.getAndShowAvailableUsersToResendEmail(project);
      }
    };
  }

  //#endregion ----- -----

  //#region ----- Quick Actions (Direct button)-----

  /**
   * Open detail project view
   * @param project
   */
  goToDetailsProject(project): void {
    this.projectSrv.setCurrentProject(project);
    this.router.navigate(['projects', 'details']);
  }

  goToContinueCreation(project){
    this.getProjectData(project.id).subscribe({
      next: _project => {

        // Set redirection data
        this.projectSrv.reloadFrom = {
          path: 'list',
          projectId: project.id
        }

        // Format project
        this.projectSrv.formatProject(_project);

        // Set current project in the shared service
        this.projectSrv.setCurrentProject(_project);

        this.router.navigate(["projects", "create"]);
      }
    });
  }

  /**
   * Close current psyuser session and go to assessment portal
   * @param project
   */
  administer(project): void{
    this.getProjectData(project.id).subscribe({
      next: _project => {
        // Go to Assessment Portal
        this.projectSrv.administer(_project.questionnaires[0].questionnaireCode);
      }
    });
  }

  /**
   * Navigate to entryMask based in a questionnaireCode extracted of project
   * @param project
   */
  insert(project): void {
    this.getProjectData(project.id).subscribe({
      next: _project => {
        // Go to entry mask
        this.projectSrv.insertQCodeAndNavigateToAnswers(_project.questionnaires[0].questionnaireCode, true);
      }
    });
  }

  /**
   * Do scoring for a project and show popup with info or filling data if needed
   * @param project
   */
  requestScoringForProject(project) {
    // Check project type first, to know if the view need go to the entrymask or not
    if([ProjectType.inMaSi, ProjectType.inPrSi, ProjectType.inReSi].includes(project.projectType)){
      // Go to entryMask
      this.insert(project);
    } else {
      this.getProjectData(project.id).subscribe({
        next: _project => {
          // Generate report status and structure based on questionnaires
          _project.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(_project.questionnaires);

          // Go to manage scoring for project
          this.manageScoringForThisProject(_project);
        }
      });
    }
  }

  /**
   * Prepare the project to scoring phase requesting the psyuser
   * shared questions
   *
   * This applies to a group Project with a SINGLETON test assigned
   * If more than one test is involved, set variables to redirect to
   * project-details-view instead
   */
  async manageScoringForThisProject(project) {

    this.projectsInstance.showLoadingSpinner = true; //Activate Spinner while Retrieving the neccesary data

    //IN PROJECTS-LIST VIEW, score the project, or open the scoring popup,ONLY if project is for single test.
    const SINGLE_TEST_PROJECT_NUMBER = 1;

    //Scoring popup is designed to manage one single test.
    if(project.projectTestReportList.length == SINGLE_TEST_PROJECT_NUMBER){
        this.scoringSrv.checkPsyUserQuestions(project.questionnaires).then(psyuserSharedQuestions => {

          if(!psyuserSharedQuestions){
            throw new Error("Error retrieving answers for psyuser from the server")
          }

          //Check if need to score directly in case no more data is needed from the psyuser
          if(psyuserSharedQuestions.length == 0){
            //Score directly the project without showing a popup
            this.requestScoringForProjectDirectly(project);

            //Open the scoring popup if there are pending questions for psyuser
          }else{
            //Psyuser needs to enter more data, popup is needed for scoring.
            this.showPopupPsyuserSharedQuestionsForScoring(project, psyuserSharedQuestions);
          }
        })
        .catch(err => {
          console.error("Error retrieving psyuser questions", err);
          this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.API-PROJECT-DATA-KO'));
        }).then(() => {
          //End loading
          this.projectsInstance.showLoadingSpinner = false;
        });

    // If project is multitest, redirect to project-detail-view instead of scoring.
    } else {
      this.projectsInstance.showLoadingSpinner = false;
      this.goToDetailsProject(project);
    }
  }

   /**
   * Request a scoring call on the project questionnaires
   */
   private async requestScoringForProjectDirectly(project){
    this.projectsInstance.showLoadingSpinner = true;

    // Check consistency of questionnaires
    const consistencyResult = await this.projectSrv.checkQuestionnairesConsistency(project.questionnaires, this.projectsInstance);
    if(!consistencyResult)
      return;

    // Request Scoring
    try {

      const scoringResult = await this.projectsApi.requestScoringServiceV2(project.questionnaires.map(_quest => _quest.questionnaireCode));

      //Success with scoring operation, show toast and update projects.
      this.projectsInstance.showLoadingSpinner = false;
      this.projectsInstance.showSuccessMessage(this.translate.instant('PROJECTS.API-SCORING-OK'));

      // Trigger get project status with changes
      this.projectSrv.getProjectStatusAndEmitChanges(project.id);

    } catch (ex) {
      this.projectsInstance.showLoadingSpinner = false;

      let errToShow=this.errorsSrv.getShowableMessageFromHttpCode(
        ex.message,
        {
          logErrorOnConsole:true,
          defaultMsgKey:"PROJECTS.API-SCORING-NO",
          customEnvironment:"SCORING.HTTP-CODES"
        }
      );

      this.projectsInstance.showErrorMessage(errToShow.translatedMessage);
      return;
    }
  }

  /**
   * Shows the popup of shared questions for psyuser for scoring
   */
  private async showPopupPsyuserSharedQuestionsForScoring(project, psyuserSharedQuestions) {
    // Set project
    this.projectsInstance.tempProject = project;

    // Set psyUserQuestions
    this.projectsInstance.tempProjectTestSharedQuestions = psyuserSharedQuestions;

    // Set project questionnaires (as a reference to build the obj api)
    this.projectsInstance.tempRelatedTestQuestionnaires = project.questionnaires;

    // Show scoring popup
    this.projectsInstance.displayScoringPopup = true;
  }

  /**
   * Update the status of project and close the scoring dialog
   */
  public updateScoringStatusOfProjectAndCloseScoringDialog(project) {

    // Close scoringPopUp dialog
    this.projectsInstance.displayScoringPopup = false;

    // Trigger get project status with changes
    this.projectSrv.getProjectStatusAndEmitChanges(project.id);
  }

  /**
   * Get project data from api
   * @param projectId
   * @returns
   */
  private getProjectData(projectId) {
    // Show loading spinner
    this.projectsInstance.showLoadingSpinner = true;

    // Get project data
    return this.projectsApi.getProject(projectId).pipe(
      map(_res => {

        // Show error message if there is an error
        if (_res.error) {
          throw new Error('Error getting project data');
        }

        // Return project data
        return _res.data;

      }),catchError(error => {

        // Show error message and return error
        this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.API-PROJECT-DATA-KO'));
        console.error(error);

        return throwError('Error fetching project data');

      }),finalize(() => {

        // Close loading spinner
        this.projectsInstance.showLoadingSpinner = false;

      })
    );
  }

  /**
   * Action to download, show and info about the reports of the project
   * @param project
   */
  async getReportForProject(project){
    this.getProjectData(project.id).subscribe({
      next: _project => {
        // Generate report status and structure based on questionnaires
        _project.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(_project.questionnaires);

        // Format project and clean project
        this.cleanAndAssignProjectToInstance(_project);

        // Check if there is non valid scoring
        if (_project.questionnaires?.some(x => x.statusCode === QuestionnaireCodeStatus.SCORED_NON_VALID)) {
          this.projectsInstance.displayNonValidScoringPopup = true;
          return;
        }

        // If in this project there is only one report and it has been purchased, download directly
        if (this.reportingSrv.checkIfIsOnlyOneReportForProject(_project.projectTestReportList)) {
          this.downloadPurchasedReports(_project, this.reportingSrv.getTestReportQuestionnaireServed(_project.projectTestReportList));
          return;
        //In any other case show the another report popup.
        } else {
          this.projectsInstance.displayReportPopup = true;
        }
      }
    });
  }

  /**
   * Format and clean the project and after that is set project to trigger actions in projects view
   * @param project
   */
  private cleanAndAssignProjectToInstance(project){
    // Clean reportList and mark as migrated
    const cleanObject = this.projectSrv.cleanProjectObject(project, project.projectTestReportList)
    project = cleanObject.item;
    project.projectTestReportList = cleanObject.projectTestReportList;

    // Set selected/formatted project in projcts instance
    this.projectsInstance.tempProject = JSON.parse(JSON.stringify(project));
  }

  /**
   * This action is trigerred after the user see the scoringNonValid popup, if confirm purchase all, even the non valid
   * only hide previous popup and launch the reportPopUp
   */
  public hideScoringNonValidPopUpAndShowReportPopUp() {
    this.projectsInstance.displayNonValidScoringPopup = false;
    this.projectsInstance.displayReportPopup = true;
  }

  /**
   * This action is trigerred after the user see the scoringNonValid popup, if confirm purchase only valid, the process get
   * only valid questionnaires and proceed to hide and show popups
   */
  public async getOnlyValidReportsFromScoringPopUp(project){
    project.projectTestReportList = await this.reportingSrv.getReportObjectByQuestionnaires(
      project.questionnaires.filter(x => x.statusCode != QuestionnaireCodeStatus.SCORED_NON_VALID));

    this.cleanAndAssignProjectToInstance(project);

    this.hideScoringNonValidPopUpAndShowReportPopUp();
  }

  /**
   * Request to the server the selected report and download it
   * showing a message to inform about the result.
   * @param report
   */
  public async downloadPurchasedReports(project, questionnaires) {
    this.reportingSrv.downloadServedReports(project, questionnaires).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE ) {
        // Messages
        let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          res,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            logErrorOnConsole:true,
            fullErrorToLog:res,
            defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
          }
        )
        this.projectsInstance.showSuccessMessage(messageToShow.translatedMessage);
      }
    }).catch(err => {
      this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.ERROR_REPORT'));
      console.error(err)
    });
  }

  /**
   * Get available users list to resend emails
   * @param project
   */
  private getAndShowAvailableUsersToResendEmail(project){
    // Get project data
    this.getProjectData(project.id).subscribe({
      next: _project => {
        // Set current project
        this.projectsInstance.tempProject = _project;

        // Get candidates testtakers to be shown
        let unfinishedTestTtId = _project.questionnaires.filter(_quest => [
          QuestionnaireCodeStatus.CREATED,
          QuestionnaireCodeStatus.ASSIGNED,
          QuestionnaireCodeStatus.FILLING].includes(_quest.statusCode))
            .map(_quest => _quest.testtaker_id);

        let unfinishedTestUsers = [];
        for (let id of unfinishedTestTtId){
          for (let testtaker of _project.projectHasTestTakers){
            if (id === testtaker.testtaker_id){
              unfinishedTestUsers.push(testtaker.testaker)
            }
          }
        }

        // Set all testtakers selected by default
        unfinishedTestUsers.forEach(x => {x.selected = true});
        unfinishedTestUsers = [...new Set(unfinishedTestUsers)]; // Remove duplicates

        // Set testtakers in projects instance
        this.projectsInstance.unfinishedTestUsers = unfinishedTestUsers;

        // Show testtakers selector
        this.projectsInstance.displaySelectUsers = true;
      }
    });
  }

  /**
   * Send email to specfic testtakers with specific project
   * @param selectedTesttakerIds
   * @param projectId
   * @param emailContent
   */
  public confirmResend(selectedTesttakerIds, projectId, emailContent) {
    let body: any = {project_id: projectId, content: emailContent, language: this.translate.currentLang}

    // Add testtaker ids to body
    if(selectedTesttakerIds){
      body['testtakers_ids'] = selectedTesttakerIds
    }

    // Send emails with communication service
    this.projectsApi.sendMails(body).subscribe(()=>{
      this.projectsInstance.showSuccessMessage(this.translate.instant('PROJECTS.SUCCESS_EMAILS'))
    },
    error => {
      this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.ERROR_EMAILS'));
    });
  }

  /**
   * Downloads the CSV table containing the score information
   */
  private downloadScoringTable(project){
     // Get project data
     this.getProjectData(project.id).subscribe({
      next: _project => {

        // Format project to assemble correctly the excel file
        this.projectSrv.formatProject(_project);

        this.projectSrv.downloadScoringTable(_project).then(res => {
          if(res){
            this.projectsInstance.showSuccessMessage(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-SUCCESS')+_project.code)
          } else {
            this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
          }
        }).catch(err => {
          console.error(err);
          this.projectsInstance.showErrorMessage(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
        })
      }
    });
  }

  /**
   * Get blind administraion excel
   * @param project
   * @returns
   */
  private getBlindAdministrationExcel(project) {
    // Get project data
    this.getProjectData(project.id).subscribe({
      next: _project => {
        this.projectSrv.generateBlindAdministrationExcel(_project);
      }
    });
  }


  //#endregion ----- -----
}
