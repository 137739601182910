<div class="p-d-flex p-ai-center p-jc-end">
    <!------------------QUICK ACTION BUTTONS--------------------->

    <!-- All projects in "created" status has to redirect to complete project creation. -->
    <button
        *ngIf="project.statusString === ProjectStatusString.CREATED"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.goToContinueCreation(project)"
        label="{{'PROJECTS.BTN-RESUME' | translate}}">
    </button>

    <!-- Individual, Presential/OnSite, Single test, and status "Assigned" and "in-progress". -->
    <button
        *ngIf="project.projectType === subProjectType.inPrSi && [ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.administer(project)"
        label="{{'PROJECTS.BTN-ADMIN' | translate}}">
    </button>

    <!-- Individual, Manual, Single test, and status "Assigned" and "in-progress". -->
    <button
        *ngIf="project.projectType === subProjectType.inMaSi && [ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(project.statusString)"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.insert(project)"
        label="{{'PROJECTS.BTN-INSERT' | translate}}">
    </button>

    <!-- For project with Multiple testtakers, and single or multitest, in assigned put button go to GoDetailsView() -->
    <button
        *ngIf="[subProjectType.grMaSi,subProjectType.grMaMu].includes(project.projectType) && project.statusString === ProjectStatusString.ASSIGNED"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.goToDetailsProject(project)"
        label="{{'PROJECTS.BTN-STATUS' | translate}}">
    </button>

    <!-- Rest of project cases "in-progress" status action go to projects details view. -->
    <button
        *ngIf="![subProjectType.inPrSi,subProjectType.inMaSi].includes(project.projectType) && [ProjectStatusString.FILLING, ProjectStatusString.SCORING_ONGOING].includes(project.statusString)"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.goToDetailsProject(project)"
        label="{{'PROJECTS.BTN-STATUS' | translate}}">
    </button>

    <!-- All project in "performed" phase will have the scoring button. -->
    <button
        *ngIf="project.statusString === ProjectStatusString.READY_TO_SCORE"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.requestScoringForProject(project)"
        label="{{'PROJECTS.BTN-SCORING' | translate}}">
    </button>

    <!--All project in finished status shall have the reporting button. -->
    <button
        *ngIf="[ProjectStatusString.SCORED, ProjectStatusString.REPORT_SERVED].includes(project.statusString)"
        type="button"
        class="btn-mini p-mx-1"
        pButton
        (click)="projectActionsSrv.getReportForProject(project)"
        label="{{'PROJECTS.BTN-REPORT' | translate}}">
    </button>

    <!------------------MENU OPTIONS--------------------->
    <div>
        <p-menu #menu class="menu-options" [model]="menuOptions" [popup]="true" [appendTo]="'body'"></p-menu>
        <span class="p-d-inline-flex p-jc-center p-ai-center options-button" (click)="menu.toggle($event)">
            <i class="pi pi-ellipsis-v"></i>
        </span>
    </div>
</div>