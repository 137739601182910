import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-detail',
  templateUrl: './popup-detail.component.html',
  styleUrls: ['./popup-detail.component.scss']  
})
export class PopupDetailComponent implements OnInit {

  constructor(    
  ) { }

  @Input() planToShow = null;
  @Input() showPopUp: any;
  @Output() showPopUpChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  hideDialog(){
    this.showPopUp = false;
    this.showPopUpChange.emit(this.showPopUp);
  }
}
