import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { PeopleService } from '../../services/people.service';

@Component({
  selector: 'app-people-group-container',
  templateUrl: './people-group-container.component.html',
  styleUrls: ['./people-group-container.component.scss']
})
export class PeopleGroupContainerComponent implements OnInit, OnDestroy {

  @Input() embedded = false;

  peopleLabel;
  groupsLabel;
  activeIndex = 0;

  subs = new SubSink();

  constructor(private translate: TranslateService, private peopleSrv: PeopleService) { }

  ngOnInit(): void {
    this.loadMenu();

    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.peopleLabel = undefined;
      // Set this timeout for reload the component titles
      setTimeout(() => {
        this.loadMenu();
      }, 0 );
    });

    this.subs.sink = this.peopleSrv.sharedActiveIndex.subscribe(data => {
      this.activeIndex = data;
    });
  }

  loadMenu(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.peopleLabel = this.translate.instant('CONTAINER-GROUP-USER.PEOPLE');
      this.groupsLabel = this.translate.instant('CONTAINER-GROUP-USER.GROUPS');
    });
  }

  storeActiveIndex(): void{
    this.peopleSrv.setActiveIndex(this.activeIndex);
  }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }
}
