import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { CatalogService } from 'src/app/modules/catalog/services/catalog.service';
import { PeopleService } from 'src/app/modules/people/services/people.service';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { SubSink } from 'subsink';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { HistoryService } from '../../services/history.service';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TruncatePipe]
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() items: any;
  @Input() subsItemsFriendlyName: 'items';
  @Input() type = '';

  // All items selector
  @Input() displayAllItemsSelector: boolean = true;
  // Search
  @Input() searchKeys: string[];
  @Input() deepSearchKeys: string[];
  searchText = '';
  // Filter
  @Input() filterByDateRange: boolean = false;
  rangeDates: any;
  @Input() filterByNumberOfSubitems: boolean = false;
  @Input() subitemsKey = '';
  rangeSubItems: number[] = [0, 100];
  // Dropdowns
  @Input() filterByDropdowns: any;
  dropdowns = [];
  dropdownsSelectedValues = [];
  // Sort
  @Input() sortTypes = [];
  visibleSortTypes = [];
  selectedSortType: any;
  // filter by active/inactive for catalog
  @Input() filterByActiveInactive = false;
  selectedActiveInactive: any = -1; // Active both by default

  // filter by active/inactive for history
  @Input() filterByActiveInactiveHistory = false;
  selectedSortTypeHistory: any;
  visibleSortTypesHistory = [];

  // get the type of the project list individual or projectgroup
  @Input() projectType: string;

  // To apply specific dropdown filter for users inside a project details
  @Input() usersProject: boolean = false;

  //For lazy loading, use the component as interface but don't calculate anything
  @Input() onlyEmitSelectedMode = false;

  //Emit values of filters to process data externally
  @Output() emitSelectedFilters = new EventEmitter<any>();

  @Output() allItemsSelected = new EventEmitter<any>();
  @Output() applyFilter = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();

  filterTranslation: any;
  tgSelectAllChecked = false;
  displayActionFilter = false;
  isFiltered = false;

  // Autofiltering by Country in catalog section
  @Input() catalogPage: boolean = false;
  psyUserCountry: any;

  activeSubscriptions = new SubSink();

  filtering = false; // If the filter is searching by key and need time

  // Flag to know if the items are filtered
  filtered = false;
  // Change the number showed of filtered items
  filteredCount = 0;

  @ViewChild('filterHeader') filterHeader: ElementRef;

  public filterHeaderHeight = 0;

  // To set search button as hovered
  public isHovered = false;

  constructor(
    private projectsSrv: ProjectsService,
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    private historySrv: HistoryService,
    private catalogSrv: CatalogService,
    private peopleSrv: PeopleService,
    private truncatePipe: TruncatePipe,
    private primeNGConfig: PrimeNGConfig,
    private _elRef: ElementRef
    )
    { }


  @HostListener('window:resize')
  public detectResize(): void {
    this.ngDoCheck();
  }

  ngDoCheck() {
    this.filterHeaderHeight = this.filterHeader?.nativeElement.offsetHeight;
  }

  ngOnInit(): void {
    this.initData();

    // Load translation for calendar filter
    this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  private initData(): void {
    // load previous data criteria for projects
    this.setFilterCriteria();

    // Autoselect the psyuser country as filter if this is the catalog screen.
    if (this.catalogPage){
      //this.setDropDowns();
      this.applyPsyuserCountryFilter();
      // Only show active test of pysuser by default
      this.selectedActiveInactive = 'mine';

      // Trigger the filter
      this.emitFilter();
    } else { // Non catalog page
      this.setDropDowns();
    }

    // If is history Active/inactive items for history
    if (this.filterByActiveInactiveHistory) {
      this.selectedSortTypeHistory = false;
      this.historySrv.setStatusHistory(this.filterByActiveInactiveHistory);
      this.emitFilter();
    }

    this.activeSubscriptions.sink = this.translate.get('random.key').subscribe(() => {
      this.filterTranslation = this.translate.instant('FILTER');
      this.setSelectedSortTypes();
    });

    // Listener for language changes
    this.activeSubscriptions.sink = this.translate.onLangChange.subscribe(() => {
      // Restore filter upon language change
      this.emitResetFilter();

      this.filterTranslation = this.translate.instant('FILTER');

      // Autoselect the psyuser country as filter in catalog section
      if (this.catalogPage){
        this.applyPsyuserCountryFilter();
      }

      this.setSelectedSortTypes();
      this.setDropDowns();
      this.emitFilter();
    });
    this.filteredCount = this.items.length;
  }

  /**
   * Activate the neccesary logic to filter automatically by psyuser
   * country upon initialization or language change
   */
  private applyPsyuserCountryFilter() {
    this.psyUserCountry = this.translate.instant('SELECT-COUNTRY.' + this.authSrv.getCurrentPsyUserCountryId());
    this.dropdownsSelectedValues[0] = [];
    this.dropdownsSelectedValues[0].push(this.psyUserCountry);
    this.setDropDowns();
  }

  setSelectedSortTypes(): void {
    this.visibleSortTypes = [];

    const completeSortTypes = [
      {
        key: 'desc-code',
        name: this.filterTranslation['ORDER-CODE-AZ']
      },
      {
        key: 'asc-code',
        name: this.filterTranslation['ORDER-CODE-ZA']
      },
      {
        key: 'az-name',
        name: this.filterTranslation['ORDER-NAME-AZ']
      },
      {
        key: 'za-name',
        name: this.filterTranslation['ORDER-NAME-ZA']
      },
      {
        key: 'desc-creation-date',
        name: this.filterTranslation['ORDER-DATE-DESC']
      },
      {
        key: 'asc-creation-date',
        name: this.filterTranslation['ORDER-DATE-ASC']
      },
      {
        key: 'desc-items-number',
        name: this.filterTranslation['ORDER-ITEMS-DESC'].replace('${itemFriendlyName}', this.filterTranslation[this.subsItemsFriendlyName])
      },
      {
        key: 'asc-items-number',
        name: this.filterTranslation['ORDER-ITEMS-ASC'].replace('${itemFriendlyName}', this.filterTranslation[this.subsItemsFriendlyName]),
      },
      {
        key: 'desc-items-name',
        name: this.filterTranslation['NAME-ITEMS-DESC'].replace('${itemFriendlyName}', this.filterTranslation[this.subsItemsFriendlyName])
      },
      {
        key: 'asc-items-name',
        name: this.filterTranslation['NAME-ITEMS-ASC'].replace('${itemFriendlyName}', this.filterTranslation[this.subsItemsFriendlyName]),
      },
      {
        key: 'desc-test',
        name: this.filterTranslation['ODER-TESTS-DESC']
      },
      {
        key: 'asc-test',
        name: this.filterTranslation['ODER-TESTS-ASC']
      },
      {
        key: 'desc-testtaker',
        name: this.filterTranslation['ODER-USERS-DESC']
      },
      {
        key: 'asc-testtaker',
        name: this.filterTranslation['ODER-USERS-ASC']
      }
    ];

    this.sortTypes.forEach(x => {
      this.visibleSortTypes.push(completeSortTypes.filter(z => z.key === x)[0]);
    });

    this.visibleSortTypesHistory = [
      {
        key: 'active',
        name: this.translate.instant('FILTER.ACTIVE')
      },
      {
        key: 'inactive',
        name: this.translate.instant('FILTER.INACTIVE')
      }
    ];
  }

  setDropDowns(): void {
    if (this.filterByDropdowns !== undefined && this.dropdowns.length >= 0){
      let index = 0;
      this.filterByDropdowns.forEach(key => {

        //For status label used for project, generate all states and in logical order.
        if(key === 'status_id') {
          this.dropdowns[index] = this.projectsSrv.generateProjectStatusDropdown();
        //For entryType/sumministration of project
        }else if(key === 'entryType_id') {
          this.dropdowns[index] = this.projectsSrv.generateProjectEntryTypeDropdown();
        //For type of search
        }else if(key === 'search') {
          this.dropdowns[index] = this.projectsSrv.generateProjectSearchTypeDropdown();
          // Set search type to project by default
          this.dropdownsSelectedValues[index] = this.dropdowns[index][0].value;
        //For gender of testakers
        }else if(key === 'gender') {
          this.dropdowns[index] = this.peopleSrv.generateTesttakerGenderDropdown();
        //For active/inactive test for catalog
        }else if(key === 'active') {
            this.dropdowns[index] = this.catalogSrv.generateActiveInactiveCatalogDropdown();
        }else{
          this.dropdowns[index] = this.generateRegularDropdown(key);
        }

        index++;
      });

      // Set specific keys, values and types for dropdowns
      index = -1;
      this.dropdowns = this.dropdowns.map(x => {
        index++;
        return {
          key: this.filterByDropdowns[index],
          values: this.dropdowns[index],
          type: ["active","search"].includes(this.filterByDropdowns[index]) ? "single" : "multi", // To show a single dropdown or a multiselect
        };
      });

      // If the dropdown is country, add the customer country to dropdown, even if the
      // tests doesn't have this country available
      const countryDropdown = this.dropdowns.filter(x => x.key === 'country')[0];
      if(countryDropdown){
        if(countryDropdown.values.filter(x => x.value === this.psyUserCountry).length <= 0){
          countryDropdown.values.push({value: this.psyUserCountry});
          countryDropdown.values.sort((a, b) => a.value.localeCompare(b.value));
          // Remove duplicates
          const map = new Map(countryDropdown.values.map(x => [x.name, x]));
          // put unique brand objects in an array
          countryDropdown.values = [...map.values()];
        }
      }
    }


  }

  selectAllItemsEvent($event): void {
    this.allItemsSelected.emit($event.checked);
  }

  checkSelectionStatus(): void {
    if (this.items.filter(x => x.selected === false).length > 0){
      this.tgSelectAllChecked = false;
    }
  }

  searchItem(text): void {
    text = text.toLowerCase().trim();

    this.items.forEach(item => {
      const itemName = item.name.toLowerCase().trim();
      if (itemName.indexOf(text) >= 0){
        item.visible = true;
      } else {
        item.visible = false;
      }
    });
  }

  applyFilterByMode(): void{

    if(!this.onlyEmitSelectedMode){
      this.emitFilter()
    }
    else{
      this.emitSelections()
    }
  }

  emitFilter(): void {
    let result = [];
    this.filtering = true;

    // ---KEY ITEMS---
    let { deepSubitemsKey, codeKey, nameKey } = this.getItemKeys()

    // ---SEARCH BY TEXT---
    let text = this.searchText;
    text = text.toLowerCase().trim();
    // let isSpecific = /^"(.*?[^\\])"$/.test(text);
    result = this.searchByText(result, text);

    // ----FILTER BY----
    result = this.filterDateRange(result);

    // ---NUMBER OF SUBITEMS ---
    //result = this.filterNumberOfSubitems(result);

    // ---DROPDOWNS---
    if(this.usersProject){
      result = this.filterDropdownsUserProject(result);
    }else{
      result = this.filterDropdowns(result);
    }

    // Active/inactive tests for catalog
    if (this.filterByActiveInactive) {
      if (this.selectedActiveInactive === 'mine') {
        result = result.filter(x => x.active);
      }
    }

    // Active/inactive items for history
    if (this.filterByActiveInactiveHistory) {

      if(this.selectedSortTypeHistory) {
        result = result.filter(x => !x.isAlive);
      } else {
        result = result.filter(x => x.isAlive);
      }

      this.historySrv.setStatusHistory(this.selectedSortTypeHistory);
    }


    // ---SORT-BY---
    result = this.sortBy(result, codeKey, nameKey);

    this.applyFilter.emit(result);
    this.isFiltered = true;
    //this.displayActionFilter=false;
    this.filtering = false;
    this.filteredCount = result.length;
  }

  emitSelections(): void {
    let currentFilterFields=this.getCurrentFilterCriteria();
    this.emitSelectedFilters.emit(currentFilterFields);
    this.isFiltered=true;
  }

  resetFilters(){
    // Clean filter inputs
    this.searchText = '';
    this.rangeDates = undefined;
    this.rangeSubItems = [0, 100];
    this.dropdownsSelectedValues = [];
    this.selectedSortType = undefined;
    this.selectedActiveInactive = undefined;
    this.selectedSortTypeHistory = undefined;
    this.historySrv.setStatusHistory(null);
    this.filteredCount = this.items.length;
    this.isFiltered = false;
    if(this.projectType){
      //Delete also from localStorage
      if (this.projectType === 'individual') {
        localStorage.removeItem('individualFilter');
      }
      else {
        localStorage.removeItem('projectgroupFilter');
      }
    }
  }

  emitResetFilter(): void {
    this.resetFilters()
    this.resetFilter.emit('');
  }

  setTgToFalse(): void {
    this.tgSelectAllChecked = false;
  }

  getCurrentFilterCriteria(): any {
    let dropdownsStr = [];
    for (let i = 0; i < this.dropdowns.length; i++) {
      dropdownsStr.push({
        key: this.dropdowns[i],
        selected: this.dropdownsSelectedValues[i]
      });
    }

    const obj = {
      searchText: this.searchText,
      rangeDates: this.rangeDates,
      rangeSubItems: this.rangeSubItems,
      selectedSortType: this.selectedSortType,
      selectedSortTypeHistory: this.selectedSortTypeHistory,
      dropdowns: dropdownsStr
    };

    if (this.projectType === 'individual') {
      localStorage.setItem('individualFilter', JSON.stringify(obj));
    }

    if (this.projectType === 'projectgroup') {
      localStorage.setItem('projectgroupFilter', JSON.stringify(obj));
    }
    return obj
  }

  setFilterCriteria(): void {
    let item: any;

    if (this.projectType === 'individual') {
      item = localStorage.getItem('individualFilter');
    }

    if (this.projectType === 'projectgroup' && !item) {
      item = localStorage.getItem('projectgroupFilter');
    }

    if (item) {
      item = JSON.parse(item);
      this.searchText = item.searchText;
      if (item.rangeDates) {
        try{
          item.rangeDates[0] = new Date(item.rangeDates[0]);
          item.rangeDates[1] = new Date(item.rangeDates[1]);
          this.rangeDates = item.rangeDates;
        } catch (ex) {
          console.error('Date not parsed');
        }
      }
      this.rangeSubItems = item.rangeSubItems;
      this.selectedSortType = item.selectedSortType;
      if (item.dropdowns){
        for (let i = 0; i < item.dropdowns.length; i++) {
          this.dropdownsSelectedValues[i] = item.dropdowns[i].selected;
        }
      }
      //Filters found on localStorage, then,
      //filters are loaded and "Reset filters" button must be enabled
      this.isFiltered = true;
    }
  }

  /**
   * Order the string array alphabetically.
   * If null, or undefined, return 0 and set
   * them on the beginning.
   * @param array
   * @returns
   */
   sortStringArrayAlphabetically(array : string[]) :string[]{
     return array.sort(function(a, b){
      var nameA = a?.toLowerCase(), nameB = b?.toLowerCase();
      if (nameA < nameB) //sort string ascending
       return -1;
      if (nameA > nameB)
       return 1;
      return 0;
     })
  }

  setElipsisInText(text: string, chars: number) {
    if (text && text.length > chars) {
      return text.substring(0, chars) + '...';
    }
    return text;
  }

  /**
   * Get the object keys for search and filter
   */
  getItemKeys(){
    let deepSubitemsKey = [];
    let codeKey = '';
    let nameKey = '';

    // Get key ids
    this.items.forEach( x => {
      Object.keys(x).some(attr => {
        if (Array.isArray(x[attr])) {
          //subitemsKey = attr;
          deepSubitemsKey.push(attr);
        } else if (attr === 'id' || attr === 'friendlyId') {
          codeKey = attr;
        } else if ( attr === 'name' || attr === 'title' || attr === 'code' || attr === 'parentTestName') {
          nameKey = attr;
        }
      });
    });

    return {
      deepSubitemsKey: deepSubitemsKey,
      codeKey: codeKey,
      nameKey: nameKey
    };
  }

  /**
   * Search text in object based in objectKeys and quotes
   */
  searchByText(result, text) {
    if (text !== '' && text !== undefined) {
      this.items.forEach((x) => {
        let found = false;
        Object.keys(x).some((attr) => {
          if ((this.searchKeys.includes(attr)) && (x[attr] !== null)) {
            let param = x[attr] + '';
            let textSearch = text + '';
            if(param.toLowerCase().indexOf(textSearch) >= 0) {
              found = true;
            }
          }
          if(attr==this.subitemsKey) {
            x[this.subitemsKey].forEach(element => {
              const subKeys = ['friendlyId','name','email'];
              Object.keys(element.testaker).some((attrSub) => {
                let param = attrSub + '';
                let textSearch = text + '';
                if(subKeys.includes(param) && param?.toLowerCase().indexOf(textSearch) >= 0){
                  found = true;
                }
              });
            })
          }
        });
        if (found) {
          result.push(x);
          this.filtered = true;
        }
      });
    } else {
      result = this.items;
    }
    return result;
  }

  /**
   * Filter the result based in a range of dates
   */
  filterDateRange(result){
    if (this.filterByDateRange && this.rangeDates !== null && this.rangeDates !== undefined) {
      if (result === undefined || result.length <= 0){
        result = this.items;
      }
      result = result.filter( x => (moment(x.createdAt).isSameOrAfter(moment(this.rangeDates[0]))
        && moment(x.createdAt).isSameOrBefore(moment(this.rangeDates[1]))));
      this.filtered = true;
    }
    return result;
  }

  /**
   * Filter by object attribute arrays, like: testakers, number of tests...
   */
  filterNumberOfSubitems(result) {
    let aux = [];
    // number of subitems
    if (this.filterByNumberOfSubitems){
      if (result === undefined || result.length <= 0){
        result = this.items;
      }
      result.forEach( x => {
        let found = false;
        Object.keys(x).forEach(attr => {
          if (x[this.subitemsKey].length >= this.rangeSubItems[0] && x[this.subitemsKey].length <= this.rangeSubItems[1]) {
            found = true;
          }
        });
        if (found){
          aux.push(x);
        }
      });
      result = aux;
      this.filtered = true;
    }
    return result;
  }

  /**
   * Filter the result based in the selected dropdowns elements
   */
  filterDropdowns(result){
    let aux = [];
    if (this.filterByDropdowns && result.length > 0){
      if (result === undefined || result.length <= 0){
        result = this.items;
      }
      let found = false;
      this.filterByDropdowns.forEach((x, i) => {
        if (this.dropdownsSelectedValues[i] !== undefined && this.dropdownsSelectedValues[i] !== null
          && this.dropdownsSelectedValues[i].length > 0 ) {
          if (!found) {
            aux = result.filter(z => this.findInDropdown(this.dropdownsSelectedValues[i], z[x]));
            found = true;
          } else {
            aux = aux.filter(z => this.findInDropdown(this.dropdownsSelectedValues[i], z[x]));
          }
        }
      });
      if (found){
        result = aux;
        this.filtered = true;
      }
    }
    return result;
  }

  filterDropdownsUserProject(result){
    let aux = [];
    if (this.filterByDropdowns && result.length > 0){
      let found = false;
      /// Set statusCode to filter 'compiled' and 'ready_to_score' together like 'performed'.
      /// Only valid for this scope, this don't change original object.
      result.forEach(_tt => {
        _tt.tests.forEach(_quest => {
          if(_quest.statusCode === QuestionnaireCodeStatus.COMPILED){
            _quest.statusCode = QuestionnaireCodeStatus.READY_TO_SCORE;
          }
          /// Same case for 'REPORT_SERVED', 'REPORT_ERROR' and 'SCORED' like 'finished'
          if (_quest.statusCode === QuestionnaireCodeStatus.REPORT_SERVED || _quest.statusCode === QuestionnaireCodeStatus.REPORT_ERROR){
            _quest.statusCode = QuestionnaireCodeStatus.SCORED;
          }
        });
      });

      this.filterByDropdowns.forEach((x, i) => {
        if (this.dropdownsSelectedValues[i] !== undefined && this.dropdownsSelectedValues[i] !== null
          && this.dropdownsSelectedValues[i].length > 0 ) {
          if (!found) {
            aux = result.filter(z => z.tests.some(y => this.dropdownsSelectedValues[i].includes(y.statusCode)));
            found = true;
          } else {
            aux = aux.filter(z => z.tests.some(y => this.dropdownsSelectedValues[i].includes(y.statusCode)));
          }
        }
      });
      if (found){
        result = aux;
        this.filtered = true;
      }
    }
    return result;
  }

  // Select dropdown item based in key or array
  findInDropdown(dropdownSelectedValues, itemObject): boolean {
    if(Array.isArray(itemObject)){
      let found = false;
      itemObject.forEach(subItem => {
        if(dropdownSelectedValues.includes(subItem + ''.trim())){
          found = true;
        }
      });
      return found;
    } else {
      return dropdownSelectedValues.includes(itemObject + ''.trim().toLowerCase())
    }
  }

  /**
   * Sort by selected option in sort dropdown
   */
  sortBy(result, codeKey, nameKey) {
    let aux = result;

    if (result === undefined && this.filtered === false){
      result = this.items;
    }

    switch (this.selectedSortType){
      case 'desc-code':
        result.sort((a, b) => (a[codeKey] > b[codeKey]) ? 1 : ((b[codeKey] > a[codeKey]) ? -1 : 0));
        break;
      case 'asc-code':
        result.sort((a, b) => (a[codeKey] < b[codeKey]) ? 1 : ((b[codeKey] < a[codeKey]) ? -1 : 0));
        break;
      case 'az-name':
        result.sort((a, b) => (a[nameKey] > b[nameKey]) ? 1 : ((b[nameKey] > a[nameKey]) ? -1 : 0));
        break;
      case 'za-name':
        result.sort((a, b) => (a[nameKey] < b[nameKey]) ? 1 : ((b[nameKey] < a[nameKey]) ? -1 : 0));
        break;
      case 'desc-creation-date':
        result.sort((a, b) => (moment(b.createdAt).diff(moment(a.createdAt))));
        break;
      case 'asc-creation-date':
        result.sort((a, b) => (moment(a.createdAt).diff(moment(b.createdAt))));
        break;
      case 'desc-items-number':
        result.sort((a, b) => (b[this.subitemsKey].length > a[this.subitemsKey].length) ? 1
         : (a[this.subitemsKey].length > b[this.subitemsKey].length) ? -1 : 0);
        break;
      case 'asc-items-number':
        result.sort((a, b) => (a[this.subitemsKey].length > b[this.subitemsKey].length) ? 1
         : (b[this.subitemsKey].length > a[this.subitemsKey].length) ? -1 : 0);
        break;
      case 'desc-items-name': // Only work for individual project
        aux = result.map(x => ({...x, inheritUserName: x[this.subitemsKey][0].name}));
        result = aux.sort((a, b) => (a['inheritUserName'] > b['inheritUserName']) ? 1
         : ((b['inheritUserName'] > a['inheritUserName']) ? -1 : 0));
        break;
      case 'asc-items-name': // Only work for individual project
        aux = result.map(x => ({...x, inheritUserName: x[this.subitemsKey][0].name}));
        result = aux.sort((a, b) => (a['inheritUserName'] < b['inheritUserName']) ? 1
         : ((b['inheritUserName'] < a['inheritUserName']) ? -1 : 0));
        break;
      case 'desc-test':
        aux = result.map(x => ({...x, numberOfTests: [...new Set(x.questionnaires.map(z => z.test.testKey))].length}));
        result = aux.sort((a, b) => (a.numberOfTests < b.numberOfTests) ? 1 : ((b.numberOfTests < a.numberOfTests) ? -1 : 0));
        break;
      case 'asc-test':
        aux = result.map(x => ({...x, numberOfTests: [...new Set(x.questionnaires.map(z => z.test.testKey))].length}));
        result = aux.sort((a, b) => (a.numberOfTests > b.numberOfTests) ? 1 : ((b.numberOfTests > a.numberOfTests) ? -1 : 0));
        break;
      case 'desc-testtaker':
        result.sort((a, b) => (a.projectHasTestTakers.length < b.projectHasTestTakers.length) ? 1
        : ((b.projectHasTestTakers.length < a.projectHasTestTakers.length) ? -1 : 0));
        break;
      case 'asc-testtaker':
        result.sort((a, b) => (a.projectHasTestTakers.length > b.projectHasTestTakers.length) ? 1
        : ((b.projectHasTestTakers.length > a.projectHasTestTakers.length) ? -1 : 0));
        break;
    }

    return result;
  }

  /**
   * Generate and return the dropdowns values for a non specifics one
   * @returns
   */
  generateRegularDropdown(key): any[] {
    let list:any[] = [];

    // Get keys, remove duplicates and trim
    list = [...new Set(
              this.items.map((x) => { return x[key];}).flat()
                .map((x) => { return x ? x.trim() : x;})
            )];

    // Create object
    list = list.map(x => {
      // Set label as string and start with mayus
      let auxLabel = x + '';
      auxLabel = auxLabel.charAt(0).toUpperCase() + auxLabel.slice(1);
      return {label: `${this.truncatePipe.transform(auxLabel.trim(), 30)}`, value: `${x}`}
    });

    // Order list
    list = list.sort((a, b) => (a.label > b.label) ? 1 : -1);

    return list;
  }

  onEnterPressed(event: any) {
    this.applyFilterByMode();

    // To show button as hover
    this.isHovered = true;

    setTimeout(() => {
      this.isHovered = false;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }
}
