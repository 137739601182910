import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';
import { PeopleApi } from '../api/people.api';
@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  private currentUser = new BehaviorSubject<any>(null);
  sharedCurrentUser = this.currentUser.asObservable();

  private currentGroup = new BehaviorSubject<any>(null);
  sharedCurrentGroup = this.currentGroup.asObservable();

  private formData = new BehaviorSubject<any>(null);
  sharedFormData = this.formData.asObservable();

  private currentActiveIndex = new BehaviorSubject<number>(null);
  sharedActiveIndex = this.currentActiveIndex.asObservable();

  private currentcounterUserAssigned = new BehaviorSubject<number>(0);
  sharedUserAssigned = this.currentcounterUserAssigned.asObservable();

  subs = new SubSink();

  public selectedTesttakersToCreateGroup: any[] = []
  private _counter = 0;

  constructor(
    private peopleApi: PeopleApi,
    private authSrv: AuthenticationService,
    private translate: TranslateService
  ) {
    const storedUser = localStorage.getItem('storedUser');
    if (storedUser) {
      try {
        this.setSharedUser(JSON.parse(storedUser));
      } catch (ex) {}
    }

    const storedGroup = localStorage.getItem('storedGroup');
    if (storedGroup) {
      try {
        this.setSharedGroup(JSON.parse(storedGroup));
      } catch (ex) {}
    }

    const activeIndex = localStorage.getItem('activeIndex');
    if (activeIndex) {
      try {
        this.setActiveIndex(JSON.parse(activeIndex));
      } catch (ex) {}
    }

    this.initOrRefreshFormData();
  }

  setSharedUser(user: any): void {
    localStorage.setItem('storedUser', JSON.stringify(user));
    this.currentUser.next(user);
  }

  setSharedGroup(group: any): void {
    localStorage.setItem('storedGroup', JSON.stringify(group));
    this.currentGroup.next(group);
  }

  setActiveIndex(index: number): void {
    localStorage.setItem('activeIndex', JSON.stringify(index));
    this.currentActiveIndex.next(index);
  }

  setFormData(items): void {
    this.formData.next(items);
  }

  getEducationByCode(code: string): string {
    try{
      return this.formData.value?.educations.filter(x => code === x.code)[0].name;
    } catch (ex){
      return '';
    }
  }

  getOccupationByCode(code: string): string {
    try{
      return this.formData.value?.occupations.filter(x => code === x.code)[0].name;
    } catch (ex){
      return '';
    }
  }

  getCountryByCode(code: string): string {
    try{
      return this.formData.value?.countries.filter(x => code === x.code)[0].name;
    } catch (ex){
      return '';
    }
  }

  // TO-DO: Meter eso a nivel de traducción
  getGenders(): any{
    return  [{code: 'male', name: '', },
             {code: 'female', name: ''}];
  }

  simplifyGroups(groups): any{
    return groups.map(x =>({code: x.id, name: x.name}));
  }

  simplifyCountries(countries): any{
    return countries.map(x =>({code: x.id, name: x.country}));
  }

  initOrRefreshFormData(): void{
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res => {
      if (res !== null) {
        let aux = {};
        this.subs.sink = this.peopleApi.getCountries().subscribe(
          countries => {
            aux['countries'] = this.simplifyCountries(countries.data);
          },
          err => {
            console.error('Error getting countries');
          })
          .add(() => {
              this.subs.sink = this.peopleApi.getEducations().subscribe(edu => {
                aux['educations'] = edu.data;
              },
              err => {
                console.error('Error getting Educations');
              }).add(() => {
                aux['occupations'] = [];
                aux['genders'] = this.getGenders();
                this.setFormData(aux);
              });
          });
      }
    });
  }

    /**
     * Introduces all entryTypes available for projects
     */
    generateTesttakerGenderDropdown(): string[] {
      let statusList:any[] = []

      //Add the project entry types
      const values = ['male','female'];

      values.forEach(item => {
        statusList.push({value: item, label: this.translate.instant('SELECT-GENDER.' + item.toUpperCase())})
      });

      return statusList;
    }

}


