import { Component, OnDestroy, OnInit, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AssignationGroupsService } from 'src/app/shared/services/assignation-groups.service';
import { CreditsService } from 'src/app/shared/services/credits.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { SubSink } from 'subsink';
import { CoreApi } from '../api/core.api';
import { AuthenticationService } from '../authentication/authentication.service';
import * as moment from 'moment';
import { listAnimationV2 } from 'src/app/shared/animations/list';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { ReportingService } from 'src/app/modules/projects/services/reporting.service';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { CatalogService } from 'src/app/modules/catalog/services/catalog.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-profile-menu",
  templateUrl: "./profile-menu.component.html",
  styleUrls: ["./profile-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [listAnimationV2],
  providers: [ ToastService ]
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

  items: MenuItem[];
  isArrowRotate = '';
  creditNumber = 0;
  usesNumber = 0;
  userName = '';
  userLevel = '';
  showDialog = false;

  subs = new SubSink();

  hasSubsidiaryData = false;
  subsidiaryData: any;

  showCredits: boolean;
  showUses: boolean;

  isAdmin: PsyUserRoles;

  displayNotificationsBar: boolean = false;
  notificationsList = [];
  showShaking = false;
  loaded = false;
  customerDemo = false;
  currentLang: any;
  psyUserId: any;
  nonValidQuest = [];
  downloading : boolean = false;

  readonly psyUserRoles = PsyUserRoles;

  constructor(
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    private assignationGroupSrv: AssignationGroupsService,
    private socketSrv: SocketService,
    private projectsSrv: ProjectsService,
    private coreApi: CoreApi,
    private projectsApi: ProjectsApi,
    private router: Router,
    private creditsSrv: CreditsService,
    private toastSrv: ToastService,
    private reportingSrv: ReportingService,
    private catalogSrv: CatalogService,
    private errorsSrv: ErrorsService) {}

  ngOnInit(): void {
    this.loaded = false;
    // Preserve the lang to refresh the page in language changes
    this.currentLang = this.translate.currentLang;
    this.subs.sink = this.translate.onLangChange.subscribe(res => {
      if(res.lang !== this.currentLang)
        this.initComponent();
    });

    this.subs.sink = this.authSrv.sharedCurrentSession.subscribe(data => {
      this.initComponent();
    });

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res=>{
      this.customerDemo = res?.customer?.demo;
    });
  }

  initComponent(): void {
    const currentUser = this.authSrv.getCurrentSession();
    // Get the initial data of the psy-user
    this.subs.sink = this.coreApi.getProfileData().subscribe(
      res => {
        if (res.data.customer.frontApplication) {
            // if customer.frontApplication present, it must match the environment one
            if (res.data.customer.frontApplication.name?.toLocaleLowerCase() !== environment.frontApplication?.toLocaleLowerCase()) {
              this.authSrv.logout();
              return;
            }
        }

        // Set the correct qualification based on assignation group
        this.subs.sink = this.assignationGroupSrv.getHigherAssignationGroupCode(res.data.id).subscribe(q => {
          res.data.qualification =q.data;
          this.userLevel = q.data;
        });

        let psyUser = this.authSrv.assignPlanTypes(res.data);

        this.authSrv.setCurrentPsyUser(psyUser);
        this.isAdmin = psyUser.isAdmin;
        this.showCredits = psyUser?.hasLegacyPlan;
        this.showUses = psyUser?.hasUsesPlan;
        this.psyUserId = psyUser?.id;

        this.loadMenu();

        // Connect to socket to get notifications
        this.socketSrv.connect();
        this.loadNotifications();

        // Get psyUser credits
        if(this.showCredits){
          this.setPsyUserCredits();
        }

        // Get psyuser uses
        if(psyUser.hasUsesPlan) {
          this.setPsyUserUses();
        }

        this.loaded = true;
      },
      error => {
        try{
          this.subs.sink.unsubscribe();
        }catch(ex){
          // Do nothing
        }
        this.authSrv.logout();
      });

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {
      this.userName = dataUsr?.name;
      this.usesNumber = dataUsr?.uses;
      this.subs.sink = this.authSrv.sharedCurrentPsyUserCredits.subscribe(psyUserCredits => {
          this.creditNumber = psyUserCredits;
          this.loadMenu();
      });

      this.subs.sink = this.authSrv.sharedCurrentPsyUserUses.subscribe(psyUserUses => {
        this.usesNumber = psyUserUses;
        this.loadMenu();
      });

    });
    this.subs.sink = this.authSrv.sharedChangePsyUserCredits.subscribe(_res =>{
      if (_res > 0){
        this.setPsyUserCredits();
      };
    })  ;
  }

  loadMenu(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      let arrItems:any = []
        if(this.showCredits){
          arrItems.push(
            {
              label: `${this.creditNumber} ${this.translate.instant('PROFILE.CREDITS')}`,
              styleClass: 'credits-item'
            }
          )
        }

        if(this.showUses){
          arrItems.push(
            {
              label: `${this.usesNumber} ${this.translate.instant('PROFILE.USES')}`,
              styleClass: 'credits-item'
            }
          )
        }

        arrItems.push(
          {
            label: this.showCredits ? this.translate.instant('PROFILE.BUY_CREDITS') : this.translate.instant('PROFILE.SINGLE_BUY'),
            command: () => {
              this.buyCredits();
            },
            styleClass: 'buy-item'
          }
        )

        arrItems.push(
          {
            label: this.translate.instant('PROFILE.PROFILE'),
            icon: 'pi pi-cog',
            command: () => {
              this.router.navigate(['profile']);
            },
            styleClass: 'profile-item'
          }

        )


      if (this.isAdmin !== PsyUserRoles.PSYUSER) {
        arrItems.push({
          label: this.translate.instant('PROFILE.ACCOUNTS'),
          icon: 'pi pi-user',
          command: () => {
            this.router.navigate(['admin']);
          },
          styleClass: 'profile-item'
        });
      }

      arrItems.push(
        {
          label: this.translate.instant('PROFILE.EXIT'),
          icon: 'pi pi-sign-out',
          command: () => {
            this.authSrv.logout();
          },
        });

      this.items = [
        {
          items: arrItems
        }
      ];
    });
  }

  arrowToBottom(): void {
    this.isArrowRotate = 'rotate';
  }

  arrowToTop(): void {
    this.isArrowRotate = 'no-rotate';
  }

  buyCredits(): void{
    if (this.translate.currentLang === 'it'){
      this.creditsSrv.buyCredits();
    } else {
      this.subs.sink = this.coreApi.getSubsidiaryData().subscribe(
        res => {
          if(res.data){
            this.hasSubsidiaryData = true;
            this.subsidiaryData = res.data;
          } else {
            this.hasSubsidiaryData = false;
          }
        },
        err => {
          this.hasSubsidiaryData = false;
        },
        () => {
          this.showDialog = true;
        }
      );
    }
  }

  loadNotifications(): void {
    const psyUserId = this.authSrv.getCurrentPsyUser().id;
    this.subs.sink = this.coreApi.getScoringNotifications(psyUserId).subscribe( async res => {
      // Assign response to notifications list
      this.notificationsList = res.data;

      //calculate warning
      this.notificationsList.forEach(_notification => {
        if ((_notification.statusOk && !_notification.valid)){
          this.nonValidQuest.push(_notification)
        };
      });

      // Format notifications
      this.notificationsList = await this.groupNotificationByProject(this.notificationsList, ProjectStatusString.SCORING_ONGOING)

      this.subs.sink = this.coreApi.getReportingNotifications(psyUserId).subscribe(async res => {
        let reportNotificationList = res.data;

        // Format notifications
        reportNotificationList = await this.groupNotificationByProject(reportNotificationList, ProjectStatusString.REPORT_ONGOING)

        // Merge the array of report and scoring notifications
        this.notificationsList = [...this.notificationsList, ...reportNotificationList];

        // Order by date the array of scoring and reporting notifications merged
        this.notificationsList.sort((a, b) => (moment(b.createdAt).diff(moment(a.createdAt))));
      })
    });

    // This function is triggered when the system get a scoring notification
    this.subs.sink = this.authSrv.sharedScoringNotifications.subscribe(res => {
      if(res){
       this.updateNotificationList(res, ProjectStatusString.SCORING_ONGOING);
      }
    });

    // This function is triggered when the system get a reporting notification
    this.subs.sink = this.authSrv.sharedReportingNotifications.subscribe(res => {
      if(res){
        if(res.statusOk){
          this.setPsyUserCredits();
        }
        this.updateNotificationList(res, ProjectStatusString.REPORT_ONGOING);
      }
    });

    // This function is triggered when the system get a download notification
    this.subs.sink = this.authSrv.sharedDownloadReportingNotifications.subscribe(res => {
      if(res){
        if(res.statusOk){
          let uniq = {};
          res.downloads = res.questionnaire.reportingResults.filter(obj => obj.served && !uniq[obj.url] && (uniq[obj.url] = true));
          this.download(res)
        }
      }
    });
  }

  deleteAllNotifications() {
    // Delete the notification in db
    this.subs.sink = this.coreApi.deleteAllNotifications(this.authSrv.getCurrentPsyUser().id).subscribe(res => {
      if(res.statusOk) {
          //Delete the item/s in app list
          this.notificationsList = [];
        }
    });
  }

  deleteNotification(item?) {
    // Delete the notification in db
    this.subs.sink = this.coreApi.deleteNotification(this.authSrv.getCurrentPsyUser().id, item?.project_id, item?.type).subscribe(res => {
      if(res.statusOk) {
        //Delete the item/s in app list
        if(item){
          this.notificationsList = this.notificationsList.filter(x => !(x.id == item.id && x.type == item.type));
        } else {
          this.notificationsList = [];
        }
      }
    });
  }

  reloadListInView() {
    // To force re-view of notifications
    this.loaded = false;

    setTimeout( () => {
      this.notificationsList = [...this.notificationsList];
      this.loaded = true;
    }, 0 );
  }

  download(item){
    this.downloading  = true;
    this.downloadPurchasedReports(item);
  }

  public async downloadPurchasedReports(notification) {
    let item = {
      id: notification.project.id,
      projectType: notification.project.projectType,
      projectName: notification.project.code,
      projectSupply: notification.project.supply
    }
    let downloadQuestioners = [];
    if(notification.downloads.length > 0) {
      notification.downloads.forEach(reportingResult => {
          downloadQuestioners.push({...item,testtaker_id: reportingResult.testtaker_id == undefined?notification.questionnaire.testtaker_id:reportingResult.testtaker_id , reportingResult:reportingResult});
      })
    }

    if(!notification.testtakers || notification.testtakers.length < 0) {
      this.projectsApi.getProject(item.id).subscribe((detailProjects) => {
        let data = detailProjects.data;
        let findedUsers = []
        data.projectHasTestTakers.map((pht) => {
          notification.downloads.forEach(reportingResult => {
            if(pht.testtaker_id === reportingResult.testtaker_id)
              findedUsers.push(pht.testaker)
          })
        })
        item["testtakers"] = findedUsers;
        this.handleDownload(item, downloadQuestioners);
      })
    } else {
      await this.handleDownload(item, downloadQuestioners);
    }
  }


  goToProject(item) {
    this.subs.sink = this.projectsApi.getProject(item.project.id).subscribe(
      res => {
        let project = res.data;

        // Format project to view detail
        project.testtakers = project.projectHasTestTakers.map(z => z.testaker);
        project.supply = this.projectsSrv.getCustomEntryType(project.entryType_id);
        this.projectsSrv.setProjectType(project, [])
        this.projectsSrv.setCurrentProject(project);

        // Close notification navbar
        this.displayNotificationsBar = false;

        // Delete notification of the list and db
        this.deleteNotification(item);

        // Go to to project detail
        setTimeout( () => {
          this.router.navigate(["projects", "details"]);
        }, 500);
      },
      error => {
        console.error(error)
      });
  }

  updateNotificationList(notification: any, type: string){

    // Shaking reference
    this.showShaking = true;
    setTimeout( () => {
      this.showShaking = false;
    }, 4000 );

     // Set Type
     notification.type = type;
     // Check if the list have previous reference to a project
     if(this.notificationsList.some(x => x.project_id == notification.project_id && x.type == notification.type)) {

      // Exist previous notification
      this.notificationsList.forEach(async _notification => {
        if(_notification.project_id === notification.project_id && _notification.type == notification.type){
          if(notification.statusOk){

            if(notification.statusWarning || notification.valid == false){
                _notification.statusWarning++;
                console.log("statusWarning "+_notification.statusWarning);
              } else {
                _notification.statusSuccess++;
                console.log("statusSuccess "+_notification.statusSuccess);
              }

              if(type === ProjectStatusString.REPORT_ONGOING){ // add new downloads
                let reportingResults = _notification.downloads.concat(notification.questionnaire.reportingResults);
                let uniq = {}
                reportingResults = reportingResults.filter(obj => obj.served && !uniq[obj.url] && (uniq[obj.url] = true));
                _notification.downloads = reportingResults;
                console.log("downloads "+ reportingResults.length);
              }
          }else{
            _notification.statusFail++;
            console.log("statusFail "+_notification.statusFail);
          }
        }
      })
     } else {
      console.log("new notification statusOk: "+notification.statusOk+ " statusWarning: "+notification.statusWarning);
      // Add new notification project to current the list
      notification.statusSuccess = 0;
      notification.statusWarning = 0;
      notification.statusFail = 0;
      if(notification.statusOk){
        if(notification.statusWarning || notification.valid == false){
          notification.statusWarning = 1;
        } else {
          notification.statusSuccess = 1;
        }
      }else{
        notification.statusFail = 1;
      }
      if(type === ProjectStatusString.REPORT_ONGOING){
        if(notification.statusOk){
          let uniq = {};
          notification.downloads = notification.questionnaire.reportingResults.filter(obj => obj.served && !uniq[obj.url] && (uniq[obj.url] = true));
          console.log("downloads "+ notification.downloads.length);
        }else{
          notification.downloads = [];
        }
      }
      this.notificationsList.push(notification);
     }

     this.notificationsList = this.notificationsList.map(x => ({...x, tooltip: x.type === 'reporting' ? x.downloads.length > 0 ? this.translate.instant('PROFILE.STATUS-DOWN-REP') :'':'' }));

     // Order list by date
     this.notificationsList.sort((a, b) => (moment(b.createdAt).diff(moment(a.createdAt))));

     // Update the status of the project component in the list (needed for project details)
     this.projectsSrv.setUpdatedStatus(notification);

     // V2 Update the status of the project component in the list
     this.projectsSrv.getProjectStatusAndEmitChanges(notification.project_id);
  }

  async groupNotificationByProject(notificationList, type){

    if(notificationList.length>0){
      notificationList = notificationList.map(x => ({...x, type: type}));
    }

    // Order notificationslist by date
    notificationList.sort((a, b) => (moment(b.createdAt).diff(moment(a.createdAt))));

    // Group by project
    notificationList = notificationList.reduce((x, z) => {
      (x[z['project_id']] = x[z['project_id']] || []).push(z);
      return x;
    }, {});

    // Get first notification and add info
    let aux = []
    for (const [key] of Object.entries(notificationList)) {
      let firstNotification = notificationList[key][0];

      //Remove non-valid duplicates
      const projectNonValidQuest = [...new Map(this.nonValidQuest.filter(x => x.project_id == key).map((x)=> [x["id"], x])).values()];

      firstNotification.statusWarning = projectNonValidQuest.length;
      firstNotification.statusSuccess = (notificationList[key].filter(x => x.statusOk).length) - firstNotification.statusWarning;
      firstNotification.statusFail = notificationList[key].filter(x => !x.statusOk).length;

      if(type === ProjectStatusString.REPORT_ONGOING){
        let reportingResults = [];
        for (let i = 0; i < notificationList[key].length; i++) {
          reportingResults = reportingResults.concat(notificationList[key][i].questionnaire.reportingResults.map((element) => ({
            ...element,
            testtaker_id: notificationList[key][i].questionnaire.testtaker_id
          })));
        }
        let uniq = {};
        reportingResults = reportingResults.filter(obj => obj.served && !uniq[obj.url] && (uniq[obj.url] = true));
        firstNotification.downloads = reportingResults;
        firstNotification.tooltip = reportingResults.length > 0 ? this.translate.instant('PROFILE.STATUS-DOWN-REP') :'';
      }

      delete firstNotification.statusOk;
      aux.push(firstNotification);
    }
    return aux;
  }

  setPsyUserCredits(){
    this.subs.sink = this.coreApi.getPsyUserCredits(this.psyUserId).subscribe(creditsRes => {
      this.authSrv.setCurrentPsyUserCredits(creditsRes.data)
    }, err => {
      console.error(err);
      this.toastSrv.showToastError(this.translate.instant('ACCOUNTS.ERROR-PSYUSER-CREDITS'))
    })
  }

  setPsyUserUses(){
    this.coreApi.getPsyUserUses(this.psyUserId).subscribe(res => {
      this.authSrv.setCurrentPsyUserUses(res.data)
    }, err => {
      console.error(err);
      this.toastSrv.showToastError(err.message)
    });
  }

  handleDownload(item, downloadQuestioners) {
    this.reportingSrv.downloadServedReports(item, downloadQuestioners).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE ) {
        // Messages
        let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          res,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
          }
        )

        this.toastSrv.showToastSucess(messageToShow.translatedMessage);
      }
    }).catch(err => {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.ERROR_REPORT'));
      console.error(err)
    })
    this.downloading = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
