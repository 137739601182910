<!-- Spinner -->
<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
    <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<div *ngIf="loaded">
    <div *ngIf="!planToShow">
        <app-empty-template 
            [text]="('ACCOUNTS.NO-PLANS' | translate)">
        </app-empty-template>
    </div>
    <div *ngIf="planToShow" class="dialog-content">
        <!--Subscription Name-->
        <h2>{{plan.plan_name}} <span *ngIf="!plan.isAlive">[{{'PROFILE.INACTIVE' | translate}}]</span></h2> 
        <div class="detail-subtitle">{{'DETAIL-PEOPLE.INFO' | translate}}</div>

        <!--Subscription Ids-->
        <div class="detail-label p-d-flex p-my-2">
            <i class="pi pi-info-circle"></i>
            <div>
                <div>{{'ACCOUNTS.SUBSCRIPTION' | translate}}:
                    <!--If plan has psyuserSub_id show the as a customerHasPLanHasPsyuser reference-->
                    <span class="detail-data">{{plan.sub_id | padNumber: 10}} {{plan.psyuserSub_id ? '#' + plan.psyuserSub_id : ''}}</span>
                </div>
            </div>
        </div>
        
        <!--Period-->
        <div class="detail-label p-d-flex"> 
            <i class="pi pi-calendar"></i>
            <div>{{'ACCOUNTS.PERIOD' | translate}} 
                <span *ngIf="!moreThan100(plan.startDate, plan.finishDate)" class="detail-data">{{plan.startDate | date: 'dd/MM/yyyy'}} - {{plan.finishDate | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="!moreThan100(plan.startDate, plan.finishDate) && plan.demo && !checkLessThanOneDayLeft(plan) && !checkOneDayLeft(plan)" class="detail-data"> ({{calculateDaysLeft(plan)}} {{'ACCOUNTS.DAYS-LEFT' | translate}})</span>
                <span *ngIf="!moreThan100(plan.startDate, plan.finishDate) && plan.demo && !checkLessThanOneDayLeft(plan) && checkOneDayLeft(plan)" class="detail-data"> (1 {{'ACCOUNTS.DAY-LEFT' | translate}})</span>
                <span *ngIf="!moreThan100(plan.startDate, plan.finishDate) && plan.demo && checkLessThanOneDayLeft(plan) && !checkOneHourLeft(plan)" class="detail-data"> ({{calculateHoursLeft(plan)}} {{'ACCOUNTS.HOURS-LEFT' | translate}})</span>
                <span *ngIf="!moreThan100(plan.startDate, plan.finishDate) && plan.demo && checkLessThanOneDayLeft(plan) && checkOneHourLeft(plan)" class="detail-data"> (1 {{'ACCOUNTS.HOUR-LEFT' | translate}})</span>
                <span *ngIf="moreThan100(plan.startDate, plan.finishDate)" class="infin">&infin;</span>
            </div>
        </div>
        
        <!--Based in Credit or Uses-->
        <div class="detail-label p-d-flex p-my-2">
            <i class="pi pi-book"></i>
            <div>
                <div *ngIf="!plan.usesBased">{{'ACCOUNTS.PLAN-TYPE' | translate}}
                    <span class="detail-data">{{'ACCOUNTS.CREDIT-BASED' | translate}}</span></div>
                <div *ngIf="plan.usesBased">{{'ACCOUNTS.PLAN-TYPE' | translate}}
                    <span class="detail-data">{{'ACCOUNTS.USES-BASED' | translate}}</span></div>
            </div>
        </div>       

        <!--Deduction type (just informative)-->
        <div class="detail-label p-d-flex p-my-2" *ngIf="getDeduction(plan)!==undefined">
            <i class="pi pi-cog"></i>
            <div>
                {{'ACCOUNTS.CONSUM-TYPE' | translate}}
                <span class="detail-data">{{'ACCOUNTS.BY' | translate}} {{'ACCOUNTS.LABEL-' + (getDeduction(plan) | uppercase) | translate }}</span>
            </div>
        </div>
        
        <!--Show available consumes of the psyuser's plan-->
        <div class="detail-label p-d-flex p-my-2" *ngIf="!plan.usesBased"> 
            <i class="pi pi-wallet"></i>
            <div>{{'ACCOUNTS.CREDIT-AVAILABLE' | translate}}:                
                <span class="detail-data" *ngIf="plan.psyuserSub_id">{{psyUserConsumesInfo.total}}</span>
                <span class="detail-data" *ngIf="!plan.psyuserSub_id">{{availableCredits}}</span>
            </div>
        </div>
        
        <!--Show total consumes of credit plan-->
        <div class="detail-label p-d-flex p-my-2" *ngIf="!plan.usesBased">            
            <i class="pi pi-minus-circle"></i>
            <div>{{'ACCOUNTS.CREDIT-CREDITS' | translate}}: <!--Columna créditos consumidos-->
                <span class="detail-data" *ngIf="plan.psyuserSub_id">{{psyUserConsumesInfo.consumed * -1}}</span>
                <span class="detail-data" *ngIf="!plan.psyuserSub_id">{{subscriptionConsumption}}</span>
            </div>
        </div>
        
        <!--Number of requested reports-->
        <div class="detail-label p-d-flex p-my-2">
            <i class="pi pi-chart-line"></i>
            <div *ngIf="!plan.psyuserSub_id">
                <div *ngIf="!plan.usesBased">{{'ACCOUNTS.REPORT-USES' | translate}}
                    <span class="detail-data">{{getTransactionHistoryCountsByCredits()}}</span>
                </div>
                <div *ngIf="plan.usesBased">{{'ACCOUNTS.TOTAL-USES' | translate}}                    
                    <span class="detail-data">{{subscriptionConsumption}}/{{plan.aggLimit ?? 0}}</span>
                </div>
            </div>            
            <div *ngIf="plan.psyuserSub_id">
                <div *ngIf="!plan.usesBased">{{'ACCOUNTS.REPORT-USES' | translate}}
                    <span class="detail-data">{{getTransactionHistoryCountsByCredits()}}</span>
                </div>
                <div *ngIf="plan.usesBased">{{'ACCOUNTS.TOTAL-USES-GLOBAL' | translate}}
                    <span class="detail-data">{{getTransactionHistoryCountsByUses()}}/{{plan.aggLimit}}</span>
                </div>
            </div>
        </div>

        <!--Number of reports/scoring requested of a psyuser-->
        <div class="detail-label p-d-flex p-my-2" *ngIf="plan.psyuserSub_id && plan.usesBased">
            <i class="pi pi-user-plus"></i>                 
            <div>                
                <div>{{'ACCOUNTS.TOTAL-USES-USER' | translate}}
                    <span class="detail-data">{{getTransactionHistoryCountsByUsesAndPsyUser()}}</span>
                </div>
            </div>
        </div>

        <!--TABLE OF REPORTS USES-->
        <p-table [value]="dataTable" responsiveLayout="scroll" [autoLayout]="true" [scrollable]="true" scrollHeight="50vh">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'ACCOUNTS.TEST' | translate}}</th> 
                    <!--REPORT-->
                    <th *ngIf="getDeduction(plan) === 'reporting'">{{'ACCOUNTS.REPORT' | translate}}</th>
                    <!--CREDITS-->
                    <th *ngIf="!plan.usesBased" class="p-text-center">{{'ACCOUNTS.CREDIT-REPORTS' | translate}}</th>
                    <th *ngIf="!plan.usesBased" class="p-text-center">{{'ACCOUNTS.CREDIT-CREDITS' | translate}}</th>
                    <!--USES-->
                    <th *ngIf="plan.usesBased">{{'ACCOUNTS.USES-SUMMARY' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-test>
                <tr>
                    <td>{{test.testKey}}</td>
                    <!--REPORT-->
                    <td *ngIf="getDeduction(plan) === 'reporting'">
                        <div class="p-d-flex p-flex-column p-jc-start">
                            <span>{{test.reportName}}</span>
                            <span class="credit" *ngIf="!plan.usesBased">{{test.price}} {{'PROFILE.CREDITS' | translate}}</span>
                        </div>
                    </td>
                    <!--CREDITS-->
                    <td *ngIf="!plan.usesBased" class="p-text-center"><span class="use">{{test.creditUses}}</span></td> <!-- col informes -->
                    <td *ngIf="!plan.usesBased" class="p-text-center">{{test.uses}}</td><!-- col creditos -->
                    <!--USES-->
                    <td *ngIf="plan.usesBased" class="p-text-center">
                        <div *ngIf="test.limitUses === test.uses" class="pointer reached-limit"
                            pTooltip="{{'ACCOUNTS.LIMIT' | translate}}" placeholder="Top" tooltipZIndex="99999">
                            <span class="use">{{test.uses}}</span><span *ngIf="test.limitUses">/{{test.limitUses}}</span>
                        </div>
                        <div *ngIf="test.limitUses !== test.uses">
                            <span class="use">{{test.uses}}</span><span *ngIf="test.limitUses">/{{test.limitUses}}</span>
                        </div>
                    </td>
                </tr>
            </ng-template> 
        </p-table>

        <hr class="p-my-4">
        
        <!--PsyUsers of the subscription-->
        <div class="detail-label p-d-flex p-jc-between p-ai-center p-my-2" *ngIf="!plan.psyuserSub_id">            
            <div class="p-d-flex">
                <i class="pi pi-user"></i>
                <div>{{'ACCOUNTS.USERS' | translate}} <span class="detail-data">{{planUsers?.length}}/{{plan.nPsyUsers}}</span></div>
            </div>            
            <p-avatarGroup styleClass="p-mb-3">
                <p-avatar *ngFor ="let user of planUsers"
                    class="plan-avatar pointer"
                    shape="circle"
                    styleClass="p-mr-2"
                    size="large"
                    label="{{user.name.charAt(0)}}"
                    pTooltip="{{user.name + ' ' + user.surname}}" 
                    (click)="managePsyUser(user)"
                    tooltipPosition="top">
                </p-avatar>
            </p-avatarGroup>
        </div>
  </div>  
</div>