import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { TranslateService } from '@ngx-translate/core';
import { Paginator } from 'primeng/paginator';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { PadNumberPipe } from 'src/app/shared/pipes/pad-number.pipe';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { AdminApi } from '../../api/admin.api';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  providers: [ ToastService, DatePipe, PadNumberPipe ]
})
export class AccountsComponent implements OnInit, OnDestroy  {

  @ViewChild('paginator') pag: Paginator;
  @ViewChild('filter') filter: FilterComponent;

  private subs = new SubSink();

  // Loaders
  loaded = false;

  // Info Ui
  rowsToDisplayDefault: number = 5;
  topRow = this.rowsToDisplayDefault;
  private confirmSubscription: Subscription;

  // Show Pop-ups
  showPlanInfo = false;  
  showCustomerSubscriptions = false;

  // PsyUsers List Data
  originalListPsyUsers = [];
  customerPsyUsers = [];
  public listUserChunk = [];
  
  // Current Psyuser (using the app) 
  private psyuser;

  // Customer Data
  customerName = '';
  availablePlans2 = []; // The subscriptions list of the customer 
  customerConsumesInfo: any;  // The consumes of credits/uses of the customer
  customerConsumptionsByPlan: any;

  // Plan details
  planToShow : any;   

  readonly psyUserRoles = PsyUserRoles;
  

  constructor(
    private adminSrv: AdminService,
    private translate: TranslateService,
    private router: Router,
    private toastSrv: ToastService,
    private toastConfirmSrv: ToastConfirmService,
    private adminApi: AdminApi,    
    private authenticationService:AuthenticationService,
    private planSrv: PlanService,
    private authSrv: AuthenticationService
  ) { }  

  ngOnInit(): void {
    this.subs.sink = this.authenticationService.sharedCurrentPsyUser.subscribe(resShared=>{
      if (resShared) {

        let customerId = resShared.customer.id;
        this.psyuser = resShared;

        // Set customer name
        this.customerName = this.authSrv.getCurrentCustomerName();

        // Get all plans V2        
        this.planSrv.getCustomerPlans(customerId).then(res => {
          this.availablePlans2 = res;  

          // Get available and consumed uses/credits
          this.planSrv.getCustomerBalance(customerId).then(res => {            

            this.customerConsumesInfo = res.aggregatedConsumesInfo;
            this.customerConsumptionsByPlan = res.consumptionsByPlan;

            // Get customer psyusers
            this.adminApi.getCustomerPsyUsers(customerId).subscribe(res => {
              // Preserve the oriringal array list
              if(res.statusOk) {
                this.originalListPsyUsers = res.data; // Preserve the original list
                this.customerPsyUsers = this.originalListPsyUsers;
                this.setPlansAndCreditsToPsyUsers();

                // Set customer users
                this.listUserChunk = this.customerPsyUsers.slice(0, this.rowsToDisplayDefault);
                this.loaded = true;
                if (this.filter){
                  this.filter.isFiltered = false;
                }
              }            
            });
          }, err => {
            this.toastSrv.showToastError(err);
          });       
        }, err => {
          this.toastSrv.showToastError(err);
        });
      }  
    });    
  }
  
  paginate(event): any {
    this.topRow = event.first + event.rows;
    if (this.topRow > this.customerPsyUsers.length) {
      this.topRow = this.customerPsyUsers.length;
    }
    this.listUserChunk = this.customerPsyUsers.slice(event.first, this.topRow);
  }

  generateMenu(item): any {
    const opt_menu = {
      label: 'Options',
      items: [
        {
          label: this.translate.instant('ACCOUNTS.EDIT'),
          icon: 'pi pi-user-edit',
          command: () => {
            this.adminSrv.setPsyToEdit(item);
            this.router.navigate(['admin', 'edit'], {queryParams: {type: 'EDIT'}});
          }
        }
      ]};
    return [opt_menu];
  }

  goToCreateNew(): void {
    this.router.navigate(['admin', 'edit'], {queryParams: {type: 'NEW'}});
  } 

  /**
   * Show plan details   
   */
  showPlan(plan): void {

    this.planToShow = plan;

    this.showPlanInfo = true;
  }

  setPlansAndCreditsToPsyUsers(){
    this.originalListPsyUsers.forEach(_psyuser => {
      _psyuser.plans = [];
      _psyuser.numCredits = 0;      

      _psyuser.customerHasPlanHasPsyusers.forEach(_item => {
        let planAux = this.availablePlans2.find(_sub => _sub.sub_id == _item.customerHasPlan_id);     

        // Set only alive plans
        if(planAux && planAux.isAlive){
          planAux.name = planAux.plan_name;
          _psyuser.plans.push(planAux);          

          let consumesPlan = this.customerConsumptionsByPlan.find(x => x.id == _item.customerHasPlan_id);

          if(consumesPlan && consumesPlan.creditsConsumptionByPsyuser){
            let consumesPsyUser = consumesPlan.creditsConsumptionByPsyuser.find(z => z.id == _psyuser.id);            

            if(consumesPsyUser && consumesPsyUser.total){
              _psyuser.numCredits += consumesPsyUser.total;
            }
          }          
        }
      })      
    })    
  }

  managePsyUser(user){
    this.adminSrv.setPsyToEdit(user);
    this.router.navigate(['admin', 'manage']);
  }  

  applyFilter($event): any {
    if (this.loaded) {
      this.customerPsyUsers = $event;
      this.listUserChunk = this.customerPsyUsers.slice(0, this.rowsToDisplayDefault);
    }
  }
  
  resetFilter($event): any {
    if (this.loaded){
      this.customerPsyUsers = this.originalListPsyUsers;      
      this.listUserChunk = this.customerPsyUsers.slice(0, this.rowsToDisplayDefault);
    }
  }
  

  ngOnDestroy(): void {
    this.toastSrv.closeToastConfirm();
    this.subs.unsubscribe();
  }
}
