import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private authSrv: AuthenticationService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authSrv.getCurrentToken();

    // Add header to every call
    let request = req;
    if ((req.url.includes('https://dante-fronts-dev.s3.eu-west-1.amazonaws.com/reporting/') || 
        req.url.includes('https://dante-fronts-pre.s3.eu-west-1.amazonaws.com/reporting/') || 
        req.url.includes('https://dante-fronts.s3.eu-west-1.amazonaws.com/reporting/'))
        && req.method === 'GET') {
        request = req.clone({
        setHeaders: {
            'Content-Type': 'application/json'
        }
    });
    }else if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`,
          'Content-Type': 'application/json'
        }
      });
    }

    // Return of the call
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 && error.error.message === 'invalid-token') ||
            (error.error.code === 'expired' && error.error.status === 'error' )) {
          this.authSrv.refreshToken();
        } else if ((error.status === 400 && error.error.message === 'invalid-refresh-token') ||
                    (error.status === 498 && error.error.code === 'expired') ||
                    (error.status === 401 && error.error.code === 'unauthorized')) {
          this.authSrv.logout();
        }

        return throwError( error );

      })
    );
  }
}
