import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NumericValueType, ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { PsyUserRoles, QuestionnaireCodeStatus, Status } from '@giuntipsy/utils/lib';
import { ProjectsApi } from '../../api/projects.api';
import { ProjectsService } from '../../services/projects.service';
import { ScoringService } from '../../services/scoring.service';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-answers-project',
  templateUrl: './answers-project.component.html',
  styleUrls: ['./answers-project.component.scss'],
  providers: [ToastService],
  animations: [inOutAnimation]
})
export class AnswersProjectComponent implements OnInit, OnDestroy {

  qCode: string;
  questionnaire: any;
  manualEntryType: number = 1;
  arrItems: any = [];
  arrProfileData: any = [];
  arrPsyQuestionsScoringReporting: any = [];
  mandatoriesProfile: any = [];
  arrPsyuserData: any = [];
  mandatoriesPsyUser: any = [];
  arrDefaultScore: any = {};
  arrSelectItems: string[] = [];
  testStructure: any;
  questionnaireStructure: any;
  loaded: boolean = false;
  canDoRandomize: boolean = false;
  editForm: UntypedFormGroup;
  profileForm: UntypedFormGroup;
  psyUserForm: UntypedFormGroup;
  psyUserScoringReportingQuestionsForm: UntypedFormGroup;
  compilationDate;
  maskEditable = true;
  showMore = false;
  mode = '';
  subtests: any;
  editFormScales: UntypedFormGroup;
  allItemsOrderToShow = [];
  subs = new SubSink();
  visibleScalesAtEntryMask: boolean = false;

  // get the structure of the test given the qCode
  private arrValidStatus = [QuestionnaireCodeStatus.ASSIGNED,
    QuestionnaireCodeStatus.COMPILED, QuestionnaireCodeStatus.READY_TO_SCORE, QuestionnaireCodeStatus.FILLING, QuestionnaireCodeStatus.SCORED_NON_VALID];
  labelItemType: string;


  constructor(
    private projectsApi: ProjectsApi,
    private projectsSrv: ProjectsService,
    private scoringSrv: ScoringService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    public location: Location,
    private el: ElementRef,
    private errorsSrv: ErrorsService,
    private primeNGConfig: PrimeNGConfig
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res => {
      if (res !== null) {
        if(!environment.production){
          this.canDoRandomize = [PsyUserRoles.PSYUSER_ADMIN, PsyUserRoles.PSYUSER_FULL].includes(res.isAdmin) ? true: false
        }
      }
    });

    this.subs.sink = this.projectsSrv.sharedQCode.subscribe(res => {
      if (res !== null) {
        this.qCode = res;
        this.route.queryParams.subscribe(params => {
          if (params['mode']) {
            this.mode = params['mode'];
          }
          this.getInfoFromQuestionnaire();
        });
      }
    });

    this.subs.sink = this.translate.onLangChange.subscribe(() => this.generateMessages());

    // Load translation for calendar filter
    this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  private getInfoFromQuestionnaire(): void{
    // get info from the db
    this.subs.sink = this.projectsApi.getQuestionaire(this.qCode).subscribe( (res: any) => {
      this.questionnaire = res.data;
      let _status = this.questionnaire.statusCode;
      let entryTypeId = this.questionnaire.project.entryType_id;

      if (entryTypeId === this.manualEntryType && _status != QuestionnaireCodeStatus.SCORED) {
        this.mode = ProjectStatusString.SCORING_ONGOING;
      }

      // if status is not one of the editable ones jump with errror
      if (!this.arrValidStatus.includes(_status)) {
        this.maskEditable = false;
      } else if(_status == QuestionnaireCodeStatus.SCORED_NON_VALID && entryTypeId != 1){
        this.maskEditable = false;
      }

      // set compilation date if exist
      if (this.questionnaire.compilationDate != null) {
        this.compilationDate = new Date(this.questionnaire.compilationDate);
      }

      // get structure of the test to build the forms from db
      this.subs.sink = this.projectsApi.getTestStructure(this.qCode).subscribe(res => {

        this.testStructure = res.data.test_structure;
        this.questionnaireStructure = res.data.questionnaire_structure;
        this.subtests = this.testStructure.subtests;

        //get labelItemType to pass it to the child component
        if (this.testStructure.testRules && this.testStructure.testRules.itemLabel) {
          this.labelItemType = this.testStructure.testRules.itemLabel
        }

        // set order of subtest if exist
        if (this.testStructure.testRules?.subtestOrder) {
          let aux = [];
          this.testStructure.testRules.subtestOrder.forEach(x => {
            aux.push(this.subtests.filter(z => z.customId == x)[0]);
          });
          this.subtests = aux;
        }

        if(this.subtests.length >= 0 && this.subtests[0] != undefined){
          this.subtests.forEach(subtest => {
            // push ordered items
            subtest.items?.sort((a, b) => a.itemId - b.itemId).forEach(_it => {
              _it.subtestId = subtest.subtestId;
              _it.subtestTitle = subtest.subtestTitle;
              this.arrItems.push(_it);
              this.arrDefaultScore[_it.itemId] = _it.responseOptions?.defaultScore != null ? _it.responseOptions.defaultScore : 99;
            });
          });
        }

        // ---------Profile Data ------------
        const formItems = this.testStructure.profileData?.formItems.filter(x => !x.psyQuestion);
        this.arrProfileData = formItems ? formItems : [];

        this.testStructure.profileData?.mandatoryForScoring.forEach(x => {
          this.mandatoriesProfile.push(x);
        });

        // assign the description of the group in fields with group
        this.arrProfileData.forEach(x => {
          if (x.group){
            x.groupDescription = this.testStructure.profileData.groups.filter(z => z.name === x.group)[0].label;
            x.commonOptions = this.testStructure.profileData.groups.filter(z => z.name === x.group)[0].commonOptions;
          }
        });

        // set option labels as string to avoid empty dropdown value
        this.arrProfileData.filter(x => x.type === 'select').forEach(x => {
          x.options.forEach(z => {
            z.label = z.label + '';
          });
        });

        this.generateProfileAndPsyUserForm(this.arrProfileData, false);

        const psyFormItems = this.testStructure.profileData?.formItems.filter(x => x.psyQuestion);
        this.arrPsyuserData = psyFormItems ? psyFormItems : [];
        // set option labels as string to avoid empty dropdown value
        this.arrPsyuserData.filter(x => x.type === 'select').forEach(x => {
            x.options.forEach(z => {
              z.label = z.label + '';
            });
          });

        // assign the description of the group in fields with group and common options boolean
        this.arrPsyuserData.forEach(x => {
          if (x.group){
            x.groupDescription = this.testStructure.profileData?.groups.filter(z => z.name === x.group)[0].label;
            x.commonOptions = this.testStructure.profileData?.groups.filter(z => z.name === x.group)[0].commonOptions
          }
        });

        this.mandatoriesPsyUser = this.testStructure.profileData?.mandatoryForPsy
        ? this.testStructure.profileData.mandatoryForPsy : [] ;
        if (this.arrPsyuserData.length > 0) {
          this.generateProfileAndPsyUserForm(this.arrPsyuserData, true);
        }

        // ---------Psy Questions For Scoring and Reporting ------------
        this.generatePsyUserScoringAndReportingQuestions(this.testStructure.scoringReportingData)

        // set min 0 and max 1 to checkbox items
        this.setCheckboxItems();
        this.generateItemsForm();
        this.generateMessages();

        // ---------Scales and Scores------------
        if(this.testStructure.scaleData && this.testStructure.scaleData.scales.length > 0){
          this.generateScaleSubsections();
        }

        // ---------Fill Questionnaire with previous data ------------
        if (_status !== QuestionnaireCodeStatus.ASSIGNED){
          this.fillQuestionnaire();
        }

        // ----------Set Subtest/Section/SubSection format -----------
        this.setSubtestSectionFormat();

        //End loading phase
        this.loaded = true;
      });
    });
  }

  public setColumnsStyle(subsectionColumns: number | undefined,  sectionColumns: number | undefined, subtestColumns: number | undefined) {
    const columns = subsectionColumns || sectionColumns || subtestColumns || 10;
    return { 'grid-template-columns': `repeat(${columns}, fit-content(100%))` };
  }

  // generates the form related to the profileData and psyUserData
  private generateProfileAndPsyUserForm(arrData, isPsyUser): void{
    const groupForm = [];

    if (arrData.length > 0){
      arrData.forEach(it => {
        const validators = [];
        let mandatoryItself = false;

        if (it.type === 'number'){
          validators.push(RxwebValidators.numeric());
        }

        if (it.type === 'select'){
          this.arrSelectItems.push(it.name);
        }

        try{
          if (it.validation[0].type === 'required'){
            mandatoryItself = true;
          }
        } catch (ex) {}

        if (isPsyUser) {
          if (this.mandatoriesPsyUser.includes(it.name) || mandatoryItself){
            validators.push(RxwebValidators.required());
            it.mandatory = true;
          }
        } else {
          if (this.mandatoriesProfile.includes(it.name) || mandatoryItself){
            validators.push(RxwebValidators.required());
            it.mandatory = true;
          }
        }
        groupForm[it.name] = ['', validators];
      });
      if (isPsyUser) {
        this.psyUserForm = this.formBuilder.group(groupForm);
      } else {
        this.profileForm = this.formBuilder.group(groupForm);
      }
    }
  }

  // generates the form related to the profileData and psyUserData
  private generatePsyUserScoringAndReportingQuestions(arrData): void{
    const groupForm = [];

    if (arrData != undefined && arrData.formItems.length > 0){
      arrData.formItems.forEach(it => {
        const validators = [];
        let mandatoryItself = false;

        if (it.type === 'number'){
          validators.push(RxwebValidators.numeric());
        }

        if (it.type === 'select'){
          this.arrSelectItems.push(it.name);
        }

        try{
          if (it.validation[0].type.toLowerCase() === 'required'){
            mandatoryItself = true;
          }
        } catch (ex) {}

        if (mandatoryItself){
          validators.push(RxwebValidators.required());
          it.mandatory = true;
        } else {
          it.mandatory = false;
        }

        this.arrPsyQuestionsScoringReporting.push(it)

        groupForm[it.name] = ['', validators];
      });

      this.psyUserScoringReportingQuestionsForm = this.formBuilder.group(groupForm);
    }
  }

  // generate form builder with the items
  private generateItemsForm(): void {
    //Check if we have items, if we don't, it is a test only with scales and we don't need to build this FormGroup
    if (this.arrItems.length > 0) {
      const groupForm = {};

      // for each item we have to see which is mandatory and which is not, and the conditions
      this.arrItems.forEach(it => {
        const Validators = [];

        if (it.isCheckbox) {
          Validators.push(RxwebValidators.minNumber({ value: it.min }));
          Validators.push(RxwebValidators.maxNumber({ value: it.max }));
        } else {
          let itemMin = 99;
          let itemMax = 99;
          it.responseOptions.options.forEach(option => {
            if ( itemMax == 99 || parseInt(option.value) > itemMax) {
              itemMax = parseInt(option.value);
            }
            if (itemMin == 99 || parseInt(option.value) < itemMin) {
              itemMin = parseInt(option.value);
            }
          });
          it.max = itemMax;
          it.min = itemMin;
          Validators.push(RxwebValidators.minNumber({ value: it.min }));
          Validators.push(RxwebValidators.maxNumber({ value: it.max }));
        }

        // add also numeric validator
        Validators.push(RxwebValidators.numeric());
        groupForm[it.itemId] = ['', Validators];
      });
      this.editForm = this.formBuilder?.group(groupForm);
    }
  }
  // fills the questionnaire with the info saved on db
  private fillQuestionnaire(): void{
    // fill psyUserData
    try{
      if (this.arrPsyuserData.length > 0) {
        Object.keys(this.questionnaire.scoringData.profileData).forEach(key => {
          try{
            if (this.arrSelectItems.includes(key)){
              const option = this.arrPsyuserData.find(x => x.name === key).options.find(x => x.value === this.questionnaire.scoringData.profileData[key]);
              if (option) {
                this.psyUserForm.controls[key].setValue(option.value);
              }
            }else{
              this.psyUserForm.controls[key].setValue(this.questionnaire.scoringData.profileData[key]);
            }
            if (this.questionnaire.scoringData.profileData[key] !== '') {
              this.psyUserForm.controls[key].markAsTouched();
            }
          } catch {
            // Ignore action
          }

        });
      }
    }catch{}

    // fill profile data form
    try{
      Object.keys(this.questionnaire.profileData).forEach(key => {
        try{
            if (this.arrSelectItems.includes(key)){
              // Get profile data value
              const profileDataValue = this.questionnaire.profileData[key];

              // Get selector item
              const selectItem = this.arrProfileData.find(x => x.name === key);

              // Get selected option of selector
              const selectedOption = selectItem.options?.find(x => x.value == profileDataValue);

              // Set selected option in select
              if(selectedOption){
                this.profileForm.controls[key].setValue(selectedOption.value);
              }
          }else{
            this.profileForm.controls[key].setValue(this.questionnaire.profileData[key]);
          }
          if (this.questionnaire.profileData[key] !== '') {
            this.profileForm.controls[key].markAsTouched();
          }
        }catch{
          // Not apply, because can have selected values and inputs as empty
        }
      });
    }catch {}

    // fill psy questions for scoring and reporing
    try{
      Object.keys(this.questionnaire.psyQuestionsForScoringAndReporting).forEach(key => {
        try{
            if (this.arrSelectItems.includes(key)){
              // Get profile data value
              const psyQuestionsForScoringAndReportingValue = this.questionnaire.psyQuestionsForScoringAndReporting[key];

              // Get selector item
              const selectItem = this.arrPsyQuestionsScoringReporting.find(x => x.name === key);

              // Get selected option of selector
              const selectedOption = selectItem.options?.find(x => x.value == psyQuestionsForScoringAndReportingValue);

              // Set selected option in select
              if(selectedOption){
                this.psyUserScoringReportingQuestionsForm.controls[key].setValue(selectedOption.value);
              }
          }else{
            this.psyUserScoringReportingQuestionsForm.controls[key].setValue(this.questionnaire.psyQuestionsForScoringAndReporting[key]);
          }
          if (this.questionnaire.psyQuestionsForScoringAndReporting[key] !== '') {
            this.psyUserScoringReportingQuestionsForm.controls[key].markAsTouched();
          }
        }catch{
          // Not apply, because can have selected values and inputs as empty
        }
      });
    }catch {}

    // fill items form
    try{
      this.questionnaire.questionnaireResponses.forEach(subtest => {
        subtest.responseItems.forEach(item => {
          this.editForm?.controls[item.itemId].setValue(item.responseValue == this.arrDefaultScore[item.itemId] ? null : item.responseValue);
          if (this.editForm?.controls[item.itemId].value !== null) {
            this.editForm?.controls[item.itemId].markAsTouched();
          }
        });
      });
    }catch {}

    // fill scale score form
    try{
      this.questionnaire.scaleData.forEach(_scale => {
        for (let _score in _scale.scores) {

          const _id = `${_scale.key}_${_score}`;
          // If read value is -1, need to be shown empty
          const _value = _scale.scores[_score] === -1 ? '' : _scale.scores[_score];

          try{
            this.editFormScales.controls[_id].setValue(_value);

            if (this.editFormScales.controls[_id].value !== null) {
              this.editFormScales.controls[_id].markAsTouched();
            }
            //If score is calculated it has no value in scaleData and will take the value of scoringResults
            if (!this.maskEditable && (this.editFormScales.controls[_id].value === "" || this.editFormScales.controls[_id].value === null)){

              // Try to find the scale in scoringResults
              const _scaleResult = this.questionnaire.scoringResults.find(_result => _result.key == _scale.key);
              if (_scaleResult.scores[_score]) {
                // Set scoring result in input, if value is -1, the input appear empty
                const _scoreValue = _scaleResult.scores[_score] === -1 ? '' : _scaleResult.scores[_score]
                this.editFormScales.controls[_id].setValue(_scoreValue);
              }
            }
          } catch {} // For cases that the _score is not defined in form
        }
      })
    }catch {}
  }

  private setSubtestSectionFormat(): void {
    this.subtests.forEach(_subtest => {

      if(_subtest) {
        // Set subtest type as standard or section based
        _subtest.subtestType = this.getSubtestOrSectionType(_subtest);
        // Set items into subtest object
        _subtest.items = this.getItemsOfSubtest(_subtest);
        // Set itemsn into sections
        if(_subtest.subtestType !== 'standard') {
          _subtest.sections = this.setItemsInSections(_subtest.subtestRules.sections);

          // Set section type as 'section' for single section or 'subsection' for a sections with sections inside
          _subtest.sections.forEach(_section => {
            _section.sectionType = this.getSectionOrSubSectionType(_section);

            if(_section.sectionType === 'subsection'){
              // Set items for every subsection
              _section.sections.forEach(_subsection => {
                _subsection = this.setItemsInSections([_subsection]);
              })
            };

          })
        }

        //Set format to parent and childs
        this.setItemFormat();

        //Get items order object
        this.getallItemsOrderToShowSections(_subtest);
      }
    });
  }

  /**
   * Save the questionnarie updated by the psyuser and perform the scoring if requested.
   * @param scoring
   */
  saveTest(scoring?): void{
    // get all data and prepare to format
    let date = this.compilationDate === undefined ? null : new Date(this.compilationDate).toISOString();

    // if the value is a number, remove string format
    this.setNumberInputsAsNumbers();

    //check if is needed add fields of psyUserScoringReportingQuestionsForm to scoringData and reportingData

    let psyMandatoriesForReporting = {}
    let psyMandatoriesForScoring = {}

    this.arrPsyQuestionsScoringReporting.forEach(_psyQ => {
      if(this.testStructure.scoringReportingData.mandatoryForReporting.includes(_psyQ.name)){
        psyMandatoriesForReporting[_psyQ.name] = this.psyUserScoringReportingQuestionsForm.get(_psyQ.name).value;
      }
      if(this.testStructure.scoringReportingData.mandatoryForScoring.includes(_psyQ.name)){
        psyMandatoriesForScoring[_psyQ.name] = this.psyUserScoringReportingQuestionsForm.get(_psyQ.name).value;
      }
    })

    let bodyJson = {
      compilationDate: date,
      profileData: this.profileForm?.value,
      psyMandatoriesForReporting: psyMandatoriesForReporting,
      psyMandatoriesForScoring: psyMandatoriesForScoring,
      scoringParams: this.arrPsyuserData.length > 0 ? this.psyUserForm.value : []
    };

    if(this.psyUserScoringReportingQuestionsForm != undefined)
      bodyJson["psyQuestionsForScoringAndReporting"] = this.psyUserScoringReportingQuestionsForm.value;

    // build the scale data if needed
    if(this.testStructure.scaleData) {
      let aux = [];

      this.testStructure.scaleData.scales.forEach(_scale => {
        let auxScores = {};
        _scale.scores.forEach(_score => {
          if(['integerRange','decimalRange'].includes(_score.type)){
            let lb = _score.id + '_lb';
            let ub = _score.id + '_ub';
            auxScores[_score.scoreName + '_lb'] = this.getScoreValueFromForm(this.editFormScales, lb, _score);
            auxScores[_score.scoreName + '_ub'] = this.getScoreValueFromForm(this.editFormScales, ub, _score);
          }else if (_score.type === 'itemRange'){
            auxScores[_score.scoreName] = this.getScoreValueFromForm(this.editFormScales, _score.id, _score);
            auxScores[_score.scoreName + '_selectedRange'] = _score.selectedRange;
          } else {
            auxScores[_score.scoreName] = this.getScoreValueFromForm(this.editFormScales, _score.id, _score);
          }
        })
        aux.push({
          key: _scale.key,
          scores: auxScores
        });
      });

      bodyJson["scaleData"] = aux;
    }

    //We check separately if you have items and scales
    if (this.testStructure.subtests[0].items && this.testStructure.subtests[0].items.length > 0) {
      // build item data
      let arrItems = {};
      Object.keys(this.editForm?.value).forEach(key => {
        const myval = this.editForm?.value[key];
        if (myval == null || myval === '') {
          arrItems[key] = this.arrDefaultScore[key];
        } else {
          arrItems[key] = parseInt(myval);
        }
      });
      // now build the questionnaire structure with the new values
      let qStr = this.questionnaireStructure;
      qStr.forEach(subtest => {
        subtest.responseItems.forEach(respItem => {
          respItem.responseValue = arrItems[respItem.itemId];
        });
      });
      bodyJson["questionnaireResponses"] = qStr;

    }

    this.subs.sink = this.projectsApi.saveQuestionnaire(bodyJson, this.qCode).subscribe(res => {
      if (scoring) {
        this.requestScoring();
      } else {
        let entryTypeId = this.questionnaire.project.entryType_id;
        if(entryTypeId === this.manualEntryType){
          let statusCode = QuestionnaireCodeStatus.FILLING;
          this.changeQuestionnaireStatusCode(this.questionnaire.questionnaireCode, statusCode)
        }
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-ENTRY-MASK-OK'));
        this.subs.sink = this.projectsApi.getProject(this.questionnaire.project_id).subscribe(res => {
          this.projectsSrv.setCurrentProject(res.data);
          this.router.navigate(['projects', 'details']);
        });
      }
    },
    error => {
      const msg = 'fail'; // this.translate.instant(`RESPONSES.${error.error.toUpperCase()}`);
      this.toastSrv.showToastError(msg);
    });
  }

  getScoreValueFromForm(form, id, score) {
    if(form){
      let val = form.get(id)?.value;
      if(val || val === 0){
        return Number(val);
      }
    }
    return -1; // preguntarle a David que le devuelvo
  }


  changeQuestionnaireStatusCode(questionnaireCode, statusCode){
    let body = {
      questionnaireCode: questionnaireCode,
      statusCode: statusCode
    };
    this.projectsApi.updateQuestionnaireStatusCode(body).subscribe(res=>{
      if (res){
        this.projectsSrv.setUpdatedStatus({questionnaire_id: questionnaireCode, statusCode: QuestionnaireCodeStatus.FILLING});
      }
    });
  }

  // puts random values on answers inputs
  randomize(): void{
    this.arrItems.forEach(item => {
      if (item.isCheckbox) {
        this.editForm?.controls[item.itemId].setValue(this.getRandomInt(item.max, item.min, true));
      } else {
        this.editForm?.controls[item.itemId].setValue(this.getRandomInt(item.max, item.min, false));
      }
      this.editForm?.controls[item.itemId].markAsTouched();
    });
  }

  async requestScoring() {
    this.loaded = false;

    const validForScoring = await this.scoringSrv.checkPsyQuestionsFieldsForScoring([this.qCode]);

    if(!validForScoring.valid){
      if (validForScoring.notValidQuestionnaires.length > 0){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-PSYQUESTION-NO-ONE'));
      }
    } else {
      try {
        const scoringResult = await this.projectsApi.requestScoringServiceV2([this.qCode]);

        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-SCORING-OK'));
        this.subs.sink = this.projectsApi.getProject(this.questionnaire.project_id).subscribe(res => {
          this.projectsSrv.setCurrentProject(res.data);
          this.router.navigate(['projects', 'details']);
        });
      } catch (ex) {
        let errToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          ex.message,
          {
            logErrorOnConsole:true,
            defaultMsgKey:"PROJECTS.API-SCORING-NO",
            customEnvironment:"SCORING.HTTP-CODES"
          }
        )
        this.toastSrv.showToastError(errToShow.translatedMessage);
      }
    }

    this.loaded = true;
  }

  generateMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            numeric: this.translate.instant('VALIDATIONS.NUMERIC'),
            minNumber: this.translate.instant('VALIDATIONS.MIN-NUMBER'),
            min: this.translate.instant('VALIDATIONS.MIN-NUMBER'),
            maxNumber: this.translate.instant('VALIDATIONS.MAX-NUMBER'),
            max: this.translate.instant('VALIDATIONS.MAX-NUMBER'),
            decimalValidation: this.translate.instant('VALIDATIONS.NUMERIC-DECIMAL')
          }
        });
    });
  }

  checkDisabled(): boolean {
    if (this.arrPsyuserData.length > 0) {
      return this.editForm?.invalid || this.editFormScales?.invalid || this.profileForm?.invalid || this.psyUserForm?.invalid || this.psyUserScoringReportingQuestionsForm?.invalid || !this.maskEditable;
    } else {
      return this.editForm?.invalid || this.editFormScales?.invalid || this.profileForm?.invalid || this.psyUserScoringReportingQuestionsForm?.invalid || !this.maskEditable;
    }
  }

  jumpNext(itemId): void {
      //dentro del listado de orden de items obtenemos el index
      //y cogemos el id del siguiente index para hacer el salto al item siguiente por posición y no por id
      let index = this.allItemsOrderToShow.indexOf(itemId)
      index++;
      let nextItemId = this.allItemsOrderToShow[index]
      document.getElementById(nextItemId)?.focus();
    //}
  }

  getRandomInt(min, max, binary): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    if (binary) {
      return Math.floor(Math.random() * 2);
    } else {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }

  formatNumber(id): void{
    try{
      let value = this.editForm?.controls[id].value;
      if (value.length > 1) {
        value = value.replace(/\b0+/g, '');
      }
      this.editForm?.controls[id].setValue(value);
    } catch (ex) {}
  }

  /**
   * Check if subtest has sections
   * @param subtest Return 'standard' for non section subtests, and 'section' for subtests with sections
   * @returns
   */
  getSubtestOrSectionType(subtest) {
    if(subtest.subtestRules?.sections?.length > 0) {
      return 'section'
    }
    return 'standard'
  }

  getSectionOrSubSectionType(section){
    if(section.sections?.length > 0){
      return 'subsection'
    } else {
      return 'section'
    }
  }

  getItemsOfSubtest(subtest): any {
    return this.getItemsOrderByIndex(this.arrItems.filter(_item => this.arrItems.filter(x => x.subtestId === subtest.subtestId)), subtest.subtestRules?.itemOrder);
  }


  getallItemsOrderToShowSections(subtest){
    if(subtest.subtestRules.sections && subtest.subtestRules.sections.length > 0){
      subtest.subtestRules.sections.forEach(_section => {
        if (_section.sections.length === 0) {
          this.allItemsOrderToShow.push(_section.items.map(_item => _item.itemId))
        } else {
          _section.sections.forEach(_subsection => {
            this.allItemsOrderToShow.push(_subsection.items.map(_item => _item.itemId))
          });
        }
      });
      this.allItemsOrderToShow = this.allItemsOrderToShow.flat();
    } else if (subtest.subtestRules.itemOrder) {
      subtest.subtestRules.itemOrder.forEach(_item => {
        let item = parseInt(_item, 10)
        this.allItemsOrderToShow.push(item)
        this.allItemsOrderToShow = this.allItemsOrderToShow.flat();
      })
    }
  }

  setItemsInSections(sections) {

    sections.forEach(_section => {
      _section.items = [];
      let auxItems = [];

      // Extract items and push by order
      this.arrItems.forEach(_item => {
        _section.itemsRef.forEach(_itemRef => {
          if(_item.itemId == _itemRef) { // Soft equal to accept strings and numbers
            auxItems.push(_item)
          }
        })
      });

      _section.items = auxItems;
    });

    return sections;
  }

  getItemsOrderByIndex(listItems, orderIndex){

    let result = []
    if(orderIndex){
      orderIndex.forEach(i => {
        result.push(listItems.find(_item => _item.itemId == i));
      })
    }
    return result;
  }

  setItemFormat(){
    this.subtests.forEach(_subtest => {
      // Apply format to subtest items
      _subtest.items.forEach(_item => {
        this.setItemStyleBasedInParent(_item, _subtest.items);

        // Apply format to section items
        _subtest.sections?.forEach(_section => {
          _section.items.forEach(_sectionItem => {
            this.setItemStyleBasedInParent(_sectionItem, _section.items);

            // Apply format to subsection items
            _section.sections.forEach(_subsection => {
              _subsection.items.forEach(_subsectionItem => {
                this.setItemStyleBasedInParent(_subsectionItem, _subsection.items);
              });
            });
          })
        });
      })
    });
  }

  setItemStyleBasedInParent(itemRef, items){
    if(itemRef.scoringItems.length > 0) {
      itemRef.class = 'init-square'; // Set as first item of a group

      itemRef.scoringItems = itemRef.scoringItems.map(z => parseInt(z)); // Parse al ids to integer

      // Get childs based in scoringItems array
      let childs = items.filter(x => itemRef.scoringItems.includes(x.itemId));

      if(childs.length > 0) {
        // Set class square in elements
        childs.forEach((x,i) => {
            x.class = 'square';
        })

        // Add finish-square class to the last item
        childs[childs.length-1]['class'] = 'finish-square';
      }
    }
  }


  setCheckboxItems(): void {
    this.arrItems.filter(z => z.scoringItems.length > 0).forEach(z => {
      z.min = 0;
      z.max = 1;
      z.isCheckbox = true;
      this.arrItems.filter(x => z.scoringItems.includes(x.itemId)).forEach(x => {
        x.min = 0;
        x.max = 1;
        x.isCheckbox = true;
      });
    });
  }

  /**
   * Convert all inputs with number values to a number in the object, for not send as string
   */
  setNumberInputsAsNumbers(): void{
    if (this.psyUserForm) {
      this.arrPsyuserData.filter(x => x.type === 'number').forEach(x => {
        const myval = this.profileForm.value[x.name];
        if (!isNaN(+myval) && myval.length > 0){
          this.profileForm.value[x.name] = +myval;
        }
      });
    }

    if (this.profileForm) {
      this.arrProfileData.filter(x => x.type === 'number').forEach(x => {
        const myval = this.profileForm.value[x.name];
        if (!isNaN(+myval) && myval.length > 0){
          this.profileForm.value[x.name] = +myval;
        }
      });
    }

    if (this.psyUserScoringReportingQuestionsForm) {
      this.arrPsyQuestionsScoringReporting.filter(x => x.type === 'number').forEach(x => {
        const myval = this.psyUserScoringReportingQuestionsForm.value[x.name];
        if (!isNaN(+myval) && myval.length > 0){
          this.psyUserScoringReportingQuestionsForm.value[x.name] = +myval;
        }
      });
    }
  }

  generateScaleSubsections() {

    // Remove subsections without scores
    this.testStructure.scaleData.subsections = this.testStructure.scaleData.subsections.filter(x => x.scaleScoreRef.length > 0);

    // Remove scores not visible to PsyUser
    this.testStructure.scaleData.scales.forEach(_scale => {
      _scale.scores = _scale.scores.filter(x => x.visibleToPsyUser);
    })

    this.testStructure.scaleData.subsections.forEach(_subsection => {
      _subsection.scores = [];
      _subsection.scaleScoreRef.forEach(_item => {
        let scale = this.testStructure.scaleData.scales.find(x => x.key == _item.scaleKey);
        let score = scale.scores.find(x => x.scoreName == _item.scoreKey);
        if(score) {
          score.id = `${scale.key}_${score.scoreName}`;
          _subsection.scores.push(score);
        }
      })
    })

    // Generate form
    const groupForm = {};

    // iterate over scores to generate form data
    this.testStructure.scaleData.subsections.forEach(_sub => {
      if(_sub.scores.length > 0){
        _sub.scores.forEach(_score => {
          const Validators = [];
          //required
          if(_score.mandatory){
            Validators.push(RxwebValidators.required());
          }
          // add min/max
          Validators.push(RxwebValidators.minNumber({ value: _score.minVal }));
          Validators.push(RxwebValidators.maxNumber({ value: _score.maxVal }));

          // add numeric validation
          if(['decimal','decimalRange'].includes(_score.type)){
            Validators.push(RxwebValidators.numeric({acceptValue:NumericValueType.Both, isFormat:true, allowDecimal:true}));
            Validators.push(RxwebValidators.pattern({expression:{'onlyAlpha': /^-?\d+(\.\d+)?$/}, messageKey: 'decimalValidation'}));
          } else {
            Validators.push(RxwebValidators.numeric({acceptValue:NumericValueType.Both}));
          }

          //Split if is range
          if(['integerRange','decimalRange'].includes(_score.type)){
            let auxMaxCondition = RxwebValidators.lessThanEqualTo({fieldName: _score.id+'_ub'})
            groupForm[_score.id+'_lb'] = ['', Validators.concat(auxMaxCondition)];
            let auxMinCondition = RxwebValidators.greaterThanEqualTo({fieldName: _score.id+'_lb'})
            groupForm[_score.id+'_ub'] = ['', Validators.concat(auxMinCondition)];
          } else if (_score.type === 'itemRange'){
            groupForm[_score.id] = ['', Validators];
            let validatorForDp = [];
            if(_score.mandatory){
              validatorForDp.push(RxwebValidators.required());
            }
            groupForm[_score.id + '_selectedRange'] = ['', validatorForDp];
          } else {
            groupForm[_score.id] = ['', Validators];
          }
        })
      }
    });

    let scaleForm: any
    try{
      scaleForm = this.formBuilder.group(groupForm);
      this.visibleScalesAtEntryMask = groupForm !== undefined ? true : false;
    } catch {
      // Return empty if formBuilder fail
    }

    this.editFormScales = scaleForm;
  }

  ngOnDestroy(): void{
    this.loaded = false;
    this.subs.unsubscribe();
  }
}
