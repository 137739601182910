import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedElementsModule } from 'src/app/shared/shared-elements.module';
import { PeopleModule } from '../people/people.module';
import { ProjectsModule } from '../projects/projects.module';
import { CatalogModule } from '../catalog/catalog.module';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedElementsModule,
    ProjectsModule,
    PeopleModule,
    CatalogModule
  ]
})
export class DashboardModule { }
