<!-- Pop Up info plan  -->
<!--(POP-UP)-->
<p-dialog (onHide)="hideDialog()" [(visible)]="showPopUp" [style]="{width: '90vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog">
  <ng-template pTemplate="header" class="plan-detail-header">
      <div class="content p-ml-5 p-my-5 p-d-flex p-flex-column">
        <span class="title">{{'ACCOUNTS.ASSOCIATED' | translate}}</span>
      </div>
  </ng-template>
  <div class="dialog-content">      
     <app-plan-details [planToShow]="planToShow" (hideParent)="hideDialog()"></app-plan-details>    
  </div>
  <ng-template pTemplate="footer">
  </ng-template>
</p-dialog>
