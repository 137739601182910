import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { distance } from 'fastest-levenshtein';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-input-data',
  templateUrl: './input-data.component.html',
  styleUrls: ['./input-data.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
})
export class InputDataComponent implements OnInit {

  @Input() itemForm;
  @Input() itemData;
  @Input() title;
  @Input() maskEditable;
  @Output() optionChange = new EventEmitter<any>();
  nonGroupedFields = [];
  groupedFields = [];
  dynamicOptions = [];
  groupCommonOptions = [];

  constructor() { }

  ngOnInit(): void {
    this.nonGroupedFields = this.getNonGroupedFields(this.itemData);
    this.groupedFields = this.getGroupedFields(this.itemData);
  }

  getNonGroupedFields(list): any {
    return list.filter(x => !x.group);
  }

  getGroupedFields(list): any {
    const groups = [...new Set(list.map(x => x.group))];

    const groupsCollection = [];

    groups.forEach(group => {
      if (group) {
        groupsCollection.push({
          name: group,
          items: list.filter(x => x.group === group),
          label: list.filter(x => x.group === group)[0].groupDescription,
          commonOptions: list.filter(x => x.group === group)[0].commonOptions ? list.filter(x => x.group === group)[0].commonOptions : false
        });
      }
    });
    // Diferenciamos dos tipos de grupos:
    // - groupsCollection: serán los grupos a mostrar en la entryMask
    // - this.groupCommonOptions: dentro de los grupos de groupsCollection, los que compartan opciones
    this.groupCommonOptions = groupsCollection.filter(x => x.commonOptions === true)
    this.setCommonSelectOptions();
    return groupsCollection;
  }

  setCommonSelectOptions(): void{
    this.groupCommonOptions.forEach(group => {
      // clono la lista de items cuyo type sea select
      const items = [...group.items.filter(x => x.type === 'select')];

      // ordeno la lista de items para buscar repetidos
      items.sort((a, b) => (a.name > b.name) ? 1 : -1);

      // extraigo los que sean repetidos
      const equalInputs = [];
      if (items.length > 1){
        for (let i = 0; i < items.length; i++) {
          if (i === items.length - 1){
            if (this.compareOptions(items[i], items[i-1])) {
              equalInputs.push(items[i]);
            }
          } else {
            if (this.compareOptions(items[i + 1], items[i])) {
              equalInputs.push(items[i]);
            }
          }
        }
      }

      // Si existen campos con options repetidas, genero un objeto con un id único e indico como opciones
      // las mismas del primer objeto que se repite, puesto que en todas es igual, además añado la propiedad
      // inactive que será la que indique si el campo puede volver a ser usado por el resto o no.
      //
      // La finalidad de esto es que los campos que tengan opciones similares compartirán las mismas opciones
      // entre ellos.
      if (equalInputs.length > 0){
        const optionId = uuidv4();
        // Genero la opción compartida
        this.dynamicOptions.push({
          id: optionId,
          options: equalInputs[0].options.map(x => ({...x, inactive: false}))
        });
        // Hago referencia a la opción compartida en los campos originales.
        equalInputs.forEach(x => {
          group.items.filter(z => z.name === x.name)[0]['dynamicOption'] = optionId;
        });
      }
    });
  }

  compareOptions(field1, field2): boolean{
    if (JSON.stringify(field1.options) === JSON.stringify(field2.options)
      && distance(field1.name, field2.name) < 2
      && field1.name.substring(0, 3) === field2.name.substring(0, 3)) {
      return true;
    }
    return false;
  }

  /**
   * Actualiza el estado de las opciones del dropdown compartido, para activar/desactivar
   * @param $dynamicOptionId id referencia del elemento
   */
  dynamicOptionChange($dynamicOptionId): void{
    this.groupCommonOptions.forEach(group => {
      const selectedValues = [];
      const aux = this.dynamicOptions.filter(x => x.id === $dynamicOptionId)[0];
      group.items.filter(x => x.dynamicOption === aux.id).forEach(item => {
        try{
          selectedValues.push(this.itemForm.get(item.name).value);
        } catch (ex) {}
      });
      aux.options.forEach(option => {
        if (selectedValues.includes(option.value)){
          option.inactive = true;
        } else {
          option.inactive = false;
        }
      });

    });
  }

}
