import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ToastConfirmService } from "src/app/shared/services/toast-confirm.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { SubSink } from "subsink";
import { ProjectsApi } from "../../../api/projects.api";
import { ProjectsService } from "../../../services/projects.service";
import { QuestionnaireCodeStatus } from "@giuntipsy/utils/lib";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ProjectsActionsService } from "../../../services/projects-actions.service";
import { fadeInAnimation } from "src/app/shared/animations/fade-in-animation";
import { CatalogService } from "src/app/modules/catalog/services/catalog.service";
import { ScoringPsyuserFormularyTemplateComponent } from "src/app/shared/components/modal-templates/scoring-psyquestions-template/scoring-psyquestions-template.component";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
  animations: [ fadeInAnimation ],
  providers: [ToastService],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @Input() projectType: "individual" | "projectgroup" = "individual";
  @Input() embedded = false;

  @ViewChild('dataTable') dataTable: Table;

  @ViewChild('scoringPopupTemplate') scoringPopupRefPopup: ScoringPsyuserFormularyTemplateComponent;

  private subs = new SubSink();
  private confirmSubscription: Subscription;

  public data = []; // Projects list
  public dataCount: number = 0; // Number of projects filter
  public loaded = false;
  public selectedData = [];
  public showBottomMenu: boolean = false;
  public showBlockUi = false;
  public showLoadingSpinner = false;

  // Storage temporal data to do actions with a specific project
  public tempProject: any;
  public tempProjectTestSharedQuestions: any;
  public tempRelatedTestQuestionnaires: any;

  // Save project pagination for reload
  private storedFirst: number;
  private storedRows: number;

  // UI: Show or hide dialog popups
  public displayReportPopup: boolean = false;
  public displayScoringPopup: boolean = false;
  public displayNonValidScoringPopup: boolean = false;
  public displaySelectUsers: boolean = false
  public displayCustomEmail: boolean = false

  // Resend emails data
  public unfinishedTestUsers = [];
  public _emailContent: string;

  constructor(
    private projectsApi: ProjectsApi,
    private toastSrv: ToastService,
    private toastConfirmSrv: ToastConfirmService,
    private translate: TranslateService,
    private router: Router,
    private projectsSrv: ProjectsService,
    private projectActionsSrv: ProjectsActionsService,
    private catalogSrv: CatalogService
  ) {}

  ngOnInit(): void {
    // Set projects instance in service
    this.projectActionsSrv.setComponentInstance(this);

    // Listen to project status changes
    this.subs.sink = this.projectsSrv.sharedCurrentStatusProject.subscribe(res => {
      if(res) {
        this.changeProjectStatus(res.projectId, res.statusCode);
      }
    });
  }

  /**
   * Get project list based in filters criteria
   * @param event
   * @param projectType filter between individual and groups
   */
  getDataLazy(event: LazyLoadEvent) {

    // Restore pagination to event
    if(this.storedFirst && this.storedRows){
      event.first = this.storedFirst;
      event.rows = this.storedRows;
    }

    this.projectsApi.getProjectsLazyV3(event, this.projectType).subscribe({
      next: _res => {

        this.data = _res.data.rows;
        this.dataCount = _res.data.count;

        // Assign string status and entryType
        this.data = this.data.map(_project => {return {
          ..._project,
          statusString: this.projectsSrv.getProjectStatusStringByCode(_project.statusCode),
          supply: this.projectsSrv.getCustomEntryType(_project.entryType_id),
          projectType: this.projectsSrv.getProjectTypeV2(_project, this.projectType)
        }})

        // Disable previous stored rows and go to specific pagination
        if(this.storedFirst && this.storedRows){
          this.dataTable.first = this.storedFirst;
          this.dataTable.rows = this.storedRows;

          this.storedFirst = undefined;
          this.storedRows = undefined;
        }
      },
      error: _err => {
        this.showErrorMessage(this.translate.instant('TABLE.ERROR-API'))
      },
      complete: () => {
        this.loaded = true;

        // Load psyuser catalog after load projects (this work as a pre-load for create new project)
        this.catalogSrv.loadAvailableCatalog().subscribe();
      }
    });
  }

  /**
   * Normalize filter object
   * @param event
   */
  applyDataTableFilter(event) {

    // Apply date range
    if(event.rangeDates && event.rangeDates.length > 0){
      this.dataTable.filters['startDate'] = event.rangeDates[0];
      this.dataTable.filters['endDate'] = event.rangeDates[1];
    }

    // Apply search type project/people
    if(event.dropdowns?.some(x => x.key.key == 'search')){
      const selectedData = event.dropdowns.find(x => x.key.key == 'search').selected;
      this.dataTable.filters['search'] = selectedData;
    }

    // Apply entryType_id dropdown
    if(event.dropdowns?.some(x => x.key.key == 'entryType_id')){
      const selectedData = event.dropdowns.find(x => x.key.key == 'entryType_id').selected;
      this.dataTable.filters['entryType_id'] = selectedData;
    }

    // Apply statusCode dropdown
    if(event.dropdowns?.some(x => x.key.key == 'status_id')){
      const selectedData = event.dropdowns.find(x => x.key.key == 'status_id').selected;
      this.dataTable.filters['statusCode'] = selectedData;
    }

    // Apply global filter string
    this.dataTable.filterGlobal(event.searchText, 'contains');
  }

  /**
   * Show or hide the bottom bar if the number of selected users is greater than 1
   */
  onSelect() {
    this.showBottomMenu = this.selectedData.length > 1 ? true : false;
  }

  /**
   * Clear datatable filters
   */
  clearDataTableFilter() {
    this.dataTable.filters = {};
    this.dataTable.reset();
  }

  /**
   * Function to know if item is selected, valid to apply specific class
   * @param itemData
   * @returns
   */
  isSelected(itemData: any): boolean {
    return this.selectedData.some(x => x.id == itemData.id);
  }

  /**
   * Ask to the user if want to delete de selected list of project or a specific project
   * @param projectId To delete a specific project
   */
  confirmDelete(projectId?: number): void {
    // Show confirm toast
    this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE'));
    // Block the rest of the ui to avoid errors
    this.showBlockUi = true;

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true) {
        let projectIds = [];
        // To delete an array of items
        if(!projectId) {
          projectIds = this.selectedData.map(_item => _item.id);
        } else {
        // To delete one by one
          projectIds.push(projectId);
        }

        this.subs.sink = this.projectsApi.deleteProjects(projectIds).subscribe(
          res => {
            this.toastSrv.showToastSucess(this.translate.instant("PROJECTS.API-DELETE-OK"));
            this.reloadCurrentPage();
          },
          error => {
            console.error("Error deleting projects:", projectIds, error);
            this.showErrorMessage(this.translate.instant("PROJECTS.API-DELETE-KO"));
          }
        );

        this.clearDeleteSubscription();
      }

      if (res === false) {
        this.clearDeleteSubscription();
      }
    });
  }

  /**
   * Get data from api for same page
   */
  reloadCurrentPage(){
    const _event = this.dataTable.createLazyLoadMetadata()
    // Preserve pagination after delete
    this.storedFirst = _event.first;
    this.storedRows = _event.rows;
    this.applyDataTableFilter(_event);
  }

  /**
   * Unsuscribe delete confirmation subscription and hide blockUi
   */
  clearDeleteSubscription(){
    this.confirmSubscription.unsubscribe();
    this.showBlockUi = false;
    this.showBottomMenu = false;
    this.selectedData = [];
  }

  /**
   * Click in a row item to access in details
   * @param item
   * @param event
   */
   goToDetails(item, event?: Event) {
    let redirect = true;

    // Click in row
    if(event){
      const rowDetails = event?.target as HTMLElement;

      // Avoid go to details when click in checkbox or menu
      if (!Array.from(rowDetails?.classList).some((x) =>
          [ "p-checkbox-box",
            "options-button",
            "pi-ellipsis-v",
            "p-menuitem-link",
            "non-clickable",
            "p-avatar-circle",
            "p-avatar-text",
            "custom-label-content",
            "custom-label-flag",
            "p-button-label",
            "btn-mini"
          ].includes(x))
      ) {
        event.stopPropagation();
      } else {
        redirect = false;
      }
    }

    if(redirect) {
      this.projectsSrv.setCurrentProject(item);
      this.router.navigate(['projects', 'details']);
    }
  }

  /**
   * Go to create new project page
   */
  goToCreateNew(): void {
    this.projectsSrv.setCurrentProject(this.projectsSrv.generateNewProject());
    this.router.navigate(["projects", "create"]);
  }

  /**
   * Refresh project status with provided project id
   * @param projectId
   * @param statusCode
   */
  changeProjectStatus(projectId: number, statusCode: QuestionnaireCodeStatus){
    // Check if the project exist in the current page, if not, there's is nothing to do
    this.data = this.data.map(_project => (_project.id === projectId ?
      { ..._project,
        statusCode: statusCode,
        statusString: this.projectsSrv.getProjectStatusStringByCode(statusCode)} :
        _project));
  }

  /**
   * Show error toast from this view and for service
   * @param text
   */
  public showErrorMessage(text: string) {
    this.toastSrv.showToastError(text);
  }

  /**
   * Show success toast from this view and for service
   * @param text
   */
  public showSuccessMessage(text: string) {
    this.toastSrv.showToastSucess(text);
  }

  /**
   * Confirm resend email action
   */
  public confirmResend(){
    // get selected testtaker ids
    let testtakerIds = this.unfinishedTestUsers.filter(_tt => _tt.selected).map(_tt => _tt.id);
    this.projectActionsSrv.confirmResend(testtakerIds, this.tempProject.id, this._emailContent);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
