import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ProjectStatusString } from '../../enums/project-status-string.enum';

@Component({
  selector: 'app-row-card',
  templateUrl: './row-card.component.html',
  styleUrls: ['./row-card.component.scss']
})
export class RowCardComponent implements OnInit {

  @Input() customNopeopleTl: TemplateRef<any>;
  @Input() optionLeft: 'none' | 'checkbox' | 'move' = 'none';
  @Input() optionRight: 'none' | 'options' | 'checkbox' | 'delete' | 'button' | 'trigger' = 'none';
  @Input() item: any;
  @Input() itemOptions: any;
  @Input() pointer = 'pointer';
  @Input() triggerName = 'NaN';
  @Input() entryType;
  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemTriggered = new EventEmitter<any>();
  @Output() itemReSend = new EventEmitter<any>();
  @Output() sendMail = new EventEmitter<any>();

  ProjectStatusString = ProjectStatusString;

  constructor() { }

  ngOnInit(): void {
  }

  generateMenu(item): any {
    return this.itemOptions;
  }

  selectItem(item, $event): void {
    if(!item.original) {
      item.selected = $event.target.checked;
      this.emmitSelectedAction(item);
    }
  }

  emmitDeleteAction(item): void{
    this.itemDeleted.emit(item);
  }

  emmitSelectedAction(item): void{
    this.itemSelected.emit(item);
  }

  emmitTrigerredAction(item): void{
    this.itemTriggered.emit(item);
  }

  sendEmail(id:number){
    this.sendMail.emit(id)
  }
}
