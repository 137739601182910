import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AssignationGroupsService {
  private url = environment.apiBackOfficeURL;
  itQualifications = ['C', 'B+', 'B', 'HR', 'A+', 'A'];

  constructor(private http: HttpClient) {}

  getHigherAssignationGroupCode(id): Observable<any> {
    return  this.http.get(this.url + 'qualification/psyuser/' + id);
  }

  /**
   * Filter the assinationGroups to get only the related with qualification based on the assignationGroupType
   * TO-DO: For MVP get the assignationGroupType with id 1
   * @param groups 
   */
  filterQualificationAssignationGroup(groups): any{
    return groups.filter(x => x.assignationGroupType.id === 1);
  }
  getAssignationsCustomer(id): Observable<any> {
    return this.http.get(this.url + 'assignationGroups/customer/' + id);
  }
  getAssignationsPsyUser(id): Observable<any> {
    return this.http.get(this.url + 'assignationGroups/psyuser/' + id);
  }
  getAssignationGroupsTests(customer_id, assign_customer,assignation_groups,psy_user_id = undefined): Observable<any> {
    if(assign_customer){
      return this.http.post(this.url + 'assignationGroups/tests/' + customer_id,{"groups":false,"psyUserId":psy_user_id});
    }else{
      if(assignation_groups == "") assignation_groups = []
      return this.http.post(this.url + 'assignationGroups/tests/' + customer_id,{"groups":assignation_groups});
    }
 
  }
}
