<p-tabView [(activeIndex)]="activeIndex" (onChange)="storeActiveIndex()">    
    <!--PROFILE TAB-->
    <p-tabPanel header="{{'PROFILE.PROFILE'|translate}}">
        <app-dashboard-header [isProfile]="true" class="dashboard" *ngIf="psyuser?.isAdmin !== psyUserRoles.ASSISTANT"></app-dashboard-header>
        <div class="p-grid profile-details">
            <app-change class="p-col-12 p-lg-6 p-md-12 reset-password" [reload]="false"></app-change>
            <app-detail-profile class="p-col-12 p-lg-6 p-md-12"></app-detail-profile>
        </div>
    </p-tabPanel>
    <!--SUBSCRIPTIONS TAB-->
    <p-tabPanel header="{{'ACCOUNTS.PLANS'|translate}}" *ngIf="psyuser?.isAdmin !== psyUserRoles.ASSISTANT">
        <ng-template pTemplate="content">
            <div class="plans-container">
                <div class="p-d-flex p-jc-end p-ai-center p-mb-3">
                    <!-- FILTER ACTIVE/INACTIVE -->
                    <div class="p-d-flex p-ai-center active-inactive-switch">
                        <label>{{'PROFILE.SWITCH-INACTIVE'|translate}}</label> 
                        <p-inputSwitch [(ngModel)]="showInactive" (onChange)="changeShowInactive()"></p-inputSwitch>                        
                    </div>

                    <!-- SUBSCRIPTION SELECTOR -->                    
                    <p-dropdown
                        *ngIf="psyuserSubscriptionsToShow.length > 0"
                        [options]="psyuserSubscriptionsToShow" 
                        class="custom-dropdown"                
                        optionLabel="plan_name"                
                        [(ngModel)]="planToShow"
                        [filter]="true"
                        filterBy="plan_name"                        
                        placeholder="&nbsp;">
                        <ng-template let-plan pTemplate="item">
                            <div class="item-plan" [ngClass]="{'plan-inactive': !plan.isAlive}">
                                {{plan.plan_name}}                                
                                <div class="p-d-flex p-flex-column">
                                    <span class="suscription-id">
                                        {{('ACCOUNTS.SUBSCRIPTION' | translate) + ' ' + (plan.sub_id | padNumber: 8) + ' #' + plan.psyuserSub_id}}
                                    </span>                                    
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <app-plan-details *ngIf="showPlanInfo" #planDetail [planToShow]="planToShow"></app-plan-details>
            </div>
        </ng-template>       
    </p-tabPanel>
    <!--HISTORY TAB-->
    <p-tabPanel header="{{'PROFILE.HISTORY'|translate}}"  *ngIf="psyuser?.isAdmin !== psyUserRoles.ASSISTANT">
        <ng-template pTemplate="content">
            <app-history 
                [history]="psyuserSubscriptionsHistory" 
                [psyUserSubscriptions]="psyuserSubscriptions"
                [psyUserName]="psyUserName"                 
                *ngIf="showHistory" 
                class="history">
            </app-history>
        </ng-template>
    </p-tabPanel>
</p-tabView>

<!--Loading UI-->
<p-blockUI [blocked]="showLoadingUI">    
    <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</p-blockUI>