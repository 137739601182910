<div class="row p-grid {{pointer}}">
    <div class="row-options-left p-col-fixed" [ngClass]="{'fixed-none': optionLeft === 'none'}">
        <div *ngIf="optionLeft==='checkbox'">
            <div>
                <input type="checkbox"
                class="custom-checkbox"
                [checked]="item.selected"
                (change)="selectItem(item,$event)">
            </div>
        </div>
        <div *ngIf="optionLeft==='move'">
            <i class="pi pi-sort btn-secondary-menu pointer"></i>
        </div>
    </div>
    <div class="row-content p-col">
        <ng-container *ngTemplateOutlet="customNopeopleTl">
            <!--CONTENT DETAILS-->
        </ng-container>
    </div>
    <div class="row-options-right p-col-fixed" [ngClass]="{'width-200': optionRight === 'button'}">

        <div *ngIf="(entryType == 3 && [ProjectStatusString.FILLING, ProjectStatusString.ASSIGNED].includes(item.status))" class="send">
            <i class="pi pi-send btn-secondary-menu pointer" (click)="sendEmail(item.testtaker_id)"></i>
        </div>

        <div *ngIf="optionRight==='options'" [ngClass]="{'p-pr-5': true}">
            <i class="pi pi-ellipsis-v btn-secondary-menu pointer"
            (click)="menu_options.toggle($event)"></i>
            <p-menu #menu_options class="menu-options" [popup]="true" [model]="generateMenu(item)"></p-menu>
        </div>

        <div *ngIf="optionRight==='delete' && !item.original" class="p-mr-6 p-ml-2">
            <i class="pi pi-trash btn-secondary-menu pointer" (click)="emmitDeleteAction(item)"></i>
        </div>

        <div *ngIf="optionRight==='button'">
            <button
                type="button"
                class="btn-mini"
                pButton
                (click)="emmitDeleteAction(item)"
                label="{{ 'DETAIL-PEOPLE.BTN-DELETE' | translate }}" >
            </button>
        </div>
        <div *ngIf="optionRight==='trigger'">
            <button
                type="button"
                class="btn-mini btn-trigger"
                pButton
                (click)="emmitTrigerredAction(item)"
                label="{{ 'DETAIL-PEOPLE.'+triggerName | translate }}" >
            </button>
        </div>
    </div>
</div>