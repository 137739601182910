<input class="hidden-input"> <!--Input to force focus and load styles-->
<div class="main" [ngClass]="{'p-d-flex p-jc-center p-ai-center': reload}">
    <div class="content">
        <form [formGroup]="changeForm" class="login-form p-d-flex p-flex-column p-shadow-3">
            <div class="p-d-flex p-jc-between p-ai-center">
                <h2>{{'CHANGE.TITLE' | translate}}</h2>

                <!-- <span (click)="displayPanel=true" class="info pointer">
                    <i class="pi pi-question-circle"></i>
                </span> -->
            </div>           

            <div class="p-inputgroup custom-inputgroup">
                <span class="p-float-label">
                    <p-password                         
                        #password
                        formControlName="password"
                        [strongRegex]="strongRegex" 
                        [promptLabel]="'&nbsp;'"
                        [strongLabel]="('RESET.STRONG' | translate)"
                        [mediumLabel]="('RESET.MEDIUM' | translate)"
                        [weakLabel]="('RESET.WEAK' | translate)">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">{{'RESET.INFO' | translate}}</p><br>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li *ngIf="!ext_pass">{{'RESET.INFO-2' | translate}}</li>
                            <li *ngIf="ext_pass">{{'RESET.INFO-2-1' | translate}}</li>
                            <li>{{'RESET.INFO-3' | translate}}</li>
                            <li>{{'RESET.INFO-5' | translate}}</li>
                        </ul>
                    </ng-template>
                </p-password>
                    <label for="repeat-password">{{'RESET.PASSWORD' | translate}}</label>
                </span>
                <button type="button" pButton 
                    icon="{{showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}}" 
                    class="input-button" 
                    tabindex="-1"
                    (click)="showPassword = !showPassword; showPasswordEvent(password)">
                </button>
            </div>

            <div class="p-inputgroup custom-inputgroup">
                <span class="p-float-label">
                    <p-password 
                        #repeatPassword
                        formControlName="repeatPassword"
                        [strongRegex]="strongRegex" 
                        [promptLabel]="'&nbsp;'"
                        [strongLabel]="('RESET.STRONG' | translate)"
                        [mediumLabel]="('RESET.MEDIUM' | translate)"
                        [weakLabel]="('RESET.WEAK' | translate)">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">{{'RESET.INFO' | translate}}</p><br>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>{{'RESET.INFO-2' | translate}}</li>
                            <li>{{'RESET.INFO-3' | translate}}</li>
                            <li>{{'RESET.INFO-5' | translate}}</li>
                            <li>{{'RESET.INFO-6' | translate}}</li>
                        </ul>
                    </ng-template>
                </p-password>
                    <label for="repeat-password">{{'RESET.CONFIRM-PASSWORD' | translate}}</label>
                </span>
                <button type="button" pButton 
                    icon="{{showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}}" 
                    class="input-button" 
                    tabindex="-1"
                    (click)="showRepeatPassword = !showRepeatPassword; showRepeatPasswordEvent(repeatPassword)">
                </button>
            </div>            

            <div class="btn-row p-d-flex p-jc-end p-ai-center">
                <button 
                    type="submit" 
                    class="btn-primary" 
                    pButton 
                    label="{{'CHANGE.SUBMIT' | translate}}"
                    [disabled]="changeForm.invalid"
                    (click)="login()">
                    <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}"></p-progressSpinner>
                </button>    
            </div>            
        </form>
    </div>
</div>

<!--(POP-UP)-->
<p-dialog [(visible)]="displayPanel" [style]="{width: '70vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog">
    <div class="dialog-content">
        <div class="body-text">
            {{'RESET.INFO'|translate}}
            <ul>
                <li>
                     {{'RESET.INFO-2'|translate}}
                </li>                   
                <li>
                     {{'RESET.INFO-3'|translate}}
                </li>
                <li>
                     {{'RESET.INFO-5'|translate}}
                 </li>
            </ul>
         </div>
    </div>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>

<!-- {{myCodes | json}} -->