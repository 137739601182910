
<!--Title/Instruction-->
<div class="p-grid p-ai-center score-instructions-container">
    <!--Label for input range-->
    <div [ngClass]="['integerRange','decimalRange'].includes(score.type) ? 'p-col-10' : 'p-col-6'" class="p-text-center">
        <label *ngIf="['integerRange','decimalRange'].includes(score.type)" class="input-range-label">
            <span *ngIf="score.mandatory" class="red_dot">*</span>
            {{score.label}}
        </label>
    </div>
    <!--Instruction Tooltip-->
    <div *ngIf="score.instructions" class="score-instructions p-text-center pointer"
        [ngClass]="['integerRange','decimalRange'].includes(score.type) ? 'p-col-2' : 'p-col-6'"
        pTooltip="{{score.instructions}}" tooltipPosition="bottom">?</div>
    <div *ngIf="score.type === 'itemRange'" class="score-instructions pointer"
    [ngClass]="['integerRange','decimalRange'].includes(score.type) ? 'p-col-2' : 'p-col-6'"
    pTooltip="{{'PROJECTS.SELECT-RANGE' | translate}}" tooltipPosition="top">?</div>
        
</div>

<!--Input-->
<div class="p-grid" *ngIf="['number', 'decimal'].includes(score.type)">

    <span class="p-col-3 p-d-flex p-ai-center p-jc-end p-text-right">
        <label for="{{score.id}}">
            <span *ngIf="score.mandatory" class="red_dot">*</span>
            {{score.label}}
            <!-- <span *ngIf="score.minVal" class="sublabel">[Min. {{score.minVal}}]</span>
            <span *ngIf="score.maxVal" class="sublabel"> [Max. {{score.maxVal}}]</span> -->            
        </label>
    </span>

    <div class="p-col-9" 
        [ngClass]="{'margin-top-tooltip': myFormGroup.controls[score.id]?.touched 
        && myFormGroup.controls[score.id]?.invalid}">

        <!--Errors-->
        <span *ngIf="myFormGroup.controls[score.id]?.touched && myFormGroup.controls[score.id]?.invalid"
            [@inAnimation]
            class="error-tooltip">
            {{myFormGroup.controls[score.id]['errorMessage']}}
            {{myFormGroup.controls[score.id].errors.minNumber ? score.minVal : ''}}
            {{myFormGroup.controls[score.id].errors.maxNumber ? score.maxVal : ''}}
        </span>

        <input
            id="{{score.id}}"
            type="{{score.type === 'decimal' ? 'text' : 'number'}}"
            pInputText
            class="custom-input-text response_item"
            formControlName="{{score.id}}"
            [min]="score.minVal"
            [max]="score.maxVal"
            [attr.disabled]="!score.allowEnteredPsyUser || !maskEditable ? true : null"
            placeholder="{{score.defaultValue}}">
    </div>
</div>

<!--Input Range-->
<div class="p-grid" *ngIf="['integerRange','decimalRange'].includes(score.type)">

    <span class="p-col-3 p-text-right p-d-flex p-ai-center p-jc-end">
        <label>{{'PROJECTS.FROM'|translate}}</label>
    </span>

    <div class="p-col-3" 
        [ngClass]="{'margin-top-tooltip': myFormGroup.controls[score.id+'_lb']?.touched 
        && myFormGroup.controls[score.id+'_lb']?.invalid}">
        
        <!--Errors-->
        <span *ngIf="myFormGroup.controls[score.id+'_lb']?.touched && myFormGroup.controls[score.id+'_lb']?.invalid"
            [@inAnimation]
            class="error-tooltip">
            {{myFormGroup.controls[score.id+'_lb']['errorMessage']}}
            {{myFormGroup.controls[score.id+'_lb'].errors.minNumber ? score.minVal : ''}}
            {{myFormGroup.controls[score.id+'_lb'].errors.maxNumber ? score.maxVal : ''}}
            {{myFormGroup.controls[score.id+'_lb'].errors.lessThanEqualTo ? 
                ('PROJECTS.FROM-RANGE' | translate) + myFormGroup.controls[score.id+'_ub'].value : ''}}
        </span>

        <input
            id="{{score.id+'_lb'}}"
            type="{{score.type === 'decimalRange' ? 'text' : 'number'}}"
            pInputText
            class="custom-input-text response_item"
            formControlName="{{score.id+'_lb'}}"
            [min]="score.minVal"
            [max]="score.maxVal"
            [attr.disabled]="!score.allowEnteredPsyUser || !maskEditable ? true : null"
            placeholder="{{score.defaultValue}}">
    </div>

    <span class="p-col-3 p-text-right p-d-flex p-ai-center p-jc-end">
        <label>{{'PROJECTS.TO'|translate}}</label>
    </span>

    <div class="p-col-3" 
        [ngClass]="{'margin-top-tooltip': myFormGroup.controls[score.id+'_ub']?.touched 
        && myFormGroup.controls[score.id+'_ub']?.invalid}">

        <!--Errors-->
        <span *ngIf="myFormGroup.controls[score.id+'_ub']?.touched && myFormGroup.controls[score.id+'_ub']?.invalid"
            [@inAnimation]
            class="error-tooltip">
            {{myFormGroup.controls[score.id+'_ub']['errorMessage']}}
            {{myFormGroup.controls[score.id+'_ub'].errors.minNumber ? score.minVal : ''}}
            {{myFormGroup.controls[score.id+'_ub'].errors.maxNumber ? score.maxVal : ''}}
            {{myFormGroup.controls[score.id+'_ub'].errors.greaterThanEqualTo ? 
                ('PROJECTS.TO-RANGE' | translate) + myFormGroup.controls[score.id+'_lb'].value : ''}}
        </span>


        <input
            id="{{score.id+'_ub'}}"
            type="{{score.type === 'decimalRange' ? 'text' : 'number'}}"
            pInputText
            class="custom-input-text response_item"
            formControlName="{{score.id+'_ub'}}"
            [min]="score.minVal"
            [max]="score.maxVal"
            [attr.disabled]="!score.allowEnteredPsyUser || !maskEditable ? true : null"
            placeholder="{{score.defaultValue}}">
    </div>
</div>

<!-- Item Range -->
<div class="p-grid" *ngIf="score.type === 'itemRange'">
    <span class="p-col-3 p-d-flex p-ai-center p-jc-end p-text-right">
        <label for="{{score.id}}">
            <span *ngIf="score.mandatory" class="red_dot">*</span>
            {{score.label}}          
        </label>
    </span>

    <div class="p-col-9" 
        [ngClass]="{'margin-top-tooltip': myFormGroup.controls[score.id]?.touched 
        && myFormGroup.controls[score.id]?.invalid}">

        <!--Errors-->
        <span *ngIf="myFormGroup.controls[score.id]?.touched && myFormGroup.controls[score.id]?.invalid"
            [@inAnimation]
            class="error-tooltip margin-left-tooltip">
            {{myFormGroup.controls[score.id]['errorMessage']}}
            {{myFormGroup.controls[score.id].errors.min ? score.minVal : ''}}
            {{myFormGroup.controls[score.id].errors.max ? score.maxVal : ''}}
        </span>

        <span class="p-d-flex p-flex-row p-ai-center">
            <div class="custom-dropdown-range">
                <p-dropdown 
                    id="{{score.id+'_selectedRange'}}"
                    [options]="score.ranges" 
                    optionLabel="label"
                    (onChange)="getMaxValueForItemRange($event.value)" 
                    formControlName="{{score.id+'_selectedRange'}}"
                    [value]="score.selectedRange"
                    placeholder="&nbsp;">
                </p-dropdown>
            </div>
            <div>
                <input
                    id="{{score.id}}"
                    type="number"
                    pInputText
                    class="p-col-5 custom-input-text response_item input-dropdown"
                    formControlName="{{score.id}}"
                    [min]="score.minVal"
                    [max]="score.maxVal"
                    [attr.disabled]="!score.allowEnteredPsyUser || !maskEditable || !score.selectedRange ? true : null"
                    placeholder="{{score.defaultValue}}"
                > 
            </div>
        </span>
    </div>
</div>