<div class="main p-md-flex p-jc-center p-ai-center">
    <div class="content p-d-flex p-ai-center">
        <form [formGroup]="forgotForm" class="login-form p-d-flex p-flex-column">
            <h2>Reset Password</h2>

            <div class="body-text">
                {{'FORGOT.DESCRIPTION' | translate}}
            </div>

            <span class="p-float-label">
                <input 
                    id="email" 
                    type="text" 
                    pInputText
                    class="custom-input-text"
                    formControlName="email">
                <label for="email">{{ 'FORGOT.EMAIL' | translate }}</label>
                <span 
                    *ngIf="email.invalid && email.touched" 
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{forgotForm.controls.email['errorMessage']}}
                </span>
            </span>

            <div class="btn-row p-d-flex p-jc-center p-ai-center ">
                <re-captcha #captchaRef (resolved)="resolved($event)" [siteKey]="getKey()" formControlName="captchaRef"></re-captcha>
            </div>

            <div class="btn-row p-d-flex p-jc-end p-ai-center">
                <button 
                    type="submit" 
                    class="btn-primary" 
                    pButton 
                    label="{{'FORGOT.SUBMIT' | translate}}"
                    [disabled]="forgotForm.invalid"
                    (click)="login()">
                    <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}"></p-progressSpinner>
                </button>    
            </div>            
        </form>
    </div>
</div>
