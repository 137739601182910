import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { CoreApi } from 'src/app/core/api/core.api';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [ inOutAnimation ],
  providers: [ToastService]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  forgotForm: UntypedFormGroup;
  showPassword: Boolean = false;
  showRepeatPassword: Boolean = false;
  ext_pass: Boolean = false;
  subs = new SubSink();

  constructor(
    public authSrv: AuthenticationService,
    private coreApi: CoreApi,
    public router: Router,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService) { }

  ngOnInit(): void {
    this.ext_pass = this.authSrv.getCurrentUser().ext_pass;
    this.setValidationMessages();
    this.generateForm();
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.forgotForm.reset();
    });
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            minLength: this.translate.instant('VALIDATIONS.MIN-LENGTH'),
            password: this.translate.instant('VALIDATIONS.APLHA'),
            passwordSpecials: this.translate.instant('VALIDATIONS.SPECIALS'),
            compare: this.translate.instant('VALIDATIONS.EQUALS'),
          }
        });
    });
  }

  generateForm(): void {
    this.forgotForm = this.formBuilder.group({
      password: ['', [
        RxwebValidators.required(),
        RxwebValidators.minLength({value: this.ext_pass ? 12:8}),
        RxwebValidators.compose(
          {validators: [
            RxwebValidators.pattern(
              {expression: {
                onlyAlpha : /[.;#@%!+=[“~,]/
              }})
          ], messageKey: 'passwordSpecials'}
        ),
        RxwebValidators.password({validation:{digit: true} })
        ]
      ],
      repeatPassword: ['', [
          RxwebValidators.required(),
          RxwebValidators.compare({fieldName:'password'})
        ]
      ],
    });
  }

  get password(): any {
    return this.forgotForm.get('password');
  }

  get repeatPassword(): any {
    return this.forgotForm.get('repeatPassword');
  }

  send(): void {
    const password = {password: this.password.value, confirmPassword: this.repeatPassword.value};

    // this.coreApi.forgotPassword(user).subscribe(
    //   res => {
    //     console.log(res);
    //     this.toastSrv.showToastSucess(this.translate.instant(`FORGOT.MESSAGE`));
    //     // setTimeout( () => {
    //     //   this.router.navigateByUrl('/'); // Redirect to home
    //     // }, 3000 );
    //   },
    //   error => {
    //     const msg = this.translate.instant(`RESPONSES.${error.error.message.toUpperCase()}`);
    //     this.toastSrv.showToastError(msg);
    //   });
  }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }
}

