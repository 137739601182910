import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Paginator } from 'primeng/paginator';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { SubSink } from 'subsink';
import { CatalogService } from '../services/catalog.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
  providers: [ToastService],
})
export class CatalogComponent implements OnInit {

  @Input() embedded = false;  
  @ViewChildren(FilterComponent) filter: QueryList<FilterComponent>;
  @ViewChild('paginator') pag: Paginator;

  subs = new SubSink();

  listTest = [];
  listTestChunk = [];
  originalListTest = [];  
  rowsToDisplayDefault = this.embedded ? 4 : 8;
  maxNumberOfTestDisplayedInEmbedded = 4
  topRow = this.rowsToDisplayDefault;
  loaded = false;
  currentLang: any;

  activeSubscriptions = new SubSink();
  filterCount = -1;
  countryId: string;
  showZendeskButton: boolean = false;

  constructor(
    private catalogSrv: CatalogService,
    private authSrv: AuthenticationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.getCatalog();

    // Preserve the lang to refresh the page in language changes
    this.currentLang = this.translate.currentLang;

    this.activeSubscriptions.sink = this.translate.onLangChange.subscribe((res) => {
      if(res.lang !== this.currentLang)
        document.location.reload()
    });

    ///*** we deactivated the button at the request from Italy, I leave a comment in case they want to activate it in a future.*/
    /// check if  current user subsidiary is from Italy to show the zendesk button
    // this.countryId = localStorage.getItem('countryPsyuserId')
    // if (this.countryId === '108'){
    //   this.showZendeskButton = true;
    // };
  }

  ngAfterViewChecked(){
    this.filter.changes.subscribe(() => {      
      this.filterCount = this.filter.first.filteredCount;
    });
  }

  ngAfterViewInit() {
    this.subs.sink = this.filter.changes.subscribe(() => {      
      this.filter.first?.emitFilter()
    });
  }

  getCatalog(): void{
    // Get psyUser data
    this.activeSubscriptions.sink = this.authSrv.sharedCurrentPsyUser.subscribe((resPsyUser) => {
      
      if (resPsyUser !== null) {
        this.catalogSrv.setPsyUserAssignationGroups(resPsyUser);

        this.catalogSrv.getCatalog().then(res => {
          this.listTest = res;

          // Order by parentTestName by default
          this.listTest.sort((a, b) => (a.parentTestName > b.parentTestName) ? 1 : ((b.parentTestName > a.parentTestName) ? -1 : 0));
          // Preserve the oriringal array list
          this.originalListTest = this.listTest;

          //Not embedded
          if (this.embedded) {            
            //Filter test list to be only maxNumberOfTestDisplayedInEmbedded tests, and not inactive
            let filterTestList = this.listTest.filter(test => test.active == true)
            filterTestList = filterTestList.slice(0,this.maxNumberOfTestDisplayedInEmbedded)

            this.listTest = filterTestList
          }

          this.listTestChunk = this.listTest.slice(0, this.rowsToDisplayDefault);
          this.loaded = true;

          if (this.filter) {            
            this.filter.first?.emitFilter();
          }
        });
      }
    });
  }

  paginate(event): any {
    this.topRow = event.first + event.rows;
    if (this.topRow > this.listTest.length) {
      this.topRow = this.listTest.length;
    }
    this.listTestChunk = this.listTest.slice(event.first, this.topRow);
  }

  applyFilter($event): any {
    this.listTest = $event;
    this.listTestChunk = this.listTest.slice(0, this.rowsToDisplayDefault);
    if(this.pag)
      this.pag.changePage(0)
  }

  resetFilter($event): any {
    this.listTest = this.originalListTest;
    this.listTestChunk = this.listTest.slice(0, this.rowsToDisplayDefault);
  }
}
