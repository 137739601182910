import { Component, OnDestroy, OnInit ,ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  ReactiveFormConfig,
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { inOutAnimation } from "src/app/shared/animations/in-out-animation";
import { ToastService } from "src/app/shared/services/toast.service";
import { SubSink } from "subsink";
import { CoreApi } from "../api/core.api";
import { AuthenticationService } from "../authentication/authentication.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
  animations: [inOutAnimation],
  providers: [ToastService],
})
export class ForgotComponent implements OnInit, OnDestroy {
  forgotForm: UntypedFormGroup;
  loading: Boolean = false;
  private key = environment.key;
  private clientId = environment.clientId;
  subs = new SubSink();
  private token = "";
  @ViewChild('captchaRef') input;

  constructor(
    public authSrv: AuthenticationService,
    private coreApi: CoreApi,
    public router: Router,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.forgotForm.reset();
    });
    this.setValidationMessages();
    this.generateForm();
  }

  getKey() {
    return this.key;
  }

  get captchaRef(): any {
    return this.forgotForm.get("captchaRef");
  }


  setValidationMessages(): void {
    this.subs.sink = this.translate.get("random.key").subscribe(() => {
      ReactiveFormConfig.set({
        validationMessage: {
          required: this.translate.instant("VALIDATIONS.REQUIRED"),
          email: this.translate.instant("VALIDATIONS.EMAIL"),
        },
      });
    });
  }

  generateForm(): void {
    this.forgotForm = this.formBuilder.group({
      email: ["", [RxwebValidators.required(), RxwebValidators.email()]],
      captchaRef: [null, [RxwebValidators.required()]]
    });
  }

  get email(): any {
    return this.forgotForm.get("email");
  }

  login(): void {
    const captcha = {
      token: this.token,
    };

    const user = { email: this.email.value, client_id: this.clientId };
    this.loading = true;
    this.coreApi.captcha(captcha).subscribe(
      (result) => {
        this.loading = false;
        this.subs.sink = this.coreApi.forgotPassword(user).subscribe(
          (res) => {
            this.toastSrv.showToastSucess(
              this.translate.instant(`FORGOT.MESSAGE`)
            );
            //setTimeout( () => {
            this.router.navigateByUrl('/'); // Redirect to home
            //}, 3000 );
          },
          (error) => {
            
            const msg = this.translate.instant(
              `RESPONSES.${error.error.message.toUpperCase().replace(" ", "-")}`
            );
            this.toastSrv.showToastError(msg);
          }
        );
      },
      (error_captcha) => {
        this.loading = false;
        this.input.reset();
        this.toastSrv.showToastError(
          this.translate.instant(`RESPONSES.ERROR-CAPTCHA`)
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  resolved(captchaResponse: string) {
    this.token = captchaResponse;
  }
}
