import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { Router } from '@angular/router';
const moment = require('moment-timezone');
import { TranslateService } from '@ngx-translate/core';
import { DashboardApi } from '../api/dashboard.api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ToastService],
})
export class DashboardComponent implements OnInit {
  countryId: number;
  showZendeskButton: boolean = false;
  subs = new SubSink();
  loaded = false;
  maintenanceMsg = ''

  protected showDialog: boolean = false;

  constructor(
    private authSrv: AuthenticationService,
    private translate: TranslateService,
    private dashboardApi: DashboardApi,
    public router: Router
  ) { }

  // CHECK IF SHOW MAINTENANCE DIALOG
  private checkShowDialog() {

    this.dashboardApi.getPsyUserMaintenance().subscribe(_res => {
      try {
        // Set Init date to show the dialog (-1 hr in db for Madrid)
        const initDate = moment(_res.data.init_date).tz('Europe/Madrid');
        // Set Finish date to show the dialog (-1 hr in db for Madrid)
        const secondDate = moment(_res.data.finish_date).tz('Europe/Madrid');

        // Compare dates to know if the current time is between init and finish
        const now = moment();
        const betweenDates = now.isBetween(initDate.local(), secondDate.local());

        // To check if the user has previously clicked in the dialog
        const dialogData = JSON.parse(localStorage.getItem('dialogData'));

        // If the current time is between init and finish, and the dialog has not been clicking
        if (betweenDates && !dialogData?.read) {

          // Check if exist expected date (-1 hr in db for Madrid)
          const expectedDate = _res.data.expected_date ? moment(_res.data.expected_date).tz('Europe/Madrid') : null;

          // Set date msg
          const dateMsg = this.translate.instant('MAINTENANCE.MSG-START').replace('[date]', secondDate.local().format('DD/MM/YYYY'));

          // Set hour msg depending on wether there is expectedDate
          const hourMsg = expectedDate ? this.translate.instant('MAINTENANCE.MSG-HOUR-RANGE').replace('[hour-from]', secondDate.local().format('HH:mm')).replace('[hour-to]', expectedDate.local().format('HH:mm'))
                                     : this.translate.instant('MAINTENANCE.MSG-HOUR').replace('[hour]', secondDate.local().format('HH:mm'));

          // Set complete msg
          this.maintenanceMsg = dateMsg + hourMsg + this.translate.instant('MAINTENANCE.MSG-FINISH');

          // Enable and show the dialog
          this.showDialog = true;
        }
      } catch {
        console.error('Error parsing maintenance date');
      }

    });
  }

  protected closeDialog() {
    this.showDialog = false;
    const dialogData = { date: moment(), info: '', read: true }; // this enables other messages to be viewed in the future even if the "read" property is set to true.
    localStorage.setItem('dialogData', JSON.stringify(dialogData));
  }

  ngOnInit(): void {

    /// check if  current user subsidiary is from Italy to show the zendesk button
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {

      if(dataUsr){
        ///*** we deactivated the button at the request from Italy, I leave a comment in case they want to activate it in a future.*/
        // this.countryId = dataUsr.customer.country_id;
        // if (this.countryId === 108) {
        //   this.showZendeskButton = true;
        // }

        // Change to catalog it the user is assistant
        if(dataUsr.isAdmin == PsyUserRoles.ASSISTANT){
          this.router.navigateByUrl("/catalog"); // Redirect to catalog
        }

        this.loaded = true;
      }
    });

    // Displays maintenance warning message
    this.checkShowDialog();
  }
}
