<div class="p-d-flex profile-menu">
    <div class="profile-name p-d-inline-flex p-ai-center" (click)="menu.toggle($event)">
        <span class="profile-name-text p-mx-2">{{userName}}</span>
        <span class="profile-name-icon p-mx-2 {{isArrowRotate}}">
            <i class="pi pi-angle-up"></i>
        </span>
    </div>
    <p-menu #menu [popup]="true" [model]="items" (onShow)="arrowToBottom()" (onHide)="arrowToTop()" class="profile-menu"></p-menu>
    <div class="p-d-flex p-ai-center">
        <!-- PROFILE ICON -->
        <div class="profile-avatar p-mx-2 p-d-inline-flex p-ai-center p-jc-center">
            <span class="profile-avatar-icon"><i class="pi pi-user"></i></span>
        </div>

        <!--LABELS-->
        <div class="profile-label p-d-flex p-flex-column p-jc-end">
            <div *ngIf="userLevel" class="profile-name-level p-d-flex p-ai-center p-jc-center">{{userLevel}}</div>
            <div *ngIf="customerDemo" class="profile-name-demo p-d-flex p-ai-center p-jc-center">DEMO</div>

            <div class="profile-name-role p-d-flex p-ai-center p-jc-center" *ngIf="isAdmin !== psyUserRoles.PSYUSER" [ngSwitch]="isAdmin">
                <span *ngSwitchCase="psyUserRoles.PSYUSER_ADMIN">{{'DETAIL-PEOPLE.PROFILE-ADMIN' | translate }}</span>
                <span *ngSwitchCase="psyUserRoles.PSYUSER_FULL">{{'DETAIL-PEOPLE.PROFILE-ADMIN-FULL' | translate }}</span>
                <span *ngSwitchCase="psyUserRoles.ASSISTANT">{{'DETAIL-PEOPLE.PROFILE-ASSISTANT' | translate }}</span>
            </div>
        </div>

        <!--NOTIFICATIONS BELL-->
        <span class="profile-name-bell p-mx-2 p-d-flex">
            <i class="pi pi-bell" [ngClass]="{'shaking-animation': showShaking}" (click)="displayNotificationsBar=true"></i>
            <p-badge *ngIf="notificationsList.length > 0" [value]="notificationsList.length" (click)="displayNotificationsBar=true"></p-badge>
        </span>
    </div>
</div>

<!--CONTACT DATA DIALOG-->
<p-dialog [(visible)]="showDialog">
    <!-- if the psyuser does not have a subsidiary with data, the default data appears -->
    <div *ngIf="!hasSubsidiaryData" class="dialog-content">
        <h3>{{'CONTACT.MSG'|translate}}</h3>
        <div class="text">
            <p [innerHtml]="'CONTACT.DATA' | translate"></p>
        </div>
    </div>
    <!-- if the psyuser has a subsidiary with data, the available data will appear -->
    <div *ngIf="hasSubsidiaryData" class="dialog-content p-d-flex p-flex-column">
        <div *ngIf="subsidiaryData?.message">
            <h3>{{subsidiaryData.message}}</h3>
        </div>
        <div *ngIf="subsidiaryData?.phone" class="p-my-2 p-text-center">
            <span>☎ &nbsp;&nbsp;{{subsidiaryData.phone}}</span>
        </div>
        <div *ngIf="subsidiaryData?.email" class="p-my-2 p-text-center">
            ✉️ &nbsp;&nbsp;<a href="mailto:{{subsidiaryData.email}}">{{subsidiaryData.email}}</a>
        </div>
        <div *ngIf="!subsidiaryData?.message && !subsidiaryData?.phone && !subsidiaryData?.email" class="p-my-2 p-text-center">
            <h3>{{'CONTACT.MSG'|translate}}</h3>
            <div class="text">
                <p [innerHtml]="'CONTACT.DATA' | translate"></p>
            </div>
        </div>
    </div>
</p-dialog>

<!--SIDEBAR CONTENT-->
<p-sidebar #sidebarNotifications [(visible)]="displayNotificationsBar" position="right" appendTo="body" [blockScroll]="true" [modal]="true" (onShow)="reloadListInView()">
    <div class="sidebar-filter-header">
        <h4>{{('PROFILE.NOTIFICATIONS' | translate) | uppercase}}</h4>
        <hr>
    </div>
    <!-- [@listAnimationV2]="notificationsList"  -->
    <div class="sidebar-filter-content" #sidebarContent *ngIf="loaded">
        <div *ngFor="let notification of notificationsList"
            class="notification-card p-shadow-2 p-d-flex p-jc-between">
            <div class="p-grid row-project pointer">
                <p-avatar
                    class="row-avatar p-col-12 p-lg-2 p-md-2 p-sm-2"
                    [icon]="notification.type === 'reporting' ? notification.downloads.length > 0 ? 'pi pi-file-pdf' : 'pi pi-ban'   : 'pi pi-folder'"
                    (click)="notification.type === 'reporting' ? download(notification) : goToProject(notification)"
                    size="xlarge"
                    pTooltip="{{notification.tooltip}}"
                    >
                </p-avatar>
                <div *ngIf="notification.type === 'reporting' && notification.downloads.length > 0" class="downloadnumber">{{notification.downloads.length}}</div>
                <div class="p-col-12 p-lg-10 p-md-10 p-sm-10 p-d-flex p-flex-column p-jc-between p-my-2" (click)="goToProject(notification)">
                    <span class="project-name">
                        {{notification.project.code}}
                    </span>
                    <div class="row-created p-d-flex p-ai-center p-jc-between">
                        <span class="row-status p-mt-1">
                            <span *ngIf="notification.type === 'scoring'">
                                <div *ngIf="notification.statusSuccess">{{'PROFILE.STATUS-OK' | translate}} <span class="notification-success">{{notification.statusSuccess}}</span></div>
                                <div *ngIf="notification.statusFail">{{'PROFILE.STATUS-NO' | translate}} <span class="notification-fail">{{notification.statusFail}}</span></div>
                                <div *ngIf="notification.statusWarning">{{'PROFILE.STATUS-OK' | translate}} <span class="notification-warning">{{notification.statusWarning}}</span></div>
                            </span>
                            <span *ngIf="notification.type === 'reporting'">
                                <div *ngIf="notification.statusSuccess">{{'PROFILE.STATUS-OK-REP' | translate}} <span class="notification-success">{{notification.statusSuccess}}</span></div>
                                <div *ngIf="notification.statusFail">{{'PROFILE.STATUS-NO-REP' | translate}} <span class="notification-fail">{{notification.statusFail}}</span></div>
                                <div *ngIf="notification.statusWarning">{{'PROFILE.STATUS-OK' | translate}} <span class="notification-warning">{{notification.statusWarning}}</span></div>
                            </span>
                        </span>
                        <span class="p-mr-4">
                            {{notification.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-ai-center">
                <i class="pi pi-trash btn-secondary-menu pointer" (click)="deleteNotification(notification)"></i>
            </div>
        </div>
        <div class="p-text-center btn-delete-notifications">
            <button
                [disabled]="notificationsList.length == 0"
                pButton
                class="btn-mini2"
                (click)="deleteAllNotifications()"
                label="{{'PROFILE.BTN-DELETE-ALL' | translate}}">
            </button>
        </div>
    </div>
</p-sidebar>