  <!-- psyUser questions -->
  <div class="p-fluid p-formgrid p-grid profile-container" [formGroup]="itemForm" *ngIf="itemData.length > 0">
    <h3 class="p-field p-col-12 p-lg-12 p-md-12">
      {{title | translate}}
    </h3>

    <!--Inputs without group-->
    <div class="p-field p-col-12 p-lg-6 p-md-12" *ngFor ="let i = index; let item of nonGroupedFields">      
      <app-input *ngIf="!item.hidden" [inputForm]="item" [myFormGroup]="itemForm" [maskEditable]="maskEditable" [dynamicOptions]="item.options"></app-input>
    </div>

     <!--Inputs withs groups-->
    <div *ngFor ="let i = index; let group of groupedFields" class="p-col-12 p-lg-12 p-md-12">
      <label class="label">{{group.label}}</label>
      <p></p>
      <hr class="group-divisor">
      <div class="group">
        <div *ngFor="let item of group.items" class="p-col-12 p-lg-6 p-md-12 p-field">
          <div *ngIf="item.commonOptions; else nonCommonOptions">
            <app-input *ngIf="!item.hidden"
              [inputForm]="item" 
              [myFormGroup]="itemForm" 
              [maskEditable]="maskEditable"
              [dynamicOptions]="dynamicOptions"
              (optionChange)="dynamicOptionChange($event)">
            </app-input>
          </div>
          <ng-template #nonCommonOptions>
            <app-input [inputForm]="item" [myFormGroup]="itemForm" [maskEditable]="maskEditable" [dynamicOptions]="item.options"></app-input>
          </ng-template>
        </div>
      </div>
    </div> 

    <div class="p-col-12 info-data">
      <span><span class="red_dot">*</span>{{'EDIT-PEOPLE.MANDATORY' | translate}}</span>
    </div>
</div>