<app-row-card
    [optionLeft]="optionsLeft"
    [optionRight]="optionsRight"
    [customNopeopleTl]="groupItems"
    [item]="item"
    [itemOptions]="itemOptions"
    (itemDeleted)="deleteItem()"
    (itemSelected)="selectItem()"
    (click)="selectItemOpt()">
</app-row-card>

<ng-template #groupItems>
    <div class="p-grid p-ai-center container-data">
        <p-avatar
            icon="pi pi-users"
            class="row-avatar group p-col-12 p-lg-2 p-md-2 p-sm-2"
            [ngClass]="{'circle-selected': item.selected}"
            size="xlarge"
            shape="circle">
        </p-avatar>
        <span class="row-name p-col-12 p-lg-10 p-md-10 p-sm-10">
            {{item.name}}
        </span>
        <div *ngIf="notEmails">
            <ul class="emails">
                <li *ngFor="let testaker of item.nonValidTesttakers" class="testtaker-email">
                    {{!testaker.name && !testaker.surname ? '#' + testaker.friendlyId : testaker.name + ' ' + testaker.surname}}
                </li>
            </ul>
        </div>
    </div>
</ng-template>