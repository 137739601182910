import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ZendeskService {
    private url = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    createNewTicket(psyuser_name, psyuser_id, psyuser_email, message): Observable<any>{
      const body = {
        psyuser_name : psyuser_name,
        psyuser_id : psyuser_id,
        psyuser_email : psyuser_email,
        message : message
      }
        return this.http.post(this.url + 'zendesk/newticket', body);
    }

}