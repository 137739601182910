<app-row-card
    [optionLeft]="optionsLeft"
    [optionRight]="optionsRight"
    [customNopeopleTl]="groupItems"
    [itemOptions]="itemOptions"
    [item]="item"
    [triggerName]="triggerName"
    (itemDeleted)="deleteItem()"
    (itemTriggered)="emmitTrigerredAction($event)">
</app-row-card>

<ng-template #groupItems>
    <div class="p-grid p-ai-center container-data">
        <img class="image p-col-12 p-lg-1 p-md-1 p-sm-1" src="assets/images/row-test.png">
        <span class="row-name p-col-12 p-lg-5 p-md-5 p-sm-5">
            <span  *ngIf="testNameClickable" class="p-mr-2" (click)="goToDetailsView()">{{item.parentTestName}} - {{item.testName}} [{{item.locale | uppercase}}]</span>
            <span  *ngIf="!testNameClickable" class="p-mr-2">{{item.parentTestName}} - {{item.testName}} [{{item.locale | uppercase}}]</span>
        </span>
        <!-- <span class="row-user-name p-col-12 p-lg-2 p-md-2 p-sm-2">
            {{item.userName}}
        </span> -->

        <span class="row-user-name p-col-12 p-lg-2 p-md-2 p-sm-2">
            {{'PROJECTS.CREATED'|translate}} {{item.createdAt | date: 'dd/MM/yyyy'}}
        </span>

        <div class="p-col-12 p-lg-2 p-md-2 p-sm-2">
            <custom-label [labelType]="item.status"></custom-label>
        </div>
        <div class="p-col-12 p-lg-2 p-md-2 p-sm-2 p-text-right">
            <!--BUTTONS-->
            <div *ngIf="!loading && optionsRight!=='trigger'">
                <button
                    *ngIf="([subProjectType.inMaMu,subProjectType.inMaSi, subProjectType.grMaSi, subProjectType.grMaMu].includes(item.projectType) && [ProjectStatusString.ASSIGNED].includes(item.status))&& !onlyViewMode"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="insert()"
                    label="{{'PROJECTS.BTN-INSERT' | translate}}">
                </button>

                <button
                    *ngIf="((item.projectType === subProjectType.inPrSi || item.projectType === subProjectType.inPrMu) && [ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(item.status))&& !onlyViewMode"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="administer()"
                    label="{{'PROJECTS.BTN-ADMIN' | translate}}">
                </button>

                <button
                    *ngIf="[ProjectStatusString.READY_TO_SCORE].includes(item.status) && !onlyViewMode"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="manageScoringForTest()"
                    label="{{'PROJECTS.BTN-SCORING'  | translate}}">
                </button>

                <!-- This button used to call downloadReports() -->
                <!-- Only created if user is not in the user-detail page and the test is groupal-->
                <button
                    *ngIf="item.status === ProjectStatusString.SCORED && !onlyViewMode"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="getReportForTest()"
                    label="{{'PROJECTS.BTN-REPORT' | translate}}">
                </button>

                <!-- VIEW-PROJECT (or another action) if onlyViewMode. This replaces REPORT, SCORING or any other action  -->
                <button
                    *ngIf="onlyViewMode"
                    type="button"
                    class="btn-mini btn-trigger p-mr-1"
                    pButton
                    (click)="emmitTrigerredAction(item)"
                    label="{{ 'DETAIL-PEOPLE.'+triggerName | translate }}" >
                </button>

                <!-- (This buttons before it called downloadReport) -->
                <button
                    *ngIf="item.status === ProjectStatusString.INVALID_SCORING && !onlyViewMode"
                    type="button"
                    class="btn-mini p-mx-1"
                    pButton
                    (click)="manageScoringForTest()"
                    label="{{'PROJECTS.BTN-FORCE-SCORING' | translate}}">
                </button>
            </div>

            <!-- Spinner -->
            <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
        </div>
    </div>
</ng-template>

<!--POP-UP: Select User-->
<p-dialog [(visible)]="displayPanel" [style]="{width: '70vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog">
    <div class="dialog-content p-text-center user-dialog">
        <h3>{{displayScoring ? ('PROJECTS.DIALOG-TITLE-SCORING'|translate) : ('PROJECTS.DIALOG-TITLE'|translate)}}</h3>
        <app-template-user-simple
            *ngFor="let item of dialogItems"
            [item]="item.testtaker"
            (itemTriggered)="insertByItem($event, displayScoring)"
            [optionsLeft]="'none'"
            [triggerName]="'SELECT'"
            [optionsRight]="'trigger'">
        </app-template-user-simple>
    </div>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>

<!-- POP-UP: Scoring with Psyuser questions popup-->
<p-dialog [(visible)]="displayScoringPopup" [style]="{width: '80vw'}" [modal]="true" class="template-dialog-scoring">
    <ng-template pTemplate="header">
        <h3 class="p-ai-center p-text-center scoring-title">Scoring Menu</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <app-scoring-psyquestions-template
            #scoringPopupTemplate
            [psyUserQuestionsForTest]="projectTestSharedQuestions"
            [relatedTestQuestionnaires]="this.item.tests"
            (scoringSuccessfull) = "onClosePopupPsyuserQuestionsForScoring()"
            (scoringFailed) = "onClosePopupPsyuserQuestionsForScoringFailed()">
        </app-scoring-psyquestions-template>
    </ng-template>

    <ng-template pTemplate="footer" class= "p-text-right p-my-3">
        <button pButton
            (click)="scoringPopupRefPopup.saveQuestionnairesAndScore()"
            label="Save & Score"
            class="btn-primary"
            [disabled]="scoringPopupRefPopup?.scoreButtonDisabled">
        </button>
    </ng-template>
</p-dialog>

<!-- POP-UP: Get another report popup-->
<p-dialog [(visible)]="displayAnotherReportPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report" (onHide)="reportingPopupRefPopup.clearPreviousSelected()">
    <div class="dialog-content">
        <app-report-selector-template
            #reportingPopupTemplate
            [projectData]="item"
            [testAndReportList]="projectTestReportList"
            [testtakerList]="projectTesttakerList"
            (reportPurchased)="anotherReportMenuOnClose()"
            *ngIf = "projectTestReportList.length > 0">
        </app-report-selector-template>
    </div>
</p-dialog>

<!-- POP-UP: Scoring no valid popup-->
<p-dialog [(visible)]="displayNonValidScoringPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report">
    <div class="dialog-content">
            <app-non-valid-scoring-template
                #scoredNonValidPopupTemplate
                [itemTests]="itemTests"
                [NonValidScoringCount]="NonValidScoringCount">
            </app-non-valid-scoring-template>
    </div>
    <ng-template pTemplate="footer">
        <span class="p-d-flex p-jc-between">
            <button
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ALL' | translate}}"
                (click)="getAllReports()"
                class="btn-primary">
            </button>
            <button *ngIf="itemTests.length > 1"
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ONLY-VALID' | translate}}"
                (click)="getOnlyValidReports()"
                class="btn-primary">
            </button>
            <button
                pButton
                label="{{'REPORTS.BTN-CANCEL' | translate}}"
                (click)="closePopup()"
                class="btn-primary">
            </button>
        </span>
    </ng-template>
</p-dialog>