<!--TOAST ELEMENTS-->
<p-toast position="top-center" key="toast-error-dialog" preventOpenDuplicates="true" class="toast-dialog">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
        </div>
    </ng-template>
</p-toast>

<div class="dialog-roles">
    <h3 class="p-mb-4">{{'CREATE-PROJECT.ASSING-ROLE-TO-TESTTAKER' | translate}}</h3>
<!-- MAIN CONTENT -->
    <div class="user-card" *ngFor= "let user of availableUsers let i = index">
        <div class="user-card-content p-grid"  >
            <div class=" p-col-8 p-d-flex  p-ai-center">
                <p-avatar
                    icon="pi pi-user"
                    class="p-mx-3"
                    styleClass="p-mr-2.5"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
                <div class="p-d-flex  p-mx-3">
                    <span class="user-card-name">{{user.nameToShow}}</span>
                </div>
            </div>
            <div class="p-d-flex p-ai-center p-col-4 width-full">
                <p-dropdown
                    [options]="availableRoles"
                    class="custom-dropdown pointer"
                    optionLabel="label"
                    optionValue="name"
                    (onChange)="setAvailableRoles()"
                    placeholder="{{'CREATE-PROJECT.SELECT-ROLE' | translate}}"
                    [showClear]="true"
                    [(ngModel)]="user.role"
                    optionDisabled="inactive">
                </p-dropdown>
            </div>
        </div>
    </div>
    <h3 class="p-mt-4 p-mb-2">{{'CREATE-PROJECT.EVALUATED-PERSON' | translate}}</h3>
    <div>
        <div class="p-d-flex p-ai-center width-full">
            <p-dropdown
                (onChange)="setEvaluatedAssessmentName(evaluatedPerson.nameToShow)"
                [options]="evaluatedPersonList"
                class="custom-dropdown pointer p-my-3"
                optionLabel="nameToShow"
                placeholder="{{'CREATE-PROJECT.EVALUATED-TEXT' | translate}}"
                [(ngModel)]="evaluatedPerson">
            </p-dropdown>
        </div>
        <div *ngIf="evaluatedPerson && evaluatedPerson.id >= 0">
            <label class="custom-label p-my-2">{{'CREATE-PROJECT.EVALUATED-NAME-ASSESSMENT' | translate}}</label>
            <input type="text" pInputText class="custom-input-text p-my-3" [(ngModel)]="evaluatedPerson.assessmentName" (input)="emitChangesToParent()">
        </div>
    </div>
</div>





