import { Injectable } from '@angular/core';
import { ProjectsApi } from '../api/projects.api';
import { ProjectsService } from './projects.service';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ReportingService } from './reporting.service';
import { async } from '@rxweb/reactive-form-validators';


//Check communications results helper
let checkerSuccess = arr => arr.every(value => value === true); //If all array values are true, returns true
let checkerFailure = arr => arr.every(value => value === false); //If all array values are false, returns true

@Injectable({
  providedIn: 'root'
})
export class ScoringService {

  constructor(
    private projectsApi: ProjectsApi,
    private projectSrv: ProjectsService,
    private reportingSrv: ReportingService
  ) { }

  //@PABLO This function, used in entry mask, do almost the same job than the one above in scoring popup template and scoring service.
  //TODO Evaluate for a future refactoring into the service, or just use the other.
  async checkPsyQuestionsFieldsForScoring(qCodes) {
    let notValidQuestionnaires = [];

    for (const x of qCodes) {
      const res: any = await this.projectsApi.getQuestionaire(x).toPromise();
      const questionnaire = res.data;

      if(questionnaire.test.profileData){
        // Get the mandatory questions for the psyuser and check if exist in the current scoringParams
        if (questionnaire.test.profileData.mandatoryForPsy) {
          questionnaire.test.profileData.mandatoryForPsy.forEach((z) => {
            const value = questionnaire.scoringParams[z];
            if (value === null || value === undefined) {
              notValidQuestionnaires.push(x);
            }
          });
        }
      }
    }

    notValidQuestionnaires = [...new Set(notValidQuestionnaires)];

    if (notValidQuestionnaires.length > 0) {
      return { valid: false, notValidQuestionnaires: notValidQuestionnaires };
    } else {
      return { valid: true };
    }
  }

  //MANAGE RESULTS OF SCORING OPERATIONS SECTION//
  /**
   * Check the result of the operation of saving scoring params
   * and change status to 23, ready to score.
   * @param scoringResultArray
   * @returns true if all went well, false if not
   */
  public checkSavingParamsResults(scoringResultArray) : boolean{

    if(checkerSuccess(scoringResultArray)){
      console.log("Success saving questionnaires scoring params and putting them in status READY TO SCORE(23)")
      return true

    }else if(checkerFailure(scoringResultArray)){
      console.error("Failure saving questionnaires scoring params")
      return false

    }else{
      console.error("Failure saving some of the questionnaires scoring params")
      return false
    }
  }

  async checkPsyUserQuestions(questionnaireList) {

    // Avoid external test for check questions
    const questionnairesToCheck = questionnaireList.filter(_quest => !_quest.test.external);

    //Get test and report information for this test
    let projectTestIds = this.reportingSrv.getTestsAndReportsFromProject(questionnairesToCheck).map(_test => _test.id);

    try {
      const testSharedQuestions: any = await this.projectsApi.getTestPsyuserSharedQuestions(projectTestIds);

      return testSharedQuestions.data.filter(x => !x.scoreDirectly);
    }catch(err){
      let msg = "Error retrieving psyuser questions";
      console.error(msg, err)
      throw new Error(msg)
    }
  }
}
