import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { SubSink } from "subsink";
import { AuthenticationService } from "../authentication/authentication.service";
import { ProfileMenuComponent } from "../profile-menu/profile-menu.component";
import { headerMenuItems, headerMenuItemsAssistant } from "./data/header.options";
import { PsyUserRoles } from '@giuntipsy/utils/lib';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild(ProfileMenuComponent, {static: false}) profileMenuComp: ProfileMenuComponent;

  public menuItems: MenuItem[] = [];
  public isAuthenticated;  
  private psyuser: any;
  
  subs = new SubSink();

  constructor(
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    private router: Router) {}

  ngOnInit(): void {
    // The init component is re-launch for every change in user session
    this.subs.sink = this.authSrv.sharedCurrentSession.subscribe(data => {
      this.initComponent();
    });
    this.initComponent();
  }

  initComponent(): void {
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.loadMenu();
    });

    if (this.authSrv.isAuthenticated()) {
      this.isAuthenticated = true;            
    } else {      
      this.isAuthenticated = false;      
      this.profileMenuComp?.ngOnDestroy();
    }
  }

  loadMenu(): void {
    // Necesita llamar al servicio translator, una vez dentro ya se puede hacer el instant sin problema
    this.subs.sink = this.translate.get('random.key').subscribe(() => {

      this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(psyuser => {
        if(psyuser !== null) {

          this.psyuser = psyuser;
          let menuItemsToLoad: any;

          if(psyuser.isAdmin === PsyUserRoles.ASSISTANT){
            menuItemsToLoad = headerMenuItemsAssistant;
          } else {
            menuItemsToLoad = headerMenuItems;
          }

          const items = [];
          menuItemsToLoad.data.forEach(element => {
            const item = {
              label: this.translate.instant(element.title),
              routerLink: element.link,
            };
            items.push(item);
          });
          this.menuItems = items;
        }
      })      
    });
  }

  goHome(): void {
    if(this.psyuser.isAdmin == PsyUserRoles.ASSISTANT){
      this.router.navigateByUrl('/catalog');
    } else {
      this.router.navigateByUrl('/');
    }    
  }

  ngOnDestroy(): void {    
    this.subs.unsubscribe();
  }
}
