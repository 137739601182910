<div class="plan_available p-shadow-2 p-d-flex p-ai-center p-jc-between" [ngClass]="{'inactive': !plan.isAlive}">
    <div class="p-d-flex p-ai-center">
        <i class="pi pi-eye btn-secondary-menu pointer p-mr-4" (click)="showPlan(plan)" pTooltip="Info" tooltipPosition="top"></i>
        <div class="p-d-flex p-flex-column p-ai-start">
            <span class="plan-name">{{planName}}</span>
            <div class="p-d-flex p-ai-center detail-label">
                <i class="pi pi-info-circle"></i>
                <div>{{'ACCOUNTS.SUBSCRIPTION' | translate}}:
                    <span class="detail-data">{{plan.sub_id | padNumber: 10}}</span>
                    <span *ngIf="plan.psyuserSub_id"> #{{plan.psyuserSub_id}}</span>
                </div>
            </div>            
            <div class="p-d-flex p-ai-center detail-label">
                <i class="pi pi-calendar"></i>
                <div>{{'ACCOUNTS.PERIOD' | translate}} 
                    <span *ngIf="!moreThan100(plan.startDate, plan.finishDate)" class="detail-data">{{plan.startDate | date: 'dd/MM/yyyy'}} - {{plan.finishDate | date: 'dd/MM/yyyy'}} </span>
                    <span *ngIf="moreThan100(plan.startDate, plan.finishDate)" class="detail-data infin">&infin;</span>
                </div>
            </div>
            <div class="p-d-flex p-ai-center detail-label">
                <i class="pi pi-book"></i>
                <div>
                    <div *ngIf="!plan.usesBased">{{'ACCOUNTS.PLAN-TYPE' | translate}}
                        <span class="detail-data">{{'ACCOUNTS.CREDIT-BASED' | translate}}</span></div>
                    <div *ngIf="plan.usesBased">{{'ACCOUNTS.PLAN-TYPE' | translate}}
                        <span class="detail-data">{{'ACCOUNTS.USES-BASED' | translate}}</span></div>
                </div>
            </div>
        </div>
    </div>
    
    <!--Optional: Show button to assign subscription-->
    <button 
        *ngIf="showAssign"
        type="button" 
        class="btn-mini" 
        pButton
        (click)="addSubscription()"
        label="{{'CREATE-PROJECT.BTN-ADD' | translate}}">
    </button>

    <button 
    *ngIf="plan.reachedMaxUsers"
    type="button" 
    class="btn-mini" 
    pButton
    disabled
    label="{{'CREATE-PROJECT.BTN-COMPLETED' | translate}}">
    </button>
 

    <!--Optional: Show buttons to manage credits and delete subscription-->
    <div class="p-col-12 p-lg-4 p-md-12 p-d-flex p-ai-center p-jc-end user-card-actions clickable" *ngIf="actions">        
        <i *ngIf="!plan.usesBased && plan.isAlive" class="pi pi-pencil btn-secondary-menu pointer p-mx-5" (click)="showEditCredit()"></i>
        <i *ngIf="plan.isAlive" class="pi pi-trash btn-secondary-menu pointer" (click)="deleteSubscription()"></i>
    </div> 
</div>
