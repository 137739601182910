import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ReportingService } from 'src/app/modules/projects/services/reporting.service';
import { ReportStatus } from 'src/app/shared/enums/report-status.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { Codes, QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ErrorsService } from 'src/app/shared/services/errors.service'
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { CatalogApi } from 'src/app/modules/catalog/api/catalog.api';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';

@Component({
  selector: 'app-report-selector-template',
  templateUrl: './report-selector-template.component.html',
  styleUrls: ['./report-selector-template.component.scss']
})
export class ReportSelectorTemplateComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() testAndReportList: any;
  @Input() testtakerList: any;
  @Input() projectData: any;
  @Input() partialReport: boolean = false; // Flag to indicate if is partial report or not

  @Output() reportPurchased = new EventEmitter<any>();
  @Output() visiblePartialReporting = new EventEmitter<any>();
  @Output() requestedReports = new EventEmitter<any>();

  @ViewChild('purchasedReport') purchasedReport: ElementRef;
  @ViewChild('selectorReport') selectorReport: ElementRef;

  subs = new SubSink();

  reportList = [];
  //Report User's Selection
  reportsToPurchase = [];

  //Price management variables
  totalSelectionCredits = 0;
  totalSelectionPlanUses = 0;

  //Purchase button active/inactive
  psyuserCanPurchaseSelection = false;

  //Download Button active/inactive
  allSelectedReportsAlreadyPurchased = false;
  onlyNewReports = false;
  //Spinner if Purchasing/Generating
  purchasingSpinner = false;

  //Error purchasing flag
  errorGeneratingReport = false;

  errorGeneratingMsg = ""
  //Contingency mode, block user selection until all reports are downloaded.
  // userSelectionBlocked = false;

  // Show or hide uses and credits
  showCredits: boolean;
  showUses: boolean;

  // Declare report status enum
  reportStatus = ReportStatus;

  // Set MBTI bottom message
  mbtiMsg: string = '';
  currentCustomer: any;

  constructor(
    private authSrv: AuthenticationService,
    private reportingSrv: ReportingService,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private messageSrv: MessageService,
    private errorsSrv: ErrorsService,
    private catalogApi: CatalogApi,
    private cdRef: ChangeDetectorRef,
    private projectsApi: ProjectsApi,
    private projectsSrv: ProjectsService
  ) { }

  ngOnInit(): void {

    //Add to each report a field to mark the selected user's choices from the rest
    this.reportList = [...this.testAndReportList]

    this.clearPreviousSelected();

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {
      this.showCredits = dataUsr?.hasLegacyPlan;
      this.showUses = dataUsr?.hasUsesPlan;
    });

    this.sortReports();
    this.checkIfParentReportsArePurchased();


    // console.log('-------------------------------------')
    // console.log('partialReport', this.partialReport)
    // console.log('projectData', this.projectData)
    // console.log('testtakersList', this.testtakerList)
    // console.log('testAndReportList', this.testAndReportList)


  }

  private checkIfParentReportsArePurchased() {
    const purchasedParentReportsIDs: number[] = [];
    this.testAndReportList.forEach(test => {
      test.reports.forEach(report => {
        if (report.parentReport_id === null) {
          if (this.checkReportStatus(report) == this.reportStatus.complete)
            purchasedParentReportsIDs.push(report.id);
        }
      });
    });

    for (const id of purchasedParentReportsIDs) {
      this.testAndReportList.forEach(test => {
        test.reports.forEach(report => {
          if (report.parentReport_id === id)
            report['selectable'] = true;
        });
      });
    }
  }

  protected showAppendicesMessage(): boolean {
    if (this.partialReport) return false;

    for (const test of this.testAndReportList) {
      for (const rep of test.reports) {
        const found = (rep.questionnaires.length > 0 && rep.parentReport_id !== null);
        if (found) return true;
      }
    }

    return false;
  }

  private sortReports() {
    for (let i = 0; i < this.testAndReportList.length; i++) {
      const sortedReports = [];
      const test = this.testAndReportList[i];
      for (const report of test.reports) {
        if (!report.parentReport_id) {
          sortedReports.push(report); // push parent report
          const parentReportId = report.id;
          const appendices = test.reports.filter(rep => rep.parentReport_id === parentReportId);
          sortedReports.push(...appendices);
        }
      }
      this.testAndReportList[i].reports = [...sortedReports];
    }
  }

  ngAfterViewChecked(): void {

    let dropdownDisableStatus: any = undefined;

    try {
      dropdownDisableStatus = this.selectorReport['disabled'];
    } catch {
      // Do nothing
    }

    if(this.purchasedReport != undefined || dropdownDisableStatus == false){
      this.mbtiMsg = this.translate.instant('REPORTS.MBTI-ADVICE-1');
    } else if (dropdownDisableStatus == true) {
      this.mbtiMsg = this.translate.instant('REPORTS.MBTI-ADVICE-2');
    } else {
      this.mbtiMsg = '';
    }

    this.cdRef.detectChanges();
  }

  /**
   * Method called when checkbox report selection changes
   * Saves the selected report list and updates GUI
   */
  saveReportSelection(){
    this.psyuserCanPurchaseSelection = false;
    this.allSelectedReportsAlreadyPurchased = false;
    this.reportsToPurchase = [];

    //Get user's report choices from checkbox
    this.reportList?.forEach(test => {
      test.reports.forEach(report => {
        if(report.reportSelected){
          this.reportsToPurchase.push(report);
        }
      });
    });

    this.testAndReportList?.forEach(test => {
      test.reports.forEach(report => {
        if(report.reportSelected){
          this.reportsToPurchase.push(report);
        }
      });
    });

    // Remove duplicates
    this.reportsToPurchase =[...new Set(this.reportsToPurchase)];

    //Recalculates price for user's report choices
    this.calculateSelectionPrice()

    //Check if psyuser can afford the selection to habilitate purchase button
    if(this.reportsToPurchase.length != 0){
      this.psyuserCanPurchaseSelection = true

      //If psyuser can purchase, and total cost is 0, all reports were purchased
      if(this.totalSelectionCredits == 0 && this.totalSelectionPlanUses == 0){

        //Change button text for Download instead of purchasing
        this.allSelectedReportsAlreadyPurchased = true
      }
    }
  }

  /**
   * Calculate total price in credits or plan uses for user's selection
   */
  calculateSelectionPrice(){
    this.totalSelectionCredits = 0;
    this.totalSelectionPlanUses = 0;

    //Calculate total report credits price for selection
    this.reportsToPurchase.forEach(report => {

      const numberOfReportServed = report.questionnaires.filter(x => !x.reportingResult.served && !x.reportingResult.url).length;

      //Update total price in credits accordingly to the total testtakers pending for purchase it's report
      this.totalSelectionCredits = this.totalSelectionCredits + (numberOfReportServed * report.credits);

      //Update total plan uses needed counter
      this.totalSelectionPlanUses = this.totalSelectionPlanUses + numberOfReportServed;
    });
  }


  /**
   * Method called when the user press Purchase button.
   * It purchases the selected report/s
   * Also closes the popup on termination to show the toast result
   * If error occurs, it shows the message try again...
   */
  async purchaseSelection() {
    let servedQuestionnaireReports = []
    let notServedQuestionnaireReports = []
    this.purchasingSpinner = true;
    this.reportsToPurchase.forEach(_report => {
      // Served reports
      servedQuestionnaireReports = servedQuestionnaireReports.concat(this.reportingSrv.getReportQuestionnaireServed(_report))
      // Not served reports
      notServedQuestionnaireReports = notServedQuestionnaireReports.concat(this.reportingSrv.getReportQuestionnaireNotServed(_report))
    })

    // Check if servedQuestionnaireReports has a new selectedProfile
    const requestedReportsWithProfile = servedQuestionnaireReports.filter(x => x.selectedField);
    let requestProfileChangeSuccess = false;
    if(requestedReportsWithProfile.length > 0) {
      // Call gate to generate the new data
      for (let i = 0; i < requestedReportsWithProfile.length; i++) {

        const selectedReport = requestedReportsWithProfile[i];

        try {
          let result = await this.projectsApi.getRequestProfileChangeForMBTI(selectedReport.selectedField, selectedReport.reportingResult.reportName, selectedReport.questionnaireCode);

          if(!result['statusOk']) {
            throw new Error();
          }

          requestProfileChangeSuccess = true;

        } catch (ex) {
          this.showToastError(this.translate.instant('REPORTS.MBTI-FAIL'));
          console.error(ex);
          this.purchasingSpinner = false;
          return;
        }
      }

      // Remove the selected profile report of served reports
      servedQuestionnaireReports = servedQuestionnaireReports.filter(x => !x.selectedField);

      // Add the selected profile report as not served report
      notServedQuestionnaireReports = notServedQuestionnaireReports.concat(requestedReportsWithProfile);
    }


    /**
     * to tell the modal whether to close or not. if the user wants to download and request in the same action,
     * the modal should not be closed, as messages may appear.
     */
    if(notServedQuestionnaireReports.length > 0) {
      if(this.onlyNewReports){ // ONLY NEW ONES
        this.continuePurchaseReports(notServedQuestionnaireReports,[], true);
      }else{ // ALL IN ONE DOWNLOAD
        this.continuePurchaseReports(notServedQuestionnaireReports,servedQuestionnaireReports, true);
      }
    }else if(!this.onlyNewReports && servedQuestionnaireReports.length > 0){
      this.continueDownload(servedQuestionnaireReports, true);  // NOTHING NEW, DOWNLOAD IT DIRECTLY
    }else{
      this.showToastError(this.translate.instant('REPORTS.NOTHIG-DOWNLOAD')); // NOTHING TO DOWNLOAD OR NOTHING NEW TO DOWNLOAD
      this.purchasingSpinner = false;
    }
  }

  showToastError(text: string): void {
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');
    this.messageSrv.add({severity: 'error', detail: text, summary: btnText ,key: 'toast-error-dialog', life: 5000});
  }

  showToastWarning(text: string): void {
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');
    this.messageSrv.add({severity: 'warning', detail: text, summary: btnText ,key: 'toast-error-dialog', life: 5000});
  }

  closeToastError(): void {
    this.messageSrv.clear('toast-error-dialog');
  }

  closeConfirm() {
    this.messageSrv.clear('toast-confirm-dialog');
  }

  checkIfTestIsRequested(test): boolean {
    return this.reportingSrv.checkIfTestIsRequested(test);
  }

  checkReportStatus(report): ReportStatus {
    return this.reportingSrv.checkReportStatus(report);
  }

  checkDiscontinuedReport(report): Boolean {
    // Get reports discontinued with data
    const previousData = report.questionnaires.some(x => x.reportingResult.served && x.reportingResult.url);

    if(report.discontinued && !previousData) {
      return true;
    }
    return false;
  }

  getReportsCompleteNumber(report): string {
    const served = report.questionnaires.filter(x => x.reportingResult.served && x.reportingResult.url).length;
    const count = report.questionnaires.length;

    return `${served}\\${count}`
  }

  checkAllDisposable(){
    let showAllDisposable = false;
    if(this.testtakerList != undefined){
      showAllDisposable = this.testtakerList.length > 1;
      if( showAllDisposable){
        let partialCount = 0;
        let completeCount = 0;
        let newCount = 0;
        let totalCount = 0;
        this.testAndReportList.forEach(_test => {
          if(!this.checkIfTestIsRequested(_test)){
            _test.reports.forEach(_report => {
              if(_report.questionnaires.length > 0 && !this.checkDiscontinuedReport(_report)){
                switch(this.checkReportStatus(_report)){
                  case ReportStatus.complete:
                    completeCount++;
                    totalCount++;
                    break;
                  case ReportStatus.partial:
                    partialCount++;
                    totalCount++;
                    break;
                  case ReportStatus.none:
                    newCount++;
                    totalCount++;
                    break;
                }
              }
            })
          }
        });
        if((completeCount > 0 && completeCount == totalCount) || (newCount > 0 && newCount == totalCount) || totalCount == 0 ){ // HIDE WHEN ALL THE REPORTS ARE COMPLETE OR NEW
          showAllDisposable = false;
        }
      }
    }
    if(!showAllDisposable) this.onlyNewReports = false;
    return showAllDisposable;
  }
  clearPreviousSelected(){
    this.reportList.forEach(_test => {
      _test.reports.forEach(_report => {
        _report.reportSelected = false
      });
    });
    this.psyuserCanPurchaseSelection = false
    this.allSelectedReportsAlreadyPurchased = false
    this.purchasingSpinner = false;
    this.reportsToPurchase = [];
  }

  async continuePurchaseReports(questionnaireReports, servedQuestionnaireReports, closeModal: boolean) {

    this.changeReportSelectedStatus(questionnaireReports, QuestionnaireCodeStatus.REPORT_ONGOING);

    //Activate Spinner
    this.purchasingSpinner = true
    // Check if psyuser have credits and reserve them
    //Requesting all reports to be procesed
    if(this.onlyNewReports){
      for (let _test of this.reportsToPurchase){
        _test.questionnaires = _test.questionnaires.filter(x => !x.reportingResult.served && !x.reportingResult.url);
      }
    }

    this.subs.sink = this.projectsApi.reserveCreditsOrUseToPurchase(this.reportsToPurchase).subscribe({
      next: _res => {
        // If the response is not ok
        if(!_res || !_res.statusOk){
          this.showToastError(this.translate.instant('PROJECTS.ERROR_REPORT'));
        // If the response is numeric
        }else {
          //Hide partial reporting
          this.isVisiblePartialReporting();

          if (Number(_res.code) === Codes.Plans.NOT_ENOUGH_BALANCE){
            let messageToShow = this.translate.instant("PROJECTS.NOT_ENOUGH_BALANCE");
            messageToShow = messageToShow.replace('{generated}', String(_res.message.succesfullyPurchased));
            messageToShow = messageToShow.replace('{total}', String(_res.message.totalPurchased));
            // Close reporting dialog
            this.showSuccessMessageAndClose(closeModal, messageToShow);
          } else {
            // Close reporting dialog with general success
            this.showSuccessMessageAndClose(closeModal);
          }
        }
      },
      error: _err => {
        //Clear selected checkboxs
        this.clearPreviousSelected();

        let translated=this.errorsSrv.getShowableMessageFromHttpCode(
          _err.error.code,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            logErrorOnConsole:true,
            fullErrorToLog:_err,
            defaultMsgKey:"PROJECTS.ERROR_REPORT"
          }
        )

        let messageToShow=translated.translatedMessage;
        this.showToastError(messageToShow);


        // Return questionnaires to scored status
        this.changeReportSelectedStatus(questionnaireReports, QuestionnaireCodeStatus.SCORED);

        //We do not close the window, neither update user credits, to allow
        //him to try again without any trouble. This is becouse there are
        //times where some reports are generated, and other not, and the total
        //price shown differ from the real one, only the problematic ones are
        //purchased this time, and not all the reports.
      },
      complete: () => {
        // Hide confirm toast
        this.closeConfirm()

        //Clear variables before closing the popup
        this.clearPreviousSelected();

        //Updates psyuser shown budget of credits
        this.authSrv.updatePsyuserCredits();

        //Stop spinner
        this.purchasingSpinner = false;

        // Trigger get project status with changes
        this.projectsSrv.getProjectStatusAndEmitChanges(this.projectData.id);
      }
    });
  }

  async continueDownload(questionnaires, closeModal: boolean) {
    if(!this.projectData.testtakers && this.testtakerList) this.projectData.testtakers = this.testtakerList.map(z => z.testaker);
    this.reportingSrv.downloadServedReports(this.projectData, questionnaires).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE) {
        // Messages
        let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          res,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
          }
        )

        this.toastSrv.showToastSucess(messageToShow.translatedMessage);

        // Hide confirm toast
        this.closeConfirm()

        // Clear variables before closing the popup
        this.clearPreviousSelected();

        // Close reporting dialog
        if(closeModal){
          this.reportPurchased.emit(true);
        }
      }
    }).catch(err => {
      this.showToastError(this.translate.instant('PROJECTS.ERROR_REPORT'));
      console.error(err)
    }).finally(()=> {
      // Hide confirm toast
      this.closeConfirm()

      //Clear variables before closing the popup
      this.clearPreviousSelected();

      //Stop spinner
      this.purchasingSpinner = false;
    });
  }

  showSuccessMessageAndClose(closeModal: boolean, messageToShow?: string) {
    if(messageToShow){
      this.showToastWarning(messageToShow);
      setTimeout(() => {
        // To close de report selector dialog
        if (closeModal) {
          this.reportPurchased.emit(true);
        }
      }, 2500);
    } else{
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.SUCCESS_REPORT_REQUEST'));
      if (closeModal) {
        this.reportPurchased.emit(true);
      }
    }
  }

  changeReportSelectedStatus(questionnaires, expectedStatus){
    // Set not served questionnaires to requested status
    let notServedIds = questionnaires.map(x => x.questionnaireCode)

    this.projectData.questionnaires?.forEach(_questionnaire => {
      if(notServedIds.includes(_questionnaire.questionnaireCode)){
        _questionnaire.statusCode = expectedStatus;
      }
    });

    this.testAndReportList.forEach(_test => {
      _test.reports.forEach(_report => {
        _report.questionnaires.forEach(_questionnaire => {
          if(notServedIds.includes(_questionnaire.questionnaireCode)){
            _questionnaire.statusCode = expectedStatus;
          }
        })
      })
    });

    this.requestedReports.emit(notServedIds);

    this.projectData.status = ProjectStatusString.REPORT_ONGOING;
  }

  isVisiblePartialReporting(){
    this.visiblePartialReporting.emit(false);
  }

  loadReportDropdown(report) {
    this.catalogApi.getTestById(report.test_id).subscribe(res => {
      const scoringReportingField = res.data.scoringReportingData.formItems.find(x => x.name == report.extraInfo.reportField);
      report.options = scoringReportingField.options;
    });
  }

  showSelectedSubReport(report){

    let ttId = report.questionnaires[0].testtaker_id;

    if(report.questionnaires.some(x => x.reportingResult.selectedField) && report.questionnaires.filter(x => x.testtaker_id == ttId).length == report.questionnaires.length) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
