import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private selectedHelp = new BehaviorSubject<any>(null);
  sharedSelectedHelp = this.selectedHelp.asObservable();

  constructor() { }

  setSelectedHelp(item: any): void {
    this.selectedHelp.next(item);
  }

  cleanLazyAndFormatLoadEventObj(item: any) {
    for (const key in item) {

      // Check if atribute is a object and parse to json
      try {
        if(this.isObject(item[key])) {
          item[key] = JSON.stringify(item[key]);
        }
      } catch {}

      // Remove undefined or null properties
      if (item[key] === null || item[key] === undefined) {
        delete item[key];
      }
    }
  }

  isObject (item) {
    return item != null && item.constructor === Object;
  }
}
