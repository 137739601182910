import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PeopleService } from 'src/app/modules/people/services/people.service';
import { SubSink } from 'subsink';
import { PsyUserRoles } from '@giuntipsy/utils/lib';

@Component({
  selector: 'app-detail-profile',
  templateUrl: './detail-profile.component.html',
  styleUrls: ['./detail-profile.component.scss']
})
export class DetailProfileComponent implements OnInit, OnDestroy {

  item: any;

  subs = new SubSink();

  readonly psyUserRoles = PsyUserRoles;

  constructor(
    private authSrv: AuthenticationService,
    private peopleSrv: PeopleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res => {
      this.item = res;
    });
  }

  goToAdminAccounts(): void {
    this.router.navigate(['admin']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
