<app-header></app-header>

<div class="container p-d-flex p-flex-column" [ngClass]="{'white-background': !isAuthenticated()}">
    <div class="content main-content" [ngClass]="{'white-background': !isAuthenticated()}">
        <router-outlet></router-outlet>
    </div>
    <app-help-shortcut></app-help-shortcut>
    <app-footer class="p-mt-auto"></app-footer>
</div>

<!--TOAST ELEMENTS-->
<app-toast-content></app-toast-content>
