import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectsApi } from '../../api/projects.api';
import { ProjectsService } from '../../services/projects.service';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { SubSink } from 'subsink';
import { UserPreferences } from 'src/app/shared/models/user-preferences-model';
import { ScoringService } from '../../services/scoring.service';
import { ProjectType } from 'src/app/shared/enums/project-type.enum';
import { ScoringPsyuserFormularyTemplateComponent } from 'src/app/shared/components/modal-templates/scoring-psyquestions-template/scoring-psyquestions-template.component';
import { ReportingService } from 'src/app/modules/projects/services/reporting.service';
import { ReportSelectorTemplateComponent } from 'src/app/shared/components/modal-templates/report-selector-template/report-selector-template.component';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { NonValidScoringTemplateComponent } from 'src/app/shared/components/modal-templates/non-valid-scoring-template/non-valid-scoring-template.component';
import { ChartComponentComponent } from './components/chart-component/chart-component.component';
import { ProjectDataComponentComponent } from './components/project-data-component/project-data-component.component';
import { EntryType } from 'src/app/shared/enums/entry-type.enum';
import { TemplateProjectUserComponent } from 'src/app/shared/components/row-card/templates/template-project-user/template-project-user.component';

enum eventStatus {
  blocked =  0,//The edresee has not received the transactional email since the address is in the blacklist
  hardBounces = 1, //It has been impossible to deliver the transactional email due to a PERMANENT problem (email address wrongly written or non existant)
  softBounces = 2, //It has been impossible to deliver the transactional email due to a TEMPORAL problem (the server is temporaly not available or the inbox is full)
  requests = 3, // The email has been sent to the addressee
  delivered = 4, //The addressee has received the email
  opened = 5,//The addressee has opened the email
  clicks = 6 //The addressee has clicked in a link of the email
}
@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.scss'],
  providers: [ToastService]
})

export class DetailProjectComponent implements OnInit, OnDestroy {

  @ViewChild('scoringPopupTemplate') scoringPopupRefPopup: ScoringPsyuserFormularyTemplateComponent;
  @ViewChild('reportingPopupTemplate') reportingPopupRefPopup: ReportSelectorTemplateComponent;
  @ViewChild('nonValidPopupTemplate') scoredNonValidRefPopup: NonValidScoringTemplateComponent;
  @ViewChild(ChartComponentComponent) private chartComponent!: ChartComponentComponent;

  subs = new SubSink();
  confirmSubscription: Subscription;

  // Enums
  ProjectStatusString = ProjectStatusString;
  projectEntryType = EntryType;
  subProjectType = ProjectType;

  // Project Object
  project: any;

  // Project Events
  loaded = false;
  firstCheck = false;
  checkStatus = true;

  // Change name pop-up properties
  displayChangeName = false;
  prevName = '';

  // Project Actions
  partialScoringCount : any[] = [];
  visiblePartialScoring: boolean = false;
  partialReportingCount : any[] = [];
  NonValidScoringCount: any[] = [];
  visiblePartialReporting: boolean = false;

  // Scoring pop-up properties
  displayScoringPopup = false;
  needShowScoringPopup: boolean;
  scoreDirectlyFromProjectDetailView: boolean;
  projectTestSharedQuestions;
  loadingPartSco = false;
  visibleBlindAdministration: boolean = false;
  blindAdministrationCount: any[] = [];

  // Non valid scoring properties
  displayNonValidScoringPopup = false;
  reasonNonValid: any;
  onlyValidReportList: any[] = [];
  loadingScoredNonValid: boolean = false;

  // Partial reporting pop-up properties
  displayAnotherReportPopup: boolean = false;
  loadingPartRep = false;
  projectTestReportList = [];
  existServedReport = false;
  existNotServedReport = false;

  // Emails
  displayCustomEmail: boolean = false;
  testtakersToSend_ids = [];
  testtakerToSend_id: number;
  _emailContent: string;
  loadingEmailData: boolean = false;
  communicationDisconnected: boolean = false;
  statusInterval: any;
  unfinishedTestUsers: any;
  displaySelectUsers: boolean = false;
  sendFromList: boolean = false;
  firstIterationStatusEmail = false;

  // Test List
  testStatus = [];
  testStatusChunk = []; // Paginated tests
  rowTests = 10;

  // Users lists
  userStatus = [];
  userStatusChunk = [];
  originalUserStatus = [];

  // UI
  userPreferences: UserPreferences;
  protected numberOfTests: number = 0;
  protected numberOfPeople: number = 0;

  constructor(
    private projectsSrv: ProjectsService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private toastConfirmSrv: ToastConfirmService,
    private projectsApi: ProjectsApi,
    private router: Router,
    private scoringSrv: ScoringService,
    private reportingSrv: ReportingService,
    private errorSrv:ErrorsService
  ) { }

  //#region ----- INIT FLOW -----
  ngOnInit(): void {
    // Set project to display details
    this.initProject();

    //Perform the update, then restore the flag to false.
    this.subs.sink = this.projectsSrv.sharedRequestUpdate.subscribe(data => {
      if(data ===  true){
        this.initProject();
      }
    });

    // Update graphs in change of language
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.chartComponent?.ngOnInit();
    });

    // Set label and pagination user
    this.subs.sink = this.projectsSrv.sharedActiveIndexAndPagDetails.subscribe(data => {
      this.userPreferences = data;
      this.rowTests = this.userPreferences.rowsToDisplay;
    });

    //Get project status at startup
    this.retrieveEmailStatus();

    // Check project Status
    this.statusInterval = setInterval(()=>{
      if(this.checkStatus &&
        (this.userStatus.filter((x:any) => x.emailStatus == eventStatus.hardBounces
                                          || x.emailStatus == eventStatus.softBounces
                                          || x.emailStatus == eventStatus.requests
                                          || x.emailStatus == eventStatus.delivered)[0]
                                          || !this.firstCheck)
        && this.project.status !== ProjectStatusString.COMPILED && this.project.status !== ProjectStatusString.REPORT_SERVED){
        this.retrieveEmailStatus();
      }
    }, 60000)

    // Observer for notifications actions
    this.subs.sink = this.projectsSrv.sharedupdatedStatus.subscribe((_notification: any)=> {
      if(_notification){
          this.subs.sink = this.projectsSrv.sharedProject.subscribe(projectData => {
            if(projectData && projectData.id === _notification?.project_id) {
              this.changeQuestionnaireByIncomingStatus(_notification.questionnaireCode, _notification.statusCode, _notification.questionnaire?.reportingResults);
            }
        });
      }
    });
  }

  initProject() {
    this.subs.sink = this.projectsSrv.sharedProject.subscribe(projectData => {
      this.loaded = false;
      //getProject sólo tiene sentido si no es un proyecto recién creado. De serlo, tendrá id null
      if(projectData.id){
        this.subs.sink = this.projectsApi.getProject(projectData.id).subscribe(
          async res => {
            this.project = res.data;

            // format project
            this.projectsSrv.formatProject(this.project);

            // check if exist at least one testaker with email not sent
            this.project.sent = this.project.projectHasTestTakers.filter(x => !x.emailSent).length > 0 ? false : true;

            // Set project type
            this.projectsSrv.setProjectType(this.project, [])

            this.setProjectSequence();
            this.generateTestStatus();
            this.generateUserStatus();
            await this.generateReportStructure();

            this.initProjectActions();
            this.loadingScoredNonValid = true;
          },
          error => {
            this.toastSrv.closeToastError();
            console.error(error)
            this.toastSrv.showToastError(error.error.message)
        });

        this.getNumberOfTests(projectData.id);
        this.getNumberOfPeople(projectData.id);
      }
    });
  }

  /**
   * Read all questionnaires to enable/disable action buttons for project
   */
  initProjectActions() {
    this.visiblePartialScoring = false;
    this.visibleBlindAdministration = false;
    this.partialReportingCount = [];

    for (let test of this.testStatus){
      for (let questionnaire of test.tests){

        // Enable partial scoring button
        if (questionnaire.statusCode == QuestionnaireCodeStatus.COMPILED
          || questionnaire.statusCode == QuestionnaireCodeStatus.READY_TO_SCORE){
          this.visiblePartialScoring = true;
          this.partialScoringCount.push(questionnaire)
        }

        // Enable blind administration button
        if([this.projectEntryType.Blind,
            this.projectEntryType.Remote,
            this.projectEntryType.OnSite].includes(this.project.entryType_id)
            && (questionnaire.statusCode === QuestionnaireCodeStatus.ASSIGNED
              || questionnaire.statusCode === QuestionnaireCodeStatus.FILLING)){
          this.visibleBlindAdministration = true;
        }

        // Count partial report
        if (questionnaire.statusCode === QuestionnaireCodeStatus.SCORED
          || questionnaire.statusCode === QuestionnaireCodeStatus.REPORT_SERVED
          || questionnaire.statusCode === QuestionnaireCodeStatus.SCORED_NON_VALID){
         this.partialReportingCount.push(questionnaire);
        }
      }
    }

    // Enable partial reporting button
    if (this.partialReportingCount.length > 0){
      this.visiblePartialReporting = true
    }

    // Count non valid
    this.project.questionnaires.forEach(x => {
      if (x.status_name === 'non-valid'){
        this.NonValidScoringCount.push(x)
      }
    });
  }
  //#endregion

  //#region ----- UI -----
  protected getNumberOfTests(projectId: number) {
    this.projectsApi.getNumberOfTests(projectId).subscribe({
      next: ({data}) => {
        this.numberOfTests = data;
      },
      error: ({ error }) => {
        this.toastSrv.closeToastError();
        this.toastSrv.showToastError(error.message);
      }
    });
  }

  protected getNumberOfPeople(projectId: number) {
    this.projectsApi.getNumberOfPeople(projectId).subscribe({
      next: ({data}) => {
        this.numberOfPeople = data;
      },
      error: ({ error }) => {
        this.toastSrv.closeToastError();
        this.toastSrv.showToastError(error.message);
      }
    });
  }

  storeActiveIndex(): void{
    this.projectsSrv.setActiveIndexAndPagDetails(this.userPreferences);
  }

  changeTab() {
    this.storeActiveIndex();
  }

  /**
   * Set label to know if the project is created with a sequencial order or not
   */
  async setProjectSequence() {
    if (this.project.questionnaires.filter(x => x.orderInProject == null).length > 0){
      this.project.sequence = false;
    } else {
      this.project.sequence = true;
    }
  }
  //#endregion

  //#region ----- USERS -----
  /**
   * Format all users to be shown
   */
  generateUserStatus(): void {
    let aux = [];
    this.project.projectHasTestTakers.forEach(x => {

      const currentStatus = this.projectsSrv.getProjectStatusStringByCode(this.project.statusCode);

      const testStatusByUser = this.project.questionnaires.filter(z => z.testtaker_id === x.testaker.id);

      testStatusByUser.forEach((x, i) => {
        testStatusByUser[i].status_name = this.projectsSrv.getProjectStatusStringByCode(x.statusCode);
        // For search status of test
        testStatusByUser[i].statusLabel = this.translate.instant(this.projectsSrv.getProjectStatusStringKey(testStatusByUser[i].status_name));
        if (x.statusCode === QuestionnaireCodeStatus.SCORED_NON_VALID){
          this.projectsApi.getScoringParamsByQCode(x.questionnaireCode).subscribe((_result)=>{

            let reason = _result.data.scoringParams.reasonNonValid
            this.reasonNonValid = reason ? reason : this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
          })
        }
      });

      aux.push({
        id: this.project.id,
        testtaker_id: x.testaker.id,
        email: x.testaker.email,
        testtakerFriendlyId: x.testaker.friendlyId,
        name: x.testaker.name,
        surname: x.testaker.surname,
        status: currentStatus,
        tests: testStatusByUser,
        projectType: this.project.projectType,
        projectName: this.project.code,
        code: this.project.code,
        projectSupply: this.project.entryType_id,
        emailStatus: x.emailSent ? 4 : 1
      });

      this.userStatus = aux;
    });


    // Preserve the oriringal array list
    this.originalUserStatus = this.userStatus;

    // Paginate Users
    this.userStatusChunk = this.userStatus.slice(0, this.rowTests);
  }

   /**
   * Show confirmation to delete a user
   * @param $event
   */
   confirmDeleteUser($event): void {

    if (this.userStatus.length === 1) {
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE-LAST-USER'));
    } else {
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE-USER'));
    }

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.deleteUserFromProject($event);
        this.confirmSubscription.unsubscribe();
      }
      if (res === false){
        this.confirmSubscription.unsubscribe();
      }
    });
  }

  /**
   * Confirm delete user of project
   * @param item
   */
  deleteUserFromProject(item): void {
    this.subs.sink = this.projectsApi.deleteUserProject(item.id, item.testtaker_id).subscribe(res => {
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-DELETE-USER-OK'));
      this.userStatus = this.userStatus.filter(x => x.testtaker_id != item.testtaker_id);
      this.userStatusChunk = this.userStatus.slice(0, this.rowTests);
      this.project.projectHasTestTakers = this.project.projectHasTestTakers.filter(x => x.testtaker_id !== item.testtaker_id);
      this.project.questionnaires = this.project.questionnaires.filter(x => x.testtaker_id != item.testtaker_id);
      this.getNumberOfPeople(item.id);
      this.chartComponent.ngOnInit();
    },
    error => {
      this.toastSrv.showToastError(error);
    });
  }

  applyFilter($event): void {
    this.userStatus = $event;
    this.userStatusChunk = this.userStatus.slice(0, this.rowTests);
  }

  resetFilter($event): void {
    this.userStatus = this.originalUserStatus;
    this.userStatusChunk = this.userStatus.slice(0, this.rowTests);
  }

  //#endregion

  //#region ----- TESTS -----
  /**
   * Format all test to be shown
   */
  generateTestStatus(): void {
    let aux = [];
    this.testStatus = [];
    this.project.questionnaires.forEach(x => {

      const questionnairesByTest = this.project.questionnaires.filter(z => z.test_id === x.test_id);

      const projectTesttakers = this.project.projectHasTestTakers.map(z => z.testaker);

      aux.push({
        id: this.project.id,
        testName: x.test.title,
        createdAt: this.project.createdAt,
        testId: x.test_id,
        status: x.statusCode,
        tests: questionnairesByTest,
        orderInProject: x.orderInProject,
        projectType: this.project.projectType,
        projectName: this.project.code,
        code: this.project.code,
        testtakers: projectTesttakers,
        parentTestName: x.test.parentTest.test,
        locale: x.test.locale,
        external: x.test.external
      });

    });

    // Remove duplicates
    aux.forEach(x => {
      if (this.testStatus.filter(z => z.testId === x.testId).length > 0){
        const currentTestStatus = this.testStatus.filter(z => z.testId === x.testId)[0];
        if (!Number.isInteger(currentTestStatus.status)) {
          currentTestStatus.status = this.projectsSrv.getProjectStatusIntByString(currentTestStatus.status);
        }
        if (x.status < currentTestStatus.status || x.status === QuestionnaireCodeStatus.FILLING) {
          this.testStatus.filter(z => z.testId === x.testId)[0].status = x.status;
        }
      } else {
        this.testStatus.push(x);
      }
    });

    // Assign the status
    this.testStatus.forEach(x => {
      if (Number.isInteger(x.status)) {
        x.status = this.projectsSrv.getProjectStatusStringByCode(x.status);
      }
    });

    this.testStatus.sort((a, b) => (a.orderInProject > b.orderInProject)
    ? 1 : ((b.orderInProject > a.orderInProject) ? -1 : 0));

    // Paginate tests
    this.testStatusChunk = this.testStatus.slice(0, this.rowTests);
  }

  /**
   * Show confirmation to delete a test
   * @param $event
   */
  confirmDeleteTest($event): void {
    if (this.testStatus.length === 1) {
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE-LAST-TEST'));
    } else {
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE-TEST'));
    }

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.deleteTestFromProject($event);
        this.confirmSubscription.unsubscribe();
      }
      if (res === false){
        this.confirmSubscription.unsubscribe();
      }
    });
  }

  /**
   * Confirm delete test of project
   * @param item
   */
  deleteTestFromProject(item): void {
    this.subs.sink = this.projectsApi.deleteTestProject(item.id, item.testId).subscribe(res => {
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-DELETE-TEST-OK'));
      this.testStatus = this.testStatus.filter(x => x.testId !== item.testId);
      this.testStatusChunk = this.testStatus.slice(0, this.rowTests);
      this.project.questionnaires = this.project.questionnaires.filter(x => x.test_id !== item.testId);
      this.getNumberOfTests(item.id);
      this.chartComponent.ngOnInit();
    },
    error => {
      let messageToShow=this.errorSrv.getShowableMessageFromHttpCode(
        error.error.code,
        {
          customEnvironment:"PROJECTS.DELETE-TEST-ERRORS.HTTP-CODES",
        }
      )
      this.toastSrv.showToastError(messageToShow.translatedMessage);
    });
  }
  //#endregion

  //#region ----- PAGINATION -----
  /**
   * Change users and test data based in pagination event
   * @param event
   */
  paginateTestsAndUsers(event): any {
    this.rowTests = event.first + event.rows;
    if (this.rowTests > this.userStatus.length) {
      this.rowTests = this.userStatus.length;
    }
    this.userStatusChunk = this.userStatus.slice(event.first, this.rowTests);

    this.rowTests = event.first + event.rows;
    if (this.rowTests > this.testStatus.length) {
      this.rowTests = this.testStatus.length;
    }
    this.testStatusChunk = this.testStatus.slice(event.first, this.rowTests);

    this.userPreferences.rowsToDisplay = event.rows;
    this.projectsSrv.setActiveIndexAndPagDetails(this.userPreferences);
  }
  //#endregion

  //#region ----- DATA EVENTS -----
  /**
   * Trigger to reload data if user or test has been changed in a child component
   */
  itemStatusChanged(qCodes): void {
    // set items in scored status based in qCodes
    qCodes.forEach(x => {
      this.project.questionnaires.filter(z => z.questionnaireCode === x )[0].statusCode = QuestionnaireCodeStatus.SCORED;
    });

    this.projectsSrv.setCurrentProject(this.project);

    this.generateTestStatus();
    this.generateUserStatus();
    this.chartComponent.ngOnInit();
  }

  /**
   * Search a questionnaire and set the received status and/or the reporting results
   * @param qCode
   * @param status
   * @param reportingResults
   */
  changeQuestionnaireByIncomingStatus(qCode, status, reportingResults?){
    breakLoop: // Flag to break the loop
    for (const _user of this.userStatus) {
      for (const _userTest of _user.tests) {
          if (_userTest.questionnaireCode === qCode) {
            // Change attributes in user lists
            _userTest.statusCode = status;
            _userTest.status_name = this.projectsSrv.getProjectStatusStringByCode(_userTest.statusCode);
            _userTest.statusLabel = this.translate.instant('CUSTOM-LABEL.' + _userTest.status_name.toUpperCase());

            // Add reporting results to project
            if(reportingResults){
              this.addReportingResultsOfNotificationToQuestionnaire(_userTest, qCode, reportingResults)
            }

            // Change attributes in user tab
            let _userStatusChunk = this.userStatusChunk.find(_userStatus => _user.testtaker_id === _userStatus.testtaker_id);
            if(_userStatusChunk) {
              _userStatusChunk.hide = true;
              setTimeout( () => {
                _userStatusChunk.hide = false;
              }, 1);

              //Re-generate test tab
              this.generateTestStatus();

              //Re-generate chart component
              this.chartComponent.ngOnInit();

              //Re-generate actions
              this.initProjectActions();
            }

            break breakLoop; // Break loop
          }
      }
    }
  }

  /**
   * Add reporting results to questionnaire
   * @param userTest
   * @param qCode
   * @param reportingResults
   */
  addReportingResultsOfNotificationToQuestionnaire(userTest, qCode, reportingResults){
    userTest.reportingResult = reportingResults;
    for (let _test of this.testStatus){
      for (let _questionnaire of _test.tests){
        if(_questionnaire.questionnaireCode == qCode){
          _questionnaire.reportingResults = reportingResults
        }
      }
    }
  }

  //#endregion

  //#region ----- PROJECT ACTIONS -----
  /**
   * Go to create project view
   */
  addUsers(): void {
    // Set current project in local storage before go to edit view
    this.projectsSrv.setCurrentProject(this.project);

    this.projectsSrv.reloadFrom = {
      path: 'details',
      projectId: this.project.id
    }
    this.router.navigate(["projects", "create"]);
  }

  /**
   * Get excel of blind administration
   */
  getBlindAministrationData(){
    this.projectsSrv.generateBlindAdministrationExcel(this.project).then(res => {
      if(res){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-BLIND-OK'));
      } else {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-BLIND-NO'));
      }
    }).catch(err => {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-BLIND-NO'));
    })
  }

  getScoringExcel(){
    this.projectsSrv.downloadScoringTable(this.project).then(res => {
      if(res){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-SUCCESS')+this.project.code)
      } else {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
      }
    }).catch(err => {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
    })
  }

  protected confirmDeleteProject(): void {
    this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE'));

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.confirmSubscription.unsubscribe();

        this.projectsApi.deleteProject(this.project.id).subscribe(
          response => {
            this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-DELETE-OK'));
            this.router.navigate(['projects']);
          },
          error => {
            this.toastSrv.showToastError(error);
          });
      }
      if (res === false){
        this.confirmSubscription.unsubscribe();
      }
    });
  }

  //#endregion

  //#region ----- EMAILS -----
  retrieveEmailStatus() {
    // Only apply to projects with remote entry type
    if(this.project && this.project.entryType_id == this.projectEntryType.Remote){
      this.subs.sink = this.projectsApi.checkStatus(this.project.id).subscribe((data:any)=>{
        this.firstCheck = true;
        const status = data.data;
        status.forEach((_status:any)=>{
          let user = this.userStatus.find((x:any) => x.email.toLowerCase() === _status.email.toLowerCase())
          if(user)
            user['emailStatus'] = _status.status;
        });
        this.firstIterationStatusEmail = true;

        // If the project is created and the emails are just sended, refresh the page to show the project
        // in assigned way
        if(this.project.status === ProjectStatusString.CREATED && status.length > 0){
          setTimeout( () => { document.location.reload() }, 6000 );
        }
      },
      error => {
        if(error.status == 500)
          this.communicationDisconnected = true
      })
    }
  }

  searchUnfinishedTestUsers(){
    this.unfinishedTestUsers = this.userStatus.filter(x => [ProjectStatusString.CREATED, ProjectStatusString.ASSIGNED, "filling"].includes(x.status));
    this.unfinishedTestUsers.forEach(x => {x.selected = true});

    // If the project is in 'Created' status send emails to all users
    if(this.project.status === ProjectStatusString.CREATED){
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.SEND_EMAIL_TO_USERS_DIRECT'), 'warning');
      this.subs.sink = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
        if (res === true){
          this.sendFromList = true;
          this.sendMails(); // Send with force refresh ok
        }
      });
    }else{
      this.displaySelectUsers = true;
    }
  }

  /**
   * Send emails in broadcast mode or to a single testtaker. Confirm of a pop-up emails
   * @param id
   */
  sendMails(){
    if (this.sendFromList){
      for (let user of this.unfinishedTestUsers){
        if(user.selected){
          this.testtakersToSend_ids.push(user.testtaker_id)
        }
      }
    } else {
      this.testtakersToSend_ids.push(this.testtakerToSend_id)
    }
    this.loadingEmailData = true
    let body: any = {project_id: this.project.id, content: this._emailContent, language: this.translate.currentLang}
    if(this.testtakersToSend_ids){
      body['testtakers_ids'] = this.testtakersToSend_ids
    }

    this.projectsApi.sendMails(body).subscribe(()=>{
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.SUCCESS_EMAILS'))
      this.loadingEmailData = false;
      this.retrieveEmailStatus();
    },
    error => {
      console.error(error)
      this.loadingEmailData = false
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.ERROR_EMAILS'));
    });

    this.testtakersToSend_ids = [];
    this.unfinishedTestUsers = [];
    this.sendFromList = false;
  }

  //#endregion

  //#region ----- SCORING -----
  /**
   * Init scoring action
   */
  async manageScoringForTest(){
    try {
      const psyUserQuestionsForTest = await this.scoringSrv.checkPsyUserQuestions(this.partialScoringCount)

      if (psyUserQuestionsForTest.length === 0){
        this.partialScoring()
      } else {
        this.displayScoringPopup = true;
      }
      this.projectTestSharedQuestions = psyUserQuestionsForTest;
    } catch {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-SCORING-COMMUNICATION-ERROR'))
    }
  }

  /**
   * Show partial scoring pop-up
   * @returns
   */
  async partialScoring(){

    this.loadingPartSco = true;

    // Check consistency of questionnaires
    const consistencyResult = await this.projectsSrv.checkQuestionnairesConsistency(this.partialScoringCount, this);
    if(!consistencyResult)
      return;

    try {
      // Request scoring
      const scoringResult: any = await this.projectsApi.requestScoringServiceV2(this.partialScoringCount.map(_quest => _quest.questionnaireCode));

      // Set questionnaires as requested
      scoringResult.data.data.forEach(_questCode => {
        this.changeQuestionnaireByIncomingStatus(_questCode, QuestionnaireCodeStatus.SCORING_ONGOING);
      });

      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-SCORING-OK'));
      this.projectsSrv.setRequestUpdate(true);

    } catch (ex) {
      let errToShow=this.errorSrv.getShowableMessageFromHttpCode(
        ex.message,
        {
          logErrorOnConsole:true,
          defaultMsgKey:"PROJECTS.API-SCORING-NO",
          customEnvironment:"SCORING.HTTP-CODES"
        }
      )
      this.toastSrv.showToastError(errToShow.translatedMessage);
    } finally {
      this.loadingPartSco = false;
      this.initProjectActions();
    }
  }

  /**
  * Method to manage the psyuser shared questions popup on success scoring close event.
  */
  onClosePopupPsyuserQuestionsForScoring(){
    this.displayScoringPopup = false;
    this.projectsSrv.setRequestUpdate(true)
  }

  //#endregion

  //#region ----- REPORTING -----
  /**
   * Generate data to request report
   */
  generateReportStructure(){
    // Generate report status and structure
    this.projectTestReportList =  this.reportingSrv.getReportObjectByQuestionnaires([...this.partialReportingCount]);

    // To know if at least one report is served or not
    this.existServedReport = this.reportingSrv.checkIfAtLeastOneReportIsServed(this.projectTestReportList);

    this.existNotServedReport = this.reportingSrv.checkIfAtLeastOneReportIsNotServed(this.projectTestReportList);

    this.loaded = true;
  }

  /**
   * Manages the popup used to select a report to purchase
   * or download directly if already purchased.
   *
   * Configures the popup to get reports from a single testtaker
   */
  showAnotherReportMenuProject() {
    if (this.NonValidScoringCount.length === 0){
      this.showReportingPopUp();
    } else {
      // this.loadingScoredNonValid = true;
      this.displayNonValidScoringPopup = true
    }
  }

  showReportingPopUp(questList?){
    if(questList){
      this.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(questList);
    } else{
      this.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires([...this.partialReportingCount]);
    }

    this.displayAnotherReportPopup = true;
  }

  /**
  * Request to the server the selected report and download it
  * showing a message to inform about the result.
  * @param report
  */
  public async downloadPurchasedReports(questionnaires) {
    this.reportingSrv.downloadServedReports(this.project, questionnaires).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE ) {
        // Messages
      let messageToShow=this.errorSrv.getShowableMessageFromHttpCode(
        res,
        {
          useGenericEnvIfCustomFail:true,
          customEnvironment:"REPORTS.HTTP-CODES",
          defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
        }
      )

      this.toastSrv.showToastSucess(messageToShow.translatedMessage);
      }
    }).catch(err => {
      console.error(err)
      let errorMsgToShow=this.errorSrv.getShowableMessageFromHttpCode(
      err.error.code,
      {
        useGenericEnvIfCustomFail:true,
        customEnvironment:"REPORTS.HTTP-CODES",
        logErrorOnConsole:true,
        fullErrorToLog:err.error.code,
        defaultMsgKey:"REPORTS.HTTP-CODES.Plans.REQUEST_ERROR"
      }
    )

      this.toastSrv.showToastSucess(errorMsgToShow.translatedMessage);
    })

  }

  isVisiblePartialReporting($event) {
    this.visiblePartialReporting = $event;
  }

  /**
   * Change reporting status to requested (just in visual)
   * @param $event
   */
  changeTestStatusByReportingRequest($event) {

    this.testStatus.forEach(_test => {
      _test.tests.forEach(_itemTest => {
        if($event.includes(_itemTest.questionnaireCode)){
          _itemTest.statusCode = QuestionnaireCodeStatus.REPORT_ONGOING;
          _itemTest.status_name = ProjectStatusString.REPORT_ONGOING;
          _test.status = ProjectStatusString.REPORT_ONGOING;
        }
      })
    });

    this.testStatusChunk.forEach(_test => {
      _test.tests.forEach(_itemTest => {
        if($event.includes(_itemTest.questionnaireCode)){
          _itemTest.statusCode = QuestionnaireCodeStatus.REPORT_ONGOING;
          _itemTest.status_name = ProjectStatusString.REPORT_ONGOING;
          _test.status = ProjectStatusString.REPORT_ONGOING;;
        }
      })
    });

    this.userStatus.forEach(_test => {
      _test.tests.forEach(_itemTest => {
        if($event.includes(_itemTest.questionnaireCode)){
          _itemTest.statusCode = QuestionnaireCodeStatus.REPORT_ONGOING;
          _itemTest.status_name = ProjectStatusString.REPORT_ONGOING;;
        }
      })
    });

    this.userStatusChunk.forEach(_test => {
      _test.tests.forEach(_itemTest => {
        if($event.includes(_itemTest.questionnaireCode)){
          _itemTest.statusCode = QuestionnaireCodeStatus.REPORT_ONGOING;
        }
      })
    });
  }

  //#endregion

  //#region ----- SCORING NON VALID -----
  getAllReports(){
    this.displayNonValidScoringPopup = false;
    this.showReportingPopUp(this.project.questionnaires);
  }

  async getOnlyValidReports(){
    this.project.questionnaires.forEach(_quest => {
      if(_quest.statusCode != QuestionnaireCodeStatus.SCORED_NON_VALID){
        this.onlyValidReportList.push(_quest)
      }
    })
    this.displayNonValidScoringPopup = false;
    this.showReportingPopUp(this.onlyValidReportList);
  }
  //#endregion

  ngOnDestroy(): void {
    this.checkStatus = false;
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
    this.subs.unsubscribe();
  }
}
