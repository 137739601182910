import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CoreModule } from './core/core.module';
import { ProjectsModule } from './modules/projects/projects.module';
import { CatalogModule } from './modules/catalog/catalog.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { HelpModule } from './modules/help/help.module';
import { PeopleModule } from './modules/people/people.module';
import { ProfileModule } from './modules/profile/profile.module';
import { AdminModule } from './modules/admin/admin.module';

import { SharedElementsModule } from './shared/shared-elements.module';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';

import {GAnalyticsService} from './shared/services/ganalytics.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CatalogModule,
    DashboardModule,
    HelpModule,
    PeopleModule,
    ProfileModule,
    ProjectsModule,
    AdminModule,
    CoreModule,
    SharedElementsModule,
  ],
  providers: [
    GAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

// La traducción requiere exportar esta función
export function createTranslateLoader(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient, './assets/i18n/v1/', '.json');
}
