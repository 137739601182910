import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardApi {
    private url = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getPsyUserMaintenance(): Observable<any>{
        return this.http.get(this.url + 'psy/maintenance');
    }

}
