import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from './prime-ng/prime-ng.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ToastContentComponent } from './components/toast-content/toast-content.component';
import { UsersGroupsCirclesComponent } from './components/users-groups-circles/users-groups-circles.component';
import { RowCardComponent } from './components/row-card/row-card.component';
import { TemplateGroupSimpleComponent } from './components/row-card/templates/template-group-simple/template-group-simple.component';
import { TemplateUserSimpleComponent } from './components/row-card/templates/template-user-simple/template-user-simple.component';
import { FilterComponent } from './components/filter/filter.component';
import { TestCardComponent } from './components/test-card/test-card.component';
import { CustomLabelComponent } from './components/custom-label/custom-label.component';
import { TemplateTestComponent } from './components/row-card/templates/template-test/template-test.component';
import { TemplateProjectTestComponent } from './components/row-card/templates/template-project-test/template-project-test.component';
import { TemplateProjectUserComponent } from './components/row-card/templates/template-project-user/template-project-user.component';
import { EmptyTemplateComponent } from './components/empty-template/empty-template.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { HelpShortcutComponent } from './components/help-shortcut/help-shortcut.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CustomEmailComponent } from './components/custom-email/custom-email.component';
import { QuillModule } from 'ngx-quill';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ScoringPsyuserFormularyTemplateComponent } from './components/modal-templates/scoring-psyquestions-template/scoring-psyquestions-template.component';
import { ReportSelectorTemplateComponent } from './components/modal-templates/report-selector-template/report-selector-template.component';
import { PadNumberPipe } from './pipes/pad-number.pipe';
import { TemplateTestSimpleComponent } from './components/row-card/templates/template-test-simple/template-test-simple.component';
import { InputComponent } from './components/input/input.component';
import { PopupDetailComponent } from './components/popup-detail/popup-detail.component';
import { TableModule } from 'primeng/table';
import { HistoryComponent } from './components/history/history.component';
import { PlanDetailsComponent } from './components/plan-details/plan-details.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { NonValidScoringTemplateComponent } from './components/modal-templates/non-valid-scoring-template/non-valid-scoring-template.component';
import { ZendeskButtonComponent } from './components/zendesk-button/zendesk-button.component';
import { PlanCardComponent } from './components/plan-card/plan-card.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { RoleBasedTemplateComponent } from './components/modal-templates/role-based-template/role-based-template.component';
import { TesttakerGroupSelectorComponent } from './components/modal-templates/testtaker-group-selector/testtaker-group-selector.component';

const elements = [
  ReactiveFormsModule,
  RxReactiveFormsModule,
  TranslateModule,
  FormsModule,
  PrimeNgModule,
  TableModule,
  TranslateModule,
  RecaptchaModule,
  ClipboardModule,
  QuillModule.forRoot()
];

const components = [
  ToastContentComponent,
  UsersGroupsCirclesComponent,
  RowCardComponent,
  TemplateGroupSimpleComponent,
  TemplateUserSimpleComponent,
  TemplateTestComponent,
  TemplateProjectTestComponent,
  TemplateProjectUserComponent,
  FilterComponent,
  TestCardComponent,
  CustomLabelComponent,
  EmptyTemplateComponent,
  DashboardHeaderComponent,
  HelpShortcutComponent,
  NotFoundComponent,
  CustomEmailComponent,
  ScoringPsyuserFormularyTemplateComponent,
  ReportSelectorTemplateComponent,
  PadNumberPipe,
  TemplateTestSimpleComponent,
  InputComponent,
  PopupDetailComponent,
  HistoryComponent,
  PlanDetailsComponent,
  NonValidScoringTemplateComponent,
  ZendeskButtonComponent,
  PlanCardComponent,
  RoleBasedTemplateComponent,
  TesttakerGroupSelectorComponent
];

@NgModule({
  declarations: [components, TruncatePipe, NotAuthorizedComponent, TesttakerGroupSelectorComponent],
  imports: [
    CommonModule,
    elements
  ],
  providers:[
    MessageService
  ],
  exports: [
    elements, components
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedElementsModule { }
