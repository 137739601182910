/**
 * Enum to set the type of project
 * in-gr: Indicate the number of users
 * Ma-Pr-Re-Bl: Indicate the kind of administration: Manual, Presencial, Remote, Blind (Anonymous) *
 * Si-Mu: Indicate the number of tests; Si = 1 test, Mu > 1 test
 */
export enum ProjectType {
    // --- Individual Projects ---
    inMaSi, // Individual_Manual_Single
    inMaMu, // Individual_Manual_Multiple
    inPrSi, // Individual_Presencial_Single
    inPrMu, // Individual_Presencial_Multiple
    inReSi, // Individual_Remota_Single
    inReMu, // Individual_Remota_Multiple
    // --- Group Projects ---
    grReSi, // Grupo_Remoto_Single
    grReMu, // Grupo_Remoto_Multiple
    grMaSi, // Grupo_Manual_SingleTest
    grMaMu, // Grupo_Manual_MultipleTest
    // --- Blind Administration ---
    inBlSi, // Individual_Blind_Single
    inBlMu, // Individual_Blind_Multple
    grBlSi, // Grupo_Blind_Single
    grBlMu  // Grupo_Blind_Multiple
}
