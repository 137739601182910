<span [ngClass]="'regular-label'" *ngIf="inputForm.type == 'number' || inputForm.type == 'singleLine'">          
    <label for="{{inputForm.name}}" class="label"><span class="red_dot">{{inputForm.mandatory? '*' : ''}}</span>{{inputForm.label | titlecase}}
        <span *ngIf="inputForm.validation[0]?.min" class="sublabel">[Min. {{inputForm.validation[0]?.min}}]</span>
        <span *ngIf="inputForm.validation[0]?.max" class="sublabel"> [Max. {{inputForm.validation[0]?.max}}]</span>
    </label>

    <span *ngIf="myFormGroup.controls[inputForm.name].invalid && myFormGroup.controls[inputForm.name].touched" 
        [@inOutAnimation]
        class="error-tooltip">
        {{myFormGroup.controls[inputForm.name]['errorMessage']}}
        {{myFormGroup.controls[inputForm.name].errors.min?.min ? 'Min. ' + myFormGroup.controls[inputForm.name].errors.min?.min : null}}
        {{myFormGroup.controls[inputForm.name].errors.max?.max ? 'Max. ' + myFormGroup.controls[inputForm.name].errors.max?.max : null}}
    </span>

    <input *ngIf="inputForm.type === 'singleLine'" 
        id="{{inputForm.name}}" 
        type="text" 
        pInputText 
        class="custom-input-text" 
        formControlName="{{inputForm.name}}"
        [attr.disabled]="maskEditable? null : !maskEditable">
    <input *ngIf="inputForm.type === 'number'" 
        id="{{inputForm.name}}" 
        type="number" 
        pInputText 
        class="custom-input-text" 
        formControlName="{{inputForm.name}}"
        [min]="inputForm.validation[0]?.min ? inputForm.validation[0]?.min : ''"
        [max]="inputForm.validation[0]?.max ? inputForm.validation[0]?.max : ''"  
        [attr.disabled]="maskEditable? null : !maskEditable">        
</span>    

<span [ngClass]="'regular-label'" *ngIf="inputForm.type == 'select'">
    <label for="{{inputForm.name}}" class="label"><span class="red_dot">{{inputForm.mandatory? '*' : ''}}</span>{{inputForm.label | titlecase}}</label>

    <span 
        *ngIf="myFormGroup.controls[inputForm.name].invalid && myFormGroup.controls[inputForm.name].touched" 
        [@inOutAnimation] 
        class="error-tooltip">
        {{myFormGroup.controls[inputForm.name]['errorMessage']}}
    </span>
    
    <div *ngIf="inputForm.group && inputForm.dynamicOption; else nonGroup">            
        <p-dropdown #inpt 
            class="custom-dropdown" 
            formControlName="{{inputForm.name}}"
            filterBy="name" 
            [showClear]="false" 
            inputId="{{inputForm.name}}" 
            placeholder="&nbsp;"
            [options]="getDynamicOption(inputForm.dynamicOption)" 
            optionLabel="label"
            optionValue="value"
            optionDisabled="inactive"
            appendTo="{{displayInModal? 'body' : null}}"
            (onChange)="optionChanged(inputForm.dynamicOption)">
        </p-dropdown>    
    </div>
    <ng-template #nonGroup>
        <p-dropdown 
            [options]="inputForm.options" 
            formControlName="{{inputForm.name}}"
            class="custom-dropdown"
            placeholder="&nbsp;" 
            optionLabel="label"
            optionValue="value"
            [showClear]="false"        
            appendTo="{{displayInModal? 'body' : null}}"
            [disabled]="!maskEditable">
        </p-dropdown> 
    </ng-template>
</span>