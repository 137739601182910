<div class="custom-label {{labelType}}">
    <div class="custom-label-content">
        <div class="icon">
            <i class="pi pi-circle-on"></i>
        </div>
        <div class="p-d-flex p-ai-center">
            <span [ngClass]="{'loading': (labelType === ProjectStatusString.SCORING_ONGOING || labelType === ProjectStatusString.REPORT_ONGOING)}" class="custom-label-flag">
                {{'CUSTOM-LABEL.'+(labelType|uppercase)|translate}}
            </span>
        </div>
    </div>
    <div class="arrow-right"></div>
</div>
