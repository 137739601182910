import { group } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Paginator } from 'primeng/paginator';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PeopleApi } from '../../../api/people.api';
import { PeopleService } from '../../../services/people.service';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';
import { Sidebar } from 'primeng/sidebar';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [ToastService]
})
export class GroupsComponent implements OnInit, OnDestroy {

  @ViewChild('dataTable') dataTable: Table;

  public data = [];
  public dataCount: number = 0;
  public loaded = false;
  public selectedData = [];
  public showBottomMenu: boolean = false;
  public activeItem: any;
  public menuItems: MenuItem[];
  public showBlockUi = false;

  private confirmSubscription: Subscription;
  private subs = new SubSink();

  constructor(
    private peopleSrv: PeopleService,
    private peopleApi: PeopleApi,
    private toastSrv: ToastService,
    private toastConfirmSrv: ToastConfirmService,
    private router: Router,
    private translate: TranslateService,
    private projectsSrv: ProjectsService,
    private authSrv: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.initMenuItems();
  }

  /**
   * Declare right menu for table items
   */
  initMenuItems(){
    this.menuItems = [
      {
          label: 'Options',
          items: [
              {
                label: this.translate.instant("PEOPLE.EDIT"),
                icon: 'pi pi-pencil',
                command: () => {
                  this.peopleSrv.setSharedUser(this.activeItem);
                  this.peopleSrv.setSharedGroup(this.activeItem)
                  this.router.navigate(['people', 'editgroup'], {queryParams: {type: 'EDIT'}});
                }
              },
              {
                label: this.translate.instant("PEOPLE.DELETE"),
                icon: 'pi pi-trash',
                command: () => this.confirmDelete(this.activeItem)
              }
          ]
      }
    ]
  }

  /**
   * Get users list based in filters criteria
   * @param event
   */
  getDataLazy(event: LazyLoadEvent) {

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(_psyUser =>{
      if (_psyUser) {
        this.peopleApi.getTestTakerGroupsLazy(event).subscribe({
          next: _res => {

            this.data = _res.data.rows;
            this.dataCount = _res.data.count;

            this.setTestTakersName();
          },
          error: _err => {
            this.toastSrv.showToastError(this.translate.instant('TABLE.ERROR-API'))
          },
          complete: () => {
            this.loaded = true;
          }
        });
      };
    });
  }

  setTestTakersName(){
    this.data.forEach(_group => {
      if(_group.testtakersCount > 5) {
        for (let i = 5; i < _group.testtakersCount; i++) {
          _group.testtakersNames.push('');
        }
      }
    });
  }

  /**
   * Normalize filter object
   * @param event
   */
  applyDataTableFilter(event) {

    // Apply date range
    if(event.rangeDates && event.rangeDates.length > 0){
      this.dataTable.filters['startDate'] = event.rangeDates[0];
      this.dataTable.filters['endDate'] = event.rangeDates[1];
    }

    // Apply global filter string
    this.dataTable.filterGlobal(event.searchText, 'contains');
  }

  /**
   * Show or hide the bottom bar if the number of selected users is greater than 1
   */
  onSelect() {
    this.showBottomMenu = this.selectedData.length > 1 ? true : false;
  }

  /**
   * Confirm delete user/s
   * @param users
   */
  confirmDelete(groups): void {
    // Show confirm toast
    this.toastSrv.showToastConfirm(this.translate.instant('GROUP.CONFIRM-DELETE'));
    // Block the rest of the ui to avoid errors
    this.showBlockUi = true;
    let deleteSuccess = true;

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true) {

        groups = this.normalizeDeleteGroups(groups);
        groups.forEach(group => {
          this.peopleApi.deleteGroups(group).subscribe(
            res => {
            },
            error => {
              deleteSuccess = false;
              this.toastSrv.showToastError(this.translate.instant('GROUP.ERROR-DELETING'));
          });
        });

        if (deleteSuccess) {
          this.toastSrv.showToastSucess(this.translate.instant('GROUP.DELETE-API-OK'));
          this.applyDataTableFilter(this.dataTable.createLazyLoadMetadata());
        }

        this.clearDeleteSubscription();
      }

      if (res === false) {
        this.clearDeleteSubscription();
      }
    });
  }

  normalizeDeleteGroups(groups): string[]{
    if (Array.isArray(groups)) {
      return groups.map(x => x.id);
    } else {
      const aux = [];
      aux.push(groups.id);
      return aux;
    }
  }

  /**
   * Unsuscribe delete confirmation subscription and hide blockUi
   */
  clearDeleteSubscription(){
    this.confirmSubscription.unsubscribe();
    this.showBlockUi = false;
    this.showBottomMenu = false;
  }

  /**
   * Clear datatable filters
   */
  clearDataTableFilter() {
    this.dataTable.filters = {};
    this.dataTable.reset();
  }

  /**
   * Go to create project with selected users
   * @param item
   */
  assignTest(item): void {

    if (item.testtakersCount > 0) {
      let project = this.projectsSrv.generateNewProject();
      project.testtakers.push(item);

      if(this.projectsSrv.setCurrentProject(project)){
        item.selected = true;
        item.isGroup = true;
        this.router.navigate(['projects', 'create']);
      } else {
        this.toastSrv.showToastError(this.translate.instant('SHARING-PANEL.MAX-REACHED'));
      }
    } else {
      this.toastSrv.showToastError(this.translate.instant('GROUP.MSG-NO-USERS'));
    }
  }

  /**
   * Function to know if item is selected, valid to apply specific class
   * @param itemData
   * @returns
   */
  isSelected(itemData: any): boolean {
    return this.selectedData.some(x => x.id == itemData.id);
  }

  /**
   * Click in a row item to access in details
   * @param item
   * @param event
   */
  goToDetails(item, event?: Event) {
    let redirect = true;

    // Click in row
    if(event){
      const rowDetails = event?.target as HTMLElement;

      // Avoid go to details when click in checkbox or menu
      if(!Array.from(rowDetails?.classList).some(x => ['p-checkbox-box','options-button','pi-ellipsis-v','p-menuitem-link','non-clickable', 'p-button-label'].includes(x))) {
        event.stopPropagation();
      } else {
        redirect = false;
      }
    }

    if(redirect) {
      this.peopleSrv.setSharedGroup(item);
      this.router.navigate(['people', 'editgroup'], {queryParams: {type: 'EDIT'}});
    }
  }

  goToCreateNew(): void {
    this.router.navigate(['people', 'editgroup'], {queryParams: {type: 'NEW'}});
  }


  ngOnDestroy(): void {
    this.toastSrv.closeToastConfirm();
    this.subs.unsubscribe();
  }
}
