<div *ngIf="loaded">
  <!-- Test details -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12 p-lg-6 p-md-12">
      <div class="detail-data">
        <h2 class="parent-title">{{questionnaire.test.parentTest.test}}</h2>
        <h2>{{questionnaire.test.title}}</h2>
        <p *ngIf="questionnaire.test.description" class="description" [innerHtml]="(showMore) ? questionnaire.test.description : (questionnaire.test.description | slice:0:320) + '...'">
        </p>
        <div class="p-text-center">
          <button
            *ngIf="questionnaire.test.description?.length > 320"
            pButton
            class="btn-mini2"
            (click)="showMore=!showMore"
            label="{{showMore ? ('PROJECTS.SHOW-LESS' | translate) : ('PROJECTS.SHOW-MORE' | translate) }}">
          </button>
        </div>
        <hr>
        <div class="detail-subtitle">{{'PROJECTS.ENTRY-MASK-INFO' | translate}}</div>
        <!-- <div class="detail-label">{{'PROJECTS.ENTRY-MASK-QCODE' | translate}}<span>{{questionnaire.questionnaireCode}}</span></div> -->
        <div class="detail-label">{{'PROJECTS.ENTRY-MASK-QCODE' | translate}}<span>{{ questionnaire.id | padNumber: 6 }}</span></div>
        <div class="detail-label">{{'PROJECTS.ENTRY-MASK-CREATED' | translate}} <span>{{questionnaire.createdAt | date: 'dd/MM/yyyy'}}</span></div>
      </div>
    </div>
    <!-- Compilation Date -->
    <div class="p-field p-col-12 p-lg-6 p-md-12">
      <span class="regular-label">
        <label for="birthdate" class="label">{{'PROJECTS.ENTRY-MASK-COMPILATION-DATA' | translate}}</label>
        <p-calendar
          readonlyInput="true"
          [(ngModel)] = "compilationDate"
          class="custom-calendar"
          dateFormat="dd/mm/yy"
          [monthNavigator]="true"
          [yearNavigator]="true"
          yearRange="1960:2030"
          firstDayOfWeek="1"
          [disabled]="!maskEditable">
        </p-calendar>
      </span>
    </div>
  </div>

  <!--PsyUser Questions-->
  <app-input-data
    *ngIf="arrPsyuserData"
    [itemForm]="psyUserForm"
    [itemData]="arrPsyuserData"
    [title]="'PROJECTS.ENTRY-MASK-PSYUSER-DATA'"
    [maskEditable]="maskEditable">
  </app-input-data>

  <!--Profile Data Questions-->
  <app-input-data
    *ngIf="arrProfileData"
    [itemForm]="profileForm"
    [itemData]="arrProfileData"
    [title]="'PROJECTS.ENTRY-MASK-PROFILE-DATA'"
    [maskEditable]="maskEditable">
  </app-input-data>

  <!--PsyUser Scoring and Reporting Questions container -->
  <app-input-data
    *ngIf="arrPsyQuestionsScoringReporting"
    [itemForm]="psyUserScoringReportingQuestionsForm"
    [itemData]="arrPsyQuestionsScoringReporting"
    [title]="'PROJECTS.ENTRY-MASK-SCORING-AND-REPORTING'"
    [maskEditable]="maskEditable">
  </app-input-data>

  <div>

  <div class="empty-hr">
    <hr>
  </div>

  <!-- Answers Container -->

  <div *ngIf="testStructure.subtests[0].items && testStructure.subtests[0].items.length > 0" [formGroup]="editForm">
    <h2 class="p-field p-col-12 p-lg-12 p-md-12">
      {{'PROJECTS.ENTRY-MASK-ANSWERS' | translate}}
    </h2>
    <div *ngFor ="let subtest of subtests">
      <h4 *ngIf="subtests.length > 1" class="subtest-title p-d-flex">
        {{subtest.subtestTitle}}
      </h4>
      <!-- Subtest instructions -->
      <div *ngIf="subtest.subtestRules.entryMaskInstructions">
          <app-info-box title="{{'PROJECTS.INSTRUCTIONS' | translate}}" [info]="subtest.subtestRules.entryMaskInstructions" [open]="false"></app-info-box>
      </div>

      <!-- Subtest Types -->
      <div [ngSwitch]="subtest.subtestType">

        <div class="items-container" *ngSwitchCase="'standard'" [ngStyle]="setColumnsStyle(undefined, undefined, subtest.subtestRules.columnsBySubtest)">
          <!-- Items container for standard subtests -->
          <app-input-item
              *ngFor="let i = index; let item of subtest.items"
              [item]="item"
              ngClass="{{item.class}} w-full"
              [maskEditable]="maskEditable"
              [formControl]="editForm.get(item.itemId+'')"
              [editForm]="editForm"
              (inputChange)="jumpNext($event)"
              [labelItemType]="labelItemType">
          </app-input-item>
        </div>

        <!-- Items container for section subtests -->
        <div *ngSwitchCase="'section'">
          <div *ngFor="let i = index; let section of subtest.sections">
            <div *ngIf="section.sectionTitle">
              <h3>{{section.sectionTitle}}</h3>
            </div>
            <div *ngIf="section.sectionInstructions"
                class="subtest-section-instructions p-my-4"
                [innerHtml]="section.sectionInstructions">
            </div>
            <div [ngSwitch]="section.sectionType">
              <div *ngSwitchCase="'section'">
                <div class="items-container" [ngStyle]="setColumnsStyle(undefined, section.columns, subtest.subtestRules.columnsBySubtest)">
                  <app-input-item
                      *ngFor="let z = index; let item of section.items"
                      ngClass="{{item.class}}"
                      [item]="item"
                      [maskEditable]="maskEditable"
                      [formControl]="editForm.get(item.itemId+'')"
                      [editForm]="editForm"
                      (inputChange)="jumpNext($event)"
                      [labelItemType]="labelItemType">
                  </app-input-item>
                </div>
              </div>

              <div *ngSwitchCase="'subsection'">
                <div *ngFor="let z = index; let subsection of section.sections" class="subtest-subsection">
                  <div *ngIf="subsection.sectionTitle">
                    <h4>{{subsection.sectionTitle}}</h4>
                  </div>
                  <div *ngIf="subsection.sectionInstructions"
                      class="subtest-section-instructions p-my-4"
                      [innerHtml]="subsection.sectionInstructions">
                  </div>
                  <div class="items-container" [ngStyle]="setColumnsStyle(subsection.columns, section.columns, subtest.subtestRules.columnsBySubtest)">
                    <app-input-item
                        *ngFor="let item of subsection.items"
                        [item]="item"
                        ngClass="{{item.class}}"
                        [maskEditable]="maskEditable"
                        [formControl]="editForm.get(item.itemId+'')"
                        [editForm]="editForm"
                        (inputChange)="jumpNext($event)"
                        [labelItemType]="labelItemType">
                    </app-input-item>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-hr" *ngIf="visibleScalesAtEntryMask">
      <hr>
    </div>
  </div>

<!--Scale data-->
<div *ngIf="visibleScalesAtEntryMask"
  class="scale-container" [formGroup]="editFormScales">

    <h3 class="p-field p-my-4">
      {{testStructure.scaleData.globalSectionTitle}}
    </h3>

    <div *ngIf="testStructure.scaleData.sectionInstructions"
      class="section-instructions"
      [innerHtml]="testStructure.scaleData.sectionInstructions">
    </div>

    <div class="subsection" *ngFor="let subsection of testStructure.scaleData.subsections">

      <div *ngIf="subsection.scores.length > 0"> <!--Hide for subsection without scores-->
        <h4 *ngIf="subsection.subsectionLabel" class="p-field p-mt-4">
          {{subsection.subsectionLabel}}
        </h4>

        <div *ngIf="subsection.subsectionInstructions"
          class="section-instructions p-my-4"
          [innerHtml]="subsection.subsectionInstructions">
        </div>

        <div class="p-grid">
          <div *ngFor="let score of subsection.scores" class="p-col-3">
            <app-input-score [score]="score" [myFormGroup]="editFormScales" [maskEditable]="maskEditable"></app-input-score>
          </div>
        </div>

        <hr>
      </div>
    </div>
</div>

  <!-- button container -->
  <div class="p-col-12 p-text-right btn-container">
      <button
        *ngIf="canDoRandomize"
        type="button"
        class="btn-secondary p-mx-2"
        pButton
        label="Randomize"
        (click)="randomize()"
        [disabled]="!maskEditable">
      </button>
      <button
        type="button"
        class="btn-secondary p-mx-2"
        pButton
        label="{{'PROJECTS.BTN-BACK' | translate}}"
        (click)="location.back()">
      </button>
      <button
        type="button"
        class="btn-primary p-mx-2"
        pButton
        label="{{'PROJECTS.BTN-SAVE-TEST' | translate}}"
        [disabled]="checkDisabled()"
        (click)="saveTest()">
      </button>
      <button
        *ngIf="mode==='scoring'"
        type="button"
        class="btn-primary p-mx-2"
        pButton
        label="{{'PROJECTS.MENU-SCORING' | translate | uppercase}}"
        [disabled]="checkDisabled()"
        (click)="saveTest(true)">
      </button>
    </div>
  </div>
</div>

<!-- Spinner -->
<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
  <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>
