import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ExcelCsvService } from 'src/app/shared/services/excel-csv.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { PeopleApi } from '../../api/people.api';
import { PeopleService } from '../../services/people.service';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { GroupService } from '../../services/group.service';
import { TesttakerGroupSelectorComponent } from 'src/app/shared/components/modal-templates/testtaker-group-selector/testtaker-group-selector.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss'],
  providers: [ ToastService ],
  animations: [ inOutAnimation ]
})
export class EditGroupComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload') fileUpload: FileUpload;
  @ViewChild('inputDescriptionID') inputDescriptionID: ElementRef;
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('testtakerGroupSelector') testTakerGroupSelector: TesttakerGroupSelectorComponent;

  subs = new SubSink();

  // Set max testtakers allowed
  public MAX_TESTTAKERS = environment.MAX_TESTTAKERS;

  // Group
  public group: any = {};
  editForm: UntypedFormGroup;
  public type: string; // New or edit group
  public loaded = false; // Form loaded flag

  // Users
  public data = []; // Users List
  public dataCount: number = 0; // Total of users
  public dataLoaded = false; // Flag to know if users are loaded

  public selectedData = []; // Selected users
  public showBottomMenu = false;
  public showAddButton = false;
  public showTesttakersTable = false;
  public showBlockUi = false;

  private confirmSubscription: Subscription;
  private observer: IntersectionObserver; // To ui actions

  // Related with import csv
  public showImportUsersModal = false;
  public showSpinner = false;
  public showPreview = false;
  public showTooBigToImport = false;
  public usersToSave: any[] = [];
  public usersAlreadySaved: any[] = [];
  public usersToAddToGroupWithoutCreating: any[] = [];
  public usersAlreadySavedInGroup: any[] = [];
  public csvHasDuplicatedUsers: boolean = false;
  parserOptions = [{code: ',', name: ',', },  {code: ';', name: ';'}];
  selectedParser = {code: ';', name: ';', };
  parseTotalErrorsFound :number = 0;
  parseTotalWarningsFound:number = 0;
  wrongDelimiterCase:boolean = false
  loading: Boolean = false; // Loaded flag to impor users

  constructor(
    private peopleSrv: PeopleService,
    private excelCsvSrv: ExcelCsvService,
    private toastSrv: ToastService,
    private peopleApi: PeopleApi,
    private translate: TranslateService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private authSrv: AuthenticationService,
    private formBuilder: RxFormBuilder,
    private toastConfirmSrv: ToastConfirmService,
    private el: ElementRef,
    private groupSrv: GroupService
  ) { }

  ngOnInit(): void {
    this.groupSrv.clearTemporalUserList();
    this.subs.sink = this.activatedroute.queryParamMap.subscribe(event => {
      const params: any = event;
      this.type = params.params.type;

      if (this.type === 'NEW'){
        this.initPage();
      } else {
        this.initPageAsEdit();
      }

      this.initUiObserver();
    });
  }

  get name(): any {
    return this.editForm.get('name');
  }

  get description(): any {
    return this.editForm.get('description');
  }

  initPageAsEdit(): void {
    this.subs.sink = this.peopleSrv.sharedCurrentGroup.subscribe(data => {
      this.group = data;
      this.showTesttakersTable = true;
      this.initPage();
    });
  }

  initPage(): void {
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
    });
    this.initForm();
  }

  initForm(): void{
    this.setValidationMessages();

    // Generate form
    this.editForm = this.formBuilder.group({
      name : ['', [RxwebValidators.required()]],
      description: ['']
    });

    if (this.group !== undefined && this.group !== null) {
      setTimeout(() => {
        this.setGroupDetail(this.group);
      }, 0 );
    }
    this.loaded = true;
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            email: this.translate.instant('VALIDATIONS.EMAIL'),
            phonePattern: this.translate.instant('VALIDATIONS.PHONE')
          }
        });
    });
  }

  /**
   * Show or hide add user button
   */
  initUiObserver(){
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
            this.showAddButton = true;
          } else {
            this.showAddButton = false;
          }

      });
    });
    this.observer.observe(this.el.nativeElement.querySelector('.main-container'));
  }

  /**
   * Set group data for edit view
   * @param group
   */
  setGroupDetail(group): void {
    const controls: string[] = Object.keys(this.editForm.controls);

    Object.keys(group).forEach(e => {
      if (group[e] !== undefined && group[e] !== null && group[e] !== '' && controls.includes(e)) {
        this[e].patchValue(group[e]);
        this[e].markAsTouched();
      }
    });
  }

  /**
   * Get paginated user list
   * @param event
   */
  getDataLazy(event: LazyLoadEvent) {
    if(this.type !== 'NEW'){
      // Get data from API
      this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(_psyUser =>{
        if (_psyUser) {

          this.peopleApi.getTestTakersLazy(event, this.group.id).subscribe({
            next: _res => {
              this.data = _res.data.rows;
              this.dataCount = _res.data.count;
              if(this.dataCount > 0) {
                this.data = this.data.map(x => ({...x, completeName: `${x.name} ${x.surname}`, name: x.name + '' }));
              }
            },
            error: _err => {
              this.toastSrv.showToastError(this.translate.instant('TABLE.ERROR-API'))
            },
            complete: () => {
              this.dataLoaded = true;
            }
          });
        };
      });
    } else {
      // Get data from service
      const _res: any = this.groupSrv.getUserTemporal(event);
      this.data = _res.data.rows;
      this.dataCount = _res.data.count;
      this.data = this.data.map(x => ({...x, completeName: `${x.name} ${x.surname}`, name: x.name + '' }));
      if(this.data.length > 0 && this.dataCount > 0){
        this.showTesttakersTable = true;
      }
      this.dataLoaded = true;
    }
  }

  /**
   * Show or hide the bottom bar if the number of selected users is greater than 1
   */
  onSelect() {
    this.showBottomMenu = this.selectedData.length > 1 ? true : false;
  }

  /**
   * To know if user is selected or not in the table
   * @param itemData
   * @returns
   */
  isSelected(itemData: any): boolean {
    return this.selectedData.some(x => x.id == itemData.id);
  }

  /**
   * Confirm delete user/s
   * @param users
   */
  confirmDelete(user?): void {

    if(this.type !== 'NEW') {
       // Show confirm toast
      this.toastSrv.showToastConfirm(this.translate.instant('PEOPLE.CONFIRM-DELETE'));
      // Block the rest of the ui to avoid errors
      this.showBlockUi = true;

      this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
        if (res === true) {

          // Delete only one user
          if(user){
            this.sendDeleteUsersRequest([user.id]);
          } else { // Delete selecter users
            this.sendDeleteUsersRequest(this.selectedData.map(testtaker => testtaker.id));
          }
          this.clearDeleteSubscription();
        }

        if (res === false) {
          this.clearDeleteSubscription();
        }
      });
    } else {
      this.groupSrv.removeUserTemporal(user);
      // Unselect user in modal
      user.selected = false;
      this.testTakerGroupSelector.selectItem(user);
      // Reload table data
      this.getDataLazy(this.dataTable.createLazyLoadMetadata());
    }
  }

  /**
   * Send delete user action to backend
   * @param selectedUsers
   */
  sendDeleteUsersRequest(selectedUsers): void{
    const deleteObj = {
      groupID: this.group.id,
      testtakersIDList: selectedUsers
    };

    this.subs.sink = this.peopleApi.deleteUsersGroup(deleteObj).subscribe(
      res => {
        if (selectedUsers.length > 1){
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-GROUP.API-DELETEASSOCMULTIPLE-OK'));
        } else {
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-GROUP.API-DELETEASSOCUNIQUE-OK'));
        }
        // Reload table data
        this.getDataLazy(this.dataTable.createLazyLoadMetadata());
      },
      error => {
        this.toastSrv.showToastError(error);
    });
  }

  /**
   * Unsuscribe delete confirmation subscription and hide blockUi
   */
  clearDeleteSubscription(){
    this.confirmSubscription.unsubscribe();
    this.showBlockUi = false;
    this.showBottomMenu = false;
  }

  /**
   * Save user changes
   */
  saveChanges(): void {
    // Assign form objects to group data
    Object.keys(this.editForm.value).forEach(e => {
      this.group[e] = this.editForm.value[e];
    });

    if (this.type === 'EDIT') {
      this.group.updateAt = new Date();

      this.subs.sink = this.peopleApi.updateGroup(this.group).subscribe(
        res => {
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-GROUP.API-EDIT-OK'));
          this.peopleSrv.initOrRefreshFormData();

          if(this.group.id){
            this.saveUsersIntoGroup();
          }

          this.router.navigateByUrl('people');
        },
        error => {
          this.toastSrv.showToastError(this.translate.instant("EDIT-GROUP.API-ERROR-EDITING-GROUP"));
      });
    } else {
      this.group.createdAt = new Date();
      this.group.createdBy = this.authSrv.getCurrentPsyUser().id;
      this.group.customer_id = this.authSrv.getCurrentPsyUser().customer_id;
      this.group.testtakers = this.groupSrv.getUserTemporalList();

      this.subs.sink = this.peopleApi.createGroup(this.group).subscribe(
        res => {
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-GROUP.API-CREATE-OK'));
          this.peopleSrv.initOrRefreshFormData();
          this.group.id = res.data.id;

          this.saveUsersIntoGroup();

          this.router.navigateByUrl('people');
        },
        error => {
          this.toastSrv.showToastError(this.translate.instant("EDIT-GROUP.API-ERROR-CREATING-GROUP"));
      });
    }
  }

  /**
   * Associate users to group
   */
  saveUsersIntoGroup(): void{
    const assignObj = {
      groupID: this.group.id,
      testtakersIDList: this.data.map(x => x.id),
    };

    this.subs.sink = this.peopleApi.assignUsersToGroup(assignObj).subscribe(
      res => {
        console.log("Users from group updated.")
      },
      error => {
        console.error("Error adding users to the group.", error)
    });
  }

  goToGroupList(): void {
    this.router.navigate(['people']);
  }

  /**
   * Open modal-dialog to select new users
   */
  openSelectorModal(){
    this.testTakerGroupSelector.openModal();
  }

  /**
   * Push selected modal data to group
   * @param event
   */
  loadSelectedDataModal(selectedTesttakers){

    if(this.type === 'NEW') {

      // Add testtakers to new form
      this.groupSrv.addUsersTemporal(selectedTesttakers);
      this.getDataLazy(this.dataTable.createLazyLoadMetadata());

    } else {

      // Add testtakers to a created group
      const assignObj = {
        groupID: this.group.id,
        testtakersIDList: selectedTesttakers.filter(x => x.selected).map(x => x.id)
      };

      this.subs.sink = this.peopleApi.assignUsersToGroup(assignObj).subscribe(
        res => {
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-GROUP.API-ASSOC-OK'));
          this.getDataLazy(this.dataTable.createLazyLoadMetadata());
          this.testTakerGroupSelector.clear();
        },
        error => {
          this.toastSrv.showToastError(error);
      });
    }
  }

  checkSaveDisable() {
    let result = false;
    if(this.type == 'NEW' && this.dataCount <= 0) {
      result = true;
    }

    if(this.editForm.invalid) {
      result = true;
    }

    return result;
  }

  //#region ----- CSV IMPORT -----
  /**
   * Fires the upload action in p-fileUploader component
   */
  executeUpload(): void{
    this.fileUpload.upload();
  }

  reloadListIfFileSelected(): void{
    if (this.showPreview){
      this.reloadListOnDelimiterChange(this.fileUpload._files);
    }
  }

  /**
   * This is the Upload event, where all resultant users in the
   * list managed by the psyuser are exported as new users,
   * and also added to the group in this case.
   */
  async uploadTesttakerSavedListAndAddToGroupPreview(){
    if(!this.loading ){
      this.loading = true;
      let arrayUsersFailedCreation = []
      let arraySuccessfullyCreatedUsers = []

      let resp = await this.peopleApi.createUsers(this.usersToSave).toPromise();
      arraySuccessfullyCreatedUsers = resp.data.created;
      for (let index of Object.keys(resp.data.failed)) {
        let _user = this.usersToSave[index];
        _user.error = resp.data.failed[index];
        _user.index = index;
        arrayUsersFailedCreation.push(_user);
      }

      //Some users failed to create, maybe becouse they already exist
      let userFatalErrorImporting = false
      if (arrayUsersFailedCreation.length > 0) {
        userFatalErrorImporting = true;
      }

      //If this group wasn´t new, it has an id, so we can add users now to it.
      let errorAssigningUsersToGroup = false;

      // Concat new created with selected
      let testtakersIDList = arraySuccessfullyCreatedUsers.map(x => x.id).concat(this.usersToAddToGroupWithoutCreating.map(x => x.id));

      if (this.group.id){
        const assignObj = {
          groupID: this.group.id,
          testtakersIDList: testtakersIDList
        };
        try{
          await this.peopleApi.assignUsersToGroup(assignObj).toPromise();
        }catch (error) {
          errorAssigningUsersToGroup = true
        }
      }else{
        // Add testtakers to new form
        this.groupSrv.addUsersTemporal(arraySuccessfullyCreatedUsers.concat(this.usersToAddToGroupWithoutCreating));
      }

      // Reload table data
      this.getDataLazy(this.dataTable.createLazyLoadMetadata());

      if(userFatalErrorImporting && !errorAssigningUsersToGroup){
        let details = '<ul class="info-list"><li><b>'  + this.translate.instant('EDIT-GROUP.API-ASSOC-MULTIPLE-USERS-ERROR-INDEX' ) + ':</b> <b>'  + this.translate.instant('EDIT-GROUP.API-ASSOC-MULTIPLE-USERS-ERROR-MESSAGE' ) + '</b></li>';
        arrayUsersFailedCreation.forEach(_user => {

          details += "<li><b>"+_user.index+ ':</b> '  + this.translate.instant('EDIT-GROUP.'+_user.error) + "</li>";
        });
        details += '</ul>';
        this.toastSrv.showToastInfo(this.translate.instant('EDIT-GROUP.API-ASSOC-MULTIPLE-USERS-DETAIL')
        .replace('${created}',arraySuccessfullyCreatedUsers.length)
        .replace('${total}',this.usersToSave.length),details);

      }else if(errorAssigningUsersToGroup){
        this.toastSrv.showToastError(this.translate.instant('EDIT-GROUP.API-ERROR-ASSOC-MULTIPLE-USERS'));

      //Success in the operation
      }else{

          this.toastSrv.showToastSucess(this.usersToSave.length + ' '
            + this.translate.instant('EDIT-GROUP.API-ASSOC-MULTIPLE-USERS'));

      }

      this.showImportUsersModal = false;
      this.loading = false;
    }
  }

  /**
   * Method called upon selected file to parse action, and change of delimitier
   *
   * Updates the list of testtaker obtained from the CSV file, and in case of
   * another delimitier has been chosen
   * @param event;
   */
  async updateCSVTesttakerList(event: any): Promise<void> {

    // Applyng focus to other element to prevent pressing buttons and launching the parsing with errors
    setTimeout(() => {
      this.inputDescriptionID.nativeElement.focus();
    }, 0 );

    // Start the processing
    this.showSpinner = true;
    const files = event.currentFiles;

    //Parse the testtakers from the csv file
    let operationResult = await this.excelCsvSrv.parseTesttakersFromFile(files, this.selectedParser)
    if(operationResult.parseError || operationResult.delimiterProblem){
      if(operationResult.parseError){
        this.showSpinner = false;
        this.toastSrv.showToastError(this.translate.instant('EDIT-PEOPLE.ERROR-PARSING'));
      }

      //If wrong delimiter case is found, try to automatically change it
      if(operationResult.delimiterProblem){
        this.wrongDelimiterCase = true;
      }
    }

    //Process the rows of the imported buffer, update total error/warning count
    let usersRetrievedResult = this.excelCsvSrv.processTesttakersFromCSVBuffer(operationResult.usersToSaveList)
    let groupId = this.group.id || -1;
    let checkTesttakers= await this.peopleApi.validateAndClassifyUsersToImportWithGroupInformation(usersRetrievedResult.testtakers, groupId).toPromise();
    this.usersToSave= checkTesttakers.data.newUsers;
    this.usersToAddToGroupWithoutCreating= checkTesttakers.data.existingUsersNotInGroup;
    this.usersAlreadySaved= checkTesttakers.data.existingUsers;
    this.usersAlreadySavedInGroup = checkTesttakers.data.existingUsersInGroup;
    this.csvHasDuplicatedUsers = checkTesttakers.data.csvHasDuplicatedUsers;

    let count= this.dataCount + checkTesttakers.data.processed;

    if(count>this.MAX_TESTTAKERS){
      this.rejectImportAndNotify(this.MAX_TESTTAKERS)
      return;
    }

    this.parseTotalErrorsFound = usersRetrievedResult.parseErrorNumber
    this.parseTotalWarningsFound = usersRetrievedResult.parseWarningNumber

    if(this.csvHasDuplicatedUsers){
      this.toastSrv.showToastWarning(this.translate.instant('EDIT-PEOPLE.DUPLICATED-USERS'));
    }

    //Show results in GUI
    this.showSpinner = false;
    this.showPreview = true;
  }

  rejectImportAndNotify(limit:number){
    let message=this.translate.instant('EDIT-PEOPLE.TOO-MANY-IMPORTING').replace('${limit}', limit);

    this.toastSrv.showToastError(message);
        this.showSpinner = false;
        this.showTooBigToImport = true;
        this.showPreview=true;
  }

  /**
   * Delete the user selected and clear his errors and warnings
   * @param $event 'user selected'
   */
  deleteNewUserFromImported($event): void {
    this.usersToSave = this.usersToSave.filter(user=> user != $event)
  }

  deleteExistingUserFromImported($event): void {
    this.usersToAddToGroupWithoutCreating = this.usersToAddToGroupWithoutCreating.filter(user=> user != $event)
  }

  /**
   * Reload the parsed list using the different sepparator
   * @param selectedFiles
   */
  reloadListOnDelimiterChange(selectedFiles): void{
    this.usersToSave = [];
    this.parseTotalErrorsFound = 0;
    this.parseTotalWarningsFound = 0;
    this.wrongDelimiterCase = false;

    // Emulate select file event '{currentFiles: selectedFiles}'
    this.updateCSVTesttakerList({currentFiles: selectedFiles})
  }

  /**
   * Restart GUI, clear file selection and variables
   */
  clearFileUpload(): void {
    this.showTooBigToImport=false;
    this.fileUpload.clear();
    this.showPreview = false;
    this.usersToSave = [];
    this.parseTotalErrorsFound = 0;
    this.parseTotalWarningsFound = 0;
    this.wrongDelimiterCase = false;
  }

  //#endregion

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
