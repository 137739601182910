<h1 *ngIf="!embedded">{{"CONTAINER-GROUP-USER.PEOPLE" | translate}}</h1>

<button
    *ngIf="embedded"
    pButton
    class="btn-mini3 p-mx-3"
    [routerLink]="['/people']"
    label="{{'DASHBOARD.VIEW-PEOPLE' | translate}}">
</button>

<p-tabView *ngIf="peopleLabel!==undefined" [(activeIndex)]="activeIndex" (onChange)="storeActiveIndex()">
    <p-tabPanel header="{{peopleLabel}}">
        <ng-template pTemplate="content">
            <app-people></app-people>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{groupsLabel}}">
        <ng-template pTemplate="content">
            <app-groups></app-groups>
        </ng-template>
    </p-tabPanel>
</p-tabView>