import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { TranslateService } from "@ngx-translate/core";
import { ProjectsService } from "../../services/projects.service";
import { ProjectsApi } from "../../api/projects.api";
import { Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { CatalogService } from "src/app/modules/catalog/services/catalog.service";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";
import { Codes } from '@giuntipsy/utils/lib';
import { MessageService } from "primeng/api";
import { ErrorsService } from "src/app/shared/services/errors.service";
import { ProjectStatusString } from "src/app/shared/enums/project-status-string.enum";
import { RoleBasedTemplateComponent } from "src/app/shared/components/modal-templates/role-based-template/role-based-template.component";
import { TesttakerGroupSelectorComponent } from "src/app/shared/components/modal-templates/testtaker-group-selector/testtaker-group-selector.component";
import { EntryType } from "src/app/shared/enums/entry-type.enum";

@Component({
  selector: "app-create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.scss"],
  providers: [ToastService],
})

export class CreateProjectComponent implements OnInit, OnDestroy {

  @ViewChild('roleBasedPopUp') roleBasedPopUp: RoleBasedTemplateComponent;
  @ViewChild('evaluatedPersonUpdate') evaluatedPersonUpdate;
  @ViewChild('testtakerGroupSelector') testTakerGroupSelector: TesttakerGroupSelectorComponent;

  // Subscriptions Handler
  subs = new SubSink();

  // Entry Types
  projectEntryType = EntryType;

  supplyTypes = [
    { name: "", code: this.projectEntryType.Manual },
    { name: "", code: this.projectEntryType.OnSite },
    { name: "", code: this.projectEntryType.Remote },
    { name: "", code: this.projectEntryType.Blind }
  ];
  supplyTypesSingle = [
    { name: "", code: this.projectEntryType.Manual },
    { name: "", code: this.projectEntryType.OnSite },
    { name: "", code: this.projectEntryType.Remote },
    { name: "", code: this.projectEntryType.Blind }
  ];
  supplyTypesMultiple = [
    { name: "", code: this.projectEntryType.Manual },
    { name: "", code: this.projectEntryType.Remote },
    { name: "", code: this.projectEntryType.Blind }
  ];

  // Params
  ProjectStatusString = ProjectStatusString;  // Status
  currentPsyUser: any;
  private assessmentUrl = environment.webAssesmentURL;
  private clientId = environment.integrationClientId; // get the client ID

  // Project
  project: any; // Project obj
  private projectId;
  tempProjectId: any; // Project id to get blind administration data
  assigned: boolean = false;
  creating: boolean = false;
  redirect: boolean = false; // To know if the project go to details view after save
  isGroup: boolean = false;
  isEdit: boolean; // set if the project is in edit mode

  // UI
  displayActionSidebar = false;
  showCredits: boolean = false;  // Display credits counter
  accumulatedCredits = 0;
  showUses: boolean = false; // Display uses counter
  accumulatedUses = 0;

  // Tests
  displayTestsPanel = false; // Show/hide test pop-up
  catalogLoaded: boolean = false; // Test loaded flag
  availableDataLoaded = false;
  availableTests: any = [];
  selectedTest: any = {};
  selectedReport_id: any = [];
  invalidTest = 0; // Check invalid test
  originalQuestionnaires: any = []; // save the original questionnaries in edit mode

  // Entry Type
  loadedDropdown = false; // Loaded entrytype
  selectedType: any;
  previousSelectedType: any;

  // Emails
  withMail: boolean = false; //set flow based in send emails
  usersWithoutEmails = []; // Invalid email users
  displayPanelUsersWithoutEmails = false;
  displayCustomEmail = false; // Email template modal
  emailContentHtml: string = null; // Email template

  // Zendesk
  countryId: string; // Country id to enable or not zendeskbutton
  showZendeskButton: boolean = false;

  // Roles
  roleBasedTest: boolean = false;
  currentTestRoleBased: boolean;
  displayRoleBasedAssignation: boolean = false;
  rolesBySubtest = []; //use this to save project and questionnaires structures at db
  roles = [];
  currentAvailableRoles = [];
  validRoleBased: boolean = false;
  navigateToBeforeRoleBasedPopUp: string;
  evaluatedPerson = {
    nameToShow: '',
    assessmentName: '',
    name:null,
    surname: null,
    id:null
  }
  evaluatedPersonList: any[] = [];
  validRoleAssing = false;
  validNameToShow = false;
  supplyAdviceShow = false;

  constructor (
    private catalogSrv: CatalogService,
    private projectsApi: ProjectsApi,
    private projectsSrv: ProjectsService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private messageSrv: MessageService,
    private authSrv: AuthenticationService,
    private route: Router,
    private errorsSrv: ErrorsService
  ) {}

  //#region ----- INIT FLOW -----
  ngOnInit(): void {
    this.subs.sink = this.projectsSrv.sharedProject.subscribe((data) => {
      this.project = data;
      if(this.project.questionnaires)
        this.project['_questionnaires'] = JSON.parse(JSON.stringify(this.project.questionnaires))

      if(this.project.projectHasTestTakers?.length > 1){
        this.isGroup = true
      }
      if(this.project.questionnaires?.length > 0){
        this.project.sequence = this.project.questionnaires[0].orderInProject != null
      }

      if((this.project.testtakers)&&(this.project.questionnaires)){
        if(!((this.project.testtakers.length==0)||(this.project.questionnaires.length==0))&&(this.project.status===ProjectStatusString.ASSIGNED)){
          this.assigned=true;
        }
      }

      // check edit or create
      this.isEdit = this.project.id ? true : false;

      this.authSrv.sharedCurrentPsyUser.subscribe((res) => {
        if (res !== null && res !== undefined && res !== "null" && this.currentPsyUser === undefined) {
          this.currentPsyUser = res;
          this.initActionSidebar();
          this.initTestsCatalog();
          if (this.isEdit) {
            this.selectedType = this.supplyTypes.filter(
              (x) => x.code === data.entryType_id
            )[0];
          } else {
            this.selectedType = this.supplyTypes[2]; // Remote at start new project
          }
          this.initTypeDropdown();
          this.reloadSupplyTypes();
        }
      });
    });

    /// check if  current user subsidiary is from Italy to show the zendesk button
    this.countryId = localStorage.getItem('countryPsyuserId')
    if (this.countryId === '108'){
      this.showZendeskButton = true;
    };

    // Check if supply type warning has been showed
    this.projectsSrv.sharedCurrentSupplyAdviceShow.subscribe(_res => {
      this.supplyAdviceShow = _res;
    });
  }

  /**
   * Set available entry types at startup
   */
  initTypeDropdown(): void {
    this.subs.sink = this.translate.get("random.key").subscribe(() => {
      this.supplyTypes.forEach((x) => {
        x.name = this.translate.instant(`CREATE-PROJECT.DD-${this.projectsSrv.getCustomEntryType(x.code).toUpperCase()}`);
      });
      this.selectedType = this.supplyTypes.filter(
        (x) => x.code === this.selectedType.code
      )[0];
      if (!this.selectedType) this.selectedType = this.supplyTypes[1]; // Set remote if change from on-site
      this.project.supply = this.selectedType.code;
      this.previousSelectedType = this.selectedType;
      this.loadedDropdown = true;
    });
  }

  /**
   * Get available tests for psyuser
   */
  async initTestsCatalog(): Promise<void> {
    this.catalogSrv.setPsyUserAssignationGroups(this.currentPsyUser);

    this.catalogSrv.loadAvailableCatalog().subscribe(data => {
      for (const test of data.data) {
        test.combinedName = `${test.parentTest.test} - ${test.title} [${test.locale.toUpperCase()}]`
      }

      this.availableTests = JSON.parse(JSON.stringify(data.data));

      if (this.isEdit) {
        this.generateUsersEditProject();
        this.generateTestsEditProject();
      }

      this.recalculateCredits();
      this.catalogLoaded = true;
      this.availableDataLoaded = true;

      //this.selectTesttTakerAndGroupOnLoad();
    });
  }

  /**
   * Add previus selected users to project data
   */
   generateUsersEditProject(): any {
    // get the testtakers form projectHasTestakers
    const testakersIds = this.project.projectHasTestTakers.map(
      (x) => x.testtaker_id
    );
    this.project.testtakers = this.project.testtakers.filter((x) =>
      testakersIds.includes(x.id)
    );
    // remove duplicate testakers
    this.project.testtakers = [
      ...new Map(this.project.testtakers.map((x) => [x["id"], x])).values(),
    ];
    // add flag original to prevent delete action in previous users
    this.project.testtakers = this.project.testtakers.map((x) => ({
      ...x,
      original: true,
    }));
  }

  /**
   * Add previus selected tests to project data
   */
  generateTestsEditProject(): any {
    if (this.originalQuestionnaires.length === 0) {
      // Remove duplicates questionnaires by tesd_id, is enough because the selected report for this test is always the same
      this.originalQuestionnaires = [
        ...new Map(
          this.project.questionnaires.map((x) => [x["test_id"], x])
        ).values(),
      ];

      // Reset the current selected questionnaires
      this.project.questionnaires = [];

      // Order the test based in orderInProject property
      this.originalQuestionnaires.sort((a, b) =>
        a.orderInProject > b.orderInProject
          ? 1
          : b.orderInProject > a.orderInProject
          ? -1
          : 0
      );

      let keys:string[] = []
      this.originalQuestionnaires.forEach((x) => {
        // Emulate the selection of the test and add test to project
        this.selectedTest = this.availableTests.filter(
          (z) => z.id === x.test_id
        )[0];
        if(this.isEdit){
          keys.push(this.selectedTest.testKey)
        }
        this.selectedTest.original = true; // For avoid delete test
        this.selectedReport_id = x.selectedReport_id;
        this.addTestsToProject();
      });
      this.availableTests = this.availableTests.filter(x => !keys.includes(x.testKey))

    }
  }
  //#endregion

  //#region ----- USERS EVENTS -----
  /**
   * Set selected users in modal to users column project
   * @param $event
   */
  loadSelectedDataModal($event){
    this.project.testtakers=$event;
    this.reloadSupplyTypes();
    this.recalculateCredits();
  }

  /**
   * Get length of users in project
   * @returns
   */
  getParentDataCount() {
    return this.project.testtakers.length;
  }

  /**
   * Remove user of users column project
   * @param $event
   */
  deleteUser($event): void {

    this.project.testtakers = this.project.testtakers.filter(__project => __project.id !== $event.id);
    $event.selected = false;
    this.testTakerGroupSelector.selectItem($event);

    this.reloadSupplyTypes();
    this.recalculateCredits();
    this.setUsersNameToShow();
  }

  /**
   * Set selected users on load
   */
  selectTesttTakerAndGroupOnLoad() {
    // Set testtaker as selected
    this.project.testtakers.forEach(_testtaker => {
      _testtaker.selected = true;
      this.testTakerGroupSelector.selectItem(_testtaker);
    });
  }

  /**
   * Event when the selected user is moved to another position
   * @param event
   */
  dropUser(event): void {
    moveItemInArray(
      this.project.testtakers,
      event.previousIndex,
      event.currentIndex
    );
  }
  //#endregion

  //#region ----- EMAIL CONTROL -----
  /**
   * Change the email template
   * @param $event
   */
  emailContent($event: any) {
    this.emailContentHtml = $event;
  }

  /**
   * Confirmation from email text modal
   */
  saveProjectWithMail() {
      this.displayCustomEmail = false;
      this.send();
  }

  /**
   * Check if all the selected tetsttakers have emails before send
   */
  async send() {
    this.usersWithoutEmails = [];
    if (!this.withMail) this.project.status = ProjectStatusString.CREATED;

    for(const _item of this.project.testtakers) {
      if(_item.isGroup){
        const _validationResult: any = await this.projectsApi.getGroupValidation(_item.id);
        if(!_validationResult.data.valid) {
          // Add non valid testtakers reference
          _item.nonValidTesttakers = _validationResult.data.nonValidTesttakers;
          this.usersWithoutEmails.push(_item);
        }
      } else {
        if (_item.email === undefined || _item.email === null || _item.email === ""){
          this.usersWithoutEmails.push(_item);
        }
      }
    }

    if ( this.usersWithoutEmails.length > 0 && this.project.supply === this.projectEntryType.Remote) {
      this.displayPanelUsersWithoutEmails = true;
    } else {
      this.saveProject(["projects"]);
    }
  }

  private sortReports(reports: any[]) {
    const sortedArray = [];
    for (const report of reports) {
      if (!report.parentReport_id) {
        sortedArray.push(report);
        const parentReportId = report.id;
        const appendices = reports.filter(rep => rep.parentReport_id === parentReportId);
        sortedArray.push(...appendices);
      }
    }
    return sortedArray;
  }

  /**
   * Return list test ordered by name
   * @param reports
   * @returns
   */
  sortSelectedTestReports(reports): any {
    if (reports) {
      let validReports = reports.filter(rep => !rep.discontinued);
      validReports.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0 );
      return this.sortReports(validReports);
    } else {
      return [];
    }
  }

  /**
   * Check if selected test is valid, if not show specific message
   */
  async checkValidTest(): Promise<void> {
    await new Promise<void>((resolve) => {
      this.checkIfIsRoleBasedTest(this.selectedTest, resolve);
    });
    // Reset selected values
    let result = 0;
    this.closeToastWarning();

    // Check if test is repeated
    if (this.project.questionnaires.filter((x) => x.id == this.selectedTest.id).length > 0) {
      result = 1;
      this.showToastWarning(this.translate.instant('CREATE-PROJECT.MSG-REPEATED'));
    }

    // Check if selected type is valid
    if (!this.selectedTest.entryTypes.map(x => x.id).includes(this.selectedType.code)) {
      result = 2;
      this.showToastWarning(this.translate.instant('CREATE-PROJECT.MSG-INVALID-SUPPLY'));
    }

    // Check if exist external test with same parentTest
    if(this.selectedTest.external && this.project.questionnaires.some(_quest => _quest.parentTest.id == this.selectedTest.parentTest.id)){
      result = 3;
      this.showToastWarning(this.translate.instant('CREATE-PROJECT.MSG-EXTERNAL-REPEATED'));
    }

    // Check if project has a role based test selected previously
    if(this.project.questionnaires.length > 0 && this.currentTestRoleBased){
      result = 4;
      this.showToastWarning(this.translate.instant('CREATE-PROJECT.MSG-ROLE-BASED-CURRENT'));
    }

    // Check if project has a role based test selected previously
    if(this.roleBasedTest){
      result = 5;
      this.showToastWarning(this.translate.instant('CREATE-PROJECT.MSG-ROLE-BASED-PREVIOUS'));
    }

    this.invalidTest = result;
  }

  /**
   * Change available supply types rearding the number of selected testtakers
   */
  reloadSupplyTypes(): void {
    if (this.project.testtakers.length > 1 || this.project.testtakers.filter((x) => x.isGroup).length > 0) {
      this.supplyTypes = this.supplyTypesMultiple;
      if(!this.supplyAdviceShow && !this.isEdit){
        this.toastSrv.showToastConfirm(this.translate.instant("CREATE-PROJECT.CONFIRM-TESTAKER"), 'warning-ok');
        this.projectsSrv.setCurrentSupplyAdviceShow(true);
      }
    } else {
      this.supplyTypes = this.supplyTypesSingle;
    }
    this.initTypeDropdown();
  }

  /**
   * Remove questionnaires that not work with the selected entry type
   */
  cleanProjectsTest(): void {
    this.project.questionnaires = this.project.questionnaires.filter((x) => {
      const types = x.entryTypes.map(type => type.id);
      return types.includes(this.selectedType.code);
    });
    this.recalculateCredits();
  }

  /**
   * Add test from test selector to seletected tests column
   */
  async addTestsToProject(): Promise<void> {
    this.selectedTest.selectedReport_id = this.selectedReport_id;
    const testToInsert = { ...this.selectedTest };
    this.project.questionnaires.push(testToInsert);
    await this.checkIfIsRoleBasedTestInProject(this.selectedTest);
    this.recalculateCredits();
    this.setUsersNameToShow();
  }

  /**
   * Remove test from the selected tests column
   * @param $event
   * @param index
   */
  deleteTest($event, index): void {
    this.project.questionnaires.splice(index, 1);
    this.roleBasedTest = false;
    this.recalculateCredits();
  }

  /**
   * Event when the selected test is moved to another position
   * @param event
   */
  dropTest(event): void {
    if (!this.isEdit) {
      moveItemInArray(
        this.project.questionnaires,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this.toastSrv.showToastError(
        this.translate.instant("CREATE-PROJECT.MSG-ORDER")
      );
    }
  }
  //#endregion

  //#region ----- ROLE BASED -----
  checkIfIsRoleBasedTest(test, resolve): void { //check if current test is a role based test
    this.currentTestRoleBased = false;
    this.projectsApi.getTestRules(test.id).subscribe((_result) => {
      this.currentTestRoleBased = _result.message.test_rules?.basedInRole ? _result.message.test_rules.basedInRole : false;
      if (this.currentTestRoleBased) {
        this.projectsApi.getRolesByTest(test.id).subscribe((_result) => {
          this.rolesBySubtest = _result.message;
          this.roles = this.rolesBySubtest.map(x => x.role)
          // Remove duplicates roles
          this.roles = [... new Map(this.roles.map((x) => [x["name"], x])).values()];
        })
      }
      resolve();
    });
  }

  async checkIfIsRoleBasedTestInProject(test): Promise<void> { // check if the project has a role based test
    this.currentTestRoleBased = false;
    await this.projectsApi.getTestRules(test.id).subscribe((_result) => {
      this.roleBasedTest = _result.message.test_rules?.basedInRole ? _result.message.test_rules.basedInRole : false;
    });
  }

  /**
   * Centralises the decision to open or not to open a popup and if so, to indicate which popup to open
   * @param navigateTo optional, only for options without popUp
   */
  determinatePopUp(navigateTo?){
    this.navigateToBeforeRoleBasedPopUp = navigateTo;
    if(!this.isEdit && this.roleBasedTest){
      if (this.project.testtakers.length <= this.roles.length){
        this.displayRoleBasedAssignation = true;
        this.roleBasedPopUp.initRoleBasedTemplate();
      } else {
        this.toastSrv.showToastWarning(this.translate.instant('CREATE-PROJECT.MORE-TESTTAKERS-THAN-ROLES'));
      }
    } else {
      switch (this.project.supply) {
        case this.projectEntryType.Manual:
          if(navigateTo){
            this.saveProject(navigateTo);
          } else {
            this.answers();
          }
          break;
        case this.projectEntryType.Remote:
          if(this.withMail){
            this.displayCustomEmail = true;
          } else {
            this.saveProjectWithMail();
          }
          break;
        case this.projectEntryType.OnSite:
          this.administer();
          break;
        case this.projectEntryType.Blind:
          if(navigateTo){
            this.saveProject(navigateTo);
          } else {
            this.saveProjectWithMail();
          }
          break;
      }
    }
  }

  checkValidRoleAssingToSave(assignedRoles) {
    this.validRoleAssing = this.project.testtakers.length === assignedRoles.filter(x => x.role).length;
    this.checkValidRoleBasedToSave();
  }

  checkValidEvaluatedPersonToSave(evaluatedPerson){
    evaluatedPerson.assessmentName = evaluatedPerson.assessmentName.trim();
    this.validNameToShow = evaluatedPerson.assessmentName.length > 0;
    this.evaluatedPerson = {...evaluatedPerson};
    this.checkValidRoleBasedToSave();
  }

  checkValidRoleBasedToSave(){
    if(this.validNameToShow && this.validRoleAssing){
      this.validRoleBased = true;
    } else {
      this.validRoleBased = false;
    }
  }

  /**
   * prepare testtakers list to show at roleBasedTemplate and add extra 'other person' at evaliatedPersonList
   */
  setUsersNameToShow(){
    this.project.testtakers.forEach((_user)=>{
      if(_user.name && _user.surname){
        _user['nameToShow'] = _user.name  + ' ' + _user.surname;
      } else if (_user.name) {
        _user['nameToShow'] = _user.name;
      } else {
        _user['nameToShow'] = _user.friendlyId;
      }
    });
    this.evaluatedPersonList = JSON.parse(JSON.stringify(this.project.testtakers));
    let anotherPerson = {
      nameToShow: this.translate.instant('CREATE-PROJECT.ANOTHER-PERSON'),
      name:'other',
      surname: 'other',
      id: 0
    }
    this.evaluatedPersonList.push(anotherPerson);
  }

  determinateNextStepBySupplyType(){
    switch (this.project.supply) {
      case this.projectEntryType.Manual:
        if(this.navigateToBeforeRoleBasedPopUp){
          this.saveProject(this.navigateToBeforeRoleBasedPopUp);
        } else {
          this.answers();
        }
        break;
      case this.projectEntryType.Remote:
        if(this.withMail){
          this.displayCustomEmail = true;
        } else {
          this.saveProjectWithMail();
        }
        break;
      case this.projectEntryType.OnSite:
        this.administer();
        break;
      case this.projectEntryType.Blind:
        if(this.navigateToBeforeRoleBasedPopUp){
          this.saveProject(this.navigateToBeforeRoleBasedPopUp);
        } else {
          this.saveProjectWithMail();
        }
        break;
    }
  }
  //#endregion

  //#region ----- UI EVENTS -----
  /**
   * Show tpo bar to create project
   */
  initActionSidebar(): void {
    setTimeout(() => {
      this.displayActionSidebar = true;
    }, 500);

    if (this.isEdit) {
      this.project.name = this.project.code;
    }
  }

  showToastWarning(text: string): void {
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');
    this.messageSrv.add({severity: 'warning', detail: text, summary: btnText ,key: 'toast-warning-dialog', life: 10000});
  }

  closeToastWarning(): void {
    this.messageSrv.clear('toast-warning-dialog');
  }

  showApiMessage(res, answer) {
    if (res.code === Codes.PsyPortal.ERROR_CREATING) {
      this.toastSrv.showToastSucess(
        this.translate.instant("CREATE-PROJECT.API-LATER-OK-MAIL")
      );
    } else {
      if (!answer) {
        if(this.project.status == ProjectStatusString.CREATED){
          this.toastSrv.showToastSucess(
            this.translate.instant("CREATE-PROJECT.API-LATER-OK")
          );
        }else{
          if (this.project.supply === this.projectEntryType.Manual){
            if (this.redirect){
              this.toastSrv.showToastSucess(this.translate.instant("CREATE-PROJECT.API-SAVE-OK-MANUAL"));
            } else {
              this.toastSrv.showToastSucess(this.translate.instant("CREATE-PROJECT.API-LATER-OK"));
            }
          } else if (this.project.supply === this.projectEntryType.OnSite) {
            this.toastSrv.showToastSucess(this.translate.instant("CREATE-PROJECT.API-SAVE-OK-MANUAL"));
          } else if (this.project.supply === this.projectEntryType.Blind) {
              this.toastSrv.showToastSucess(this.translate.instant("CREATE-PROJECT.API-SAVE-OK-BLIND"));
              // Get excel file of blind administration data
              this.getBlindAministrationData();
          } else {
            this.toastSrv.showToastSucess(this.translate.instant("CREATE-PROJECT.API-SAVE-OK"));
          };
        };
      }
    }
  }

  recalculateCredits(): void {
    this.accumulatedCredits = 0;
    this.accumulatedUses = 0;
    const accumulatedUsers = this.project.testtakers.reduce((acc, item) => {
      if (item.isGroup) acc += item.testtakersCount;
      else acc++;
      return acc;
    }, 0);

    let credits = 0;
    let uses = 0;
    for (const test of this.project.questionnaires) {
      for (const reportID of test.selectedReport_id) {
        if(test.plans) {
          const useCredits = test.plans.some(plan => plan.type === 1);
          if (useCredits) {
            const index = test.reports.findIndex(report => report.id === reportID);
            if (index !== -1) credits += test.reports[index].credits;
          } else {
            uses++;
          }
        }
      }
    }

    this.accumulatedCredits = credits * accumulatedUsers;
    this.accumulatedUses = uses * accumulatedUsers;

    this.showCredits = this.accumulatedCredits > 0;
    this.showUses = this.accumulatedUses > 0;
  }
  //#endregion

  //#region ----- BLIND ADMINISTRATION -----
  getBlindAministrationData() {
    const tempProject = {
      id: this.tempProjectId,
      code: this.project.name,
      supply: this.project.supply
    }

    this.projectsSrv.generateBlindAdministrationExcel(tempProject).then(res => {
      if(res){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-BLIND-OK'));
      } else {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-BLIND-NO'));
      }
    }).catch(err => {
      this.toastSrv.showToastError("Error " + err.message);
    })
  }
  //#endregion

  //#region ----- SAVE CHANGES -----

  /**
   * Go to entry mask of created manual project
   * @returns
   */
  answers(): void {
    if(this.creating) return;
    this.projectsSrv.setCurrentTest(this.project.questionnaires);
    this.saveProject(["projects", "answers"], false, true);
  }

  /**
   * Go to save project with params to logout and open new page with the created assessment
   */
  administer(): void {
    this.saveProject(["projects", "admin"], true);
  }

  /**
   * Save changes in project
   * @param navigateTo Route of app to be navigated after saved
   * @param logout Indicate if the app need to be logout once the project is saved
   * @param answer Indicate if the app go to the entryMask once the project is saved
   * @returns
   */
  saveProject(navigateTo, logout?, answer?): void {
    if(this.creating) return;
      this.creating = true;

    if (this.emailContentHtml) {
      this.project["customEmail"] = this.emailContentHtml;
    }

    this.project["language"] = this.translate.currentLang;

    // Add clientId in project body
    this.project["clientId"] = this.clientId;
    this.project["withMail"] = this.withMail;
    this.project[ProjectStatusString.ASSIGNED] = this.assigned;
    this.project.status = this.assigned ? ProjectStatusString.ASSIGNED : ProjectStatusString.CREATED

    // set order in project
    if (this.project.sequence) {
      this.project.questionnaires.forEach((questionnaire, index) => {
        questionnaire.orderInProject = index + 1;
      });
    }

    /// Add evaluatedPerson at extraInfo
    if(this.evaluatedPerson.nameToShow !== ''){
      this.evaluatedPerson = {
        id: this.evaluatedPerson.id,
        name: this.evaluatedPerson.name,
        surname:this.evaluatedPerson.surname,
        nameToShow: this.evaluatedPerson.nameToShow,
        assessmentName: this.evaluatedPerson.assessmentName
      }
      this.project.evaluatedPerson = {...this.evaluatedPerson}
    }

    /// Add roleBased flag
    this.project.roleBased = this.roleBasedTest;

    // Generate the api object
    const projectObj = this.projectsSrv.generateApiProject(this.project);

    if (this.isEdit) {
      this.subs.sink = this.projectsApi.updateProject(projectObj).subscribe(
        (res) => {
          this.tempProjectId = this.project.id;
          this.showApiMessage(res, answer);
          // Set currentQcode
          this.projectsSrv.setQCode(projectObj._questionnaires[0].questionnaireCode);

          // Set tab when the navigation returns
          if (this.project.testtakers.length > 1) {
            this.projectsSrv.setActiveIndex(1);
          } else {
            this.projectsSrv.setActiveIndex(0);
          }

          // Send email to new testtakers
          if(this.project.entryType_id === 3) { // Only for edition remote projects
            this.sendEmailsToNewTesttakers(navigateTo);
          } else {
            this.route.navigate(navigateTo);
          }
        },
        (error) => {
          this.toastSrv.showToastError(error.error.message);
          this.creating = false;
        }
      );
    } else {
      this.subs.sink = this.projectsApi.createProject(projectObj).subscribe(
        (res) => {
          // Set project_id for blind data before use the function showApiMessage
          this.tempProjectId = res.data.project_id;
          this.showApiMessage(res, answer);

          // Set project id and project reference
          this.project.id = this.tempProjectId;
          this.projectsSrv.setCurrentProject(this.project);

          if (logout || answer) {
            this.projectId = res.data.project_id;
            this.subs.sink = this.projectsApi
              .getProject(this.projectId)
              .subscribe((resProject) => {
                const createdProject = resProject.data;
                const questionnaireCode = createdProject.questionnaires[0].questionnaireCode;
                if (logout) {
                  const url = `${this.assessmentUrl}${questionnaireCode}`;
                  window.open(url, "_blank");
                  this.authSrv.logout();
                } else if (answer) {
                  this.projectsSrv.setQCode(questionnaireCode);
                  this.route.navigate(["projects", "answers"]);
                }
              });
          } else {
            if (this.project.testtakers.length > 1) {
              this.projectsSrv.setActiveIndex(1);
            } else {
              this.projectsSrv.setActiveIndex(0);
            }
            if (this.redirect) {
              this.project.id = res.data.project_id;
              this.projectsSrv.setCurrentProject(this.project);
              this.route.navigate(["projects/details"]);
            } else {
              this.route.navigate(navigateTo);
            }
          }
        },
        (error) => {
          let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
            error.error.code,
            {
              useGenericEnvIfCustomFail:true,
              customEnvironment: "PROJECTS.HTTP-CODES",
              defaultMsgKey: "CREATE-PROJECT.DEFAULT-ERROR"
            }
          )
          this.toastSrv.showToastError(messageToShow.translatedMessage + error.error?.message);
          this.creating = false;
        }
      );
    }

  }
  //#endregion

  /**
   * Send emails to added testtakers to a remote project
   * @param navigateTo
   */
  sendEmailsToNewTesttakers(navigateTo) {
    let body: any = {project_id: this.project.id, content: this.emailContentHtml, language: this.translate.currentLang}

    // Add testtaker ids to body
    const newTesttakers = this.project.testtakers.filter(testtaker => !this.project.projectHasTestTakers.map(item => item.testtaker_id).includes(testtaker.id))
    body['testtakers_ids'] = newTesttakers.map(testtaker => testtaker.id);

    // Send emails with communication service
    this.projectsApi.sendMails(body).subscribe({
      next: () => {
        // Do Nothing
      },
      error: _err => {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.ERROR_EMAILS'));
      },
      complete: () => {
        this.route.navigate(navigateTo);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

