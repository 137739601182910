import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { ProjectType } from 'src/app/shared/enums/project-type.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { ScoringPsyuserFormularyTemplateComponent } from '../../../modal-templates/scoring-psyquestions-template/scoring-psyquestions-template.component';
import { ExcelCsvService } from 'src/app/shared/services/excel-csv.service';
import { ReportingService } from 'src/app/modules/projects/services/reporting.service';
import { ScoringService } from 'src/app/modules/projects/services/scoring.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { ReportSelectorTemplateComponent } from '../../../modal-templates/report-selector-template/report-selector-template.component';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { NonValidScoringTemplateComponent } from '../../../modal-templates/non-valid-scoring-template/non-valid-scoring-template.component';
import test from 'node:test';
@Component({
  selector: 'app-template-project-test',
  templateUrl: './template-project-test.component.html',
  styleUrls: ['./template-project-test.component.scss']
})
export class TemplateProjectTestComponent implements OnInit, OnDestroy {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Input() triggerName;
  @Input() refreshScoring = true;
  @Input() testNameClickable=false;
  @Input() onlyViewMode=false;
  @Input() isUserDetailView=false;

  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemStatusChanged = new EventEmitter<any>();
  @Output() itemTriggered = new EventEmitter<any>();

  @ViewChild('scoringPopupTemplate') scoringPopupRefPopup: ScoringPsyuserFormularyTemplateComponent;
  @ViewChild('reportingPopupTemplate') reportingPopupRefPopup: ReportSelectorTemplateComponent;
  @ViewChild('nonValidPopupTemplate') scoredNonValidRefPopup: NonValidScoringTemplateComponent;

  ProjectStatusString = ProjectStatusString;

  itemTests = [];
  loading = false;
  subProjectType = ProjectType;
  itemOptions: any;
  displayPanel = false;
  dialogItems = [];
  displayScoring = false;
  customerDemo: boolean = false;
  partialScoringCount = [];
  visiblePartialScoring = false;
  NonValidScoringCount = [];


  // Reporting popup
  projectTesttakerList = [];
  projectTestReportList = [];
  existServedReport = false;
  existNotServedReport = false;
  displayAnotherReportPopup = false;
  displayNonValidScoringPopup = false;
  onlyValidReportList = [];

  // Scoring popup
  needShowScoringPopup: boolean;
  displayScoringPopup: boolean;
  scoreDirectlyFromProjectDetailView: boolean;
  projectTestSharedQuestions;


  subs = new SubSink();

  constructor(
    private projectsSrv: ProjectsService,
    private reportingSrv: ReportingService,
    private scoringSrv: ScoringService,
    private excel_csvSrv: ExcelCsvService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private authenticationService:AuthenticationService,
    private router: Router,
    private projectsApi: ProjectsApi,
    private errorsSrv: ErrorsService

  ) { }

  ngOnInit(): void {
    /* if (this.item.status === ProjectStatusString.SCORED_NON_VALID){
      this.item.status = ProjectStatusString.SCORED
    } */
    this.subs.sink = this.authenticationService.sharedCurrentPsyUser.subscribe(async res=>{
      this.customerDemo = res?.customer?.demo;

      this.initMenuOptions();

      // Copy item tests
      this.itemTests = JSON.parse(JSON.stringify(this.item.tests));

      // Get the testtaker list for a project
      this.projectTesttakerList = [...this.item.testtakers];

      // Generate report status and structure
      this.projectTestReportList = await this.reportingSrv.getReportObjectByQuestionnaires(this.itemTests);

      // To know if at least one report is served or not
      this.existServedReport = this.reportingSrv.checkIfAtLeastOneReportIsServed([...this.projectTestReportList]);
      this.existNotServedReport = this.reportingSrv.checkIfAtLeastOneReportIsNotServed([...this.projectTestReportList]);

      this.subs.sink = this.translate.onLangChange.subscribe(() => {
        this.initMenuOptions();
      });

      this.countToScoring();
      this.countNonValid();
    });

    if(this.item.projectId){
      this.subs.sink = this.projectsApi.getProject(this.item.projectId).subscribe(res => {
        // Set status
        res.data.status = this.projectsSrv.getProjectStatusStringByCode(res.data.statusCode);
        // Set project supply type
        this.projectsSrv.setProjectType(res.data, [])
        this.item.projectType=res.data.projectType;

        this.initMenuOptions();
      });
    }
  }

  // TO-DO: Add readonly  mode for scored tests
  initMenuOptions(): void {
    if ([this.subProjectType.inMaSi, this.subProjectType.grMaSi,this.subProjectType.grMaMu, this.subProjectType.inMaMu].includes(this.item.projectType)) {
      this.getMenuOptionsInMaSiMu();
    }

    if ([this.subProjectType.inPrSi, this.subProjectType.inPrMu, this.subProjectType.inReSi, this.subProjectType.inReMu,
      this.subProjectType.grReSi, this.subProjectType.grReMu, this.subProjectType.inBlSi, this.subProjectType.inBlMu,
      this.subProjectType.grBlSi, this.subProjectType.grBlMu].includes(this.item.projectType)) {
      this.getMenuOptionsInPrSiPrMuReSiReMu();
    }
  }

  getMenuOptionsInMaSiMu(): void{
    this.subs.sink = this.translate.get('random.key').subscribe(() => {

      const aux = [];

      if (this.item.status === ProjectStatusString.READY_TO_SCORE) {
        this.addDownloadScoringTableMenu(aux);
      }

      if ([ProjectStatusString.SCORED, ProjectStatusString.REPORT_SERVED].includes(this.item.status)) {
        this.addProjectPurchasedReportsShortcuts(aux)
        this.addAnotherReportShortcut(aux)
        this.addDownloadScoringTableMenu(aux);
        if(!this.item.external){
          aux.push(this.getOptInsert());
        }
      }

      // Always insert delete option
      aux.push(this.getDeleteOpt());

      this.itemOptions = [{
        label: 'Options',
        items: aux
      }];
    });
  }

  getMenuOptionsInPrSiPrMuReSiReMu(): void{
    this.subs.sink = this.translate.get('random.key').subscribe(() => {

      const aux = [];

      if (this.item.status === ProjectStatusString.READY_TO_SCORE) {
        this.addDownloadScoringTableMenu(aux);
      }

      if ([ProjectStatusString.SCORED].includes(this.item.status)) {
        this.addProjectPurchasedReportsShortcuts(aux)
        this.addAnotherReportShortcut(aux)
        this.addDownloadScoringTableMenu(aux);
        if(!this.item.external){
          aux.push(this.getOptInsert());
        }
      }

      // Always insert delete option
      aux.push(this.getDeleteOpt());

      this.itemOptions = [{
        label: 'Options',
        items: aux
      }];
    });
  }

  // ----MENU OPTIONS---
  getDeleteOpt(): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-DELETE'),
      icon: 'pi pi-trash',
      command: () => {
        this.deleteItem();
      }
    };
  }

  getOptInsert(): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-ENTRY-MASK'),
      icon: 'pi pi-plus',
      command: () => {
        this.insert(false);
      }
    };
  }

  /**
   * Adds shortcut menu item to download the scoring table in Excel.
   * Only display if at least one report has been purchased
   */
   addDownloadScoringTableMenu(dotProjectChoices): any{

    //Show only if at least one report has been purchased
    // avoid report purchase??
    if((this.existServedReport || this.customerDemo)&&!this.isUserDetailView){

      let labelDownloadScoringTable = {
        label: this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING'),
        icon: 'pi pi-file-excel',
        command: () => {
          this.downloadScoringTable()
        }
      };

      dotProjectChoices.push(labelDownloadScoringTable)
    }
  }

  /**
   * Opens a report selector menu with report choices still
   * not purchased by the psyuser for this test
   *
   * Only displayed if other choices are available!
   * If no reports have already been purchased, do not show the option.
   */
  addAnotherReportShortcut(dotProjectChoices): any{

    //Show only if there are pending reports to be purchased. And at least one report is already purchased
    if(this.existServedReport && this.existNotServedReport){

      let labelGetAnotherReport = {
        label: this.translate.instant('REPORTS.MENU-GET-ANOTHER-REPORT'),
        icon: 'pi pi-cloud-download',
        command: () => {
          this.showAnotherReportMenuProject()
        }
      };
      dotProjectChoices.push(labelGetAnotherReport)
    }
  }

  /**
   * This function add to the three-dot menu in projects a link
   * to the reports already purchased to download them directly
   * @param dotProjectChoices
   */
  addProjectPurchasedReportsShortcuts(dotProjectChoices): any{
    if(this.existServedReport){
      this.projectTestReportList.forEach(_test => {

        let questionnaires = [];
        let labelText = _test.parentTest.test;

        _test.reports.forEach(_report => {
          questionnaires = questionnaires.concat(this.reportingSrv.getReportQuestionnaireServed(_report))
        });

        let labelReport = {
          label: labelText,
          icon: 'pi pi-cloud-download',
          command: () => {
            this.downloadPurchasedReports(questionnaires)
          }
        };
        dotProjectChoices.push(labelReport)
      })

    }
  }

  /**
   * Download report for this test
   * - If there are multiple reports available, shows the multireporting popup.
   * - If there is only a report available, and it has been purchased, start download directly.
   */
  getReportForTest() {
    if(this.reportingSrv.checkIfIsOnlyOneReportForProject([...this.projectTestReportList])) {
      this.downloadPurchasedReports(this.reportingSrv.getTestReportQuestionnaireServed([...this.projectTestReportList]));
    //In any other case show the another report popup.
    }else{
      this.showAnotherReportMenuProject();
    }
  }

  /**
   * Generate an Excel using test title as filename, and the scoring data
   * from the related questionnaires to generate the scoring table and
   * download it, showing a success toast, or an error toast if there was
   * a problem.
   */
  downloadScoringTable(){
    this.projectsSrv.downloadScoringTable(this.item).then(res => {
      if(res){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-SUCCESS')+this.item.code)
      } else {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
      }
    }).catch(err => {
      console.error(err);
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.MENU-DOWNLOAD-SCORING-ERROR'));
    })
  }


  countToScoring(){
    this.partialScoringCount = [];
    for (let questionnaire of this.itemTests){
      if (questionnaire.statusCode == QuestionnaireCodeStatus.COMPILED || questionnaire.statusCode == QuestionnaireCodeStatus.READY_TO_SCORE){
        this.partialScoringCount.push(questionnaire);
      }
    }
  }

  async manageScoringForTest(){
    if([this.subProjectType.inMaSi, this.subProjectType.inMaMu, this.subProjectType.inPrSi, this.subProjectType.inPrMu, this.subProjectType.inReSi, this.subProjectType.inReMu].includes(this.item.projectType)){
      this.insert(true);
      return;
    }

    try {
      const psyUserQuestionsForTest = await this.scoringSrv.checkPsyUserQuestions(this.partialScoringCount)

      if (psyUserQuestionsForTest.length === 0){
        this.partialScoring()
      } else {
        this.displayScoringPopup = true
      }
      this.projectTestSharedQuestions = psyUserQuestionsForTest;
    } catch {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-SCORING-COMMUNICATION-ERROR'));
    }
  }

  async partialScoring() {
    console.log("Scoring project from project view...");
    this.loading = true;

    // Check consistency of questionnaires
    const consistencyResult = await this.projectsSrv.checkQuestionnairesConsistency(this.partialScoringCount, this);
    if(!consistencyResult) {
      this.loading = false;
      return;
    }

    // Request Scoring
    try {

      const scoringResult = await this.projectsApi.requestScoringServiceV2(this.partialScoringCount.map(_quest => _quest.questionnaireCode));

      //Success
      this.loading = false;
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-SCORING-OK'));
      this.projectsSrv.setRequestUpdate(true);
      this.visiblePartialScoring = false;

    } catch (ex) {
      this.loading = false

      let errToShow=this.errorsSrv.getShowableMessageFromHttpCode(
        ex.message,
        {
          logErrorOnConsole:true,
          defaultMsgKey:"PROJECTS.API-SCORING-NO",
          customEnvironment:"SCORING.HTTP-CODES"
        }
      )
      this.toastSrv.showToastError(errToShow.translatedMessage);
      return;
    }
  }

  /**
   * Shows the popup of shared questions for psyuser for scoring
   */
  showPopupPsyuserSharedQuestionsForScoring() {
    this.displayScoringPopup = true;
  }

  /**
   * Method to manage the psyuser shared questions popup on success scoring close event.
   */
  onClosePopupPsyuserQuestionsForScoring(){
    this.displayScoringPopup = false;
    this.projectsSrv.setRequestUpdate(true)
  }

  /**
   * Method to manage the psyuser shared questions popup on close event.
   */
  onClosePopupPsyuserQuestionsForScoringFailed(){
    this.displayScoringPopup = false;
    this.projectsSrv.setRequestUpdate(true)
  }


  /**
 * Request to the server the selected report and download it
 * showing a message to inform about the result.
 * @param report
 */
  public async downloadPurchasedReports(questionnaires) {
    this.reportingSrv.downloadServedReports(this.item, questionnaires).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE ) {
        // Messages
        let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          res,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
          }
        )

        this.toastSrv.showToastSucess(messageToShow.translatedMessage);
      }
    }).catch(err => {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.ERROR_REPORT'));
      console.error(err)
    })

  }

  /**
   * Manages the popup used to select a report to purchase
   * or download directly if already purchased.
   *
   * This applies to a individual user or a group with
   * single or multiple test assigned
   */
  showAnotherReportMenuProject() {
    this.item.singleTestPurchase = true;
    if (this.NonValidScoringCount.length === 0){
      this.displayAnotherReportPopup = true;
    } else {
      this.displayNonValidScoringPopup = true
    }

  }

  /**
   * After closing the report menu upon report purchase
   * Close the popup and update the view
   */
  anotherReportMenuOnClose() {
    this.displayAnotherReportPopup = false;
  }

  /**
   * This method is to open the menu to select individually the psyuser entry mask.
   * @param isScoring
   */
  insert(isScoring: boolean): void {
    this.dialogItems = [];
    this.displayScoring = isScoring;
    if (this.itemTests.length > 1) {
      this.itemTests.forEach(element => {
        element.testtaker = this.projectTesttakerList.filter(x => x.id === element.testtaker_id)[0];
        element.testtaker.questionnaireCode = element.questionnaireCode;
        this.dialogItems.push(element);
      });
      this.displayPanel = true;
    } else {
      this.insertByItem(this.itemTests[0], isScoring);
    }
  }

  insertByItem(item, isScoring): void {
    this.projectsSrv.insertQCodeAndNavigateToAnswers(item.questionnaireCode, isScoring);
  }

  administer(): void{
    this.projectsSrv.administer(this.itemTests[0].questionnaireCode);
  }

  deleteItem(): void {
    this.itemDeleted.emit(this.item);
  }

  emmitTrigerredAction(item): void{
    this.itemTriggered.emit(item);
  }


  openProject($event): void {
    this.subs.sink = this.projectsApi.getProjects().subscribe(res => {
      this.projectsSrv.setCurrentProject(res.data.filter(x => x.id === $event.projectId)[0]);
      this.router.navigate(['projects', 'details']);
    });
  }

  goToDetailsView(): void {
    this.subs.sink = this.projectsApi.getProject(this.item.projectId).subscribe(project => {
        this.projectsSrv.setCurrentProject(this.item.project);
        this.projectsSrv.setCurrentTest(this.item.test);
        this.router.navigate(['projects', 'details']);
      });
  }

  countNonValid(){
    this.item.tests.forEach(x => {
      if (x.status_name === 'non-valid'){
        this.NonValidScoringCount.push(x)
      }
    });
  }

  closePopup(){
    this.displayNonValidScoringPopup = false;
  }

  getAllReports(){
    this.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(this.itemTests)
    this.displayNonValidScoringPopup = false;
    this.displayAnotherReportPopup = true;
  }

  async getOnlyValidReports(){
    this.itemTests.forEach(_quest => {
      if(_quest.statusCode != QuestionnaireCodeStatus.SCORED_NON_VALID){
        this.onlyValidReportList.push(_quest)
      }
    })
    this.projectTestReportList = await this.reportingSrv.getReportObjectByQuestionnaires(this.onlyValidReportList)
    this.displayNonValidScoringPopup = false;
    this.displayAnotherReportPopup = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
