<!-- ZENDESK BUTTON: we deactivated the button at the request from Italy, I leave a comment in case they want to activate it in a future.
<div  *ngIf="showZendeskButton">
    <app-zendesk-button class="p-d-flex p-jc-end zendeskButton" [embedded]="true"></app-zendesk-button>
</div> -->
<!-- Spinner -->
<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
    <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<div *ngIf="loaded">
    <app-empty-template *ngIf="originalListTest.length == 0" [text]="('EMPTY.GENERAL' | translate)">
    </app-empty-template>    

    <div *ngIf="originalListTest.length > 0">
        <!--FILTER-->
        <app-filter 
            #filter 
            *ngIf="!embedded"    
            (applyFilter)="applyFilter($event)"
            (resetFilter)="resetFilter($event)"
            [items]="originalListTest"
            [displayAllItemsSelector]="false"
            [searchKeys]="['parentTestName','title','testKey']"
            [filterByDropdowns]="['country','ambit','recipient','active']"
            [filterByNumberOfSubitems]="false"
            [filterByDateRange]="false"
            [filterByActiveInactive]="true"
            [sortTypes]="['az-name','za-name']"
            [catalogPage] = "true">
        </app-filter>        

        <!--Title-->
        <div class="p-d-flex p-jc-between p-ai-center p-mb-6" [style.margin-top]="(filter?.filterHeaderHeight-70)+'px'">
            <h1 *ngIf="!embedded">{{'CATALOG.TITLE'|translate}}</h1>
            <h2 *ngIf="embedded">{{'CATALOG.TITLE'|translate}}</h2>
            <button 
                *ngIf="embedded"
                pButton
                class="btn-mini3 p-mx-3" 
                [routerLink]="['/catalog']"
                label="{{'DASHBOARD.VIEW-CATALOG' | translate}}">
            </button>
        </div>        
        
        <!--Filter Count-->
        <div *ngIf="filterCount > -1" class="result-count">{{filter?.first.filteredCount + ' ' + ('FILTER.RESULT' | translate)}}</div>
        
        
        <div class="p-grid">
            <app-test-card *ngFor="let item of listTestChunk" [item]="item" class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-my-2">
            </app-test-card>
        </div>

        <!--No result filter-->
        <div class="p-grid no-result" *ngIf="listTestChunk.length == 0">
            <app-empty-template [text]="('FILTER.NO-RESULTS' | translate)" class="p-col-12 p-md-12 p-lg-12 p-xl-12" >
            </app-empty-template>
        </div>
        

        <!--PAGINATION-->
        <div class="paginatior">
            <p-paginator 
                #paginator
                *ngIf="!embedded"
                [rows]="rowsToDisplayDefault" 
                [totalRecords]="listTest.length" 
                [rowsPerPageOptions]="[4,8,16,50]"
                [showCurrentPageReport]="true" 
                currentPageReportTemplate = "{{ ('CATALOG.ROW-COUNT-1' | translate) + ' ' + topRow + ('CATALOG.ROW-COUNT-2' | translate) }}"
                (onPageChange)="paginate($event)">
            </p-paginator>
        </div>
    </div>
</div>