import { Component, Input, OnInit } from '@angular/core';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { ProjectType } from 'src/app/shared/enums/project-type.enum';
import { ProjectsActionsService } from '../../../services/projects-actions.service';

@Component({
  selector: 'app-project-actions',
  templateUrl: './project-actions.component.html',
  styleUrls: ['./project-actions.component.scss']
})
export class ProjectActionsComponent implements OnInit {

  @Input() project;

  subProjectType = ProjectType;
  ProjectStatusString = ProjectStatusString;
  menuOptions: any;

  constructor(
    public projectActionsSrv: ProjectsActionsService
  ) { }

  ngOnInit(): void {
    this.initMenuOptions();
  }

  /**
   * Depending on the type of project, the service returns the desired options
   */
  initMenuOptions(): void {

    // Individual Projects
    if (this.project.projectType === this.subProjectType.inMaSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInMaSi(this.project);
    }

    if ([this.subProjectType.inMaMu, this.subProjectType.inPrMu].includes(this.project.projectType)){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInMaMuAndInPrMu(this.project);
    }

    if (this.project.projectType === this.subProjectType.inPrSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInPrSi(this.project);
    }

    if (this.project.projectType === this.subProjectType.inReSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInReSi(this.project);
    }

    if (this.project.projectType === this.subProjectType.inReMu){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInReMu(this.project);
    }

    if (this.project.projectType === this.subProjectType.inBlSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInBlSi(this.project);
    }

    if (this.project.projectType === this.subProjectType.inBlMu){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsInBlMu(this.project);
    }

    // Group Projects
    if (this.project.projectType === this.subProjectType.grReMu){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsGrReMu(this.project);
    }

    if (this.project.projectType === this.subProjectType.grReSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsGrReSi(this.project);
    }

    if ([this.subProjectType.grMaSi, this.subProjectType.grMaMu].includes(this.project.projectType)){
      this.menuOptions = this.projectActionsSrv.getMenuOptions_GrMaSi_GrMaMu(this.project);
    }

    if (this.project.projectType === this.subProjectType.grBlMu){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsGrBlMu(this.project);
    }

    if (this.project.projectType === this.subProjectType.grBlSi){
      this.menuOptions = this.projectActionsSrv.getMenuOptionsGrBlSi(this.project);
    }
  }
}
