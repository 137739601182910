import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { CatalogApi } from 'src/app/modules/catalog/api/catalog.api';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { SubSink } from 'subsink';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-test-card',
  templateUrl: './test-card.component.html',
  styleUrls: ['./test-card.component.scss'],
  providers: [ToastService]
})
export class TestCardComponent implements OnInit, OnDestroy {

  @Input() item;
  displayDetailsPanel = false;
  showCredits: boolean;

  subs = new SubSink();
  availableReports = [];

  constructor(
    private authSrv: AuthenticationService,
    private projectsSrv: ProjectsService,
    private router: Router,
    private catalogApi: CatalogApi,
    private toastSrv: ToastService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {
      this.showCredits = dataUsr?.hasLegacyPlan;
    });
  }

  selectItem(item, $event): void {
    item.selected = $event.target.checked;
  }

  setUnsetFavorite(item, value): void{
    // this.catalogApi.setUnsetFavorite(item.id, true).subscribe(
    //   res => {
    //     if(value){
    //       this.toastSrv.showToastSucess(this.translate.instant('CATALOG.API-FAVORITE-OK').replace('${name}',item.title));
    //     } else {
    //       this.toastSrv.showToastSucess(this.translate.instant('CATALOG.API-UNFAVORITE-OK').replace('${name}',item.title));
    //     }
    //     item.favorite = value;
    //   },
    //   error => {
    //     console.log(error);
    //     this.toastSrv.showToastError(error);
    // });
  }

  displayDetails(item): void{
    this.displayDetailsPanel = true;
    this.availableReports = item.reports.filter(report => !report.discontinued)
  }

  assignTest(report): void {

    // Get the plans of the test
    this.catalogApi.getTestPlans(this.item.id).subscribe(res => {
      this.item.plans = res.data;

      // Set the first entryType by default
      const project = this.projectsSrv.generateNewProject(this.item.customEntryTypes[0]);
      this.item.selectedReport_id = [report.id];
      this.item.combinedName = `${this.item.parentTestName} - ${this.item.title} [${this.item.locale.toUpperCase()}]`
      project.questionnaires.push(this.item);
      this.projectsSrv.setCurrentProject(project);
      this.router.navigate(['projects', 'create']);
    }, (err) => {
      this.toastSrv.showToastError(this.translate.instant('CATALOG.ASSIGN-ERROR'))
    })

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
