import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Session } from 'src/app/shared/models/session-model';
import { PlanService } from 'src/app/shared/services/plan.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { SubSink } from 'subsink';
import { CoreApi } from '../api/core.api';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  currentSession = new BehaviorSubject<Session>(null);
  sharedCurrentSession = this.currentSession.asObservable();

  currentPsyUser = new BehaviorSubject<any>(null);
  sharedCurrentPsyUser = this.currentPsyUser.asObservable();

  private currentPsyUserCredits = new BehaviorSubject<number>(0);
  sharedCurrentPsyUserCredits = this.currentPsyUserCredits.asObservable();

  private currentPsyUserUses = new BehaviorSubject<number>(0);
  sharedCurrentPsyUserUses = this.currentPsyUserUses.asObservable();

  private scoringNotifications = new BehaviorSubject<any>(null);
  sharedScoringNotifications = this.scoringNotifications.asObservable();

  private reportingNotifications = new BehaviorSubject<any>(null);
  sharedReportingNotifications = this.reportingNotifications.asObservable();

  private downloadReportingNotifications = new BehaviorSubject<any>(null);
  sharedDownloadReportingNotifications = this.downloadReportingNotifications.asObservable();

  private changePassword = new BehaviorSubject<boolean>(false);
  sharedChangePassword = this.changePassword.asObservable();

  private changePsyUserCredits = new BehaviorSubject<number>(0);
  sharedChangePsyUserCredits = this.changePsyUserCredits.asObservable();

  private countryId = new BehaviorSubject<any>(null);
  sharedCountryId = this.countryId.asObservable();


  subs = new SubSink();

  private clientId = environment.clientId;
  private localStorageSrv;
  private STRG_KEY = 'currentUser';
  private STRG_KEY_CH_PWD = 'currentUserChPwd';

  private socketSrv: any;


  constructor(
    private coreApi: CoreApi,
    private planSrv: PlanService,
    private router: Router,
    private injector: Injector) {
    this.localStorageSrv = localStorage;
    this.currentSession.next(this.loadSessionData());
    const changePassword = localStorage.getItem(this.STRG_KEY_CH_PWD);
    if (changePassword) {
      try {
        this.setChangePassword(JSON.parse(changePassword));
      } catch (ex) {}
    }
  }

  setCurrentSession(session: Session): void {
    this.currentSession.next(session);
    this.localStorageSrv.setItem(this.STRG_KEY, JSON.stringify(session));
  }

  loadSessionData(): Session{
    const session = this.localStorageSrv.getItem(this.STRG_KEY);
    return (session && session !== 'undefined') ? <Session> JSON.parse(session) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession.value;
  }
  getSocket(): SocketService{
    this.socketSrv = this.injector.get(SocketService);
    return this.socketSrv;
  }
  removeCurrentSession(): void {
    this.localStorageSrv.clear();
    this.currentSession.next(null);
    this.socketSrv = this.injector.get(SocketService);
    this.socketSrv.disconnect();
  }

  getCurrentUser(): any {
    const session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  }

  isAuthenticated(): boolean {
    // Bypass change password
    if(this.changePassword.value){
      return false;
    }
    return (this.getCurrentToken() != null) ? true : false;
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return (session && session.accessToken) ? session.accessToken : null;
  }

  getCurrentRefreshToken(): string {
    const session = this.getCurrentSession();
    return (session && session.refreshToken) ? session.refreshToken : null;
  }

  refreshToken(): void {
    const session = this.getCurrentSession();
    const tokens = {
      grant_type: 'refresh_token',
      client_id: this.clientId,
      refresh_token: this.getCurrentRefreshToken()
    };
    this.subs.sink = this.coreApi.refresh(tokens).subscribe(
    res => {
      session.accessToken = res.data.accessToken;
      session.accessTokenExpiresAt = res.data.accessTokenExpiresAt;
      session.refreshToken = res.data.refreshToken;
      session.refreshTokenExpiresAt = res.data.refreshTokenExpiresAt;
      this.setCurrentSession(session);
      window.location.reload();
    },
    err => {
      if (err.error.message === 'Refresh token is not valid') {
        this.logout();
      }
    });
  }

  setCurrentPsyUser(user: any): void {
    if(JSON.stringify(user)!==JSON.stringify(this.currentPsyUser.value)){
      this.currentPsyUser.next(user);
      this.setCurrentCustomerId(user.customer_id);
      this.setCustomerName(user.customer.name);
      this.setCurrentPsyUserCountryId(user.customer.country_id);
    }
  }

  getCurrentPsyUser(): any {
    return this.currentPsyUser.value;
  }

  setCurrentPsyUserCredits(credits: number): void {
    this.currentPsyUserCredits.next(credits);
  }

  getCurrentPsyUserCredits(): number {
    return this.currentPsyUserCredits.value;
  }

  setCurrentPsyUserUses(uses: number): void {
    this.currentPsyUserUses.next(uses);
  }

  getCurrentPsyUserUses(): number {
    return this.currentPsyUserUses.value;
  }

  setScoringNotifications(item: any){
    this.scoringNotifications.next(item);
  }

  setReportingNotifications(item: any){
    this.reportingNotifications.next(item);
  }
  setDownloadReportingNotifications(item: any){
    this.downloadReportingNotifications.next(item);
  }

  setCurrentCustomerId(customerId: any): void {
    this.localStorageSrv.setItem('customerId', JSON.stringify(customerId));
  }

  setCustomerName(customerName: any): void {
    this.localStorageSrv.setItem('customerName', customerName);
  }

  setCurrentPsyUserCountryId(psyuserCountryId: any): any {
    this.countryId.next(psyuserCountryId);
    return this.localStorageSrv.setItem('countryPsyuserId', psyuserCountryId)
  }

  getCurrentCustomerId(): any {
    return this.localStorageSrv.getItem('customerId');
  }

  getCurrentCustomerName(): any {
    return this.localStorageSrv.getItem('customerName');
  }

  getCurrentPsyUserCountryId(): any{
    return this.localStorageSrv.getItem('countryPsyuserId')
  }

  /**
   * Updates the current psyuser credits amount asking server
   */
  async updatePsyuserCredits(){

    let psyuserCreditsUpdated = await this.getCurrentPsyUserCredits();

    const currentPsyUser = this.getCurrentPsyUser();
    currentPsyUser.numCredits = psyuserCreditsUpdated;
    this.setCurrentPsyUser(currentPsyUser);
  }

  /**
   *
   * @param psyuser Assign the plan types to psyUser
   * @returns
   */
  assignPlanTypes(psyuser): any {
    psyuser.hasLegacyPlan = psyuser.customerHasPlanHasPsyusers.filter(x =>
      this.planSrv.isPlanLegacy(x.customerHasPlan.plan) && x.isActive).length > 0 ? true : false;
    psyuser.hasUsesPlan = psyuser.customerHasPlanHasPsyusers.filter(x =>
      !this.planSrv.isPlanLegacy(x.customerHasPlan.plan) && x.isActive).length > 0 ? true : false;
    return psyuser;
  }

  setChangePassword(value: boolean){
    this.changePassword.next(value);
    this.localStorageSrv.setItem(this.STRG_KEY_CH_PWD, JSON.stringify(value));
  }

  setChangePsyUserCredits(): void {
    const flag = this.changePsyUserCredits.value + 1
    this.changePsyUserCredits.next(flag);
  }

  // To force deploy
  logout(): void{
    this.removeCurrentSession();
    this.router.navigate(['/login']);
    document.location.reload();
  }
}
