import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditsService {

  constructor() { }

  buyCredits(): void {
    window.open('https://www.giuntipsy.it/crediti-internet-test/', '_blank');
  }
}
