import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { languageItems } from './data/languages.options';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  availableLanguages = languageItems.data;
  selectedLanguage = {};
  private localStorage;
  private STRG_KEY = 'langUser';

  constructor(public translate: TranslateService, private messageSrv: MessageService) {
    // Get valid languages
    const languages = this.availableLanguages.map( x => x.code);
    let browserLang: string;
    this.localStorage = localStorage;

    // Set all languages
    translate.addLangs(languages);
    // Set default languade
    translate.setDefaultLang('en');

    try {
      // Check if user previously used other language
      browserLang = this.localStorage.getItem(this.STRG_KEY);

      // If not, set the default language of the browser
      if (browserLang === null || browserLang === undefined) {
        browserLang = translate.getBrowserLang();
        this.localStorage.setItem(this.STRG_KEY, browserLang);
      }
    } catch {
      browserLang = translate.getBrowserLang();
      this.localStorage.setItem(this.STRG_KEY, browserLang);
    }

    const reg = new RegExp(languages.join('|'));
    translate.use(browserLang.match(reg) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.selectedLanguage = this.availableLanguages.filter(x => x.code === this.translate.currentLang)[0];
  }

  changeLanguage(): void{
    const selectedLanguage = this.selectedLanguage['code'];

    this.translate.use(selectedLanguage);
    // Save the selected language in storage
    this.localStorage.setItem(this.STRG_KEY, selectedLanguage);
    this.messageSrv.clear('toast-error');
  }

}
