<div class="p-grid" *ngIf="loaded">
    <div class="detail-container p-col-12 p-lg-6 p-mx-auto">
        <div class="detail-data p-mr-lg-2">
            <h1>{{user.name}} {{user.surname}}</h1>
            <div class="Body-1">{{'DETAIL-PEOPLE.USER' | translate}} #{{user.friendlyId}}</div>
            <hr>
            <div class="detail-subtitle">{{'DETAIL-PEOPLE.INFO' | translate}}</div>
            <div class="detail-label">{{'DETAIL-PEOPLE.ADDED-IN' | translate}}: <span class="detail-data">{{user.createdAt | date: 'dd/MM/yyyy'}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.GENDER' | translate}}: <span class="detail-data">{{ 'SELECT-GENDER.' + (user.gender | uppercase) | translate}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.BIRTH-DATE' | translate}}: <span class="detail-data">{{user.birthdate | date: 'dd/MM/yyyy' }}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.EMAIL' | translate}}: <span class="detail-data">{{user.email}}</span></div>
            <hr>
            <div class="detail-subtitle">{{'DETAIL-PEOPLE.INFO-2' | translate}}</div>
            <div class="detail-label">{{'EDIT-PEOPLE.COUNTRY' | translate}}: <span class="detail-data">{{getCountryByCode(user.country_id)}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.NACIONALITY' | translate}}: <span class="detail-data">{{user.nationality}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.CITY' | translate}}: <span class="detail-data">{{user.city}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.ADDRESS' | translate}}: <span class="detail-data">{{user.address}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.PHONENUMBER' | translate}}: <span class="detail-data">{{user.phonenumber}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.EDUCATION' | translate}}: <span class="detail-data">{{getEducationByCode(user.education_id)}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.OCCUPATION' | translate}}: <span class="detail-data">{{getOccupationByCode(user.occupation_id)}}</span></div>
            <div class="detail-label">{{'DETAIL-PEOPLE.NOTES' | translate}}: <span class="detail-data">{{user.notes}}</span></div>

            <div class="btn-group p-d-flex p-jc-xl-between p-jc-lg-center">
                <button
                    type="button"
                    class="btn-primary p-mx-auto p-my-2"
                    pButton
                    (click)="assignTest()"
                    label="{{'DETAIL-PEOPLE.BTN-ASSIGN-TEST' | translate }}">
                </button>
                <button
                    type="button"
                    class="btn-secondary p-mx-auto p-my-2"
                    pButton
                    [routerLink]="['/people','edit']"
                    [queryParams]="{type: 'EDIT', from: 'detail', id: user.id}"
                    label="{{'DETAIL-PEOPLE.BTN-MODIFICATION' | translate }}">
                </button>
                <button
                    type="button"
                    class="btn-secondary p-mx-auto p-my-2"
                    pButton
                    (click)="confirmDelete()"
                    label="{{'DETAIL-PEOPLE.BTN-DELETE' | translate }}">
                </button>
            </div>
        </div>
    </div>
    <div class="groups-container p-col-12 p-lg-6 p-mx-auto">
        <div class="groups-data p-ml-lg-2" [@listAnimation]>
            <app-template-group-simple
                *ngFor="let item of user.testTakerGroups;"
                [item]="item"
                [optionsLeft]="'none'"
                [optionsRight]="'button'"
                [disableSelection]="true"
                (itemDeleted)="deleteGroup($event)">
            </app-template-group-simple>
        </div>
    </div>
</div>

<div *ngIf="questionnaires!==undefined && questionnaires.length > 0" class="questionnaires">
    <h2>{{ 'GENERAL.TEST' | translate }}</h2>
    <app-template-project-test
        *ngFor="let item of questionnaires.slice().reverse()"
        [refreshScoring]="false"
        [item]="item"
        (itemTriggered)="openProject($event)"
        [optionsLeft]="'none'"
        [optionsRight]="!item.isGroupal ? 'options' : 'none'"
        [testNameClickable]="true"
        (itemDeleted)="confirmDeleteTest($event)"
        [onlyViewMode]="item.isGroupal"
        [isUserDetailView]="true"
        [triggerName]="'VIEW-PROJECT'">
    </app-template-project-test>
</div>
