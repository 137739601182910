import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {}
};

@Component({
  selector: 'app-input-item',
  templateUrl: './input-item.component.html',
  styleUrls: ['./input-item.component.scss'],
  animations: [inOutAnimation]
})
export class InputItemComponent implements OnInit {

  @Input() item;
  @Input() maskEditable;
  @Input() itemForm: FormControl;
  @Input() editForm;
  @Input() labelItemType
  @Output() inputChange = new EventEmitter<any>();

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      // And we use NOOP_VALUE_ACCESSOR so WrappedInput don't do anything with NgControl
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }

  ngOnInit(): void {
    const tagName = this.item.customLabel === null ? this.item.itemId : this.item.customLabel;
    this.item.customLabel = tagName//this.breakTagName(tagName.toString());
  }

  jumpNext(itemId, max): void {
    if(parseInt(max) < 10) {
      this.inputChange.emit(itemId);
    }
  }

  breakTagName(tag: string): string {
    let result = '';
    for (let i = 0; i < tag.length; i++) {
      if (i !== 0 && i % 4 === 0) result += '<br>';
      result += tag[i];
    }
    return result;
  }
}