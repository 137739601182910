import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

declare let gtag:Function;

interface AnalyticsPageNamesMap
{
  //<page_path, page_title>
  [page_path : string]:string;
}

const reducedUrlsToTrack:AnalyticsPageNamesMap=
{
  '/dashboard':'dashboard',
  '/':'dashboard or login',
  '/login':'login',
  '/projects':'projects',
  '/people':'people',
  '/catalog':'catalog',
  '/help':'help',
  '/profile':'profile',
  '/admin':'admin',
}
const urlsToTrack:AnalyticsPageNamesMap =
{
  '/dashboard':'dashboard',
  '/':'dashboard or login',
  '/login':'login',
  '/projects':'projects',
  '/people':'people',
  '/catalog':'catalog',
  '/help':'help',
  '/profile':'profile',
  '/admin':'admin',

  '/profile/edit':'profile/edit',
  'admin/edit?type=NEW':'admin/edit?type=NEW',
  '/projects/details':'projects/details',
  '/people/edit?type=NEW':'people/edit?type=NEW'
}
const filterUrls=environment.filterUrlsGAnalytics;

@Injectable({
  providedIn: 'root'
})

export class GAnalyticsService {
  constructor(private router:Router)
  {
    
  }

  public startTracking=(():void=>{
    //This is used to track every page change from router
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      //Code to Track Page View on router NavigationEnd event.
      if(filterUrls)
      {
        if(event.urlAfterRedirects in urlsToTrack)
        {
          gtag('event', 'page_view', {
            page_title: urlsToTrack[event.urlAfterRedirects],
            page_path: event.urlAfterRedirects
          })
        } 
      }
      else
      {
          gtag('event', 'page_view', {
            page_title: event.urlAfterRedirects,
            page_path: event.urlAfterRedirects
          })
      }
    })
  })

  //Emit some custom event to google analytics
  public eventEmitter( eventName: string, eventCategory: string, 
    eventAction: string, eventLabel: string = null,  eventValue: number = null )
  { 
    gtag('event', eventName, 
    { 
      eventCategory: eventCategory, 
      eventLabel: eventLabel, 
      eventAction: eventAction, 
      eventValue: eventValue
    })
  }
  public pageViewEvent(page_path:string, page_title:string){
    gtag('event', 'page_view', {
      page_title: page_title,
      page_path: page_path
    })
  }
}
