import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileApi {
    private url = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    updatePsyUser(psyUserData): Observable<any>{
        return this.http.put(this.url + 'psy/', psyUserData);
    }

}
