<div *ngIf="loaded">
    <!-- **** we deactivated the button at the request from Italy, I leave a comment in case they want to activate it in a future. -->
    <!-- <div  *ngIf="showZendeskButton">
        <app-zendesk-button class="p-d-flex p-jc-end p-mt-2 p-mb-0" [embedded]="true"></app-zendesk-button>
    </div> -->

    <app-dashboard-header></app-dashboard-header>

    <app-projects-container [embedded]="true"></app-projects-container>

    <p-dialog header="{{'MAINTENANCE.HEADER' | translate}}" [(visible)]="showDialog" [style]="{width: '80vw'}" [draggable]="false" [closeOnEscape]="false" [closable]="false" [modal]="true">
        <div [innerHTML]="maintenanceMsg" class="maintenance-msg">
        </div>
        <ng-template pTemplate="footer">
            <button pButton (click)="closeDialog()" class="btn-primary" label="{{'MAINTENANCE.BTN-OK' | translate}}"></button>
        </ng-template>
    </p-dialog>
</div>