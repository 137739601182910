import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnswersProjectComponent } from './components/answers-project/answers-project.component';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { DetailProjectComponent } from './components/detail-project/detail-project.component';
import { ProjectsContainerComponent } from './components/projects-container/projects-container.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsContainerComponent
  },
  {
    path: 'details',
    component: DetailProjectComponent
  },
  {
    path: 'create',
    component: CreateProjectComponent
  },
  {
    path: 'answers',
    component: AnswersProjectComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
