import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  ReactiveFormConfig,
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { inOutAnimation } from "src/app/shared/animations/in-out-animation";
import { ToastService } from "src/app/shared/services/toast.service";
import { CoreApi } from "../api/core.api";
import { AuthenticationService } from "../authentication/authentication.service";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [inOutAnimation],
  providers: [ToastService],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  showPassword: Boolean = false;
  loading: Boolean = false;
  public isBlocked = false;
  private key = environment.key;
  subs = new SubSink();
  private token = "";
  private clientId = environment.clientId;
  public loginErrors = 0;
  mfa = false;
  secret = true;
  qr:String;
  mfaForm: UntypedFormGroup;
  opt:String;
  @ViewChild("captchaRef") input;
  constructor(
    public authSrv: AuthenticationService,
    private coreApi: CoreApi,
    public router: Router,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService
  ) {}

  ngOnInit(): void {

    // clear cache on init
    localStorage.clear();
    this.setValidationMessages();
    this.generateForm();

    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.loginForm.reset();
    });
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get("random.key").subscribe(() => {
      ReactiveFormConfig.set({
        validationMessage: {
          required: this.translate.instant("VALIDATIONS.REQUIRED"),
          email: this.translate.instant("VALIDATIONS.EMAIL"),
          numeric: this.translate.instant("VALIDATIONS.NUMERIC")
        },
      });
    });
  }

  generateForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [RxwebValidators.required(), RxwebValidators.email()]],
      password: ["", [RxwebValidators.required()]],
    });
    this.mfaForm = this.formBuilder.group({
      otp: ["", [RxwebValidators.required(), RxwebValidators.numeric()]]
    })
  }

  get email(): any {
    return this.loginForm.get("email");
  }

  get password(): any {
    return this.loginForm.get("password");
  }

  get otp(): any {
    return this.mfaForm.get("otp");
  }

  getKey() {
    return this.key;
  }

  login(): void {
    const captcha = {
      token: this.token,
    };
   
    if (this.loginErrors > 2 && !this.isBlocked){
      this.loading = true;
      this.coreApi.captcha(captcha).subscribe(
        (result) => {
          this.makeLoginCall();
        },
        (error_captcha) => {
          this.loading = false;
          this.input.reset();
          this.toastSrv.showToastError(
            this.translate.instant(`RESPONSES.ERROR-CAPTCHA`)
          );
        }
      );
    }else{
      this.makeLoginCall();
    }
  }

  resolved(captchaResponse: string) {
    this.token = captchaResponse;
  }

  makeLoginCall(){
    const user = {
      grant_type: "password",
      username: this.email.value,
      password: this.password.value,
      client_id: this.clientId,
    };
    this.loading = true;

    this.subs.sink = this.coreApi.login(user).subscribe(
      (res) => {     
        this.isBlocked = false;   
        this.loading = false;
        if (res.data.user && res.data.user.reset && !res.data.user.mfa) {

          // Set change password true
          this.authSrv.setChangePassword(true);
         
          this.authSrv.setCurrentSession({
            accessToken: res.data.accessToken,
            accessTokenExpiresAt: "",
            refreshToken: "",
            refreshTokenExpiresAt: "",
            user: res.data.user,
          });
          this.router.navigateByUrl("/change");
        } else if(res.data.user.mfa) {
          this.authSrv.setCurrentSession({
            accessToken: res.data.accessToken,
            accessTokenExpiresAt: "",
            refreshToken: "",
            refreshTokenExpiresAt: "",
            user: res.data.user,
          });
          if(res.data.user.secret) {
            //Ventana de OTP
            this.mfa = true;
          } else {
            //Mostrar QR y después pedir la OTP
            this.mfa = true;
            this.secret = false;
            this.qr = res.data.qr;
          }
        }else {
          this.authSrv.setCurrentSession(res.data);
          this.router.navigateByUrl("/"); // Redirect to home
        }
      },
      (error) => {
        if (error && error.error && error.error.message){
          try{  
            let mMessage = JSON.parse(error.error.message);
            if (mMessage.errors){
              this.loginErrors = mMessage.errors;
              let mBlocks = mMessage.blocks;
              if (this.loginErrors < 5){
                this.showError("UNAUTHORIZED");
              }else{
                this.isBlocked = true;
                if (mBlocks && mBlocks > 2){
                  this.showError("USER BLOCKED");
                }else{
                  this.showError("BLOCKED");
                }
              }
            }else{
              this.showError("UNAUTHORIZED");
            }
          }catch(err){
            this.showError(error.statusText);
          }
        }
      }
    );
  }

  showError(msg: string) {
    this.loading = false;
            this.toastSrv.showToastError(
              this.translate.instant(
                `RESPONSES.ERROR-${msg.toUpperCase()}`
              )
            );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  sendChallenge() {
    this.coreApi.getFirstQrAndChallenge("code").subscribe((res) => {
      if(res.isLogged) {
        this.authSrv.setCurrentSession(res.data);
        this.router.navigateByUrl("/"); // Redirect to home
      }
    });
  }

  askForChallenge() {
    let data = {
      grant_type: "password",
      otp: this.otp.value,
      username: this.email.value,
      password: this.password.value,
      client_id: this.clientId
    }
    
    this.coreApi.login(data).subscribe((res) => {
      if(res.data.validOTPChallenge) {
        if(!res.data.user.reset){
          this.authSrv.setCurrentSession(res.data);
          this.router.navigateByUrl("/"); // Redirect to home
        } else {
          this.authSrv.setCurrentSession(res.data);
          this.router.navigateByUrl("/change"); // Redirect to resetPassword
        }
      }
    },
    (error) => {
      if (error && error.error && error.error.message){
        try{  
          let mMessage = JSON.parse(error.error.message);
          if (mMessage.errors){
            this.loginErrors = mMessage.errors;
            let mBlocks = mMessage.blocks;
            if (this.loginErrors < 5){
              this.showError("UNAUTHORIZED");
            }else{
              this.isBlocked = true;
              if (mBlocks && mBlocks > 2){
                this.showError("USER BLOCKED");
              }else{
                this.showError("BLOCKED");
              }
            }
          }else{
            this.showError("UNAUTHORIZED");
          }
        }catch(err){
          this.showError(error.statusText);
        }

        this.authSrv.setCurrentSession(null);
        this.mfa = false;
      }
    });
  }
}
