import {ViewportScroller} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AccordionModule} from 'primeng/accordion';
import {HelpService} from '../services/help.service';
import {SubSink} from 'subsink';
import {AuthenticationService} from 'src/app/core/authentication/authentication.service';
import {LanguageService} from 'src/app/shared/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {

  activeState: boolean[] = [];

  showCredits: boolean;

  subs = new SubSink();

  quickGuideURL: string = environment.quickGuideURL;
  constructor(
    private translate: TranslateService,
    private viewPortScroller: ViewportScroller,
    private accordionModule: AccordionModule,
    private helpSrv: HelpService,
    private authSrv: AuthenticationService,
    public langSrv: LanguageService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.helpSrv.sharedSelectedHelp.subscribe(res => {
      if (res !== null) {
        this.toggle(res.position);
        setTimeout( () => {
          this.anchorTo(res.key);
        }, 500 );
        this.helpSrv.setSelectedHelp(null);
      }
    });

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {
      this.showCredits = dataUsr?.hasLegacyPlan;
    });
  }

  anchorTo(id: string): void{
    const elementXPosition = document.getElementById(id).offsetLeft;
    const elementYPosition = document.getElementById(id).offsetTop - 110; // Fot scroll the height of the navbar

    this.viewPortScroller.scrollToPosition([elementXPosition, elementYPosition]);
  }

  toggle(i: number): void {
    if (!this.activeState[i]) {
      this.activeState[i] = true;
    }
    // this.activeState[index] = !this.activeState[index];
  }

  getTranslationMod(translationKey){
    let text = this.translate.instant(translationKey);
    if (!this.showCredits){
      text = text.replace(new RegExp(this.translate.instant('HELP.CREDITS-KEY'), 'g'), this.translate.instant('HELP.USES-KEY'));
      text = text.replace(new RegExp(this.translate.instant('HELP.CREDIT-KEY'), 'g'), this.translate.instant('HELP.USE-KEY'));
    }
    return text;
  }

  /**
   * Gets the file name from the quick guide URL.
   */
  getQuickGuideFilename(): string {
    return this.quickGuideURL.split('/').pop();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
