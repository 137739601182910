import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { SharedElementsModule } from 'src/app/shared/shared-elements.module';
import { CoreModule } from 'src/app/core/core.module';
import { DetailProfileComponent } from './components/detail-profile/detail-profile.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';


@NgModule({
  declarations: [ProfileComponent, DetailProfileComponent, EditProfileComponent ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedElementsModule,
    CoreModule
  ]
})
export class ProfileModule { }
