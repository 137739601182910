<button pButton type="button" 
    class="warning"
    tooltipStyleClass="warning-tooltip"
    (click)="showDisplayZendesk()">
    
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-circle" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="#ff9300" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
    </svg>
    {{'ZENDESK.BTN-ZENDESK'|translate}}
</button>

<!-- PopUp: send request to Zendesk  -->
<p-dialog [(visible)]="displayZendeskPopup" [style]="{width: '65vw', height: '65vh'}" [modal]="true">
    <ng-template pTemplate="header">
        <h3 class="zendesk-title p-ml-3">{{'ZENDESK.POPUP-TITLE'| translate}}</h3>
    </ng-template>
    <ng-template pTemplate="content" class="custom-template">
        <p-dropdown
        [style]="{width: '100%'}"
            class="custom-dropdown zendesk-dropdown"
            placeholder = "Test" 
            [showClear]="true"
            [options]="discontinuedTests"  
            optionLabel="combinedName"
            [(ngModel)]="selectedTest"
            appendTo="{{displayInModal? 'body' : null}}">
        </p-dropdown>
        <input id="message" type="text" pInputText class="custom-input-text" [(ngModel)]="message" placeholder="{{'ZENDESK.ADD-COMMENT' | translate}}">
    </ng-template>

    <ng-template pTemplate="footer" class= "p-text-right p-my-3">
        <button pButton 
            (click)="createTicketForZendesk(message)"            
            label="{{'FILTER.BTN-SEND'| translate}}"
            class="btn-primary"
            [disabled]="!selectedTest">
        </button>
    </ng-template>   
</p-dialog>

