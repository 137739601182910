/**
 * Set Menu header options values
 */
export const headerMenuItems = {
    data : [
        {
            index: 1,
            title: 'HEADER.DASHBOARD',
            link: '/dashboard'
        },{
            index: 2,
            title: 'HEADER.PEOPLE',
            link: '/people'
        },{
            index: 3,
            title: 'HEADER.PROJECTS',
            link: '/projects'
        },{
            index: 4,
            title: 'HEADER.CATALOG',
            link: '/catalog'
        },{
            index: 5,
            title: 'HEADER.HELP',
            link: '/help'
        },
    ]
};


export const headerMenuItemsAssistant = {
    data : [
        {
            index: 1,
            title: 'HEADER.CATALOG',
            link: '/catalog'
        },{
            index: 2,
            title: 'HEADER.ACCOUNTS',
            link: '/admin'
        },{
            index: 3,
            title: 'HEADER.HELP',
            link: '/help'
        },
    ]
};