<!--GROUP TITLE-->
<h2>{{type === 'NEW' ? ('EDIT-GROUP.INFO-CREATE' | translate) : ('EDIT-GROUP.INFO-UPDATE' | translate)}}</h2>
<span class="p-float-label" *ngIf="group.name !== undefined">
    <div class="detail-label">{{'EDIT-GROUP.ADDED' | translate}} <span class="detail-data">{{group.createdAt | date: 'dd/MM/yyyy'}}</span></div>
</span>

<!--GROUP FORM-->
<form [formGroup]="editForm" *ngIf="loaded" class="groupForm">
    <h3>{{'EDIT-GROUP.INFO' | translate}}</h3>

    <div class="p-grid p-fluid">
        <!-- Input para el nombre del grupo -->
        <div class="p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="name" type="text" pInputText class="custom-input-text" formControlName="name">
                <label for="name"><span class="red_dot">*</span>{{'EDIT-GROUP.NAME' | translate}}</label>
                <span
                    *ngIf="name.invalid && name.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{name['errorMessage']}}
                </span>
            </span>
        </div>

        <!-- Import Button -->
        <div class="p-col-12 p-lg-6 p-md-12 p-text-right">
            <button
                pButton
                class="btn-primary btn-import"
                icon="pi pi-cloud-upload"
                iconPos="right"
                (click)="showImportUsersModal = true ">
                {{'EDIT-PEOPLE.IMPORT-USERS' | translate}}
            </button>
        </div>


        <!-- Input para las notas -->
        <div class="p-col-12 p-lg-6 p-md-12 p-my-3">
            <span class="p-float-label">
                <textarea
                    #inputDescriptionID
                    id="description"
                    [rows]="4"
                    [cols]="30"
                    class="custom-input-text custom-text-area"
                    formControlName="description"
                    pInputTextarea>
                </textarea>
                <label for="description">{{'EDIT-PEOPLE.NOTES' | translate}}</label>
                <span
                    *ngIf="description.invalid && description.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{description['errorMessage']}}
                </span>
            </span>
        </div>

        <!-- Botones de cancelar y crear/editar un grupo -->
        <div class="p-col-12 p-lg-6 p-md-12 p-my-3 p-d-flex p-ai-end p-jc-end">
            <button
                type="btn"
                class="btn-secondary p-mx-3"
                pButton
                label="{{'EDIT-GROUP.BTN-CANCEL' | translate}}"
                (click)="goToGroupList()">
            </button>
            <button
                type="submit"
                class="btn-primary"
                pButton
                label="{{ type == 'NEW' ? ('EDIT-GROUP.BTN-CREATE' | translate) : ('EDIT-GROUP.BTN-UPDATE' | translate) }}"
                (click)="saveChanges()"
                [disabled]="checkSaveDisable()">
            </button>
        </div>
    </div>
</form>

<!--USERS TITLE-->
<h3>{{'EDIT-GROUP.USERS' | translate}}</h3>

<!--USERS TABLE-->
<div class="main-container">
    <p-table
        [hidden]="!showTesttakersTable"
        #dataTable
        class="rounded-table"
        responsiveLayout="scroll"

        [value]="data"
        [lazy]="true"
        (onLazyLoad)="getDataLazy($event)"
        [totalRecords]="dataCount"

        [loading]="!dataLoaded"
        loadingIcon="pi pi-spin pi-spinner"

        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[5,10,20,50]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'TABLE.ROW-COUNT' | translate}}"

        dataKey="id"
        [(selection)]="selectedData"
        (selectionChange)="onSelect()"
        (onPage)="selectedData=[]; onSelect()">

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr class="primary-row">
          <th style="width: 1rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="name">
            <div>
              {{'PEOPLE.HEADER-NAME' | translate}}
              <p-sortIcon field="name"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="createdAt">
            <div>
              <i class="pi pi-envelope p-mr-3"></i>
              {{'PEOPLE.HEADER-EMAIL-CREATION' | translate}}
              <p-sortIcon field="createdAt"></p-sortIcon>
            </div>
          </th>
          <th></th>
        </tr>
      </ng-template>

      <!-- BODY -->
      <ng-template pTemplate="body" let-item>
        <tr class="primary-row pointer" [ngClass]="{'selected-row': isSelected(item)}">
          <td class="non-clickable">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
          <td class="p-d-flex p-ai-center user-card-avatar">
            <div *ngIf="item.name && item.surname; then thenTmp else elseTmp"></div>
            <ng-template #thenTmp>
                <p-avatar
                    label="{{item.name.charAt(0)}}"
                    class="p-mr-3"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
            <ng-template #elseTmp>
                <p-avatar
                    icon="pi pi-user"
                    class="p-mr-3"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
            <div class="p-d-flex p-flex-column">
                <div *ngIf="item.name && item.surname; then thenTemp else elseTemp"></div>
                <ng-template #thenTemp>
                    <span class="user-card-name">
                        {{item.name}} {{item.surname}}
                    </span>
                </ng-template>
                <ng-template #elseTemp>
                    <span class="user-card-name">
                        {{item.friendlyId}}
                    </span>
                </ng-template>
                <span class="user-card-id">
                    {{'PEOPLE.USER' | translate}} #{{item.friendlyId}}
                </span>
            </div>
          </td>
          <td>
            <div class="p-d-flex p-flex-column">
                <span class="user-card-email">{{item.email}}</span>
                <span class="user-card-created">
                    {{'PEOPLE.ADDED'| translate}} {{item.createdAt | date: 'dd/MM/yyyy'}}
                </span>
            </div>
          </td>
          <td class="p-text-right">
            <button
                type="button"
                class="btn-mini p-mr-3"
                pButton
                (click)="confirmDelete(item)"
                label="{{ 'DETAIL-PEOPLE.BTN-DELETE' | translate}}">
            </button>
          </td>
        </tr>
      </ng-template>

      <!--EMPTY MESSAGE-->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">
            <h3 class="p-text-center">{{'FILTER.NO-RESULTS' | translate}}</h3>
          </td>
        </tr>
      </ng-template>
    </p-table>
</div>

<!--FOOTER-->
<hr>
<div class="form-footer p-d-flex p-jc-start p-ai-center btn-footer">
    <span><span class="red_dot">*</span>{{'EDIT-GROUP.MANDATORY' | translate}}</span>
</div>

<!-- ADD BUTTON -->
<button
    *ngIf="showAddButton"
    type="button"
    class="btn-float btn-float-add p-shadow-3"
    pButton
    icon="pi pi-plus"
    iconPos="right"
    label="{{ 'EDIT-GROUP.ADD-USER' | translate | uppercase }}"
    (click)="openSelectorModal()">
</button>


<!--ACTION SIDEBAR-->
<p-sidebar
    [(visible)]="showBottomMenu"
    position="bottom"
    [baseZIndex]="10000"
    class="sidebar-action sidebar-action-table"
    [dismissible]="false"
    [modal]="false"
    (onHide)="selectedData = []">
   <div>
        <button
            type="button"
            class="btn-secondary p-mx-3"
            pButton
            (click)="confirmDelete()"
            label="{{'EDIT-GROUP.DELETE-SELECTED' | translate}}">
        </button>
   </div>
</p-sidebar>

<!--BLOCKER-->
<p-blockUI [blocked]="showBlockUi"></p-blockUI>

<!--TESTTAKERS SELECTOR-->
<app-testtaker-group-selector #testtakerGroupSelector [itemRef]="group.id" [parentDataCount]="dataCount" [type]="'user'" (selectedDataEvent)="loadSelectedDataModal($event)"></app-testtaker-group-selector>

<!-- IMPORT CSV MODAL -->
<p-dialog [(visible)]="showImportUsersModal" [style]="{width: '70vw', height: '50vw'}"  [modal]="true" class="import-modal" (onHide)="clearFileUpload()">
    <div class="import-content">
        <h3 class="p-text-center">{{'EDIT-PEOPLE.IMPORT-USERS-EXTENDED' | translate}}</h3>
        <div class="p-grid p-m-0">
            <!-- UPLOAD FILE SELECTOR -->
            <div class="p-d-flex p-flex-column p-ai-center p-col p-mx-4">
                <h4>{{'EDIT-PEOPLE.IMPORT-SELECT-FILE' | translate}}</h4>
                <p-fileUpload
                    #fileUpload
                    accept=".csv"
                    customUpload="true"
                    class="btn-float p-shadow-3"
                    mode="basic"
                    (onSelect)="updateCSVTesttakerList($event)"
                    (uploadHandler)="updateCSVTesttakerList($event)"
                    [disabled]= "parseTotalErrorsFound > 0 || showPreview"
                    chooseLabel="{{'EDIT-PEOPLE.IMPORT-CHOOSE' | translate}}"
                    cancelLabel="{{'EDIT-PEOPLE.IMPORT-CANCEL' | translate}}" >
                </p-fileUpload>
            </div>
            <!-- SEPARATOR CHARACTER SELECTOR -->
            <div class="parser-content p-col p-mx-4">
                <h4>{{'EDIT-PEOPLE.IMPORT-SELECT-SEPARATOR' | translate}}</h4>
                <p-dropdown
                    [options]="parserOptions"
                    class="custom-dropdown p-shadow-3"
                    filterBy="code"
                    optionLabel="name"
                    (onChange)="reloadListIfFileSelected()"
                    [(ngModel)]="selectedParser"
                    placeholder="&nbsp;" >
                </p-dropdown>
            </div>
        </div>
        <br>

        <!-- ERROR/WARNING/WRONGDELIMITIER COUNT MESSAGES -->
        <div class="p-text-center p-ai-center" *ngIf="showPreview">
            <div *ngIf="parseTotalErrorsFound > 0 && parseTotalWarningsFound > 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-errors">{{parseTotalErrorsFound}}</span> {{'EDIT-PEOPLE.IMPORT-SEVERE-ERRORS' | translate}}, {{'EDIT-PEOPLE.IMPORT-AND-TEXT' | translate}} <span class="text-warnings">{{parseTotalWarningsFound}}</span> {{'EDIT-PEOPLE.IMPORT-WARNINGS-IN-TOTAL' | translate}}.
            </div>

            <div *ngIf="parseTotalErrorsFound > 0 && parseTotalWarningsFound == 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-errors">{{parseTotalErrorsFound}}</span> {{'EDIT-PEOPLE.IMPORT-SEVERE-ERRORS' | translate}}.
            </div>

            <div *ngIf="parseTotalErrorsFound == 0 && parseTotalWarningsFound > 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-warnings">{{parseTotalWarningsFound}}</span> {{'EDIT-PEOPLE.IMPORT-WARNINGS-IN-TOTAL' | translate}}.
            </div>

            <div *ngIf="wrongDelimiterCase && usersToSave.length > 0" class="text-warnings " >
                <br>
                {{'EDIT-PEOPLE.IMPORT-WRONG-DELIMITIER' | translate}}
                    <br>
            </div>

            <div *ngIf="parseTotalErrorsFound == 0 && usersToSave.length > 0" class="text-ready-to-upload">
                {{'EDIT-PEOPLE.IMPORT-READY-TO-UPLOAD' | translate}}
            </div>

            <div *ngIf="usersToSave.length == 0" class="text-regular">
                <span class="text-warnings">{{'EDIT-PEOPLE.NO-USERS-TO-IMPORT' | translate}} </span>
            </div>
        </div>
        <br>

        <!-- CANCEL IMPORT / UPLOAD USERS Buttons -->
        <div class="p-text-center p-ai-center p-mb-3" *ngIf="showPreview">
                <button
                    pButton
                    type="button"
                    class="p-button p-button-info btn-secondary"
                    (click)="clearFileUpload()"
                    [disabled]="loading"
                    label="{{'EDIT-PEOPLE.IMPORT-CANCEL-UPLOAD' | translate}}">
                </button>

                <button
                    *ngIf="!showTooBigToImport"
                    pButton
                    type="button"
                    class="p-button p-button-success upload-button btn-primary"
                    (click)="uploadTesttakerSavedListAndAddToGroupPreview()"
                    label="{{'EDIT-PEOPLE.IMPORT-UPLOAD-USERS' | translate}}"
                    [disabled]="parseTotalErrorsFound > 0">
                    <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
                    </p-progressSpinner>
                </button>
        </div>

        <div class="p-text-center" *ngIf="showSpinner">
            <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
        </div>

        <!-- USERS TO IMPORT LIST -->
        <div class="top import-users" *ngIf="showPreview && !showTooBigToImport">
            <div class="imported-users-type-container">
                <p class="imported-users-type" >{{'EDIT-PEOPLE.IMPORT-NEW-USERS' | translate}}</p>
            </div>

            <app-template-user-simple
                *ngFor="let item of usersToSave"
                [item]="item"
                [optionsRight]="'button'"
                [importingCSVTesttakers]="true"
                (itemDeleted)="deleteNewUserFromImported($event)"
                (itemSelected)="selectUserCSV($event)">
            </app-template-user-simple>
        </div>
        <div class="top import-users" *ngIf="showPreview && !showTooBigToImport">
            <div class="imported-users-type-container">
                <p class="imported-users-type" >{{'EDIT-PEOPLE.IMPORT-EXISTING-USERS' | translate}}</p>
            </div>
            <app-template-user-simple
                *ngFor="let item of usersToAddToGroupWithoutCreating"
                [item]="item"
                [optionsRight]="'button'"
                [importingCSVTesttakers]="true"
                (itemDeleted)="deleteExistingUserFromImported($event)"
                (itemSelected)="selectUserCSV($event)">
            </app-template-user-simple>
            <app-template-user-simple
                *ngFor="let item of usersAlreadySavedInGroup"
                [item]="item"
                [importingCSVTesttakers]="true">
            </app-template-user-simple>
        </div>
    </div>
    <p-footer>
        <div class="p-text-center download-example">
            <a class="btn-link pointer" href="assets/examples/ImportsUsersExample.csv">{{'EDIT-PEOPLE.IMPORT-EXAMPLE' | translate}}</a>
        </div>
    </p-footer>
</p-dialog>