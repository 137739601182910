import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private currentStatusHistory = new BehaviorSubject<any>(null);
  sharedStatusHistory = this.currentStatusHistory.asObservable();

  constructor() { }

  setStatusHistory(item){
    this.currentStatusHistory.next(item);
  }
}
