<container-element [ngSwitch]="mode" *ngIf="info" >
    <p-accordion  *ngSwitchCase="'accordion'"  [expandIcon]="'pi pi-info-circle'" [collapseIcon]="'pi pi-info-circle'"  >
        <p-accordionTab header="{{title}}" [selected]="open">
            <div class="body" [innerHTML]="info"></div>
        </p-accordionTab>   
    </p-accordion>    
    <div *ngSwitchCase="'slim'" class="slim">
        <div class="body" [innerHTML]="info"></div>
    </div>   
</container-element>
