<div *ngIf="loaded">
    <div class="p-grid header">
        <div class="grow-2">
            <app-project-data-component [groupalProject]="userStatus.length > 1" [projectId]="project.id" (sendEmailEvent)="searchUnfinishedTestUsers()" (deleteProjectEvent)="confirmDeleteProject()" (addUsers)="addUsers()"></app-project-data-component>
        </div>

        <div class="grow-1">
            <app-chart-component #chartComponent [projectId]="project.id"></app-chart-component>

            <div class="p-mt-5 p-d-flex p-jc-end">
                <button
                    *ngIf="project.status != ProjectStatusString.ASSIGNED"
                    pButton
                    type="button"
                    class="btn-primary btn-icon"
                    icon="pi pi-file-excel"
                    iconPos="right"
                    (click)="getScoringExcel()"
                    label="{{'PROJECTS.BTN-SCORING-TABLE'|translate}}">
                </button>
            </div>
        </div>
    </div>

    <!--SPECIFIC OPTIONS-->
    <div class="p-d-flex p-jc-end">
        <div *ngIf="visibleBlindAdministration" class="p-ml-3 p-d-flex p-ai-center">
            <!--Blind Administration-->
            <button
                pButton
                type="button"
                class="btn-primary btn-icon"
                (click)="getBlindAministrationData()"
                label="{{'PROJECTS.BTN-BLIND-ADMINISTRATION'|translate}}"
                icon="pi pi-file-excel"
                iconPos="right"
                [disabled]="loadingPartRep">
            </button>
        </div>
        <div *ngIf="visiblePartialScoring" class="p-ml-3 p-d-flex p-ai-center">
            <!--Partial Scoring-->
            <button
                type="button"
                class="btn-primary"
                pButton
                (click)="manageScoringForTest()"
                label="{{'PROJECTS.BTN-PARTIAL-SCORING'|translate}}"
                [disabled]="loadingPartSco">
            </button>
            <p-progressSpinner class="p-ml-2 p-mt-2" *ngIf="loadingPartSco" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
            </p-progressSpinner>
        </div>
        <div *ngIf="visiblePartialReporting" class="p-ml-3 p-d-flex p-ai-center">
            <!--Partial Reporting-->
            <button
                type="button"
                class="btn-primary"
                pButton
                (click)="showAnotherReportMenuProject()"
                label="{{'PROJECTS.BTN-PARTIAL-REPORTING'|translate}}"
                [disabled]="loadingPartRep">
            </button>
            <p-progressSpinner class="p-ml-2 p-mt-2" *ngIf="loadingPartRep" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
            </p-progressSpinner>
        </div>
    </div>

    <p-tabView class="tabs" (onChange)="changeTab()">

        <!--PEOPLE PANEL-->
        <!-- With cache true, the tab-panel reloads, but app-template-project-user have not the api call inside -->
        <p-tabPanel header="{{'PROJECTS.PEOPLE' | translate}} ({{numberOfPeople}})" [cache]="false">
            <!-- <app-template-project-user-container > -->
            <!-- this component makes api call and shows each "app-template-project-user" as row -->

            <!--FILTER-->
            <app-filter
                #filter
                *ngIf="!embedded"
                (allItemsSelected)="selectAllItems($event)"
                (applyFilter)="applyFilter($event)"
                (resetFilter)="resetFilter($event)"
                [items]="originalUserStatus"
                [subsItemsFriendlyName]="'USERS'"
                [searchKeys]="['name','surname','testtakerFriendlyId','email', 'completeName']"
                [filterByDropdowns]="['status_id']"
                [filterByNumberOfSubitems]="false"
                [usersProject]="true"
                [sortTypes]="['desc-code','asc-code','az-name','za-name']">
            </app-filter>

            <div *ngFor="let item of userStatusChunk">
                <app-template-project-user
                    *ngIf="item.hide!==true"
                    [item]="item"
                    [entryType]="project.entryType_id"
                    [disconnected]="communicationDisconnected"
                    (itemDeleted)="confirmDeleteUser($event)"
                    (itemStatusChanged)="itemStatusChanged($event)"
                    (sendMail)="displayCustomEmail = true; testtakerToSend_id = $event; sendFromList = false"
                    [optionsLeft]="'none'"
                    [optionsRight]="'options'"
                    [emailStatusRetrieved]="firstIterationStatusEmail">
                </app-template-project-user>
            </div>

            <div class="paginator">
                <p-paginator
                    #paginator2
                    [rows]="rowTests"
                    [totalRecords]="userStatus.length"
                    [rowsPerPageOptions]="[5,10,20,50]"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate = "{{ ('CATALOG.ROW-COUNT-1' | translate) + rowTests + ('CATALOG.ROW-COUNT-2' | translate) }}"
                    (onPageChange)="paginateTestsAndUsers($event)">
                </p-paginator>
            </div>
        </p-tabPanel>

        <!--TESTS PANEL-->
        <!-- With cache true, the tab-panel reloads, but app-template-project-test have not the api call inside -->
        <p-tabPanel header="{{'PROJECTS.TESTS' | translate}} ({{numberOfTests}})" [cache]="false">
            <!-- <app-template-project-test-container > -->
            <!-- this component makes api call and shows each "app-template-project-test" as row -->
            <ng-template pTemplate="content">
                <app-template-project-test
                *ngFor="let item of testStatusChunk"
                [item]="item"
                (itemDeleted)="confirmDeleteTest($event)"
                (itemStatusChanged)="itemStatusChanged($event)"
                [optionsLeft]="'none'"
                [testNameClickable]="false"
                [optionsRight]="'options'">
                </app-template-project-test>
                <div class="paginator">
                    <p-paginator
                        #paginator
                        [rows]="rowTests"
                        [totalRecords]="testStatus.length"
                        [rowsPerPageOptions]="[5,10,20,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate = "{{ ('CATALOG.ROW-COUNT-1' | translate) + rowTests + ('CATALOG.ROW-COUNT-2' | translate) }}"
                        (onPageChange)="paginateTestsAndUsers($event)">
                    </p-paginator>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>

    <!--POP-UP CHANGE NAME-->
    <p-dialog [(visible)]="displayChangeName" [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true" class="project-name-dialog" (onShow)="prevName=project.code">
        <div class="project-dialog-content">
            <span class="p-float-label">
                <input id="projectName" type="text" pInputText class="custom-input-text" [(ngModel)]="project.code">
                <label for="projectName">{{'CREATE-PROJECT.NAME'|translate}}</label>
            </span>
        </div>
        <ng-template pTemplate="footer">
            <button
                type="button"
                class="btn-secondary p-mx-1"
                pButton
                (click)="displayChangeName=false; project.code=prevName;"
                label="{{'CREATE-PROJECT.BTN-CANCEL'|translate}}">
            </button>
            <button
                type="button"
                class="btn-primary p-mx-1"
                pButton
                (click)="displayChangeName=false; changeName();"
                label="{{'CREATE-PROJECT.BTN-OK'|translate}}">
            </button>
        </ng-template>
    </p-dialog>

    <!-- POP-UP SELECT USERS TO SEND MAIL -->
    <p-dialog [(visible)]="displaySelectUsers" [style]="{width: '70vw'}" [modal]="true" class="template-dialog">
        <div class="template-dialog">
            <h3>{{'PROJECTS.SELECT_USERS_TO_SEND_EMAIL'|translate}}</h3>
            <div class="listUsers">
                <app-template-user-simple
                    *ngFor="let item of unfinishedTestUsers"
                    [item]="item"
                    [optionsLeft]="'checkbox'"
                    [optionsRight]="'none'"
                    [from]="'detail'">
                </app-template-user-simple>
            </div>
             <div class="paginator">
                <p-paginator
                    #paginator2
                    [rows]="rowTests"
                    [totalRecords]="userStatus.length"
                    [rowsPerPageOptions]="[5,10,20,50]"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate = "{{ ('CATALOG.ROW-COUNT-1' | translate) + rowTests + ('CATALOG.ROW-COUNT-2' | translate) }}"
                    (onPageChange)="paginateUsers($event)">
                </p-paginator>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button
                pButton
                (click)="displaySelectUsers=false"
                label="{{'EDIT-GROUP.BTN-CANCEL' | translate}}"
                class="btn-secondary">
            </button>
            <button
                pButton
                (click)="displayCustomEmail = true; displaySelectUsers=false; sendFromList = true"
                label="{{'CREATE-PROJECT.BTN-SEND' | translate}}"
                class="btn-primary">
            </button>
        </ng-template>
    </p-dialog>

    <!-- POP-UP SEND MAILS-->
    <p-dialog [(visible)]="displayCustomEmail" [modal]="true"[style]="{height: '480px'}" class="email-template">
        <div>
            <h3>Email: </h3>
            <app-custom-email (htmlChanged)="_emailContent = $event"></app-custom-email>
            <div class="center top">
                <button
                    pButton
                    (click)="sendMails(); this.displayCustomEmail = false"
                    label="{{'CREATE-PROJECT.BTN-SEND' | translate}}"
                    class="btn-primary">
                </button>
            </div>
        </div>
    </p-dialog>
</div>

<!-- POP-UP: Scoring with Psyuser questions popup-->
<p-dialog [(visible)]="displayScoringPopup" [style]="{width: '80vw'}" [modal]="true" class="template-dialog-scoring">
    <ng-template pTemplate="header">
        <h3 class="p-ai-center p-text-center scoring-title">Scoring Menu</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <app-scoring-psyquestions-template
            #scoringPopupTemplate
            [psyUserQuestionsForTest]="projectTestSharedQuestions"
            [relatedTestQuestionnaires]="this.partialScoringCount"
            (scoringSuccessfull)="onClosePopupPsyuserQuestionsForScoring()"
            (scoringFailed)="onClosePopupPsyuserQuestionsForScoring()"
            [contentStyle]="{'overflow':'visible'}">
        </app-scoring-psyquestions-template>
    </ng-template>

    <ng-template pTemplate="footer" class= "p-text-right p-my-3">
        <button pButton
            (click)="scoringPopupRefPopup.saveQuestionnairesAndScore()"
            label="Save & Score"
            class="btn-primary"
            [disabled]="scoringPopupRefPopup?.scoreButtonDisabled">
        </button>
    </ng-template>
</p-dialog>


<!-- POP-UP: Get another report popup-->
<p-dialog [(visible)]="displayAnotherReportPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report" (onHide)="reportingPopupRefPopup.clearPreviousSelected()">
    <div class="dialog-content">
        <app-report-selector-template
            #reportingPopupTemplate
            [projectData]="partialReportingCount"
            [testAndReportList]="projectTestReportList"
            [testtakerList]="project.projectHasTestTakers"
            (reportPurchased)="displayAnotherReportPopup = false;"
            (visiblePartialReporting)="isVisiblePartialReporting($event)"
            (requestedReports)="changeTestStatusByReportingRequest($event)"
            [partialReport]="true"
            *ngIf="projectTestReportList.length > 0">
        </app-report-selector-template>
    </div>
</p-dialog>

<!-- POP-UP: Scoring no valid popup-->
<div *ngIf="loadingScoredNonValid">
    <p-dialog [(visible)]="displayNonValidScoringPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report">
        <div class="dialog-content">
                <app-non-valid-scoring-template
                    #scoredNonValidPopupTemplate
                    [itemTests] = "partialReportingCount">
                </app-non-valid-scoring-template>
        </div>
        <ng-template pTemplate="footer">
            <span class="p-d-flex p-jc-between">
                <button
                    pButton
                    label="{{'REPORTS.BTN-PURCHASE-ALL' | translate}}"
                    (click)="getAllReports()"
                    class="btn-primary">
                </button>
                <button *ngIf="partialReportingCount.length > 1"
                    pButton
                    label="{{'REPORTS.BTN-PURCHASE-ONLY-VALID' | translate}}"
                    (click)="getOnlyValidReports()"
                    class="btn-primary">
                </button>
                <button
                    pButton
                    label="{{'REPORTS.BTN-CANCEL' | translate}}"
                    (click)="displayNonValidScoringPopup = false;"
                    class="btn-primary">
                </button>
            </span>
        </ng-template>
    </p-dialog>
</div>