import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AdminApi {
    private apiUrl = environment.apiUrl;
    private backofficeUrl = environment.apiBackOfficeURL;
    private plansUrl = environment.planUrl;
    private clientName = environment.clientName;

    constructor(
        private http: HttpClient) {}

    getCustomerData(id): Observable<any>{
        return this.http.get(this.apiUrl + 'psy/customer/' + id);
    }

    updatePsyUser(psyuser, id): Observable<any>{
        return this.http.put(this.backofficeUrl + 'psyUser/' + id, psyuser);
    }

    changeEmail(psyUser, body){
        return this.http.put(this.backofficeUrl + 'psyUser/changeEmail/' + psyUser.id, body);
    }
    
    createPsyUser(psyuser): Observable<any>{
        return this.http.post(this.backofficeUrl + 'psyUserWithAG', psyuser);
    }

    deletePsyUser(userId): Observable<any> {
        return this.http.delete(this.backofficeUrl + 'psyUser/' + userId);
    }

    deassignPsyuserFromPlan(customerHasPlanHasPsyusers_id): Observable<any>{
        return this.http.delete(this.plansUrl + 'subscription/deassign/' + customerHasPlanHasPsyusers_id);
    }


    assignPsyuserToPlan(custHasPlan_id, psyuser_id): Observable<any>{
        return this.http.post(this.plansUrl + 'subscription/assign/' + custHasPlan_id, {
            "psyusers": [psyuser_id]
        });
    }

    legacyControl(numCredits, adminPsyuserId, psyuserId, customerId, tempPlan){

        const _body = {
            credits: numCredits,
            consumptionType: 'TRANSFER',
            user_id: adminPsyuserId, // the admin psyuser making the change [action_user_id]
            psyuser_id: psyuserId,
            demo: tempPlan.demo,
            customerHasPlan_id: tempPlan.sub_id,
            customer_id: customerId,
            clientName: this.clientName
        }
        
        return this.http.post(this.backofficeUrl + 'credits/' + psyuserId , _body);        
    }

    getCustomerPlans(customerId): Observable<any> {
        return this.http.get(this.plansUrl + 'subscriptions/checkStatus/' + customerId);
    }

    getCustomerBalance(customerId, onlyAlivePlans: boolean = true): Observable<any> {
        return this.http.post(this.plansUrl + 'historyStats/' + customerId , { onlyAlivePlans });
    }

    getPsyuserSubscriptions(psyuserId): Observable<any> {
        return this.http.get(this.plansUrl + 'subscriptions/status/' + psyuserId);
    }

    getPsyuserHistorySubscriptions(psyuserId): Observable<any> {
        return this.http.get(this.plansUrl + 'subscriptions/history/' + psyuserId + '/psyuser');
    }

    getHistorySubscriptions(customerHasPlanId): Observable<any> {
        return this.http.get(this.plansUrl + 'subscriptions/history/' + customerHasPlanId);
    }

    getPlanTests(planId): Observable<any> {
        return this.http.get(this.plansUrl + 'plan/' + planId + '/tests');
    }

    getCustomerPsyUsers(customerId): Observable<any> {
        return this.http.get(this.backofficeUrl + 'customers/' + customerId + '/psyusers');
    }
}