<!-- LOADING SPINNER AND ERROR MESSAGES POPUP -->
<div *ngIf="scoringSpinner" class="p-d-flex p-ai-center p-text-center scoring-spinner">
    <div *ngIf="scoringSpinner" class="p-d-flex p-ai-center">
        <p-progressSpinner  [style]="{width: '20vh', height: '20vh'}" strokeWidth="4" class="custom-spinner spinner-margin" animationDuration="2s"></p-progressSpinner>
        
        <span class="p-ml-3">{{messageLoading}}</span>
    </div>
</div>


<div class="dialog-scoring-content" *ngIf="!scoringSpinner">

    <!--TOAST ELEMENTS-->
    <p-toast position="top-center" key="toast-error-dialog" preventOpenDuplicates="true" class="toast-dialog">
        <ng-template let-message pTemplate="message">
            <div class="p-d-flex p-ai-center p-jc-center toast-content">
                <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>            
            </div>
        </ng-template>
    </p-toast>
    <div class="p-fluid" [formGroup]="psyUserForm">

        <div *ngFor= "let psyQuestions of psyUserQuestionsForTest">
            <h3><span><strong>{{psyQuestions.testName}}</strong></span> </h3>
            <h5>{{'PROJECTS.API-SCORING-POPUP-SUBTITLE' | translate}}</h5>
            <!--Test psyuser questions at scoringReportingData-->
            <div  *ngFor="let item of psyQuestions.scoringReportingQuestions" class="p-field">
                <app-input 
                    [inputForm]="item" 
                    [myFormGroup]="psyUserForm" 
                    [maskEditable]="true"
                    [dynamicOptions]="item.options"
                    [displayInModal]="true">
                </app-input>
            </div>
        
            <br>
            <!--Test psyuser questions without groups-->
            <div  *ngFor="let item of psyQuestions.testSharedNonGroupedQuestions" class="p-field">
                <app-input 
                    [inputForm]="item" 
                    [myFormGroup]="psyUserForm" 
                    [maskEditable]="true"
                    [dynamicOptions]="item.options"
                    [displayInModal]="true">
                </app-input>
            </div>
        
            <br>

            <!--Test psyuser questions with groups, group item example:-->

            <div *ngFor="let group of psyQuestions.testSharedGroupedQuestions" class="p-grid group-header">
                {{group.groupLabel}}
                <label>{{'PROJECTS.API-SCORING-POPUP-GROUPTITLE' | translate}}<strong>{{group.groupName}}</strong></label>
                <label><strong>{{group.groupLabel}}</strong></label>
                <hr class="group-divisor">
                <div *ngFor="let item of group.items" class="p-col-12 p-field">
                    <app-input 
                        [inputForm]="item" 
                        [myFormGroup]="psyUserForm" 
                        [maskEditable]="true" 
                        [dynamicOptions]="item.options" 
                        [displayInModal]="true">
                    </app-input>
                </div>
            </div> 
        </div>
    </div>
    <div class="p-col-12 info-data">
        <span><span class="red_dot">*</span>{{'EDIT-PEOPLE.MANDATORY' | translate}}</span>
    </div>

    <hr>
</div>


