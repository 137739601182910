import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastConfirmService {

  private currentConfirmActionValue = new BehaviorSubject<any>(null);
  sharedConfirmActionValue = this.currentConfirmActionValue.asObservable();

  constructor() { }

  setConfirmActionValue(res): void {
    this.currentConfirmActionValue.next(res);
  }
}
