import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminApi } from 'src/app/modules/admin/api/admin.api';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { PlanService } from '../../services/plan.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss'],
  providers: [ToastService]
})
export class PlanDetailsComponent implements OnInit {

  @Input() planToShow = null;
  @Output() hideParent = new EventEmitter<any>();   

  // Loaders
  loaded = false;

  // Customer Data
  customerConsumesInfo: any;
  customerId: any;

  // PsyUser Data
  psyUserConsumesInfo = {
    total: 0,
    consumed: 0
  }

  // Plan (main object)
  plan: any;
  planTests = []; // All tests of the plan
  planUsers = []; // All psyusers of the subscription
  subscriptionHistory = []; // All history related with the subscription
  subscriptionConsumption = 0;  
  availableCredits = 0;

  // Reports Table
  dataTable = [];

  constructor(
    private planSrv: PlanService,
    private adminSrv: AdminService,
    private authSrv: AuthenticationService,
    private toastSrv: ToastService,
    private adminApi: AdminApi,
    private router: Router
  ) { }  

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initData();
  }

  initData(){
    this.loaded = false;
    this.dataTable = [];

    this.plan = {...this.planToShow};

    if (this.planToShow !== undefined) {
      // Get available and consumed uses/credits
      this.customerId = this.authSrv.getCurrentCustomerId();
      this.planSrv.getCustomerBalance(this.customerId, false).then(res => {

        this.customerConsumesInfo = res;

        if(this.plan.psyuserSub_id){
          this.getPsyUserConsumesInfo();
        }else{
          this.setSubscriptionBalance();
        }

        // Set table format
        if(this.plan.sub_id){
          this.getSubcriptions();    
        }
        this.loaded = true;
      }, err => {
        this.toastSrv.showToastError(err);
        this.loaded = true;
      });
    } else this.loaded = true;
  }

  /**
   * 1. Get the subscription history depending on whether the plan to be displayed is for psyuser or not
   */
  getSubcriptions() {

    if(this.plan.usesBased || !this.plan.psyuserSub_id){
      // Get subscription history based in subcription
      this.adminApi.getHistorySubscriptions(this.plan.sub_id).subscribe(res => {        
        this.subscriptionHistory = res.data;
        this.getPlanTests();
        if(!this.plan.psyuserSub_id){
          this.setPlanUsers();
        }        
      }, err => {
        this.toastSrv.showToastError(err);
      });
    } else {
      // Get subscription history based in psyuser
      this.adminApi.getPsyuserHistorySubscriptions(this.plan.psyuserId).subscribe(res => {
        this.subscriptionHistory = res.data;        
        this.getPlanTests();
      }, err => {
        this.toastSrv.showToastError(err);
      });
    }
  }

  /**
   * 2. Get information of a plans related with a test
   */
  getPlanTests(){
    this.adminApi.getPlanTests(this.plan.plan_id).subscribe(res => {
      this.planTests = res.data;
      this.setTableFormat();
    }, err => {
      this.toastSrv.showToastError(err);
    });
  }

  /**
   * 2.b Set the psyusers of the subscription (only for a plan detail non based in a specific psyuser)
   */
  setPlanUsers(){
    const planConsumes = this.customerConsumesInfo.consumptionsByPlan.find(_plan => _plan.id == this.plan.sub_id);
    if(planConsumes){
      if(this.plan.usesBased){      
        this.planUsers = planConsumes.usesConsumptionByPsyuser;
      }else{
        this.planUsers = planConsumes.creditsConsumptionByPsyuser;
      }      
    }
  }

  /**
   * 3. Set data of test and reports to display as a table
   */
  setTableFormat(){      
    let result = [];
    this.planTests.forEach(test => {
      test.reports.forEach(report => {
        let auxLimit = test.planHasTest.limit;
        result.push(
          {
            testKey: `${test.parentTest.test} - ${test.title} [${test.locale.toUpperCase()}]`,
            reportName: report.name,
            uses:  this.plan.psyuserId ? this.getUsesByReportForPsyUser(report.id) : this.getUsesByReport(report.id),
            creditUses:  this.plan.psyuserId ? this.getCreditUsesByReportForPsyUser(report.id) : this.getCreditUsesByReport(report.id),
            price: report.credits,
            limitUses: auxLimit ? auxLimit : '∞' 
          }
        )
      });
    });    
    this.dataTable = result;
    this.loaded = true;
  }
  
  /**
   * Get the total uses/credits weight amount of a report in the history
   * @param reportId 
   * @returns 
   */
  getUsesByReport(reportId) {        
    let result = 0;

    if(this.subscriptionHistory){
      result = this.subscriptionHistory.filter(x => x.report_id == reportId).reduce((a, b) => a + b.weight, 0) * -1;
    }    
    
    return result;
  }

  /**
   * Get the total uses/credits weight amount of a report in the history
   * @param reportId 
   * @returns 
   */
  getUsesByReportForPsyUser(reportId) {        
    let result = 0;

    if(this.subscriptionHistory){
      result = this.subscriptionHistory.filter(x => x.report_id == reportId 
        && x.psyuser_id == this.plan.psyuserId 
        && x.customerHasPlan_id == this.plan.sub_id).reduce((a, b) => a + b.weight, 0) * -1;
    }    
    
    return result;
  }

  /**
   * Get the total number of a report uses
   * @param reportId 
   * @returns 
   */
  getCreditUsesByReport(reportId) {
    let result = 0;

    if(this.subscriptionHistory){
      result = this.subscriptionHistory.filter(x => x.report_id == reportId 
        && x.transactionType === 'DECREASE_CREDIT'
        && x.customerHasPlan_id == this.plan.sub_id).length;

      let refunds = this.subscriptionHistory.filter(x => 
          x.report_id == reportId 
          && x.transactionType !== 'DECREASE_CREDIT'
          && x.customerHasPlan_id == this.plan.sub_id).length;
    
      result = result - refunds;
    }

    return result;
  }

  /**
   * Get the total number of a report uses for a psyuser
   * @param reportId 
   * @returns 
   */
  getCreditUsesByReportForPsyUser(reportId) {
    let result = 0;

    if(this.subscriptionHistory){
      result = this.subscriptionHistory.filter(x => 
        x.report_id == reportId 
        && x.transactionType === 'DECREASE_CREDIT'
        && x.psyuser_id == this.plan.psyuserId 
        && x.customerHasPlan_id == this.plan.sub_id).length;


      let refunds = this.subscriptionHistory.filter(x => 
        x.report_id == reportId 
        && x.transactionType !== 'DECREASE_CREDIT'
        && x.psyuser_id == this.plan.psyuserId 
        && x.customerHasPlan_id == this.plan.sub_id).length;
  
        result = result - refunds;
    }    

    return result;
  }

  /**
   * Return string with the type of deduction (just info)
   * @param item 
   * @returns 
   */
  getDeduction(item) {
    let deduction = item.deduction;
    if(deduction?.scoring)
      return 'scoring';
    
    if(deduction?.reporting)
      return 'reporting';

    if(deduction?.administration)
      return 'administration';

    return undefined;
  }

  /**
   * Format specific periods
   * @param startDate 
   * @param finishDate 
   * @returns 
   */
  moreThan100(startDate, finishDate): boolean {
    return this.planSrv.moreThan100(startDate, finishDate);
  }

  getTimeBetweenDates(initDate:Date, endDate:Date, inHours:boolean=false): number {
    let result = (endDate.getTime() - initDate.getTime())/ (1000 * 3600);
    if(!inHours){
      //In days
      result = result / 24;
    }
    result = Math.floor(result);
    return result;
  }

  checkLessThanOneDayLeft(plan):boolean {
    let lessThanOneDay=this.calculateDaysLeft(plan)===0;
    return lessThanOneDay;
  }

  checkOneDayLeft(plan):boolean {
    let oneDayLeft=this.calculateDaysLeft(plan)===1;
    return oneDayLeft;
  }

  checkOneHourLeft(plan):boolean {
    let oneHourLeft=this.calculateHoursLeft(plan)===1;
    return oneHourLeft;
  }

  calculateDaysLeft(plan): number {
    let result = 0;
    if(plan.finishDate){
      //Date constructor adjusts for timezone, so we are comparing local hours
      let finishDate = new Date(plan.finishDate);
      let today = new Date();
      result=this.getTimeBetweenDates(today, finishDate, false);
    }
    return result;
  }

  calculateHoursLeft(plan): number {
    let result = 0;
    if(plan.finishDate){
      //Date constructor adjusts for timezone, so we are comparing local hours
      let finishDate = new Date(plan.finishDate);
      let today = new Date();
      result=this.getTimeBetweenDates(today, finishDate, true);
    }
    return result;
  }

  /**
   * Go to pyuser edition page
   * @param user 
   */
  managePsyUser(user){
    this.hideParent.emit(true);
    this.adminSrv.setPsyToEdit(user);
    this.router.navigate(['admin', 'manage']);
  }

  /**
   * Set psyUser consumes data
   */
  getPsyUserConsumesInfo() {    
    const planConsumes = this.customerConsumesInfo.consumptionsByPlan.find(_plan => _plan.id == this.plan.sub_id);
    if(planConsumes){      
      if(this.plan.usesBased){      
        const psyUserData = planConsumes.usesConsumptionByPsyuser.find(_psyuser => _psyuser.id == this.plan.psyuserId);
        this.psyUserConsumesInfo.total = psyUserData?.total ?? 0;      
        this.psyUserConsumesInfo.consumed = psyUserData?.consumed ?? 0;
      }else{
        const psyUserData = planConsumes.creditsConsumptionByPsyuser.find(_psyuser => _psyuser.id == this.plan.psyuserId);
        this.psyUserConsumesInfo.total = psyUserData?.total ?? 0;      
        this.psyUserConsumesInfo.consumed = psyUserData?.consumed ?? 0;
      }
    }
  }

  /**
   * Set subscription consumes based in the customer data
   */
  setSubscriptionBalance(){
    const planConsumes = this.customerConsumesInfo.consumptionsByPlan.find(_plan => _plan.id == this.plan.sub_id); 
    
    if(planConsumes){
      if(this.plan.usesBased){      
        this.subscriptionConsumption = planConsumes.generalUsesInfo.totalConsumption;
      }else{
        this.subscriptionConsumption = planConsumes.generalCreditsInfo.totalConsumption;
        this.availableCredits = planConsumes.generalCreditsInfo.totalAvailableToAssign;
      }
    }
  }
 
  /**
   * Number of requested reports by credits
   * @returns 
   */
  getTransactionHistoryCountsByCredits() {    

    let decreases = this.subscriptionHistory.filter(_transaction => _transaction.consumptionType === 'REPORTING' 
      && _transaction.transactionType === 'DECREASE_CREDIT'
      && _transaction.customerHasPlan_id === this.plan.sub_id).length;
    
    let notReportingDecreases = this.subscriptionHistory.filter(_transaction => _transaction.consumptionType === 'REPORTING' 
      && _transaction.transactionType !== 'DECREASE_CREDIT'
      && _transaction.customerHasPlan_id === this.plan.sub_id).length;

    decreases = decreases - notReportingDecreases;

    return decreases;
  }

  /**
   * Number of uses
   * @returns 
   */
  getTransactionHistoryCountsByUses() {
    let result = 0;

    if(this.subscriptionHistory) {
      result = this.subscriptionHistory.filter(x => x.report_id !== null && x.customerHasPlan_id == this.plan.sub_id).reduce((a, b) => a + b.weight, 0) * -1;
    }

    return result;
  }

   /**
   * Number of uses of a psyuser
   * @returns 
   */
    getTransactionHistoryCountsByUsesAndPsyUser() {
      let result = 0;      
  
      if(this.subscriptionHistory) {
        result = this.subscriptionHistory.filter(x => x.report_id !== null && x.customerHasPlan_id == this.plan.sub_id && x.psyuser_id == this.plan.psyuserId).reduce((a, b) => a + b.weight, 0) * -1;
      }
  
      return result;
    }
}
