import { Component, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import * as moment from "moment";
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ProjectType } from "src/app/shared/enums/project-type.enum";
import { EntryType } from "src/app/shared/enums/entry-type.enum";
import { ReportingService } from "./reporting.service";
import { UserPreferences } from "src/app/shared/models/user-preferences-model";
import { LazyProjectsArgs, ProjectsApi } from "../api/projects.api";
import { ExcelCsvService } from "src/app/shared/services/excel-csv.service";
import { ProjectStatusString } from "src/app/shared/enums/project-status-string.enum";
import { switchMap } from "rxjs/operators";
import { ProjectSize } from "../components/projects-container/projects-container.component";
import { ToastService } from "src/app/shared/services/toast.service";

@Injectable({
  providedIn: "root"
})
export class ProjectsService {

  private currentActiveIndex = new BehaviorSubject<number>(null);
  sharedActiveIndex = this.currentActiveIndex.asObservable();

  private currentProject = new BehaviorSubject<any>(null);
  sharedProject = this.currentProject.asObservable();

  private currentProjectId =  new BehaviorSubject<any>(null);
  sharedProjectId = this.currentProjectId.asObservable();

  private currentGroupProjectsPage = new BehaviorSubject<any>(undefined);
  sharedGroupProjectsPage = this.currentGroupProjectsPage.asObservable();

  private currentIndividualProjectsPage = new BehaviorSubject<any>(undefined);
  sharedIndividualProjectsPage = this.currentIndividualProjectsPage.asObservable();

  private currentIndividualPaginationConditions = new Subject();
  sharedIndividualPaginationConditions = this.currentIndividualPaginationConditions.asObservable();

  private currentGroupPaginationConditions = new Subject();
  sharedGroupPaginationConditions = this.currentGroupPaginationConditions.asObservable();

  private currentTest = new BehaviorSubject<any>(null);
  sharedTest = this.currentTest.asObservable();

  private maskStatus = new BehaviorSubject<any>(null);
  sharedMaskStatus = this.maskStatus.asObservable();

  private projectDeleted = new BehaviorSubject<any>(0);
  sharedProjectDeleted = this.projectDeleted.asObservable();

  private updatedStatus = new BehaviorSubject<number>(null);
  sharedupdatedStatus = this.updatedStatus.asObservable();

  private assessmentUrl = environment.webAssesmentURL;

  private qCode = new BehaviorSubject<any>(null);
  sharedQCode = this.qCode.asObservable();

  public reloadFrom = null;
  private requestUpdate = new BehaviorSubject<boolean>(false);
  sharedRequestUpdate = this.requestUpdate.asObservable();

  private currentNewestProjectCompilationDate = new BehaviorSubject<any>(null);
  sharedNewestProjectCompilationDate = this.currentNewestProjectCompilationDate.asObservable();

  private currentActiveIndexAndPagDetails = new BehaviorSubject<UserPreferences>(new UserPreferences);
  sharedActiveIndexAndPagDetails = this.currentActiveIndexAndPagDetails.asObservable();

  private currentSupplyAdviceShow = new BehaviorSubject<boolean>(false);
  sharedCurrentSupplyAdviceShow = this.currentSupplyAdviceShow.asObservable();

  private currentStatusProject = new BehaviorSubject<any>(null);
  sharedCurrentStatusProject = this.currentStatusProject.asObservable();

  subProjectType = ProjectType;
  projectEntryType = EntryType;

  // Set max testtakers allowed
  public MAX_TESTTAKERS = environment.MAX_TESTTAKERS;

  constructor(
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    private reportingSrv: ReportingService,
    private projectsApi: ProjectsApi,
    private excelCsvSrv: ExcelCsvService,
    private route: Router
  ) {

    //Request projects page every time currentPaginationConditions are updated
    this.getProjectsPaginatedOnUpdate()

    const activeIndex = localStorage.getItem("projectsActiveIndex");
    if (activeIndex) {
      try {
        this.setActiveIndex(JSON.parse(activeIndex));
      } catch (ex) {}
    }

    const curProject = localStorage.getItem("currentProject");
    if (curProject) {
      try {
        this.setCurrentProject(JSON.parse(curProject));
      } catch (ex) {}
    }

    const curTest = localStorage.getItem("currentTest");
    if (curTest) {
      try {
        this.setCurrentTest(JSON.parse(curTest));
      } catch (ex) {}
    }

    const qCode = localStorage.getItem('qCode');
    if (qCode) {
      try {
        this.setQCode(JSON.parse(qCode));
      } catch (ex) {}
    }

    const activeIndexAndPagDetails = localStorage.getItem("activeIndexAndPagDetails");
    if (activeIndexAndPagDetails) {
      try {
        this.setActiveIndexAndPagDetails(JSON.parse(activeIndexAndPagDetails));
      } catch (ex) {}
    }
   }

  updatePaginationConditions(conditions: LazyProjectsArgs, projectSize: ProjectSize.INDIVIDUAL | ProjectSize.GROUPAL) {
    if(projectSize === ProjectSize.INDIVIDUAL)
      this.currentIndividualPaginationConditions.next(conditions)
    else
      this.currentGroupPaginationConditions.next(conditions)
  }

  setActiveIndex(index: number): void {
    localStorage.setItem("projectsActiveIndex", JSON.stringify(index));
    this.currentActiveIndex.next(index);
  }

  //TODO: ojo con el tope de testtakers
  setCurrentProject(item: any): boolean {

    try {
      localStorage.setItem("currentProject", JSON.stringify(item));
    } catch {
      console.warn('Error saving project in localStorage')
    }

    let selectedLengh = 0;
    item.testtakers?.forEach(_tt => {
      if(_tt.testtakersCount){
        selectedLengh += _tt.testtakersCount;
      } else {
        selectedLengh++;
      }
    });

    if(selectedLengh > this.MAX_TESTTAKERS) {
      return false;
    }

    this.currentProject.next(item);
    return true;
  }

  setRequestUpdate(needsUpdate: boolean): void {
    this.requestUpdate.next(needsUpdate);
  }

  setUpdatedStatus(statusObject: any): void{
    this.updatedStatus.next(statusObject);
  }

  generateNewProject(supplyType?): any {
    return {
      createdAt: new Date(),
      id: null,
      name: `Project_${moment().valueOf()}`,
      status: ProjectStatusString.CREATED,
      questionnaires: [],
      testtakers: [],
      sequence: false,
      supply: supplyType ? supplyType : this.projectEntryType.Manual,
    };
  }

  setCurrentTest(item: any): void {
    localStorage.setItem("currentTest", JSON.stringify(item));
    this.currentTest.next(item);
  }

  setQCode(item: any): void{
    localStorage.setItem('qCode', JSON.stringify(item));
    this.qCode.next(item);
  }

  setActiveIndexAndPagDetails(item: any): void {
    localStorage.setItem("activeIndexAndPagDetails", JSON.stringify(item));
    this.currentActiveIndexAndPagDetails.next(item);
  }

  getCurrentStatusCode(project): number{
    const statuses = project.questionnaires.map(x => x.statusCode);
    if (statuses.includes(QuestionnaireCodeStatus.FILLING)) {
      return QuestionnaireCodeStatus.FILLING;
    }
    return Math.min(...statuses);
  }

  getProjectStatusStringByCode(statusCode: number): string {
    let result = "";
    switch (statusCode) {
      case QuestionnaireCodeStatus.CREATED:
        result = ProjectStatusString.CREATED;
        break;
      case QuestionnaireCodeStatus.ASSIGNED:
        result = ProjectStatusString.ASSIGNED;
        break;
      case QuestionnaireCodeStatus.FILLING:
        result = ProjectStatusString.FILLING;
        break;
      case QuestionnaireCodeStatus.COMPILED:
      case QuestionnaireCodeStatus.READY_TO_SCORE:
        result = ProjectStatusString.READY_TO_SCORE;
        break;
      case QuestionnaireCodeStatus.SCORING_ONGOING:
        result = ProjectStatusString.SCORING_ONGOING;
        break;
      case QuestionnaireCodeStatus.SCORED:
        result = ProjectStatusString.SCORED;
        break;
      case QuestionnaireCodeStatus.REPORT_SERVED:
        result = ProjectStatusString.REPORT_SERVED;
        break;
      case QuestionnaireCodeStatus.SCORED_NON_VALID:
        result = ProjectStatusString.SCORED_NON_VALID;
        break;
      case QuestionnaireCodeStatus.INVALID_SCORING:
        result = ProjectStatusString.INVALID_SCORING;
        break;
      case QuestionnaireCodeStatus.REPORT_ONGOING:
        result = ProjectStatusString.REPORT_ONGOING;
        break;
    }
    return result;
  }

  getProjectStatusIntByString(statusCode): number {
    let result = 0;
    switch (statusCode) {
      case ProjectStatusString.ASSIGNED:
        result = QuestionnaireCodeStatus.ASSIGNED;
        break;
      case ProjectStatusString.FILLING:
        result = QuestionnaireCodeStatus.FILLING;
        break;
      case ProjectStatusString.READY_TO_SCORE:
        result = QuestionnaireCodeStatus.READY_TO_SCORE;
        break;
      case ProjectStatusString.SCORING_ONGOING:
        result = QuestionnaireCodeStatus.SCORING_ONGOING;
        break;
      case ProjectStatusString.SCORED:
        result = QuestionnaireCodeStatus.SCORED;
        break;
      case ProjectStatusString.CREATED:
        result = QuestionnaireCodeStatus.CREATED;
        break;
      case ProjectStatusString.SCORED_NON_VALID:
        result = QuestionnaireCodeStatus.SCORED_NON_VALID;
        break;
      case ProjectStatusString.REPORT_SERVED:
        result = QuestionnaireCodeStatus.REPORT_SERVED;
        break;
    }
    return result;
  }

  getProjectStatusStringKey(statusString): string {
    let result = '';
    switch (statusString) {
      case ProjectStatusString.CREATED:
        result = 'FILTER.CREATED';
        break;
      case ProjectStatusString.ASSIGNED:
        result = 'FILTER.ASSIGNED';
        break;
      case ProjectStatusString.FILLING:
        result = 'FILTER.IN-PROGRESS';
        break;
      case ProjectStatusString.READY_TO_SCORE:
        result = 'FILTER.PERFORMED';
        break;
      case ProjectStatusString.SCORING_ONGOING:
        result = 'FILTER.SCORING';
        break;
      case ProjectStatusString.SCORED:
        result = 'FILTER.SCORED';
        break;
      case ProjectStatusString.REPORT_SERVED:
      case 'finished':
        result = 'FILTER.FINISHED';
        break;
      case 'reporting':
        result = 'FILTER.REPORTING';
        break;
      case 'non-valid':
        result = 'FILTER.NON-VALID'
        break;
    }
    return result;
  }

  /**
   * Introduces all defined project statuses translated
   * and in logical order to be used on dropdowns and selectors.
   * @returns list of total ordered statuses for projects
   */
  generateProjectStatusDropdown(): string[] {
    let statusList:any[] = []

    //Add the project statuses in logical order and translated
    const values = [ProjectStatusString.CREATED, ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING, ProjectStatusString.READY_TO_SCORE,
                    ProjectStatusString.SCORED, ProjectStatusString.REPORT_SERVED];
    values.forEach(item => {
      let text = this.translate.instant(this.getProjectStatusStringKey(item))
      statusList.push({value: this.getProjectStatusIntByString(item), label: text})
    });
    return statusList
  }

  /**
   * Introduces all entryTypes available for projects
   */
   generateProjectEntryTypeDropdown(): string[] {
    let statusList:any[] = []

    //Add the project entry types
    const values = [
      { value: '1', label: "DD-MANUAL" },
      { value: '2', label: "DD-ON-SITE" },
      { value: '3', label: "DD-REMOTE" },
      { value: '4', label: "DD-BLIND" },
    ];
    values.forEach(item => {
      statusList.push({value: item.value, label: this.translate.instant('CREATE-PROJECT.' + item.label)})
    });

    return statusList;
  }

  generateProjectSearchTypeDropdown(): string[] {
    let list:any[] = []

    //Add the project type of search
    const values = ['project', 'people'];
    values.forEach(item => {
      let text = this.translate.instant('FILTER.'+item.toUpperCase())
      list.push({value: item, label: text})
    });
    return list
  }

  getCustomEntryType(entryTypeId): string{
    if (entryTypeId == 1) {
      return "manual";
    } else if (entryTypeId == 2) {
      return "on-site";
    } else if (entryTypeId == 3) {
      return "remote";
    } else if (entryTypeId == 4) {
      return "blind";
    }
  }

  generateApiProject(projectObj): any {
    let project: any = JSON.parse(JSON.stringify(projectObj));
    project.psyuser_id = this.authSrv.getCurrentPsyUser().id;
    project.entryType_id = Number(project.supply);
    project.code = project.name;
    project.customerId = this.authSrv.getCurrentCustomerId();
    // format tests
    project.tests = project.questionnaires.map((_quest) => ({
      id: _quest.id,
      external: _quest.external,
      selectedReport_id: _quest.selectedReport_id,
      orderInProject: _quest.orderInProject,
      reports: _quest.reports.map(_report => ({
        id: _report.id,
        reportName: _report.reportName
      }))
    }));
    project.questionnaires = project.tests;
    // format testakers
    if(!project.roleBased){
      project.testtakers = project.testtakers.map(x => ({id: x.id, isGroup: x.isGroup}));
    }

    return project;
  }

  insertQCodeAndNavigateToAnswers(questionnaireCode, isScoring): void {
    this.setQCode(questionnaireCode);
    if (isScoring) {
      this.route.navigate(['projects', 'answers'], {queryParams: {mode: 'scoring' }});
    } else {
      this.route.navigate(['projects', 'answers']);
    }
  }

  administer(questionnaireCode): void {
    const url = `${this.assessmentUrl}${questionnaireCode}`;
    window.open(url, "_blank");
    this.authSrv.logout();
  }

  increaseProjectDeleted(): void {
    this.projectDeleted.next(this.projectDeleted.value + 1);
  }

  //TODO: el objetivo es borrar esto
  setProjectType(item, projectTestReportList): any {
    projectTestReportList = this.reportingSrv.getTestsAndReportsFromProject(item.questionnaires)

    if (item.projectHasTestTakers.length > 1) { //Project for individual, or a group.

      if(item.entryType_id === this.projectEntryType.Remote){ //Entry type: remote, onsite, manual
        if (projectTestReportList.length > 1) { // Multitest, singleTest
          item.projectType = this.subProjectType.grReMu;
        } else {
          item.projectType = this.subProjectType.grReSi;
        }
      }else if(item.entryType_id === this.projectEntryType.Manual){
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.grMaMu;
        } else {
          item.projectType = this.subProjectType.grMaSi;
        }
      }else if(item.entryType_id === this.projectEntryType.Blind){
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.grBlMu;
        } else {
          item.projectType = this.subProjectType.grBlSi;
        }
      }

    } else { // Individual user
      if (item.entryType_id === this.projectEntryType.Remote) {
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.inReMu;
        } else {
          item.projectType = this.subProjectType.inReSi;
        }
      } else if (item.entryType_id === this.projectEntryType.OnSite) {
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.inPrMu;
        } else {
          item.projectType = this.subProjectType.inPrSi;
        }
      } else if (item.entryType_id === this.projectEntryType.Manual) {
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.inMaMu;
        } else {
          item.projectType = this.subProjectType.inMaSi;
        }
      } else if (item.entryType_id === this.projectEntryType.Blind) {
        if (projectTestReportList.length > 1) {
          item.projectType = this.subProjectType.inBlMu;
        } else {
          item.projectType = this.subProjectType.inBlSi;
        }
      }
    }

    return {item, projectTestReportList};
  }

  getProjectTypeV2(project: any, prevFilter: "individual" | "projectgroup"): ProjectType {

    if (prevFilter === 'projectgroup') { //Project for individual, or a group.
      if(project.entryType_id === this.projectEntryType.Remote){ //Entry type: remote, onsite, manual
        if (project.tests.length > 1) { // Multitest or singleTest
          return this.subProjectType.grReMu;
        } else {
          return this.subProjectType.grReSi;
        }
      }else if(project.entryType_id === this.projectEntryType.Manual){
        if (project.tests.length > 1) {
          return this.subProjectType.grMaMu;
        } else {
          return this.subProjectType.grMaSi;
        }
      }else if(project.entryType_id === this.projectEntryType.Blind){
        if (project.tests.length > 1) {
          return this.subProjectType.grBlMu;
        } else {
          return this.subProjectType.grBlSi;
        }
      }
    } else { // Individual user
      if (project.entryType_id === this.projectEntryType.Remote) {
        if (project.tests.length > 1) {
          return this.subProjectType.inReMu;
        } else {
          return this.subProjectType.inReSi;
        }
      } else if (project.entryType_id === this.projectEntryType.OnSite) {
        if (project.tests.length > 1) {
          return this.subProjectType.inPrMu;
        } else {
          return this.subProjectType.inPrSi;
        }
      } else if (project.entryType_id === this.projectEntryType.Manual) {
        if (project.tests.length > 1) {
          return this.subProjectType.inMaMu;
        } else {
          return this.subProjectType.inMaSi;
        }
      } else if (project.entryType_id === this.projectEntryType.Blind) {
        if (project.tests.length > 1) {
          return this.subProjectType.inBlMu;
        } else {
          return this.subProjectType.inBlSi;
        }
      }
    }
  }

  convertHtmlToQuillFormat(html:string): string{
    //Match </p>, then, between </p> and <p> (Which is (?<=<\/p>).....(?=<p>)) match every space or \n until delimiter [ *\n*]*? then, match (<\/p>), global, multiline (gm)
    //Then, add <p><br></p>.
    let quillHtml= html.replace(/<\/p>([*\n*]*?)<p>/gm,"</p><p><br></p><p>")
    return quillHtml;
  }

  convertQuillFormatToHtml(quillHtml:string, convertToPreTags:boolean=true): string{
    //[\s\S]+?(?=<p(?<=<p)[^>]*>[ *\n*]*?<br>[ *\n*]*?(?=<\/p>)<\/p>)
    //[\s\S]+?(?=<p(?<=<p)[^>]*>[ *\n*]*?<br>[ *\n*]*?(?=<\/p>)<\/p>)|<p(?<=<p)[^>]*>[ *\n*]*?<br>[ *\n*]*?(?=<\/p>)<\/p>[\s\S]+?(?=<p(?<=<p)[^>]*>[ *\n*]*?<br>[ *\n*]*?(?=<\/p>)<\/p>)



    let html="";
    if(!convertToPreTags)
    {
      //Match <p, then, between <p and </p> (Which is (?<=<p).....(?=<\/p>)), first, find the end of the <p like <p class="paragraph">, then, match every space or \n until <br> ([ *\n*]*?<br>) then, match every space or \n until delimiter [ *\n*|]*?(?=<\/p>) then, match (<\/p>), global, multiline (gm)
      //Then, delete.
      html = quillHtml.replace(/<p[^>]*>[ *\n\t*]*?<br>[ *\n\t*]*?(?=<\/p>)<\/p>/gm,"")
    }
    else{
      //Workaround to make the regex less complex. Explanation can be found later.
      quillHtml+="<p><br></p>"
      //First find matches. Every text between br will be considered as a paragraph
      let matches=[];
      //Match everything until finding any structure like: <p ... ><br></p>. This is a paragraph. Example:
      /*
      Given this html:

      <p class="paragraph">sei stato/a invitato/a ad effettuare un test online. Seleziona il pulsante per accedere al portale di assessment.</p>
      <p><br></p>
      <p class="paragraph">Per svolgere il test segui attentamente le istruzioni riportate sul sito. Il test viene svolto nel completo rispetto della tua privacy.</p>
      <p class="class-example">

      <br>
      </p>
      <p class="paragraph">Testo 2</p>
      <p class="paragraph">Testo 3</p>

      There will be 2 matches:
      <p class="paragraph">sei stato/a invitato/a ad effettuare un test online. Seleziona il pulsante per accedere al portale di assessment.</p>

      and

      <p><br></p>
      <p class="paragraph">Per svolgere il test segui attentamente le istruzioni riportate sul sito. Il test viene svolto nel completo rispetto della tua privacy.</p>

      Thats the reason why <p><br></p> is added to the end of the text to make things more easy; it's like a delimiter
      */

     //So, repeat until no more matches are found:

      let regEx=/[\s\S]+?(?=<p[^>]*>[ *\n\t*]*?<br>[ *\n\t*]*?(?=<\/p>)<\/p>)/gm;
      let result;

    do {
      result = regEx.exec(quillHtml);
      if (result)
      {
        matches.push(result[0]);
      }
    } while (result);

      //Then, for each match:
      matches.forEach((match)=>{
        //Delete the <p class...><br><p> found
        match=match.replace(/<p[^>]*>[ *\n\t*]*?<br>[ *\n\t*]*?(?=<\/p>)<\/p>/gm,"")
        //Then, replace every pair of (</p><p>) to \n
        match=match.replace(/<\/p\s*.*>\s*.*<p>/gm,``);
        //Now, replace the <p> tag with <pre> and the same with </p> and </pre>
        match=match.replace(/<p/gm,'<pre')
        match=match.replace(/<\/p>/gm,'</pre>')
        //Then add to the html file as new paragraph
        html+=match
      })
    }
    return html;
  }

  async generateBlindAdministrationExcel(project): Promise<any> {

    let blindAdministrationData = await this.projectsApi.getBlindDataByProjectId(project.id).toPromise();

    // filter by testtaker
    if(project.friendlyId) {
      blindAdministrationData.data = blindAdministrationData.data.filter(_testtaker => _testtaker.friendlyId === project.friendlyId);
    }

    const result = await this.excelCsvSrv.generateBlindAdministrationExcel(this.assessmentUrl, project, blindAdministrationData.data);

    return result;
  }

  async getProjectsPaginated(params, parseTesttakers:boolean=false): Promise<any> {
    const projectsPaginatedPromise = this.projectsApi.getFilteredProjectsAndCount({ params }).toPromise();

    try {
      const res = await projectsPaginatedPromise;
      const { projects, count } = res.data;

      //Parse to adapt to projects component
      if(parseTesttakers){
        projects.forEach(project => {
          project.testtakers = project.projectHasTestTakers.map(phtt => phtt.testtaker);
          // Manage project status to apply filters
          project.status = this.translate.instant(this.getProjectStatusStringByCode(project.statusCode));
        });
      }

      return {
        count,
        projects,
        options: params
      }
    }
    catch(err){
      return {
        count: 0,
        projects: [],
        options: params
      }
    }
  }

  getProjectsPaginatedOnUpdate() {
    //Each time current<ProjectType>PaginationConditions is updated, new projects page is requested and stored in current<ProjectType>ProjectsPage.
    //If a new update is done while waiting some previous response, it will be override it avoiding collisions
    this.currentIndividualPaginationConditions.pipe(switchMap((newSearchConditions) => this.getProjectsPaginated(newSearchConditions, true)))
    .subscribe((res) => {
        this.currentIndividualProjectsPage.next({
          projects: res.projects,
          count: res.count,
          options: res.options
        })
    })

    //Update group paginations
    this.currentGroupPaginationConditions.pipe(switchMap((newSearchConditions) => this.getProjectsPaginated(newSearchConditions, true)))
    .subscribe((res) => {
        this.currentGroupProjectsPage.next({
          projects: res.projects,
          count: res.count,
          options: res.options
        })
    })
  }

  setNewestProjectCompilationDate(timeStamp:Date){
    this.currentNewestProjectCompilationDate.next(timeStamp);
  }

  getNewestProjectCompilationDate(): Date{
    return this.currentNewestProjectCompilationDate.value;
  }

  setLastProjectId(id: number){
    this.currentProjectId.next(id);
  }

  setCurrentSupplyAdviceShow(value: boolean) {
    this.currentSupplyAdviceShow.next(value);
  }

  getLastProjectId(): number{
    return this.currentProjectId.value;
  }

  cleanProjectObject(item, projectTestReportList) {
    let cleanArrayOfTest = [];
    let response;
    item["isMigrated"] = false;
    item.questionnaires?.forEach(q => {
      if(!q.scoringResults && !q.selectedReport_id) {
        projectTestReportList.forEach((p,index) => {
          p.reports.forEach((pReports,i) => {
            q.reportingResults.forEach((qReports) => {
              if(qReports.reportName === pReports.reportName && qReports.url != null) {
                cleanArrayOfTest.push(p.reports[i]);
              }
            })
          })
          projectTestReportList[index].reports = cleanArrayOfTest;
        });
        item["isMigrated"] = true;
      }
    });
    return response = {
      projectTestReportList,
      item
    }
  }

  /**
  * Downloads the CSV table containing the score information
  */
  async downloadScoringTable(project) {

    console.log('proyecto que entra en lo del excel', project)

    try {
      let relatedTest = this.reportingSrv.getTestsAndReportsFromProject(project.questionnaires);

      let nonExternalTests = project.questionnaires.filter(_test => !_test.test.external);
      let nonExternalRelatedTests = relatedTest.filter(_test => !_test.external);

      let qCodesList = nonExternalTests.map(_qcodes => _qcodes.questionnaireCode);

      let profileData = await new Promise((resolve, reject) => {
        this.projectsApi.getProfilesDataByQuestionnaires(qCodesList).subscribe(
          (_data) => {
            resolve(_data.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
      let profileList = profileData;
      let scoringExcel = await this.excelCsvSrv.createScoringExcel(project, nonExternalRelatedTests, profileList);

      return scoringExcel;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  /**
   * Get the status of a specific project by id, and emmit event to be listener for project list
   * @param projectId
   */
  getProjectStatusAndEmitChanges(projectId){
    this.projectsApi.getProjectStatus(projectId).subscribe(res => {
      if(res && res.data) {
        this.currentStatusProject.next({
          projectId: projectId,
          statusCode: res.data[0].statusCode
        })
      }
    });
  }

  /**
   * Set basic data to project to apply actions
   */
  public formatProject(project){
    this.cleanProjectObject(project, this.reportingSrv.getReportObjectByQuestionnaires(project.questionnaires));

    // Set testtakers
    const testtakers = [];
    project.projectHasTestTakers.forEach(z => {
      testtakers.push(z.testaker);
    });
    project.testtakers = testtakers;

    // Set questionnaires test titles
    project.questionnaires.forEach((z) => {
      z.testTitle = z.test.title;
    });

    // Set status
    const statusProject = this.getProjectStatusStringByCode(project.statusCode);
    project.statusLabel = this.translate.instant(this.getProjectStatusStringKey(statusProject));
    project.status = statusProject;
  }

  /**
   * Check questionnaires consistency
   * @param questionnaires
   * @param componentInstance
   * @returns
   */
  async checkQuestionnairesConsistency(questionnaires: any[], componentInstance: any){

    let errorConsistency = false;
    try {
      //Check questionnaires consistency before the call to scoring
      const nonExternalTest = questionnaires.filter(item => !item.test.external); // Avoid checkconsistency for external tests
      let nonExternalTestQCodes = nonExternalTest.map(item => item.questionnaireCode); // Extract only qCodes

      // Remove duplicates
      nonExternalTestQCodes = [...new Set(nonExternalTestQCodes)];

      const consistencyResponse: any = await this.projectsApi.checkQuestionnaireConsistencyBulk(nonExternalTestQCodes);
      if (!consistencyResponse.statusOk) {
        errorConsistency = true;
      }
    } catch (ex) {
      console.error("Error checking questionnaire consistency: ", ex.message);
      errorConsistency = true;
    }

    if(errorConsistency) {
      console.error("Error checking questionnaire consistency")
      componentInstance.toastSrv.showToastError(this.translate.instant('PROJECTS.API-SCORING-CONSISTENCY-FAILED'));
      return false;
    } else {
      return true;
    }
  }


}//End of Project Service Class
