import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { FileUpload } from 'primeng/fileupload';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ExcelCsvService } from 'src/app/shared/services/excel-csv.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { Codes } from '@giuntipsy/utils/lib';
import { PeopleApi } from '../../api/people.api';
import { PeopleService } from '../../services/people.service';
import { TesttakerGroupSelectorComponent } from 'src/app/shared/components/modal-templates/testtaker-group-selector/testtaker-group-selector.component';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-edit-people',
  templateUrl: './edit-people.component.html',
  styleUrls: ['./edit-people.component.scss'],
  providers: [ToastService],
  animations: [ inOutAnimation ]
})
export class EditPeopleComponent implements OnInit, OnDestroy {

  @ViewChild('inputFriendlyId') inputFriendlyId: ElementRef;
  @ViewChild('fileUpload') fileUpload: FileUpload;
  @ViewChild('testtakerGroupSelector') testTakerGroupSelector: TesttakerGroupSelectorComponent;

  editForm: UntypedFormGroup;
  public formData: any = [];
  public loaded = false;
  public type: string;
  public maxDate = new Date();
  public subs = new SubSink();

  private user: any = {};
  private fromRoute: string;
  private originalUserId: any;

  // Related with import csv
  public showImportUsersModal = false;
  public showSpinner = false;
  public showPreview = false;
  public usersToSave: any[] = [];
  public usersAlreadySaved: any[] = [];
  public csvHasDuplicatedUsers: boolean = false;
  parserOptions = [{code: ',', name: ',', },  {code: ';', name: ';'}];
  selectedParser = {code: ';', name: ';', };
  parseTotalErrorsFound :number = 0;
  parseTotalWarningsFound:number = 0;
  wrongDelimiterCase:boolean = false;

  protected language = '';

  constructor(
    private peopleSrv: PeopleService,
    private peopleApi: PeopleApi,
    private excelCsvSrv: ExcelCsvService,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private authSrv: AuthenticationService,
    private primeNGConfig: PrimeNGConfig
    ) { }

  ngOnInit(): void {
    this.language = this.translate.currentLang;

    this.subs.sink = this.activatedroute.queryParamMap.subscribe(event => {
      const params: any = event;
      this.type = params.params.type;
      if (this.type === 'NEW') {
        // Set group of testtakers as empty
        this.user.testTakerGroups = [];
        this.initPage();
      } else {
        this.initPageAsEdit(params.params.id);
      }
      this.fromRoute = params.params.from;
    });

    // Load translation for calendar filter
    this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  initPageAsEdit(id): void {
    this.subs.sink = this.peopleApi.getUser(id).subscribe(
    usr => {
      this.user = usr.data;
      this.originalUserId = this.user.id;
      // Set previously selected groups
      for(let _group of this.user.testTakerGroups) {
        _group.selected = true;
        // Indicate isGroup in object to be marked in the testtaker dialog correctly
        _group.isGroup = true;
        this.testTakerGroupSelector.selectItem(_group);
      }
      this.initPage();
    },
    err => {
      this.router.navigate(['notfound']);
    });
  }

  initPage(): void {
    this.subs.sink = this.peopleSrv.sharedFormData.subscribe(formData => {
      this.subs.sink = this.translate.onLangChange.subscribe((ev) => {
        this.language = ev.lang;
        this.setValidationMessages();
        if (this.editForm !== undefined) {
          this.editForm.reset();
        }
      });
      this.initForm(formData);
    });
  }

  initForm(formData: any): void{
    this.formData = formData;
    this.setValidationMessages();
    this.generateForm();
    this.initDropdowns();

    if (this.user !== undefined && this.user !== null) {
      setTimeout(() => {
        this.setUserDetail(this.user);
      }, 0 );
    }
    setTimeout( () => {
      this.inputFriendlyId.nativeElement.click();
    }, 500 );
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            email: this.translate.instant('VALIDATIONS.EMAIL'),
            phonePattern: this.translate.instant('VALIDATIONS.PHONE'),
            onlyIntegers: 'Solo permite enteros'
          }
        });
    });
  }

  generateForm(): void {
    let friendlyId = '';
    if (this.type === 'NEW') {
      friendlyId = `People_${moment().valueOf()}`;
    }
    this.editForm = this.formBuilder.group({
      friendlyId: [friendlyId, []],
      gender: ['', [RxwebValidators.required()]],
      birthdate: [''],
      name : [''],
      surname: [''],
      email: ['', [RxwebValidators.email()]],
      // ---Seconday Info---
      country_id: [''],
      nationality: [''],
      city: [''],
      address: [''],
      phonenumber: ['', [
        RxwebValidators.compose(
          {validators: [
            RxwebValidators.pattern(
              {expression: {
                onlyAlpha : /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
              }})
          ], messageKey: 'phonePattern'}
        )]],
      education_id: [''],
      occupation_id: [''],
      notes: ['']
    });

    if (friendlyId !== '') {
      this.friendlyId.markAsTouched();
    }
  }

  setUserDetail(user): void {
    const controls: string[] = Object.keys(this.editForm.controls);

    Object.keys(user).forEach(e => {
      if (user[e] !== undefined && user[e] !== null && user[e] !== '' && controls.includes(e)) {
        if (e === 'gender') {
          this[e].patchValue(this.formData?.genders.filter(x => x.code === user[e])[0]);
        } else if (e === 'country_id') {
          this[e].patchValue(this.formData?.countries.filter(x => x.code === user[e])[0]);
        } else if (e === 'education_id') {
          this[e].patchValue(this.formData?.educations.filter(x => x.code === user[e])[0]);
        } else if (e === 'occupation_id') {
          this[e].patchValue(this.formData?.occupations.filter(x => x.code === user[e])[0]);
        } else if (e === 'birthdate') {
          this[e].patchValue(new Date(user[e]));
        } else {
          this[e].patchValue(user[e]);
        }
        this[e].markAsTouched();
      }
    });
  }

  saveChanges(): void {
    if (this.type === 'EDIT') {
      this.editUser();
    } else {
      this.createUser();
    }
  }

  editUser(){
    const sendObj = this.mapAndFormatObject();

    this.subs.sink = this.peopleApi.updateUser(sendObj, this.originalUserId).subscribe(
      (res) => {
        this.toastSrv.showToastSucess(this.translate.instant('EDIT-PEOPLE.API-EDIT-OK'));
        if (this.fromRoute === 'detail') {
          this.peopleSrv.setSharedUser(this.user);
          this.router.navigate(['people', 'detail'], {queryParams: {id: this.user.id}});
        } else {
             this.router.navigateByUrl('people');
        }
      },
      (error) => {
        let code = error.error.code;
        let msg = this.translate.instant('EDIT-PEOPLE.ERROR-CREATING');
        if(code == Codes.PsyPortal.REQUEST_ERROR)
          msg = this.translate.instant('EDIT-PEOPLE.MAIL-ID-REPEATED');
        this.toastSrv.showToastError(msg);
      }
    );
  }

  createUser() {
    this.user.createdBy = this.authSrv.getCurrentPsyUser().id;
    const sendObj = this.mapAndFormatObject();

    this.subs.sink = this.peopleApi.createUser(sendObj).subscribe(
      res => {
        this.toastSrv.showToastSucess(this.translate.instant('EDIT-PEOPLE.API-CREATE-OK'));
        this.router.navigateByUrl('people');
      },
      error => {
        let code = error.error.code;
        let msg = this.translate.instant('EDIT-PEOPLE.ERROR-CREATING');
        if (code == Codes.PsyPortal.REQUEST_ERROR){
          msg = this.translate.instant('EDIT-PEOPLE.MAIL-ID-REPEATED'); }
        this.toastSrv.showToastError(msg);
    });
  }

  mapAndFormatObject(): void {
    Object.keys(this.editForm.value).forEach(e => {
      if (['gender', 'education_id', 'occupation_id', 'country_id'].includes(e)) {
        this.user[e] = this.editForm.value[e]?.code ? this.editForm.value[e]?.code : null;
      } else if (e === 'birthdate') {
          if (this.editForm.value[e] === '') {
            this.user[e] = null;
          } else {
            this.user[e] = this.editForm.value[e];
          }
      } else {
        this.user[e] = this.editForm.value[e];
      }
    });


    const obj = JSON.parse(JSON.stringify(this.user));

    // Clean not needed data to be sended
    delete obj.questionnaires;

    // Set email
    if (obj.email === '' || obj.email === undefined){
      obj.email = null;
    }

    // Set only groupIds
    obj.testTakerGroups = obj.testTakerGroups?.map(_group => _group.id);

    return obj;
  }

  initDropdowns(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.formData?.genders.forEach(x => {
        x.name = this.translate.instant(`SELECT-GENDER.${x.code.toUpperCase()}`);
      });

      this.formData?.educations.map(x => {
        x.code = x.id,
        x.name = this.translate.instant(`SELECT-EDUCATIONS.${x.education.toUpperCase()}`);
      });

      this.formData?.countries.forEach(x => {
        x.name = this.translate.instant(`SELECT-COUNTRY.${x.code}`);
      });

      for (let x = 1; x < 16; x++) {
        this.formData?.occupations.push({
          code: x,
          name: this.translate.instant(`SELECT-OCCUPATION.${x}`)
        });
      }
      this.loaded = true;
    });
  }

  get friendlyId(): any {
    return this.editForm.get('friendlyId');
  }

  get name(): any {
    return this.editForm.get('name');
  }

  get gender(): any {
    return this.editForm.get('gender');
  }

  get birthdate(): any {
    return this.editForm.get('birthdate');
  }

  get surname(): any {
    return this.editForm.get('surname');
  }

  get email(): any {
    return this.editForm.get('email');
  }

  get country_id(): any {
    return this.editForm.get('country_id');
  }

  get nationality(): any {
    return this.editForm.get('nationality');
  }

  get city(): any {
    return this.editForm.get('city');
  }

  get address(): any {
    return this.editForm.get('address');
  }

  get phonenumber(): any {
    return this.editForm.get('phonenumber');
  }

  get education_id(): any {
    return this.editForm.get('education_id');
  }

  get occupation_id(): any {
    return this.editForm.get('occupation_id');
  }

  get notes(): any {
    return this.editForm.get('notes');
  }

  deleteGroup($event) {
    // Mark group as not selected
    $event.selected = false;
    // Remove group of testtaker
    this.user.testTakerGroups = this.user.testTakerGroups.filter(_group => _group.id !== $event.id);
    // Unselect group in modal
    this.testTakerGroupSelector.selectItem($event);
  }

  loadSelectedDataModal($event){
    this.user.testTakerGroups = $event;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#region Import Users From CSV

  /**
   * Fires the upload action in p-fileUploader component
   */
  executeUpload(): void{
    this.fileUpload.upload();
  }

  reloadListIfFileSelected(): void{
    if (this.showPreview){
      this.reloadListOnDelimiterChange(this.fileUpload._files);
    }
  }

  /**
   * This is the Upload event, where all resultant users in the
   * list managed by the psyuser are exported as new users.
   */
  async uploadTesttakerSavedList(){

    let resp = await this.peopleApi.createUsers(this.usersToSave).toPromise();
    let errorCreating: boolean = Object.keys(resp.data.failed).length > 0;

    if (errorCreating){
      this.toastSrv.showToastError(this.translate.instant('EDIT-PEOPLE.ERROR-IMPORTING'));
    }else{
      this.toastSrv.showToastSucess(this.translate.instant('EDIT-PEOPLE.API-CREATE-OK'));
      this.showImportUsersModal = false;
      this.router.navigateByUrl('people');
    }
  }

  /**
   * Method called upon selected file to parse action, and change of delimitier
   *
   * Updates the list of testtaker obtained from the CSV file, and in case of
   * another delimitier has been chosen
   * @param event;
   */
  async updateCSVTesttakerList(event: any): Promise<void> {

    // Applyng focus to other element to prevent pressing buttons and launching the parsing with errors
    setTimeout(() => {
      this.inputFriendlyId.nativeElement.focus();
    }, 0 );

    // Start the processing
    this.showSpinner = true;
    const files = event.currentFiles;

    //Parse the testtakers from the csv file
    let operationResult = await this.excelCsvSrv.parseTesttakersFromFile(files, this.selectedParser)
    if(operationResult.parseError || operationResult.delimiterProblem){
      if(operationResult.parseError){
        this.showSpinner = false;
        this.toastSrv.showToastError(this.translate.instant('EDIT-PEOPLE.ERROR-PARSING'));
      }

      //If wrong delimiter case is found, try to automatically change it
      if(operationResult.delimiterProblem){
        this.wrongDelimiterCase = true;
      }
    }

    //Process the rows of the imported buffer, update total error/warning count
    let usersRetrievedResult = this.excelCsvSrv.processTesttakersFromCSVBuffer(operationResult.usersToSaveList)

    //Check if users are already imported:

    let checkTesttakers= await this.peopleApi.validateAndClassifyUsersToImport(usersRetrievedResult.testtakers).toPromise();
    this.usersAlreadySaved= checkTesttakers.data.existingUsers;
    this.usersToSave= checkTesttakers.data.newUsers;
    this.csvHasDuplicatedUsers = checkTesttakers.data.csvHasDuplicatedUsers;

    this.parseTotalErrorsFound = usersRetrievedResult.parseErrorNumber
    this.parseTotalWarningsFound = usersRetrievedResult.parseWarningNumber

    if(this.csvHasDuplicatedUsers){
      this.toastSrv.showToastWarning(this.translate.instant('EDIT-PEOPLE.DUPLICATED-USERS'));
    }

    //Show results in GUI
    this.showSpinner = false;
    this.showPreview = true;
  }

  /**
   * Transform array of text strings, into one text with /n
   */
  formatMessages(messagesArray): any{
    return messagesArray.join('\n');
  }

  /**
   * Delete the user selected and clear his errors and warnings
   * @param $event 'user selected'
   */
  deleteUserCSV($event): void{
    this.usersToSave = this.usersToSave.filter(user=> user != $event)

    if ($event.errorMessages.length > 0){
      this.parseTotalErrorsFound = this.parseTotalErrorsFound - $event.errorMessages.length;
    }

    if ($event.warningMessages.length > 0){
      this.parseTotalWarningsFound = this.parseTotalWarningsFound - $event.warningMessages.length;
    }

    // If no more users are left in the list, restart GUI
    if (this.usersToSave.length === 0){
      this.clearFileUpload();
    }
  }

  /**
   * To be removed, actually not used.
   * @param $event
   */
  selectUserCSV($event): void{
  }

  /**
   * Reload the parsed list using the different sepparator
   * @param selectedFiles
   */
  reloadListOnDelimiterChange(selectedFiles): void{
    this.usersToSave = [];
    this.parseTotalErrorsFound = 0;
    this.parseTotalWarningsFound = 0;
    this.wrongDelimiterCase = false;

    // Emulate select file event '{currentFiles: selectedFiles}'
    this.updateCSVTesttakerList({currentFiles: selectedFiles})
  }

  /**
   * Restart GUI, clear file selection and variables
   */
  clearFileUpload(): void {
    this.fileUpload.clear();
    this.showPreview = false;
    this.usersToSave = [];
    this.parseTotalErrorsFound = 0;
    this.parseTotalWarningsFound = 0;
    this.wrongDelimiterCase = false;
  }

  //#endregion
}
