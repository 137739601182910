import { Component, OnInit } from '@angular/core';
import { NgxCookiebotConfig, NgxCookiebotService } from '@halloverden/ngx-cookiebot';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  constructor(private _cookieBotService: NgxCookiebotService) { }


  ngOnInit(): void {
  }

}