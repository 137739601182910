import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExcelCsvService } from '../../services/excel-csv.service';
import { ToastService } from '../../services/toast.service';
import { PadNumberPipe } from '../../pipes/pad-number.pipe';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [ ToastService, PadNumberPipe ]
})
export class HistoryComponent implements OnInit {

  @Input() history = [];
  @Input() psyUserName = '';
  @Input() filterByActiveInactiveHistory = true;
  @Input() psyUserSubscriptions;

  listHistoryChunk = [];
  rowsToDisplayDefault: number = 10;
  topRow = this.rowsToDisplayDefault;
  originalHistoryGroup = [];
  filterCount = 0;

  constructor(
    private translate: TranslateService,
    private excelCsvSrv: ExcelCsvService,
    private toastSrv: ToastService
  ) { }

  ngOnInit(): void {
    // Order by creation date DESC by default
    this.history.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());    
    
    // Set isAlive or not based in psyUser subscriptions
    this.setHistoryAliveBasedInSubscription();

    // Preserve the oriringal array list
    this.originalHistoryGroup = this.history;

    this.history = this.history.map(transaction => {
      if (transaction.weight === 0) {
        transaction.status = 'zero';
      } else {
        transaction.status = transaction.weight > 0 ? 'positive' : 'negative';
      }
      return transaction;
    });
    
    this.listHistoryChunk = this.history.slice(0, this.rowsToDisplayDefault);    
  }

  setHistoryAliveBasedInSubscription(){    
    if(this.psyUserSubscriptions){
      this.psyUserSubscriptions.filter(_sub => _sub.isAlive).forEach(_sub => {      
        this.history.filter(_hist => _hist.customerHasPlan_id == _sub.sub_id).forEach(_hist => _hist.isAlive = true);
      });
      const psyUserSubcriptionsAlives = this.psyUserSubscriptions.filter(_sub => _sub.isAlive).map(_sub => _sub.sub_id); // To avoid duplicates    
      this.psyUserSubscriptions.filter(_sub => !_sub.isAlive && !psyUserSubcriptionsAlives.includes(_sub.sub_id)).forEach(_sub => {
        this.history.filter(_hist => _hist.customerHasPlan_id == _sub.sub_id).forEach(_hist => _hist.isAlive = false);
      });
    }
  }

  paginate(event): any {
    this.topRow = event.first + event.rows;
    if (this.topRow > this.history.length) {
      this.topRow = this.history.length;
    }
    this.listHistoryChunk = this.history.slice(event.first, this.topRow);
  }

  getTranslationType(hist) {
    if(hist.customerHasPlan?.plan.planType.withCredits === true)
      return this.translate.instant('PROFILE.CREDITS');

    if(hist.customerHasPlan?.plan.deduction.scoring)
      return this.translate.instant('ACCOUNTS.LABEL-SCORING');
    
    if(hist.customerHasPlan?.plan.deduction.reporting)
      return this.translate.instant('ACCOUNTS.LABEL-REPORTING');

    if(hist.customerHasPlan?.plan.deduction.administration)
      return this.translate.instant('ACCOUNTS.LABEL-ADMINISTRATION');
  }

  applyFilter($event): void {
    this.history = $event;
    this.filterCount = $event.length;
    this.listHistoryChunk = this.history.slice(0, this.rowsToDisplayDefault);
  }

  resetFilter($event): void {
    this.history = this.originalHistoryGroup;
    this.listHistoryChunk = this.history.slice(0, this.rowsToDisplayDefault);
  }

  async downloadHistory() {
    if(await this.excelCsvSrv.generateExcelHistory(this.history, this.psyUserName)){  
      this.toastSrv.showToastSucess(this.translate.instant('EXCEL.MENU-DOWNLOAD-SUCCESS'));
    }else{
      this.toastSrv.showToastError(this.translate.instant('EXCEL.MENU-DOWNLOAD-ERROR'));
    }
  }
}
