import { Injectable } from '@angular/core';
import { io } from 'socket.io-client'
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private gateUrl = environment.apiGateURL;
  private socket: any;

  constructor(
    private authSrv: AuthenticationService,
    private projectSrv: ProjectsService
  ) { }

  /**
   * Connect to socket
   */
  connect(){
    // Reconnection is false to avoid multiple connection loop
    this.socket = io(this.gateUrl, {'reconnection': false})
      .on('connect', ()=>{
          console.log(`Connected socket to socket with id ${this.socket.id}`);
          // Join the room with the psyuser id
          this.joinRoom();          
      })
      .on('scoringResults', (results: any)=> {
        console.log('scoringResults');
        console.log(results);
        this.authSrv.setScoringNotifications(results);
      })
      .on('reportingResults', (results: any)=> {
        console.log('reportingResults');
        console.log(results);
        this.authSrv.setReportingNotifications(results);
      })
      .on('downloadReportingResults', (results: any)=> {
        console.log('downloadReportingResults');
        console.log(results);
        this.authSrv.setDownloadReportingNotifications(results);
      });
  }
  
  getDownload(questionnaireCode,report_id,project_id){ 
      const psyUser = this.authSrv.getCurrentPsyUser();
      console.log('getDownload: '+project_id );
      this.socket.emit('getDownload',{"psyuser_id":psyUser.id,"report_id":report_id,"project_id":project_id,"questionnaireCode":questionnaireCode});   
  }
 
  /**
   * Join a room with the psyUserId
   */
  joinRoom(){
    const psyUser = this.authSrv.getCurrentPsyUser();
    console.log('join-room '+psyUser.id );
    this.socket.emit('join-room', `${psyUser.id}` );
  }

  /**
   * Disconnect from socket
   */
  disconnect() {
    try {
      this.socket.disconnect()
    }catch(ex){
      // Do Nothing, just for avoid errors
    }    
  }
}
