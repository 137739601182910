/**
 * Enum to set the project status by string
 */
 export enum ProjectStatusString {
    "CREATED" = "created",
    "ASSIGNED" = "assigned",
    "FILLING" = "in-progress",
    "COMPILED" = "performed",
    "READY_TO_SCORE" = "performed",
    "SCORING_ONGOING" = "scoring",
    "SCORED" = "scored",
    "SCORING_ERROR" = "error",
    "REPORT_SERVED" = "finished",
    "REPORT_ERROR" = "error",
    "FAILURE" = "error",
    "INVALID_SCORING" = "force",
    "REPORT_ONGOING" = "reporting",
    "SCORED_NON_VALID" = "non-valid"
}