import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  public temporalUserList = [];

  constructor() { }

  public clearTemporalUserList(){
    this.temporalUserList = [];
  }

  public addUsersTemporal(users){
    this.temporalUserList = [...users];
  }

  public removeUserTemporal(user){
    this.temporalUserList = this.temporalUserList.filter(_user => _user.id !== user.id);
  }

  public getUserTemporalList(){
    return this.temporalUserList;
  }

  public getUserTemporal(event: LazyLoadEvent) {
    if(event.sortField == 'name'){
      this.orderUsersByName(event.sortOrder);
    }

    if(event.sortField == 'createdAt'){
      this.orderUsersCreatedAt(event.sortOrder);
    }

    return {
      data : {
        rows: this.temporalUserList.slice(event.first, event.rows + event.first),
        count: this.temporalUserList.length
    }};
  }

  private orderUsersByName(order){
    this.temporalUserList.sort((a, b) => {
      // Order by name
      const nameA = a.name !== null && a.name !== undefined && a.name !== 'null' ? a.name : a.friendlyId;
      const nameB = b.name !== null && b.name !== undefined && b.name !== 'null' ? b.name : b.friendlyId;
      if(order == 1) {
        return nameA.localeCompare(nameB);
      } else  {
        return nameB.localeCompare(nameA);
      }
    });
  }

  private orderUsersCreatedAt(order){
    if(order == 1) {
      this.temporalUserList.sort((a, b) => (moment(b.createdAt).diff(moment(a.createdAt))));
    } else {
      this.temporalUserList.sort((a, b) => (moment(a.createdAt).diff(moment(b.createdAt))));
    }
  }
}
