<app-row-card
    [optionLeft]="optionsLeft"
    [optionRight]="optionsRight"
    [customNopeopleTl]="groupItems"
    [item]="item"
    [triggerName]="triggerName"
    (itemDeleted)="deleteItem()"
    (itemSelected)="selectItem()"
    (itemTriggered)="triggerItem()"
    (click)="selectItemOpt()">
</app-row-card>

<ng-template #groupItems>
    <div class="p-grid p-ai-center container-data">
        <div *ngIf="item.name && item.surname; then thenTmp else elseTmp"></div>
            <ng-template #thenTmp>
                <p-avatar
                    label="{{item.name.charAt(0)}}"
                    class="row-avatar user"
                    [ngClass]="{'circle-selected': item.selected}"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
            <ng-template #elseTmp>
                <p-avatar
                    icon="pi pi-user"
                    class="row-avatar user"
                    [ngClass]="{'circle-selected': item.selected}"
                    size="xlarge"
                    shape="circle">
                </p-avatar>
            </ng-template>
            <div class="p-d-flex p-flex-column p-ai-start">
                <div *ngIf="item.name && item.surname; then thenTemp else elseTemp"></div>
                <ng-template #thenTemp>
                    <div *ngIf="from == 'detail'" class="row-name p-mx-3">
                        {{item.name}} {{item.surname}} - {{item.email}}
                    </div>
                    <div *ngIf="!from">
                        <span *ngIf="confirmation" class="row-name p-mx-3">
                            {{AddThreeDotsIfTextLongerThan32(item.name+" "+item.surname+" - "+ item.email)}}
                            <!-- {{item.name}} {{item.surname}} - {{item.email}} -->
                        </span>
                        <span *ngIf="!confirmation" class="row-name p-mx-3">
                            {{AddThreeDotsIfTextLongerThan32(item.name+" "+item.surname)}}
                            <!-- {{item.name}} {{item.email}} -->
                        </span>
                    </div>
                </ng-template>
                <ng-template #elseTemp>
                    <span class="row-name p-mx-3">
                        {{item.friendlyId}}
                    </span>
                </ng-template>
                <span class="row-id p-mx-3">{{'PEOPLE.USER' | translate}} #{{AddThreeDotsIfTextLongerThan32(item.friendlyId? item.friendlyId:item.testtakerFriendlyId)}}</span>
                <span *ngIf="item.email" class="row-email">[{{item.email}}]</span>
            </div>

            <!-- CSV IMPORT USERS ITEM, SHOW ALERTS WHEN ERRORS AND WARNINGS HAPPENS -->
            <!-- Warning Icon -->
            <div class="p-d-flex p-flex-column" *ngIf= "item.warningMessages?.length > 0">
                <div *ngIf= "item.warningMessages.length > 0">
                    <i class="pi pi-info-circle warningIcon" pTooltip="* {{item.warningMessages.join('\n * ')}}"></i>
                </div>
            </div>

            <!-- Error Icon -->
            <div class="p-d-flex p-flex-column" *ngIf= "item.errorMessages?.length > 0">
                <div *ngIf= "item.errorMessages.length > 0">
                    <i class="pi pi-times-circle errorIcon" pTooltip="* {{item.errorMessages.join('\n * ')}}"></i>
                </div>
            </div>
    </div>
</ng-template>