<h1 *ngIf="!embedded">{{ 'FILTER.PROJECTS' | translate }}</h1>

<button
    *ngIf="embedded"
    pButton
    class="btn-mini3 p-mx-3"
    [routerLink]="['/projects']"
    label="{{'DASHBOARD.VIEW-PROJECTS' | translate}}">
</button>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="storeActiveIndex()">
    <p-tabPanel header="{{'PROJECTS.INDIVIDUALS'|translate}}">
        <app-projects
            *ngIf="activeIndex === ProjectSize.INDIVIDUAL"
            #individualProjects
            [projectType]="'individual'"
            [embedded]="embedded">
        </app-projects>
    </p-tabPanel>
    <p-tabPanel header="{{'PROJECTS.GROUPS'|translate}}">
        <app-projects
            *ngIf="activeIndex === ProjectSize.GROUPAL"
            #groupProjects
            [projectType]="'projectgroup'"
            [embedded]="embedded">
        </app-projects>
    </p-tabPanel>
</p-tabView>
