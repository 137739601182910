import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { listAnimation } from 'src/app/shared/animations/list';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { PeopleApi } from '../../api/people.api';
import { PeopleService } from '../../services/people.service';


@Component({
  selector: 'app-detail-people',
  templateUrl: './detail-people.component.html',
  styleUrls: ['./detail-people.component.scss'],
  providers: [ToastService],
  animations: [listAnimation]
})
export class DetailPeopleComponent implements OnInit, OnDestroy {

  user: any;
  showList = false;
  questionnaires = [];
  loaded = false;

  listUser = [];

  subs = new SubSink();

  private confirmSubscription: Subscription;

  constructor(
    private peopleSrv: PeopleService,
    private toastSrv: ToastService,
    private toastConfirmSrv: ToastConfirmService,
    private translate: TranslateService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private peopleApi: PeopleApi,
    private projectsApi: ProjectsApi,
    private projectsSrv: ProjectsService,
    private errorsSrv: ErrorsService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.activatedroute.queryParamMap.subscribe(params => {
        this.subs.sink = this.peopleApi.getUser(params['params']['id']).subscribe(
        usr => {
          this.user = usr.data;
          this.user.name = this.user.name ? this.user.name + '' : '';
          this.questionnaires = usr.data.questionnaires;
          this.formatQuestionnaires();
          this.loaded = true;
        },
        err => {
          this.router.navigate(['notfound']);
        });
    });
  }

  formatQuestionnaires(): void {
    this.questionnaires = this.questionnaires.map(x => {
      x.testtaker_id = this.user.id
      return {
        createdAt: x.project.createdAt,
        testName: x.test.title,
        status: this.projectsSrv.getProjectStatusStringByCode(x.statusCode),
        projectId: x.project.id,
        parentTestName: x.test.parentTest.test,
        locale: x.test.locale,
        project: x.project,
        tests: [x],
        test: x.test,
        testtakers: [this.user],
        isGroupal: x.project.isGroupal ? true : false,
        code:x.project.code ? x.project.code : undefined,
        projectName:x.project.projectName ? x.project.projectName : undefined,
      };
    });
  }

  deleteTestFromProject(item): void {
    this.subs.sink = this.projectsApi.deleteTestProject(item.projectId, item.test.id).subscribe(res => {
      this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-DELETE-TEST-OK'));
      this.questionnaires=this.questionnaires.filter(x => !(x.test.id==item.test.id && x.projectId==item.projectId));
    },
    error => {
      let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
        error.error.code,
        {
          customEnvironment:"PROJECTS.DELETE-TEST-ERRORS.HTTP-CODES",
        }
      )
      this.toastSrv.showToastError(messageToShow.translatedMessage);
    });
  }

  confirmDeleteTest($event): void {
      this.toastSrv.showToastConfirm(this.translate.instant('PROJECTS.CONFIRM-DELETE-TEST'));

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.deleteTestFromProject($event);
        this.confirmSubscription.unsubscribe();
      }
      if (res === false){
        this.confirmSubscription.unsubscribe();
      }
    });
  }
  confirmDelete(): void {
    const name = this.user.name !== (undefined || null || '') ? `${this.user.name} ${this.user.surname}` : `#${this.user.id}`;

    this.toastSrv.showToastConfirm(this.translate.instant('DETAIL-PEOPLE.API-DELETE-CONFIRM').replace('${username}', name));

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true ){
        this.subs.sink = this.peopleApi.deleteUsers([this.user.id]).subscribe(
            () => {
              this.toastSrv.showToastSucess(this.translate.instant('DETAIL-PEOPLE.API-DELETE-OK'));
              this.router.navigateByUrl('people');
            },
            error => {
              this.toastSrv.showToastSucess(this.translate.instant('DETAIL-PEOPLE.API-DELETE-OK'));
              this.router.navigateByUrl('people');
        });
        this.confirmSubscription.unsubscribe();
      }
    });
  }

  deleteGroup($group): void{


    this.toastSrv.showToastConfirm(this.translate.instant('PEOPLE.CONFIRM-DELETE-GROUP'), 'warning');

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true) {
        const deleteObj = {
          groupID: $group.id,
          testtakersIDList: [this.user.id]
        };

        this.subs.sink = this.peopleApi.deleteUsersGroup(deleteObj).subscribe(
          res => {
            this.toastSrv.showToastSucess(this.translate.instant('DETAIL-PEOPLE.API-DELETE-GROUP-OK'));
            this.user.testTakerGroups = this.user.testTakerGroups.filter(x => x.id !== $group.id);
          },
          error => {
            this.toastSrv.showToastError(error);
        });
      }
      if (res === false) {
        this.confirmSubscription.unsubscribe();
      }
    });
  }


  getEducationByCode(code: string): string{
    return this.peopleSrv.getEducationByCode(code);
  }

  getOccupationByCode(code: string): string{
    return this.peopleSrv.getOccupationByCode(code);
  }

  getCountryByCode(code: string): string {
    return this.peopleSrv.getCountryByCode(code);
  }

  assignTest(): void {
    let project = this.projectsSrv.generateNewProject();
    this.user.selected = true;
    project.testtakers.push(this.user);

    if(this.projectsSrv.setCurrentProject(project)){
      this.router.navigate(['projects', 'create']);
    } else {
      this.toastSrv.showToastError(this.translate.instant('SHARING-PANEL.MAX-REACHED'));
    }
  }

  openProject($event): void {
    this.subs.sink = this.projectsApi.getProject($event.projectId).subscribe(res => {
      this.projectsSrv.setCurrentProject(res.data);
      this.router.navigate(['projects', 'details']);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
