import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";
import { NgxCsvParser } from 'ngx-csv-parser';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import * as xlsx from 'sheetjs-style-v2'
import * as moment from "moment";
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { QuestionnaireCodeStatus, Status } from '@giuntipsy/utils/lib';
import { EntryType } from '../enums/entry-type.enum';


////// Specific Types for XLSX Excel Operations /////
// Cell
// c: columnNumber, r: rowNumber
type cell = { c: number, r: number }

// Merge Range Cells
// s: Source cell, e: End cell for merge the entire cell space between
type mergeCellRange = { s: cell , e: cell }

//Excel row sctructure to generate with merges included.
type rowExcelStructure = {
  dataPositions : any[],
  mergeRanges: mergeCellRange[],
  rowNumber: number,
  error: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ExcelCsvService {

  private url = environment.apiUrl;
  projectEntryType = EntryType;

  //-----BACKGROUND AND BORDERS STYLES--------
  private readonly blueGiuntiFill = {
    fgColor: {
      rgb: "FF006eb6",
      auto: 1
    },
  }

  private readonly blueHeavyGiuntiFill = {
    fgColor: {
      rgb: "FF004379",
      auto: 1
    },
  }

  private readonly blueSoftGiuntiFill = {
    fgColor: {
      rgb: "FFe9f0fa",
      auto: 1
    },
  }

  private readonly whiteBoldFont = {
    bold: true,
    color: {
      rgb: "FFE9F0FA"
    }
  }

  private readonly blackBoldFont = {
    bold: true,
    color: {
      rgb: "FF000000"
    }
  }

  private readonly borderTitle = {
    right:{style:"medium", color: {rgb: "FF000000"}},
    left:{style:"medium", color: {rgb: "FF000000"}},
  }

  private readonly borderTitle2 = {
    top:{style:"medium", color: {rgb: "FF000000"}},
    right:{style:"medium", color: {rgb: "FF000000"}},
    left:{style:"medium", color: {rgb: "FF000000"}},
    bottom:{style:"medium", color: {rgb: "FF000000"}},
  }

  private readonly borderThin = {
    top:{style:"thin", color: {rgb: "FF000000"}},
    right:{style:"thin", color: {rgb: "FF000000"}},
    left:{style:"thin", color: {rgb: "FF000000"}},
    bottom:{style:"thin", color: {rgb: "FF000000"}},
  }

  //------------------------------------------

  constructor(
    private http: HttpClient,
    private authSrv: AuthenticationService,
    private ngxCsvParser: NgxCsvParser,
    private translate: TranslateService,
    private projectsApi: ProjectsApi
    ) { }


  ////// CSV IMPORT FUNCTIONS SECTION ////////
  /**
   * Parse the selected file using the parser in the argument.
   * @param files
   * @param selectedParser
   * @return userList, delimiterProblem, parseError
   */
  async parseTesttakersFromFile(files, selectedParser): Promise<any>{

    let operationResult = {
      usersToSaveList: null,
      delimiterError: false,
      parseError: false
    }

    let result : any;
    try {
      result = await this.ngxCsvParser.parse(files[0], { header: true, delimiter: selectedParser.code }).pipe().toPromise();

    } catch (error) {
      operationResult.parseError = true;
      console.error("Error parsing: ",error)
      return operationResult
    }

    let userRecords = result

    //Check parse error or empty file
    if (!userRecords || userRecords.length <= 0){
      operationResult.parseError = true;
      return operationResult
    }

    // If only one column is parsed, the delimiter has to be wrong, min are 2 columns, friendlyID and gender
    const csvColumns = Object.keys(userRecords[0]);
    if (csvColumns.length === 1){
      operationResult.delimiterError = true;
      //Continue with operation
    }

    //Apply trimmer processing to the retrieved users to remove white spaces or blanks
    for (let i = 0; i < userRecords.length; i++) {
      userRecords[i] = JSON.parse(JSON.stringify(userRecords[i]).replace(/"\s+|\s+"/g,'"'));
    }

    operationResult.usersToSaveList = userRecords
    return operationResult;
  }

  /**
   * Extract the users from the csv row elements and check
   * the problems encountered during the process, if any
   * add them to the items.
   * Returns the list of testtakers found and overview of operation.
   * @param CSVBufferElements
   * @returns operationResult{JSON}
   */
  processTesttakersFromCSVBuffer(CSVBufferElements): any{

    let operationResult = {
      testtakers: [],
      parseErrorNumber: 0,
      parseWarningNumber: 0
    }

    let psyuserResponsible = this.authSrv.getCurrentPsyUser();
    if(!psyuserResponsible){
      console.error("Session lost, we counld'nt retrieve the psyuser")
      operationResult.parseErrorNumber++
      return operationResult
    }

    // For each testtaker parsed from csv testtaker list file
    CSVBufferElements.forEach((parsedTesttaker: any) => {

      let testTakerItem: any  = {};
      try {
        // import column names
        const csvColumnNames = Object.keys(parsedTesttaker);

        // ///TestTakers Expected Table Headers//////
        // Code	| Gender | Name |	Surname |	Email //

        // Parse testtaker string fields from object readed in CSV
        testTakerItem.friendlyId = this.importCSVFieldByColumnIndex(csvColumnNames,parsedTesttaker, 0);
        testTakerItem.name = this.importCSVFieldByColumnIndex(csvColumnNames, parsedTesttaker, 2);
        testTakerItem.surname = this.importCSVFieldByColumnIndex(csvColumnNames, parsedTesttaker, 3);

        // Parse special cases, Gender, etc..
        let genderFromCSV = this.importCSVFieldByColumnIndex(csvColumnNames, parsedTesttaker, 1);
        testTakerItem.gender = this.parseValidGenderOrNull(genderFromCSV);

        let emailFromCSV = this.importCSVFieldByColumnIndex(csvColumnNames, parsedTesttaker, 4);
        testTakerItem.email = this.checkValidEmailOrNull(emailFromCSV);

        // Add metadata and customer responsible
        testTakerItem.createdBy = psyuserResponsible.id;
        testTakerItem.customer_id = psyuserResponsible.customer_id;

      } catch (error) {
        console.error('Error parsing testtaker item: ', error);
        testTakerItem.errorMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-UNHANDLED-ERROR'));
        operationResult.parseErrorNumber++
      }

      // Evaluate the resulting testtaker and add error/warning messages accordingly
      this.evalErrorAndWarningMessages(testTakerItem, operationResult);

      // Add the testtaker to the total list with its according errors/warnings messages included
      operationResult.testtakers.push(testTakerItem);
    });
    return operationResult
  }

  /**
   * This method decide which fields are mandatory(errors), which are
   * recommendable(warnings) and add these messages to testTaker item
   *
   * Also updates the TOTAL error/warnings count
   * @param testTakerItem;
   */
  private evalErrorAndWarningMessages(testTakerItem: any, operationResult : any): void {

    // Define arrays to save the corresponding messages
    testTakerItem.errorMessages = [];
    testTakerItem.warningMessages = [];

    if (!testTakerItem.friendlyId) {
      testTakerItem.errorMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-CODE-ERROR'));
      operationResult.parseErrorNumber++
    }

    if (!testTakerItem.gender) {
      testTakerItem.errorMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-GENDER-ERROR'));
      operationResult.parseErrorNumber++
    }

    if (!testTakerItem.customer_id) {
      testTakerItem.errorMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-UNHANDLED-ERROR'));
      operationResult.parseErrorNumber++
    }

    if (!testTakerItem.name) {
      testTakerItem.warningMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-NAME-WARNING'));
      operationResult.parseWarningNumber++
    }

    if (!testTakerItem.surname) {
      testTakerItem.warningMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-SURNAME-WARNING'));
      operationResult.parseWarningNumber++    }

    if (!testTakerItem.email) {
      testTakerItem.warningMessages.push(this.translate.instant('EDIT-PEOPLE.IMPORT-MAIL-WARNING'));
      operationResult.parseWarningNumber++
    }
  }

  /**
   * Search in a csvDataObject, or excel data row, the string data type field 'dataField'
   * @param csvDataObject
   * @param csvColumnNames 'Array of column names'
   * @param columnIndex 'Index position of the column to be parsed'
   * @returns
   */
  importCSVFieldByColumnIndex(csvColumnNames, csvDataObject, columnIndex): any{

    let csvColumnName = csvColumnNames[columnIndex]

    // Found column to import
    let fieldFound = csvDataObject[csvColumnName];

    // Check validity of string, type and length > 0
    if (typeof fieldFound !== 'string') {
      return null;
    }
    if (fieldFound.length === 0){
      return null;
    }

    // Success
    return fieldFound;
  }

  /**
   * Search in a csvDataObject, or excel data row, the string data type field 'dataField'
   * @param csvDataObject
   * @param parsedColumnNames 'List of table headers'
   * @param fieldNameToFind 'Name of the desired header data column'
   * @returns
   */
  importCSVStringField(csvDataObject, dataField): any{

    // Found column to import
    let stringFound = csvDataObject[dataField];

    // Check validity of string, type and length > 0
    if (typeof stringFound !== 'string') {
      return null;
    }
    if (stringFound.length === 0){
      return null;
    }

    // Success
    return stringFound;
  }

  /**
   * Difference between male or female
   * @param parsedGender;
   * @returns @gender or @null
   */
  parseValidGenderOrNull(parsedGender): any{
    if (parsedGender == null){
      return null;
    }

    if (parsedGender === 'F'){
      return 'female';
    }

    if (parsedGender === 'M'){
      return 'male';
    }

    // LGTBI case!
    return null;
  }

  /**
   * Check if string @parsedEmail is a valid email direction
   * @param parsedEmail;
   * @returns @parsedEmail or @null
   */
  checkValidEmailOrNull(parsedEmail): any{
    const regexCheckEmail = /^[^\s@]+@[^\s@]+$/;
    if(regexCheckEmail.test(parsedEmail)){
      return parsedEmail
    }
    return null;
  }

  ///--------- Export Scoring to Excel SECTION ---------///

  async createScoringExcel(project, relatedTest, profileList) {
    try {
      /// get profileData structures
      let questList = [...project.questionnaires];
      // Filter to get only the related test by params
      questList = questList.filter(_quest => relatedTest.map(_test => _test.id).includes(_quest.test_id));

      ///set profileData and questionnaireResponses in each questionnaire
      questList.forEach(_quest =>{
        let selectedProfile = profileList.find(_profile => _profile.questionnaireCode === _quest.questionnaireCode);
        _quest['profileData'] = selectedProfile.profileData;
        _quest['questionnaireResponses'] = selectedProfile.questionnaireResponses;
      });

      let scoringWorkbook = xlsx.utils.book_new();

      scoringWorkbook.Props = {
        Title: project.code ,
        Subject: "ScoringData",
        Author: "Giuntipsy",
        CreatedDate: new Date()
      };

      ///For scoringResults
      let testKey = "";
      let scoringResultsWorkSheet: xlsx.WorkSheet;
      let resultsRows = [];
      let testSheet : any[] = [];

      const _scoringRows = await this.createExcelScoringRows(relatedTest, testSheet, testKey, questList, resultsRows, project, scoringResultsWorkSheet, scoringWorkbook);

      if (_scoringRows === 'error create excel scoring rows') {
        throw new Error(_scoringRows);
      }

      ///For entry mask responses
      let answersRows = [];
      let entryMaskSheet: any[] = [];
      const promises = [];

      // Create a promise for each related test
      for (let i = 0; i < relatedTest.length; i++) {
        let testQuest = questList.filter(_quest => _quest.test_id === relatedTest[i].id);
        testKey = relatedTest[i].testKey;

        const promise = this.createExcelAnswerSheets(testQuest[0].questionnaireCode, entryMaskSheet, testKey, testQuest, answersRows, project, scoringWorkbook);
        promises.push(promise);
      }

      // Wait for all promises to complete
      await Promise.all(promises);

      //Introduce Scoring Results and Responses worksheets at workbook
      const worksheetName = scoringWorkbook.Props.Title;
      const excelBuffer: any = xlsx.write(scoringWorkbook,{ bookType: 'xlsx', type: 'array' });
      //Download excel file.
      const data: Blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(data, worksheetName + ".xlsx");
      return true;

    } catch (error) {
      let msg = 'error create excel';
      return msg;
    }
  }

  async createExcelScoringRows(relatedTest, testSheet, testKey, questList, resultsRows, project, scoringResultsWorkSheet, scoringWorkbook): Promise<any>{
    for (let i = 0; i < relatedTest.length; i++) {
      testSheet = [];
      testKey = relatedTest[i].testKey

      ///--------FIXED HEADERS FOR ALL TESTS-------////
      let headersRow = [];
      headersRow.push(this.translate.instant('EXCEL.HEADER-FRIENDLYID'));
      headersRow.push(this.translate.instant('EXCEL.HEADER-SHORTCODE'));
      headersRow.push('Status');

    ///----------VARIABLE HEADERS BY TEST------------///

      let headers = await this.getHeadersbyTest(questList, relatedTest[i], headersRow).then(headers =>{
        return headers
      })
      .catch(err =>{
        return 'error create excel scoring rows'
      })

      headersRow = headers[0];
      let testQuest = headers[1];
      let profileHeadersRow = headers[2];
      let scoresHeaders = headers[3];
      let additionalHeaders = headers[4];

      if (additionalHeaders){
        additionalHeaders.splice(0, 0, undefined, undefined, undefined); //Add 3 empty cells (tt_id, qCode and status);
        testSheet.push(additionalHeaders);
      }
      testSheet.push(headersRow);

    /// -------RESULT ROWS (one for each questionnaire)-------- ///

      testQuest.forEach(_quest =>{
        resultsRows = [];
        let selectedTt = project.testtakers.find(_tt => _tt.id === _quest.testtaker_id);
        resultsRows.push(selectedTt.friendlyId); ///friendlyId
        resultsRows.push(_quest.questionnaireCode.substring(0,10)); ///shortQCode

        ///We have 3 status for a questionnaire:
        /// - Available: all data available, report served.
        /// - Only Profile: only profile data available, statuscode between filling and scored_nonValid (includes).
        /// - Unavailable: non-started questionnaires.

        if (_quest.statusCode >= QuestionnaireCodeStatus.REPORT_SERVED){
          resultsRows.push('Available');
          // get profileData responses
          let profileResponses = this.profileDataResultsExcel(_quest, profileHeadersRow);
          resultsRows = [...resultsRows, ...profileResponses];

          //get scoringResults
          let scoringResults = this.scoringResultsExcel(_quest, scoresHeaders);
          resultsRows = [...resultsRows, ...scoringResults];

        } else if (_quest.statusCode < QuestionnaireCodeStatus.REPORT_SERVED && _quest.statusCode >= QuestionnaireCodeStatus.FILLING){
          resultsRows.push('Only profile');
          // get profileData responses
          let profileResponses = this.profileDataResultsExcel(_quest, profileHeadersRow);
          resultsRows = [...resultsRows, ...profileResponses];
        } else {
          resultsRows.push('Unavailable');
        };
        testSheet.push(resultsRows);
      })
      scoringResultsWorkSheet = xlsx.utils.aoa_to_sheet(testSheet);
      this.applyStyleToWorksheet(scoringResultsWorkSheet, additionalHeaders);
      xlsx.utils.book_append_sheet(scoringWorkbook, scoringResultsWorkSheet, testKey);

    };
    return scoringWorkbook;
  }

  getHeadersbyTest(questList, _test, headersRow): Promise<any>{
    //search questionnaires for this test
    let testQuest = questList.filter( _quest => _quest.test_id === _test.id);
    let currentTestStructure;
    let currentScaleData;
    let profileHeaders = {};
    let result;
    let profileHeadersRow = [];
    let maxNum = 0;
    let scoresHeadersRow = [];
    let allKeys = [];
    let additionalHeadersRow;

    ///get current testRules and scaleData
    return this.projectsApi.getTestStructure(testQuest[0].questionnaireCode).toPromise()
    .then( async res => {
      currentTestStructure = res.data.test_structure;
      currentScaleData = res.data.test_structure.scaleData;
      //check if is select to download all profile and set profile headers
      if (currentTestStructure.testRules && currentTestStructure.testRules.downloadAllProfile) {
        // in this case, you get profileData which has the maximum number of elements
        for (let _quest of testQuest){
          if (_quest.profileData && maxNum < Object.values(_quest.profileData).length){
            maxNum = Object.values(_quest.profileData).length;
            profileHeaders = {..._quest.profileData};
          };
        };
        for (const key in profileHeaders) {
          if (Object.prototype.hasOwnProperty.call(profileHeaders, key)) {
            headersRow.push(key);
            profileHeadersRow.push(key);
          };
        };
      } else {
        //If donloadAllProfille is false, we must get availables items at profileData and scoringReportingData by separate
        ///get profileData items
        if (currentTestStructure.profileData){
          let auxProfile = currentTestStructure.profileData.formItems.filter(_item => _item?.downloadForExcel === true);
          auxProfile.forEach(_profileItem => {
            headersRow.push(_profileItem['name']);
            profileHeadersRow.push(_profileItem['name']);
          });
        };
        ///get scoringReportingData items
        if(currentTestStructure.scoringReportingData){
          let auxScorRepData = currentTestStructure.scoringReportingData.formItems.filter(_item => _item?.downloadForExcel === true);
          auxScorRepData.forEach(_scorRepItem => {
            headersRow.push(_scorRepItem['name']);
            profileHeadersRow.push(_scorRepItem['name']);
          });
        };
      }
      //check if is select to download all scales and set scores headers
      if (currentTestStructure.testRules && currentTestStructure.testRules.downloadAllScales){
        for (let _quest of testQuest){
          if (_quest.scoringResults){
            _quest.scoringResults.forEach(_scale => {
              let scaleKey = _scale.key;
              let scores = _scale.scores;
              for (const key in scores) {
                allKeys.push(scaleKey+"_"+key);
              };
            });
          };
        };
        const aux = new Set(allKeys);
        scoresHeadersRow = [...aux];
        headersRow = [...headersRow, ...scoresHeadersRow.sort()];
      } else {
        if(currentScaleData?.scales){
          try {
            currentScaleData.scales.forEach(_scale => {
              let aux = _scale.scores.filter(_score => _score.visibleToPsyUser === true);
              if(aux.length > 0){
                aux.forEach(_score => {
                  let scaleKey = _scale.key;
                  let scoreKey = _score.scoreName;
                  headersRow.push(scaleKey + "_" + scoreKey);
                  scoresHeadersRow.push(scaleKey+"_"+scoreKey);
                });
              }
            });
            additionalHeadersRow = await this.createAdditionalHeaderWithTranslates(profileHeadersRow, scoresHeadersRow, currentTestStructure);
          } catch (error) {
            console.log(error)
          }
        };
      };
      result = [headersRow, testQuest, profileHeadersRow, scoresHeadersRow, additionalHeadersRow];
      return result;
    })
    .catch(err =>{
      console.log(err)
      return err
    })
  }

  ///get profileData responses row for a questionnaire
  private profileDataResultsExcel(questionnaire, profileHeadersRow){
    let profileResponses = [];
    if(questionnaire.profileData){
      profileHeadersRow.forEach(profileItem =>{
        if(profileItem in questionnaire.profileData){
          profileResponses.push(questionnaire.profileData[profileItem])
        } else {
          profileResponses.push("")
        }
      });
    } else {
      profileResponses.push("Error, data unconsistency")
    }
    return profileResponses
  }
  ///get scoringResults row for a questionnaire
  private scoringResultsExcel(questionnaire, scoresHeaders){
    let scoringResults = [];
    scoresHeaders.forEach(_scaleScore => {
      // Split scale and score
      const splittedValue = _scaleScore.split('_');

      // Set scale and score
      let _scale = splittedValue[0];
      let _score = splittedValue[1];

      const scaleFound = questionnaire.scoringResults.find(_scaleFound => _scaleFound.key === _scale && _score in _scaleFound.scores);//at scoresHeaders there could be any lowercase

      if(scaleFound){
        scoringResults.push(scaleFound.scores[_score]);
      } else {
        scoringResults.push("");
      };
    });
    return scoringResults
  }

  async createExcelAnswerSheets(questionnaireCode, entryMaskSheet, testKey, testQuest, answersRows, project, scoringWorkbook):Promise<any>{
    let currentTestStructure;
    let downloadRawAnswers;
    let entryMaskWorkSheet: xlsx.WorkSheet;
    return this.projectsApi.getTestStructure(questionnaireCode).toPromise()
    .then(res => { //get testRules to check if is allowed download entryMask
      currentTestStructure = res.data.test_structure;
      downloadRawAnswers = currentTestStructure.testRules?.downloadRawAnswers;

      if (downloadRawAnswers) {
        entryMaskSheet = [];
        const testKeyName = testKey.substring(0,18);
        let sheetTitle = testKeyName + "_Raw_Answers";
        ///--------FIXED HEADERS FOR ALL TESTS-------///
        let headersEntryMaskRow = [];
        headersEntryMaskRow.push(this.translate.instant('EXCEL.HEADER-FRIENDLYID'));
        headersEntryMaskRow.push(this.translate.instant('EXCEL.HEADER-SHORTCODE'));

        ///----------VARIABLE HEADERS BY TEST------------///
        const notNullIndex = (element) => element.questionnaireResponses != null;
        let validIndex = testQuest.findIndex(notNullIndex);
        for (let k = 0; k < testQuest[validIndex].questionnaireResponses.length; k++){
          for (let i = 0; i < testQuest[validIndex].questionnaireResponses[k].responseItems.length; i++) {
            headersEntryMaskRow.push(i + 1);
          };
        };
        entryMaskSheet.push(headersEntryMaskRow);

        /// -------ANSWERS ROWS (one for each questionnaire)-------- ///

        for (let _quest of testQuest) {
          answersRows = [];
          let selectedTt = project.testtakers.find(_tt => _tt.id === _quest.testtaker_id);
          answersRows.push(selectedTt.friendlyId); ///friendlyId
          answersRows.push(_quest.questionnaireCode.substring(0, 10)); ///shortQCode

          if (_quest.statusCode > QuestionnaireCodeStatus.ASSIGNED) {
            for (let i = 0; i < _quest.questionnaireResponses.length; i++){
              for (let _item of _quest.questionnaireResponses[i].responseItems){
                answersRows.push(_item.responseValue);
              };
            };
          };
          entryMaskSheet.push(answersRows);
        }
        entryMaskWorkSheet = xlsx.utils.aoa_to_sheet(entryMaskSheet);
        this.applyStyleToWorksheet(entryMaskWorkSheet)
        xlsx.utils.book_append_sheet(scoringWorkbook, entryMaskWorkSheet, sheetTitle);
      }
    })
    .catch(err =>{
      return err
    })
  }

  createAdditionalHeaderWithTranslates(profileHeadersRow, scoresHeadersRow, testStructure) {
    let additionalHeadersRow = [];
    profileHeadersRow.forEach(_profileItem => {
      let profileFound = testStructure.profileData.formItems.find(_profileFound => _profileFound.name === _profileItem);
      if (profileFound){
        additionalHeadersRow.push(profileFound.label);
      } else {
        let psyQuestionFound = testStructure.scoringReportingData.formItems.find(_psyQuestionFound => _psyQuestionFound.name === _profileItem);
        if(psyQuestionFound){
          additionalHeadersRow.push(psyQuestionFound.label)
        } else {
          additionalHeadersRow.push('');
        };
      };
    });
    /// get scores labels
    scoresHeadersRow.forEach(_scaleScore => {
      // Split scale and score
      const splittedValue = _scaleScore.split('_');

      // Set scale and score
      let _scale = splittedValue[0];
      let _score = splittedValue[1];
      let scaleFound = testStructure.scaleData.scales.find(_scaleFound => _scaleFound.key === _scale);
      let scoreFound = scaleFound.scores.find(score =>  score.scoreName === _score);

      if(scoreFound){
        additionalHeadersRow.push(scoreFound.label)
      } else {
        additionalHeadersRow.push("");
      };
    });
    return additionalHeadersRow
  }

  /**
   * Apply styles of scoring table to the @worksheet given by params
   * @param xlsx xlsx api
   * @param worksheet scoring sheet to apply the style
   */
  applyStyleToWorksheet(worksheet, additionalHeaders?) :void{
    //Determine total range of used cells (worksheet(s: source, e: end), cell(r:row, c:column))
    var range = xlsx.utils.decode_range(worksheet['!ref']);
    let titleRows = [range.s.r];
    const firstColumn = range.s.c;
    const maxRows = range.e.r;
    const maxCols = range.e.c;
    let secondRow
    if(additionalHeaders){
      secondRow = 1;
      titleRows.push(secondRow);
    }
    //Apply style to title cells in Row 0
    let columnIndex: number = 0;
    let rowIndex: number = 0;
    titleRows.forEach(_row => {
      for (rowIndex = _row; rowIndex <= _row; ++rowIndex) {
        let alternateColor : boolean = false
        for (columnIndex = firstColumn; columnIndex <= maxCols; ++columnIndex) {
          // Convert {r: row, c: column} coordinates to Excel cell coordinates A1...AN (A..Z)
          let cell_address = { c: columnIndex, r: rowIndex };
          let cell_ref = xlsx.utils.encode_cell(cell_address);
          // If value is different from undefined, set the style (cell.s) for the target cell
          if (worksheet[cell_ref]) {
            worksheet[cell_ref].s = {
              font: this.whiteBoldFont,
              fill: this.blueGiuntiFill,
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true
              },
              border: this.borderTitle
            };
            // Change background color alternating
            if (alternateColor) {
              worksheet[cell_ref].s.fill = this.blueHeavyGiuntiFill;
            }
            alternateColor = !alternateColor;
          };
        };
      };
    });
    //Apply data style to rest of cells row:(2->N) col:(1->N) (Not titles nor headers)
    let responsesRowIndex = 1;
    if (additionalHeaders){
      responsesRowIndex = 2; //if additionalHeaders exists, response rows init at third row
    }
    for(rowIndex = responsesRowIndex; rowIndex <= maxRows; ++rowIndex){
      for(columnIndex = 1; columnIndex <= maxCols; ++columnIndex) {

        //Convert {r:row,c:column} coordinates to Excel cell coordinates A1...AN (A..Z)
        let cell_address = {c:columnIndex, r:rowIndex};
        let cell_ref = xlsx.utils.encode_cell(cell_address);

        if(worksheet[cell_ref]){
          worksheet[cell_ref].s = {
            font: {
              bold: false,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: false
            },
            border: this.borderThin
          };
        }
      }
    }
  } //End of apply styles function

  async generateExcelHistory(history, fileName) : Promise<boolean>{

    let historyWorkbook = xlsx.utils.book_new();

    let worksheetData = [];

    history.forEach(use => {
      worksheetData.push({
          [this.translate.instant('EXCEL.HEADER-DATE')]: moment.parseZone(use.operationDate).format('YYYY-MM-DD HH:mm:ss'),
          [this.translate.instant('EXCEL.HEADER-REPORT')]: use.test ? `${use.test?.parentTest.test} - ${use.test?.title} [${use.test?.locale.toUpperCase()}]` : '',
          [this.translate.instant('EXCEL.HEADER-ACTION')]: use.transactionType,
          [this.translate.instant('EXCEL.HEADER-WEIGHT')]: use.weight,
          [this.translate.instant('EXCEL.HEADER-BASED')]: use.consumptionType,
          [this.translate.instant('EXCEL.HEADER-PLAN')]: use.customerHasPlan?.plan.name
      });
    });

    let historyWorksheet = xlsx.utils.json_to_sheet(worksheetData);

    xlsx.utils.book_append_sheet(historyWorkbook, historyWorksheet, this.translate.instant('EXCEL.TITLE'));

    const excelBuffer: any = xlsx.write(historyWorkbook, { bookType: 'xlsx', type: 'array' });

    //Download excel file.
    const data: Blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    FileSaver.saveAs(data, fileName + ' - ' + this.translate.instant('EXCEL.TITLE') +  ".xlsx");

    //Return success!
    return true;
  }

  async generateBlindAdministrationExcel(assessmentUrl, project, projectData) : Promise<boolean> {

    const fileName = project.code;

    try {
      // ------ Title -------
      let firstRowExcel: rowExcelStructure = {
        dataPositions : [],
        mergeRanges: [],
        rowNumber: 0,
        error: false
      }

      //First row/column has to be empty
      firstRowExcel.dataPositions.push(undefined)
      if(project.supply===this.projectEntryType.Blind){
        firstRowExcel.dataPositions.push(this.translate.instant('EXCEL.ASSESSMENT-TITLE'))
        firstRowExcel.dataPositions.push(' ' + assessmentUrl + 'blind/login')
        firstRowExcel.mergeRanges.push({s:{r:0,c:2},e:{r:0,c:4}})
      } else {
        firstRowExcel.dataPositions.push(this.translate.instant('PROJECTS.BTN-BLIND-ADMINISTRATION'))
        firstRowExcel.mergeRanges.push({s:{r:0,c:1},e:{r:0,c:2}})
      }

      // ------ Table Header -------
      let secondRowExcel: rowExcelStructure = {
        dataPositions : [],
        mergeRanges: [],
        rowNumber: 2,
        error: false
      }
      secondRowExcel.dataPositions.push(undefined)
      if(project.supply===this.projectEntryType.Blind) {
        secondRowExcel.dataPositions.push(this.translate.instant('EXCEL.HEADER-SHORTCODE'))
        secondRowExcel.dataPositions.push(this.translate.instant('EXCEL.HEADER-FRIENDLYID'))
        secondRowExcel.dataPositions.push(undefined)
        secondRowExcel.dataPositions.push(this.translate.instant('EXCEL.DIRECT-URL-FILE'))
        // secondRowExcel.dataPositions.push(this.translate.instant('EXCEL.HEADER-EMAIL')); Remove email column
      } else {
        secondRowExcel.dataPositions.push(this.translate.instant('EXCEL.HEADER-FRIENDLYID'))
        secondRowExcel.dataPositions.push('Url')
      }


      // ------ Table Data -------
      let dataRowExcel: rowExcelStructure[] = [];

      let minCodeWidth = 20;
      let minCodeFriendlyId = 20;
      let minQCode = 90;
      //let minEmail = 20;

      projectData.forEach(testtaker => {
        let testtakerData: rowExcelStructure = {
          dataPositions : [],
          mergeRanges: [],
          rowNumber: 2,
          error: false
        }
        testtakerData.dataPositions.push(undefined)
        if(project.supply===this.projectEntryType.Blind) {
          testtakerData.dataPositions.push(testtaker.shortCode)
          testtakerData.dataPositions.push(testtaker.friendlyId)
          testtakerData.dataPositions.push(undefined)
          testtakerData.dataPositions.push(`${assessmentUrl}${testtaker.qCode}`)
          // testtakerData.dataPositions.push(testtaker.email); Remove email column
        } else {
          testtakerData.dataPositions.push(testtaker.friendlyId)
          testtakerData.dataPositions.push(`${assessmentUrl}${testtaker.qCode}`)
        }

        dataRowExcel.push(testtakerData);

        // Get maximum widths (to set the width of the column later)
        if(testtaker.shortCode.length > minCodeWidth) minCodeWidth = testtaker.shortCode.length + 4;
        if(testtaker.friendlyId.length > minCodeFriendlyId) minCodeFriendlyId = testtaker.friendlyId.length + 4;
        if(testtaker.qCode.length > minQCode) minQCode = testtaker.qCode.length + 4;
        //if(testtaker.email?.length > minEmail) minEmail = testtaker.email.length + 2;
      })

      // ------ Build raw excel document -------
      let finalDocument : any[] = [];
      finalDocument.push(firstRowExcel.dataPositions)
      finalDocument.push(undefined) // Space between title and table
      finalDocument.push(secondRowExcel.dataPositions)
      dataRowExcel.forEach(data => {
        finalDocument.push(data.dataPositions);
      });

      //Convert the array of arrays of cells to a "raw" xlsx worksheet
      const blindAdministrationWorkSheet = xlsx.utils.aoa_to_sheet(finalDocument);

      //Apply merge list to the entire worksheet
      blindAdministrationWorkSheet['!merges'] = firstRowExcel.mergeRanges

      //Apply format to scoring worksheet
      this.applyStyleToBlindAdministrationWorksheet(blindAdministrationWorkSheet);

      // Set columns width (based in data values)
      if(project.supply===this.projectEntryType.Blind) {
        blindAdministrationWorkSheet['!cols'] = [{ width: 5 }, { width: minCodeWidth }, { width: minCodeFriendlyId }, { width: 6 }, { width: minQCode }];
      } else {
        blindAdministrationWorkSheet['!cols'] = [{ width: 5 }, { width: minCodeFriendlyId }, { width: minQCode }];
      }

      //Create workbook and introduce the Scoring Results worksheet
      const worksheetName = this.translate.instant('EXCEL.TITLE-BLIND')
      const workbook = { Sheets: { 'data': blindAdministrationWorkSheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      //Download excel file.
      const data: Blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(data, fileName + ".xlsx");
    } catch (error) {
      console.error('Error generating excel file:', error.message)
      return false;
    }
    return true;
  }

  applyStyleToBlindAdministrationWorksheet(worksheet) :void{

    //Determine total range of used cells (worksheet(s: source, e: end), cell(r:row, c:column))
    var range = xlsx.utils.decode_range(worksheet['!ref']);
    const firstRow = range.s.r;
    const firstColumn = range.s.c;
    const maxRows = range.e.r;
    const maxCols = range.e.c;

    //Apply style to title cells in Row 0
    let columnIndex: number = 0;
    let rowIndex: number = 0;
    for(columnIndex = firstColumn; columnIndex <= maxCols; ++columnIndex) {
      //Convert {r:row,c:column} coordinates to Excel cell coordinates A1...AN (A..Z)
      let cell_address = {c:columnIndex, r:firstRow};
      let cell_ref = xlsx.utils.encode_cell(cell_address);

      //If value is different of undefined, set the style (cell.s) for target cell
      if(columnIndex == 1){
        if(worksheet[cell_ref]){
          worksheet[cell_ref].s = {
            font: this.whiteBoldFont,
            fill: this.blueGiuntiFill,
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true
            },
            border: this.borderTitle
          };
        }
      }

      if(columnIndex == 2){
        if(worksheet[cell_ref]){
          worksheet[cell_ref].s = {
            font: this.blackBoldFont,
            fill: this.blueSoftGiuntiFill,
            alignment: {
              vertical: "center",
              horizontal: "left",
              //wrapText: true
            }
          };
        }
      }
    }

    //Apply data style to rest of cells row:(2->N) col:(1->N) (Not titles nor headers)
    for(rowIndex = 2; rowIndex <= maxRows; ++rowIndex){
      for(columnIndex = 1; columnIndex <= maxCols; ++columnIndex) {

        //Convert {r:row,c:column} coordinates to Excel cell coordinates A1...AN (A..Z)
        let cell_address = {c:columnIndex, r:rowIndex};
        let cell_ref = xlsx.utils.encode_cell(cell_address);

        if(worksheet[cell_ref]){

          if(rowIndex == 2) {
            worksheet[cell_ref].s = {
              font: this.whiteBoldFont,
              fill: this.blueGiuntiFill,
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: false
              },
              border: this.borderTitle2
            };
          } else {
            worksheet[cell_ref].s = {
              font: {
                bold: columnIndex == 1 ? true : false,
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: false
              },
              border: this.borderThin
            };
          }
        }
      }
    }
  }
}
