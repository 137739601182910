import { Injectable } from '@angular/core';
import { ProjectsApi } from '../api/projects.api';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";
import { Codes, QuestionnaireCodeStatus, StatusCodeEnum } from '@giuntipsy/utils/lib';
import { ReportStatus } from 'src/app/shared/enums/report-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  STANDARD_SUCCESS_MESSAGE = 9000;

  constructor(
    private projectsApi: ProjectsApi,
    private authSrv: AuthenticationService
  ) { }

  /**
   * This method returns the individual test With it Reports list updated with the
   * received information from the server
   *
   * returns the same list but with the following information:
   * - Report including the friendly test name, 'test_name', from the parentTest.
   * - Report including an array 'reportServedArray' reflecting purchase status for each testaker
   * @param testWithReports
   * @param questionnairesRelated
   * @returns testWithReports (Updated with server information)
   */
  updateTestReportsWithQuestionnaires(testWithReports: any, questionnairesRelated: any): any {

    //Build metadata info for reports items using any questionnaire of the list
    testWithReports.reports.forEach(report => {

      //Any Questionnaire related to the test
      let questionnaireFromList = questionnairesRelated[0]

      //Questionnaire test "friendly name" added to report item (related to Popup/Pdf/Zip final report name)
      report.test_name = questionnaireFromList.test.parentTest.test;

      //Prepare a receptacle for each report item to acquire report served status (Purchased/Not) for all testtakers
      //for example: report served status for 4 testtaker in a project value: [true|false,t|f,t|f,t|f]
      report.reportServedArray = [];
    });

    try{
        //For each questionnaire, check the 'reportingResult' field to update local report items
        for (let qIndex = 0; qIndex < questionnairesRelated.length; qIndex++) {
          const questionnaire = questionnairesRelated[qIndex];

          //Multirreporting, for each report item from the test, update it local status using the questionnaire
          for (let reportIndex = 0; reportIndex < questionnaire.reportingResult.length; reportIndex++) {
            const reportInfoFromQuest = questionnaire.reportingResult[reportIndex];

            //Update the report array served result with status from server
            let affectedReportIndex = testWithReports.reports.findIndex(report => report.id == reportInfoFromQuest.report_id)
            testWithReports.reports[affectedReportIndex].reportServedArray.push(reportInfoFromQuest.served);
          }

          //Evaluation of general served status to display in the GUI
          testWithReports.reports.forEach(report => {
            report.served = this.checkReportOperationResult(report.reportServedArray)
          });
        }
    }catch(err){
      console.log("Error fetching reportResult: ",err)
      //In case of any error, returns empty array
      return []
    }

    //Success updating test and report list with server data
    return testWithReports
  }

  /**
   * Evaluate boolean array. If all elements are true, returns true,
   * If one element is false, return false.
   * True means all testtakers have their reports purchased.
   * False means at least one testtaker has got his report pending for purchase.
   * @param reportServedArray
   */
  checkReportOperationResult(reportServedArray: any): any {
    let boolArrayChecker = arr => arr.every(value => value === true);
    if(boolArrayChecker(reportServedArray)){
      return true
    }
    return false
  }

 /**
  * Returns the selected tests for the project
  * using its associated questionnaires
  * Filter duplicate test entries
  */
  getTestsAndReportsFromProject(questionnaires): any {

    //console.log('1',...questionnaires)

    let testList : any = []
    questionnaires.forEach((x) => {
        testList.push(x.test)
    });

    //console.log('2', ...new Map(testList.map(test => [test['testKey'], test])).values())

    //Filter duplicates and return test list related to project
    return [...new Map(testList.map(test => [test['testKey'], test])).values()]
  }

  // /**
  //  * Download report @report for entire project @project and its testtakers
  //  * @param project
  //  * @param report
  //  */
  // async downloadSelectedReportForProject(project, report) {

  //   //Define Zip/Report name
  //   let projectName
  //   if(project.code){
  //     projectName = project.code;
  //   }else{
  //     projectName = project.projectName;
  //   }
  //   const zipName = 'Project_' + projectName + '_Report_' + report.name;

  //   //Find the questionnaires qCodes related to requested report
  //   let qCodesRelatedToReport = []
  //   project.questionnaires.forEach(questionnaire => {
  //     if(questionnaire.test_id == report.test_id){
  //       qCodesRelatedToReport.push(questionnaire.questionnaireCode)
  //     }
  //   });

  //   // Request the pdf and perform a payment if needed
  //   const validResultCodeOrFalse = await this.requestReportForProject(qCodesRelatedToReport, report, zipName);

  //   //Return a result code or false for unhandled errors.
  //   return  validResultCodeOrFalse;
  // }


  /**
   * Download multiple reports in a Zip format
   * @param files list of url to retrieve
   * @param zipName name of the final Zip file to download
   * @returns true if success, false otherwise
   */
  async getReportsInZip(questionnaires: any[], zipName: string, project, projectName) {
    const zip = new JSZip();
    const name = zipName + ".zip";
    let result = true;
    let singleName;
    var uniq = {};
    for (let i = 0; i < questionnaires.length; i++) {
      const url = questionnaires[i].reportingResult.url;
      singleName =  this.getPdfName(project, questionnaires[i], projectName);
      if(uniq[singleName]) {
        singleName = singleName.replace(".pdf","_"+Object.keys(uniq).length+".pdf");
      }
      uniq[singleName] = true;
      const fileData: any = await this.projectsApi.getPdfFile(url);
      if (fileData.type === "error") {
        result = false;
        break;
      }
      const b: any = new Blob([fileData], { type: "" + fileData.type + "" });
      zip.file(singleName, b);
    }

    //If no errors were present, download zip package and return true (success)
    if (result) {
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content) {
          FileSaver.saveAs(content, name);
        }
      });
      return true;
    } else {
      return false;
    }
  }

  async downloadPdfObject(url: string, name) {
    const fileData: any = await this.projectsApi.getPdfFile(url);
    if (fileData.type === "error") {
      return false;
    }

    const b: any = new Blob([fileData], { type: "application/pdf" });
    FileSaver.saveAs(b, name);
    return true;
  }


 /**
  * Translate the system code from Plans module into a
  * human readable message
  * @param systemCodeRcvd;
  */
  plansMessageSelector(systemCodeRcvd): string {
    let msgToUserKey: string;

    switch (systemCodeRcvd) {

      case Codes.Plans.SUCCESS:
        msgToUserKey = 'PROJECTS.SUCCESS_REPORT';
        break;

      case Codes.Plans.SUCCESS_LEGACY_PLAN_VALID:
        msgToUserKey = "PROJECTS.SUCCESS_LEGACY_PLAN_VALID";
        break;

      case Codes.Plans.SUCCESS_VALID_SUBSCRIP_PLAN:
        msgToUserKey = "PROJECTS.SUCCESS_VALID_SUBSCRIP_PLAN";
        break;

      case Codes.Plans.LEGACY_WITHOUT_ENOUGH_CREDITS:
        msgToUserKey = "PROJECTS.LEGACY_WITHOUT_ENOUGH_CREDITS";
        break;

      case Codes.Plans.BASIC_WITHOUT_ENOUGH_USES:
        msgToUserKey = "PROJECTS.BASIC_WITHOUT_ENOUGH_USES";
        break;

      case Codes.Plans.SUBSCRIPTION_WITHOUT_REPORTS:
        msgToUserKey = "PROJECTS.NO_REPORTS_PLAN";
        break;

      case Codes.Plans.SUCCESS_USING_SUBSCRIP_PLAN:
        msgToUserKey = "PROJECTS.SUCCESS_USING_SUBSCRIP_PLAN";
        break;

      case Codes.Plans.SUCCESS_SUBSTRACTING_CREDITS:
        msgToUserKey = "PROJECTS.SUCCESS_SUBSTRACTING_CREDITS";
        break;

      case Codes.Plans.NO_SUBSCRIPTION_AVALIABLE:
        msgToUserKey = "PROJECTS.NO_SUBSCRIPTION_AVALIABLE";
        break;

      case Codes.Plans.INTERNAL_ERROR:
        msgToUserKey = 'PROJECTS.ERROR_REPORT';
        break;

      case Codes.Plans.REQUEST_ERROR:
        msgToUserKey = 'PROJECTS.ERROR_REPORT';
        break;

      case Codes.Plans.ERROR_SUBSTRACTING_CREDITS:
        msgToUserKey = "PROJECTS.ERROR_SUBSTRACTING_CREDITS";
        break;

      case Codes.Plans.ERROR_USING_SUBSCRIP_PLAN:
        msgToUserKey = "PROJECTS.ERROR_USING_SUBSCRIP_PLAN";
        break;

      case Codes.Plans.ERROR_REGISTERING_PLAN_USE:
        msgToUserKey = "PROJECTS.ERROR_REGISTERING_PLAN_USE";
        break;

      default:
        msgToUserKey = "PROJECTS.ERROR_REPORT";
        break;
    }

    return msgToUserKey;
  }

  /**
   * Check if a success toast is needed
   * @param systemCodeRcvd
   */
  pdfRequestFunctionSuccess(systemCodeRcvd): boolean {
    let successStatusCodeList = [
      Codes.Plans.SUCCESS,
      Codes.Plans.SUCCESS_LEGACY_PLAN_VALID,
      Codes.Plans.SUCCESS_VALID_SUBSCRIP_PLAN,
      Codes.Plans.SUCCESS_USING_SUBSCRIP_PLAN,
      Codes.Plans.SUCCESS_SUBSTRACTING_CREDITS,
    ];

    //If received code is present in the list return true
    if (successStatusCodeList.indexOf(systemCodeRcvd) != -1) {
      return true;
    }
    return false;
  }

  /**
   * Generate the report data structure, test -> report -> questionnaire -> reportingResult
   * @param questionnaires
   * @returns
   */
  getReportObjectByQuestionnaires(questionnairesList) {
    let testList = []

    // Get the list of the tests in the project
    questionnairesList.forEach(_quest => {
      testList.push(_quest.test);
    });

    // Remove duplicate tests
    testList = testList.filter((x, i, z) => i === z.findIndex((t) => (t.id === x.id)));

    // Iterate over test reports and add empty questionnaires array
    testList.forEach(_test => {
      _test.reports = _test.reports.map(x => ({...x, questionnaires: []}));
    })

    // Add questionnaires to report
    questionnairesList.forEach(_quest => {
      _quest.reportingResults?.forEach(_reportingResults => {

        if([
            QuestionnaireCodeStatus.SCORED_NON_VALID,
            QuestionnaireCodeStatus.SCORED,
            QuestionnaireCodeStatus.REPORT_SERVED,
            QuestionnaireCodeStatus.REPORT_ERROR].includes(_quest.statusCode)){
              testList.forEach(_test => {
                _test.reports.forEach(_report => {
                  if(_report.id == _reportingResults.report_id){
                    // Push object that contains basic info to operate with the report
                    _report.questionnaires.push({
                      reportingResult: _reportingResults,
                      questionnaireCode: _quest.questionnaireCode,
                      statusCode: _quest.statusCode,
                      test_id: _quest.test_id,
                      testtaker_id: _quest.testtaker_id
                    });
                  }
                })
              });
        }
      })
    });
    return testList;
  }

  /**
   * Return if a test is requested or reporting on going
   * @param test
   * @returns
   */
  checkIfTestIsRequested(test): boolean {
    let result = false;
    test.reports.forEach(_report => {
      if(_report.questionnaires.some(x => x.statusCode === QuestionnaireCodeStatus.REPORT_ONGOING))
        result = true;
    });

    return result;
  }

  /**
   * Return if exist at least one report served in the input data
   * @param projectTestReportList
   * @returns
   */
  checkIfAtLeastOneReportIsServed(projectTestReportList): boolean {
    let result = false;
    projectTestReportList?.forEach(_test => {
      _test.reports.forEach(_report => {
        if(_report.questionnaires.some(x => x.reportingResult.served && x.reportingResult.url))
          result = true;
      })
    });
    return result;
  }

  /**
   * Return if exist at least one not served report in the input data
   * @param projectTestReportList
   * @returns
   */
  checkIfAtLeastOneReportIsNotServed(projectTestReportList): boolean {
    let result = false;
    projectTestReportList?.forEach(_test => {
      _test.reports.forEach(_report => {
        if(_report.questionnaires.some(x => !x.reportingResult.served && !x.reportingResult.url))
          result = true;
      })
    });
    return result;
  }

  /**
   * Return if there is only one report for the project
   * @param projectTestReportList
   * @returns
   */
  checkIfIsOnlyOneReportForProject(projectTestReportList): boolean {
    return projectTestReportList.length == 1 && projectTestReportList[0].reports.length == 1
    && projectTestReportList[0].reports[0].questionnaires.length == 1
    && projectTestReportList[0].reports[0].questionnaires[0].statusCode === QuestionnaireCodeStatus.REPORT_SERVED;
  }

  /**
   * Get all report served at a Test level
   * @param projectTestReportList
   * @returns
   */
  getTestReportQuestionnaireServed(projectTestReportList){
    let servedQuestionnaireReports = []
    projectTestReportList?.forEach(_test => {
      _test.reports.forEach(_report => {
        servedQuestionnaireReports = servedQuestionnaireReports.concat(this.getReportQuestionnaireServed(_report))
      })
    });
    return servedQuestionnaireReports;
  }

  /**
   * Get all reports served at a Report Level
   * @param report
   * @returns
   */
  getReportQuestionnaireServed(report){
    return report.questionnaires.filter(x => x.reportingResult.served && x.reportingResult.url);
  }

  /**
   * Get all not served reports at a Report Level
   * @param report
   * @returns
   */
  getReportQuestionnaireNotServed(report){
    return report.questionnaires.filter(x => !x.reportingResult.served && !x.reportingResult.url);
  }

  /**
   * Return the status of the report
   * @param report
   * @returns
   */
  checkReportStatus(report): ReportStatus {

    // If exist at least one questionnaire in requested report status
    if(report.questionnaires.some(x => x.statusCode === QuestionnaireCodeStatus.REPORT_ONGOING))
      return ReportStatus.requested;

    // If exist reports generated for at least one user, but not all
    if(report.questionnaires.some(x => x.reportingResult.served && x.reportingResult.url)
       && report.questionnaires.some(x => !x.reportingResult.served && !x.reportingResult.url))
      return ReportStatus.partial;

    // If all the reports has been generated for the users
    if(report.questionnaires.some(x => x.reportingResult.served && x.reportingResult.url))
      return ReportStatus.complete;

    // None if not exist report requested
    return  ReportStatus.none;

  }

  /**
   * Download the reports selected, and zip if is more than one
   * @param project
   * @param reportList
   * @returns
   */
  async downloadServedReports(project, questionnaires) {

    const STANDARD_SUCCESS_MESSAGE = 9000;

    //Define Zip/Report name
    let projectName
    if(project.code){
      projectName = project.code;
    }else if (project.projectName){
      projectName = project.projectName;
    } else{
      await this.projectsApi.getProjectByQCode(project[0].questionnaireCode).toPromise().then((_project)=>{
        projectName = _project.data.code
        project = _project.data
      });
    }
    const zipName =  projectName + '_Reports';
    try {

      // Multiple reports download in zip success
      if (questionnaires.length > 1) {
        await this.getReportsInZip(questionnaires, zipName, project, projectName);
        return STANDARD_SUCCESS_MESSAGE;
      }

      let singleName = this.getPdfName(project, questionnaires[0], projectName);
      await this.downloadPdfObject(questionnaires[0].reportingResult.url, singleName);

    } catch (err) {
      return err;
    }

    return STANDARD_SUCCESS_MESSAGE;
  }

  getPdfName (project, questionnaire, projectName){
    let singleName;
    let ttName;
    let ttSurname;
    let ttFriendlyId;
    // Depending on type of project and where the reports are downloaded, the object "project" has a different structure.
    // Given the two possible structures:
    if (project.testtakers){
        let selectedTesttaker = project.testtakers.find(tt => tt.id === questionnaire.testtaker_id)
        if(selectedTesttaker){
          ttName = selectedTesttaker.name;
          ttSurname = selectedTesttaker.surname;
          ttFriendlyId = selectedTesttaker.friendlyId;
        }else{
          ttName = project.name ? project.name : questionnaire.testtaker_id;
          ttSurname = project.surname;
          ttFriendlyId = project.testtakerFriendlyId;
        }
    } else {
        ttName = project.name ? project.name : questionnaire.testtaker_id;
        ttSurname = project.surname;
        ttFriendlyId = project.testtakerFriendlyId;
    }
    //Define PDF name
    if (ttName && ttSurname){
      singleName = projectName + "_" + ttName + "_" + ttSurname + "_" + questionnaire.reportingResult.reportName
    } else if (ttName && !ttSurname){
      singleName = projectName + "_" + ttName + "_" + questionnaire.reportingResult.reportName
    } else {
      singleName = projectName + "_" + ttFriendlyId + "_" + questionnaire.reportingResult.reportName
    }

    return singleName + ".pdf"
  }

}
