<p-toast position="top-center" key="toast-error-dialog" preventOpenDuplicates="true" class="toast-dialog">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="error-msg p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-error pointer p-mx-3" (click)="closeToastError()">{{message.summary}}</button>
        </div>
    </ng-template>
</p-toast>

<p-dialog [(visible)]="showModal" [style]="{width: '95vw'}" [baseZIndex]="10000" modal="true" class="template-dialog" [closable]="false" (onShow)="updateParentDataCount()">
    <!--HEADER-->
    <ng-template pTemplate="header">
        <div class="p-d-flex p-jc-between p-ai-center search-container">
            <!--USERS: Search Box-->
            <div class="p-input-icon-right search-box p-mr-4 p-d-inline-flex p-jc-between p-ai-center p-mt-1" *ngIf="activeTabIndex == 0">
                <div class="p-d-flex p-jc-start">
                    <input
                        type="text"
                        pInputText
                        [(ngModel)]="usersSearchText"
                        (keyup.enter)="onUsersEnterPressed($event)"
                        placeholder="{{'FILTER.SEARCH-TEXT-LABEL' | translate}}">
                    <button
                        pButton type="button" icon="pi pi-search"
                        (click)="filterUsersByText()"
                        [class.hovered-element]="isUsersSearchHovered">
                    </button>
                </div>

                <!--RESET BUTTON-->
                <span *ngIf="isUsersFiltered">
                    <button
                        type="button"
                        class="btn-mini3 reset-button"
                        pButton
                        (click)="resetUsersFilter()"
                        label="{{'FILTER.BTN-RESET' | translate}}">
                    </button>
                </span>

                <!--LOAD SPINNER-->
                <div class="spinner-container">
                    <p-progressSpinner *ngIf="!usersDataLoaded" strokeWidth="4" [style]="{width: '2em', height: '2em'}" class="p-ml-2"></p-progressSpinner>
                </div>
            </div>

            <!--GROUPS: Search Box-->
            <div class="p-input-icon-right search-box p-mr-4 p-d-inline-flex p-jc-between p-ai-center p-mt-1" *ngIf="activeTabIndex == 1">
                <div class="p-d-flex p-jc-start">
                    <input
                        type="text"
                        pInputText
                        [(ngModel)]="groupsSearchText"
                        (keyup.enter)="onGroupsEnterPressed($event)"
                        placeholder="{{'FILTER.SEARCH-TEXT-LABEL' | translate}}">
                    <button
                        pButton type="button" icon="pi pi-search"
                        (click)="filterGroupsByText()"
                        [class.hovered-element]="isGroupsSearchHovered">
                    </button>
                </div>

                <!--RESET BUTTON-->
                <span *ngIf="isGroupsFiltered">
                    <button
                        type="button"
                        class="btn-mini3 reset-button"
                        pButton
                        (click)="resetGroupsFilter()"
                        label="{{'FILTER.BTN-RESET' | translate}}">
                    </button>
                </span>

                <!--LOAD SPINNER-->
                <div class="spinner-container">
                    <p-progressSpinner *ngIf="!groupsDataLoaded" strokeWidth="4" [style]="{width: '2em', height: '2em'}" class="p-ml-2"></p-progressSpinner>
                </div>
            </div>

            <!--User/Group Selector-->
            <div *ngIf="type==='both'" class="p-d-inline-flex p-mt-1">
                <p-selectButton [options]="stateOptions" [(ngModel)]="activeTabIndex" optionLabel="label" optionValue="value"></p-selectButton>
            </div>


            <!--Count indicator-->
            <div class="count-indicator text-regular p-mr-3 p-text-right p-mt-1" *ngIf="['user','both'].includes(type)">
                <div>
                    {{'EDIT-GROUP.USERS' | translate}}:
                    {{parentDataCount}}/{{MAX_TESTTAKERS}}
                    <span class="text-regular" *ngIf="parentDataCount >= MAX_TESTTAKERS">  ({{'EDIT-GROUP.FULL' | translate}})</span>
                </div>
                <span *ngIf="showRepeatedMessage()" class="repeated-message">{{'EDIT-PEOPLE.USERS-ALSO-IN-GROUP' | translate }}</span>
            </div>
        </div>
    </ng-template>

    <!--BODY-->
    <div class="content" [ngClass]="'hide-tab'">
        <p-tabView [(activeIndex)]="activeTabIndex">
            <p-tabPanel header="{{'EDIT-GROUP.USERS' | translate}}">
                <ng-template pTemplate="content">

                    <!--Select All Switch-->
                    <div class="select-all-container p-d-flex p-jc-center p-ai-center" *ngIf="!hideSelectAll">
                        <label for="selectAllUserSw" class="pointer p-mr-2">{{'FILTER.SELECT-ALL' | translate}}</label>
                        <p-inputSwitch id="selectAllUserSw" #selectAllUserSw (onChange)="selectAllUsers($event)"  [(ngModel)]="selectAllUserSwStatus"></p-inputSwitch>
                    </div>

                    <!--USERS-->
                    <div class="p-grid p-d-flex p-jc-center" *ngIf="['user','both'].includes(type)">
                        <app-template-user-simple
                            *ngFor="let item of usersData;"
                            class="p-col-12 p-md-12 p-lg-6 "
                            [item]="item"
                            [optionsLeft]="'checkbox'"
                            [optionsRight]="'none'"
                            (itemSelected)="item.original ? null : selectItem($event)">
                        </app-template-user-simple>
                    </div>

                    <!--NO-CONTENT MESSAGE-->
                    <h3 *ngIf="isFiltered && usersDataCount == 0" class="p-text-center">{{'FILTER.NO-RESULTS' | translate}}</h3>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="{{'FILTER.GROUPS' | translate}}">
                <ng-template pTemplate="content">

                    <!--Select All Switch-->
                    <div class="select-all-container p-d-flex p-jc-center p-ai-center" *ngIf="!hideSelectAll">
                        <label for="selectAllGroupsSw" class="pointer p-mr-2">{{'FILTER.SELECT-ALL' | translate}}</label>
                        <p-inputSwitch id="selectAllGroupsSw" #selectAllGroupsSw (onChange)="selectAllGroups($event)" [(ngModel)]="selectAllGroupsSwStatus"></p-inputSwitch>
                    </div>

                    <!--GROUPS-->
                    <div class="p-grid p-d-flex p-jc-center" *ngIf="['group','both'].includes(type)">
                        <app-template-group-simple
                            *ngFor="let item of groupsData;"
                            class="p-col-12 p-md-12 p-lg-6 "
                            [item]="item"
                            [optionsLeft]="'checkbox'"
                            [optionsRight]="'none'"
                            (itemSelected)="selectItem($event)">
                        </app-template-group-simple>
                    </div>
                    <!--NO-CONTENT MESSAGE-->
                    <h3 *ngIf="isFiltered && groupsDataCount == 0" class="p-text-center">{{'FILTER.NO-RESULTS' | translate}}</h3>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>


    <!--FOOTER-->
    <ng-template pTemplate="footer">
        <div>
            <!--Users Paginator-->
            <p-paginator
                #paginatorGroups
                [hidden]="activeTabIndex == 1"
                class="item-paginator"
                [rows]="rowsToDisplayDefault"
                [totalRecords]="usersDataCount"
                [showCurrentPageReport]="true"
                currentPageReportTemplate = "{{ ('SHARING-PANEL.ROW-COUNT-1' | translate) + topRow + ('SHARING-PANEL.ROW-COUNT-2' | translate) }}"
                [rowsPerPageOptions]="[6,10,20,100]"
                (onPageChange)="paginate($event)">
            </p-paginator>
            <!--Groups Paginator-->
            <p-paginator
                #paginatorGroups
                [hidden]="activeTabIndex == 0"
                class="item-paginator"
                [rows]="rowsToDisplayDefault"
                [totalRecords]="groupsDataCount"
                [showCurrentPageReport]="true"
                currentPageReportTemplate = "{{ ('SHARING-PANEL.ROW-COUNT-1' | translate) + topRow + ('SHARING-PANEL.ROW-COUNT-2' | translate) }}"
                [rowsPerPageOptions]="[6,10,20]"
                (onPageChange)="paginate($event)">
            </p-paginator>
        </div>
        <button
            pButton
            (click)="showModal=false; ['user','group'].includes(type) ? selectedData=[] : null;"
            label="{{'EDIT-GROUP.BTN-CANCEL' | translate}}"
            class="btn-secondary">
        </button>
        <button
            pButton
            [disabled]="!ignoreLimit && parentDataCount > MAX_TESTTAKERS"
            (click)="showModal=false; confirm()"
            label="{{'EDIT-GROUP.BTN-APPLY' | translate}}"
            class="btn-primary">
        </button>
    </ng-template>
</p-dialog>