/**
 * Set Languages options values
 */
export const languageItems = {
   // To add a new lenguage, add the file in i18n folder and a objects here to appear
  data : [
    {
      name: 'ENG', // Name that appears en dropdown
      code: 'en' // Standard name for the file and browser
    },
    {
      name: 'ESP',
      code: 'es'
    },
    {
      name: 'ITA',
      code: 'it'
    },
    {
      name: 'TUR',
      code: 'tr'
    },
    {
      name:'BUL',
      code: 'bg'
    },
    {
      name:'RUS',
      code: 'ru'
    },
    {
      name:'HUN',
      code: 'hu'
    },
    {
      name:'UKR',
      code: 'ua'
    }
  ]
};

