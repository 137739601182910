<!--NAVBAR HEADER-->
<div class="filter-header content p-d-flex p-ai-center" #filterHeader [ngClass]="{'filter-header-project-details': usersProject}">
    <!--SEARCH BY TEXT-->
    <div class="p-input-icon-right search-box p-mr-4 p-d-flex p-jc-between p-ai-center" *ngIf="searchKeys.length > 0">
        <input
            #inputSearch
            type="text"
            pInputText
            [(ngModel)]="searchText"
            (keyup.enter)="onEnterPressed($event)"
            placeholder="{{'FILTER.SEARCH-TEXT-LABEL' | translate}}">
        <button
            pButton type="button" icon="pi pi-search"
            (click)="applyFilterByMode()"
            [class.hovered-element]="isHovered">
        </button>

    </div>
    <div class="filter-content p-d-flex p-jc-between p-ai-center">
        <div class="p-d-flex p-ai-center filters">
            <!--SHARED DROPDOWNS-->
            <span *ngFor="let item of dropdowns, let i = index">
                <!--Multi Choice-->
                <p-multiSelect
                    *ngIf="item.type === 'multi'"
                    [options]="item.values"
                    class="custom-dropdown-filter"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="dropdownsSelectedValues[i]"
                    (onChange)="applyFilterByMode()"
                    display="chip"
                    placeholder="{{('FILTER.KEY-'+item.key) | translate}}">
                </p-multiSelect>

                <!--Kind of search dropdown (apply for projects)-->
                <p-dropdown
                    *ngIf="item.key === 'search'"
                    [options]="item.values"
                    class="custom-dropdown-filter"
                    optionLabel="label"
                    optionValue="value"
                    [filter]="false"
                    [(ngModel)]="dropdownsSelectedValues[i]"
                    placeholder="{{('FILTER.KEY-'+item.key) | translate}}">

                    <ng-template pTemplate="selectedItem">
                        <div class="p-d-flex" *ngIf="dropdownsSelectedValues[i]">
                            <i [ngClass]="dropdownsSelectedValues[i] === 'people' ? 'pi pi-user' : 'pi pi-folder'"></i>
                            <div class="p-ml-3">{{'FILTER.' + dropdownsSelectedValues[i].toUpperCase() | translate}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-search pTemplate="item">
                        <div class="p-d-flex">
                            <i [ngClass]="search.value === 'people' ? 'pi pi-user' : 'pi pi-folder'"></i>
                            <div class="p-ml-3">{{search.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>

                <!--Single Choice only for active/inactive-->
                <p-dropdown
                    *ngIf="item.type === 'single' && item.key !== 'search'"
                    [options]="item.values"
                    class="custom-dropdown-filter"
                    optionLabel="label"
                    optionValue="value"
                    [filter]="false"
                    [(ngModel)]="selectedActiveInactive"
                    (onChange)="applyFilterByMode()"
                    placeholder="{{('FILTER.KEY-'+item.key) | translate}}">
                </p-dropdown>
            </span>

            <!-- FILTER ACTIVE/INACTIVE HISTORY AND SUBSCRIPTIONS-->
            <div class="p-d-flex p-ai-center active-inactive-switch" *ngIf="filterByActiveInactiveHistory">
                <label>{{'PROFILE.SWITCH-INACTIVE'|translate}}</label>
                <p-inputSwitch class="p-ml-3 p-mt-1" [(ngModel)]="selectedSortTypeHistory" (onChange)="applyFilterByMode()"></p-inputSwitch>
            </div>

            <!--DATE FILTER-->
            <span class="p-input-icon-right" *ngIf="filterByDateRange">
                <i class="pi pi-calendar"></i>
                <p-calendar
                    class="custom-calendar custom-calendar-filter"
                    dateFormat="dd/mm/yy"
                    [(ngModel)]="rangeDates"
                    selectionMode="range"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    (onClickOutside)="applyFilterByMode()"
                    [autoZIndex]="true"
                    appendTo='inputSearch'
                    firstDayOfWeek="1"
                    yearRange="1990:2030"
                    placeholder="{{'FILTER.DATE-RANGE' | translate}}">
                </p-calendar>
            </span>
        </div>

        <div class="p-d-flex p-ai-center">
            <!--RESET BUTTON-->
            <span *ngIf="isFiltered">
                <button
                    type="button"
                    class="btn-mini3 reset-button"
                    pButton
                    (click)="emitResetFilter()"
                    label="{{'FILTER.BTN-RESET' | translate}}">
                </button>
            </span>

            <!--SORT BY-->
            <div class="filter-section" *ngIf="visibleSortTypes.length>0">
                <p-dropdown
                    [options]="visibleSortTypes"
                    inputId="orderby"
                    optionLabel="name"
                    optionValue="key"
                    placeholder="&nbsp;"
                    [autoDisplayFirst]="false"
                    (onChange)="applyFilterByMode()"
                    class="order-by"
                    placeholder="{{'FILTER.ORDER-BY' | translate}}"
                    [(ngModel)]="selectedSortType">
                </p-dropdown>
            </div>
        </div>
    </div>
</div>


<!--BLOCKER-->
<p-blockUI [blocked]="filtering"></p-blockUI>

<!-- Spinner -->
<div *ngIf="filtering" class="spinner p-d-flex p-jc-end p-ai-center">
    <p-progressSpinner [style]="{width: '120px', height: '120px'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<!--SIDEBAR CONTENT-->
<!-- <p-sidebar #sidebarFilter [(visible)]="displayActionFilter" position="right" [baseZIndex]="10000" class="sidebar-filter" (onShow)="setDropDowns()">
    <div class="sidebar-filter-content" #sidebarContent></div>
</p-sidebar> -->