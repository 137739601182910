import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from "moment";
import { AdminApi } from 'src/app/modules/admin/api/admin.api';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  private currentActiveIndex = new BehaviorSubject<number>(null);
  sharedActiveIndex = this.currentActiveIndex.asObservable();

  constructor(private adminApi: AdminApi) { 
    this.currentActiveIndex.next(0);
  }

  /**
   * Return true or false if the plan type is Legacy (credit based) or not
   * @param plan 
   * @returns 
   */
  public isPlanLegacy(plan) {
    return plan.planType?.withCredits === true || plan.plan?.planType?.withCredits === true;
  }

  setActiveIndex(index: number): void {    
    this.currentActiveIndex.next(index);
  }

  moreThan100(startDate, finishDate): boolean {
    const result = new Date(finishDate).getFullYear() - new Date(startDate).getFullYear();
    return result >= 100;
  }

  getCustomerPlans(customerId): Promise<any> {        
    return this.adminApi.getCustomerPlans(customerId).toPromise()
      .then(res => {
        let availablePlans = [];
          if(res.statusOk) {
            // Remove not alive plans
            availablePlans = res.message.filter(_plan => _plan.isAlive == true);

            // Order by subscription_id            
            availablePlans.sort((a, b) => { return a.sub_id - b.sub_id});            
          }
          return availablePlans;
        },
        (err) => {
          return err;
        }
      );
  }

  getCustomerBalance(customerId, onlyAlivePlans: boolean = true): Promise<any> {        
    return this.adminApi.getCustomerBalance(customerId, onlyAlivePlans).toPromise()
      .then(res => {       
          if(res.statusOk) {
            return res.data;
          }
        },
        (err) => {
          return err;
        }
      );
  }
}
