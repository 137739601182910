<div class="card p-mx-1">
    <div class="top-card p-d-flex p-flex-column p-jc-between" [ngClass]="{'card-selected': item.selected, 'card-inactive': !item.active}">
        <div class="p-d-flex p-jc-between">
            <!--Checkbox disabled, out of MVP Scope-->
            <input type="checkbox" 
            class="custom-checkbox"
            (change)="selectItem(item, $event)"
            [disabled]="true"
            [checked]="item.selected">

            <span class="test-level" *ngIf="item.requiredQualification">
                {{item.requiredQualification}}
            </span>
        </div>
        <div class="p-d-flex p-flex-column">
            <span class="test-type">
                <i  class="pi pi-circle-on"></i>
                {{item.ambit}}                
            </span>
            <span class="test-title">
                {{item.parentTestName}} ({{item.locale | uppercase}})
            </span>
            <span class="test-subtitle">
                {{item.title}}
            </span>
        </div>
        <span class="test-recipients">
            <i  class="pi pi-user"></i>
            {{item.target}}
            <!-- For debug -->
            <!-- {{item.recipient|json}} -->
        </span>
    </div>    
    <div class="bottom-card p-d-flex p-flex-column p-jc-between">        
        <!-- <span class="test-favorite" *ngIf="!item.favorite" (click)="setUnsetFavorite(item,true)">
            <i class="pi pi-star"></i>
        </span>
        <span class="test-favorite-active" *ngIf="item.favorite" (click)="setUnsetFavorite(item,false)">
            <label class="text-on">{{'CATALOG.BTN-FAVORITE'|translate}}</label>
            <label class="text-off">{{'CATALOG.BTN-UNFAVORITE'|translate}}</label>
            <i class="pi pi-star"></i>
        </span> -->
        <div>
            <span class="test-modality">
                {{item.purpose}}<br>
            </span>
            <span class="test-authors">
                {{item.authors}}
            </span>
        </div>
        <span class="test-details pointer" (click)="displayDetails(item)">
            {{'CATALOG.BTN-DETAILS'|translate}}
            <span>
                <i class="pi pi-plus"></i>
            </span>
        </span>
    </div>
</div>

<!--DETAILS (POP-UP)-->
<p-dialog [(visible)]="displayDetailsPanel" [style]="{width: '80vw'}" [baseZIndex]="1000" [modal]="true" class="template-dialog" [ngClass]="{'card-selected': item.selected, 'card-inactive': !item.active}">
    <ng-template pTemplate="header">
        <div class="top-card p-d-flex p-flex-column p-jc-between">
            <div class="p-d-flex p-jc-between">
                <span class="test-type">
                    <i  class="pi pi-circle-on"></i>
                    {{item.ambit}}
                </span>
                <span class="test-level">
                    {{item.requiredQualification}}
                </span>
            </div>
            <span class="test-title">
                {{item.parentTestName}}
            </span>
            <span class="test-subtitle">
                {{item.title}}
            </span>
            <span class="test-recipients">
                <i  class="pi pi-user"></i>
                {{item.target}}
            </span>

            <!-- <span class="test-favorite" *ngIf="!item.favorite" (click)="setUnsetFavorite(item,true)">
                <i class="pi pi-star"></i>
            </span>
            <span class="test-favorite-active" *ngIf="item.favorite" (click)="setUnsetFavorite(item,false)">
                <label class="text-on">{{'CATALOG.BTN-FAVORITE'|translate}}</label>
                <label class="text-off">{{'CATALOG.BTN-UNFAVORITE'|translate}}</label>
                <i class="pi pi-star"></i>
            </span> -->
        </div>        
    </ng-template>    
    
    <div class="content-dialog">
        <div class="bottom-card p-d-flex p-flex-column p-jc-between">
            <span class="test-modality">
                {{item.purpose}}
            </span>
            <span class="test-authors">
                {{item.authors}}
            </span>
            
            <span class="test-description" [innerHtml]="item.description">                
            </span>
            <hr>
            <span class="test-subtitle-mini">
                {{'CATALOG.INFORMATION'|translate}}
            </span>
            <span class="test-info">
                <i class="pi pi-bookmark"></i>
                <span>{{'CATALOG.ITEM'|translate}} {{item.minQuestions}}</span>
            </span>
            <span class="test-info">
                <i class="pi pi-clock"></i>
                <span>{{item.administrationTime}}</span> <!--remove minutes label for now-->
                <div *ngFor="let type of item.customEntryTypes" class="type-label">
                    {{('CREATE-PROJECT.DD-' + type | uppercase) | translate}}
                </div>
            </span>
            <span class="test-info">
                <i class="pi pi-chart-line"></i>
                <span>{{item.normalizationSample}}</span>
            </span>
            <!-- HIDDEN FOR MVP -->
            <!-- <span class="test-info">
                <i class="pi pi-file-o"></i>
                <span>{{'CATALOG.LANGUAGES'|translate}}
                    <span *ngFor="let lang of item.reportLanguages; let i = index">
                        <span *ngIf="i>0">, </span>{{lang}}
                    </span>
                </span>
            </span>
            <span class="test-info test-info-last">
                <i class="pi pi-flag"></i>
                <span>{{'CATALOG.TESTS'|translate}}
                    <span *ngFor="let lang of item.testLanguages; let i = index">
                        <span *ngIf="i>0">, </span>{{lang}}
                    </span>
                </span>
            </span> -->
            <hr>
            <span class="test-subtitle-mini">
                {{'CATALOG.REPORT'|translate}}
            </span>
            <span class="test-credit" *ngFor="let report of availableReports">
                <span>
                    {{report.name | titlecase}}
                </span>
                <div class="credit" *ngIf="showCredits">
                    {{report.credits}} {{'CATALOG.CREDIT'|translate}}
                </div>
                <button 
                    *ngIf="item.active"
                    type="button" 
                    class="btn-mini p-mx-1" 
                    pButton 
                    (click)="assignTest(report)"
                    label="{{'CATALOG.ASSIGN' | translate}}">
                </button>
            </span>
            <h3 *ngIf="!item.active" class="p-text-center invalid-text">{{'CATALOG.PERMISSION' | translate}}</h3>
        </div>
    </div>

    <p-footer>
        <!-- <div class="test-button">
            <button 
                type="button" 
                class="btn-primary" 
                pButton 
                (click)="assignTest()"
                label="{{'CATALOG.ASSIGN'|translate}}">
            </button>
        </div> -->
    </p-footer>
</p-dialog>