import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class CoreApi {
    private oauthUrl = environment.apiLoginUrl;
    private apiUrl = environment.apiUrl;
    private plansUrl = environment.planUrl;

    constructor(private http: HttpClient, private translate: TranslateService) {}


    captcha(user): Observable<any> {
        return this.http.post(this.apiUrl + 'captcha', user);
    }

    login(user): Observable<any>{
        return this.http.post(this.oauthUrl + 'oauth/token', user);
    }

    forgotPassword(user): Observable<any>{
        user['language'] = this.translate.currentLang; // Get the current selected language (not the browser one)        
        return this.http.post(this.oauthUrl + 'oauth/forgot', user);
    }

    refresh(tokens): Observable<any>{
        return this.http.post(this.oauthUrl + 'oauth/token', tokens);
    }

    changePassword(user): Observable<any>{
        return this.http.post(this.oauthUrl + 'oauth/change', user);
    }

    getProfileData(): Observable<any>{
        return this.http.get(this.apiUrl + 'psy/getMe');
    }
    
    getPsyUserCredits(psyUserID): Observable<any>{
        return this.http.get(this.plansUrl + 'credits/psyuser/' + psyUserID);
    }
    
    getPsyUserUses(psyUserID): Observable<any>{
        return this.http.get(this.plansUrl + 'uses/psyuser/' + psyUserID);
    }

    getSubsidiaryData(): Observable<any>{
        return this.http.get(this.apiUrl + 'psy/subsidiaryInfo');
    }

    getScoringNotifications(psyUserID): Observable<any> {
        return this.http.get(this.apiUrl + 'scoring/notifications/' + psyUserID);
    }

    getReportingNotifications(psyUserID): Observable<any> {
        return this.http.get(this.apiUrl + 'report/notifications/' + psyUserID);
    }

    deleteAllNotifications(psyUserID): Observable<any> {
        return this.http.delete(this.apiUrl + 'report/notifications/' + psyUserID);
    }

    deleteNotification(psyUserID, projectId, type): Observable<any> {
        return this.http.delete(this.apiUrl + 'report/notifications/' + psyUserID + '/' + projectId + '/' + type);
    }
    getFirstQrAndChallenge(data): Observable<any>  {
        return this.http.post(this.oauthUrl + 'mfa/challenge', data);
    }
}
