<div class="main p-md-flex p-jc-center">
    <div class="content p-d-flex p-ai-center">
        <form [formGroup]="loginForm" class="login-form " *ngIf="!mfa">
            <h3>{{ 'LOGIN.LOGIN' | translate }}</h3>
            <span class="p-float-label">
                <input id="email" type="text" pInputText class="custom-input-text" formControlName="email">
                <label for="email">{{ 'LOGIN.EMAIL' | translate }}</label>
                <span *ngIf="email.invalid && email.touched" [@inOutAnimation] class="error-tooltip">
                    {{loginForm.controls.email['errorMessage']}}
                </span>
            </span>
            <div class="p-inputgroup custom-inputgroup custom-inputgroup-password">
                <span class="p-float-label">
                    <input id="password" type="password" pPassword [feedback]="false" [showPassword]="showPassword"
                        class="custom-input-text" formControlName="password">
                    <label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
                    <span *ngIf="password.invalid && password.touched" [@inOutAnimation] class="error-tooltip">
                        {{loginForm.controls.password['errorMessage']}}
                    </span>
                </span>
                <button type="button" pButton icon="{{showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}}"
                    class="input-button" (click)="showPassword = !showPassword">
                </button>
            </div>

            <div *ngIf="loginErrors > 2 && !isBlocked" class="btn-row p-d-flex p-jc-center p-ai-center ">
                <re-captcha #captchaRef (resolved)="resolved($event)" [siteKey]="getKey()" formControlName="captchaRef">
                </re-captcha>
            </div>

            <div class=" p-d-flex p-jc-between p-ai-center">
                <a class="btn-link pointer" [routerLink]="['../forgot']">
                    {{ 'LOGIN.FORGOT' | translate }}
                </a>
                <button type="submit" class="btn-primary" pButton label="{{'LOGIN.SUBMIT' | translate}}"
                    [disabled]="loginForm.invalid" (click)="login()">
                    <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
                    </p-progressSpinner>
                </button>
            </div>
        </form>

        <form [formGroup]="mfaForm" class="login-form " *ngIf="mfa">
            <h3>{{'LOGIN.OTP' | translate}}</h3>
            <span *ngIf="mfa && !secret"class="p-float-label p-text-center">
                <img src="{{qr}}" alt="QR Validation" />
            </span>
            <span class="p-float-label">
                <input id="otp" type="text" pInputText class="custom-input-text" formControlName="otp">
                <label for="otp">OTP</label>
                <span *ngIf="otp.invalid && otp.touched" [@inOutAnimation] class="error-tooltip">
                    {{mfaForm.controls.otp['errorMessage']}}
                </span>
            </span>
            
            <div class=" p-d-flex p-jc-end">
                <button type="submit" class="btn-primary otp" pButton label="{{'LOGIN.SUBMIT' | translate}}"
                    [disabled]="mfaForm.invalid" (click)="askForChallenge()">
                    <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
                    </p-progressSpinner>
                </button>
            </div>
        </form>

    </div>
</div>