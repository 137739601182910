import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PeopleApi } from 'src/app/modules/people/api/people.api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from './../../../../../environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-testtaker-group-selector',
  templateUrl: './testtaker-group-selector.component.html',
  styleUrls: ['./testtaker-group-selector.component.scss'],
  providers: [ ToastService ]
})
export class TesttakerGroupSelectorComponent implements OnDestroy, OnInit {

  @Input() itemRef: Number;
  @Input() parentDataCount: number;
  @Input() type: string;
  @Input() ignoreLimit: boolean = false;
  @Input() hideSelectAll: boolean = false;
  @Output() selectedDataEvent = new EventEmitter<any>();

  public showModal : boolean = false;

  // Users Data
  public usersData = [];
  public usersDataCount = 0;
  usersDataLoaded: boolean = false;

  // Users Search box
  isUsersSearchHovered: boolean = false;
  usersSearchText: string = '';
  isUsersFiltered: boolean = false;

  // Groups Data
  public groupsData = [];
  public groupsDataCount = 0;
  groupsDataLoaded: boolean = false;

  // Groups Search box
  isGroupsSearchHovered: boolean = false;
  groupsSearchText: string = '';
  isGroupsFiltered: boolean = false;

  // Common selected data
  public selectedData = [];

  // Select all status
  selectAllUserSwStatus = false;
  selectAllGroupsSwStatus = false;

  // Pagination default
  public rowsToDisplayDefault = 10;
  public topRow = this.rowsToDisplayDefault;

  // Set max testtakers allowed
  public MAX_TESTTAKERS = environment.MAX_TESTTAKERS;

  // To show tab based in groups or users
  activeTabIndex = 0;

  subs = new SubSink();

  stateOptions = [{
        label: this.translate.instant('EDIT-GROUP.USERS'),
        value: 0},
      { label: this.translate.instant('FILTER.GROUPS'),
        value: 1}];

  constructor(private authSrv: AuthenticationService,
              private peopleApi: PeopleApi,
              private toastSrv: ToastService,
              private translate: TranslateService,
              private messageSrv: MessageService) {}
  ngOnInit(): void {
    this.activeTabIndex = this.type === 'group' ? 1 : 0;
  }

  public openModal() {
    this.usersSearchText = '';
    this.isUsersFiltered = false;
    this.groupsSearchText = '';
    this.isGroupsFiltered = false;

    this.showModal = true;
    this.initDataLazy();
  }

  initDataLazy(applyViewType?: boolean){
    // Initial pagination query
    let event: LazyLoadEvent = {
      first: 0,
      rows: this.rowsToDisplayDefault,
      sortOrder: 1
    };

    this.getDataLazy(event, applyViewType);
  }

  /**
   * Clear previously selected data
   */
  public clear(){
    this.selectedData = [];
  }

  /**
   * Get paginated user list
   * @param event
   */
  getDataLazy(event: LazyLoadEvent, applyViewType?: boolean) {
    // Call API
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(_psyUser =>{
      if (_psyUser) {
        if(this.type === 'user' || (applyViewType && this.activeTabIndex == 0)) {
          this.loadUserDataOnTable(event);
        } else if (this.type === 'group' || (applyViewType && this.activeTabIndex == 1)) {
          this.loadGroupDataOnTable(event);
        } else {
          this.loadUserDataOnTable(event);
          this.loadGroupDataOnTable(event);
        }
      };
    });
  }

  loadUserDataOnTable(event) {
    this.usersDataLoaded = false;

    // Add search text value
    if(this.usersSearchText !== ''){
      event.globalFilter = this.usersSearchText;
    }

    this.subs.sink = this.peopleApi.getTestTakersLazy(event, this.itemRef, this.itemRef ? true : undefined).subscribe({
      next: _res => {
        this.usersData = _res.data.rows;
        this.usersDataCount = _res.data.count;
        this.usersData = this.usersData.map(x => ({...x, completeName: `${x.name} ${x.surname}`, name: x.name + '' }));
        this.setPreviouslySelectedItems();
      },
      error: _err => {
        this.toastSrv.showToastError(this.translate.instant('TABLE.ERROR-API'))
      },
      complete: () => {
        this.usersDataLoaded = true;
      }
    });
  }


  loadGroupDataOnTable(event){
    this.groupsDataLoaded = false;

    if(this.groupsSearchText !== ''){
      event.globalFilter = this.groupsSearchText;
    }

    this.subs.sink = this.peopleApi.getTestTakerGroupsLazy(event, this.itemRef, this.itemRef ? true : undefined).subscribe({
      next: _res => {
        this.groupsData = _res.data.rows;
        this.groupsDataCount = _res.data.count;
        this.groupsData = this.groupsData.map(x => ({...x, isGroup: true }));
        this.setPreviouslySelectedItems();
      },
      error: _err => {
        this.toastSrv.showToastError(this.translate.instant('TABLE.ERROR-API'))
      },
      complete: () => {
        this.groupsDataLoaded = true;
      }
    });
  }

  /**
   * Add seleted item to temporal array
   */
  selectItem(item) {
    if(item.selected === true && !this.selectedData.some(_item => _item.id == item.id)){
      this.selectedData.push(item);
      this.updateParentDataCount()
      if(!this.ignoreLimit && this.parentDataCount>this.MAX_TESTTAKERS){
        this.showAddUserLimit();
      }
    } else if (item.selected === false){
      this.selectedData = this.selectedData.filter(_item => _item.id !== item.id);
      this.updateParentDataCount()
        if(!this.ignoreLimit && this.parentDataCount<=this.MAX_TESTTAKERS){
          this.closeToastError();
        }
    }
    this.setUnsetSelectAllSwitch();
  }

  updateParentDataCount(){
    const selectedUsersLength = this.selectedData.filter(_item => !_item.isGroup).length;
    const selectedGroupsLength = this.selectedData.filter(_item => _item.isGroup).reduce((a, b) => {
      return a + b.testtakersCount;
    }, 0);

    this.parentDataCount = selectedUsersLength + selectedGroupsLength;
  }

  /**
   * Mark previously selected items, for UI
   */
  setPreviouslySelectedItems(){
    this.usersData.forEach(_user => {
      const seletectedUser = this.selectedData.find(_selected => _user.id == _selected.id);

      _user.selected = seletectedUser ? true : false;
      _user.original = seletectedUser?.original;
    });


    this.groupsData.filter(_item => this.selectedData.filter(item => item.isGroup)
                                      .map(_selected => _selected.id)
                                      .includes(_item.id))
                                      .forEach(_item => _item.selected = true);

    this.setUnsetSelectAllSwitch();
  }

  setUnsetSelectAllSwitch(){
    if(!this.usersData.some(_user => !_user.selected)){
      this.selectAllUserSwStatus = true;
    } else {
      this.selectAllUserSwStatus = false;
    }

    if(!this.groupsData.some(_group => !_group.selected)){
      this.selectAllGroupsSwStatus = true;
    } else {
      this.selectAllGroupsSwStatus = false;
    }
  }

  /**
   * Paginate component
   * @param paginationEvent
   */
  paginate(paginationEvent) {
    // To show UI top limit
    this.topRow = paginationEvent.first + paginationEvent.rows;

    // Pagination query
    let event: LazyLoadEvent = {
      first: paginationEvent.first,
      rows: paginationEvent.rows,
      sortOrder: 1
    };

    this.getDataLazy(event, true);
  }

  //--------MESSAGES------------
  showAddUserLimit(): void{
    this.showToastError(this.translate.instant('SHARING-PANEL.MAX-REACHED'));
  }

  showToastError(text: string): void {
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');
    this.messageSrv.add({severity: 'error', detail: text, summary: btnText ,key: 'toast-error-dialog', life: 5000});
  }

  closeToastError(): void {
    this.messageSrv.clear('toast-error-dialog');
  }

  confirm(){
    this.selectedDataEvent.emit([...this.selectedData]);
  }

  //--------SEARCH BOX-------
  onUsersEnterPressed(event: any) {
    this.filterUsersByText();

    // To show button as hover
    this.isUsersSearchHovered = true;

    setTimeout(() => {
      this.isUsersSearchHovered = false;
    }, 2000);
  }

  onGroupsEnterPressed(event: any) {
    this.filterGroupsByText();

    // To show button as hover
    this.isGroupsSearchHovered = true;

    setTimeout(() => {
      this.isGroupsSearchHovered = false;
    }, 2000);
  }

  filterUsersByText() {
    this.isUsersFiltered = true;
    this.initDataLazy(true);
  }

  filterGroupsByText() {
    this.isGroupsFiltered = true;
    this.initDataLazy(true);
  }

  resetUsersFilter() {
    this.usersSearchText = '';
    this.isUsersFiltered = false;
    this.initDataLazy(true);
  }

  resetGroupsFilter() {
    this.groupsSearchText = '';
    this.isGroupsFiltered = false;
    this.initDataLazy(true);
  }

  selectAllUsers($event) {
    if($event.checked){
      // Set as selected
      this.usersData.forEach(_user => _user.selected = true);
      // Add the current users page
      this.selectedData = this.selectedData.concat(this.usersData);
    } else {
      // Ser as not selected
      this.usersData.forEach(_user => _user.selected = false);
      // Extract the groups
      let auxGroups = this.selectedData.filter(_item => _item.isGroup);
      // Remove the current users page
      this.selectedData = this.selectedData.filter(_user => !this.usersData.map(x => x.id).includes(_user.id));
      // Add groups again to avoid drop based in id
      this.selectedData = this.selectedData.concat(auxGroups);
    }
    // Remove selected duplicates
    this.selectedData = this.selectedData.filter((_value, i, array) =>
      array.findIndex(x => x.id === _value.id && x.isGroup === _value.isGroup) === i
    );
    // Update count
    this.updateParentDataCount();
  }

  selectAllGroups($event) {
    if($event.checked){
      // Set as selected
      this.groupsData.forEach(_group => _group.selected = true);
      // Add the current users page
      this.selectedData = this.selectedData.concat(this.groupsData);
    } else {
      // Ser as not selected
      this.groupsData.forEach(_group => _group.selected = false);
      // Extract the users
      let auxUsers = this.selectedData.filter(_item => !_item.isGroup);
      // Remove the current groups page
      this.selectedData = this.selectedData.filter(_group => !this.groupsData.map(x => x.id).includes(_group.id));
      // Add groups again to avoid drop based in id
      this.selectedData = this.selectedData.concat(auxUsers);
    }
    // Remove selected duplicates
    this.selectedData = this.selectedData.filter((_value, i, array) =>
      array.findIndex(x => x.id === _value.id && x.isGroup === _value.isGroup) === i
    );
    // Update count
    this.updateParentDataCount();
  }

  showRepeatedMessage() {
    return this.selectedData.some(_item => _item.isGroup);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
