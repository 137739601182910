<app-row-card 
    [optionLeft]="optionsLeft" 
    [optionRight]="optionsRight" 
    [customNopeopleTl]="groupItems" 
    [item]="item"
    [triggerName]="triggerName"
    (itemTriggered)="triggerItem()">
</app-row-card>

<ng-template #groupItems>
    <div class="container-data">
        <span class="row-name p-mx-3 p-d-flex p-ai-center p-jc-start">
            <span class="p-mr-2">{{item.test.parentTest.test}}</span>
            {{item.testTitle}}
        </span>
    </div>
</ng-template>