import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsRoutingModule } from './projects-routing.module';
import { SharedElementsModule } from 'src/app/shared/shared-elements.module';
import { ProjectsContainerComponent } from './components/projects-container/projects-container.component';
import { ProjectsComponent } from './components/projects-container/projects/projects.component';
import { DetailProjectComponent } from './components/detail-project/detail-project.component';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AnswersProjectComponent } from './components/answers-project/answers-project.component';
import { InputDataComponent } from './components/answers-project/components/input-data/input-data.component';
import { InputScoreComponent } from './components/answers-project/components/input-score/input-score.component';
import { InfoBoxComponent } from './components/answers-project/components/info-box/info-box.component';
import { InputItemComponent } from './components/answers-project/components/input-item/input-item.component';
import { ProjectDataComponentComponent } from './components/detail-project/components/project-data-component/project-data-component.component';
import { ChartComponentComponent } from './components/detail-project/components/chart-component/chart-component.component';
import { ProjectActionsComponent } from './components/projects-container/project-actions/project-actions.component';


@NgModule({
  declarations: [
    ProjectsContainerComponent,
    ProjectsComponent,
    DetailProjectComponent,
    CreateProjectComponent,
    AnswersProjectComponent,
    InputDataComponent,
    InputScoreComponent,
    InfoBoxComponent,
    InputItemComponent,
    ProjectDataComponentComponent,
    ChartComponentComponent,
    ProjectActionsComponent
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedElementsModule,
    DragDropModule
  ],
  exports: [
    ProjectsContainerComponent
  ]
})
export class ProjectsModule { }
