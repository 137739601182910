import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { group } from '@angular/animations';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';
import { HelpService } from '../../help/services/help.service';

@Injectable({
    providedIn: 'root'
})
export class PeopleApi {

    subs = new SubSink();

    private url = environment.apiUrl;
    private boUrl = environment.apiBackOfficeURL;
    private psyuserId;

    constructor(private http: HttpClient, private authSrv: AuthenticationService, private helperSrv: HelpService) {
        this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(psyUser => {
            if(psyUser){
                this.psyuserId = psyUser.id
            }
        });
    }

    getUser(userId): Observable<any>{
        return this.http.get(this.url + 'users/' +  userId);
    }

    getTestTakersLazy(params, groupId?, denied?: boolean): Observable<any> {
        this.helperSrv.cleanLazyAndFormatLoadEventObj(params);

        const psyuserId = this.authSrv.getCurrentPsyUser().id;

        // Add groupId if needed
        let requestParams = groupId? `${psyuserId}/${groupId}` : psyuserId;

        // Add denied group or not
        if(denied) {
            params['denied'] = true;
        }

        return this.http.get(this.url + 'v2/testtakers/psyuser/' + requestParams, { params: params });
    }

    getTestTakerGroupsLazy(params, testtakerId?, denied?: boolean): Observable<any>{
        this.helperSrv.cleanLazyAndFormatLoadEventObj(params);

        const psyuserId = this.authSrv.getCurrentPsyUser().id;

        // Add groupId if needed
        let requestParams = testtakerId? `${psyuserId}/${testtakerId}` : psyuserId;

        // Add denied group or not
        if(denied) {
            params['denied'] = true;
        }

        return this.http.get(this.url + 'v2/testtakers/groups/psyuser/' + requestParams, { params: params });
    }

    getGroups(): Observable<any>{
        return this.http.get(this.url + 'groups/psyuser/' + this.psyuserId);
    }

    getCountries(): Observable<any>{
        return this.http.get(this.boUrl + 'country');
    }

    getEducations(): Observable<any>{
        return this.http.get(this.boUrl + 'educations');
    }

    updateUser(user, originalUserId): Observable<any>{
        return this.http.patch(this.url + 'users/' + originalUserId, user);
    }

    createUser(user): Observable<any>{
        return this.http.post(this.url + 'users', user);
    }
    createUsers(users): Observable<any>{
        return this.http.post(this.url + 'importusers/' + this.psyuserId, users);
    }

    validateAndClassifyUsersToImport(users): Observable<any>{
        return this.http.post(this.url + 'importusers/' + this.psyuserId, {usersToSave:users, validateWithoutSaving:true});
    }

    validateAndClassifyUsersToImportWithGroupInformation(users, groupId): Observable<any>{
        return this.http.post(this.url + 'importusers/' + this.psyuserId, {usersToSave:users, validateWithoutSaving:true, previouslyOnGroup:groupId});
    }

    deleteUsers(deleteObj): Observable<any>{
        return this.http.request('delete', this.url + 'users/', {body: deleteObj});
    }

    assignGroups(assignObj): Observable<any>{
        return this.http.post(this.url + '/psyuser/' + this.psyuserId + '/users/groups', assignObj);
    }

    updateGroupUsersBulk(obj): Observable<any>{
        return this.http.patch(this.url + 'user/groups', JSON.stringify(obj));
    }

    createGroup(group): Observable<any>{
        return this.http.post(this.url + 'groups', group);
    }

    updateGroup(group): Observable<any>{
        return this.http.patch(this.url + 'groups/' + group.id, group);
    }

    deleteGroups(groupId): Observable<any>{
        return this.http.request('delete', this.url + 'groups/' + groupId);
    }

    assignUsersToGroup(assignObj): Observable<any>{
        return this.http.post(this.url + 'group/' + assignObj.groupID + '/users', assignObj);
    }

    deleteUsersGroup(deleteObj): Observable<any>{
        return this.http.request('delete', this.url + 'group/' + deleteObj.groupID + '/users', {body: deleteObj});
    }
}
