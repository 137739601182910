import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private messageSrv: MessageService, private translate: TranslateService) { }

  showToastSucess(text: string): void{
    this.messageSrv.add(
      {severity: 'success', detail: text, key: 'toast-sucess'});
  }

  showToastWarning(text: string): void{
    this.messageSrv.add(
      {severity: 'warning', detail: text, key: 'toast-warning'});
  }
 showToastError(text: string): void{
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');
    this.messageSrv.add({severity: 'error', detail: text, summary: btnText, key: 'toast-error', life: 10000});
  }

  showToastInfo(text: string,details: string): void{


    this.messageSrv.add(
      {severity: 'error', detail: text, summary: details, key: 'toast-info', life: 10000,sticky: details!= "" });
  }

  closeToastError(): void {
    this.messageSrv.clear('toast-error');
  }

  // Add warning-ok to show message warning with only confirmation action
  showToastConfirm(text: string, severity: 'error' | 'warning' | 'success' | 'warning-ok' = 'error'): void {
    let summary = '';
    if(severity === 'warning-ok'){
      summary = severity;
      severity = 'warning';
    }
    this.messageSrv.add({severity: severity, detail: text, summary: summary, key: 'toast-confirm', sticky: true});
  }

  closeToastConfirm(): void {
    this.messageSrv.clear('toast-confirm');
  }
}
