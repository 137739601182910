import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { ProjectType } from 'src/app/shared/enums/project-type.enum';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { ReportingService } from 'src/app/modules/projects/services/reporting.service';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { ReportSelectorTemplateComponent } from '../../../modal-templates/report-selector-template/report-selector-template.component';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { NonValidScoringTemplateComponent } from '../../../modal-templates/non-valid-scoring-template/non-valid-scoring-template.component';
import { EntryType } from 'src/app/shared/enums/entry-type.enum';

@Component({
  selector: 'app-template-project-user',
  templateUrl: './template-project-user.component.html',
  styleUrls: ['./template-project-user.component.scss']
})
export class TemplateProjectUserComponent implements OnInit, OnDestroy, OnChanges {

  @Input() item;
  @Input() optionsLeft;
  @Input() optionsRight;
  @Input() disconnected;
  @Input() entryType;
  @Input() emailStatusRetrieved;


  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemStatusChanged = new EventEmitter<any>();
  @Output() sendMail = new EventEmitter<any>();

  @ViewChild('reportingPopupTemplate') reportingPopupRefPopup: ReportSelectorTemplateComponent;
  @ViewChild('NonValidPopupTemplate') scoredNonValidRefPopup: NonValidScoringTemplateComponent;

  readonly ProjectStatusString = ProjectStatusString;

  // Entry Types
  projectEntryType = EntryType;

  itemTests = [];
  itemOptions: any;
  subProjectType = ProjectType;
  loading = false;

  displayPanel = false;
  dialogItems = [];
  displayScoring = false;

  subs = new SubSink();

  //Reporting logic
  projectTestReportList = [];
  existServedReport = false;
  existNotServedReport = false;
  displayAnotherReportPopup: boolean;

  //Email status
  emailTrackingActive : boolean = false;

  //Report requested

  downloading: boolean = false;
  downloadQuestioners = [];
  reportToDownloadList = [];
  partialReportingCount : any[] = [];

  //Administration Data
  displayAdministrationData = false;
  urlAssessment = environment.webAssesmentURL;

  //Scored non valid
  NonValidScoringCount = [];
  displayNonValidScoringPopup = false;
  onlyValidReportList = [];

  showScoringBtn: false

  constructor(
    private translate: TranslateService,
    private projectsSrv: ProjectsService,
    private reportingSrv: ReportingService,
    private toastSrv: ToastService,
    private errorsSrv: ErrorsService,
    private messageSrv: MessageService
  ) { }

  ngOnInit(): void {

    // Fix: Copy object to avoid change propagation
    this.item = JSON.parse(JSON.stringify(this.item));

    this.countToReportingPopUp();

    // Generate report status and structure
    this.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(this.partialReportingCount);

    // To know if at least one report is served or not
    this.existServedReport = this.reportingSrv.checkIfAtLeastOneReportIsServed(this.projectTestReportList);
    this.existNotServedReport = this.reportingSrv.checkIfAtLeastOneReportIsNotServed(this.projectTestReportList);

    this.manageEmailTracking();

    this.initMenuOptions();

    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.initMenuOptions();
    });

    this.countNonValid();
    this.showScoringBtn = this.item.tests.some((test: any) => test.statusCode === QuestionnaireCodeStatus.READY_TO_SCORE || test.statusCode === QuestionnaireCodeStatus.COMPILED);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.item && changes.item.isFirstChange()){
      this.item.firstChange = changes.item.firstChange;
      this.setReportRequestedFlag();
    }
  }

  /**
   * Initialices the three-dot menu choices according to project status
   * (Not visible in project-user-component)
   */
   initMenuOptions(): void {
    if ([this.subProjectType.inMaSi,this.subProjectType.inMaMu, this.subProjectType.inPrSi, this.subProjectType.inPrMu,
        this.subProjectType.inReSi, this.subProjectType.inReMu].includes(this.item.projectType)) {
      this.getMenuOptionsIn();
    }

    if ([this.subProjectType.grReSi, this.subProjectType.grReMu, this.subProjectType.grMaSi, this.subProjectType.grMaMu,
        this.subProjectType.inBlSi, this.subProjectType.inBlMu, this.subProjectType.grBlSi, this.subProjectType.grBlMu]
        .includes(this.item.projectType)) {
      this.getMenuOptionsGr();
    }
  }

  getMenuOptionsIn(): void{
    this.subs.sink = this.translate.get('random.key').subscribe(() => {

      const aux = [];

      if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(this.item.status)) {
        aux.push(this.getOptBlindAdministrationData());
      }

      // Always insert delete option
      aux.push(this.getDeleteOpt());


      this.itemOptions = [{
        label: 'Options',
        items: aux
      }];
    });
  }

  getMenuOptionsGr(): void{
    this.subs.sink = this.translate.get('random.key').subscribe(() => {

      const aux = [];

      if([ProjectStatusString.ASSIGNED, ProjectStatusString.FILLING].includes(this.item.status)) {
        aux.push(this.getOptBlindAdministrationData());
      }

      if ([ProjectStatusString.SCORED, ProjectStatusString.SCORED_NON_VALID, ProjectStatusString.REPORT_SERVED].includes(this.item.status)) {
        aux.push(this.getOptInsert());
      }

      // Always insert delete option
      aux.push(this.getDeleteOpt());

      this.itemOptions = [{
        label: 'Options',
        items: aux
      }];
    });
  }


  /**
   * Build the delete user choice to three dot menu
   * @returns
   */
  getDeleteOpt(): any {
    return {
      label: this.translate.instant('GROUP.DELETE'),
      icon: 'pi pi-trash',
      command: () => {
        this.deleteItem();
      }
    };
  }

  getOptInsert(): any {
    return {
      label: this.translate.instant('PROJECTS.MENU-ENTRY-MASK'),
      icon: 'pi pi-plus',
      command: () => {
        this.insert(false);
      }
    };
  }

  getOptBlindAdministrationData(){
    return {
      label: this.translate.instant('PROJECTS.MENU-BLIND-ADMINISTRATION'),
      icon: 'pi pi-file-excel',
      command: () => {
        this.displayAdministrationData = true;
      }
    };
  }

  deleteItem(): void {
    this.itemDeleted.emit(this.item);
  }

  selectItem(): void {
    this.itemSelected.emit(this.item);
  }

  requestScoring(): void {
    this.insert(true);
    // this.projectsSrv.setQCode(this.item.tests[0].questionnaireCode);
  }

  insert(isScoring: boolean): void {
    this.dialogItems = [];
    this.displayScoring = isScoring;
    if (this.item.tests.length > 1) {
      // Includes all questionnaires to consult/complete entryMask
      this.dialogItems = this.item.tests.filter(x => !x.test.external);
      this.displayPanel = true;
    } else {
      this.insertByItem(this.item.tests[0], isScoring);
    }
  }

  insertByItem(item, isScoring): void {
    this.projectsSrv.insertQCodeAndNavigateToAnswers(item.questionnaireCode, isScoring);
  }

  changeQuestionnaireStatus(item): void {
    this.itemTests.filter(x => x.questionnaireCode === item.qCode)[0].statusCode = QuestionnaireCodeStatus.SCORED;
  }

  changeItemStatus(qCodes): void {
    // change row status to scored
    this.item.status = QuestionnaireCodeStatus.SCORED;
    // emit change to parent component
    this.itemStatusChanged.emit(qCodes.map(z => z.qCode));
  }

 /**
  * Manage the email status tracking for testtaker
  */
  manageEmailTracking() {
    if(this.item.emailStatus != null){
      this.emailTrackingActive = true;
    }
  }

  /**
   * Manages the popup used to select a report to purchase
   * or download directly if already purchased.
   *
   * Configures the popup to get reports from a single testtaker
   */
   showAnotherReportMenuProject() {
    this.item.singleTestPurchase = true;
    if (this.NonValidScoringCount.length === 0){
      this.displayAnotherReportPopup = true;
    } else {
      this.displayNonValidScoringPopup = true
    }
  }


  /**
   * After closing the report menu upon report purchase
   * Close the popup and update the project card
   */
  anotherReportMenuOnClose(){
    this.displayAnotherReportPopup = false;
  }


  /**
   * Request to the server the selected report and download it
   * showing a message to inform about the result.
   * @param report
   */
   public async downloadPurchasedReports(questionnaires) {
    await this.reportingSrv.downloadServedReports(this.item, questionnaires).then(res => {
      if(res === this.reportingSrv.STANDARD_SUCCESS_MESSAGE ) {
        // Messages
        let messageToShow=this.errorsSrv.getShowableMessageFromHttpCode(
          res,
          {
            useGenericEnvIfCustomFail:true,
            customEnvironment:"REPORTS.HTTP-CODES",
            defaultMsgKey:"REPORTS.HTTP-CODES.Plans.SUCCESS"
          }
        )

        this.toastSrv.showToastSucess(messageToShow.translatedMessage);
      }
    }).catch(err => {
      this.toastSrv.showToastError(this.translate.instant('PROJECTS.ERROR_REPORT'));
      console.error(err)
    })
    this.downloading = false;
  }

  public sendEmail(id:number){
    this.sendMail.emit(id);
  }

  setReportRequestedFlag() {
    this.item.tests?.forEach(_quest => {
      if(_quest.reportingResults?.some(x => x.served)) {
        _quest.reportingResults.forEach(reportingResult => {
          if(reportingResult.served){
            this.downloadQuestioners.push({..._quest,reportingResult:reportingResult});
          }
        })
      }
    });
  }
  public download(){
    this.downloading = true;
    this.downloadPurchasedReports(this.downloadQuestioners);
  }

  countToReportingPopUp(){
    for (let test of this.item.tests){
      if (test.statusCode >= QuestionnaireCodeStatus.SCORED){
        this.partialReportingCount.push(test);
      }
    }
  }

  getCapitalLetter(text){
    if(text){
      text = text+''
      return text.charAt(0)
    }
    return '#'
  }

  getBlindAministrationData() {
    const tempProject = {
      id: this.item.id,
      code: this.item.projectName,
      supply: this.item.projectSupply,
      friendlyId: this.item.testtakerFriendlyId
    }

    this.projectsSrv.generateBlindAdministrationExcel(tempProject).then(res => {
      if(res){
        this.toastSrv.showToastSucess(this.translate.instant('PROJECTS.API-BLIND-OK'));
      } else {
        this.toastSrv.showToastError(this.translate.instant('PROJECTS.API-BLIND-NO'));
      }
    }).catch(err => {
      this.toastSrv.showToastError("Error " + err.message);
    })
  }

  changeTestStatusByReportingRequest($event){
    this.item.tests.forEach(_test => {
      if($event.includes(_test.questionnaireCode)){
        _test.statusCode = QuestionnaireCodeStatus.REPORT_ONGOING;
        _test.status_name = ProjectStatusString.REPORT_ONGOING;;
      }
    });
  }

  showCopySuccess(){
    this.messageSrv.add({severity: 'success', detail: this.translate.instant('PROJECTS.COPY-OK'), key: 'toast-success-copy', life: 3000});
  }

  countNonValid(){
    this.item.tests.forEach(x => {
      if (x.status_name === 'non-valid'){
        this.NonValidScoringCount.push(x)
      }
    });
  }

  closePopup(){
    this.displayNonValidScoringPopup = false;
  }

  getAllReports(){
    this.projectTestReportList = this.reportingSrv.getReportObjectByQuestionnaires(this.item.tests)
    this.displayNonValidScoringPopup = false;
    this.displayAnotherReportPopup = true;
  }

  async getOnlyValidReports(){
    this.item.tests.forEach(_quest => {
      if(_quest.statusCode != QuestionnaireCodeStatus.SCORED_NON_VALID){
        this.onlyValidReportList.push(_quest)
      }
    })
    this.projectTestReportList = await this.reportingSrv.getReportObjectByQuestionnaires(this.onlyValidReportList);
    this.displayNonValidScoringPopup = false;
    this.displayAnotherReportPopup = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
