import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminApi } from 'src/app/modules/admin/api/admin.api';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { SubSink } from 'subsink';
import { ProjectStatusString } from '../../enums/project-status-string.enum';
import { CreditsService } from '../../services/credits.service';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {

  @Input() isProfile = false;

  listProjects = [];
  userName = '';
  surname = '';
  qualification = '';
  dataAssigned: any;  
  countAssigned = 0;  
  dataCreated: any;  
  countCreated = 0;
  dataInProgress: any;  
  countInProgress = 0;
  dataPerformed: any;  
  countPerformed = 0;;
  dataScored: any;  
  countScored = 0;
  dataFinished: any;  
  countFinished = 0;
  dataScoredNonValid: any;  
  countScoredNonValid = 0;
  currentUserCredits = 0;
  currentUserUses = 0;
  showDialog = false;
  deleteProject = 0;
  showCredits: boolean;
  uses = '';
  showUses: boolean;
  showPlanInfo: boolean;
  plansToShow = [];
  currentUser: any;    

  subs = new SubSink();

  public readonly doughnutOptions = {
    cutout: 28,
    circumference: 180,
    rotation: 270,
    borderRadius: 20,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  constructor(
    private translate: TranslateService,
    private authSrv: AuthenticationService,
    private projectsApi: ProjectsApi,
    private projectsSrv: ProjectsService,
    private creditsSrv: CreditsService
  ) { }

  ngOnInit(): void {

    this.initGraphs();

    this.subs.sink = this.projectsSrv.sharedProjectDeleted.subscribe(res => {
      if (this.deleteProject !== res) {
        this.deleteProject = res;
        this.initGraphs();
      }
    });
  }

  initGraphs(): void {
    this.subs.sink = this.projectsApi.getProjectsStatus().subscribe({
      next: (res) => {
        this.listProjects = res.data;
      },
      error: () => {
        this.listProjects = [];
      }
    }).add(() => {
        this.authSrv.sharedCurrentPsyUser.subscribe(dataUsr => {  
          this.subs.sink = this.authSrv.sharedCurrentPsyUserCredits.subscribe(psyUserCredits => {
            this.currentUserCredits = psyUserCredits;
          });

          this.subs.sink = this.authSrv.sharedCurrentPsyUserUses.subscribe(psyUserUses => {
            this.currentUserUses = psyUserUses;
          });
          
          this.qualification = dataUsr?.qualification;
          this.userName = dataUsr?.name;
          this.surname = dataUsr?.surname;
          this.showCredits = dataUsr?.hasLegacyPlan;
          this.showUses = dataUsr?.hasUsesPlan;          
          this.currentUser = dataUsr;

          this.setProjectStatus();
          this.setGraphDataCreated();
          this.setGraphDataAssigned();
          this.setGraphDataInProgress();
          this.setGraphDataPerformed();
          this.setGraphDataScored();
          this.setGraphDataScoredNonValid();
          this.setGraphDataFinished();
        });
      }
    );
  }

  setGraphDataCreated(): void {
    this.countCreated = this.listProjects.filter(x => x.status === ProjectStatusString.CREATED).length;
    const countTotal = this.listProjects.length - this.countCreated;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataCreated = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-CREATED')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countCreated, countTotal],
                backgroundColor: [
                  '#e8f0fa',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#e8f0fa',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#e8f0fa',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor: '#a2c1de',
            }],
        };
    });
  }

  setGraphDataAssigned(): void {
    this.countAssigned = this.listProjects.filter(x => x.status === ProjectStatusString.ASSIGNED).length;
    const countTotal = this.listProjects.length - this.countAssigned;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataAssigned = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-ASSIGNED')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countAssigned, countTotal],
                backgroundColor: [
                  '#fcdfa5',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#fcdfa5',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#fcdfa5',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor: '#fcdfa5',
            }],
        };
    });
  }

  setGraphDataInProgress(): void{
    this.countInProgress = this.listProjects.filter(x => x.status === ProjectStatusString.FILLING).length;
    const countTotal = this.listProjects.length - this.countInProgress;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataInProgress = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-IN-PROGRESS')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countInProgress, countTotal],
                backgroundColor: [
                  '#fca902',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#fca902',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#fca902',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor: '#fca902',
            }],
        };

    });
  }

  setGraphDataPerformed(): void{
    this.countPerformed = this.listProjects.filter(x => x.status === ProjectStatusString.READY_TO_SCORE).length;
    const countTotal = this.listProjects.length - this.countPerformed;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataPerformed = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-PERFORMED')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countPerformed, countTotal],
                backgroundColor: [
                  '#86d0f5',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#86d0f5',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#86d0f5',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor:  '#86d0f5',
            }],
        };

    });
  }

  setGraphDataScored(): void{
    this.countScored = this.listProjects.filter(x => x.status === ProjectStatusString.SCORED).length;
    const countTotal = this.listProjects.length - this.countScored;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataScored = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-PERFORMED')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countScored, countTotal],
                backgroundColor: [
                  '#006EB6',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#006EB6',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#006EB6',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor:  '#006EB6',
            }],
        };

    });
  }

  setGraphDataScoredNonValid(): void{
    this.countScoredNonValid = this.listProjects.filter(x => x.status === ProjectStatusString.SCORED_NON_VALID).length;
    const countTotal = this.listProjects.length - this.countScoredNonValid;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataScoredNonValid = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-SCORED-NON-VALID')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countScoredNonValid, countTotal],
                backgroundColor: [
                  '#ff276a',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#ff276a',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#ff276a',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor:  '#ff276a',
            }],
        };

    });
  }

  setGraphDataFinished(): void{
    this.countFinished = this.listProjects.filter(x => x.status === ProjectStatusString.REPORT_SERVED).length;
    const countTotal = this.listProjects.length - this.countFinished;

    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      this.dataFinished = {
        labels: [
          `${this.translate.instant('PROJECTS.GR-FINISHED')}`,
          `${this.translate.instant('DASHBOARD.OTHERS')}`
        ],
        datasets: [
            {
                data: [this.countFinished, countTotal],
                backgroundColor: [
                  '#004379',
                  '#ffffff'
                ],
                hoverBackgroundColor: [
                  '#004379',
                  '#ffffff'
                ],
                hoverBorderColor: [
                  '#004379',
                  '#ffffff'
                ],
                hoverBorderWidth: 5,
                borderColor:   '#004379',
            }],
        };
    });
  }

  setProjectStatus(): void {
    this.listProjects.forEach(x => {
      x.status = this.projectsSrv.getProjectStatusStringByCode(x.statusCode);
    });
  }

  buyCredits(): void{
    if (this.translate.currentLang === 'it'){
      this.creditsSrv.buyCredits();
    } else {
      this.showDialog = true;
    }
  } 

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
