import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';

@Component({
  selector: 'app-non-valid-scoring-template',
  templateUrl: './non-valid-scoring-template.component.html',
  styleUrls: ['./non-valid-scoring-template.component.scss']
})
export class NonValidScoringTemplateComponent implements OnInit {


  @Input() reasonNonValid: string;
  @Input() hasValidQuestionnaires: boolean = false

  /*Sólo utilizado para la lista de proyectos, considerar eliminar la opción
  de itemTests y getReasonNonValid cuando se adapte la lista a las nuevas 
  funcionalidades de reporting usadas en detail-project en base a info general
  del proyecto y no a la lista de todos los questionnaires*/
  @Input() itemTests;

  constructor(
    private projectsApi: ProjectsApi,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if(!this.itemTests)
      this.setReasonNonValid()
    else{
      this.getReasonNonValidFromQuestionnaires()
    }
  }
  
  async getReasonNonValidFromQuestionnaires(){
    //if(this.itemTests.some(x => x.status = 'scored'))
    if(this.itemTests?.length > 1)
      this.hasValidQuestionnaires = true

    let _reason; 
    try {
      _reason = await this.projectsApi.getQuestionnairesReasonNonValid(this.itemTests)
      this.reasonNonValid = _reason.data ? _reason.data : this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
    }
    catch {
      this.reasonNonValid = this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
    }
  }

  setReasonNonValid() {
    this.reasonNonValid = this.reasonNonValid ?? this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
  }
}
