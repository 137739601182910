import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsApi } from 'src/app/modules/projects/api/projects.api';
import { QuestionnaireCodeStatus } from '@giuntipsy/utils/lib';

@Component({
  selector: 'app-users-groups-circles',
  templateUrl: './users-groups-circles.component.html',
  styleUrls: ['./users-groups-circles.component.scss']
})
export class UsersGroupsCirclesComponent implements OnInit {

  @Input() listItems = [];
  @Input() type: 'user' | 'group' | 'individual' | 'projectgroup' | 'status'  = 'user';
  @Input() escapeHtml: false;
  extra: string;
  reasonNonValid: any;
  tooltip: any;
  questionnairesList = [];

  constructor(
    private projectsApi: ProjectsApi,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {   
    this.extra = `+${(this.listItems.length - 5) > 99 ? '' : this.listItems.length - 5}`;

    this.questionnairesList = this.listItems;
    //Set title and check reasonNonValid
    this.listItems.forEach( async x =>{
      /// SET TOOLTIP TEXT
      if(this.type !== 'user') {
        x.tooltip = `<span>${x.testTitle}</span>`;
      }      

      //TOOLTIP FOR SCORED_NON_VALID
      if (x.statusCode === QuestionnaireCodeStatus.SCORED_NON_VALID){
        await this.getReasonNonValid(x);
        x.tooltip += `:<br><b>"<span>${x.reasonNonValid}</span>"`
      }
    });
  }

  async getReasonNonValid(item){

    //Check if reasonNonValid is provided and try to request it if not found
    if(item.reasonNonValid){
      return item.reasonNonValid = item.reasonNonValid ? item.reasonNonValid : this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
    }
    else{
      await this.projectsApi.getScoringParamsByQCode(item.questionnaireCode).toPromise().then((_result)=>{
        let reason = _result.data.scoringParams.reasonNonValid
        item.reasonNonValid = reason ? reason : this.translate.instant('REPORTS.NON-VALID-REASON-STANDART');
      }).catch((error) => {
        console.error(error)
      })
    }
  }
}
