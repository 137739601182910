<footer>
    <div class="content p-d-flex p-jc-between p-ai-center p-shadow-2">
        <div class="p-d-flex">
            <app-language-selector></app-language-selector>
            <div class="footer-text p-mx-6  p-d-none p-d-md-inline-flex">                
                <p>© 2003, {{currentYear}}, Giunti Psychometrics S.r.l. - 00421250481
                    <br>
                    Data Protection Manager (art. 37 of the EU REG 2016/679) Avv. Victoria Parise
                    <br>
                    <a href="mailto:privacy@giuntipsy.com" class="pointer">privacy@giuntipsy.com</a>
                </p>
            </div>
        </div>
        <div class="logo p-d-flex">
            <div class="text-logo">
                <p>Powered by</p>
            </div>
            <div>
                <img class="img-footer" src="assets/images/logo_footer.png">
                <br>
                <span>Giunti-Testing v: {{currentAppVersion}}</span>
            </div>
        </div>
    </div>
</footer>
    