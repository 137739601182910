import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { AssignationGroupsService } from 'src/app/shared/services/assignation-groups.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { inOutAnimation } from 'src/app/shared/animations/in-out-animation';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { Codes, PsyUserRoles } from '@giuntipsy/utils/lib';
import { AdminService } from '../../services/admin.service';
import { AdminApi } from '../../api/admin.api';
import { environment } from 'src/environments/environment';
import {  PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-edit-psyuser',
  templateUrl: './edit-psyuser.component.html',
  styleUrls: ['./edit-psyuser.component.scss'],
  providers: [ToastService],
  animations: [ inOutAnimation ]
})
export class EditPsyuserComponent implements OnInit, OnDestroy {
  @ViewChild('name') inputName: ElementRef;

  editForm: UntypedFormGroup;
  public loaded = false;
  public loadingTests = false;
  public type: string;
  public formData: any = [];
  subs = new SubSink();
  assignationsGroups: any[];
  dataAG: any;
  private psyuser: any;
  private admin_psyuser: any;
  private originalId: number;
  private customer_id = 0;
  private subscriptionTest: any;
  clientId = environment.clientId;
  readonly psyUserRoles = PsyUserRoles;
  psyUserRolesOpts = [{
    key: PsyUserRoles.PSYUSER,
    labelKey: 'DETAIL-PEOPLE.PROFILE-USER',
    label: ''
  },
  {
    key: PsyUserRoles.PSYUSER_ADMIN,
    labelKey: 'DETAIL-PEOPLE.PROFILE-ADMIN',
    label: '',
  },
  {
    key: PsyUserRoles.PSYUSER_FULL,
    labelKey: 'DETAIL-PEOPLE.PROFILE-ADMIN-FULL',
    label: '',
  },
  {
    key: PsyUserRoles.ASSISTANT,
    labelKey: 'DETAIL-PEOPLE.PROFILE-ASSISTANT',
    label: ''
  }];

  constructor(
    private assignationGroupsService: AssignationGroupsService,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private adminSrv: AdminService,
    private adminApi: AdminApi,
    private authService: AuthenticationService,
    public primeNGConfig: PrimeNGConfig
  ) {  
    
    this.subs.sink =  this.translate.get('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });

  }

  ngOnInit(): void {

    this.subs.sink = this.activatedroute.queryParamMap.subscribe(event => {
      const params: any = event;
      this.type = params.params.type;
      // Enable only edit
      if (this.type === 'NEW') {
        this.initPage();
      } else {
        this.initPageAsEdit();
        this.assignationGroupsService.getAssignationsPsyUser(this.originalId).subscribe(async (res) => {
          this.editForm.value.assignationsGroup = await res.data.map(x => x.id);
          this.editForm.controls['assignationsGroup'].setValue(this.editForm.value.assignationsGroup) ;
          this.updateTest();
        });      
      }

      this.setRolesTranslation();

      this.loaded = true;
    });

    this.subs.sink = this.adminSrv.sharedCustomer.subscribe(res => {
      if (res) {
        this.customer_id = res.id;
        this.getAssignationsCustomers(this.customer_id);
      }
    });

    setTimeout( () => {
      this.inputName?.nativeElement.click();
    }, 200 ); 
  }

  getAssignationsCustomers(id) {
    this.assignationGroupsService.getAssignationsCustomer(id).subscribe(
      (list) => {
        this.assignationsGroups = list.data;         
      } ,
      err =>{
        console.log(err);
        this.assignationsGroups = [];
      }
    ) 
  }

  initPageAsEdit(): void {
    this.subs.sink = this.adminSrv.sharedPsyUserToEdit.subscribe(
    usr =>  {
      if (usr == null) {
        this.router.navigate(['admin']);
        return;
      }  
      this.psyuser = usr;
      this.originalId = usr.id;
      this.initPage();
      this.setUserDetail(usr);
    
    },
    err => {
      this.router.navigate(['admin']);
    });
  }

  initPage(): void {
    this.subs.sink = this.authService.sharedCurrentPsyUser.subscribe(
      usr => {
        if (usr == null){
          this.router.navigate(['admin']);
          return;
        }
        this.admin_psyuser = usr;
       
      },
      err => {
        this.router.navigate(['admin']);
      });      
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.setRolesTranslation();
      if (this.editForm !== undefined) {
        this.editForm.reset();
      }
    });
    this.initForm();
  }

  initForm(): void{
    this.setValidationMessages();
    this.generateForm();
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            required: this.translate.instant('VALIDATIONS.REQUIRED'),
            email: this.translate.instant('VALIDATIONS.EMAIL'),
            phone: this.translate.instant('VALIDATIONS.PHONE'),
            phonePattern: this.translate.instant('VALIDATIONS.PHONE'),
            onlyIntegers: 'Solo permite enteros'
          }
        });
    });
  }

  generateForm(): void {
    //this.formData["groups"] = this.assignationGroupSrv..apply()
    this.editForm = this.formBuilder.group({
      name : ['', [RxwebValidators.required()]],
      surname: ['', [RxwebValidators.required()]],
      email: ['', [RxwebValidators.required(), RxwebValidators.email()]],
      phone: [''],
      isAdmin: ['', [RxwebValidators.required()]],
      assignationsGroup: [''],
      transferAssignationGroupPsyUser: [false],
      qualification: ['']
    });
  }
  /*
  Update the Test List from selected AssignationGroups
  */
  updateTest(){    
    this.loadingTests = true;
    if ( this.subscriptionTest ) {
      this.subscriptionTest.unsubscribe();
   }
   this.subscriptionTest = this.assignationGroupsService.getAssignationGroupsTests(this.customer_id,this.editForm.controls['transferAssignationGroupPsyUser'].value,this.editForm.controls['assignationsGroup'].value, this.admin_psyuser.id).subscribe(
      list => {
        this.dataAG =  list.data;
      },
      error => {
        console.log(error);
        this.dataAG = [];
      },
      () =>{
        this.loadingTests = false;
      }
    );    
  }
  cancel(){
    this.router.navigate(['admin', 'manage']);
  }
  setUserDetail(item): void {
    const controls: string[] = Object.keys(this.editForm.controls);

    Object.keys(item).forEach(e => {
      if (item[e] !== undefined && item[e] !== null && item[e] !== '' && controls.includes(e)) {
        this[e].patchValue(item[e]);
        this[e].markAsTouched();
      }
    });
  }

  get name(): any {
    return this.editForm.get('name');
  }

  get surname(): any {
    return this.editForm.get('surname');
  }

  get email(): any {
    return this.editForm.get('email');
  }

  get phone(): any {
    return this.editForm.get('phone');
  }

  get qualification(): any{
    return this.editForm.get('qualification');
  }

  get isAdmin(): any{
    return this.editForm.get('isAdmin');
  }

  saveChanges(): void {  
    if (this.type === 'EDIT') {
      const previousEmail = this.psyuser.email;
      const previousName = this.psyuser.name;
      const previousSurname = this.psyuser.surname;
      const previousQualification = this.psyuser.qualification;

      this.mapObjects();
      // check if is an email change
      if (previousEmail != this.psyuser.email){
        let currentUser = this.authService.getCurrentUser();
        const body ={
          email: previousEmail, 
          newEmail: this.psyuser.email, 
          customer_id: this.psyuser.customer_id, 
          doByUser: currentUser.id, 
          client_id: this.clientId, 
          comments: "Changed at PsyPortal platform", 
          name: this.psyuser.name
        }

        this.adminApi.changeEmail(this.psyuser, body).subscribe(
          (res) => {
            // checks if the request has another modified field
            if (previousName != this.psyuser.name || previousSurname != this.psyuser.surname || 
                previousQualification != this.psyuser.qualification){
                  this.updatePsyUser()
            } else {
              this.toastSrv.showToastSucess(this.translate.instant("EDIT-PEOPLE.API-EDIT-OK"));
              this.adminSrv.changeCustomerPsyusers(this.psyuser);
              this.router.navigateByUrl('admin');
            }
          },
          (error) =>{
            let code = error.error.code;
            let msg = this.translate.instant("EDIT-PEOPLE.ERROR-CREATING");
            if (code == Codes.PsyPortal.REQUEST_ERROR) {
              msg = this.translate.instant("EDIT-PEOPLE.MAIL-ID-REPEATED");
            }
            this.toastSrv.showToastError(msg);
          }
        )
      } else {
        this.updatePsyUser()
      }
    } else {
      this.mapObjects();
      if (this.psyuser.email === '' || this.psyuser.email === undefined){
        this.psyuser.email = null;
      }
      this.psyuser.parent_psyuser_id = this.admin_psyuser.id;
      this.psyuser.client_id = environment.clientId;
      this.psyuser.clientName = environment.clientName;
      this.psyuser.language = this.translate.currentLang;
      this.psyuser.customer_id = this.customer_id;

      this.subs.sink = this.adminApi.createPsyUser(this.psyuser).subscribe(
        res => {
          this.psyuser = res.data;
          this.adminSrv.changeCustomerPsyusers(this.psyuser);
          this.toastSrv.showToastSucess(this.translate.instant('EDIT-PEOPLE.API-CREATE-OK'));
          this.router.navigateByUrl('admin');
        },
        error => {
          let code = error.error.code;
          let msg = this.translate.instant('EDIT-PEOPLE.ERROR-CREATING');
          if (code == Codes.PsyPortal.REQUEST_ERROR) {
            msg = this.translate.instant('EDIT-PEOPLE.MAIL-ID-REPEATED');
          }
          this.toastSrv.showToastError(msg);
      });
    }
  }

  updatePsyUser() {
    // preserve original code
    this.subs.sink = this.adminApi.updatePsyUser(this.psyuser, this.originalId).subscribe(
      (res) => {
        this.toastSrv.showToastSucess(this.translate.instant("EDIT-PEOPLE.API-EDIT-OK"));
        this.adminSrv.changeCustomerPsyusers(this.psyuser);
        this.router.navigateByUrl('admin');
      },
      (error) => {
        let code = error.error.code;
        let msg = this.translate.instant("EDIT-PEOPLE.ERROR-CREATING");
        if (code == Codes.PsyPortal.REQUEST_ERROR) {
          msg = this.translate.instant("EDIT-PEOPLE.MAIL-ID-REPEATED");
        }
        this.toastSrv.showToastError(msg);
      }
    );
  }

  mapObjects(): void {
    this.psyuser = this.editForm.value;
    if (this.type !== 'NEW'){
      this.psyuser.id = this.originalId;
      this.psyuser.customer_id = this.adminSrv.customerId;
    }
  }

  setRolesTranslation(){
    this.psyUserRolesOpts.forEach(rol => {
      rol.label = this.translate.instant(rol.labelKey);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
