<!--TOP SIDEBAR-->
<p-sidebar
    [(visible)]="displayActionSidebar"
    position="top"
    class="sidebar-action"
    [dismissible]="false"
    [closeOnEscape]="false"
    [modal]="false">

    <div class="sidebar-content p-d-flex p-ai-center p-jc-between">

        <!--Project Name-->
        <div class="sa-input-text">
            <input
                type="text"
                pInputText
                [(ngModel)]="project.name"
                placeholder="{{'CREATE-PROJECT.NAME-PLACEHOLDER' | translate}}">
        </div>

        <!--Administration Type & Credits/Uses-->
        <div class="p-d-flex">
            <div class="sa-supply p-d-flex p-jc-center p-ai-center">
                <p-dropdown
                    *ngIf="loadedDropdown"
                    [options]="supplyTypes"
                    [(ngModel)]="selectedType"
                    optionLabel="name"
                    (onChange)="project.supply=selectedType.code; cleanProjectsTest()"
                    [disabled]="isEdit"
                    appendTo="body">
                </p-dropdown>
            </div>

            <div class="credits-container">
                <ng-container *ngIf="showCredits && showUses;else showCreditsDiv">
                    <div class="sa-credits sa-credits-both p-d-flex p-ai-center">
                        <div class="p-d-flex p-flex-column p-ai-center">
                            {{accumulatedCredits}}
                            <span class="p-text-center">{{'CREATE-PROJECT.CREDITS'|translate}}</span>
                        </div>
                        <div class="p-d-flex p-flex-column p-ai-center">
                            {{accumulatedUses}}
                            <span class="p-text-center">{{'CREATE-PROJECT.USES'|translate}}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showCreditsDiv>
                    <ng-container *ngIf="showCredits;else showUsesDiv">
                        <div class="sa-credits p-d-flex p-flex-column p-jc-center p-ai-center">
                            {{accumulatedCredits}}
                            <span class="p-text-center">{{'CREATE-PROJECT.CREDITS'|translate}}</span>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #showUsesDiv>
                    <ng-container *ngIf="showUses;">
                        <div class="sa-credits p-d-flex p-flex-column p-jc-center p-ai-center">
                            {{accumulatedUses}}
                            <span class="p-text-center">{{'CREATE-PROJECT.CREDITS'|translate}}</span>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </div>
</p-sidebar>

<!-- ZENDESK BUTTON -->
<div class="btn-zendesk" *ngIf="showZendeskButton">
    <app-zendesk-button [embedded]="true"></app-zendesk-button>
</div>

<!--MAIN CONTENT-->
<div class="project-content p-grid p-text-right">

    <div class="content-people p-col-12 p-md-12 p-lg-6">
        <div>
            <div class="p-d-flex p-ai-center add-container">
                <h3>{{'CREATE-PROJECT.ADD-USER'|translate}}</h3>
                <div>
                    <button
                        pButton
                        class="btn-mini3 p-mx-3"
                        label="{{'CREATE-PROJECT.BTN-ADD'|translate}}"
                        (click)="selectTesttTakerAndGroupOnLoad(); testtakerGroupSelector.openModal()">
                    </button>
                </div>
            </div>
            <div class="data-people" cdkDropList (cdkDropListDropped)="dropUser($event)">
                <div *ngIf="project.testtakers.length > 0">
                    <div *ngFor="let item of project.testtakers;" cdkDrag>
                        <app-template-user-simple
                            *ngIf="!item.isGroup"
                            [item]="item"
                            (itemDeleted)="deleteUser($event)"
                            [optionsLeft]="'move'"
                            [optionsRight]="'delete'">
                        </app-template-user-simple>
                        <app-template-group-simple
                            *ngIf="item.isGroup"
                            [item]="item"
                            (itemDeleted)="deleteUser($event)"
                            [optionsLeft]="'move'"
                            [optionsRight]="'delete'">
                        </app-template-group-simple>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-tests p-col-12 p-md-12 p-lg-6">
        <div>
            <div class="p-d-flex p-ai-center add-container">
                <h3 *ngIf="availableTests.length > 0">{{'CREATE-PROJECT.ADD-TEST'|translate}}</h3>
                <div *ngIf="availableDataLoaded">
                    <h3 *ngIf="availableTests.length == 0">{{('EMPTY.GENERAL' | translate)}}</h3>
                    <button
                        pButton
                        *ngIf="availableTests.length > 0"
                        class="btn-mini3 p-mx-3"
                        [disabled]="isEdit"
                        label="{{'CREATE-PROJECT.BTN-ADD'|translate}}"
                        (click)="displayTestsPanel=true">
                    </button>
                </div>
                <p-progressSpinner *ngIf="!availableDataLoaded" [style]="{width: '30px', height: '30px', 'margin-left': '20px'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
            </div>
            <div class="data-tests" cdkDropList (cdkDropListDropped)="dropTest($event)">
                <div *ngIf="project.questionnaires.length > 0 && availableDataLoaded">
                    <div *ngFor="let item of project.questionnaires; let i = index" cdkDrag>
                        <app-template-test
                            [item]="item"
                            (itemDeleted)="deleteTest($event, i)"
                            [optionsLeft]="'move'"
                            [optionsRight]="'delete'">
                        </app-template-test>
                    </div>
                </div>

                <!--SEQUENCIAL BTN-->
                <div class="sa-sequence p-grid p-jc-center p-mt-2" *ngIf="project.questionnaires.length > 1">
                    <p-inputSwitch
                        [disabled]="isEdit"
                        [(ngModel)]="project.sequence">
                    </p-inputSwitch>
                    <span>{{'CREATE-PROJECT.SEQUENCE'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!--ACTION BUTTONS-->
<div class="sa-buttons" *ngIf="project.name.trim() != ''">
    <!--Manual-->
    <!--Individual-->
    <div *ngIf="!creating">
        <button
            *ngIf="project.testtakers.length == 1 && project.supply === projectEntryType.Manual && project.questionnaires.length > 0"
            type="button"
            class="btn-secondary p-mx-1 p-my-1"
            pButton
            (click)="assigned = true; determinatePopUp(['projects/'])"
            label="{{'CREATE-PROJECT.BTN-LATER'|translate}}">
        </button>
        <button
            *ngIf="project.testtakers.length == 1 && project.supply === projectEntryType.Manual && project.questionnaires.length === 1"
            type="button"
            class="btn-primary p-mx-1 p-my-1"
            pButton
            (click)="assigned = true; determinatePopUp()"
            label="{{'CREATE-PROJECT.BTN-ANSWERS'|translate}}">
        </button>

        <!--Groups-->
        <button *ngIf="project.testtakers.length > 1 && project.supply === projectEntryType.Manual  && project.questionnaires.length > 0 && (assigned === false || assigned === undefined)"
            type="button"
            class="btn-secondary p-mx-1 p-my-1"
            pButton
            (click)="assigned = true; determinatePopUp(['projects/'])"
            label="{{'CREATE-PROJECT.BTN-LATER'|translate}}">
        </button>
        <button
            *ngIf="project.testtakers.length > 1 && project.supply === projectEntryType.Manual  && project.questionnaires.length > 0 && assigned === true"
            type="button"
            class="btn-secondary p-mx-1 p-my-1"
            pButton
            (click)="withMail = false; determinatePopUp(['projects/'])"
            label="{{'CREATE-PROJECT.BTN-LATER'|translate}}">
        </button>

        <button
            *ngIf="project.testtakers.length > 1 && project.supply === projectEntryType.Manual && project.questionnaires.length > 0"
            type="button"
            class="p-mx-1 p-my-1 btn-primary"
            pButton
            (click)="assigned = true; redirect = true; determinatePopUp(['projects/'])"
            label="{{'CREATE-PROJECT.BTN-ANSWERS'|translate}}">
        </button>

        <!--On-Site-->
        <button
            *ngIf="project.testtakers.length == 1 && project.supply === projectEntryType.OnSite && project.questionnaires.length > 0"
            type="button"
            class="btn-primary p-mx-1 p-my-1"
            pButton
            (click)="assigned = true; withMail = false; determinatePopUp()"
            label="{{'CREATE-PROJECT.BTN-ADMIN'|translate}}">
        </button>

        <!--Remote-->
        <button
            *ngIf="project.testtakers.length > 0 &&
                project.supply === projectEntryType.Remote &&
                project.status !== ProjectStatusString.ASSIGNED &&
                project.questionnaires.length > 0"
            type="button"
            class="p-mx-1 p-my-1 btn-secondary"
            pButton
            (click)="assigned = false; withMail = false; determinatePopUp()"
            label="{{'CREATE-PROJECT.BTN-SAVE'|translate}}">
        </button>

        <button
            *ngIf="project.testtakers.length > 0 &&
                project.supply === projectEntryType.Remote &&
                project.questionnaires.length > 0"
            type="button"
            class="btn-primary p-mx-1 p-my-1"
            pButton
            (click)="assigned = true; withMail = true; determinatePopUp()"
            label="{{'CREATE-PROJECT.BTN-SEND'|translate}}">
        </button>

        <!--Blind-->
        <button
            *ngIf="project.testtakers.length > 0 &&
                project.supply === projectEntryType.Blind &&
                project.status !== ProjectStatusString.ASSIGNED &&
                project.questionnaires.length > 0"
            type="button"
            class="p-mx-1 p-my-1 btn-secondary"
            pButton
            (click)="assigned = false; withMail = false; determinatePopUp()"
            label="{{'CREATE-PROJECT.BTN-SAVE'|translate}}">
        </button>

        <button
            *ngIf="project.testtakers.length > 0 &&
                project.supply === projectEntryType.Blind &&
                project.questionnaires.length > 0"
            type="button"
            class="btn-primary p-mx-1 p-my-1"
            pButton
            (click)="withMail = false; assigned = true; determinatePopUp(['projects/']);"
            label="{{'CREATE-PROJECT.BTN-SEND-BLIND'|translate}}">
        </button>
    </div>

    <p-progressSpinner  *ngIf="creating" [style]="{width: '30px', height: '30px', 'margin-left': '20px'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>


<!--POP-UP: Tests -->
<p-dialog
    *ngIf="availableDataLoaded"
    [(visible)]="displayTestsPanel"
    [style]="{width: '80vw'}"
    [baseZIndex]="10000"
    [modal]="true"
    class="tests-dialog"
    (onShow)="selectedTest=undefined;
    selectedReport_id=[];
    invalidTest=0;">

    <!--TOAST ELEMENTS-->
    <p-toast position="top-center" key="toast-warning-dialog" preventOpenDuplicates="true" class="toast-dialog">
        <ng-template let-message pTemplate="message">
            <div class="p-d-flex p-ai-center p-jc-center toast-content">
                <p><i class="pi pi-info-circle"></i> <span class="warning-msg p-mx-2">{{message.detail}}</span></p>
                <button type="button" class="btn-toast-warning pointer p-mx-3" (click)="closeToastWarning()">{{message.summary}}</button>
            </div>
        </ng-template>
    </p-toast>

    <div class="dialog-content">
       <h2 class="p-text-center">{{'CREATE-PROJECT.ADD-TEST' | translate}}</h2>
        <span class="p-float-label" *ngIf="catalogLoaded">
            <p-dropdown
                #test
                [options]="availableTests"
                class="custom-dropdown"
                filterBy="combinedName"
                [filter]="true"
                [scrollHeight]="'150px'"
                optionLabel="combinedName"
                placeholder="&nbsp;"
                [(ngModel)]="selectedTest"
                (onChange)="checkValidTest()"
                [showClear]="false">
            </p-dropdown>
            <label for="test">{{ "CREATE-PROJECT.TEST" | translate }}</label>
        </span>
        <div class="reports" *ngIf="selectedTest!==undefined">
            <div class="p-d-flex p-ai-center">
                <span>{{'PROJECTS.SUPPLY' | translate | uppercase}}</span>
                <div *ngFor="let type of selectedTest.customEntryTypes" class="type-label">
                    {{ 'CREATE-PROJECT.DD-'+(type|uppercase) | translate}}
                </div>
            </div>
            <br>
            <span>{{'CREATE-PROJECT.REPORT' | translate}}</span>
            <div *ngFor="let item of sortSelectedTestReports(selectedTest.reports);" class="p-field-radiobutton" [ngClass]="{ 'appendix': item.parentReport_id !== null }">
                <p-checkbox
                    *ngIf="item.parentReport_id === null"
                    name="item.id"
                    value="item.id"
                    [value]="item.id"
                    [(ngModel)]="selectedReport_id"
                    [inputId]="item.id"
                    [disabled]="invalidTest > 0">
                </p-checkbox>
                <label for="{{item.id}}">{{item.name}}
                    <span class="credit" *ngIf="showCredits || currentPsyUser.hasLegacyPlan">{{item.credits}} {{'CREATE-PROJECT.CREDIT' | translate}}</span>
                </label>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <button
            [disabled]="selectedReport_id.length===0 || selectedTest===undefined || invalidTest > 0"
            pButton
            (click)="displayTestsPanel=false; addTestsToProject()"
            label="{{'CREATE-PROJECT.BTN-ASSIGN' | translate}}"
            class="btn-primary">
        </button>
    </ng-template>
</p-dialog>

<!--POP-UP: Emails Edition -->
<p-dialog (onHide)="withMail = false" [(visible)]="displayCustomEmail" [modal]="true"[style]="{height: '454px'}" class="email-template">
    <div>
        <h3>Email: </h3>
        <app-custom-email (htmlChanged)="emailContent($event)"></app-custom-email>
        <div class="center top">
            <button
                pButton
                (click)="saveProjectWithMail()"
                label="{{'CREATE-PROJECT.BTN-SEND' | translate}}"
                class="btn-primary">
            </button>
        </div>
    </div>
</p-dialog>

<!--POP-UP: Users without emails-->
<p-dialog [(visible)]="displayPanelUsersWithoutEmails" [style]="{width: '70vw'}" [baseZIndex]="10000" [modal]="true" class="emails-dialog">
    <div class="dialog-content-emails p-text-center">
        <h3>{{'CREATE-PROJECT.USERS-EMAILS'|translate}}</h3>
        <div *ngFor="let item of usersWithoutEmails">
            <app-template-user-simple
                *ngIf="!item.isGroup"
                [item]="item"
                [optionsLeft]="'none'"
                [optionsRight]="'none'">
            </app-template-user-simple>
            <app-template-group-simple
                *ngIf="item.isGroup"
                [item]="item"
                [optionsLeft]="'none'"
                [optionsRight]="'none'"
                [notEmails]="true">
            </app-template-group-simple>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button
            pButton
            (click)="displayPanelUsersWithoutEmails=false;"
            label="{{'CREATE-PROJECT.BTN-OK' | translate}}"
            class="btn-primary">
        </button>
    </ng-template>
</p-dialog>

<!--POP-UP: Role-based tests -->
<p-dialog [(visible)]="displayRoleBasedAssignation" [style]="{width: '70vw'}" [baseZIndex]="10000" [modal]="true">
    <div class="dialog-content">
        <app-role-based-template
        #roleBasedPopUp
        [availableUsers]="project.testtakers"
        [roles]="roles"
        [evaluatedPerson]="evaluatedPerson"
        [evaluatedPersonList]="evaluatedPersonList"
        (setSelectedRoles)="checkValidRoleAssingToSave($event)"
        (setEvaluatedPerson)="checkValidEvaluatedPersonToSave($event)">
        </app-role-based-template>
        <div class="p-mt-5 p-d-flex p-jc-end">
            <button
                pButton
                [disabled]="!validRoleBased"
                (click)="determinateNextStepBySupplyType(); displayRoleBasedAssignation = false"
                label="{{'CREATE-PROJECT.BTN-OK' | translate}}"
                class="btn-primary">
            </button>
        </div>
    </div>
</p-dialog>

<!--TESTTAKERS SELECTOR-->
<app-testtaker-group-selector #testtakerGroupSelector [type]="'both'" (selectedDataEvent)="loadSelectedDataModal($event)" [parentDataCount]="getParentDataCount()"></app-testtaker-group-selector>