import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SubSink } from 'subsink';
import {AdminApi} from './../api/admin.api';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private subs = new SubSink();

  private customer;

  private currentCustomer = new BehaviorSubject<any>(null);
  sharedCustomer = this.currentCustomer.asObservable();

 
  private psyuserToEdit = new BehaviorSubject<any>(null);
  sharedPsyUserToEdit = this.psyuserToEdit.asObservable();

  constructor(
    private authSrv: AuthenticationService,
    private adminApi: AdminApi
  ) {
    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(res => {
      if (res !== null) {
        const cust_id = res.customer_id;
        this.subs.sink = this.adminApi.getCustomerData(cust_id).subscribe(res => {
          this.customer = res.data;
          this.currentCustomer.next(this.customer);
        }, err => {});
      }
    });


    const psyUserToEdit = localStorage.getItem('psyUserToEdit');
    if (psyUserToEdit) {
      try {
        this.setPsyToEdit(JSON.parse(psyUserToEdit));
      } catch (ex) {}
    }

  }

  get customerId(){
    return this.customer.id;
  }

  setCustomer(cust){
    this.currentCustomer.next(cust);
  }

  setPsyToEdit(user){
    localStorage.setItem('psyUserToEdit', JSON.stringify(user));
    this.psyuserToEdit.next(user);
  }

  changeCustomerPsyusers(psyuser){
    let indx = 0;
    let indxToModify = -1;
    this.customer.psyusers.forEach(_psy => {
      if(_psy.id == psyuser.id)
        indxToModify = indx;
      indx++;
    });
    if(indxToModify >= 0){
      this.customer.psyusers[indxToModify] = psyuser;
    }else{
      this.customer.psyusers.push(psyuser);
    }
    this.currentCustomer.next(this.customer);
  }

  deletePsyuser(id){
    let indx = 0;
    let indxToModify = -1;
    this.customer.psyusers.forEach(_psy => {
      if(_psy.id == id)
        indxToModify = indx;
      indx++;
    });
    this.customer.psyusers.splice(indxToModify,1);
    this.currentCustomer.next(this.customer);
  }
}
