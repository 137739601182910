import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CoreApi } from 'src/app/core/api/core.api';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AssignationGroupsService } from 'src/app/shared/services/assignation-groups.service';
import { HistoryService } from 'src/app/shared/services/history.service';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ToastConfirmService } from 'src/app/shared/services/toast-confirm.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { AdminApi } from '../../api/admin.api';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.scss'],
  providers: [ToastService]
})
export class ManagePlanComponent implements OnInit {

  constructor(
    private adminSrv: AdminService,
    private router: Router,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private toastConfirmSrv: ToastConfirmService,
    private adminApi: AdminApi,    
    private authService: AuthenticationService,
    private planSrv: PlanService,
    private historySrv: HistoryService,
    private assignationGroupSrv: AssignationGroupsService
  ) { }

  subs = new SubSink();
  private confirmSubscription: Subscription;

  // Loaders
  loaded = false; 
  showHistory = false;
 
  // Customer data
  customerId = 0;
  customerName = '';
  customerSubscriptions = [];
  customerConsumesInfo = {};

  // Selected Psyuser data
  psyuser: any;
  psyuserSubscriptions = []; // All Psyuser subscriptions (active/inactive)
  psyuserSubscriptionsToShow = [];
  psyuserSubscriptionsHistory = [];

  // Current Psyuser (using the app) 
  currentPsyUser; 

  // Show Pop-ups
  showAvailablePlans = false;
  showLegacyPopUp = false;
  showPlanInfo = false;
  showCredits = false;

  // Variables for credit change
  customerCredits = 0;
  psyUserCredits = 0;
  inputValue = 0;  
  customerCreditsAux = 0;
  psyUserCreditsAux = 0;
  buttonDisabled = true;
  tempPlan = {};  
  
  // Plan details
  planToShow: any;

  readonly psyUserRoles = PsyUserRoles;
  

  ngOnInit(): void {    
    this.initData();
  }

  initData() {    
    this.showHistory = false;
    this.subs.sink = this.authService.sharedCurrentPsyUser.subscribe(
      (res) => {  
        if (res) {

          // Set UI settings
          this.showCredits = res?.hasLegacyPlan;

          // Customer and psyuser Ids
          this.customerId = res.customer.id;
          this.currentPsyUser = res;

          // Get customer suscriptions
          this.planSrv.getCustomerPlans(this.customerId).then(res => {        
            this.customerSubscriptions = res;  

            // Get edit PsyUser
            this.subs.sink = this.adminSrv.sharedPsyUserToEdit.subscribe(res => {
              this.psyuser = res;    
               // Get psyuser qualification          
               this.subs.sink = this.assignationGroupSrv.getHigherAssignationGroupCode(res.id).subscribe(q => {
                  this.psyuser.qualification =q.data;
              });
     
              // Get psyuser subscriptions
              this.subs.sink = this.adminApi.getPsyuserSubscriptions(this.psyuser.id).subscribe(res => {                

                if(res.statusOk){
                  this.psyuserSubscriptions = res.data;
                  this.setCustomerAvailableSubscriptions();
                  this.setPsyUserActiveOrInactiveSubscriptions(); // Show active subscription by default
                  this.setPsyUserSubscriptionsHistory();
                  this.loaded = true;
                }
              }, err => {
                this.toastSrv.showToastError(err);
              });
            });    
            
          }, err => {
            this.toastSrv.showToastError(err);
          });                    

          // Get available and consumed uses/credits
          this.planSrv.getCustomerBalance(this.customerId).then(res => {
            this.customerConsumesInfo = res;
          }, err => {
            this.toastSrv.showToastError(err);
          });

          // Get Customer Name
          this.customerName = this.authService.getCurrentCustomerName();
        }
      }      
    );    
  }

  // Set customer's subscriptions avoiding psyuser's subscriptions
  setCustomerAvailableSubscriptions() {
    this.customerSubscriptions = this.customerSubscriptions.filter(_customerSub => !this.psyuserSubscriptions.map(_psyUserSub => _psyUserSub.sub_id).includes(_customerSub.sub_id));

    // Order by subscription_id            
    this.customerSubscriptions.sort((a, b) => { return a.sub_id - b.sub_id});       
  }

  // Set psyuser subscriptions based in filter data
  setPsyUserActiveOrInactiveSubscriptions() {
    this.historySrv.sharedStatusHistory.subscribe(_status => {
      this.psyuserSubscriptionsToShow = [];
      if(_status === true){
        this.psyuserSubscriptionsToShow = this.psyuserSubscriptions.filter(_psyUserSub => _psyUserSub.isAlive === false);
      } else {
        this.psyuserSubscriptionsToShow = this.psyuserSubscriptions.filter(_psyUserSub => _psyUserSub.isAlive === true);
      }
    })

    // Order by subscription_id            
    this.psyuserSubscriptionsToShow.sort((a, b) => { return a.sub_id - b.sub_id});  
  }

  // Set psyuser history based in alive subscriptions
  setPsyUserSubscriptionsHistory(){
    this.adminApi.getPsyuserHistorySubscriptions(this.psyuser.id).subscribe(res => {
      if(res.statusOk){
        this.psyuserSubscriptionsHistory = res.data;
      }
    }, err => {
      this.toastSrv.showToastError(err);
    }).add(()=> {
      this.showHistory = true;
    })    
  }

  /**
   * Confirmation to delete psyuser subscription (disable customerHasPlanHasPsyUser reference)
   */
  confirmDeletePsyuser(): void {
    const user = this.psyuser;
    this.toastSrv.showToastConfirm(this.translate.instant('ACCOUNTS.CONFIRM-DELETE'), 'warning');

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.adminApi.deletePsyUser(user.id).subscribe(
          res => {
            this.adminSrv.deletePsyuser(user.id);
            this.toastSrv.showToastSucess(this.translate.instant('ACCOUNTS.DELETE-OK'));
            this.confirmSubscription.unsubscribe();
            this.router.navigate(['admin']);
          },
          error => {
            this.toastSrv.showToastError(this.translate.instant('ACCOUNTS.DELETE-ERROR'));
        });
      }
      if (res === false) {
        this.confirmSubscription.unsubscribe();
      }
    });
  }
  
  /**
   * Disable customerHasPlanHasPsyUser reference
   * @param customerHasPlanHasPsyusers_id 
   */
  deassignPsyuser(customerHasPlanHasPsyusers_id): any {

    this.toastSrv.showToastConfirm(this.translate.instant('ACCOUNTS.REMOVE-ASK-CONFIRMATION'), 'warning');

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true) {        
        this.adminApi.deassignPsyuserFromPlan(customerHasPlanHasPsyusers_id).subscribe(
          res => {
            this.confirmSubscription.unsubscribe();
            this.toastSrv.showToastSucess(this.translate.instant('ACCOUNTS.REMOVE-OK-CONFIRMATION'));
            this.initData();
          },
          error => {
            this.toastSrv.showToastError(this.translate.instant('ACCOUNTS.ERROR-REMOVING'));
        });
      }
      if (res === false) {
        this.confirmSubscription.unsubscribe();
      }      
    });    
  }

  /**
   * Create new relation between psyuser and customerHasPlan
   * @param customerHasPlan_id 
   */
  assignPsyuser(customerHasPlan_id){    
    this.toastSrv.showToastConfirm(this.translate.instant('ACCOUNTS.ADD-ASK-CONFIRMATION'), 'warning');
    this.showAvailablePlans = false;

    this.confirmSubscription = this.toastConfirmSrv.sharedConfirmActionValue.subscribe(res => {
      if (res === true){
        this.adminApi.assignPsyuserToPlan(customerHasPlan_id,this.psyuser.id).subscribe(
          _res => {
            this.confirmSubscription.unsubscribe();
            this.toastSrv.showToastSucess(this.translate.instant('ACCOUNTS.ADD-OK-CONFIRMATION'));
            this.initData();
          },
          error => {
            this.toastSrv.showToastError(this.translate.instant('ACCOUNTS.ERROR-ADDING'));
        });
      }
      if (res === false) {
        this.confirmSubscription.unsubscribe();
      }
    });
  }  

  /**
   * Set values of credit pop-up before display
   * @param plan 
   */
  showCreditPopUp(plan) {
    let customerConsumes = this.customerConsumesInfo['consumptionsByPlan'].find(_plan => _plan.id == plan.sub_id)
    this.customerCredits = customerConsumes.generalCreditsInfo.totalAvailableToAssign;
    this.customerCreditsAux = this.customerCredits;

    let psyUserConsumes = customerConsumes.creditsConsumptionByPsyuser.find(_psyuser => _psyuser.id == this.psyuser.id);    
    this.psyUserCredits = psyUserConsumes ? psyUserConsumes.total : 0;
    this.psyUserCreditsAux = this.psyUserCredits;
    
    this.tempPlan = plan;
    this.inputValue = 0;
    this.showLegacyPopUp = true;
  }

  /**
   * Modify values of credits
   * @param sum 
   */
  changeValue(sum: boolean){
    if(sum)
      this.inputValue++;
    else
      this.inputValue--;
    this.inputChanged();
  }

  /**
   * Event to modify value of credits
   */
  inputChanged(): void{
    this.customerCredits = this.customerCreditsAux - this.inputValue;
    this.psyUserCredits = this.psyUserCreditsAux + this.inputValue;
    this.buttonDisabled = this.inputValue == 0 || this.psyUserCredits < 0 || this.customerCredits < 0;
  }

  /**
   * Save credits changes   
   */
  saveCredits(): void{
    if (this.inputValue == 0){
      return;
    }
    const currentUser = this.authService.getCurrentUser()

    this.adminApi.legacyControl(this.inputValue, currentUser.id, this.psyuser.id, this.customerId ,this.tempPlan).subscribe(
      res => {
        this.inputValue = 0;
        this.inputChanged();
        this.showLegacyPopUp = false;
        this.toastSrv.showToastSucess(this.translate.instant('ACCOUNTS.OK-LEGACY'));
        this.initData();
        this.authService.setChangePsyUserCredits();
      },
      error => {
        this.toastSrv.showToastError(this.translate.instant('ACCOUNTS.ERROR-LEGACY'));
      }
    );
  }    

  showPlan(plan): void {

    this.planToShow = plan;

    this.planToShow.psyuserId = this.psyuser.id;

    this.showPlanInfo = true;
  }
}