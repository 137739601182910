import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { SharedModule } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PasswordModule } from 'primeng/password';
import { ChartModule } from 'primeng/chart';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { BlockUIModule } from 'primeng/blockui';
import { BadgeModule } from 'primeng/badge';
import { AutoFocusModule } from 'primeng/autofocus';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';


const components = [
  CommonModule,
  ButtonModule,
  InputTextModule,
  MenubarModule,
  DropdownModule,
  MenuModule,
  SharedModule,
  ToastModule,
  TabViewModule,
  AvatarModule,
  AvatarGroupModule,
  TooltipModule,
  PaginatorModule,
  InputMaskModule,
  InputNumberModule,
  MultiSelectModule,
  CalendarModule,
  ConfirmDialogModule,
  SidebarModule,
  DialogModule,
  InputTextareaModule,
  InputSwitchModule,
  SliderModule,
  RadioButtonModule,
  PasswordModule,
  ChartModule,
  ProgressSpinnerModule,
  AccordionModule,
  CheckboxModule,
  FileUploadModule,
  BlockUIModule,
  BadgeModule,
  AutoFocusModule,
  FieldsetModule,
  SelectButtonModule
];

/**
 * Module to import al components related with PrimeNG
 */
@NgModule({
  declarations: [],
  imports: [
    components
  ],
  exports: [
    components
  ]
})
export class PrimeNgModule { }
