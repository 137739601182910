<!--FILTER-->
<app-filter
    #filter
    *ngIf="!embedded"
    [items]="[]"
    [searchKeys]="['code']"
    [filterByDropdowns]="['search','entryType_id','status_id']"
    [filterByDateRange]="true"
    [onlyEmitSelectedMode]="true"
    (emitSelectedFilters)="applyDataTableFilter($event)"
    (resetFilter)="clearDataTableFilter()">
</app-filter>

<!--DATATABLE-->
<p-table
    #dataTable
    class="rounded-table"
    responsiveLayout="scroll"

    [value]="data"
    [lazy]="true"
    (onLazyLoad)="getDataLazy($event)"
    [totalRecords]="dataCount"

    [loading]="!loaded"
    loadingIcon="pi pi-spin pi-spinner"

    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[5,10,20,50]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{'TABLE.ROW-COUNT' | translate}}"

    dataKey="id"
    [(selection)]="selectedData"
    (selectionChange)="onSelect()"
    (onPage)="selectedData=[]; onSelect()">

    <!-- HEADER -->
    <ng-template pTemplate="header">
    <tr class="primary-row">
        <th style="width: 1rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="code">
            <div>
            {{'PROJECTS.HEADER-NAME' | translate}}
            <p-sortIcon field="code"></p-sortIcon>
            </div>
        </th>
        <th pSortableColumn="createdAt">
            <div>
                <i class="pi pi-calendar p-mr-3"></i>
                {{'PROJECTS.HEADER-CREATION' | translate}}
                <p-sortIcon field="createdAt"></p-sortIcon>
            </div>
        </th>
        <th>
            <div>
                <i class="pi pi-user p-mr-3"></i>
                {{'PROJECTS.HEADER-USERS' | translate}}
            </div>
        </th>
        <th>
            <div>
                {{'PROJECTS.HEADER-STATUS' | translate}}
            </div>
        </th>
        <th style="width: 13rem"></th>
    </tr>
    </ng-template>

    <!-- BODY -->
    <ng-template pTemplate="body" let-item>
    <tr class="primary-row pointer" [ngClass]="{'selected-row': isSelected(item)}" (click)="goToDetails(item, $event)" @fadeInAnimation>
        <td class="non-clickable">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </td>
        <td class="row-name p-d-flex p-ai-center">
            <p-avatar
                class="row-avatar p-col-12 p-lg-1 p-md-2 p-sm-2"
                icon="pi pi-folder"
                [ngClass]="{'circle-selected': isSelected(item)}"
                size="xlarge">
            </p-avatar>
            <div class="p-ml-2">
                <!-- Project Name -->
                {{item.code}}
                <div class="p-d-flex p-ai-center p-mt-1">
                    <!-- Administration Type -->
                    <span class="detail-data type-label p-mr-2">{{ 'CREATE-PROJECT.DD-'+(item.supply | uppercase) | translate}}</span>
                    <!-- Project Migrated -->
                    <span pTooltip="{{'PROJECTS.MIGRATED' | translate}}" placeholder="Top"><i *ngIf="item.isMigrated" class="pi pi-globe"></i></span>
                </div>
            </div>
        </td>
        <td class="row-created">
            {{'PROJECTS.ADDED' | translate}} {{item.createdAt | date: 'dd/MM/yyyy'}}
        </td>
        <td>
            <app-users-groups-circles [listItems]="item.testtakers" [type]="projectType"></app-users-groups-circles>
        </td>
        <td>
            <custom-label [labelType]="item.statusString"></custom-label>
        </td>
        <td class="p-text-right ellipsis-button">
            <app-project-actions [project]="item"></app-project-actions>
        </td>
    </tr>
    </ng-template>

    <!--EMPTY MESSAGE-->
    <ng-template pTemplate="emptymessage">
    <tr>
    <td colspan="7">
        <h3 class="p-text-center">{{'FILTER.NO-RESULTS' | translate}}</h3>
    </td>
    </tr>
    </ng-template>
</p-table>

<!--FLOAT BUTTON-->
<button
    type="button"
    *ngIf="!embedded"
    class="btn-float p-shadow-3"
    pButton
    icon="pi pi-plus"
    iconPos="right"
    label="{{ 'PROJECTS.BTN-CREATE'|translate }}"
    (click)="goToCreateNew()">
</button>

<!--ACTION SIDEBAR-->
<p-sidebar
    *ngIf="!embedded"
    [(visible)]="showBottomMenu"
    position="bottom"
    [baseZIndex]="10000"
    class="sidebar-action"
    [dismissible]="false"
    [modal]="false"
    (onHide)="deselectAllItems()"
    (onShow)="enableDisableBackgroundSidebar(true)">
    <div>
        <button
            type="button"
            class="btn-secondary p-mx-3"
            pButton
            label="{{'ACTION-SIDEBAR.DELETE' | translate}}"
            (click)="confirmDelete()">
        </button>
    </div>
</p-sidebar>

<!-- POP-UP: Get another report popup-->
<p-dialog [(visible)]="displayReportPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report">
    <div class="dialog-content">
        <app-report-selector-template
            #reportSelectorTemplate
            [projectData]="tempProject"
            [testAndReportList]="tempProject.projectTestReportList"
            [testtakerList]="projectTesttakerList"
            (reportPurchased)="displayReportPopup = false"
            *ngIf="tempProject?.projectTestReportList?.length > 0">
        </app-report-selector-template>
    </div>
</p-dialog>

<!-- POP-UP: Scoring with Psyuser questions popup-->
<p-dialog [(visible)]="displayScoringPopup" [style]="{width: '80vw'}" [modal]="true" class="template-dialog-scoring">
    <ng-template pTemplate="header">
        <h3 class="p-ai-center p-text-center scoring-title">{{'PROJECTS.API-SCORING-POPUP-TITLE' | translate}}</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <app-scoring-psyquestions-template
            #scoringPopupTemplate
            [psyUserQuestionsForTest]="tempProjectTestSharedQuestions"
            [relatedTestQuestionnaires]="tempRelatedTestQuestionnaires"
            (scoringSuccessfull)="projectActionsSrv.updateScoringStatusOfProjectAndCloseScoringDialog(tempProject)"
            (scoringFailed)="projectActionsSrv.updateScoringStatusOfProjectAndCloseScoringDialog(tempProject)">
        </app-scoring-psyquestions-template>
    </ng-template>

    <ng-template pTemplate="footer" class= "p-text-right p-my-3">
        <button pButton
            (click)="scoringPopupRefPopup.saveQuestionnairesAndScore()"
            label="{{'PROJECTS.API-SCORING-POPUP-BUTTON' | translate}}"
            class="btn-primary"
            [disabled]="scoringPopupRefPopup?.scoreButtonDisabled">
        </button>
    </ng-template>
</p-dialog>

 <!-- POP-UP: Scoring no valid popup-->
 <p-dialog [(visible)]="displayNonValidScoringPopup" [style]="{width: '60vw'}" [baseZIndex]="10000" [modal]="true" class="template-dialog-report">
    <div class="dialog-content">
            <app-non-valid-scoring-template
                #scoredNonValidPopupTemplate
                [itemTests]="tempProject?.questionnaires">
            </app-non-valid-scoring-template>
    </div>
    <ng-template pTemplate="footer">
        <span class= "p-d-flex p-jc-between">
            <button
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ALL' | translate}}"
                (click)="projectActionsSrv.hideScoringNonValidPopUpAndShowReportPopUp()"
                class="btn-primary">
            </button>
            <button *ngIf="tempProject?.questionnaires.length > 1"
                pButton
                label="{{'REPORTS.BTN-PURCHASE-ONLY-VALID' | translate}}"
                (click)="projectActionsSrv.getOnlyValidReportsFromScoringPopUp(tempProject)"
                class="btn-primary">
            </button>
            <button
                pButton
                label="{{'REPORTS.BTN-CANCEL' | translate}}"
                (click)="displayNonValidScoringPopup = false"
                class="btn-primary">
            </button>
        </span>
    </ng-template>
</p-dialog>

<!-- POP-UP SELECT USERS TO SEND MAIL -->
<p-dialog [(visible)]="displaySelectUsers" [style]="{width: '70vw'}" [modal]="true" class="template-dialog">
    <ng-template pTemplate="header">
        <h3>{{'PROJECTS.SELECT_USERS_TO_SEND_EMAIL'|translate}}</h3>
    </ng-template>
    <div class="template-dialog">
        <div class="listUsers">
            <app-template-user-simple
                *ngFor="let item of unfinishedTestUsers"
                [item]="item"
                [optionsLeft]="'checkbox'"
                [optionsRight]="'none'"
                [from]="'detail'">
                {{item}}
            </app-template-user-simple>
        </div>
        <!-- TODO: El paginador no está funcionando -->
         <!-- <div class="paginator">
            <p-paginator
                #paginator2
                [rows]="rowToDisplayDefaultUsers"
                [totalRecords]="unfinishedTestUsers.length"
                [rowsPerPageOptions]="[5,10,20,50]"
                [showCurrentPageReport]="true"
                currentPageReportTemplate = "{{ ('CATALOG.ROW-COUNT-1' | translate) + topRowUsers + ('CATALOG.ROW-COUNT-2' | translate) }}"
                (onPageChange)="paginateUsers($event)">
            </p-paginator>
        </div> -->
    </div>
    <ng-template pTemplate="footer">
        <button
            pButton
            (click)="displaySelectUsers=false"
            label="{{'EDIT-GROUP.BTN-CANCEL' | translate}}"
            class="btn-secondary">
        </button>
        <button
            pButton
            (click)="displayCustomEmail=true; displaySelectUsers=false;"
            label="{{'CREATE-PROJECT.BTN-SEND' | translate}}"
            class="btn-primary">
        </button>
    </ng-template>
</p-dialog>

<!-- POP-UP SEND MAILS TEMPLATE-->
<p-dialog [(visible)]="displayCustomEmail" [modal]="true"[style]="{height: '480px'}" class="email-template">
    <div *ngIf="displayCustomEmail">
        <h3>Email: </h3>
        <app-custom-email (htmlChanged)="_emailContent=$event" (loaded)="loadedCustomMail=$event"></app-custom-email>
        <div class="center top p-mt-3">
            <button *ngIf="loadedCustomMail"
                pButton
                (click)="confirmResend(); displayCustomEmail = false"
                label="{{'CREATE-PROJECT.BTN-SEND' | translate}}"
                class="btn-primary">
            </button>
        </div>
    </div>
</p-dialog>

<!--BLOCKER: FOR CONFIRM DIALOGS-->
<p-blockUI [blocked]="showBlockUi"></p-blockUI>

<!--BLOCKER-LOADING: SHOW SPINNER WAITING API DATA-->
<p-blockUI [blocked]="showLoadingSpinner" class="p-d-flex p-ai-center p-jc-center">
    <p-progressSpinner strokeWidth="4" [style]="{width: '8em', height: '8em'}"></p-progressSpinner>
</p-blockUI>
