<form [formGroup]="editForm" *ngIf="loaded">    

    <h3>{{'PROFILE.EDIT-PROFILE'|translate}}</h3>

    <div class="p-fluid p-formgrid p-grid">   
        <div class="p-field p-col-12 p-lg-4 p-md-12">
            <span class="p-float-label">
                <input id="name" type="text" pInputText class="custom-input-text" formControlName="name" #name>
                <label for="name">*{{'EDIT-PEOPLE.NAME' | translate}}</label>
                <span 
                    *ngIf="name.invalid && name.touched" 
                    [@inOutAnimation] 
                    class="error-tooltip">
                    {{name['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-8 p-md-12">
            <span class="p-float-label">
                <input id="surname" type="text" pInputText class="custom-input-text" formControlName="surname">
                <label for="surname">*{{'EDIT-PEOPLE.SURNAME' | translate}}</label>
                <span 
                    *ngIf="surname.invalid && surname.touched" 
                    [@inOutAnimation] 
                    class="error-tooltip">
                    {{surname['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-3 p-md-6">
            <span class="p-float-label" *ngIf="formData?.genders">
                <p-dropdown 
                    [options]="formData.genders" 
                    formControlName="gender"
                    class="custom-dropdown"
                    filterBy="name"
                    optionLabel="name" 
                    placeholder="&nbsp;" 
                    [showClear]="true" >
                </p-dropdown>
                <label for="gender"><span class="red_dot">*</span>{{'EDIT-PEOPLE.GENDER' | translate}}</label>
                <span 
                    *ngIf="gender.invalid && gender.touched" 
                    [@inOutAnimation] 
                    class="error-tooltip">
                    {{gender['errorMessage']}}
                </span>
            </span>
        </div>               
        <div class="p-field p-col-12 p-lg-9 p-md-12">
            <span class="p-float-label">
                <input id="publicEmail" type="text" pInputText class="custom-input-text" formControlName="publicEmail">
                <label for="publicEmail">{{'DETAIL-PEOPLE.PUBLIC-EMAIL' | translate}}</label>
                <span 
                    *ngIf="publicEmail.invalid && publicEmail.touched" 
                    [@inOutAnimation] 
                    class="error-tooltip">
                    {{publicEmail['errorMessage']}}
                </span>
            </span>
        </div>
    </div>
    
    <div class="form-footer p-d-flex p-jc-between p-ai-center">
        <span><span class="red_dot">*</span>{{'EDIT-PEOPLE.MANDATORY' | translate}}</span>

        <button 
            type="submit" 
            class="btn-primary" 
            pButton 
            label="{{'EDIT-GROUP.BTN-UPDATE' | translate}}"
            (click)="saveChanges()"
            [disabled]="editForm.invalid">
        </button>
    </div>
</form>
