import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-template',
  templateUrl: './empty-template.component.html',
  styleUrls: ['./empty-template.component.scss']
})
export class EmptyTemplateComponent implements OnInit {

  @Input() text: any;
  constructor() { }

  ngOnInit(): void {
  }

}
