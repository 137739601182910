import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {

  @Input() plan;
  @Input() showAssign = false;
  @Input() actions = false;

  @Output() showPlanEvent = new EventEmitter<any>();
  @Output() showEditCreditEvent = new EventEmitter<any>();
  @Output() deleteSubscriptionEvent = new EventEmitter<any>();
  @Output() addSubscriptionEvent = new EventEmitter<any>();

  planName: string;

  constructor(private planSrv: PlanService) { }

  ngOnInit(): void {
    // Set format name format    
    this.planName = this.plan.demo ? `${this.plan.plan_name} [DEMO]`: this.plan.plan_name;

    this.showAssign =  this.showAssign && !this.plan.reachedMaxUsers;

  }

  showPlan(plan){
    this.showPlanEvent.emit(plan);
  }

  showEditCredit(){
    this.showEditCreditEvent.emit(true);
  }

  deleteSubscription(){
    this.deleteSubscriptionEvent.emit(true);
  }

  addSubscription(){    
    this.addSubscriptionEvent.emit(true);
  }

  moreThan100(startDate, finisDate){
    return this.planSrv.moreThan100(startDate, finisDate);
  }
}
