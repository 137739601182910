import { animate, query, stagger, style, transition, trigger } from "@angular/animations";


export const listAnimation = trigger('listAnimation', [
    transition('* => *', [
      // query( // TO-DO: Revisar la salida del elemento con el cambio de pantalla
      //   ':leave',
      //   [stagger(250, [animate('0.5s', style({ opacity: 0 }))])],
      //   { optional: true }),
      query(
        ':enter',
        [
          style({ opacity: 0 }),
          stagger(100, [animate('0.1s', style({ opacity: 1 }))])
        ],
        { optional: true }
      )
    ])
]);

export const listAnimationV2 = trigger('listAnimationV2', [
  transition('* => *', [
    query(
      ":leave",
      animate(
        "700ms ease-in",
        style({ opacity: 0, display: "none", transform: "translateX(300px)" })
      ),
      { optional: true }
    )
    ,
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(100, [animate('0.1s', style({ opacity: 1 }))])
      ],
      { optional: true }
    )
  ])
]);