export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  apiUrl: 'https://psy-portal-dev.giuntipsy.com/back/',
  apiLoginUrl: 'https://dante-dev.giuntipsy.com/login/',
  apiBackOfficeURL: 'https://management-dev.giuntipsy.com/back/',
  apiAssesmentURL: 'https://assessment-dev.giuntipsy.com/back/',
  apiGateURL: 'https://dante-dev.giuntipsy.com',
  webAssesmentURL: 'https://assessment-dev.giuntipsy.com/home/',
  planUrl:'https://dante-dev.giuntipsy.com/plans/',
  clientId: 'cI2VoIyiaT',
  clientName: 'PSYPORTAL',
  integrationClientId: 'DANTEINTEGRATION',
  key: '6LeWnd4aAAAAAJKEI34_sGRNsOa6xwf00K0dnwZB',
  frontApplication: 'PsyPortal',

  googleAnalyticsTag: 'G-8FR8GRLLHJ',
  //If true, only the page_view of pages defined in ganalyticsService will be sent to google analytics
  filterUrlsGAnalytics: false,

  cookieBotId: '81bd5196-f17b-497f-866f-c0bc8cec0e63',
  quickGuideURL: 'https://dante-fronts.s3.eu-west-1.amazonaws.com/psy-portal/resources/Guida_rapida_GT_2023.pdf',

  // Set the Max number of allowed testtakers for groups and projects
  MAX_TESTTAKERS: 1000
};
