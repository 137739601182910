<div class="container p-d-flex p-flex-column p-jc-between" *ngIf="item !== null">
    <div>
        <div class="detail-subtitle">{{'DETAIL-PEOPLE.INFO' | translate}}</div>
        <div *ngIf="item.gender"  class="detail-label">{{'DETAIL-PEOPLE.GENDER' | translate}}: <span class="detail-data">{{ 'SELECT-GENDER.' + (item.gender | uppercase) | translate}}</span></div>
        <div class="detail-label">{{'DETAIL-PEOPLE.NAME' | translate}}: <span class="detail-data">{{item.name}}</span></div>
        <div class="detail-label">{{'EDIT-PEOPLE.SURNAME' | translate}}: <span class="detail-data">{{item.surname}}</span></div>
        <div class="detail-label">{{'DETAIL-PEOPLE.EMAIL' | translate}}: <span class="detail-data">{{item.email}}</span></div>
        <div *ngIf="item.publicEmail" class="detail-label">{{'DETAIL-PEOPLE.PUBLIC-EMAIL' | translate}}: <span class="detail-data">{{item.publicEmail}}</span></div>
        <div *ngIf="item.customer" class="detail-label">{{'DETAIL-PEOPLE.COMPANY' | translate }} <span class="detail-data">{{item.customer.name}}</span></div>
        <span class="detail-label" [ngSwitch]="item.isAdmin">
            {{'DETAIL-PEOPLE.PROFILE' | translate }}            
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER">
                {{'DETAIL-PEOPLE.PROFILE-USER' | translate }}
                <div class="detail-help p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-USER-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER_ADMIN">
                {{'DETAIL-PEOPLE.PROFILE-ADMIN' | translate }}
                <div class="detail-help  p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.PSYUSER_FULL">
                {{'DETAIL-PEOPLE.PROFILE-ADMIN-FULL' | translate }}
                <div class="detail-help  p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-FULL-HELP' | translate"></div>
            </span>
            <span class="detail-data" *ngSwitchCase="psyUserRoles.ASSISTANT">
                {{'DETAIL-PEOPLE.PROFILE-ASSISTANT' | translate }}
                <div class="detail-help  p-mt-2" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ASSISTANT-HELP' | translate"></div>
            </span>
        </span>
        
    </div>
    
    <div class="p-d-flex p-jc-between p-ai-end">
        <button 
            type="button" 
            class="btn-primary"
            pButton
            [routerLink]="['edit']"
            label="{{'DETAIL-PEOPLE.BTN-MODIFICATION' | translate }}">
        </button>
        <button 
            *ngIf="item.isAdmin != psyUserRoles.PSYUSER"
            type="button" 
            class="btn-secondary"
            pButton
            (click)="goToAdminAccounts()"
            label="{{'DETAIL-PEOPLE.BTN-ACCOUNTS' | translate }}">
        </button>
    </div>        
</div>

