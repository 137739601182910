
<form [formGroup]="editForm" *ngIf="loaded">
    <h2>{{type === 'EDIT' ? ('ACCOUNTS.EDIT-ACCOUNT' | translate) : ('ACCOUNTS.CREATE-ACCOUNT' | translate)}}</h2>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="name" type="text" pInputText class="custom-input-text" formControlName="name">
                <label for="name"><span class="red_dot">*</span>{{'EDIT-PEOPLE.NAME' | translate}}</label>
                <span
                    *ngIf="name.invalid && name.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{name['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="surname" type="text" pInputText class="custom-input-text" formControlName="surname">
                <label for="surname"><span class="red_dot">*</span>{{'EDIT-PEOPLE.SURNAME' | translate}}</label>
                <span
                    *ngIf="surname.invalid && surname.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{surname['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="email" type="text" pInputText class="custom-input-text" formControlName="email">
                <label for="email"><span class="red_dot">*</span>{{'EDIT-PEOPLE.EMAIL' | translate}}</label>
                <span
                    *ngIf="email.invalid && email.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{email['errorMessage']}}
                </span>
            </span>
        </div>

        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="phone" type="tel" pInputText class="custom-input-text" formControlName="phone">
                <label for="phone">{{('ACCOUNTS.PHONE' | translate)}}</label>
                <span
                    *ngIf="phone.invalid && phone.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{phone['errorMessage']}}
                </span>
            </span>
        </div>

        <div class="p-field p-col-12 p-lg-6 p-md-12"  *ngIf="false">
            <div class="p-d-flex p-ai-center active-inactive-switch" >
                <label for="transferAssignationGroupPsyUser">{{('ACCOUNTS.TRANSFER-PARENT-ASSIGNATION-GROUPS' | translate)}}</label>
                <p-inputSwitch class="p-ml-3 p-mt-1" formControlName="transferAssignationGroupPsyUser" (onChange)="updateTest()"></p-inputSwitch>
            </div>
        </div>

        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <p-dropdown
                    [options]="psyUserRolesOpts"
                    formControlName="isAdmin"
                    class="custom-dropdown"
                    optionValue="key"
                    optionLabel="label"
                    placeholder="&nbsp;">
                </p-dropdown>
                <label><span class="red_dot">*</span>{{'ACCOUNTS.PROFILE' | translate}}</label>
                <span
                    *ngIf="email.invalid && email.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{email['errorMessage']}}
                </span>
            </span>
        </div>

        <div class="p-field p-col-12 p-lg-6 p-md-12" *ngIf="isAdmin.touched" [ngSwitch]="isAdmin.value">
            <span *ngSwitchCase="psyUserRoles.PSYUSER">
                <div class="detail-help" [innerHTML]="'DETAIL-PEOPLE.PROFILE-USER-HELP' | translate"></div>
            </span>
            <span *ngSwitchCase="psyUserRoles.PSYUSER_ADMIN">
                <div class="detail-help" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-HELP' | translate"></div>
            </span>
            <span *ngSwitchCase="psyUserRoles.PSYUSER_FULL">
                <div class="detail-help" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ADMIN-FULL-HELP' | translate"></div>
            </span>
            <span *ngSwitchCase="psyUserRoles.ASSISTANT">
                <div class="detail-help" [innerHTML]="'DETAIL-PEOPLE.PROFILE-ASSISTANT-HELP' | translate"></div>
            </span>
        </div>

        <div *ngIf="isAdmin.value != psyUserRoles.ASSISTANT" class="p-col-12 p-lg-12 p-md-12">
            <div class="p-col-12 p-lg-12 p-md-12">
                <hr>
            </div>

            <div class="p-field p-col-12 p-lg-6 p-md-12">
                <span class="p-float-label" *ngIf="type == 'EDIT' || !editForm.value.transferAssignationGroupPsyUser">
                    <p-multiSelect
                        [options]="assignationsGroups"
                        formControlName="assignationsGroup"
                        class="custom-dropdown-search"
                        filterBy="id"
                        optionValue="id"
                        optionLabel="name"
                        placeholder="&nbsp;">
                    </p-multiSelect>
                    <label>{{'ACCOUNTS.ASSIGNATION-GROUPS' | translate}}</label>
                </span>
            </div>

            <!-- <div class="p-field p-col-12 p-lg-12 p-md-12">

            <p-table #ag1 [value]="dataAG" styleClass="p-datatable-sm"
                [paginator]="true" currentPageReportTemplate="{{('ACCOUNTS.ROW-COUNT-11' | translate) +  ('ACCOUNTS.ROW-COUNT-2' | translate)}}"
                [rows]="5" paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10, 20,50,100]" [showCurrentPageReport]="true">
                    <ng-template pTemplate="header">
                        <tr class="tableHeader">
                            <th  pSortableColumn="testKey" style="width:200px;" >
                                <div *ngIf="!loadingTests">{{'ACCOUNTS.TEST-KEY' | translate}}  <p-sortIcon field="testKey"></p-sortIcon><p-columnFilter type="text" display="menu" field="testKey"></p-columnFilter></div>
                                <p-progressSpinner *ngIf="loadingTests" strokeWidth="4" [style]="{width: '40px', height: '40px'}"></p-progressSpinner>
                            </th>
                            <th  pSortableColumn="title"  >
                                <div>{{'ACCOUNTS.TITLE' | translate}}  <p-sortIcon field="title"></p-sortIcon><p-columnFilter type="text" display="menu" field="title"></p-columnFilter></div>
                            </th>
                            <th  pSortableColumn="assignationGroup"  style="width:200px;"  >
                                <div>{{'ACCOUNTS.QUALIFICATION' | translate}}  <p-sortIcon field="assignationGroup"></p-sortIcon><p-columnFilter type="text" display="menu" field="assignationGroup"></p-columnFilter></div>

                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-plan>
                        <tr class="tableBody">
                            <td style="width:200px;max-width:200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                              <span pTooltip="{{plan.testKey}}">{{plan.testKey}}</span>
                            </td>
                            <td style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                <span pTooltip="{{plan.title}}"> {{plan.title}}</span>
                            </td>
                            <td style="width:200px;max-width:200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                <span pTooltip="{{plan.assignationGroup}}"   tooltipPosition="left">{{plan.assignationGroup}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>    -->
        </div>
    </div>

    <div class="form-footer p-d-flex p-jc-between p-ai-center">
        <span><span class="red_dot">*</span>{{'EDIT-PEOPLE.MANDATORY' | translate}}</span>
        <!-- label="{{ type ==='EDIT' ? ('EDIT-PEOPLE.BTN-EDIT' | translate) : ('ACCOUNTS.CREATE-ACCOUNT-MAY' | translate)}}" -->
        <div>
            <button
                *ngIf="type == 'EDIT'"
                type="submit"
                class="btn-primary"
                pButton
                label="{{'GENERAL.CANCEL' | translate}}"
                (click)="cancel()">
            </button>
            <button
                type="submit"
                class="btn-primary p-ml-2"
                pButton
                label="{{'EDIT-PEOPLE.BTN-EDIT' | translate}}"
                (click)="saveChanges()"
                [disabled]="editForm.invalid">
            </button>
        </div>
    </div>
</form>
