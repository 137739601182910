<form [formGroup]="editForm" *ngIf="loaded">
    <div class="p-d-flex p-jc-between p-ai-center">
        <h2>{{type === 'EDIT' ? ('EDIT-PEOPLE.TITLE-EDIT' | translate) : ('EDIT-PEOPLE.TITLE' | translate)}}</h2>
        <button
            class="btn-primary import_csv"
            pButton
            *ngIf = "type !== 'EDIT'"
            (click)="showImportUsersModal = true ">
            {{'EDIT-PEOPLE.IMPORT-USERS' | translate}}
        </button>
    </div>

    <h3>{{'EDIT-PEOPLE.INFO-1' | translate}}</h3>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="friendlyId" pInputText class="custom-input-text" mask="99" formControlName="friendlyId" #inputFriendlyId>
                <label for="id"><span class="red_dot">*</span>{{'EDIT-PEOPLE.USER-ID' | translate}}</label>
                <span
                    *ngIf="friendlyId.invalid && friendlyId.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{friendlyId['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-3 p-md-6">
            <span class="p-float-label" *ngIf="formData?.genders">
                <p-dropdown
                    [options]="formData.genders"
                    formControlName="gender"
                    class="custom-dropdown"
                    filterBy="name"
                    optionLabel="name"
                    placeholder="&nbsp;"
                    [showClear]="true" >
                </p-dropdown>
                <label for="email"><span class="red_dot">*</span>{{'EDIT-PEOPLE.GENDER' | translate}}</label>
                <span
                    *ngIf="gender.invalid && gender.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{gender['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-3 p-md-12">
            <span class="p-float-label">
                <p-calendar
                    id="birthdate"
                    formControlName="birthdate"
                    class="custom-calendar"
                    [dateFormat]="language === 'bg' ? 'dd.mm.yy' : 'dd/mm/yy'"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    [maxDate]="maxDate"
                    firstDayOfWeek="1"
                    yearRange="1960:2030"
                    showButtonBar="true">
                </p-calendar>
                <label for="birthdate">{{'EDIT-PEOPLE.BIRTH-DATE' | translate}}</label>
                <span
                    *ngIf="birthdate.invalid && birthdate.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{birthdate['errorMessage']}}
                </span>
            </span>
         </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="name" type="text" pInputText class="custom-input-text" formControlName="name">
                <label for="name">{{'EDIT-PEOPLE.NAME' | translate}}</label>
                <span
                    *ngIf="name.invalid && name.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{name['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="surname" type="text" pInputText class="custom-input-text" formControlName="surname">
                <label for="surname">{{'EDIT-PEOPLE.SURNAME' | translate}}</label>
                <span
                    *ngIf="surname.invalid && surname.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{surname['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label" *ngIf="formData?.groups">
                <p-multiSelect
                    [options]="formData.groups"
                    formControlName="testTakerGroups"
                    class="custom-dropdown-search"
                    filterBy="id"
                    optionLabel="name"
                    placeholder="&nbsp;">
                </p-multiSelect>
                <label>{{'EDIT-PEOPLE.GROUPS' | translate}}</label>
                <span
                    *ngIf="testTakerGroups.invalid && testTakerGroups.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{testTakerGroups['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="email" type="text" pInputText class="custom-input-text" formControlName="email">
                <label for="email">{{'EDIT-PEOPLE.EMAIL' | translate}}</label>
                <span
                    *ngIf="email.invalid && email.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{email['errorMessage']}}
                </span>
            </span>
        </div>
    </div>

    <h3>{{'EDIT-PEOPLE.INFO-2' | translate}}</h3>
    <hr>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label" *ngIf="formData?.countries">
                <p-dropdown
                    [options]="formData.countries"
                    formControlName="country_id"
                    class="custom-dropdown"
                    optionLabel="name"
                    filterBy="name"
                    placeholder="&nbsp;"
                    [filter]="true"
                    [showClear]="true" >
                </p-dropdown>
                <label>{{'EDIT-PEOPLE.COUNTRY' | translate}}</label>
                <span
                    *ngIf="country_id.invalid && country_id.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{country_id['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="nationality" type="text" pInputText class="custom-input-text" formControlName="nationality">
                <label for="nationality">{{'EDIT-PEOPLE.NACIONALITY' | translate}}</label>
                <span
                    *ngIf="nationality.invalid && nationality.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{nationality['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="city" type="text" pInputText class="custom-input-text" formControlName="city">
                <label for="city">{{'EDIT-PEOPLE.CITY' | translate}}</label>
                <span
                    *ngIf="city.invalid && city.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{city['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <input id="address" type="text" pInputText class="custom-input-text" formControlName="address">
                <label for="address">{{'EDIT-PEOPLE.ADDRESS' | translate}}</label>
                <span
                    *ngIf="address.invalid && address.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{address['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label">
                <!-- Not Yet <p-inputMask id="phone" type="text" class="custom-input-text" formControlName="phone" mask="+99-9999"></p-inputMask> -->
                <input id="phonenumber" type="text" pInputText class="custom-input-text" formControlName="phonenumber">
                <label for="phonenumber">{{'EDIT-PEOPLE.PHONENUMBER' | translate}}</label>
                <span
                    *ngIf="phonenumber.invalid && phonenumber.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{phonenumber['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label" *ngIf="formData?.educations">
                <p-dropdown
                    [options]="formData.educations"
                    formControlName="education_id"
                    class="custom-dropdown"
                    optionLabel="name"
                    filterBy="name"
                    placeholder="&nbsp;"
                    [filter]="true"
                    [showClear]="true" >
                </p-dropdown>
                <label for="education_id">{{'EDIT-PEOPLE.EDUCATION' | translate}}</label>
                <span
                    *ngIf="education_id.invalid && education_id.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{education_id['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12">
            <span class="p-float-label" *ngIf="formData?.occupations">
                <p-dropdown
                    [options]="formData.occupations"
                    formControlName="occupation_id"
                    class="custom-dropdown"
                    optionLabel="name"
                    filterBy="name"
                    placeholder="&nbsp;"
                    [filter]="true"
                    [showClear]="true" >
                </p-dropdown>
                <label for="occupation_id">{{'EDIT-PEOPLE.OCCUPATION' | translate}}</label>
                <span
                    *ngIf="occupation_id.invalid && occupation_id.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{occupation_id['errorMessage']}}
                </span>
            </span>
        </div>
        <div class="p-field p-col-12 p-lg-12 p-md-12">
            <span class="p-float-label">
                <textarea
                    id="notes"
                    [rows]="5"
                    [cols]="30"
                    class="custom-input-text custom-text-area"
                    formControlName="notes"
                    pInputTextarea>
                </textarea>
                <label for="notes">{{'EDIT-PEOPLE.NOTES' | translate}}</label>
                <span
                    *ngIf="notes.invalid && notes.touched"
                    [@inOutAnimation]
                    class="error-tooltip">
                    {{occupation['errorMessage']}}
                </span>
            </span>
        </div>
    </div>

    <h3>{{'CONTAINER-GROUP-USER.GROUPS' | translate}}</h3>
    <hr>
    <div class="p-fluid p-formgrid p-grid group-container" [ngClass]="{'p-jc-center': user.testTakerGroups?.length < 3}">
        <app-template-group-simple
            *ngFor="let item of user.testTakerGroups;"
            class="p-col-12 p-md-12 p-lg-6 p-xl-4"
            [ngClass]="{'p-xl-5': user.testTakerGroups.length < 3}"
            [item]="item"
            [optionsLeft]="'none'"
            [optionsRight]="'delete'"
            (itemDeleted)="deleteGroup($event)">
        </app-template-group-simple>

        <div class="p-col-12 p-text-right btn-container">
            <button pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-text btn-float" label="{{'PEOPLE.ADD-GROUP' | translate}}" (click)="testtakerGroupSelector.openModal()"></button>
        </div>
    </div>

    <div class="form-footer p-d-flex p-jc-between p-ai-center">
        <span><span class="red_dot">*</span>{{'EDIT-PEOPLE.MANDATORY' | translate}}</span>
        <button
            type="submit"
            class="btn-primary"
            pButton
            label="{{ type ==='EDIT' ? ('EDIT-PEOPLE.BTN-EDIT' | translate | uppercase ) : ('EDIT-PEOPLE.BTN-CREATE' | translate | uppercase )}}"
            (click)="saveChanges()"
            [disabled]="editForm.invalid">
        </button>
    </div>
</form>

<!--Modal to add groups-->
<app-testtaker-group-selector #testtakerGroupSelector [type]="'group'" (selectedDataEvent)="loadSelectedDataModal($event)" [ignoreLimit]="true" [hideSelectAll]="true"></app-testtaker-group-selector>

<!-- Modal to import csv excel --> <!--TODO: esto va a ser una componente especifico-->
<p-dialog [(visible)]="showImportUsersModal" [style]="{width: '70vw', height: '50vw'}"  [modal]="true" class="import-modal" (onHide)="clearFileUpload()">
    <div class="import-content">
        <h3 class="p-text-center">{{'EDIT-PEOPLE.IMPORT-USERS-EXTENDED' | translate}}</h3>
        <div class="p-grid p-m-0">
            <!-- UPLOAD FILE SELECTOR -->
            <div class="p-d-flex p-flex-column p-ai-center p-col p-mx-4">
                <h4>{{'EDIT-PEOPLE.IMPORT-SELECT-FILE' | translate}}</h4>
                <p-fileUpload
                    #fileUpload
                    accept=".csv"
                    customUpload="true"
                    class="btn-float p-shadow-3"
                    mode="basic"
                    (onSelect)="updateCSVTesttakerList($event)"
                    (uploadHandler)="updateCSVTesttakerList($event)"
                    [disabled]= "parseTotalErrorsFound > 0 || showPreview"
                    chooseLabel="{{'EDIT-PEOPLE.IMPORT-CHOOSE' | translate}}"
                    cancelLabel="{{'EDIT-PEOPLE.IMPORT-CANCEL' | translate}}" >
                </p-fileUpload>
            </div>
            <!-- SEPARATOR CHARACTER SELECTOR -->
            <div class="parser-content p-col p-mx-4">
                <h4>{{'EDIT-PEOPLE.IMPORT-SELECT-SEPARATOR' | translate}}</h4>
                <p-dropdown
                    [options]="parserOptions"
                    class="custom-dropdown p-shadow-3"
                    filterBy="code"
                    optionLabel="name"
                    (onChange)="reloadListIfFileSelected()"
                    [(ngModel)]="selectedParser"
                    placeholder="&nbsp;" >
                </p-dropdown>
            </div>
        </div>

        <br>

        <!-- ERROR/WARNING/WRONGDELIMITIER COUNT MESSAGES -->
        <div class="p-text-center p-ai-center" *ngIf="showPreview">

            <div *ngIf="parseTotalErrorsFound > 0 && parseTotalWarningsFound > 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-errors">{{parseTotalErrorsFound}}</span> {{'EDIT-PEOPLE.IMPORT-SEVERE-ERRORS' | translate}}, {{'EDIT-PEOPLE.IMPORT-AND-TEXT' | translate}} <span class="text-warnings">{{parseTotalWarningsFound}}</span> {{'EDIT-PEOPLE.IMPORT-WARNINGS-IN-TOTAL' | translate}}.
            </div>

            <div *ngIf="parseTotalErrorsFound > 0 && parseTotalWarningsFound == 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-errors">{{parseTotalErrorsFound}}</span> {{'EDIT-PEOPLE.IMPORT-SEVERE-ERRORS' | translate}}.
            </div>

            <div *ngIf="parseTotalErrorsFound == 0 && parseTotalWarningsFound > 0 && usersToSave.length > 0" class="text-regular">
                {{'EDIT-PEOPLE.IMPORT-THERE-ARE-ATLEAST' | translate}} <span class="text-warnings">{{parseTotalWarningsFound}}</span> {{'EDIT-PEOPLE.IMPORT-WARNINGS-IN-TOTAL' | translate}}.
            </div>

            <div *ngIf="wrongDelimiterCase && usersToSave.length > 0" class="text-warnings " >
                <br>
                {{'EDIT-PEOPLE.IMPORT-WRONG-DELIMITIER' | translate}}
                <br>
            </div>

            <div *ngIf="parseTotalErrorsFound == 0 && usersToSave.length > 0" class="text-ready-to-upload">
                {{'EDIT-PEOPLE.IMPORT-READY-TO-UPLOAD' | translate}}
            </div>

            <div *ngIf="usersToSave.length == 0" class="text-regular">
                <span class="text-errors">{{'EDIT-PEOPLE.NO-USERS-TO-IMPORT' | translate}} </span>
            </div>
        </div>
        <br>

        <!-- CANCEL IMPORT / UPLOAD USERS Buttons -->
        <div class="p-text-center p-ai-center" *ngIf="showPreview">
                <button
                    pButton
                    type="button"
                    class="p-button p-button-info btn-secondary"
                    (click)="clearFileUpload()"
                    label="{{'EDIT-PEOPLE.IMPORT-CANCEL-UPLOAD' | translate}}">
                </button>

                <button
                    *ngIf="usersToSave.length > 0"
                    pButton
                    type="button"
                    class="p-button p-button-success upload-button btn-primary"
                    (click)="uploadTesttakerSavedList()"
                    label="{{'EDIT-PEOPLE.IMPORT-UPLOAD-USERS' | translate}}"
                    [disabled]="parseTotalErrorsFound > 0">
                </button>
        </div>

        <div class="p-text-center" *ngIf="showSpinner">
            <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
        </div>

        <!-- USERS TO IMPORT LIST -->
        <div class="top import-users" *ngIf="showPreview">
            <div class="imported-users-type-container">
                <p class="imported-users-type" >{{'EDIT-PEOPLE.IMPORT-NEW-USERS' | translate}}</p>
            </div>

            <app-template-user-simple
                *ngFor="let item of usersToSave"
                [item]="item"
                [optionsRight]="'button'"
                [importingCSVTesttakers]="true"
                (itemDeleted)="deleteUserCSV($event)"
                (itemSelected)="selectUserCSV($event)">
            </app-template-user-simple>
        </div>
        <div class="top import-users" *ngIf="showPreview">
            <div class="imported-users-type-container">
                <p class="imported-users-type" >{{'EDIT-PEOPLE.IMPORT-EXISTING-USERS' | translate}}</p>
            </div>
            <app-template-user-simple
                *ngFor="let item of usersAlreadySaved"
                [item]="item"
                [importingCSVTesttakers]="true">
            </app-template-user-simple>
        </div>
    </div>
    <p-footer>
        <div class="p-text-center download-example">
            <a class="btn-link pointer" href="assets/examples/ImportsUsersExample.csv">{{'EDIT-PEOPLE.IMPORT-EXAMPLE' | translate}}</a>
        </div>
    </p-footer>
</p-dialog>
