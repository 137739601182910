<div class="main p-d-flex p-jc-center p-ai-center">
    <div class="content">
        <form [formGroup]="forgotForm" class="password-form p-d-flex p-flex-column">
            <h2>{{'RESET.TITLE'|translate}}</h2>

            <div class="body-text">
               {{'RESET.INFO'|translate}}
               <ul>
                    <li *ngIf="!ext_pass">{{'RESET.INFO-2' | translate}}</li>
                    <li *ngIf="ext_pass">{{'RESET.INFO-2-1' | translate}}</li>
                    <li>
                        {{'RESET.INFO-3'|translate}}
                    </li>
                    <li>
                        {{'RESET.INFO-5'|translate}}
                    </li>
               </ul>
            </div>            

            <div class="p-inputgroup custom-inputgroup">
                <span class="p-float-label">
                    <input 
                        id="password" 
                        type="password"
                        pPassword
                        [feedback]="false"
                        [showPassword]="showPassword"
                        class="custom-input-text"
                        formControlName="password">
                    <label for="password">{{'RESET.PASSWORD' | translate}}</label>
                    <span 
                        *ngIf="password.invalid && password.touched" 
                        [@inOutAnimation]
                        class="error-tooltip">
                        {{forgotForm.controls.password['errorMessage']}}
                    </span>
                </span>
                <button type="button" pButton 
                    icon="{{showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}}" 
                    class="input-button" 
                    (click)="showPassword = !showPassword">
                </button>
            </div>

            <div class="p-inputgroup custom-inputgroup">
                <span class="p-float-label">
                    <input 
                        id="repeatPassword"
                        type="password" 
                        pPassword
                        [feedback]="false"
                        [showPassword]="showRepeatPassword"
                        class="custom-input-text"
                        formControlName="repeatPassword">
                    <label for="repeat-password">{{'RESET.CONFIRM-PASSWORD' | translate}}</label>
                    <span 
                        *ngIf="repeatPassword.invalid && repeatPassword.touched" 
                        [@inOutAnimation]
                        class="error-tooltip">
                        {{forgotForm.controls.repeatPassword['errorMessage']}}
                    </span>
                </span>
                <button type="button" pButton 
                    icon="{{showRepeatPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}}" 
                    class="input-button" 
                    (click)="showRepeatPassword = !showRepeatPassword">
                </button>
            </div>

            <div class="btn-row p-d-flex p-jc-end p-ai-center">
                <button 
                    type="submit" 
                    class="btn-primary" 
                    pButton 
                    label="{{'FORGOT.SUBMIT' | translate}}"
                    [disabled]="forgotForm.invalid"
                    (click)="send()">
                </button>
            </div>            
        </form>
    </div>
</div>
