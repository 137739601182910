import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { inOutAnimation } from '../../animations/in-out-animation';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  animations: [inOutAnimation]
})
export class InputComponent implements OnInit {

  @Input() inputForm: any;
  @Input() myFormGroup: any;
  @Input() dynamicOptions: any;
  @Input() maskEditable;
  @Input() displayInModal = false;
  @Output() optionChange = new EventEmitter<any>();  

  constructor() { }

  ngOnInit(): void {
    // Solo haremos esta llamada cuando se trate de cargar las opciones que no pueden ser utilizadas de nuevo 
    if(this.inputForm.group && this.inputForm.dynamicOption){
      this.optionChange.emit(this.inputForm.dynamicOption);
    }  
  }

  getDynamicOption(dynamicOptionId): any{
    if (this.dynamicOptions){
           let groupedOptions = this.dynamicOptions.filter(x => x.id === dynamicOptionId)[0];
     return groupedOptions.options
    }
  }

  optionChanged(dynamicOptionId): void{
    this.optionChange.emit(dynamicOptionId);
  }
}
