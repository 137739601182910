import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-role-based-template',
  templateUrl: './role-based-template.component.html',
  styleUrls: ['./role-based-template.component.scss']
})
export class RoleBasedTemplateComponent {

  @Input() availableUsers: any[];
  @Input() roles: any[];
  @Input() evaluatedPersonList: any[];
  @Input() evaluatedPerson;

  selectedRoles = [];
  availableRoles = [];

  @Output() setSelectedRoles = new EventEmitter<any>();
  @Output() setEvaluatedPerson = new EventEmitter<any>();

  initRoleBasedTemplate(){
    this.availableRoles = JSON.parse(JSON.stringify(this.roles));
    this.setAvailableRoles();
  }

  setAvailableRoles() {
    const assignedRoles = this.availableUsers.filter(_user => _user.role).map(_user => _user.role);
    this.availableRoles.forEach(_role => {
      _role.inactive = false;
      if(assignedRoles.includes(_role.name)){
        _role.inactive = true;
      }
    });
    this.setSelectedRoles.emit(this.availableUsers);
  }

  setEvaluatedAssessmentName(nameToShow){
    this.evaluatedPerson.assessmentName = nameToShow;
    this.emitChangesToParent();
  }

  emitChangesToParent(){
    this.setEvaluatedPerson.emit(this.evaluatedPerson);
  }

}
