import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PeopleService } from 'src/app/modules/people/services/people.service';
import { ToastConfirmService } from '../../services/toast-confirm.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast-content',
  templateUrl: './toast-content.component.html',
  styleUrls: ['./toast-content.component.scss'],
  providers: [ ToastService ]
})
export class ToastContentComponent implements OnInit {

  constructor(private messageSrv: MessageService, private toastConfirmSrv: ToastConfirmService) { }

  ngOnInit(): void {
  }

  close(): void {
    this.messageSrv.clear('toast-error');
  }
  closeInfo(): void {
    this.messageSrv.clear('toast-info');
  }
  closeConfirm(): void {
    this.toastConfirmSrv.setConfirmActionValue(false);
    this.toastConfirmSrv.setConfirmActionValue(null);
    this.messageSrv.clear('toast-confirm');
  }

  applyConfirm(): void {
    this.toastConfirmSrv.setConfirmActionValue(true);
    this.toastConfirmSrv.setConfirmActionValue(null);
    this.messageSrv.clear('toast-confirm');
  }
}
