import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectStatusString } from 'src/app/shared/enums/project-status-string.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';
import { ProjectsService } from '../../services/projects.service';
import { animate, style, transition, trigger } from '@angular/animations';

export enum ProjectSize{
  INDIVIDUAL=0,
  GROUPAL=1
}
@Component({
  selector: 'app-projects-container',
  templateUrl: './projects-container.component.html',
  styleUrls: ['./projects-container.component.scss'],
  providers: [ToastService]
})
export class ProjectsContainerComponent implements OnInit, OnDestroy {

  ProjectSize=ProjectSize
  @Input() embedded = false;
  @ViewChild('individualProjects') individualProjects;
  @ViewChild('groupProjects') groupProjects;

  activeIndex = ProjectSize.INDIVIDUAL;

  subs = new SubSink();

  constructor(
    private projectsSrv: ProjectsService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.projectsSrv.sharedActiveIndex.subscribe(data => {
      if(!data){
        this.activeIndex = ProjectSize.INDIVIDUAL;
      } else {
        this.activeIndex = data;
      }
    });
  }

  /**
   * Store the tab selected by psyuser
   */
  storeActiveIndex(): void{
    this.projectsSrv.setActiveIndex(this.activeIndex);
    //this.getProjects()
  }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }
}