import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PsyUserRoles } from '@giuntipsy/utils/lib';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminApi } from 'src/app/modules/admin/api/admin.api';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { PadNumberPipe } from 'src/app/shared/pipes/pad-number.pipe';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ToastService]
})
export class ProfileComponent implements OnInit, OnDestroy {

  @ViewChild('planDetail') appPlanDetail;

  subs = new SubSink();

  // Loaders
  showPlans = false;
  showLoadingUI = true;
  showPlanInfo = true;
  showHistory = false;

  // Info Ui
  activeIndex;

  // PsyUser Data
  psyUserName = '';
  psyUserId;
  psyuserSubscriptions = []; // All the subscriptions
  psyuserSubscriptionsToShow = []; // Subscriptions showed in the dropdown
  psyuserSubscriptionsHistory = [];

  // Customer Data
  customer = null;
    
  // Plan details
  planToShow: any;  
  
  // Flag to show or hide inactive plans
  showInactive = false;

  // Psyuser profile
  readonly psyUserRoles = PsyUserRoles;
  psyuser: any;

  constructor(
    private adminSrv: AdminService,
    private authSrv: AuthenticationService,
    private planSrv: PlanService,
    private adminApi: AdminApi,
    private toastSrv: ToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    // Change the tab position regarding the query params (to avoid change the plan details in the header dashboard)
    this.route.queryParams.subscribe(params => {
        if(params.index == 2) {
          setTimeout( () => { 
            this.activeIndex = 1;
           }, 1);          
        } else {
          setTimeout( () => { 
            this.activeIndex = 0;
           }, 1);          
        }
    });

    // Set previous tab position
    this.subs.sink = this.planSrv.sharedActiveIndex.subscribe(data => {
      this.activeIndex = data;
    });

    this.subs.sink = this.authSrv.sharedCurrentPsyUser.subscribe(psyuser => {
      this.psyuser = psyuser
    })


    this.subs.sink = this.adminSrv.sharedCustomer.subscribe(res => {
      if (!res) {
        return;
      }
      // get info of psyuser and customer
      this.customer = res;
      this.psyUserId = this.authSrv.getCurrentPsyUser().id;
      let myPsyUser = this.authSrv.getCurrentUser();      

      this.psyUserName = `${myPsyUser.name} ${myPsyUser.surname}`;      

      // Get psyuser subscriptions
       this.subs.sink = this.adminApi.getPsyuserSubscriptions(this.psyUserId).subscribe(res => {
        if(res.statusOk){
          this.psyuserSubscriptions = res.data;

          // Set psyuser id in object to show later plan info 
          this.psyuserSubscriptions.forEach(_sub => _sub.psyuserId = this.psyUserId);

          this.setPsyUserActiveOrInactiveSubscriptions('active') // Active by default

          // Set psyuser subscription history
          this.setPsyUserSubscriptionsHistory();
        }
      }, err => {
        this.toastSrv.showToastError(err);
      });  

      this.showPlans = true;
      this.showLoadingUI = false;
    });    
  }

  storeActiveIndex(): void{
    this.planSrv.setActiveIndex(this.activeIndex);
  }

  changeShowInactive(){
    if(this.showInactive){
      this.setPsyUserActiveOrInactiveSubscriptions('inactive');
    }else{      
      this.setPsyUserActiveOrInactiveSubscriptions('active');      
    }
  }

  // Set psyuser subscriptions based in filter data
  setPsyUserActiveOrInactiveSubscriptions(_status) {
    this.psyuserSubscriptionsToShow = [];
    if(_status === 'inactive'){
      this.psyuserSubscriptionsToShow = [...this.psyuserSubscriptions];
    } else {
      this.psyuserSubscriptionsToShow = this.psyuserSubscriptions.filter(_psyUserSub => _psyUserSub.isAlive === true);
    }

    // Order by subscription_id            
    this.psyuserSubscriptionsToShow.sort((a, b) => { return a.sub_id - b.sub_id});

    // Set the first item as selected
    this.planToShow = this.psyuserSubscriptionsToShow[0];
  }

  // Set psyuser history based in alive subscriptions
  setPsyUserSubscriptionsHistory(){
    this.adminApi.getPsyuserHistorySubscriptions(this.psyUserId).subscribe(res => {
      if(res.statusOk){
        this.psyuserSubscriptionsHistory = res.data;
      }
    }, err => {
      this.toastSrv.showToastError(err);
    }).add(()=> {
      this.showHistory = true;
    })    
  }
    
  ngOnDestroy(): void {
    this.subs.unsubscribe();   
  }
}
