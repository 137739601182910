import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) { }

  /**
   * Checks if the current language matches the specified language(s).
   * It should be used for rendering content based on the language selected within the app
   * @param lang - The language or languages to check.
   * @returns - True if the current language matches the specified language(s); False otherwise.
   */
  isLanguage(lang: string | string[]): boolean{
    return (typeof lang === 'string')
        ? this.translate.currentLang === lang
        : lang.some(element => element === this.translate.currentLang);
  }
}
