import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpService } from 'src/app/modules/help/services/help.service';
import { ProjectsService } from 'src/app/modules/projects/services/projects.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-help-shortcut',
  templateUrl: './help-shortcut.component.html',
  styleUrls: ['./help-shortcut.component.scss']
})
export class HelpShortcutComponent implements OnInit, OnDestroy {

  display = false;

  subs = new SubSink();

  constructor(
    private projectsSrv: ProjectsService,
    private helpSrv: HelpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.router.events.subscribe((val: any) => {
      if (val?.url) {
        if (['/dashboard', '/profile'].includes(val.url)) {
          this.display = true;
        } else {
          this.display = false;
        }
      }
    });
  }

  goToCreateNew(): void {
    this.projectsSrv.setCurrentProject(this.projectsSrv.generateNewProject());
    this.router.navigate(['projects', 'create']);
  }

  goToHelpDetail(key: string, position: number): void {
    this.helpSrv.setSelectedHelp({key: key, position: position});
    this.router.navigate(['help']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
